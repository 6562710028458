// Vue module
// noinspection JSUnresolvedFunction,SpellCheckingInspection,JSUnresolvedVariable,JSCheckFunctionSignatures,DuplicatedCode

import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate'
import VueResource from 'vue-resource'
import VueFilterDateFormat from 'vue-filter-date-format'
import NumberFormat from 'vue-filter-number-format'
import Base64Upload from 'vue-base64-upload'
import VueMoment from 'vue-moment'

import Home from '../.././app/views/home/home.vue'
import Login from '../.././app/views/auth/login.vue'
import NewPassword from '../.././app/views/auth/newPassword.vue'
import GroupV3 from '../.././app/views/group/groupV3.vue'
import Register from '../.././app/views/user/register.vue'
import PersonV3 from '../.././app/views/person/personV3.vue'
import InvitePersonV3 from '../.././app/views/person/invitePersonV3.vue'
import InviteFormV3 from '../.././app/views/person/inviteFormV3.vue'
import GalleryV3 from '../.././app/views/matcher/galleryV3.vue'
import PersonV4List from '../.././app/views/matcher/personv4list.vue'
import CameraV3 from '../.././app/views/matcher/cameraV3.vue'
import ProximityV3 from '../.././app/views/matcher/proximityV3.vue'
import VerifyV3 from '../.././app/views/matcher/verifyV3.vue'
import BodiesV3 from '../.././app/views/matcher/bodiesV3.vue'
import Education from '../.././app/views/education/demo.vue'
import Call from '../.././app/views/call/demo.vue'
import BIControl from '../.././app/views/bi/control.vue'
import ServerInfo from '../.././app/views/server/home.vue'
import Teleperformance from '../.././app/views/matcher/teleperformance.vue'

import { configFastMenu, showFastMenu } from './unike-ui'

import { pbe } from './pbe.js'

import Auth from './auth.js'

import UIkit from 'uikit'
import Paginate from 'vuejs-paginate'
import VueTheMask from 'vue-the-mask'

import {Strings} from './common.js'

import _App from '../.././app/app.json'

// Create a router instance
// You can pass in additional options here, but let's
// keep it simple for now
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VeeValidate, { events: 'blur' })
Vue.use(VueResource)
Vue.use(VueFilterDateFormat)
Vue.use(Base64Upload)
Vue.use(VueTheMask)
Vue.use(VueMoment)

Vue.component('paginate', Paginate)

Vue.filter('numberFormat', NumberFormat)

Vue.http.headers.common['Access-Control-Allow-Origin']  = '*'
Vue.http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Origin, X-Requested-With, Content-Type'
Vue.http.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, DELETE, PUT'
Vue.http.interceptors.push(function(request) {

    return function(response) {

        if (response.status === 401) {

            window.location.href = '/#/login'
            return

        }

        if (vm.user !== null && vm.user !== undefined && vm.user.enabledEncryptionAPI === true) {

            vm.transformRecursiveObject(response.body)

        }

    }

})

const ra = Auth.data().roles.admin,
    ru = Auth.data().roles.user,
    rc = Auth.data().roles.signer

// Here we defined the router to the application
export var router = new VueRouter({

    routes: [
        {
            path: '/',
            redirect: '/login',
            component: {
                template: '<router-view/>',
            },
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: Home,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/login',
                    name: 'login',
                    component: Login,
                    auth: false
                },
                {
                    path: '/new/password',
                    name: 'new_password',
                    component: NewPassword,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/logout',
                    name: 'logout',
                    component: Login,
                    auth: false
                },
                {
                    path: '/group',
                    name: 'group',
                    component: GroupV3,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/user/register',
                    name: 'register',
                    component: Register,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/person/v3',
                    name: 'personV3',
                    component: PersonV3,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/invite/person/v3',
                    name: 'invite_person',
                    component: InvitePersonV3,
                    auth: false
                },
                {
                    path: '/invite/form/v3',
                    name: 'invite_form',
                    component: InviteFormV3,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/matcher/gallery/v3',
                    name: 'galleryV3',
                    component: GalleryV3,
                    authorized: [ra],
                    auth: true
                },
		{
                    path: '/matcher/personv4list',
                    name: 'personv4list',
                    component: PersonV4List,
                    authorized: [ra],
                    auth: true
                },
                {
                    path: '/matcher/camera/v3',
                    name: 'cameraV3',
                    component: CameraV3,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/matcher/proximity/v3',
                    name: 'proximityV3',
                    component: ProximityV3,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/matcher/verify/v3',
                    name: 'verifyV3',
                    component: VerifyV3,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/matcher/bodies/v3',
                    name: 'bodiesV3',
                    component: BodiesV3,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/matcher/tp/v3',
                    name: 'tpV3',
                    component: Teleperformance,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/education/demo',
                    name: 'education_demo',
                    component: Education,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/call/demo',
                    name: 'call_demo',
                    component: Call,
                    authorized: [ra, ru],
                    auth: true
                },
                {
                    path: '/bi/control',
                    name: 'bi_control',
                    component: BIControl,
                    authorized: [ra, ru, rc],
                    auth: true
                },
                {
                    path: '/server/home',
                    name: 'server',
                    component: ServerInfo,
                    authorized: [ra, ru],
                    auth: true
                }
            ]
        }
    ]
})

// The router needs a root component to render.
// For demo purposes, we will just use an empty one
// because we are using the HTML as the app template.
// Note that the App is not a Vue instance.
export var App = Vue.extend({ name: 'app' })

// Global functions and data
export var vm = new Vue({

    // mixins: [mixin],

    data() {

        return {

            user: null,
            accessTime: null,
            token: null,
            roles: Auth.data().roles

        }

    },

    created() {

        let this_ = this

        this_.checkSessionStorage()

        if (sessionStorage.user && sessionStorage.token) {

            this_.sessionUpdate()

        } else {

            let loginTheme = localStorage.getItem('login_theme')

            if (loginTheme !== undefined && loginTheme) {

                document.querySelector('#tmpl').setAttribute('href', loginTheme)

                if (loginTheme !== 'content/css/template_dinamic_gradient.css') {

                    document.querySelector('.ukid-geometric-movs').style.display = 'none'

                } else {

                    document.querySelector('.ukid-geometric-movs').style.display = 'block'

                }

            }

        }

    },

    mounted() {

        let theme = localStorage.getItem(window.location.hostname + '_' + sessionStorage.user + '_theme')

        if (theme !== 'content/css/template_dinamic_gradient.css') {

            document.querySelector('.ukid-geometric-movs').style.display = 'none'

        } else {

            document.querySelector('.ukid-geometric-movs').style.display = 'block'

        }

    },

    computed: {

        auth() {
            return Auth;
        }

    },

    filters: {

        hasPermission(roles) {
            return Auth.hasPermission(this, roles)
        }

    },

    methods: {

        authenticated() {
            return sessionStorage.token != null
        },

        checkSessionStorage() {

            if (this.$root.authenticated()) {

                Vue.http.headers.common['Authorization'] = 'Bearer ' + sessionStorage.token;
                document.querySelector('#logout-buttom').style.display = 'block'

            } else {

                document.querySelector('#logout-buttom').style.display = 'none'

            }

        },

        logout() {

            this.$root.user = null
            this.$root.token = null

            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')

        },

        sessionUpdate() {

            if (sessionStorage.user) {

                this.token = sessionStorage.token
                this.user = JSON.parse(sessionStorage.user)

                this.accessTime = sessionStorage.accessTime

                let theme = localStorage.getItem(window.location.hostname + '_' + this.user.sub + '_theme')

                if (theme !== undefined && theme) {

                    document.querySelector('#tmpl').setAttribute('href', theme)

                } else {

                    document.querySelector('#tmpl').setAttribute('href', 'content/css/template_infinity_blue.css')

                }

                document.querySelector('#name-logged').innerHTML = 'user: '  + this.user.sub
                document.querySelector('#role-logged').innerHTML = 'roles: ' + this.user.auth

            }


        },

        isEditableForMe(username) {

            // Get user from datasource
            let user = (sessionStorage.user && sessionStorage.token ? JSON.parse(sessionStorage.user) : null)

            let parent = user.parentList
                .filter( o => o.type === 'CHILD' || o.type === 'SELF' )
                .filter( o => o.username === username )

            return user.auth.includes('admin') || ( Array.isArray( parent ) && parent.length > 0 )

        },

        layoutLogin() {

            document.querySelector('#app').className = 'ukid-primary-color'
            document.querySelector('#ukid-top').style.visibility = 'hidden'
            document.querySelector('#ukid-footer').style.visibility = 'hidden'
            document.querySelector('#ukid-login-logo').style.display = ''

            document.querySelector('#ukid-view-logo-un').classList.remove('uk-hidden')

        },

        layoutDefault() {

            document.querySelector('#ukid-top').style.visibility = 'visible'
            document.querySelector('#ukid-footer').style.visibility = 'visible'
            document.querySelector('#ukid-login-logo').style.display = 'none'

        },

        progress(increment, interval, spinner, icon) {

            let bar = document.querySelector('.uk-progress')

            bar.value = 0
            bar.max   = 0

            UIkit.util.ready(function() {

                let animate = setInterval(function() {

                    bar.value += increment

                    if (bar.value >= bar.max) {

                        setTimeout(function() {

                            spinner.classList.add('uk-hidden')
                            icon.classList.remove('uk-hidden')

                        }, interval)

                        clearInterval(animate)

                    }

                }, interval);

            })

        },

        spinnerWithProgress(increment, interval) {

            let spinner = document.querySelector('.ukid-spinner')
            let icon    = document.querySelector('.ukid-icon')

            if (spinner.classList.contains('uk-hidden')) {

                spinner.classList.remove('uk-hidden')
                icon.classList.add('uk-hidden')

            } else {

                spinner.classList.add('uk-hidden')
                icon.classList.remove('uk-hidden')

            }

            if (increment && interval) {

                this.progress(increment, interval, spinner, icon)

            }

        },

        configDeviceResolution() {

            let filter = document.querySelector('#ukid-filter')
            let card   = document.querySelector('#ukid-card-form')

            if (document.body.scrollWidth < 960) {

                if (card.classList.contains('uk-visible@m')) {

                    filter.classList.add('uk-hidden')
                    card.classList.remove('uk-visible@m')

                } else {

                    filter.classList.remove('uk-hidden')
                    card.classList.add('uk-visible@m')

                }

            }

        },

        spinner() {

            this.spinnerWithProgress(null, null)

        },

        spinnerSearch() {

            let spinner = document.querySelector('.ukid-search-spinner')
            let search = document.querySelector('.ukid-search-button')

            if (spinner.classList.contains('uk-hidden')) {

                spinner.classList.remove('uk-hidden')
                search.classList.add('uk-hidden')

            } else {

                spinner.classList.add('uk-hidden')
                search.classList.remove('uk-hidden')

            }

        },

        transformRecursiveObject(obj) {

            for (let k in obj) {

                if (typeof obj[k] === 'object' && obj[k] !== null) {

                    this.transformRecursiveObject(obj[k])

                } else if (obj.hasOwnProperty(k)) {

                    try {

                        window.atob(obj[k])

                        /*pbe.decrypt(obj[k], vm.user.id, function(value) {

                            if (value == "null") {

                                value = null

                            } else if (value == "undefined") {

                                value = undefined

                            }

                            obj[k] = value

                        })*/

                        pbe.decrypt(obj[k], vm.user.id, Buffer.from([111, 123, 56, 123, 99, 108, 45, 65]), 21, function(value) {

                            if (value === "null") {

                                value = null

                            } else if (value === "undefined") {

                                value = undefined

                            }

                            obj[k] = value

                        })

                    } catch(err) {
                    }

                }

            }

        }

    }

})

router.afterEach((to, from, next) => {

    if (to.path !== '/login' && to.path !== '/home' && to.path !== '/invite/person/v3' && !localStorage.getItem('cookie:accepted')) {

        /**
         * Close all visual notifications of uikit and send new
         */
        UIkit.notification.closeAll()
        UIkit.notification({
            message: _App.notification.cookiesAccept,
            status: 'primary',
            pos: 'top-center',
            timeout: _App.notification.timeout
        })

        location.href = '#/home';

    }

})

// This is the new way to validate router before send to the next action
router.beforeEach((to, from, next) => {

    let footer = document.querySelector('.ukid-main-footer')
    let offcanvas = document.querySelector('#offcanvas-slide')

    for (let i = 1; i < 999999; i++) {

        window.clearInterval(i);

    }

    // Get user from datasource
    let user = (sessionStorage.user && sessionStorage.token ? JSON.parse(sessionStorage.user) : null)

    // Prinipal element of application
    document.querySelector('#app').className = null

    // Set layout to default options
    vm.layoutDefault()

    // Show or hide fast menu
    showFastMenu(to.path)

    if (!to.matched) {

        next('/home')

        return

    }

    if (to.path === '/login' || to.path === '/logout' || !user) {

        vm.layoutLogin()

        vm.logout()

        next()

        return

    }

    if (user.blockedAccess || (to.path !== '/new/password' && user && user.enabledPasswordExpiration === true && vm.$moment(vm.$moment()).diff(user.lastPasswordTimestamp, 'days') >= user.expirationDaysPassword)) {

        next('/new/password')

        return

    }

    for (let i = 0; i < router.options.routes[0].children.length; i++) {

        let route = router.options.routes[0].children[i]

        if (route.path === to.path) {

            // noinspection PointlessBooleanExpressionJS
            if (route.auth && user === null) {

                // if route requires auth and user isn't authenticated
                next('/login')

            } else if (!route.auth) {

                next()

            } else {

                // Specific access
                let aut = false

                // Sweeps the type access
                for (let i = 0; i < route.authorized.length; i++) {

                    if (user && user.auth) {

                        if (Strings.contains(user.auth, route.authorized[i])) {

                            aut = true

                            break

                        }

                    }

                }

                // Check specific access
                if (aut) {

                    footer.classList.remove('uk-hidden')

                    UIkit.offcanvas(offcanvas).hide()

                    configFastMenu()

                    next()

                } else {

                    UIkit.offcanvas(offcanvas).hide()
                    footer.classList.add('uk-hidden')

                    // User does not have the necessary role. Redirecting to home page
                    next('/login')

                }

            }

        }

    }

})

new Vue( { el: '#app',  router: router } ).$mount('#app')