var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.App.messages.delGalleryAsk)),
                _c("br"),
                _vm._v(_vm._s(_vm.App.messages.delGalleryWarn1)),
                _c("br"),
                _vm._v(_vm._s(_vm.App.messages.delGalleryWarn2))
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.App.messages.popoUpButtonCancel) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.del(Object.assign({}, _vm.gallery))
                  }
                }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.App.messages.popoUpButtonDelete) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color",
          attrs: { id: "ukid-filter" }
        },
        [
          _c(
            "dl",
            {
              staticClass:
                "uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl"
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ukid-padding ukid-padding-10px ukid-font-size-15"
                    },
                    [
                      _vm._v(
                        "\n                    Criação, atualização e exclusão de galerias\n                    "
                      ),
                      _c("br"),
                      _vm._v(
                        "Total: " +
                          _vm._s(_vm.pagination.databaseCount) +
                          "\n                "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("br", { staticClass: "uk-hidden@m uk-hidden@l uk-hidden@xl" }),
              _vm._v(" "),
              _c("dt", [_vm._v("\n                Pesquisar\n            ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParam,
                      expression: "searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-width-80-percent",
                  attrs: {
                    id: "search-param-input",
                    type: "text",
                    placeholder: "Pesquise qualquer termo"
                  },
                  domProps: { value: _vm.searchParam },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchParam = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6",
                  attrs: { "uk-icon": "icon:search;ratio:1.4" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "uk-hidden ukid-search-spinner ukid-magin-left-6",
                  attrs: { "uk-spinner": "ratio:0.92" }
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.clear(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Iniciar Novo Registro\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.registers, function(gallery) {
                return _c("dt", { key: gallery.name }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-label ukid-width-70-percent ukid-padding-10px"
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(gallery.name) +
                          "\n                    "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                    Faces Cadastradas: " +
                          _vm._s(gallery.faces) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass:
                      "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                    attrs: { "uk-icon": "icon:file-edit;ratio:0.9" },
                    on: {
                      click: function($event) {
                        return _vm.fill(gallery)
                      }
                    }
                  }),
                  _vm._v(" "),
                  gallery
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                        on: {
                          click: function($event) {
                            return _vm.modal(gallery)
                          }
                        }
                      })
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registers.length,
                  expression: "!registers.length"
                }
              ],
              staticClass:
                "uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30",
              attrs: { "uk-alert": "" }
            },
            [
              _c("span", {
                staticClass:
                  "uk-form-icon ukid-search-spinner ukid-magin-left-10",
                attrs: { "uk-icon": "icon:ban" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ukid-magin-left-20" }, [
                _vm._v(
                  "\n                Nenhum registro encontrado\n            "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "v-model": _vm.page,
                  "page-count": _vm.pagination.last_page,
                  "click-handler": _vm.list,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent",
          attrs: { id: "ukid-card-form" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-card-header ukid-supress-border-bottom uk-visible@m"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-150",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c("span", { staticClass: "ukid-font-size-18" }, [
                      _vm._v("Gestão de Galerias")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", [
                        _vm._v(
                          "                                \n                            Criação, atualização e exclusão"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                            As galerias são armazenadas diretamente em nosso matcher"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                            São utilizadas para segmentar biometrias\n                            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-label ukid-label-yellow-color" },
                          [
                            _vm._v(
                              "Galerias: " +
                                _vm._s(_vm.pagination.databaseCount)
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("progress", {
            staticClass:
              "uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0",
            attrs: { value: "0", max: "100" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "uk-card-footer ukid-supress-border-top": !_vm.Commons.isMobile(),
                "uk-card-footer ukid-supress-border-top ukid-card-footer-mobile": _vm.Commons.isMobile()
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "ukid-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("fieldset", { staticClass: "uk-fieldset" }, [
                    _c("legend", { staticClass: "uk-legend" }, [
                      _c(
                        "span",
                        { staticClass: "ukid-font-size-25 uk-visible@m" },
                        [_vm._v("Formulário")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.clear(true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            Iniciar Novo Registro\n                        "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "uk-label uk-margin ukid-primary-label-color"
                        },
                        [_vm._v("Nome da Galeria")]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("name-input_field"),
                              expression: "errors.has('name-input_field')"
                            }
                          ],
                          staticClass: "uk-alert-danger uk-visible@s",
                          attrs: { "uk-alert": "" }
                        },
                        [
                          _c("b", [_vm._v("ATENÇÃO:")]),
                          _vm._v(
                            " A criação da galeria exige de 3 a 20 digitos, permite letras, números e underscore _\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("name-input_field"),
                              expression: "errors.has('name-input_field')"
                            }
                          ],
                          staticClass:
                            "uk-alert-danger uk-hidden@s ukid-font-size-10",
                          attrs: { "uk-alert": "" }
                        },
                        [
                          _c("b", [_vm._v("ATENÇÃO:")]),
                          _vm._v(
                            " A criação da galeria exige de 3 a 20 digitos, permite letras, números e underscore _\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uk-inline ukid-width-100-percent" },
                        [
                          _c("span", {
                            staticClass: "uk-form-icon",
                            attrs: { "uk-icon": "icon:warning" }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.gallery.name,
                                expression: "gallery.name"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  "required|alpha_dash|min:3|max:100|regex:^([a-zA-Z0-9_]+)$",
                                expression:
                                  "'required|alpha_dash|min:3|max:100|regex:^([a-zA-Z0-9_]+)$'"
                              }
                            ],
                            class: {
                              "ukid-form-error uk-form-large uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                                "name-input_field"
                              ),
                              "uk-input uk-form-large ukid-focus ukid-width-100-percent": !_vm.errors.has(
                                "name-input_field"
                              )
                            },
                            attrs: {
                              "c-data-vv-as": "field",
                              id: "name-input_field",
                              name: "name-input_field",
                              type: "text",
                              placeholder: "Mínimo 3, máximo 100"
                            },
                            domProps: { value: _vm.gallery.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.gallery,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-flex uk-flex-right uk-visible@m" },
                      [
                        _c(
                          "div",
                          { staticClass: "uk-visible@m" },
                          [
                            _c("router-link", { attrs: { to: "/home" } }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "uk-button uk-button-default uk-button-large uk-align-center"
                                },
                                [_vm._v("Cancelar")]
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-hidden@m" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "uk-button uk-button-default uk-button-large uk-align-center",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.vm.configDeviceResolution()
                                }
                              }
                            },
                            [_vm._v("Voltar")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40"
                      },
                      [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass:
                                "uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.vm.configDeviceResolution()
                                }
                              }
                            },
                            [_vm._v("Voltar")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(3)
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner-sm",
          attrs: { "uk-spinner": "ratio:2.6" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon-sm ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:folder;ratio:3.6" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-width-auto ukid-min-width-165" }, [
      _c("span", {
        staticClass: "uk-hidden ukid-spinner",
        attrs: { "uk-spinner": "ratio:4" }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "ukid-icon ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6",
        attrs: { "uk-icon": "icon:folder;ratio:5.2" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-default uk-button-large uk-margin-left",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-10205be5", { render: render, staticRenderFns: staticRenderFns })
  }
}