var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: "#history-" + _vm.id19, "uk-toggle": "" } }, [
    _c("div", { staticClass: "tooltip-container" }, [
      _c(
        "svg",
        {
          class: { work: _vm.work, alternative: !_vm.work },
          attrs: {
            width: "24",
            height: "24",
            viewBox: "0 0 20 20",
            xmlns: "http://www.w3.org/2000/svg",
            "data-svg": "user"
          }
        },
        [
          _vm.work
            ? _c("circle", {
                attrs: {
                  fill: "none",
                  stroke: "#B2EB89",
                  "stroke-width": "1.1",
                  cx: "9.9",
                  cy: "6.4",
                  r: "4.4"
                }
              })
            : _c("circle", {
                attrs: {
                  fill: "none",
                  stroke: "#1E87F0",
                  "stroke-width": "1.1",
                  cx: "9.9",
                  cy: "6.4",
                  r: "4.4"
                }
              }),
          _vm._v(" "),
          _vm.work
            ? _c("path", {
                attrs: {
                  fill: "none",
                  stroke: "#B2EB89",
                  "stroke-width": "1.1",
                  d:
                    "M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"
                }
              })
            : _c("path", {
                attrs: {
                  fill: "none",
                  stroke: "#1E87F0",
                  "stroke-width": "1.1",
                  d:
                    "M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"
                }
              })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tooltip" }, [
        _vm._v("\n      Histórico Funcionário\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-35c61f17", { render: render, staticRenderFns: staticRenderFns })
  }
}