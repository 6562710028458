var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vm.user
    ? _c("div", { staticClass: "ukid-padding-top-30 ukid-min-height" }, [
        _c("div", { staticClass: "uk-flex uk-flex-center" }, [
          _c(
            "div",
            {
              staticClass:
                "uk-alert-primary ukid-margin-bottom-20 ukid-width-30-percent ukid-padding-20px",
              attrs: { "uk-alert": "" }
            },
            [
              _c("a", {
                staticClass: "uk-alert-close",
                attrs: { "uk-close": "" }
              }),
              _vm._v("\n            AVISO\n            "),
              _c("br"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                Sua credencial de acesso foi configurada para troca de password de " +
                    _vm._s(_vm.vm.user.expirationDaysPassword) +
                    " em " +
                    _vm._s(_vm.vm.user.expirationDaysPassword) +
                    " dias. \n                O padrão de password foi configurado para conter no mínimo " +
                    _vm._s(_vm.vm.user.minPasswordLength) +
                    " caracteres e não repetir as últimos " +
                    _vm._s(_vm.vm.user.totalPasswordNonRepetition) +
                    " utilizados\n            "
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-flex uk-flex-center" }, [
          _c(
            "form",
            {
              staticClass: "ukid-width-300-px",
              attrs: { id: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("fieldset", { staticClass: "uk-fieldset" }, [
                _c("div", { staticClass: "uk-margin" }, [
                  _c(
                    "span",
                    { staticClass: "ukid-white-color ukid-font-size-14" },
                    [_vm._v("Password Atual")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-inline ukid-width-100-percent ukid-margin-top-10"
                    },
                    [
                      _c("span", {
                        staticClass: "uk-form-icon",
                        attrs: { "uk-icon": "icon:unlock" }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.credentials.password,
                            expression: "credentials.password"
                          }
                        ],
                        ref: "passwordInput",
                        staticClass: "uk-input ukid-width-300-px",
                        attrs: { type: "password", placeholder: "Senha Atual" },
                        domProps: { value: _vm.credentials.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.credentials,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-margin" }, [
                  _c(
                    "span",
                    { staticClass: "ukid-white-color ukid-font-size-14" },
                    [_vm._v("Novo Password")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-inline ukid-width-100-percent ukid-margin-top-10"
                    },
                    [
                      _c("span", {
                        staticClass: "uk-form-icon",
                        attrs: { "uk-icon": "icon:lock" }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.credentials.new_password,
                            expression: "credentials.new_password"
                          }
                        ],
                        ref: "newPasswordInput",
                        staticClass: "uk-input ukid-width-300-px",
                        attrs: { type: "password", placeholder: "Nova Senha" },
                        domProps: { value: _vm.credentials.new_password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.credentials,
                              "new_password",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.error &&
                (_vm.error.status == 401 || _vm.error.status == 403)
                  ? _c(
                      "div",
                      { staticClass: "ukid-login-text-danger uk-text-center" },
                      [_c("p", [_vm._v("Password atual incorreto")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "checkPassword",
                    staticClass:
                      "ukid-login-text-danger uk-text-center uk-hidden"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "O novo password deve ser diferente dos últimos " +
                          _vm._s(_vm.vm.user.totalPasswordNonRepetition) +
                          " utilizados"
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "checkRulePassword",
                    staticClass:
                      "ukid-login-text-danger uk-text-center uk-hidden"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "O novo password deve conter no mínimo " +
                          _vm._s(_vm.vm.user.minPasswordLength) +
                          " caracteres"
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ukid-padding-top-20" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "uk-button uk-button-default uk-button-large ukid-width-300-px ukid-font-size-14",
                      on: { click: _vm.login }
                    },
                    [
                      _c("span", { staticClass: "ukid-white-color" }, [
                        _vm._v("Atualizar")
                      ])
                    ]
                  )
                ])
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ef38239e", { render: render, staticRenderFns: staticRenderFns })
  }
}