var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ModalDebug", {
        attrs: {
          modalId: "modal-debug-" + _vm.id19,
          person: _vm.collection,
          document: _vm.document
        }
      }),
      _vm._v(" "),
      _c("ModalMedia", {
        attrs: {
          id: "media-" + _vm.id19,
          id19: _vm.id19,
          principalCrop: _vm.principalCrop
        }
      }),
      _vm._v(" "),
      _c("ModalWorkList", {
        attrs: {
          modalId: "history-" + _vm.id19,
          workList: _vm.workList,
          id19: _vm.id19
        }
      }),
      _vm._v(" "),
      !_vm.$route.query.hasOwnProperty("debug")
        ? _c(
            "div",
            {
              class:
                "uk-card uk-card-default uk-card-bod ukid-width-" +
                _vm.filters.imageSize +
                "-px"
            },
            [
              _c(
                "div",
                { staticClass: "ukid-padding-5px", attrs: { align: "center" } },
                [
                  _c(
                    "table",
                    {
                      staticClass: "ukid-font-size-17 ukid-cursor-pointer",
                      attrs: { width: _vm.filters.imageSize - 40 }
                    },
                    [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", { attrs: { align: "left" } }, [
                            _c("input", {
                              staticClass: "uk-checkbox",
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: _vm.id19,
                                checked: _vm.selectedForUnion.includes(_vm.id19)
                              },
                              on: { change: _vm.handleCheckboxChange }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ukid-circular-person-edit ukid-label-alert-color"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "#media-" + _vm.id19,
                                      "uk-tooltip": "",
                                      "uk-toggle": ""
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "ukid-circular-portrait",
                                      attrs: {
                                        src:
                                          "data:image/png;base64," +
                                          _vm.principalCrop.base64Crop
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-card-body" }, [
                    _c("div", { staticClass: "custom-row" }, [
                      _c("div", { staticClass: "custom-column" }, [
                        _c("div", { staticClass: "custom-label-value" }, [
                          _c("label", [_vm._v("Captura Inicial:")]),
                          _vm._v(" "),
                          _vm.id19
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      new Date(_vm.firstDetect.timestamp),
                                      "DD/MM/YYYY"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm.age
                          ? _c("div", { staticClass: "custom-label-value" }, [
                              _c("label", [_vm._v("Idade:")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.age.attributeValue !== "NaN"
                                      ? _vm.age.attributeValue
                                      : "Desconhecido"
                                  )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.gender
                          ? _c("div", { staticClass: "custom-label-value" }, [
                              _c("label", [_vm._v("Gênero:")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.gender.attributeValue !== "NaN"
                                      ? _vm.gender.attributeValue
                                      : "Desconhecido"
                                  )
                                )
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "custom-column" }, [
                        _vm.id19
                          ? _c("div", { staticClass: "custom-label-value" }, [
                              _c("label", [_vm._v("Id19:")]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.id19))])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "custom-column" }, [
                        _vm.age
                          ? _c("div", { staticClass: "custom-label-value" }, [
                              _c("label", [_vm._v("Qtt Capturas:")]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.age.qttDetect))])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "custom-column" }, [
                        _vm.age
                          ? _c("div", { staticClass: "custom-label-value" }, [
                              _c("label", [_vm._v("Qtt Dias:")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.person.personV4Collection.principalStore
                                      .sumDetect
                                  )
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-footer" }, [
                    _vm.loggedUser
                      ? _c("div", { staticClass: "custom-icon-container" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "#modal-debug-" + _vm.id19,
                                "uk-toggle": ""
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "custom-icon",
                                attrs: {
                                  title: "Verificar Dados",
                                  "uk-tooltip": "",
                                  "uk-icon": "icon:hashtag;ratio:1.2"
                                }
                              })
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loggedUser
                      ? _c(
                          "div",
                          { staticClass: "custom-icon-container" },
                          [
                            _c("UserIcon", {
                              attrs: {
                                id19: _vm.id19,
                                work: _vm.workList.length > 0
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4041f742", { render: render, staticRenderFns: staticRenderFns })
  }
}