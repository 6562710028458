<script type="text/javascript">

    import UIkit from 'uikit'

    import App from '../../app.json'

    export default {

        components: {
        },

        data() {

            return {

                App: App,
                searchParam: '',
                pagination: {
                    last_page: 0
                },                
                registers: {},
                interval: {},                
                documentNumber: 0,
                page: 1

            }

        },

        mounted() {

            this.initProcess()

        },

        methods: {

            initProcess() {

                var _ = this

                clearInterval(this.interval)

                UIkit.modal.prompt('Sala de Conferência', '', { 

                    bgClose: false, 
                    escClose: false, 
                    labels: {
                        ok: 'Ok',
                        cancel: 'Cancelar'
                    } 

                }).then(
                    
                    function(documentNumber) {

                        _.documentNumber = documentNumber

                        if (documentNumber === null) {

                            location.href = '/?#/home'

                            return

                        }

                        if (documentNumber == '') {

                            UIkit.modal.alert('Necessário um número de documento para acompanhar as capturas da conference call').then(function() {
                                
                                _.initProcess()

                            })

                            return

                        }

                        _.$http.get(

                            App.host.concat(App.existsEndpoint).concat(documentNumber) 

                        ).then(response => {

                            if (response.body || response.body == 'true') {

                                _.interval = setInterval(function() {

                                    _.searchParam = documentNumber

                                    _.list()

                                }, App.layout.call.interval)

                            } else {

                                UIkit.modal.alert('Número de documento não encontrado').then(function() {
                                    
                                    _.initProcess()

                                })

                            }
                                    
                        }).catch(function(err){
                            
                            console.info(err)

                            UIkit.modal.alert(App.messages.errorTitle.concat(App.messages.listError))

                        }) 

                    }

                )
            
            },

            list(page) {

                if (page !== undefined) {
                
                    this.page = page

                }

                this.$http.get(

                    App.host.concat(App.listLiveWebCamSessionsEndpoint) 
                    + '?pageNum=' + (page === undefined ? 1 : page)
                    + '&perPage=' + 4
                    + '&param=' + this.searchParam

                ).then(response => {

                    this.registers = response.body.data
                    this.pagination = response.body
                                        
                }).catch( function(err){
                        
                    UIkit.modal.alert(err.body.description)

                }) 

            },   

            del() {

                let _ = this

                this.$http.delete(App.host.concat(App.clearLiveWebCamSessionsEndpoint).concat(_.documentNumber)).then(response => {

                    _.searchParam = _.documentNumber

                    _.initProcess()

                }).catch(function(err) {
                        
                    UIkit.modal.alert(err.body.description)

                })    
            
            },            

            finalize() {

                try {

                    this.clearInterval()

                    UIkit.modal.alert('O processo de acompanhamento das imagens coletadas na conference call foi finalizado. Os dados serão mantidos até que você exclua o processo')
                    
                } catch (err) {

                }

            },

            clearInterval() {

                for (var i=1; i < 99999; i++) {

                    window.clearInterval(i);
                    
                }

            }

        }

    }

</script>

<template id="call-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>

        <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
            <div class="uk-card-header ukid-supress-border-bottom">
                <div class="uk-grid-small uk-flex-middle ukid-min-height-60" uk-grid>
                    <div class="uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-tuscan-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:desktop;ratio:4.5"></span> 
                    </div>
                    <div class="uk-width-expand ukid-margin-top-30">
                        <span class="ukid-font-size-20 ukid-margin-top-30 uk-visible@s">Demo CALL</span>
                        <span class="ukid-font-size-14 ukid-margin-top-30 uk-hidden@s">Demo CALL</span>
                        <div class="uk-margin-remove-top">
                            <span class="uk-visible@s">                                
                                Módulo de demonstração das imagens coletadas em conference call
                                <br />
                                <div class="uk-label ukid-label-tuscan-color">Sessões geradas no momento: {{pagination.databaseCount}}</div>
                            </span>  
                            <span class="uk-hidden@s ukid-font-size-11">                                
                                Módulo de demonstração das imagens coletadas em conference call
                                <br />
                                <div class="uk-label ukid-label-tuscan-color ukid-font-size-10">Sessões neste momento: {{pagination.databaseCount}}</div>
                            </span>                            
                            <button v-show="registers.length" @click="del()" type="button" class="uk-button uk-button-default uk-button-large uk-align-right uk-visible@m">                                
                                <span class="ukid-icon" uk-icon="icon:trash;ratio:1.2"></span> 
                                Excluir Processo
                            </button>                    
                            <button v-show="registers.length" @click="finalize()" type="button" class="uk-button uk-button-default uk-button-large uk-align-right uk-visible@m">
                                <span class="ukid-icon" uk-icon="icon:upload;ratio:1.3"></span> 
                                Finalizar Processo
                            </button>
                        </div>
                    </div>
                </div>
                <div v-show="registers.length" class="uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-16">
                    <a @click="del()">
                        <span class="uk-margin-small-right uk-icon" uk-icon="trash"></span>
                        Excluir Processo
                    </a>                                
                </div>  
                <div v-show="registers.length" class="uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-15 ukid-font-size-16">
                    <a @click="finalize()">
                        <span class="uk-margin-small-right uk-icon" uk-icon="icon:upload;ratio:1.2"></span>
                        Finaliz Processo
                    </a>
                </div>                        
            </div>
        
            <div class="uk-card-footer ukid-supress-border-top"> 
                <div class="uk-flex uk-flex-center">
                    <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
                        <div v-show="!registers.length" class="uk-alert-primary ukid-magin-left-35 ukid-margin-top-50 ukid-label-tuscan-color" uk-alert>                            
                            <p class="ukid-white-color">
                                <span class="uk-margin-small-right uk-icon" uk-icon="warning"></span>
                                {{App.education.noSession}}
                            </p>
                        </div>

                        <div  v-for="session in registers" :key="session._id" class="ukid-width-300-px">

                            <div :id="'modal-media-image-' + session._id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="ukid-light-blue-color uk-flex uk-flex-center uk-modal-dialog uk-width-auto uk-margin-auto-vertical ukid-photo-ead-effect">
                                    <button class="uk-modal-close-outside" type="button" uk-close></button>
                                    <img :src="'data:image/png;base64,' + session.photo" alt="">
                                </div>
                            </div>                            
                            <div class="uk-card uk-card-default uk-card-body ukid-width-300-px">
                                <div class=" ukid-ead-effect">
                                    <img :src="'data:image/png;base64,' + session.normalized" class="ukid-ead-effect" />
                                </div>                                
                                <div class="uk-flex uk-flex-center uk-label ukid-label-blue-color uk-padding-small ukid-margin-negative-top-20">
                                    <table class="ukid-font-size-10">
                                        <tbody>
                                            <tr>
                                                <th align="right">FFID:</th>
                                                <td align="left">{{session.ffid}}</td>
                                            </tr>
                                            <tr>
                                                <th align="right">N. Docto:</th>
                                                <td align="left">{{session.documentNumber}}</td>
                                            </tr>
                                            <tr>
                                                <th align="right">Score:</th>
                                                <td align="left">{{(session.confidence * 100) | numberFormat('00')}}%</td>
                                            </tr>
                                            <tr>
                                                <th align="right">Verificação:</th>
                                                <td align="left">{{session.verified ? 'Passou' : 'Não Passou'}}</td>
                                            </tr>
                                            <tr>                                                
                                                <td colspan="2">&nbsp;</td>
                                            </tr>                                            
                                            <tr>                                                
                                                <td colspan="2">
                                                    <a :href="'#modal-media-image-' + session._id" class="ukid-white-color" uk-toggle>
                                                        <span class="uk-margin-small-right uk-icon" uk-icon="image"></span>
                                                        Live Foto
                                                    </a>                                                
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                                        
                                </div>
                            </div>                       
                        </div>                
                    </div>                 
                </div>            
            </div>

            <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>
                <paginate
                    v-model="page"
                    :page-count="pagination.last_page"
                    :click-handler="list"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>
            </ul>

            <div class="uk-hidden">
                <video id="ukid-camera-stream"></video>             
                <canvas id="ukid-canvas"></canvas>
            </div>

        </div>
    </div>

</template>