var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uk-margin" },
    [
      _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
        _vm._v("\n        " + _vm._s(_vm.label)),
        _c("br"),
        _vm._v(" "),
        _c("span", {
          staticClass: "ukid-text-alert-color ukid-font-size-9",
          domProps: { innerHTML: _vm._s(_vm.description) }
        })
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.errors.first(_vm.id)
        ? _c("span", { staticClass: "ukid-text-fail-color" }, [
            _vm._v("Selecione o(s) grupo(s)")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "multiselect",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: _vm.required,
              expression: "required"
            }
          ],
          attrs: {
            id: _vm.id,
            name: _vm.name,
            selectLabel: "Enter ou clique para marcar",
            deselectLabel: "Enter ou clique para marcar",
            selectedLabel: "Grupo marcada",
            placeholder: "Você pode pesquisar um grupo",
            label: "description",
            "track-by": "id",
            options: _vm.groupList === null ? [] : _vm.groupList,
            "hide-selected": true,
            "close-on-select": false,
            "clear-on-select": false,
            multiple: true,
            taggable: true
          },
          on: {
            input: _vm.selectFill,
            select: _vm.selectAction,
            remove: _vm.removeAction
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(props) {
                return [
                  props.option.description !== undefined
                    ? _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              props.option.description +
                                " -> " +
                                (props.option.user !== "*"
                                  ? props.option.permission
                                    ? "Leitura e Escrita"
                                    : "Somente Leitura"
                                  : "Padrão do Sistema")
                            ) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ]
              }
            }
          ]),
          model: {
            value: _vm.selectedGroups,
            callback: function($$v) {
              _vm.selectedGroups = $$v
            },
            expression: "selectedGroups"
          }
        },
        [
          _vm._v(" "),
          _c("template", { slot: "noResult" }, [
            _c("span", [
              _vm._v("\n                Nenhum grupo encontrado\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("template", { slot: "noOptions" }, [
            _c("span", [
              _vm._v("\n                Comece a digitar\n            ")
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b9dfa9c", { render: render, staticRenderFns: staticRenderFns })
  }
}