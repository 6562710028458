<style scoped>

    #ukid-invite-person {
        background-color: transparent !important;
    }
    #ukid-card-form {
        border-radius: 20px !important;
        background-color: rgb(56, 53, 70) !important;
        border: 2px solid rgb(65, 174, 247) !important;
    }
    .uk-card-header {
        margin-right: 10px !important;
        border-bottom: 1px solid rgb(48, 49, 46) !important;
    }
    .uk-card-footer {
        margin-right: 10px !important;
    }    
    .ukid-success {
        color: white !important;
    }
    .ukid-check-success {
        color: chartreuse !important;
    }
    .ukid-alert-invite-color {
        color: #E6D350 !important;
    }
    @media screen and (min-width: 650px) {
        .uk-grid {
            margin-left: 0px !important;
        }
    }

</style>

<script type="text/javascript">

    import UIkit from 'uikit'
    import Base64Upload from 'vue-base64-upload'    
    
    import { cm } from '../../js/camera-person.js'

    import App from '../../app.json'

    import { vm } from '../../js/main.js' 
    import { Strings } from '../../js/common.js';
    import { Commons } from '../../js/common.js';

    var findOrientation = require('exif-orientation')

    export default {

        components: {
                    
            Base64Upload: Base64Upload

        },

        data() {

            return {
                App: App,
                searchParam: '',
                pagination: {
                    last_page: 0
                },
                registers: {},
                person: {
                    token: null,
                    documentNumber: null,
                    cpf: null,
                    name: null,
                    photo: null,
                    detection: null,
                    accessStartDatetime: null,
                    accessEndDatetime: null,
                    jokerList: [],
                    users: [],
                    plusFaceOnInvite: false
                },
                detectRequest: {
                    photo: null,
                    gae: true
                },
                detectResponse: {
                    faces: [],
                    orientation: null
                },
                terms: false,
                logoExists: false,
                customer: '_',
                customerInviteLogo: null,
                isValidInvite: true,
                validatedCpf: false,
                imageExif: null,
                imageRotate: null,                
                updateOrientation: false,
                inviteSuccess: false,
                inviteNumber: null,
                token: null,
                classError: 'ukid-form-error uk-input ukid-focus ukid-width-100-percent',
                classNorm: 'uk-input ukid-focus ukid-width-100-percent',
                page: 1,
                vm: vm,
                Commons: Commons,
                Strings: Strings,
                visibleSnaphost: false,
                video: {},
            }

        },

        mounted() {

            let _ = this

            _.token = _.$route.query.token

            _.confirmInvite()
            _.get()

            document.querySelector('input[type=file]').addEventListener('change', function(e){

                var file = e.target.files[0];
                
                findOrientation(file, function(err, orientation) {

                    if (!err) {

                        _.imageRotate = orientation.rotate
                        _.imageExif = orientation.exif

                    }

                })

            })

        },

        methods: {

            confirmInvite() {

                let _ = this

                _.token = _.$route.query.token

                // document.querySelector('#tmpl').setAttribute('href', 'content/css/template_seven_mystic.css')
                document.querySelector('#tmpl').setAttribute('href', 'content/css/template_infinity_blue.css')                
                document.querySelector("#ukid-top").style.display = 'none'
                document.querySelector("#ukid-geometric-movs").style.display = 'none'
                document.querySelector("#ukid-main-footer").style.display = 'none'
                document.querySelector("#ukid-login-logo").style.display = 'none'

                if (!_.token) {

                    UIkit.modal.dialog(App.notification.inviteExpired, { 
                        escClose: false, 
                        bgClose: false 
                    })

                }

            },

            search() {

                let _ = this

                vm.spinnerSearch()
                
                setTimeout(function(){

                    _.list(undefined, 1, App.pagination.itensPerPage)

                    vm.spinnerSearch() 

                }, App.layout.form.interval)

            },

            detect(base64) {
                
                let _ = this

                var modal = null
                
                if (!Commons.isMobile()) 
                    modal = UIkit.modal.dialog(App.notification.loadingImage, { escClose: false, bgClose: false })
                else
                    modal = UIkit.modal.dialog(App.notification.loadingImageMobile, { escClose: false, bgClose: false })
            
                _.person.detection = null
                _.detectRequest.photo = base64

                _.removeDetectFaces()

                this.$http.post(App.host.concat(App.inviteDetectPersonEndpoint), _.detectRequest).then(response => {

                    _.detectResponse = response.data

                    _.detectResponse.faces.forEach(function(object) {

                        let img = Commons.isMobile() ? document.querySelector('.base64-upload img') : document.querySelector('#ukid-face-load')

                        if (object.features.attributes.medmask3.result[0].name === 'none' && object.features.attributes.medmask3.result[0].confidence >= 0.70) {

                            let rect = document.createElement('div')

                            rect.classList.add('rect')

                            rect.style.width  = (object.bbox.right - object.bbox.left) + 'px'
                            rect.style.height = (object.bbox.bottom - object.bbox.top) + 'px'
                            rect.style.left   = (img.offsetLeft + object.bbox.left) + 'px'
                            rect.style.top    = (img.offsetTop  + object.bbox.top) + 'px'

                            if (parseInt(rect.style.width, 10) < 110) {
                                
                                setTimeout(function() { modal.hide() }, 300)

                                UIkit.notification.closeAll()
                                UIkit.notification({
                                    message: '<br /><br /><br />Verifique se não há mais de uma face<br />presente na captura ou aproxime mais<br />a sua face e refaça sua selfie',
                                    status: 'primary',
                                    pos: 'top-center',
                                    timeout: 7000
                                })

                                if (!Commons.isMobile())
                                    _.startCamera()
                                else
                                    _.photoIcon(img)
                        
                                return

                            }

                            rect.style.borderColor = _.colorBox()

                            rect.addEventListener("click", function(event) {

                                document.querySelectorAll('.rect').forEach(function(el) {
                                    
                                    el.style.borderStyle = 'dashed'
                                    el.innerHTML = '<span class="uk-margin-small-right uk-icon" style="color:' + el.style.borderColor + '" uk-icon="trash"></span>'

                                })

                                rect.style.borderStyle = "solid"
                                rect.innerHTML = ''

                                _.person.detection = object.id
                            
                                if (document.querySelectorAll('.rect').length == 1) {

                                    let img = Commons.isMobile() ? document.querySelector('.base64-upload img') : document.querySelector('#ukid-face-load')
                                    
                                    if (Commons.isMobile())
                                        _.onChangeImage(document.querySelectorAll('input[type=file]'))
                                
                                    _.photoIcon(img)

                                }

                            })

                            if (_.detectResponse.faces.length == 1) {

                                _.person.detection = object.id

                            }

                            if (!Commons.isMobile())
                                document.querySelector('.ukid-container').appendChild(rect)
                            else
                                document.querySelector('.ukid-container-mobile').appendChild(rect)

                        } else if ((object.features.attributes.medmask3.result[0].name === 'correct' || object.features.attributes.medmask3.result[0].name === 'incorrect') && object.features.attributes.medmask3.result[0].confidence >= 0.90) {

                            setTimeout(function(){

                                modal.hide()

                                if (!Commons.isMobile())
                                    _.startCamera()
                                else
                                    _.photoIcon(img)

                                UIkit.notification.closeAll()
                                UIkit.notification({
                                    message: '<br /><br /><br />Uso de máscara não permitido para o cadastramento facial<br />Retire sua máscara e refaça a captura',
                                    status: 'primary',
                                    pos: 'top-center',
                                    timeout: 7000
                                })

                            }, 350)

                        }

                    })

                    setTimeout(function() { modal.hide() }, 300)

                }, response => {

                    setTimeout(function() { modal.hide() }, 300)

                    UIkit.modal.alert(response.data)

                })
            
            },
            
            get() {

                let _ = this
                
                _.$http.get(App.host.concat(App.invitePersonEndpoint).concat(encodeURIComponent(_.token))).then(response => {

                    _.person = response.body
                    _.person.detection = null

                    document.querySelector("#ukid-invite-person").style.display = 'block'

                    _.errors.clear()

                    if (!Strings.isNullOrEmpty(_.person.cpf)) {

                        _.isCPF(_.person.cpf)

                    }

                    if (!Commons.isMobile()) {

                        _.startCamera()

                    }

                    _.fillInviteLogo()

                    _.isValidInvite = true
                    
                }).catch(function(err) {

                    UIkit.modal.alert(App.notification.inviteNotFound).then(function(){

                        _.confirmInvite()

                    })

                    _.isValidInvite = false

                })

            },

            fillInviteLogo() {

                let _ = this

                _.customer = _.person.users[0]

                _.$http.get(

                    App.host.concat(App.inviteImageLogoEndpoint).concat(_.customer)

                ).then(response => {

                    this.customerInviteLogo = response.body.data

                    _.imageExists(_.customerInviteLogo)
                                        
                })

            },

            save() {
            
                let _ = this;

                var modal

                let _buttonSave = document.querySelector('.uk-button-save')
                let _img = Commons.isMobile() ? document.querySelector('.base64-upload img') : document.querySelector('#ukid-face-load')

                this.$validator.validateAll().then((result) => {

                    if (Strings.contains(_.customer, 'dhl') && !_.validatedCpf) {
                        
                        document.querySelector('#cpf-input_field').focus()
                        return

                    }

                    if (Strings.isNullOrEmpty(_.person.name)) {
            
                        UIkit.modal.alert('<span class="ukid-font-size-17">Preencha o campo nome</span>')
                        setTimeout(function(){ document.querySelector('#name-input_field').focus() }, 2000)
                        return

                    }

                    if (Strings.isNullOrEmpty(_.person.ffid) && Strings.isNullOrEmpty(_.person.detection)) {
            
                        UIkit.modal.alert('<span class="ukid-font-size-17">Selecione ao menos uma Face</span>')

                        return

                    }

                    if (_.isBase64(_.person.photo) && !Strings.isNullOrEmpty(_.person.ffid) && Strings.isNullOrEmpty(_.person.detection)) {
            
                        UIkit.modal.alert('<span class="ukid-font-size-17">Selecione ao menos uma Face</span>')

                        return
                        
                    }

                    modal = UIkit.modal.dialog(App.notification.inviteEnrollSending, { escClose: false, bgClose: false })

                    _buttonSave.disabled = true
                    _buttonSave.innerHTML = 'Aguarde...'

                    _.$http.post(App.host.concat(App.inviteEnrollPersonEndpoint), _.person).then(response => {
                        
                        _.inviteSuccess = true
                        _buttonSave.disabled  = false
                        _buttonSave.innerHTML = Commons.isMobile() ? 'Enviar Cadastro Facial' : 'Enviar Cadastro'

                        modal.hide()

                    }).catch(function(err) {
                        
                        let error = App.notification.inviteError

                        if (error.trim() === 'Face has already been associated with another document number') {
                            
                            error = App.notification.inviteFaceAlreadyRegistered

                        }

                        UIkit.modal.alert(error)

                        _.inviteSuccess = false
                        
                        _buttonSave.disabled  = false
                        _buttonSave.innerHTML = Commons.isMobile() ? 'Enviar Cadastro Facial' : 'Enviar Cadastro'

                        modal.hide()
                        
                    })

                }).catch((err) => {

                    console.error(err)

                });                

            },
            
            removeDetectFaces() {

                document.querySelectorAll('.rect').forEach(function(el) {
                    
                    el.remove()

                })

            },
            
            photoIcon(img) {
        
                img.setAttribute('id', Commons.isMobile() ? 'facemap' : 'ukid-face-load')
                img.style.width = "97%"
                img.src = '../../content/images/selfie-icon.png'
                img.value = ''

            },

            photoImage(img, base64) {

                img.setAttribute('id', Commons.isMobile() ? 'facemap' : 'ukid-face-load')
                img.setAttribute('class', 'ukid-image-video-capture')                
                img.style.width = "460px"                
                img.src = 'data:image/jpeg;base64,' + base64

            },

            colorBox() {

                let colors = ['#fdecce','#F5EA87','#5CF77E','#3acff8','#7C5EC6','#ff33ff','#b366ff','#bb4462','#b0b036','#f49e0b']
            
                return colors[Math.floor((Math.random() * 10))]

            },

            defaultClass(classes, hasError) {

                if (window.screen.availWidth < 500 && hasError) {

                    classes += ' ukid-form-error uk-form-large ukid-width-100-percent'

                } else if (window.screen.availWidth < 500 && !hasError) {

                    classes += ' ukid-width-100-percent'

                } else if (window.screen.availWidth > 500 && hasError) {

                    classes += 'ukid-form-error uk-form-large ukid-width-100-percent'

                } else if (window.screen.availWidth > 500 && !hasError) {

                    classes += ' ukid-width-100-percent'

                }

                return classes

            },

            scaleImageAndSendToAnalyze(base64) {
                
                let _ = this

                let img = Commons.isMobile() ? document.querySelector('.base64-upload img') : document.querySelector('#ukid-face-load')

                if (!Commons.isMobile())
                    _.photoImage(img, base64)

                img.onload = function() {

                    if (_.updateOrientation) {

                        return

                    }

                    let canvas = document.createElement('canvas')
                    canvas.width  = img.width
                    canvas.height = img.height

                    // alert(img.width + 'x' + img.height)

                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(this, 0, 0, img.width, img.height)

                    if (!Strings.contains(img.src, 'photo-icon')) {

                        let b64 = canvas.toDataURL('image/jpeg')
                        .replace('data:image/png;base64,'  , '')
                        .replace('data:image/jpeg;base64,' , '')
                        .replace('data:image/jpg;base64,'  , '')

                        if (Commons.isMobile() && Commons.isAndroid() && canvas.width > canvas.height) {

                            Commons.rotateBase64Image(canvas.toDataURL('image/jpeg'), canvas, _.imageExif, canvas.width +100, canvas.height +100, function(bImage) {

                                b64 = bImage
                                .replace('data:image/png;base64,'  , '')
                                .replace('data:image/jpeg;base64,' , '')
                                .replace('data:image/jpg;base64,'  , '')
                                
                                if (!_.updateOrientation) {

                                    _.updateOrientation = true
                                    _.person.photo = b64

                                    _.photoImage(img, b64)
                                    _.detect(b64)
                                    
                                }

                            })

                        } else {

                            _.detect(b64)

                            _.person.photo = b64 

                        }

                    }

                }               
                            
            },

            startCamera() {

                let _ = this

                _.person.detection = null

                var scaled = Commons.calculatePhoto(1024, 768, 1024, 768)

                cm.width = scaled.width
                cm.height = scaled.height

                cm.validateBrowser()
                cm.startCameraProcess(function(status) {

                    if (status === 'success') {

                        _.visibleSnaphost = true

                    } else {

                        _.visibleSnaphost = false

                    }

                })
            
            },

            takeSnapshot() {
                
                let _ = this

                try {

                    _.person.detection = null

                    _.removeDetectFaces()

                    cm.takeSnapshot(function(base64){

                        _.visibleSnaphost = false

                        _.scaleImageAndSendToAnalyze(base64)

                        cm.videStop()
                        
                    })
                
                } catch (err) {
                }

            },

            onChangeImage(file) {

                let _ = this

                _.updateOrientation = false

                let img = document.querySelector('.base64-upload img')

                if (!Commons.isMobile() && file.size > 900000) {
                    
                    UIkit.modal.alert('Arquivo muito grande. Máximo 900 KB')

                    setTimeout(function(){

                        _.removeDetectFaces()
                        _.photoIcon(img)

                        _.person.photo = ''

                    }, 400)

                } else {
                    
                    _.removeDetectFaces()
                    _.scaleImageAndSendToAnalyze(file.base64)

                }

            },

            isBase64(param) {

                return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(param)

            },

            imageExists(url) {

                var http = new XMLHttpRequest()

                http.open('HEAD', url, false)
                http.send()

                this.logoExists = (http.status != 404)

                return this.logoExists

            },

            isCPF() {

                let _ = this

                if (!Commons.isCPF(_.person.cpf)) {

                    _.validatedCpf = false

                } else {

                    _.validatedCpf = true

                }

            },

            plusFace() {

                let _ = this

                _.isValidInvite = true
                _.inviteSuccess = false
                _.person.name = null
                _.person.photo = null
                _.person.detection = null
                _.terms = false

                let _buttonSave = document.querySelector('.uk-button-save')
                                
                _buttonSave.disabled = true
                
                if (!Commons.isMobile()) {

                    _.startCamera()

                } else {

                    let img = document.querySelector('.base64-upload img')

                    _.photoIcon(img)

                }

                setTimeout(function() { document.querySelector('#name-input_field').focus() }, 2000)

            }

        }

    }

</script>

<template id="invite-person-template">

    <div id="ukid-invite-person" :class=" { 'uk-align-center uk-child-width-expand@s ukid-background-white-color' : Commons.isMobile(), 'uk-align-center uk-child-width-expand@s ukid-background-white-color' : !Commons.isMobile() }" uk-grid>

        <div v-show="inviteSuccess">
            <div class="uk-position-center">
                <div align="center">
                    <span v-show="!Commons.isMobile()" class="ukid-check-success ukid-margin-bottom-30" uk-icon="icon:happy;ratio:4.6"></span>
                    <span v-show="Commons.isMobile()" class="ukid-check-success ukid-margin-bottom-30" uk-icon="icon:happy;ratio:3.3"></span>
                </div>
                <h3 v-show="!Commons.isMobile()" class="ukid-success ukid-font-size-25">
                    {{person.name}},<br />
                    Obrigado por concluir seu cadastramento facial<br />
                    Conte com a Unike Technologies para facilitar seu dia a dia
                    <br />
                    <br />
                    <span v-if="person.plusFaceOnInvite" class="ukid-font-size-20 ukid-text-alert-color">
                        Esse convite permite cadastro de faces adicionais<br />
                        Compartilhe o link de convite recebido com a pessoa desejada<br />
                        <br />
                        OU
                    </span>
                    <br />
                    <br />
                    <span v-if="person.plusFaceOnInvite" @click="plusFace()" class="ukid-font-size-20 ukid-cursor-pointer ukid-check-success">
                        <span class="ukid-margin-bottom-30" uk-icon="icon:plus;ratio:1.3"></span> 
                        Adicionar Face Agora
                    </span>
                    <br />
                    <br />
                </h3>
                <div v-show="Commons.isMobile()" class="ukid-success ukid-font-size-16 ukid-magin-left-20 ukid-magin-right-20 ukid-width-90-percent">
                    {{person.name}},<br />
                    Obrigado por concluir seu cadastramento facial. Conte com a Unike Technologies para facilitar seu dia a dia
                    <br />
                    <br />
                    <span v-if="person.plusFaceOnInvite" class="ukid-text-alert-color">
                        Esse convite permite<br /> 
                        cadastro de faces adicionais<br />
                        Compartilhe o link de convite<br />
                        recebido com a pessoa desejada<br />
                        <br />
                        OU
                    </span>
                    <br />                    
                    <br />
                    <span v-if="person.plusFaceOnInvite" @click="plusFace()" class="ukid-cursor-pointer ukid-check-success">
                        <span class="ukid-margin-bottom-30" uk-icon="icon:plus;ratio:1.4"></span> 
                        Adicionar Face Agora
                    </span>
                    <br />
                    <br />
                </div>
            </div>  
        </div>
        
        <div id="ukid-card-form" v-show="!inviteSuccess"
             :class="{ 'uk-card uk-card-default uk-container uk-align-center uk-width-1-1 uk-margin-large-top ukid-width-40-percent' : !Commons.isMobile(), 
                       'uk-card uk-card-default uk-container uk-align-center uk-width-1-1 uk-margin-large-top ukid-magin-left-35 ukid-width-90-percent' : Commons.isMobile() }">

            <div class="uk-card-header">
                <div uk-grid>
                    <div>
                        <img id="ukid-logo-un" src="content/images/unike-logo-un.png" width="95" class="ukid-logo-un" />
                    </div>
                    <div :class="{ 'ukid-width-70-percent ukid-font-size-14' : !Commons.isMobile(), 'ukid-width-100-percent ukid-font-size-16' : Commons.isMobile() }">
                        <b>Bem vindo a Unike Technologies</b>
                        <br />
                        Se você chegou até aqui, é porque foi convidado para realizar seu cadastro facial
                    </div>
                </div>
            </div>

            <progress class="uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0" value="0" max="100"></progress> 

            <div :class="{ 'uk-card-footer ukid-supress-border-top' : !Commons.isMobile(), 'uk-card-footer ukid-supress-border-top ukid-card-footer-mobile' : Commons.isMobile() }">

                <form class="ukid-form" @submit.prevent="save">

                    <fieldset class="uk-fieldset ukid-width-100-percent">

                        <div v-show="customer && logoExists" class="uk-flex uk-flex-center ukid-margin-top-10 ukid-margin-bottom-20 uk-margin-small-right">
                            <div align="center">                                
                                <img v-if="!customerInviteLogo" id="ukid-logo-customer" :src="'content/images/invite/' + (customer.includes('dhl') ? 'dhl' : customer) + '.png'" width="100" class="ukid-logo-un uk-margin-small-right" />
                                <img v-if="customerInviteLogo" id="ukid-logo-customer" :src="customerInviteLogo" width="200" class="ukid-logo-un uk-margin-small-right" />
                            </div>
                        </div>

                        <!--div v-show="!person.documentNumber && isValidInvite" class="uk-flex uk-flex-left">
                            <div class="uk-margin ukid-width-90-percent">
                                <span class="uk-label ukid-font-size-13 ukid-primary-label-color uk-visible@s">                                    
                                    {{Strings.contains(customer, 'dhl') ? 'Número de Convite' : 'CPF / Número de Convite'}}
                                    <br />
                                    <div class="uk-alert-primary uk-animation-toggle uk-animation-scale-down" uk-alert>
                                        <a class="uk-alert-close" uk-close></a>
                                        <div class="uk-label ukid-font-size-10 ukid-alert-invite-color">
                                            Você pode ter recebido seu próprio número de CPF 
                                            <br />
                                            ou um Número Aleatório como número de convite.
                                            <br />
                                            Em caso de dúvida, consulte o e-mail
                                            <br />
                                            e/ou mensagem de whatsapp recebida.
                                        </div>
                                    </div>
                                </span>
                                <span class="uk-label ukid-font-size-13 ukid-primary-label-color uk-hidden@s">
                                    {{Strings.contains(customer, 'dhl') ? 'Número de Convite' : 'CPF / Número de Convite'}}
                                    <br />
                                    <div class="uk-alert-primary uk-animation-toggle uk-animation-scale-down" uk-alert>
                                        <a class="uk-alert-close" uk-close></a>                                    
                                        <div class="uk-label ukid-font-size-10 ukid-alert-invite-color">
                                            Você pode ter recebido seu próprio
                                            <br />
                                            número de CPF ou um Número Aleatório
                                            <br />
                                            como número de convite.
                                            <br />
                                            Em caso de dúvida, consulte o e-mail
                                            <br />
                                            e/ou mensagem de whatsapp recebida.
                                        </div>
                                    </div>
                                </span>                                
                                <div class="uk-inline ukid-width-100-percent">
                                    <span class="uk-form-icon" uk-icon="icon:user"></span>
                                    <input
                                        :class="defaultClass('ukid-font-size-18 uk-input uk-form-large', errors.has('invite-input_field'))" 
                                        v-model="inviteNumber"
                                        v-validate="'min:1|max:50|numeric'"
                                        data-vv-as="field"
                                        id="invite-input_field"
                                        name="invite-input_field" 
                                        type="text" 
                                        placeholder="Somente Números">
                                </div>
                                <div class="uk-inline ukid-margin-top-20 ukid-width-100-percent">                                    
                                    <button @click="get()" type="buttom" class="uk-button uk-button-save uk-button-default uk-button-large uk-visible@s ukid-width-100-percent ukid-font-size-20">
                                        Ingressar
                                    </button>
                                    <button @click="get()" type="buttom" class="uk-button uk-button-save uk-button-default uk-button-large uk-hidden@s ukid-width-100-percent ukid-font-size-20">
                                        Ingressar
                                    </button>
                                </div>
                            </div>
                        </div-->

                        <div v-show="!isValidInvite" class="uk-flex uk-flex-center ukid-text-alert-color ukid-font-size-20">
                            Convite já concluído ou expirado!
                        </div>

                        <div v-show="person.documentNumber" class="uk-flex uk-flex-left">
                            <div class="uk-margin ukid-width-70-percent">    
                                <span class="uk-label ukid-font-size-18 ukid-primary-label-color">
                                    {{Strings.contains(customer, 'dhl') ? 'Número de Convite' : 'CPF / Número de Convite'}}
                                </span>
                                <br />
                                <div class="uk-inline ukid-font-size-18 ukid-width-100-percent">
                                    {{person.documentNumber}}
                                </div>
                            </div>
                        </div>

                        <div v-if="customer !== 'porsche'" v-show="person.documentNumber" class="uk-flex uk-flex-left">
                            <div class="uk-margin ukid-width-70-percent">    
                                <span class="uk-label ukid-font-size-18 ukid-primary-label-color">
                                    Período de Acesso
                                </span>
                                <br />
                                <div v-if="person.accessStartDatetime && person.accessEndDatetime" class="uk-inline ukid-font-size-18 ukid-width-100-percent">
                                    {{new Date(person.accessStartDatetime) | dateFormat('DD/MM/YYYY HH:mm:ss')}} até {{new Date(person.accessEndDatetime) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                </div>
                                <div v-else class="uk-inline uk-text-uppercase ukid-font-size-18 ukid-width-100-percent">
                                    Permanente
                                </div>
                            </div>
                        </div>

                        <div v-show="Strings.contains(customer, 'dhl') && person.documentNumber" class="uk-flex uk-flex-left">
                            <div class="uk-margin ukid-width-90-percent">
                                <span class="uk-label ukid-font-size-18 ukid-primary-label-color">
                                    Confirme seu CPF
                                </span>
                                <div class="uk-inline ukid-width-100-percent">
                                    <span v-show="!validatedCpf" class="ukid-text-alert-color">
                                        <b>ATENÇÃO:</b> Insira um CPF Válido
                                        <br /><br />
                                    </span>
                                    <input
                                        @input="isCPF()"
                                        :class="defaultClass('ukid-font-size-18 uk-input uk-form-large', errors.has('cpf-input_field'))" 
                                        v-model="person.cpf"
                                        data-vv-as="field"
                                        id="cpf-input_field"
                                        name="cpf-input_field" 
                                        type="text"
                                        maxlength="11"
                                        placeholder="Somente Números">
                                </div>
                            </div>
                        </div>

                        <div v-show="person.documentNumber" class="uk-flex uk-flex-left">
                            <div class="uk-margin ukid-width-90-percent">
                                <span class="uk-label ukid-font-size-18 ukid-primary-label-color">
                                    Nome
                                </span>
                                <br />
                                <div class="uk-inline ukid-width-100-percent">
                                    <span class="uk-form-icon" uk-icon="icon:user"></span>
                                    <input
                                        :class="defaultClass('ukid-font-size-18 uk-input uk-form-large', errors.has('name-input_field'))" 
                                        v-model="person.name"
                                        v-validate="'required|min:5|max:80'"
                                        data-vv-as="field"
                                        id="name-input_field"
                                        name="name-input_field" 
                                        type="text"
                                        placeholder="Primeiro e segundo nome">
                                </div>
                            </div>
                        </div>

                        <div v-show="person.documentNumber && visibleSnaphost" class="uk-flex uk-flex-left uk-margin ukid-margin-bottom-50">
                            <div class="ukid-video-camera-invite">
                                <span class="ukid-video-text-invite">
                                    Certifique-se que toda a face esteja no frame,<br />não faltando nenhuma das partes<br />Retire sua máscara e óculos caso esteja usando<br />Procure um ambiente com luz suficiente
                                </span>
                                <span class="ukid-video-buttom-invite">
                                    <span @click="takeSnapshot" class="ukid-cursor-pointer" uk-icon="icon:camera;ratio:2.7"></span>
                                </span>
                                <div align="center">
                                    <canvas id="ukid-camera-canvas" class="uk-hidden ukid-image-video-capture" width="440" height="280"></canvas>
                                    <video id="ukid-camera-stream" class="ukid-image-video-capture" crossorigin="anonymous" width="440" height="280"></video>
                                </div>                              
                            </div>
                        </div>

                        <div v-show="!Commons.isMobile() && person.documentNumber && !visibleSnaphost" align="left">
                            <div v-show="!detectRequest.photo" class="ukid-margin-top-15 ukid-font-size-17">
                                Clique na imagem da câmera<br />
                                abaixo para iniciar a captura<br />
                            </div>                            
                            <div class="uk-inline ukid-container">
                                <img id="ukid-face-load" class="ukid-image-video-capture" /> 
                                <canvas class="canvas uk-hidden"></canvas>
                            </div>
                            <br v-show="detectRequest.photo" />
                            <span class="ukid-margin-top-10 ukid-cursor-pointer" @click="startCamera" uk-icon="icon:camera;ratio:2.7"></span>
                        </div>

                        <div v-show="Commons.isMobile() && person.documentNumber && !visibleSnaphost" align="left" class="ukid-cursor-pointer ukid-width-100-percent ukid-margin-top-10">
                            <span class="ukid-font-size-13 ukid-text-alert-color uk-text-uppercase">
                                Ao tirar sua selfie, por gentileza mantenha o seu e telefone em pé (modo retrato) e seu rosto próximo a moldura da câmera.
                                Retire sua máscara, seus óculos, principalmente óculos escuros                                
                            </span>
                            <br />
                            <div class="ukid-margin-top-15 ukid-font-size-13">
                                Clique na imagem para carregar uma foto
                            </div>                            
                            <!--
                            <br />
                            <span class="ukid-font-size-13 ukid-text-alert-color uk-text-uppercase">
                                Se optar por selecionar uma foto existente, utilize uma com o rosto grande e com as indicações citadas acima
                            </span>
                            -->
                            <br />
                            <div class="uk-inline ukid-container-mobile ukid-margin-top-15">
                                <base64-upload
                                    :imageStyle="{
                                        '-moz-opacity': '0.90', 
                                        'filter': 'alpha(opacity=90)',
                                        '-khtml-opacity': '0.90',
                                        'opacity': '0.90',
                                        'border-radius': '3%',
                                        'margin-right': '25px',
                                        'width': '110%'}"
                                    imageSrc="../../content/images/selfie-icon.png"
                                    @change="onChangeImage">
                                </base64-upload>
                                <canvas class="canvas uk-hidden"></canvas>
                            </div>
                        </div>

                        <div id="modal-center" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <button class="uk-modal-close-default" type="button" uk-close></button>

                                <!-- PORSCHE -->
                                <h2 v-if="customer === 'porsche'" class="uk-modal-title">Termo de Ciência de Pândemia</h2>
                                <p v-if="customer === 'porsche'">
                                    Por meio do presente Termo, declaro, para todos os fins de direito, que estou ciente da pandemia de coronavírus (Covid-19) e 
                                    dos riscos de contaminação da mesma, e assim sendo, assumo inteira responsabilidade pelo meu ingresso aos eventos do Campeonato 
                                    “PORSCHE CUP BRASIL” (o “Campeonato”) durante toda a temporada de 2020 e pelo risco de contaminação, isentando os organizadores e 
                                    promotores do Campeonato de toda e qualquer responsabilidade relacionada ao mesmo, e me obrigo, ainda, a cumprir com todas as medidas 
                                    e orientações visando a prevenção, controle e mitigação dos riscos de transmissão da COVID-19, incluindo mas sem se limitar, às orientações 
                                    constantes no Plano Estratégico de Retorno às Atividades Automobilísticas no Brasil emitido pela Confederação Brasileira de Automobilismo – CBA, 
                                    a qual declaro ter lido e compreendido todas as suas disposições.
                                </p>

                                <!-- DHL -->
                                <h2 class="uk-modal-title" v-if="customer.includes('dhl')">Termo de Ciência de Coleta de Face</h2>
                                <p v-if="customer.includes('dhl')">
                                    Estamos coletando sua selfie para lhe oferecer a melhor experiência de autenticação facial e acesso inteligente. 
                                    Ao clicar na caixa de seleção de aceite, você automaticamente concordará com nossa política de privacidade e que a Unike Technologies, operando em nome da DHL, utilize seus dados para esta finalidade
                                    <br /><br />
                                    <a class="ukid-text-alert-color" target="_blank" href="https://docs.unike.tech/uploads/docs/politica_de_privacidade_unike.pdf">Ler nossa política de privacidade</a>
                                </p>

                                <!-- DEFAULT -->
                                <h2 class="uk-modal-title" v-if="!customer.includes('dhl') && customer !== 'porsche'">Termo de Ciência de Coleta de Face</h2>
                                <p v-if="!customer.includes('dhl') && customer !== 'porsche'">
                                    Estamos coletando sua selfie para lhe oferecer a melhor experiência de autenticação facial e acesso inteligente.
                                    Ao clicar na caixa de seleção de aceite, você automaticamente concordará com nossa política de privacidade e que a Unike Technologies utilize seus dados para esta finalidade
                                    <br /><br />
                                    <a class="ukid-text-alert-color" target="_blank" href="https://docs.unike.tech/uploads/docs/politica_de_privacidade_unike.pdf">Ler nossa política de privacidade</a>
                                </p>
                                
                            </div>
                        </div>

                        <div v-show="person.documentNumber" class="ukid-margin-top-20 ukid-font-size-14">
                            <input class="uk-checkbox" type="checkbox" v-model="terms" />
                            Declaro que li os <a class="ukid-text-alert-color" href="#modal-center" uk-toggle>termos e condições</a> e estou de acordo com todos eles
                        </div>

                        <div v-show="person.documentNumber" class="uk-visible@s ukid-margin-bottom-30">
                            <div>
                                <button type="submit" :disabled="!terms" :class="{ 'uk-button uk-margin-center ukid-buttom-disable ukid-margin-top-30 ukid-margin-bottom-30 ukid-font-size-20' : !terms, 'uk-button uk-button-default uk-button-save uk-margin-center ukid-margin-top-30 ukid-margin-bottom-30 ukid-font-size-20' : terms }">
                                    Enviar Cadastro Facial
                                </button>
                            </div>
                        </div>

                        <div v-show="person.documentNumber" class="uk-hidden@s ukid-width-100-percent ukid-margin-top-40 ukid-margin-bottom-30">                          
                            <div>
                                <button type="submit" :disabled="!terms" :class="{ 'uk-button ukid-buttom-disable ukid-font-size-20' : !terms, 'uk-button uk-button-default uk-button-save ukid-font-size-20' : terms }">Enviar Cadastro</button>
                            </div>
                        </div>

                    </fieldset>

                </form>

            </div>
        </div>
    </div>

</template>
