var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    !_vm.user.roles.includes("signer") && _vm.role !== "signer"
      ? _c("div", { staticClass: "uk-margin" }, [
          _c(
            "span",
            { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
            [_vm._v("\n            Threshold de Liveness CFTV\n        ")]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.livenessScore,
                expression: "user.livenessScore"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|decimal:2|between:0.30,1",
                expression: "'required|decimal:2|between:0.30,1'"
              }
            ],
            class: {
              "ukid-form-error uk-input ukid-width-40-percent": _vm.errors.has(
                "liveness-core-select_field"
              ),
              "uk-input ukid-width-40-percent": !_vm.errors.has(
                "liveness-core-select_field"
              )
            },
            attrs: {
              "c-data-vv-as": "field",
              id: "liveness-core-select_field",
              name: "liveness-core-select_field",
              type: "text",
              placeholder: "Entre 0.30 e 1"
            },
            domProps: { value: _vm.user.livenessScore },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "livenessScore", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm.auth.includes("admin")
        ? _c("div", [
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "div",
                {
                  class: {
                    "ukid-width-100-percent": _vm.errors.has(
                      "enabled-input_field"
                    ),
                    "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                      "liveness-web-certificates-enabled-input_field"
                    )
                  }
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.livenessWebCertificatesEnabled,
                          expression: "user.livenessWebCertificatesEnabled"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "true",
                        type: "radio",
                        id: "liveness-web-certificates-enabled-input-yes",
                        name: "liveness-web-certificates-enabled-input_field"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.user.livenessWebCertificatesEnabled,
                          "true"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "livenessWebCertificatesEnabled",
                            "true"
                          )
                        }
                      }
                    }),
                    _vm._v(" SIM\n                    ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.livenessWebCertificatesEnabled,
                          expression: "user.livenessWebCertificatesEnabled"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "false",
                        type: "radio",
                        id: "liveness-web-certificates-enabled-input-no",
                        name: "liveness-web-certificates-enabled-input_field",
                        checked: ""
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.user.livenessWebCertificatesEnabled,
                          "false"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "livenessWebCertificatesEnabled",
                            "false"
                          )
                        }
                      }
                    }),
                    _vm._v(" NÃO\n                    ")
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.auth.includes("admin") &&
      (_vm.user.livenessWebCertificatesEnabled === true ||
        _vm.user.livenessWebCertificatesEnabled === "true")
        ? _c(
            "div",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "tag",
                    fn: function(ref) {
                      var option = ref.option
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "multiselect__tag uk-text-center ukid-width-270-px"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(option) +
                                  "\n                        "
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2345987975
              )
            },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.certificateInformations,
                  multiple: true,
                  taggable: true,
                  selectLabel: "Enter para marcar",
                  deselectLabel: "Enter para desmarcar",
                  selectedLabel: "Email marcado",
                  "tag-placeholder": "Adicionar informação do certificado",
                  placeholder: "Adicionar informação do certificado"
                },
                on: { tag: _vm.addCertificateInfo },
                model: {
                  value: _vm.user.selectedCertificateInformation,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "selectedCertificateInformation", $$v)
                  },
                  expression: "user.selectedCertificateInformation"
                }
              }),
              _vm._v(" "),
              _c("template", { slot: "noResult" }, [
                _c("span", [
                  _vm._v(
                    "\n                        Nenhuma AKI encontrado\n                    "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("template", { slot: "noOptions" }, [
                _c("span", [
                  _vm._v(
                    "\n                        Comece a digitar\n                    "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._v(" "),
              _c("br"),
              _c("br")
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.livenessWebCustomerId,
            expression: "user.livenessWebCustomerId"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "min:10|max:100",
            expression: "'min:10|max:100'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "liveness-web-customer-id-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "liveness-web-customer-id-select_field"
          )
        },
        attrs: {
          disabled: !_vm.auth.includes("admin"),
          "c-data-vv-as": "field",
          id: "liveness-web-customer-id-select_field",
          name: "liveness-web-customer-id-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 1000"
        },
        domProps: { value: _vm.user.livenessWebCustomerId },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "livenessWebCustomerId", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _vm.auth.includes("admin")
        ? _c(
            "button",
            {
              staticClass: "uk-button uk-button-default uk-button-medium",
              attrs: { type: "buttom" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.uuidv4.apply(null, arguments)
                }
              }
            },
            [_vm._v("Gerar")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _vm._m(8),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.livenessWebUrl,
            expression: "user.livenessWebUrl"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "url|min:10|max:1000",
            expression: "'url|min:10|max:1000'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "liveness-web-url-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "liveness-web-url-select_field"
          )
        },
        attrs: {
          "c-data-vv-as": "field",
          id: "liveness-web-url-select_field",
          name: "liveness-web-url-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 1000"
        },
        domProps: { value: _vm.user.livenessWebUrl },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "livenessWebUrl", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(9),
      _vm._v(" "),
      _vm._m(10),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.livenessWebToken,
            expression: "user.livenessWebToken"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "min:10|max:500",
            expression: "'min:10|max:500'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "liveness-web-token-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "liveness-web-token-select_field"
          )
        },
        attrs: {
          "c-data-vv-as": "field",
          id: "liveness-web-token-select_field",
          name: "liveness-web-token-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 500"
        },
        domProps: { value: _vm.user.livenessWebToken },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "livenessWebToken", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-90-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(11),
                    _vm._v(" "),
                    _vm._m(12),
                    _vm._v(" "),
                    !_vm.strings.isNullOrEmpty(_vm.user.username)
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "uk-text-uppercase ukid-text-alert-color ukid-font-color-17"
                          },
                          [
                            _c("br"),
                            _vm._v(" "),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.user.name) +
                                  " - " +
                                  _vm._s(_vm.user.username)
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "uk-label ukid-primary-label-color uk-visible@s"
                        },
                        [_vm._v("Quantidade de Transações")]
                      ),
                      _vm._v(" "),
                      _vm._m(13),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.livenessWebForm.quantity,
                            expression: "livenessWebForm.quantity"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "numeric|min:1|max:1000000",
                            expression: "'numeric|min:1|max:1000000'"
                          }
                        ],
                        class: {
                          "ukid-form-error uk-input ukid-width-70-percent": _vm.errors.has(
                            "livenessWebForm-quantity-select_field"
                          ),
                          "uk-input ukid-width-70-percent": !_vm.errors.has(
                            "livenessWebForm-quantity-select_field"
                          )
                        },
                        attrs: {
                          "c-data-vv-as": "field",
                          id: "livenessWebForm-quantity-select_field",
                          name: "livenessWebForm-quantity-select_field",
                          type: "text",
                          placeholder: "Entre 1 e 1000000"
                        },
                        domProps: { value: _vm.livenessWebForm.quantity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.livenessWebForm,
                              "quantity",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "uk-button uk-button-default uk-button-large",
                        attrs: { type: "buttom" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.transactionsGenerate.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v("Gerar Id's")]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      !_vm.auth.includes("signer")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-90-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s ukid-font-color-17"
                      },
                      [_vm._v("Buscar um id de transação específico")]
                    ),
                    _vm._v(" "),
                    _vm._m(14),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.livenessWebFilter.transactionId,
                          expression: "livenessWebFilter.transactionId"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "min:1|max:150",
                          expression: "'min:1|max:150'"
                        }
                      ],
                      staticClass: "uk-input ukid-width-70-percent",
                      attrs: {
                        "c-data-vv-as": "field",
                        id: "liveness-web-transaction-id-select_field",
                        name: "liveness-web-transaction-id-select_field",
                        type: "text",
                        placeholder: "Mínimo 1, máximo 150"
                      },
                      domProps: { value: _vm.livenessWebFilter.transactionId },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.livenessWebFilter,
                            "transactionId",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "uk-button uk-button-default uk-button-large",
                        attrs: { type: "buttom" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.listLivenessWebTransactions()
                          }
                        }
                      },
                      [_vm._v("Pesquisar")]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.livenessWebTransactions !== null &&
    _vm.livenessWebTransactions.length > 0
      ? _c("div", { staticClass: "ukid-margin-top-20" }, [
          _c(
            "table",
            { staticClass: "uk-table uk-table-small uk-table-striped" },
            [
              _vm._m(15),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.livenessWebTransactions, function(transaction) {
                  return _c("tr", { key: transaction.id }, [
                    _c(
                      "td",
                      { staticClass: "uk-table-expand uk-text-center" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                new Date(transaction.updateTimestamp),
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            ) +
                            "\n                        "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(transaction.transactionId) +
                            " \n                        "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        transaction.status === "SENT"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "ukid-text-success-color uk-text-uppercase"
                              },
                              [_vm._v("Enviado")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "ukid-text-alert-color uk-text-uppercase"
                              },
                              [_vm._v("Não enviado")]
                            ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(transaction.feedback) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.livenessWebTransactionsPagination.last_page > 1,
                  expression: "livenessWebTransactionsPagination.last_page > 1"
                }
              ],
              staticClass:
                "uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16"
            },
            [
              _vm._v("\n            Pagina Atual: "),
              _c("b", { staticClass: "ukid-magin-left-10" }, [
                _vm._v(_vm._s(_vm.livenessWebTransactionsPage))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.livenessWebTransactionsPagination.last_page > 1,
                  expression: "livenessWebTransactionsPagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.livenessWebTransactionsPagination.last_page,
                  "click-handler": _vm.listLivenessWebTransactions,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                },
                model: {
                  value: _vm.livenessWebTransactionsPage,
                  callback: function($$v) {
                    _vm.livenessWebTransactionsPage = $$v
                  },
                  expression: "livenessWebTransactionsPage"
                }
              })
            ],
            1
          )
        ])
      : _c("div", [
          _c("br"),
          _c("br"),
          _vm._v("\n        Nunhum id de transação diponível ainda\n    ")
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n            Threshold de"),
        _c("br"),
        _vm._v("Liveness CFTV\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-margin" }, [
      _c("span", { staticClass: "ukid-primary-label-color" }, [
        _vm._v("\n            LIVENESS WEB\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _vm._v(
          "\n                Habilitar validação de certificado\n                "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
          _vm._v(
            "\n                    Utilize essa opção quando o cliente está em ambiente de DEV                       \n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v(
          "\n                Habilitar Preenchimento de\n                "
        ),
        _c("br"),
        _vm._v(
          "\n                Turno no Cadastro de Pessoa\n                "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
          _vm._v("\n                    Utilize essa opção"),
          _c("br"),
          _vm._v("\n                    quando o cliente está"),
          _c("br"),
          _vm._v(
            "\n                    em ambiente de DEV                       \n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ukid-width-80-percent ukid-font-size-11" },
      [
        _c(
          "span",
          {
            staticClass: "uk-label ukid-primary-label-color ukid-font-size-12"
          },
          [
            _vm._v(
              "\n                    Inserir informações de certificado:                        \n                "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "uk-card uk-card-primary uk-card-small ukid-card-roles"
          },
          [
            _c(
              "div",
              { staticClass: "uk-text-left@s uk-card-body ukid-font-size-15" },
              [
                _c("span", { staticClass: "ukid-label-roles" }, [
                  _vm._v("Exemplo ( Em Única Linha )")
                ]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(
                  "\n                        subject: CN=*.unike.tec, issuer: CN=AlphaSSL CA - SHA256 - G2, fingerprint: A3:37:C1:BF, serialNumber: 646C21\n                    "
                )
              ]
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", { staticClass: "ukid-icon-tuscan-color" }),
        _vm._v("\n            ID do Cliente\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", { staticClass: "ukid-icon-tuscan-color" }),
        _vm._v("\n            ID do Cliente\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:server;ratio:1.2" }
        }),
        _vm._v(
          "\n            Url do seu serviço para resposta de liveness\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:server;ratio:1.2" }
        }),
        _vm._v("\n            Url do seu serviço\n            "),
        _c("br"),
        _vm._v("\n            para resposta de liveness\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:lock;ratio:1.2" }
        }),
        _vm._v("\n            Token ( Prefix: Basic )\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:lock;ratio:1.2" }
        }),
        _vm._v("\n            Token ( Prefix: Basic )\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-color-17"
      },
      [
        _vm._v(
          "\n                            Detectamos que você está logado como admin"
        ),
        _c("br"),
        _vm._v(
          " \n                            Isso possibilita que você gere id's de transação para uso de Liveness WEB\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-color-17"
      },
      [
        _vm._v(
          "                \n                            Detectamos que você\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            está logado como admin\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            Isso possibilita que você gere\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            id's de transação para\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            uso do Liveness WEB\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Quantidade de"), _c("br"), _vm._v("Transações")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-color-17"
      },
      [
        _vm._v(
          "\n                            Buscar um id de transação\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            específico\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "uk-width-small uk-text-center" }, [
          _vm._v(
            "\n                        Data / Id de Transação / Status / Feedback\n                    "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-73193c05", { render: render, staticRenderFns: staticRenderFns })
  }
}