import Vue from 'vue'

import {
    router,
    vm
} from './main.js'

import JwtDecode from 'jwt-decode'

import UIkit from 'uikit'

import VueMoment from 'vue-moment'

import App from '.././app.json'

import { Strings } from '../js/common.js'

Vue.use(VueMoment)

function jwtDecode(token) {

    if (token == null) {

        return {}

    } else {

        return JwtDecode(token)

    }

}

export function hasAny(sourceArr, search) {

    if (search.constructor === Array) {

        return sourceArr.filter(sourceElem => search.find(searchElem => sourceElem === searchElem) != null).length > 0

    } else if (typeof(search) === 'string') {

        return sourceArr.includes(search)

    } else {

        return false

    }

}

export default {

    data() {

        return {

            App: App,

            Strings: Strings,

            user: null,

            roles: {
                admin: 'admin', 
                user: 'user',
                signer: 'signer'
            }

        }

    },

    // Send a request to the login URL and save the returned JWT
    login(context, creds, redirect) {

        let _ = this

        let noCacheNumber = Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))

        context.$http.post(

            `${App.host}${App.loginEndpoint}?nocache=${noCacheNumber}`,
            creds

        ).then(response => {            

            var user = null

            if (response.body !== null) {

                sessionStorage.token = response.body
                context.token = response.body

                user = jwtDecode(response.body)

            } else {

                sessionStorage.token = response.bodyText
                context.token = response.bodyText

                user = jwtDecode(response.bodyText)

            }

            const accessTime = new Date().toLocaleTimeString()

            sessionStorage.user = JSON.stringify(user)
            sessionStorage.accessTime = accessTime

            context.user = user
            context.accessTime = accessTime

            vm.checkSessionStorage()

            // Redirect to a specified route
            if (redirect) {

                vm.sessionUpdate()
        
                router.replace(redirect)
        
                UIkit.notification.closeAll()
        
                document.querySelector('.ukid-footer').style.display = 'block'

            }

        }, response => {

            _.clear ? _.clear(context) : context.auth.clear(context)

            context.error = response
            console.info(context.error)

            UIkit.notification.closeAll()

            if (response.status !== 401) {

                UIkit.notification({ 
                    message: App.notification.connectionError, 
                    status: 'primary', 
                    pos: 'top-center', 
                    timeout: App.notification.timeout
                })

            } else {

                if (context.error.body.code == 'blocked_username') {

                    context.error.body.description = context.error.body.description.replace('suporte@unike.tech', '<a href="mailto:suporte@unike.tech">suporte@unike.tech</a>')
    
                } else {

                    let defaultError = 'Username ou password incorretos.'

                    context.error.body.description = defaultError
                    
                    context.$http.get(`${App.host}${App.remainingAttemptsEndpoint}${creds.username}`).then(response => {

                        if (!Strings.isNullOrEmpty(response.body.total)) {

                            context.error.body.description = `${defaultError} Tentativas restantes ${response.body.total}`

                        }
        
                    })                    

                }                

            }

        })

    },

    // Send a request to the login URL and save the returned JWT
    changePassword(context, creds, redirect, errorCallback) {

        let _ = this

        context.$http.post(App.host.concat(App.chagePasswordUserEndpoint), creds).then(response => {            

            if (response.body.code == "200") {

                creds.username = creds.username
                creds.password = creds.new_password

                router.replace(redirect)

                UIkit.notification.closeAll()
                UIkit.notification( 
                    { 
                        message: App.notification.newPasswordUpdated ,
                        status: null, 
                        pos: 'top-center', 
                        timeout: 9000
                    } 
                )

            }

        }, response => {

            _.clear ? _.clear(context) : context.auth.clear(context)

            context.error = response

            if (errorCallback)
                errorCallback(response)

        })

    },    

    // To log out
    logout(context) {

        context.auth.clear(context)

        router.go('/login')

    },

    clear(context) {

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')

        context.user = null
        context.token = null

        vm.checkSessionStorage()

    },

    // Returns true if one of the roles in the argument is equal to the logged user authorization role
    hasPermission: function (context, roles) {

        var ret = true

        if (!roles || roles.length == 0) {

            ret = true

        } else {

            var user = context.$root.user

            if (!user || !user.auth) {
                
                ret = false

            } else {

                ret = hasAny(roles, user.auth)

            }

        }

        return ret

    },

    jwtDecode: JwtDecode
    
}