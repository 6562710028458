<!--suppress JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection,HtmlUnknownAttribute,HtmlDeprecatedAttribute,HtmlUnknownTag,RegExpRedundantEscape,RegExpSimplifiable,RegExpUnnecessaryNonCapturingGroupSpellCheckingInspection-->

<script>

    import Multiselect from "vue-multiselect"

    import GroupComponent from "../group/group-component";
    import * as events from "events";

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'editor', 'editorConfig', 'user', 'auth', 'role', 'vm'],

        components: {

            Multiselect,
            groupComponent: GroupComponent

        },

        data() {

            return {

                parent_: this.$parent,
                roles: this.app.lists.userTypeCredential.slice()

            }

        },

        created() {

            let _ = this

            if (!_.auth.includes("admin")) {

                 // Remove admin item
                _.roles.splice(0, 1)

            }

        },

        methods: {

            setRole() {

                this.$parent.$parent._data.role = event.target.value

            }

        }
        
    }

</script>

<template id="register-config-template">

    <!--  Configs -->
    <li>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color">Tipo de Regra</span>
            <br />
            <div v-for="role in roles" :key="role.value" class="uk-grid-small ukid-font-size-11" uk-grid>
                <div class="ukid-width-80-percent">
                    <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                        <div class="uk-text-left@s uk-card-body">
                            <span class="ukid-label-roles">{{role.description}}</span>
                            <br /><br />
                            {{role.longDescription}}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <select
                :class="{
                    'ukid-focus uk-select ukid-form-error uk-textarea ukid-width-100-percent ukid-font-size-16': errors.has('roles-select_field'), 
                    'ukid-focus uk-select uk-textarea ukid-width-100-percent ukid-font-size-16': !errors.has('roles-select_field')
                }"
                rows="7"
                @change="setRole"
                v-validate="'required'" 
                data-vv-as="field"
                id="roles-select_field"
                name="roles-select_field">
                <option value="" :selected="role === ''">-</option>
                <option v-for="option in roles" :value="option.value" :key="option.value">{{option.description}}</option>
            </select>
        </div>

        <group-component
            ref="userGroupCpnt"
            :id="'user_group-select_field_1'"
            :name="'user_group-select_field_1'"
            :label="'Grupos'"
            :description="'(Ctrl + Clique) para mais de um grupo'"
            :parent_="parent_"
            :app="app"
            :strings="strings"
            :commons="commons"
            :vm="vm"
            :noDefault="true"
            :entity="user">

        </group-component>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Título de Email Bloqueio de Password</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Título de Email<br />Bloqueio de Password</span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('email-blocked-title_text_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('email-blocked-title_text_field')
                }" 
                v-model="user.emailBlockedPasswordTitle"
                v-validate="'required'"
                c-data-vv-as="field"
                id="email-blocked-title_text_field"
                name="email-blocked-title_text_field"
                type="text"
                placeholder="" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Mensagem de Email Bloqueio de Password</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Mensagem de Email<br />Bloqueio de Password</span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome da instituição<br />
                <b>{username}</b> - Será substituído pela username de acesso da instituição
            </span>
            <br /><br />
            <ckeditor :editor="editor" v-model="user.emailBlockedPasswordMessage" :config="editorConfig"></ckeditor>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Título de Aviso de Expiração de Password</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Título de Aviso<br />de Expiração de Password</span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('email-password-expiration_text_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('email-password-expiration_text_field')
                }" 
                v-model="user.emailWarnBlockedPasswordTitle"
                v-validate="'required'"
                c-data-vv-as="field"
                id="email-password-expiration_text_field"
                name="email-password-expiration_text_field"
                type="text"
                placeholder="" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Mensagem de Aviso de Expiração de Password</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Mensagem de Aviso de<br />Expiração de Password</span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome da instituição<br />
                <b>{days}</b> - Será substituído pelos dias restantes
            </span>
            <br /><br />
            <ckeditor :editor="editor" v-model="user.emailWarnBlockedPasswordMessage" :config="editorConfig"></ckeditor>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Mensagem de Próximidade da Expiração do Password</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Mensagem de Próximidade da<br />Expiração do Password</span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome da instituição
            </span>
            <br /><br />                                        
            <ckeditor :editor="editor" v-model="user.emailExpirationPasswordMessage" :config="editorConfig"></ckeditor>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Threshold de Detecção de Corpos</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Threshold de Detecção<br />de Corpos</span>
            <input
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('body-core-select_field'),
                    'uk-input ukid-width-100-percent': !errors.has('body-core-select_field')
                }"
                v-model="user.bodyScore"
                v-validate="'decimal:2|between:0.80,1'"
                c-data-vv-as="field"
                id="body-core-select_field"
                name="body-core-select_field"
                type="text"
                placeholder="Entre .80 e 1" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Threshold de Detecção Facial ( 1:1 )</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Threshold de Detecção<br />Facial ( 1:1 )</span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('matcherScore-select_field'),
                    'uk-input ukid-width-100-percent': !errors.has('matcherScore-select_field')
                }" 
                v-model="user.matcherScore"
                v-validate="'required|decimal:2|between:0.30,1'"
                c-data-vv-as="field"
                id="matcherScore-select_field"
                name="matcherScore-select_field"
                type="text"
                placeholder="Entre 0.30 e 1" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Threshold de Identificação Facial ( 1:N )</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Threshold de Identificação<br />Facial ( 1:N )</span>
            <input
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('matcherScoreOneToN-select_field'),
                    'uk-input ukid-width-100-percent': !errors.has('matcherScoreOneToN-select_field')
                }"
                v-model="user.matcherScoreOneToN"
                v-validate="'required|decimal:2|between:0.30,1'"
                c-data-vv-as="field"
                id="matcherScoreOneToN-select_field"
                name="matcherScoreOneToN-select_field"
                type="text"
                placeholder="Entre 0.30 e 1" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Threshold de Identificação Facial de Pessoas Desconhecidas ( 1:N )</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Threshold de Identificação Facial<br />de Pessoas Desconhecidas ( 1:N )</span>
            <input
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('unknownMatcherScoreOneToN-select_field'),
                    'uk-input ukid-width-100-percent': !errors.has('unknownMatcherScoreOneToN-select_field')
                }"
                v-model="user.unknownMatcherScoreOneToN"
                v-validate="'required|decimal:2|between:0.30,1'"
                c-data-vv-as="field"
                id="unknownMatcherScoreOneToN-select_field"
                name="unknownMatcherScoreOneToN-select_field"
                type="text"
                placeholder="Entre 0.30 e 1" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Threshold de Temperatura Alta</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Threshold de<br />Temperatura Alta</span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('temperatureHighScore-core-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('temperatureHighScore-core-select_field')
                }" 
                v-model="user.temperatureHighScore"
                v-validate="'required|decimal:2|between:35.7,43.0'"
                c-data-vv-as="field"
                id="temperatureHighScore-core-select_field"
                name="temperatureHighScore-core-select_field"
                type="text"
                placeholder="Entre 35.7 e 43.0" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">Threshold de Temperatura de Atenção</span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">Threshold de<br />Temperatura de Atenção</span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('temperatureAttentionScore-core-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('temperatureAttentionScore-core-select_field')
                }" 
                v-model="user.temperatureAttentionScore"
                v-validate="'required|decimal:2|between:35.7,43.0'"
                c-data-vv-as="field"
                id="temperatureAttentionScore-core-select_field"
                name="temperatureAttentionScore-core-select_field"
                type="text"
                placeholder="Entre 35.7 e 43.0" />
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Consulta<br />em Minha BLC
                        </span>
                        <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                            Habilitar Consulta em Minha BLC
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'),
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.userBlcEnabled" value="true" class="uk-radio" type="radio" id="user-blc-enabled-input_yes_field" name="user-blc-enabled-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.userBlcEnabled" value="false" class="uk-radio" type="radio" id="user-blc-enabled-input_no_field" name="user-blc-enabled-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="user && auth.includes('admin')">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Consulta<br />em BLC Geral
                        </span>
                        <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                            Habilitar Consulta em BLC Geral
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'),
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.coreBlcEnabled" value="true" class="uk-radio" type="radio" id="core-blc-enabled-input_yes_field" name="core-blc-enabled-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.coreBlcEnabled" value="false" class="uk-radio" type="radio" id="core-blc-enabled-input_no_field" name="core-blc-enabled-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Herdar Registros<br />do Proprietário
                        </span>
                        <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                            Herdar Registros do Proprietário
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.inheritOwner" value="true" class="uk-radio" type="radio" id="inherit-owner-type-input_yes_field" name="inherit-owner-type-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.inheritOwner" value="false" class="uk-radio" type="radio" id="inherit-owner-type-input_no_field" name="inherit-owner-type-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Herdar Registros<br />dos Filhos
                        </span>
                        <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                            Herdar Registros dos Filhos
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.inheritChildren" value="true" class="uk-radio" type="radio" id="inherit-children-type-input_yes_field" name="inherit-children-type-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.inheritChildren" value="false" class="uk-radio" type="radio" id="inherit-children-type-input_no_field" name="inherit-children-type-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="user && auth.includes('admin')">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar B.I
                            <span class="ukid-icon-purple-color">
                                <br />Precisa estar habilitado<br />nas propriedades do servidor
                            </span>
                        </span>
                        <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                            Habilitar B.I
                            <span class="ukid-icon-purple-color ukid-font-size-10">
                                <br />Precisa estar habilitado nas propriedades do servidor 
                            </span>
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.biIntegration" value="true" class="uk-radio" type="radio" id="bi-type-input_yes_field" name="bi-type-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.biIntegration" value="false" class="uk-radio" type="radio" id="bi-type-input-no_field" name="bi-type-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="user && auth.includes('admin')">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Integrações Externas
                            <span class="ukid-icon-purple-color ukid-font-size-10">
                                <br />Precisa estar habilitado<br />nas propriedades do servidor
                            </span>
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Habilitar Integrações Externas
                            <span class="ukid-icon-purple-color ukid-font-size-10">
                                <br />Precisa estar habilitado nas propriedades do servidor 
                            </span>
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.externalIntegration" value="true" class="uk-radio" type="radio" id="external-type-input-yes_field" name="external-type-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.externalIntegration" value="false" class="uk-radio" type="radio" id="external-type-input-no_field" name="external-type-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="user && auth.includes('admin')">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Edição de Credenciais
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Habilitar Edição de Credenciais
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.credentialEditing" value="true" class="uk-radio" type="radio" id="credential-editing-input-yes_field" name="credential-editing-type-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.credentialEditing" value="false" class="uk-radio" type="radio" id="credential-editing-type-input-no_field" name="credential-editing-type-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Salvar Pessoas Desconhecidas<br />
                            na Identificação
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Salvar Pessoas Desconhecidas na Identificação
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.saveUnknownPerson" value="true" class="uk-radio" type="radio" id="save-unknown-input-yes_field" name="save-unknown-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.saveUnknownPerson" value="false" class="uk-radio" type="radio" id="save-unknown-input-no_field" name="save-unknown-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Faces<br />
                            Adicionais no Convite
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Habilitar Faces Adicionais no Convite
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.plusFaceOnInvite" value="true" class="uk-radio" type="radio" id="plus-face-input-yes_field" name="plus-face-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.plusFaceOnInvite" value="false" class="uk-radio" type="radio" id="plus-face-input-no_field" name="plus-face-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Mostrar Face<br />
                            do Cadastro na Detecção
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Mostrar Face do Cadastro na Detecção
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                <label>
                                    <input v-model="user.showRegistrationImageOnDetection" value="true" class="uk-radio" type="radio" id="show-registration-image-input-yes_field" name="show-registration-image-detection-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.showRegistrationImageOnDetection" value="false" class="uk-radio" type="radio" id="show-registration-image-input-no_field" name="show-registration-image-detection-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="user && auth.includes('admin')">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Detecção de Genero, Idade e Emoções
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Detecção de Genero,<br />Idade e Emoções
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('gae-input_field')}">
                                <label>
                                    <input v-model="user.gae" value="true" class="uk-radio" type="radio" id="gae-type-input-yes_field" name="gae-type-input_field" checked> SIM
                                </label>
                                <label>
                                    <input v-model="user.gae" value="false" class="uk-radio" type="radio" id="gae-type-input-no_field" name="gae-type-input_field"> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="user && auth.includes('admin')">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Detecção de Máscara
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Detecção de Máscara
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('mask-input_field')}">
                                <label>
                                    <input v-model="user.mask" value="true" class="uk-radio" type="radio" id="mask-type-input-yes_field" name="mask-type-input_field"> SIM
                                </label>
                                <label>
                                    <input v-model="user.mask" value="false" class="uk-radio" type="radio" id="mask-type-input-no_field" name="mask-type-input_field" checked> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Habilitar Deduplicação de Face no Cadastro
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Deduplicação<br />de Face no Cadastro
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('deduplication-input_field')}">
                                <label>
                                    <input v-model="user.deduplication" value="true" class="uk-radio" type="radio" id="deduplication-type-input-yes_field" name="deduplication-type-input_field" checked> SIM
                                </label>
                                <label>
                                    <input v-model="user.deduplication" value="false" class="uk-radio" type="radio" id="deduplication-type-input-no_field" name="deduplication-type-input_field"> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <div class="ukid-width-80-percent">
                <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                    <div class="uk-text-left@s uk-card-body">
                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                            Habilitar Debug Facial nas Identificações
                        </span>
                        <span class="uk-label ukid-primary-label-color uk-hidden@s">
                            Habilitar Debug Facial<br />nas Identificações
                        </span>
                        <br /><br />
                        <div class="uk-inline ukid-width-100-percent">
                            <div :class="{
                                    'ukid-width-100-percent': errors.has('enabled-input_field'),
                                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('facialDebug-input_field')}">
                                <label>
                                    <input v-model="user.facialDebug" value="true" class="uk-radio" type="radio" id="facialDebug-type-input-yes_field" name="facialDebug-type-input_field" checked> SIM
                                </label>
                                <label>
                                    <input v-model="user.facialDebug" value="false" class="uk-radio" type="radio" id="facialDebug-type-input-no_field" name="facialDebug-type-input_field"> NÃO
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </li>

</template>
