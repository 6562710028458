var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "uk-align-center uk-child-width-expand@s ukid-background-white-color": _vm.Commons.isMobile(),
        "uk-align-center uk-child-width-expand@s ukid-background-white-color": !_vm.Commons.isMobile()
      },
      attrs: { id: "ukid-invite-person", "uk-grid": "" }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.inviteSuccess,
              expression: "inviteSuccess"
            }
          ]
        },
        [
          _c("div", { staticClass: "uk-position-center" }, [
            _c("div", { attrs: { align: "center" } }, [
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.Commons.isMobile(),
                    expression: "!Commons.isMobile()"
                  }
                ],
                staticClass: "ukid-check-success ukid-margin-bottom-30",
                attrs: { "uk-icon": "icon:happy;ratio:4.6" }
              }),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.Commons.isMobile(),
                    expression: "Commons.isMobile()"
                  }
                ],
                staticClass: "ukid-check-success ukid-margin-bottom-30",
                attrs: { "uk-icon": "icon:happy;ratio:3.3" }
              })
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.Commons.isMobile(),
                    expression: "!Commons.isMobile()"
                  }
                ],
                staticClass: "ukid-success ukid-font-size-25"
              },
              [
                _vm._v("\n                " + _vm._s(_vm.person.name) + ","),
                _c("br"),
                _vm._v(
                  "\n                Obrigado por concluir seu cadastramento facial"
                ),
                _c("br"),
                _vm._v(
                  "\n                Conte com a Unike Technologies para facilitar seu dia a dia\n                "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.person.plusFaceOnInvite
                  ? _c(
                      "span",
                      {
                        staticClass: "ukid-font-size-20 ukid-text-alert-color"
                      },
                      [
                        _vm._v(
                          "\n                    Esse convite permite cadastro de faces adicionais"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    Compartilhe o link de convite recebido com a pessoa desejada"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                    OU\n                ")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.person.plusFaceOnInvite
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-20 ukid-cursor-pointer ukid-check-success",
                        on: {
                          click: function($event) {
                            return _vm.plusFace()
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "ukid-margin-bottom-30",
                          attrs: { "uk-icon": "icon:plus;ratio:1.3" }
                        }),
                        _vm._v(
                          " \n                    Adicionar Face Agora\n                "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.Commons.isMobile(),
                    expression: "Commons.isMobile()"
                  }
                ],
                staticClass:
                  "ukid-success ukid-font-size-16 ukid-magin-left-20 ukid-magin-right-20 ukid-width-90-percent"
              },
              [
                _vm._v("\n                " + _vm._s(_vm.person.name) + ","),
                _c("br"),
                _vm._v(
                  "\n                Obrigado por concluir seu cadastramento facial. Conte com a Unike Technologies para facilitar seu dia a dia\n                "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.person.plusFaceOnInvite
                  ? _c("span", { staticClass: "ukid-text-alert-color" }, [
                      _vm._v("\n                    Esse convite permite"),
                      _c("br"),
                      _vm._v(
                        " \n                    cadastro de faces adicionais"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                    Compartilhe o link de convite"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                    recebido com a pessoa desejada"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v("\n                    OU\n                ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.person.plusFaceOnInvite
                  ? _c(
                      "span",
                      {
                        staticClass: "ukid-cursor-pointer ukid-check-success",
                        on: {
                          click: function($event) {
                            return _vm.plusFace()
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "ukid-margin-bottom-30",
                          attrs: { "uk-icon": "icon:plus;ratio:1.4" }
                        }),
                        _vm._v(
                          " \n                    Adicionar Face Agora\n                "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br")
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.inviteSuccess,
              expression: "!inviteSuccess"
            }
          ],
          class: {
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 uk-margin-large-top ukid-width-40-percent": !_vm.Commons.isMobile(),
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 uk-margin-large-top ukid-magin-left-35 ukid-width-90-percent": _vm.Commons.isMobile()
          },
          attrs: { id: "ukid-card-form" }
        },
        [
          _c("div", { staticClass: "uk-card-header" }, [
            _c("div", { attrs: { "uk-grid": "" } }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "ukid-width-70-percent ukid-font-size-14": !_vm.Commons.isMobile(),
                    "ukid-width-100-percent ukid-font-size-16": _vm.Commons.isMobile()
                  }
                },
                [
                  _c("b", [_vm._v("Bem vindo a Unike Technologies")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n                    Se você chegou até aqui, é porque foi convidado para realizar seu cadastro facial\n                "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("progress", {
            staticClass:
              "uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0",
            attrs: { value: "0", max: "100" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "uk-card-footer ukid-supress-border-top": !_vm.Commons.isMobile(),
                "uk-card-footer ukid-supress-border-top ukid-card-footer-mobile": _vm.Commons.isMobile()
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "ukid-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "uk-fieldset ukid-width-100-percent" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.customer && _vm.logoExists,
                              expression: "customer && logoExists"
                            }
                          ],
                          staticClass:
                            "uk-flex uk-flex-center ukid-margin-top-10 ukid-margin-bottom-20 uk-margin-small-right"
                        },
                        [
                          _c("div", { attrs: { align: "center" } }, [
                            !_vm.customerInviteLogo
                              ? _c("img", {
                                  staticClass:
                                    "ukid-logo-un uk-margin-small-right",
                                  attrs: {
                                    id: "ukid-logo-customer",
                                    src:
                                      "content/images/invite/" +
                                      (_vm.customer.includes("dhl")
                                        ? "dhl"
                                        : _vm.customer) +
                                      ".png",
                                    width: "100"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customerInviteLogo
                              ? _c("img", {
                                  staticClass:
                                    "ukid-logo-un uk-margin-small-right",
                                  attrs: {
                                    id: "ukid-logo-customer",
                                    src: _vm.customerInviteLogo,
                                    width: "200"
                                  }
                                })
                              : _vm._e()
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isValidInvite,
                              expression: "!isValidInvite"
                            }
                          ],
                          staticClass:
                            "uk-flex uk-flex-center ukid-text-alert-color ukid-font-size-20"
                        },
                        [
                          _vm._v(
                            "\n                        Convite já concluído ou expirado!\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.person.documentNumber,
                              expression: "person.documentNumber"
                            }
                          ],
                          staticClass: "uk-flex uk-flex-left"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-margin ukid-width-70-percent" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-font-size-18 ukid-primary-label-color"
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.Strings.contains(
                                          _vm.customer,
                                          "dhl"
                                        )
                                          ? "Número de Convite"
                                          : "CPF / Número de Convite"
                                      ) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-inline ukid-font-size-18 ukid-width-100-percent"
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.person.documentNumber) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.customer !== "porsche"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.person.documentNumber,
                                  expression: "person.documentNumber"
                                }
                              ],
                              staticClass: "uk-flex uk-flex-left"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "uk-margin ukid-width-70-percent"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "uk-label ukid-font-size-18 ukid-primary-label-color"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Período de Acesso\n                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _vm.person.accessStartDatetime &&
                                  _vm.person.accessEndDatetime
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uk-inline ukid-font-size-18 ukid-width-100-percent"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(
                                                    _vm.person.accessStartDatetime
                                                  ),
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )
                                              ) +
                                              " até " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(
                                                    _vm.person.accessEndDatetime
                                                  ),
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )
                                              ) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uk-inline uk-text-uppercase ukid-font-size-18 ukid-width-100-percent"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Permanente\n                            "
                                          )
                                        ]
                                      )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.Strings.contains(_vm.customer, "dhl") &&
                                _vm.person.documentNumber,
                              expression:
                                "Strings.contains(customer, 'dhl') && person.documentNumber"
                            }
                          ],
                          staticClass: "uk-flex uk-flex-left"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-margin ukid-width-90-percent" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-font-size-18 ukid-primary-label-color"
                                },
                                [
                                  _vm._v(
                                    "\n                                Confirme seu CPF\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-inline ukid-width-100-percent"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.validatedCpf,
                                          expression: "!validatedCpf"
                                        }
                                      ],
                                      staticClass: "ukid-text-alert-color"
                                    },
                                    [
                                      _c("b", [_vm._v("ATENÇÃO:")]),
                                      _vm._v(
                                        " Insira um CPF Válido\n                                    "
                                      ),
                                      _c("br"),
                                      _c("br")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.person.cpf,
                                        expression: "person.cpf"
                                      }
                                    ],
                                    class: _vm.defaultClass(
                                      "ukid-font-size-18 uk-input uk-form-large",
                                      _vm.errors.has("cpf-input_field")
                                    ),
                                    attrs: {
                                      "data-vv-as": "field",
                                      id: "cpf-input_field",
                                      name: "cpf-input_field",
                                      type: "text",
                                      maxlength: "11",
                                      placeholder: "Somente Números"
                                    },
                                    domProps: { value: _vm.person.cpf },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.person,
                                            "cpf",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.isCPF()
                                        }
                                      ]
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.person.documentNumber,
                              expression: "person.documentNumber"
                            }
                          ],
                          staticClass: "uk-flex uk-flex-left"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-margin ukid-width-90-percent" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-font-size-18 ukid-primary-label-color"
                                },
                                [
                                  _vm._v(
                                    "\n                                Nome\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-inline ukid-width-100-percent"
                                },
                                [
                                  _c("span", {
                                    staticClass: "uk-form-icon",
                                    attrs: { "uk-icon": "icon:user" }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.person.name,
                                        expression: "person.name"
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|min:5|max:80",
                                        expression: "'required|min:5|max:80'"
                                      }
                                    ],
                                    class: _vm.defaultClass(
                                      "ukid-font-size-18 uk-input uk-form-large",
                                      _vm.errors.has("name-input_field")
                                    ),
                                    attrs: {
                                      "data-vv-as": "field",
                                      id: "name-input_field",
                                      name: "name-input_field",
                                      type: "text",
                                      placeholder: "Primeiro e segundo nome"
                                    },
                                    domProps: { value: _vm.person.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.person,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.person.documentNumber &&
                                _vm.visibleSnaphost,
                              expression:
                                "person.documentNumber && visibleSnaphost"
                            }
                          ],
                          staticClass:
                            "uk-flex uk-flex-left uk-margin ukid-margin-bottom-50"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ukid-video-camera-invite" },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ukid-video-buttom-invite" },
                                [
                                  _c("span", {
                                    staticClass: "ukid-cursor-pointer",
                                    attrs: {
                                      "uk-icon": "icon:camera;ratio:2.7"
                                    },
                                    on: { click: _vm.takeSnapshot }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(2)
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.Commons.isMobile() &&
                                _vm.person.documentNumber &&
                                !_vm.visibleSnaphost,
                              expression:
                                "!Commons.isMobile() && person.documentNumber && !visibleSnaphost"
                            }
                          ],
                          attrs: { align: "left" }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.detectRequest.photo,
                                  expression: "!detectRequest.photo"
                                }
                              ],
                              staticClass:
                                "ukid-margin-top-15 ukid-font-size-17"
                            },
                            [
                              _vm._v(
                                "\n                            Clique na imagem da câmera"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            abaixo para iniciar a captura"
                              ),
                              _c("br")
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(3),
                          _vm._v(" "),
                          _c("br", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.detectRequest.photo,
                                expression: "detectRequest.photo"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "ukid-margin-top-10 ukid-cursor-pointer",
                            attrs: { "uk-icon": "icon:camera;ratio:2.7" },
                            on: { click: _vm.startCamera }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.Commons.isMobile() &&
                                _vm.person.documentNumber &&
                                !_vm.visibleSnaphost,
                              expression:
                                "Commons.isMobile() && person.documentNumber && !visibleSnaphost"
                            }
                          ],
                          staticClass:
                            "ukid-cursor-pointer ukid-width-100-percent ukid-margin-top-10",
                          attrs: { align: "left" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "ukid-font-size-13 ukid-text-alert-color uk-text-uppercase"
                            },
                            [
                              _vm._v(
                                "\n                            Ao tirar sua selfie, por gentileza mantenha o seu e telefone em pé (modo retrato) e seu rosto próximo a moldura da câmera.\n                            Retire sua máscara, seus óculos, principalmente óculos escuros                                \n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ukid-margin-top-15 ukid-font-size-13"
                            },
                            [
                              _vm._v(
                                "\n                            Clique na imagem para carregar uma foto\n                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline ukid-container-mobile ukid-margin-top-15"
                            },
                            [
                              _c("base64-upload", {
                                attrs: {
                                  imageStyle: {
                                    "-moz-opacity": "0.90",
                                    filter: "alpha(opacity=90)",
                                    "-khtml-opacity": "0.90",
                                    opacity: "0.90",
                                    "border-radius": "3%",
                                    "margin-right": "25px",
                                    width: "110%"
                                  },
                                  imageSrc:
                                    "../../content/images/selfie-icon.png"
                                },
                                on: { change: _vm.onChangeImage }
                              }),
                              _vm._v(" "),
                              _c("canvas", { staticClass: "canvas uk-hidden" })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { id: "modal-center", "uk-modal": "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-modal-dialog uk-modal-body" },
                            [
                              _c("button", {
                                staticClass: "uk-modal-close-default",
                                attrs: { type: "button", "uk-close": "" }
                              }),
                              _vm._v(" "),
                              _vm.customer === "porsche"
                                ? _c("h2", { staticClass: "uk-modal-title" }, [
                                    _vm._v("Termo de Ciência de Pândemia")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.customer === "porsche"
                                ? _c("p", [
                                    _vm._v(
                                      "\n                                Por meio do presente Termo, declaro, para todos os fins de direito, que estou ciente da pandemia de coronavírus (Covid-19) e \n                                dos riscos de contaminação da mesma, e assim sendo, assumo inteira responsabilidade pelo meu ingresso aos eventos do Campeonato \n                                “PORSCHE CUP BRASIL” (o “Campeonato”) durante toda a temporada de 2020 e pelo risco de contaminação, isentando os organizadores e \n                                promotores do Campeonato de toda e qualquer responsabilidade relacionada ao mesmo, e me obrigo, ainda, a cumprir com todas as medidas \n                                e orientações visando a prevenção, controle e mitigação dos riscos de transmissão da COVID-19, incluindo mas sem se limitar, às orientações \n                                constantes no Plano Estratégico de Retorno às Atividades Automobilísticas no Brasil emitido pela Confederação Brasileira de Automobilismo – CBA, \n                                a qual declaro ter lido e compreendido todas as suas disposições.\n                            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.customer.includes("dhl")
                                ? _c("h2", { staticClass: "uk-modal-title" }, [
                                    _vm._v("Termo de Ciência de Coleta de Face")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.customer.includes("dhl")
                                ? _c("p", [
                                    _vm._v(
                                      "\n                                Estamos coletando sua selfie para lhe oferecer a melhor experiência de autenticação facial e acesso inteligente. \n                                Ao clicar na caixa de seleção de aceite, você automaticamente concordará com nossa política de privacidade e que a Unike Technologies, operando em nome da DHL, utilize seus dados para esta finalidade\n                                "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ukid-text-alert-color",
                                        attrs: {
                                          target: "_blank",
                                          href:
                                            "https://docs.unike.tech/uploads/docs/politica_de_privacidade_unike.pdf"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Ler nossa política de privacidade"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.customer.includes("dhl") &&
                              _vm.customer !== "porsche"
                                ? _c("h2", { staticClass: "uk-modal-title" }, [
                                    _vm._v("Termo de Ciência de Coleta de Face")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.customer.includes("dhl") &&
                              _vm.customer !== "porsche"
                                ? _c("p", [
                                    _vm._v(
                                      "\n                                Estamos coletando sua selfie para lhe oferecer a melhor experiência de autenticação facial e acesso inteligente.\n                                Ao clicar na caixa de seleção de aceite, você automaticamente concordará com nossa política de privacidade e que a Unike Technologies utilize seus dados para esta finalidade\n                                "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ukid-text-alert-color",
                                        attrs: {
                                          target: "_blank",
                                          href:
                                            "https://docs.unike.tech/uploads/docs/politica_de_privacidade_unike.pdf"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Ler nossa política de privacidade"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.person.documentNumber,
                              expression: "person.documentNumber"
                            }
                          ],
                          staticClass: "ukid-margin-top-20 ukid-font-size-14"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.terms,
                                expression: "terms"
                              }
                            ],
                            staticClass: "uk-checkbox",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.terms)
                                ? _vm._i(_vm.terms, null) > -1
                                : _vm.terms
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.terms,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.terms = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.terms = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(
                            "\n                        Declaro que li os "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "ukid-text-alert-color",
                              attrs: { href: "#modal-center", "uk-toggle": "" }
                            },
                            [_vm._v("termos e condições")]
                          ),
                          _vm._v(
                            " e estou de acordo com todos eles\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.person.documentNumber,
                              expression: "person.documentNumber"
                            }
                          ],
                          staticClass: "uk-visible@s ukid-margin-bottom-30"
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                class: {
                                  "uk-button uk-margin-center ukid-buttom-disable ukid-margin-top-30 ukid-margin-bottom-30 ukid-font-size-20": !_vm.terms,
                                  "uk-button uk-button-default uk-button-save uk-margin-center ukid-margin-top-30 ukid-margin-bottom-30 ukid-font-size-20":
                                    _vm.terms
                                },
                                attrs: { type: "submit", disabled: !_vm.terms }
                              },
                              [
                                _vm._v(
                                  "\n                                Enviar Cadastro Facial\n                            "
                                )
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.person.documentNumber,
                              expression: "person.documentNumber"
                            }
                          ],
                          staticClass:
                            "uk-hidden@s ukid-width-100-percent ukid-margin-top-40 ukid-margin-bottom-30"
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                class: {
                                  "uk-button ukid-buttom-disable ukid-font-size-20": !_vm.terms,
                                  "uk-button uk-button-default uk-button-save ukid-font-size-20":
                                    _vm.terms
                                },
                                attrs: { type: "submit", disabled: !_vm.terms }
                              },
                              [_vm._v("Enviar Cadastro")]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "ukid-logo-un",
        attrs: {
          id: "ukid-logo-un",
          src: "content/images/unike-logo-un.png",
          width: "95"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-video-text-invite" }, [
      _vm._v(
        "\n                                Certifique-se que toda a face esteja no frame,"
      ),
      _c("br"),
      _vm._v("não faltando nenhuma das partes"),
      _c("br"),
      _vm._v("Retire sua máscara e óculos caso esteja usando"),
      _c("br"),
      _vm._v(
        "Procure um ambiente com luz suficiente\n                            "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("canvas", {
        staticClass: "uk-hidden ukid-image-video-capture",
        attrs: { id: "ukid-camera-canvas", width: "440", height: "280" }
      }),
      _vm._v(" "),
      _c("video", {
        staticClass: "ukid-image-video-capture",
        attrs: {
          id: "ukid-camera-stream",
          crossorigin: "anonymous",
          width: "440",
          height: "280"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-inline ukid-container" }, [
      _c("img", {
        staticClass: "ukid-image-video-capture",
        attrs: { id: "ukid-face-load" }
      }),
      _vm._v(" "),
      _c("canvas", { staticClass: "canvas uk-hidden" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-27e95e30", { render: render, staticRenderFns: staticRenderFns })
  }
}