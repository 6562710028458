import { Commons } from './common';

/**
 * name: Daniel Fonseca
 * date:  25/12/2018
 * version: 1.0
 */
export var cm = {

    width: 640,
    height: 640,
    stream: undefined,
    worker: undefined,

    validateBrowser: function() {

        navigator.getMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)

        var video = document.querySelector('#ukid-camera-stream')

        // Success Callback
        var funSuccess = function(stream) {

            // Create an object URL for the video stream and
            // set it as src of our HTLM video element.
            try {
                video.src = window.URL.createObjectURL(stream)
            } catch (error) { 
                console.info(error)                   
                video.srcObject = stream                        
            }                    

            // Play the video element to start the stream.
            // video.play()

            cm.stream = stream
            
        }
        
        // Error Callback
        var funError = function(err) {
            alert("There was an error with accessing the camera stream: " + err.name, err);
        }

        if (!navigator.getMedia && !navigator.mediaDevices) {

            alert("Your browser doesn't have support for the navigator.getUserMedia interface.");

        } else {

            if (!navigator.mediaDevices) {

                // Request the camera
                navigator.getMedia(

                    {
                        video: true
                    },

                    funSuccess,

                    funError

                );

            } else {

                navigator.mediaDevices.getUserMedia({

                        video: true

                }).then(

                    funSuccess, 

                    funError

                );                

            }

        }

    },

    startCameraProcess: function(func) {

        var video =  document.querySelector('#ukid-camera-stream')

        // Success Callback
        var funSuccess = function(stream) {
            
            try {
                video.src = window.URL.createObjectURL(stream)
            } catch (error) { 
                console.info(error)                   
                video.srcObject = stream                        
            } 

            cm.stream = stream

            // Play the video element to show the stream to the user.
            video.play()

            func('success')

        }
        
        // Error Callback
        var funError = function(err) {

            // Most common errors are PermissionDenied and DevicesNotFound.
            console.error(err)

            func(err)

        }

        if (!navigator.mediaDevices) {

            navigator.getUserMedia(
            
                // Options
                {
                    video: true
                },
    
                funSuccess,
            
                funError
    
            )

        } else {

            navigator.mediaDevices.getUserMedia({

                    video: true

            }).then(

                funSuccess, 

                funError

            );

        }

    
    },

    takeSnapshot: function(callback) {

        var video  = document.querySelector('#ukid-camera-stream')
        var canvas = document.querySelector('#ukid-camera-canvas')

        // Context object for working with the canvas
        var context = canvas.getContext('2d', { preserveDrawingBuffer: true });
        canvas.width = cm.width
        canvas.height = cm.height

        // Get the exact size of the video element
        var width = canvas.width;
        var height = canvas.height;

        var image = new Image()
        
        image.setAttribute('src', canvas.toDataURL('image/jpeg'))
        image.crossOrigin = "anonymous"
        image.addEventListener('load', function(event) {

            // Draw a copy of the current frame from the video on the canvas
            context.drawImage(video, 0, 0, width, height)
            
            // Set the dataURL as source of an image element, showing the captured photo
            // image.setAttribute('src', hiddenCanvas.toDataURL('image/png'))

            var b64 = canvas.toDataURL('image/jpeg').replace('data:image/jpeg;base64,', '').replace('data:image/jpg;base64,', '')

            callback(b64)
            
        })

    },

    videStop: function() {

        try {

            cm.stream.getTracks().forEach(function(track) {
                track.stop();
            })

            cm.worker.terminate()
            cm.worker = undefined
            
        } catch (err) {
        }     
    
    }   

}