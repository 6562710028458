var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.strings.isNullOrEmpty(_vm.entity.user) ||
    _vm.vm.isEditableForMe(_vm.entity.user)
    ? _c(
        "div",
        { staticClass: "uk-margin" },
        [
          _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
            _vm._v("\n        " + _vm._s(_vm.label)),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-text-alert-color ukid-font-size-9",
              domProps: { innerHTML: _vm._s(_vm.description) }
            })
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.errors.first(_vm.id)
            ? _c("span", { staticClass: "ukid-text-fail-color" }, [
                _vm._v("Selecione o(s) usuários(s)")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticStyle: { color: "#444444 !important" },
              attrs: {
                "track-by": "username",
                id: _vm.id,
                name: _vm.name,
                options: _vm.userList === null ? [] : _vm.userList,
                "hide-selected": _vm.multiple === true,
                searchable: true,
                "close-on-select": true,
                "show-labels": false,
                multiple: _vm.multiple === true,
                taggable: _vm.multiple === true,
                placeholder: "Selecione o usuário"
              },
              on: {
                input: _vm.selectFill,
                select: _vm.selectAction,
                remove: _vm.removeAction
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function(props) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n                usuário: " +
                              _vm._s(props.option.username) +
                              "\n            "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "singleLabel",
                    fn: function(props) {
                      return _vm.multiple !== true
                        ? [
                            _c("span", { staticClass: "multiselect__tag" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                    usuário: " +
                                    _vm._s(props.option.username) +
                                    " -> selecionado\n                "
                                )
                              ])
                            ])
                          ]
                        : undefined
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedUsers,
                callback: function($$v) {
                  _vm.selectedUsers = $$v
                },
                expression: "selectedUsers"
              }
            },
            [
              _vm._v(" "),
              _vm._v(" "),
              _c("template", { slot: "noResult" }, [
                _c("span", [
                  _vm._v(
                    "\n                Nenhum usuário encontrada\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("template", { slot: "noOptions" }, [
                _c("span", [
                  _vm._v("\n                Comece a digitar\n            ")
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    : _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [_vm._v("\n        Usuário\n    ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [_vm._v("\n        Usuário\n    ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "uk-visible@s ukid-font-size-17" }, [
          _c("b", [
            _vm._v("CADASTRADO POR: "),
            _c(
              "span",
              { staticClass: "ukid-text-alert-color ukid-text-uppercase" },
              [_vm._v(_vm._s(_vm.entity.user))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "uk-hidden@s ukid-font-size-15" }, [
          _c("b", [
            _vm._v("CADASTRADO POR: "),
            _c(
              "span",
              { staticClass: "ukid-text-alert-color ukid-text-uppercase" },
              [_vm._v(_vm._s(_vm.entity.user))]
            )
          ])
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3bf8a271", { render: render, staticRenderFns: staticRenderFns })
  }
}