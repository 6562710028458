<!--suppress JSUnresolvedVariable, HtmlUnknownAttribute, SpellCheckingInspection, CheckImageSize, HtmlDeprecatedAttribute, HtmlRequiredAltAttribute, JSCheckFunctionSignatures, JSUnresolvedFunction, NpmUsedModulesInstalled -->

<style src="../../../content/css/vue-multiselect.min.css"></style>

<script type="text/javascript">

    import App from '../../app.json'

    import UIkit from 'uikit'
    import Base64Upload from 'vue-base64-upload'

    import { cm } from '../../js/camera-person.js'
    import { vm } from '../../js/main.js'
    import { Datetime } from 'vue-datetime'
    import { Strings } from '../../js/common.js'
    import { Commons } from '../../js/common.js'

    import GroupComponent from "../group/group-component"
    import UsersComponent from "../user/users-component"

    import Multiselect from 'vue-multiselect'

    let findOrientation = require('exif-orientation')

    export default {

        components: {

            Multiselect,
            Base64Upload: Base64Upload,
            groupComponent: GroupComponent,
            usersComponent: UsersComponent,
            datetime: Datetime

        },

        data() {

            return {
                App: App,
                searchParam: '',
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    }
                },
                pagination: {
                    last_page: 0
                },
                registers: {},
                personV4: {
			personV4Collection: {
                                id19: null,
                    		newId19: null,
				ffid: null,
                    		createTimestamp: null,
                    		updateTimestamp: null,
                    		entryTime: null,
                    		exitTime: null,
                    		galleries: [],
                    		jokerList: [],
                    		groupList: [],
                    		usersChildrenList: [vm.user.sub],
                    		user: null,
                    		detection: null,
				recordMarkedForEditing: false,
                    		recordMarkedForAlert: false,
                    		twoFactorAuthentication: false,
                    		checkedOnPublicDatabase: false,
                    		highlightBlcFace: false,
                    		accessStartDatetime: new Date().toISOString(),
                    		accessEndDatetime: new Date(new Date().setHours( new Date().getHours() + 2 )).toISOString(),
				principalCrop : {
					snapshot: null
				}
			},
			personDocumentV4Collection: {
				name: null,
                    		email: null,
                    		id19: null,
                    		cardNumber: null,
				user: null,
			}
                },
                jokers: {
                    id: null,
                    jokerList: [],
                    collection:  null,
                    user: null
                },
                invite: {
                    token: null,
                    documentNumber: null,
                    name: null,
                    photo: null,
                    detection: null,
                    accessStartDatetime: null,
                    accessEndDatetime: null
                },
                imageExif: null,
                imageRotate: null,
                entryTime: null,
                exitTime: null,
                permAccess: false,
                galleries: null,
                sub: null,
                user: {},
                users: [],
                groups: [],
                detectRequest: {
                    photo: null,
                    gae: true
                },
                detectResponse: {
                    faces: [],
                    orientation: null
                },
                aditionalFileds: {
                    fieldDesc: null,
                    fieldName: null,
                    fieldValue: '',
                    required: false,
                },
                classError: 'ukid-form-error uk-input ukid-focus ukid-width-100-percent',
                classNorm: 'uk-input ukid-focus ukid-width-100-percent',
                page: 1,
                vm: vm,
                Commons: Commons,
                Strings: Strings,
                visibleSnaphost: false,
                updateOrientation: false,
                video: {}
            }

        },

        mounted() {

            let _ = this

            document.querySelector('input[type=file]').addEventListener('change',function(e) {

                let file = e.target.files[0];
                
                findOrientation(file,function(err,orientation) {

                    if (!err) {

                        _.imageRotate = orientation.rotate
                        _.imageExif = orientation.exif
                        
                    }

                })

            })

        },

        created() {

            let _ = this

            _.sub = vm.user.sub

            _.fillJokers(_.sub)

            _.list()

        },

        methods: {

            search() {

                let _ = this

                vm.spinnerSearch()
                
                setTimeout(function(){

                    _.list(undefined, 1, App.pagination.itensPerPage)

                    vm.spinnerSearch() 

                }, App.layout.form.interval)

            },

            /**
             * Method responsible for extracting the faces contained in an image
             */
            detect(base64) {
                
                let _ = this

                let modal = null
                
                if (!Commons.isMobile()) 
                    modal = UIkit.modal.dialog(App.notification.loadingImage, { escClose: false, bgClose: false })
                else
                    modal = UIkit.modal.dialog(App.notification.loadingImageMobile, { escClose: false, bgClose: false })
            
                _.personV4.personV4Collection.detection = null
                _.detectRequest.photo = base64

                _.removeDetectFaces()

                this.$http.post(App.host.concat(App.detectPersonEndpoint), _.detectRequest).then(response => {

                    _.detectResponse = response.data

                    _.detectResponse.faces.forEach(function(object) {

                        let img  = document.querySelector('.base64-upload img')
                        let rect = document.createElement('div')

                        rect.classList.add('rect')

                        rect.style.width  = (object.bbox.right - object.bbox.left) + 'px'
                        rect.style.height = (object.bbox.bottom - object.bbox.top) + 'px'
                        rect.style.left   = (img.offsetLeft + object.bbox.left) + 'px'
                        rect.style.top    = (img.offsetTop  + object.bbox.top) + 'px'

                        if (parseInt(rect.style.width, 10) < 60) {

                            UIkit.modal.alert('<span class="ukid-font-size-17">Ops... Vamos refazer sua selfie<br />Por gentileza aproxime mais face</span>')

                            _.photoIcon(img)
                            
                            return

                        }

                        rect.style.borderColor = _.colorBox()

                        rect.addEventListener("click", function(event) {

                            document.querySelectorAll('.rect').forEach(function(el) {
                                
                                el.style.borderStyle = 'dashed'                                
                                el.innerHTML = `<span class="uk-margin-small-right uk-icon" style="color:${el.style.borderColor}" uk-icon="trash"></span>`

                            })

                            rect.style.borderStyle = 'solid'
                            rect.innerHTML = ''

                            _.personV4.personV4Collection.detection = object.id
                        
                            if (document.querySelectorAll('.rect').length === 1) {

                                let img = document.querySelector('.base64-upload img')                                                  
                            
                                _.onChangeImage(document.querySelectorAll('input[type=file]'))

                                _.photoIcon(img)

                            }

                        })

                        if (_.detectResponse.faces.length === 1) {

                            _.personV4.personV4Collection.detection = object.id

                        }

                        document.querySelector('.ukid-container').appendChild(rect)                        

                    })

                    setTimeout(function(){modal.hide()}, 500)

                }, response => {

                    setTimeout(function(){modal.hide()}, 500)

                    UIkit.modal.alert(response.data)

                })

            }, 

            /**
             * List all persons
             */
            list(page) {

                if (page !== undefined) this.page = page

                this.$http.get(

                    App.host.concat(App.listPersonEndpoint) 
                    + '?pageNum=' + (page === undefined ? 1 : page)
                    + '&perPage=' + App.pagination.itensPerPage
                    + '&param=' + this.searchParam

                ).then(response => {

                    this.registers = response.body.data
                    this.pagination = response.body
                                        
                })

            },

            /**
             * List all jokers
             */
            fillJokers(username) {

                let _ = this

                /*
                 * Clear jokers objects 
                 */
                try { _.$refs.ukidAddedFields.innerHTML = '' } catch(err) {}

                /*
                * To update the html component
                */
                _.jokers = {
                    id: null,
                    jokerList: [],
                    collection: null,
                    user: null
                }

                /*
                * Get all jokers 
                */
                _.$http.get(

                    `${App.host}${App.jokersEndpoint}${username}/persons`

                ).then(response => {

                    if (response.body) {
                        
                        _.jokers.id = response.body.id
                        _.jokers.jokerList = response.body.jokerList
                        _.jokers.collection = response.body.collection
                        _.jokers.user = response.body.user

                    }

                    if (_.personV4.personV4Collection.jokerList.length > 0) {

                        _.jokers.jokerList.forEach(function (jo1) {

                            _.personV4.personV4Collection.jokerList.forEach(function (jo2) {

                                if (jo1.fieldName === jo2.fieldName && jo2.fieldValue !== null) {

                                    jo1.fieldValue = jo2.fieldValue

                                }

                            })

                        })

                    }

                    _.jokers.jokerList.forEach(function(obj) { obj.fieldValue = Strings.nullToEmpty(obj.fieldValue) })

                })
            
            },

            /**
             * Search a person by document number
             */
            fillByDocumentNumber(documentNumber) {

                let _ = this

                this.$http.get(

                    App.host.concat(App.findByDocumentNumberPersonEndpoint.concat(documentNumber)) 

                ).then(response => {

                    _.errors.clear()
                
                    vm.spinnerWithProgress(App.layout.progress.increment, App.layout.progress.interval)

                    _.personV4 = response.body

                    _.personV4.personV4Collection.detection = ''

                })

            },

            /**
             * Method responsible for filling the form with an existing registration
             */
            fill(object) {
               
                // Myselfie
                let _ = this

                // Clear vue data objects objects
                _.clear(true)

                // Clear form errors
                _.errors.clear()
                
                vm.spinnerWithProgress(App.layout.progress.increment, App.layout.progress.interval)

                _.personV4 = object
                _.personV4.personV4Collection.detection = ''

                // Handling data from old records
                if (!_.personV4.personDocumentV4Collection.hasOwnProperty("name")) _.personV4.personDocumentV4Collection.name = ''
                if (!_.personV4.personDocumentV4Collection.hasOwnProperty("email")) _.personV4.personDocumentV4Collection.email = ''
                if (!_.personV4.personV4Collection.hasOwnProperty("jokerList")) _.personV4.personV4Collection.jokerList = []
                if (!_.personV4.personV4Collection.hasOwnProperty("galleries")) _.personV4.personV4Collection.galleries = []                
                if (!_.personV4.personV4Collection.hasOwnProperty("groupList")) _.personV4.personV4Collection.groupList = []

                // For vuejs renderer bug
                if (_.personV4.personV4Collection.recordMarkedForEditing) {

                    _.$refs.recordMarkedForEditingEl.checked = true

                }
                // For vuejs renderer bug
                if (_.personV4.personV4Collection.recordMarkedForAlert) {

                    _.$refs.recordMarkedForAlertEl.checked = true

                }

                // For person access rules ( permanent if accessStartDatetime and accessEndDatetime equals null )
                if (!_.personV4.personV4Collection.hasOwnProperty("accessStartDatetime") || !_.personV4.personV4Collection.hasOwnProperty("accessEndDatetime")) {

                    document.querySelector('.ukid-permanent-access').checked = true
                    
                    _.permanentAccess()

                } else {

                    _.personV4.personV4Collection.accessStartDatetime = new Date(_.personV4.personV4Collection.accessStartDatetime).toISOString()
                    _.personV4.personV4Collection.accessEndDatetime   = new Date(_.personV4.personV4Collection.accessEndDatetime).toISOString()
                    
                }

                //  For unike.PONTO
                if (!Strings.isNullOrEmpty(_.personV4.personV4Collection.entryTime)) {

                    let m = _.$moment()
                    m.set( { hour: _.personV4.personV4Collection.entryTime.substring(0, 2), minute: _.personV4.personV4Collection.entryTime.substring(3, 5), second: 0, millisecond: 0 } )

                    _.entryTime = m.toISOString()

                }

                // For unike.PONTO
                if (!Strings.isNullOrEmpty(_.personV4.personV4Collection.exitTime)) {

                    let m = _.$moment()
                    m.set( { hour: _.personV4.personV4Collection.exitTime.substring(0, 2), minute: _.personV4.personV4Collection.exitTime.substring(3, 5), second: 0, millisecond: 0 } )

                    _.exitTime = m.toISOString()

                }

                // Update jokers list according to object user
                _.fillJokers(object.personV4Collection.user)

                // Update component group
                _.$refs.groupCpnt.refresh()
                _.$refs.usersCpnt.refresh()

                document.querySelector('.ukid-focus').focus()

            },

            /**
             * Method responsible for delete user from server
             */
            del(object) {

                let _ = this

                _.$http.delete(App.host.concat(App.deletePersonEndpoint).concat(object.personV4Collection.id19).concat("/").concat(_.personV4.personV4Collection.user)).then(response => {

                    UIkit.notification.closeAll()

                    UIkit.notification({ 
                        message: App.notification.del, 
                        status: 'primary', 
                        pos: 'top-center', 
                        timeout: App.notification.timeout
                    })

                    if (_.pagination.current_page > 0 && _.page === _.pagination.last_page && _.pagination.data.length === 1) {

                        _.list(_.page - 1)

                    } else {

                        _.list(_.page)

                    }

                    if (!Commons.isMobile())
                        _.clear(false)

                }).catch(function(err) {

                    let error = err.body.description

                    UIkit.modal.alert(error)

                })
            
            },

            delAndResendInvite(object) {

                let _ = this

                _.$http.get(App.host.concat(App.invitePersonEndpoint).concat(object.personV4Collection.id19)).then(response => {

                    _.invite = response.body
                    
                    UIkit.modal('#ukid-modal-invite').show()

                })

            },

            /**
             * Method responsible for saving a person and his face on the server
             */
            save() {

                // Myself
                let _ = this
                
                // Get html elements fot buttom save and image click upload
                let _buttonSave = document.querySelector('.uk-button-save')
                let _img = document.querySelector('.base64-upload img')
                
                this.$validator.validateAll().then((result) => {

                    _.errors.items.forEach(obj => {

                        try {

                            document.querySelector(`#${obj.field}`).classList.remove('ukid-form-error')
                            document.querySelector(`#${obj.field}`).classList.add('ukid-form-error')

                        } catch (err) {
                        }

                    })

                    // Case some error was found
                    if (!result) {

                        document.querySelector(`#${_.errors.items[0].field}`).focus()

                        return
                        
                    }

		    _.personV4.personDocumentV4Collection.id19 = _.personV4.personV4Collection.id19
                    _.personV4.personDocumentV4Collection.user = _.personV4.personV4Collection.user

                    // Update new document number in case of user change it
                    if (!Strings.isNullOrEmpty(_.personV4.personV4Collection.ffid)) {
            
                        _.personV4.personV4Collection.newId19 = _.personV4.personV4Collection.id19

                    }

                    // Checks if it is a new record and if no extracted face
                    if (Strings.isNullOrEmpty(_.personV4.personV4Collection.ffid) && Strings.isNullOrEmpty(_.personV4.personV4Collection.detection)) {
            
                        UIkit.modal.alert("Selecione ao menos uma Face")

                        return

                    }

                    // Check if the face is extracted for the update is ok
                    if (_.isBase64(_.personV4.personV4Collection.principalCrop.snapshot) && !Strings.isNullOrEmpty(_.personV4.personV4Collection.ffid) && Strings.isNullOrEmpty(_.personV4.personV4Collection.detection)) {
            
                        UIkit.modal.alert("Selecione ao menos uma Face")

                        return
                        
                    }

                    // Handle objets db to page
                    if (_.jokers.hasOwnProperty('jokerList') && _.jokers.jokerList !== null && _.jokers.jokerList.length > 0) {

                        _.personV4.personV4Collection.jokerList = _.jokers.jokerList

                    }

                    // For unike.PONTO
                    if (_.entryTime) {

                        _.personV4.personV4Collection.entryTime = _.$moment(new Date(_.entryTime)).format('HH:mm')

                    }

                    // For unike.PONTO
                    if (_.exitTime) {

                        _.personV4.personV4Collection.exitTime = _.$moment(new Date(_.exitTime)).format('HH:mm')

                    }

                    _buttonSave.disabled = true
                    _buttonSave.innerHTML = 'Aguarde...'

                    // Person object to the server
                    this.$http.post(App.host.concat(App.savePersonEndpoint), _.personV4).then(response => {

                        UIkit.notification.closeAll()
                        UIkit.notification({ 
                            message: App.notification.save, 
                            status: 'primary', 
                            pos: 'top-center', 
                            timeout: App.notification.timeout
                        })
                        
                        // Re-list all records
                        _.list(_.page)

                        // Clear vue data objects
                        _.clear()
                    
                        _buttonSave.disabled = false
                        _buttonSave.innerHTML = 'Salvar'

                    }).catch(function(err) {
                        
                        let error = err.body.description

                        _buttonSave.disabled  = false
                        _buttonSave.innerHTML = 'Salvar'

                        UIkit.modal.alert(error)

                    })

                }).catch((err) => {
                    
                    console.error(err)

                })

            },

            /**
             * Method responsible for resetting the data of this component
             */
            clear(focus) {

                this.personV4 = {}
		this.personV4.personV4Collection = {}
		this.personV4.personDocumentV4Collection = {}
                this.personV4.personV4Collection.galleries = []

		this.personV4.personDocumentV4Collection.id = null
                this.personV4.personDocumentV4Collection.name = null
                this.personV4.personDocumentV4Collection.email = null
                this.personV4.personDocumentV4Collection.id19 = null
                this.personV4.personDocumentV4Collection.newId19 = null
                this.personV4.personDocumentV4Collection.cardNumber = null
		this.personV4.personDocumentV4Collection.user = null


                this.personV4.personV4Collection.id = null
                this.personV4.personV4Collection.id19 = null
                this.personV4.personV4Collection.entryTime = null
                this.personV4.personV4Collection.exitTime = null
                this.personV4.personV4Collection.ffid = null
                this.personV4.personV4Collection.principalCrop = {}
                this.personV4.personV4Collection.principalCrop.snapshot = null
                this.personV4.personV4Collection.createTimestamp = null
                this.personV4.personV4Collection.updateTimestamp = null
                this.personV4.personV4Collection.user = null
                this.personV4.personV4Collection.detection = null
                this.personV4.personV4Collection.jokerList = []
                this.personV4.personV4Collection.groupList = []
                this.personV4.personV4Collection.recordMarkedForEditing = false
                this.personV4.personV4Collection.recordMarkedForAlert = false
                this.personV4.personV4Collection.twoFactorAuthentication = false
                this.personV4.personV4Collection.checkedOnPublicDatabase = false
                this.personV4.personV4Collection.accessStartDatetime = new Date().toISOString()
                this.personV4.personV4Collection.accessEndDatetime = new Date(new Date().setHours(new Date().getHours() + 2)).toISOString()

                this.detectResponse.faces = []
                this.detectResponse.orientation = null
                
                this.detectRequest.photo = ''
                this.detectRequest.gae = true

                this.visibleSnaphost = false
                this.permAccess = false

                this.entryTime = null
                this.exitTime = null

                this.errors.clear()

                let form  = document.querySelector('.ukid-form')
                let field = document.querySelector('.ukid-focus')
                let img   = document.querySelector('.base64-upload img')

                form.reset()

                if (focus) {

                    field.focus()

                }
                
                this.photoIcon(img)
                this.removeDetectFaces()

                vm.configDeviceResolution()

                cm.videStop()

            },

            modal(personV4) {

                let _ = this

                _.personV4 = personV4

                if (!_.personV4.personV4Collection.hasOwnProperty('groupList')) {

                    _.personV4.personV4Collection.groupList = ['vst']

                }

                UIkit.modal('#ukid-modal-person-overflow').show()

            },

            onChangeImage(file) {

                let _ = this

                _.updateOrientation = false

                let img = document.querySelector('.base64-upload img')

                if (!Commons.isMobile() && file.size > 1000000) {

                    UIkit.modal.alert('Arquivo muito grande. Máximo 1MB')
                    
                    setTimeout(function(){

                        _.removeDetectFaces()
                        _.photoIcon(img)

                        _.personV4.personV4Collection.principalCrop.snapshot = ''

                    }, 400)

                } else {
                    
                    _.removeDetectFaces()

                    _.scaleImageAndSendToAnalyze(file.base64)

                }
            
                _.list(_.page)

            },
            
            removeDetectFaces() {

                document.querySelectorAll('.rect').forEach(function(el) {
                    
                    el.remove()

                })

            },
            
            photoIcon(img) {
        
                img.setAttribute('id', 'facemap')                
                img.style.width = "40%"
                img.src = `../../content/images/photo-icon.png?nocache=${Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))}`
                img.value = ''

            },

            photoImage(img, base64) {

                img.setAttribute('id', 'facemap');
                img.style.width = "460px"
                img.src = 'data:image/jpeg;base64,' + base64

            },

            /**
             * Face selection box
             */
            colorBox() {

                let colors = ['#fdecce','#F5EA87','#5CF77E','#3acff8','#7C5EC6','#ff33ff','#b366ff','#bb4462','#b0b036','#f49e0b']
            
                return colors[Math.floor((Math.random() * 10))]

            },

            /**
             * For classes of field errors in the form
             */
            defaultClass(classes, hasError) {

                if (window.screen.availWidth < 500 && hasError) {

                    classes += ' ukid-form-error ukid-width-100-percent'

                } else if (window.screen.availWidth < 500 && !hasError) {

                    classes += ' ukid-width-100-percent'

                } else if (window.screen.availWidth > 500 && hasError) {

                    classes += 'ukid-form-error ukid-width-100-percent'

                } else if (window.screen.availWidth > 500 && !hasError) {

                    classes += ' ukid-width-100-percent'

                }

                return classes

            },

            /**
             * Method responsible for resizing the image and sending it to the server
             */
            scaleImageAndSendToAnalyze(base64) {
                
                let _ = this

                let img = document.querySelector('.base64-upload img')

                img.onload = function(event) {
                    
                    if (_.updateOrientation) return

                    let canvas = document.createElement('canvas')
                    canvas.width  = img.width
                    canvas.height = img.height

                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(this, 0, 0, img.width, img.height)

                    let b64 = canvas.toDataURL('image/jpeg').replace('data:image/png;base64,', '')
                    b64 = b64.replace('data:image/jpeg;base64,', '')
                    b64 = b64.replace('data:image/jpg;base64,', '')
                
                    if (!Strings.contains(img.src, 'photo-icon')) {

                        if (Commons.isMobile() && Commons.isAndroid() && canvas.width > canvas.height) {

                            Commons.rotateBase64Image(canvas.toDataURL('image/jpeg'), canvas, _.imageExif, canvas.width +100, canvas.height +100, function(bImage) {
                            
                                b64 = bImage
                                .replace('data:image/png;base64,'  , '')
                                .replace('data:image/jpeg;base64,' , '')
                                .replace('data:image/jpg;base64,'  , '')
                                
                                if (!_.updateOrientation) {

                                    _.updateOrientation = true
                                    _.personV4.personV4Collection.principalCrop.snapshot = b64

                                    _.photoImage(img, b64)
                                    _.detect(b64)

                                }

                            })

                        } else {
                            
                            /*
                            * Send to the server for face extraction 
                            */
                            _.detect(b64)

                            _.personV4.personV4Collection.principalCrop.snapshot = b64

                        }

                    }

                }

                /*
                * Redo the state of the elements for capture 
                */
                _.photoImage(img, base64)

            },

            /**
             * Sets that person's permanent access
             */
            permanentAccess() {

                /*
                * Myselfie 
                */
                let _ = this

                if (document.querySelector('.ukid-permanent-access').checked) {

                    _.personV4.personV4Collection.accessStartDatetime = null
                    _.personV4.personV4Collection.accessEndDatetime   = null

                    _.permAccess = true

                } else {

                    _.personV4.personV4Collection.accessStartDatetime = new Date().toISOString()
                    _.personV4.personV4Collection.accessEndDatetime   = new Date(new Date().setHours(new Date().getHours() + 2)).toISOString()
                    
                    _.permAccess = false

                }
                
            },

            /**
             * Method responsible for starting the web camera
             */
            startCamera() {

                let _ = this

                var scaled = Commons.calculatePhoto(1024, 768, 1024, 768)

                cm.width = scaled.width
                cm.height = scaled.height

                cm.validateBrowser()
                cm.startCameraProcess(function(status) {

                    if (status === 'success') {

                        _.visibleSnaphost = true

                    } else {

                        _.visibleSnaphost = false

                    }

                })
            
            },

            /**
             * Method responsible for capturing an image from the webcam
             */
            takeSnapshot() {
                
                /*
                * Myselfie
                */
                let _ = this                

                try {

                    /*
                    * Clean front faces 
                    */
                    _.removeDetectFaces()

                    cm.takeSnapshot(function(base64){

                        _.visibleSnaphost = false

                        let file = document.querySelectorAll('input[type=file]')
                        file.base64 = base64
                    
                        _.onChangeImage(file)
                        
                        cm.videStop()
                        
                    })
                
                } catch (err) {
                }

            },

            isBase64(param) {

                return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(param)

            },

            truncate(param, size) {

                return (param.length > size) ? param.substr(0, size-1) + '...' : param;

            },

            addField() {

                let _ = this

                let ret = true

                _.personV4.personV4Collection.jokerList.forEach(function(item, index, object) {

                    if (Strings.trim(item.fieldName) === Strings.trim(_.aditionalFileds.fieldName))
                        ret = false

                })

                if (_.jokers.hasOwnProperty('jokerList')) {

                    _.jokers.jokerList.forEach(function(item, index, object) {

                        if (Strings.trim(item.fieldName) === Strings.trim(_.aditionalFileds.fieldName))
                            ret = false

                    })

                }

                if (ret) {

                    let html = _.$refs.ukidAddedFields.innerHTML

                    html += `<div id="ukid-add-field-${_.aditionalFileds.fieldName}" class="uk-margin">`
                    html += `   <span class="uk-label ukid-primary-label-color">${_.aditionalFileds.fieldDesc}</span><br />`
                    html += ` <input data-vv-as="field" id="${_.aditionalFileds.fieldName}-input_field" name="${_.aditionalFileds.fieldName}-input_field" value="${_.aditionalFileds.fieldValue}" type="text" class="uk-input ukid-width-80-percent" aria-required="true" aria-invalid="true">`
                    html += `   <span onclick="return false" class="ukid-disable-color ukid-cursor-pointer" uk-icon="icon:trash;ratio:2.2"></span>`
                    html += `</div>`

                    html = html.replaceAll('data-value', 'value').replaceAll('data-validate', 'v-validate')

                    _.$refs.ukidAddedFields.innerHTML = html

                    _.personV4.personV4Collection.jokerList.push({
                        fieldDesc: Strings.trim(_.aditionalFileds.fieldDesc),
                        fieldName: Strings.trim(_.aditionalFileds.fieldName),
                        fieldValue: Strings.trim(_.aditionalFileds.fieldValue),
                        required: _.aditionalFileds.required
                    })

                    _.personV4.personV4Collection.jokerList = _.personV4.personV4Collection.jokerList.filter((arr, index, self) => index === self.findIndex((a) => (a.fieldName === arr.fieldName)))

                    window.scrollTo(0, _.$refs.ukidAddedFields.getBoundingClientRect().bottom)

                } else {

                    UIkit.modal.alert('Campo não adicionado, campo já existe')

                }

                _.jokers.user = _.sub
                _.jokers.collection = 'persons'

                _.aditionalFileds.required   = false
                _.aditionalFileds.fieldDesc  = null
                _.aditionalFileds.fieldName  = null
                _.aditionalFileds.fieldValue = ''

            },

            removeAttribute(attributeName) {

                let _ = this

                let el = document.querySelector('#ukid-add-field-' + attributeName)

                _.personV4.personV4Collection.jokerList.forEach(function(item, index, object) { 
                    if (Strings.trim(item.fieldName) === Strings.trim(attributeName)) _.personV4.personV4Collection.jokerList.splice(index, 1)
                })

                el.parentNode.removeChild(el)

                _.person['jokerList'].push({
                        "fieldName": attributeName,
                        "fieldValue": 'field_remove'
                })

            },

            checkAttributeName(e) {

                e.target.value = e.target.value
                .replace('ˆ', '')
                .replace('\'', '')
                .replace('"', '')
                .replace('*', '')

                if (/[~ `!@#$%^&()={}[\]:;,.<>+\/?-]/.test(e.key)) {
                
                    e.preventDefault()

                }

            }            

        }

    }

</script>

<template id="person-template">

    <div class="uk-child-width-expand@s ukid-background-white-color" uk-grid>

        <!-- Modal Add Fields -->
        <div id="ukid-add-fields-modal" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body">
                    <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
                        <div class="uk-margin ukid-font-size-10">
                            Ao adicionar um campo nesta sessão o mesmo ficará disponível para todos os próximos cadastros
                            <br /><br />
                            Campos que podem ser criados e que são reconhecidos pelo unike.CORE:<br />
                            <span class="ukid-text-alert-color"> - pisNumber</span><br />
                            <span class="ukid-text-alert-color"> - phoneNumber</span><br /><br />
                            Para usar um dos campos acima, basta inserir a descrição acima em "NOME DO CAMPO"
                        </div>
                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">Descrição do Campo</span><br />
                            <div class="uk-inline ukid-width-100-percent">
                                <input
                                    class="uk-input ukid-focus ukid-width-100-percent"
                                    v-validate="'min:3|max:20'"
                                    v-model="aditionalFileds.fieldDesc"
                                    c-data-vv-as="field"
                                    id="descField-input_field"
                                    name="descField-input_field"
                                    type="text" 
                                    placeholder="Descrição do campo sem espaços">
                            </div>
                        </div>                        
                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">
                                Nome do Campo<br />
                                <span class="ukid-font-size-10">
                                    Somente letras, números e underscore "_"
                                </span>
                            </span>
                            <br />
                            <div class="uk-inline ukid-width-100-percent">
                                <input
                                    @keydown="checkAttributeName($event)"
                                    class="uk-input ukid-focus ukid-width-100-percent"
                                    v-validate="'min:3|max:20'"
                                    v-model="aditionalFileds.fieldName"
                                    c-data-vv-as="field"
                                    id="nameField-input_field"
                                    name="nameField-input_field"
                                    type="text" 
                                    placeholder="Descrição do campo sem espaços">
                            </div>
                        </div>
                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color">Valor do Campo</span><br />
                            <div class="uk-inline ukid-width-100-percent">
                                <input
                                    class="uk-input ukid-focus ukid-width-100-percent"
                                    v-validate="'min:3|max:100'"
                                    v-model="aditionalFileds.fieldValue"
                                    c-data-vv-as="field"
                                    id="valueField-input_field"
                                    name="valueField-input_field"
                                    type="text" 
                                    placeholder="Valor do campo">
                            </div>
                        </div>
                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color">Tornar Campo Obrigatório?</span><br />
                            <label>
                                <input v-model="aditionalFileds.required" value="true" class="uk-radio" type="radio" id="valueFieldRequired-input_yes_field" name="value-field-required-type-input_field"> SIM
                            </label>
                            <label>
                                <input v-model="aditionalFileds.required" value="false" class="uk-radio" type="radio" id="valueFieldRequired-input_no_field" name="value-field-required-type-input_field" checked> NÃO
                            </label>
                        </div>                            
                    </div>                   
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Cancelar</button>
                    <button @click="addField()" class="uk-button uk-modal-close" type="button">Adicionar</button>
                </div>
            </div>
        </div>

        <!-- Modal Invite -->
        <div id="ukid-modal-invite" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title"></h2>
                </div>
                <div class="uk-modal-body">
                    
                </div>
            </div>
        </div>

        <div id="ukid-modal-person-overflow" uk-modal>
            <div class="uk-modal-dialog">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <!--button v-on:click="delAndResendInvite(Object.assign({}, personV4))" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDeleteAndResendInvite}}</button-->
                    <button v-on:click="del(Object.assign({}, personV4))" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>

            </div>
        </div>

        <div id="ukid-filter" class="ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color">

            <dl class="uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center">

                <div class="uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165">
                    <span class="uk-hidden ukid-spinner-sm" uk-spinner="ratio:2.6"></span>
                    <span class="ukid-icon-sm ukid-icon-green-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:users;ratio:3.6"></span>                    
                </div>

                <div class="uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl">
                    <br />
                    <div class="ukid-padding ukid-padding-10px ukid-font-size-15">
                        Criação, atualização e exclusão de pessoas
                        <br />Total: {{pagination.databaseCount}}
                    </div>
                </div>

                <br class="uk-hidden@m uk-hidden@l uk-hidden@xl" />

                <dt>
                    Pesquisar
                </dt>

                <br />

                <dd>
                    <input v-on:keyup.enter="search()" v-model="searchParam" id="search-param-input" class="uk-input ukid-width-80-percent" type="text" placeholder="Pesquise qualquer termo">
                    <span v-on:click="search()" class="ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6" uk-icon="icon:search;ratio:1.4"></span>
                    <span class="uk-hidden ukid-search-spinner ukid-magin-left-6" uk-spinner="ratio:0.92"></span>
                </dd>

                <button @click="clear(true)" type="button" class="uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13">
                    Iniciar Novo Registro
                </button>

                <dt v-for="personV4 in registers" :key="personV4.personV4Collection.id">
                    <div class="uk-flex uk-flex-center">
                        <div class="uk-flex uk-flex-center ukid-height-90-px">
                            <div v-if="personV4.personV4Collection.principalCrop.snapshot && personV4.personV4Collection.highlightBlcFace" title="Esta pessoa foi marcada como ponto de atenção" uk-tooltip class="ukid-light-red-color ukid-circular-person-dt ukid-circular-person-dt-resolution">
                                <img :src="personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait" />
                            </div>
                            <div v-if="personV4.personV4Collection.principalCrop.snapshot && !personV4.personV4Collection.highlightBlcFace" class="ukid-light-blue-color ukid-circular-person-dt ukid-circular-person-dt-resolution">
                                <img v-if="!isBase64(personV4.personV4Collection.principalCrop.snapshot)" :src="personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait" />
                                <img v-if="isBase64(personV4.personV4Collection.principalCrop.snapshot)" :src="'data:image/jpeg;base64,' + personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait" />
                            </div>
                        </div>
                        <div class="uk-label ukid-width-55-percent ukid-magin-left-5 ukid-padding-top-15 ukid-font-size-10">
                            <span v-if="personV4.personV4Collection.checkedOnPublicDatabase === true">
                                <img title="Verificado em base pública" uk-tooltip src="content/images/checked-public-database-icon.png" width="26" />
                                <br />
                            </span>
                            <span class="ukid-font-size-9 ukid-text-lowercase">{{personV4.personV4Collection.id}}</span><br />
                            {{personV4.personV4Collection.id19}}<br />
                            <span v-if="personV4.personDocumentV4Collection.cardNumber">{{personV4.personDocumentV4Collection.cardNumber}}<br /></span>
                            {{personV4.personDocumentV4Collection.name}}<br />
                            <a v-if="!Strings.isNullOrEmpty(personV4.personDocumentV4Collection.email)" :href="'mailto:' + personV4.personDocumentV4Collection.email" class="ukid-white-color uk-visible@s">{{truncate(personV4.personDocumentV4Collection.email, 40)}}</a>
                            <a v-if="!Strings.isNullOrEmpty(personV4.personDocumentV4Collection.email)" :href="'mailto:' + personV4.personDocumentV4Collection.email" class="ukid-white-color uk-hidden@s">{{truncate(personV4.personDocumentV4Collection.email, 28)}}</a>
                            <br v-if="!Strings.isNullOrEmpty(personV4.personDocumentV4Collection.email)" />
                            {{new Date(personV4.personV4Collection.createTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                            <br />
                            <span class="ukid-text-alert-color ukid-font-size-9">proprietário: {{personV4.personV4Collection.user}}</span>
                            <br /><br />
                            <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-person-plus-animation' + personV4.personV4Collection.id" :uk-toggle="'target: #ukid-person-plus-animation-' + personV4.personV4Collection.id + '; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:plus;ratio:1.0"></span>                            
                            <span class="ukid-width-100-percent ukid-font-size-9" hidden :id="'ukid-person-plus-animation-' + personV4.personV4Collection.id">
                                <br /><br />
                                Grupos dessa Pessoa
                                <br />
                                <span v-for="group in groups" :key="group.id">
                                    <span v-html="personV4.personV4Collection.groupList && personV4.personV4Collection.groupList.includes(group.name) ? '- ' + group.description + '<br />' : ''"></span>
                                </span>
                            </span>
                        </div>
                        <div class="ukid-margin-top-28">
                            <span v-if="personV4" @click="fill(Object.assign({}, personV4))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:file-edit;ratio:0.9"></span>
                            <span v-if="personV4" @click="modal(Object.assign({}, personV4))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:trash;ratio:0.9"></span>
                        </div>
                    </div>
                </dt>

            </dl>
            
            <div v-show="!registers.length" class="uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30" uk-alert>
                <span class="uk-form-icon ukid-search-spinner ukid-magin-left-10 ukid-magin-right-10" uk-icon="icon:ban"></span>
                <span class="ukid-magin-left-20">
                    Nenhum registro encontrado
                </span>
            </div>

            <div v-show="pagination.last_page > 1" class="uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16">Pagina Atual: <b class="ukid-magin-left-10">{{page}}</b></div>

            <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>

                <paginate
                    v-model="page"
                    :page-count="pagination.last_page"
                    :click-handler="list"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>

            </ul>

        </div>        

        <div id="ukid-card-form" :class=" { 'uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-90-percent' : Commons.isMobile(), 'uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent' : !Commons.isMobile() } ">

            <div class="uk-card-header ukid-supress-border-bottom uk-visible@m">
                <div class="uk-grid-small uk-flex-middle ukid-min-height-150" uk-grid>
                    <div class="uk-width-auto ukid-min-width-165">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <div class="ukid-icon ukid-width-136-px">
                            <span v-if=" !('snapshot' in personV4.personV4Collection.principalCrop) || isBase64(personV4.personV4Collection.principalCrop.snapshot)" class="ukid-icon ukid-icon-green-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:users;ratio:5.2"></span>
                            <div v-if="personV4.personV4Collection.principalCrop.snapshot && !isBase64(personV4.personV4Collection.principalCrop.snapshot) && personV4.personV4Collection.highlightBlcFace" title="Esta pessoa foi marcada como ponto de atenção" uk-tooltip class="ukid-light-red-color ukid-circular-person-edit ukid-circular-person-dt-resolution ukid-circular-person-edit-resolution">
                                <img :src="personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait"  alt="" />
                            </div>                            
                            <div v-if="personV4.personV4Collection.principalCrop.snapshot && !isBase64(personV4.personV4Collection.principalCrop.snapshot) && !personV4.personV4Collection.highlightBlcFace" class="ukid-light-blue-color ukid-circular-person-edit ukid-circular-person-dt-resolution ukid-circular-person-edit-resolution">
                                <img :src="personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait"  alt="" />
                            </div>                            
                        </div>                        
                    </div>
                    <div class="uk-width-expand">
                        <span class="ukid-font-size-18">Gestão de Pessoas</span>
                        <p class="uk-margin-remove-top">
                            <span>
                                Criação, atualização e exclusão<br />
                                Módulo responsável pelo cadastramento facial de pessoas<br />
                                As galerias atuam como um segmentador de faces, ao selecioná-la, tenha certeza de sua escolha
                                <br />
                                <span class="uk-label ukid-label-green-color">Pessoas: {{pagination.databaseCount}}</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <progress class="uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0" value="0" max="100"></progress> 

            <div :class="{ 'uk-card-footer ukid-supress-border-top' : !Commons.isMobile(), 'uk-card-footer ukid-supress-border-top ukid-card-footer-mobile' : Commons.isMobile() }">

                <form class="ukid-form" @submit.prevent="save">

                    <fieldset class="uk-fieldset">

                        <legend class="uk-legend">                            
                            <span class="ukid-font-size-25 uk-visible@m">Formulário</span>
                            <br />
                        </legend>
                        
                        <div>
                            <button @click="clear(true)" type="button" class="uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13">
                                Iniciar Novo Registro
                            </button>
                            <a href="#ukid-add-fields-modal" class="uk-align-right" uk-toggle>
                                <span class="ukid-icon-yellow-color ukid-cursor-pointer" uk-icon="icon:plus;ratio:2.2"></span>
                            </a>
                        </div>

                        <div v-if="!isBase64(personV4.personV4Collection.principalCrop.snapshot)" class="uk-hidden@m ukid-width-136-px" align="center">
                            <div v-if="personV4.personV4Collection.principalCrop.snapshot && personV4.personV4Collection.highlightBlcFace" title="Esta pessoa foi marcada como ponto de atenção" uk-tooltip class="ukid-light-red-color ukid-circular-person-edit">
                                <img :src="personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait"  alt="" />
                            </div>                            
                            <div v-if="personV4.personV4Collection.principalCrop.snapshot && !personV4.personV4Collection.highlightBlcFace" class="ukid-light-blue-color ukid-circular-person-edit">
                                <img :src="personV4.personV4Collection.principalCrop.snapshot" class="ukid-circular-portrait"  alt="" />
                            </div>
                        </div>

                        <div v-show="visibleSnaphost" class="uk-margin ukid-margin-bottom-50">
                            <div class="ukid-video-camera">
                                <span class="ukid-video-text">
                                    Certifique-se que toda a face esteja no frame,<br />não faltando nenhuma das partes
                                </span>
                                <span class="ukid-video-buttom">
                                    <span @click="takeSnapshot" class="ukid-cursor-pointer" uk-icon="icon:camera;ratio:2.7"></span>
                                </span>
                                <div>
                                    <canvas id="ukid-camera-canvas" class="uk-hidden" width="640" height="480"></canvas>
                                    <video id="ukid-camera-stream" crossorigin="anonymous" width="640" height="480" style="border-radius: 20px"></video>
                                </div>                              
                            </div>
                        </div>

                        <div v-show="personV4.personV4Collection.id" class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">ID de Registro</span><br />
                            <div class="uk-inline ukid-width-100-percent ukid-font-size-16">
                                <b>{{personV4.personV4Collection.id}}</b>
                            </div>
                        </div>

                        <div v-show="personV4.personV4Collection.ffid" class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">Face ID</span><br />
                            <div class="uk-inline ukid-width-100-percent ukid-font-size-16">
                                <b>{{personV4.personV4Collection.ffid}}</b>
                            </div>
                        </div>                        

                        <div v-show="!visibleSnaphost" class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color uk-visible@s">
                                Clique na imagem circular para carregar uma foto
                                <br />
                                Clique na câmera para carregar a webcam
                            </span>
                            <br class="uk-visible@s" />
                            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                                Clique na imagem para<br />carregar uma foto
                            </span>
                            <br />                            
                            <div class="uk-inline ukid-container" title="Clique para utilizar selecionar um arquivo" uk-tooltip>                                
                                <base64-upload
                                    :imageStyle="{
                                        '-moz-opacity': '0.90', 
                                        'filter': 'alpha(opacity=90)', 
                                        '-khtml-opacity': '0.90', 
                                        'opacity': '0.90',
                                        'border-radius': '3%', 
                                        'width': '40%'}" 
                                    imageSrc="../../content/images/photo-icon.png" 
                                    @change="onChangeImage">
                                </base64-upload>
                                <canvas class="canvas uk-hidden"></canvas>
                            </div>
                        </div>

                        <div title="Clique para utilizar a WebCam" uk-tooltip v-show="!visibleSnaphost && !Commons.isMobile()" @click="startCamera" class="ukid-icon-tuscan-color ukid-cursor-pointer" uk-icon="icon:camera;ratio:2.7"></div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                                Duplo Fator de Autenticaçãos
                                <br />
                                <span class="ukid-font-size-9 ukid-text-alert-color">
                                    OBS: Valor padrão NÃO<br />
                                    Para SIM, consulte a equipe da<br />
                                    unike.TECH para maiores esclarecimentos
                                </span>
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-visible@s">
                                Duplo Fator de Autenticação
                                <br />
                                <span class="ukid-font-size-9 ukid-text-alert-color">
                                    OBS: Valor padrão NÃO<br />
                                    Para SIM, consulte a equipe da<br />
                                    unike.TECH para maiores esclarecimentos
                                </span>
                            </span>
                            <br />
                            <div class="uk-inline ukid-width-100-percent">
                                <div :class="{
                                        'ukid-width-100-percent': errors.has('enabled-input_field'), 
                                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                                    <label>
                                        <input v-model="personV4.personV4Collection.twoFactorAuthentication" value="true" class="uk-radio" type="radio" id="twoFactor-authentication-input-yes_field" name="twoFactor-authentication-type-input_field"> SIM
                                    </label>
                                    <label>
                                        <input v-model="personV4.personV4Collection.twoFactorAuthentication" value="false" class="uk-radio" type="radio" id="twoFactor-authentication-type-input-no_field" name="twoFactor-authentication-type-input_field" checked> NÃO
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!--div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">
                                Marcar Registro para Edição
                                <br />
                                <span class="ukid-font-size-9 ukid-text-alert-color">
                                    OBS: Quando marcado, o parâmetro<br /> 
                                    é exbido na verificação facial<br />
                                    ou na consulta ao registro
                                </span>
                            </span>
                            <br />
                            <input ref="recordMarkedForEditingEl" v-model="personV4.personV4Collection.recordMarkedForEditing" true-value="true" false-value="false" class="uk-checkbox ukid-margin-top-10" type="checkbox" :checked="personV4.personV4Collection.recordMarkedForEditing">
                        </div-->

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">
                                Marcar Registro para Envio de Alerta
                                <br />
                                <span class="ukid-font-size-9 ukid-text-alert-color">
                                    OBS: Quando marcado, envia<br />
                                    alertas por mensagens SMS / Whatsapp<br />
                                    quando a pessoa é identificada
                                </span>
                            </span>
                            <br />
                            <input ref="recordMarkedForAlertEl" v-model="personV4.personV4Collection.recordMarkedForAlert" true-value="true" false-value="false" class="uk-checkbox ukid-margin-top-10" type="checkbox" :checked="personV4.personV4Collection.recordMarkedForAlert">
                        </div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">
                                Cadastrar Registro em BLC
                                <br />
                                <span class="ukid-font-size-9 ukid-text-alert-color">
                                    OBS: Quando marcado, o registro<br />
                                    vai também para uma BLC.<br />
                                    Quando identificado ficará marcado.<br />
                                </span>
                            </span>
                            <br />
                            <input ref="recordHighlightBlcFace" v-model="personV4.personV4Collection.highlightBlcFace" true-value="true" false-value="false" class="uk-checkbox ukid-margin-top-10" type="checkbox" :checked="personV4.personV4Collection.highlightBlcFace">
                        </div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">
                                Acesso Permanente?
                            </span>
                            <br />
                            <input @click="permanentAccess()" class="ukid-permanent-access uk-checkbox ukid-margin-top-10" type="checkbox">
                        </div>

                        <div v-if="!Commons.isMobile()" :class="{ 'uk-margin ukid-margin-bottom-40' : !permAccess, 'uk-margin uk-hidden ukid-margin-bottom-40' : permAccess }">
                            <span class="uk-label ukid-primary-label-color">Intervalo de Acesso</span>
                            <br />
                            <div uk-grid>
                                <div>
                                    DE:
                                    <datetime
                                    v-model="personV4.personV4Collection.accessStartDatetime"
                                    type="datetime"
                                    input-class="mx-input ukid-width-100-percent"
                                    value-zone="America/Sao_Paulo"
                                    zone="America/Sao_Paulo"
                                    format="dd/MM/yyyy HH:mm"
                                    :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                    :hour-step="1"
                                    :minute-step="5"
                                    :min-datetime="new Date(new Date().setMinutes(new Date().getMinutes() - 5)).toISOString()"
                                    :max-datetime="personV4.personV4Collection.accessEndDatetime"
                                    :week-start="7"
                                    :auto="true"
                                    use24-hour></datetime>
                                </div>
                                <div></div>
                                <div>
                                    ATÉ:
                                    <datetime
                                    v-model="personV4.personV4Collection.accessEndDatetime"
                                    type="datetime"
                                    input-class="mx-input ukid-width-100-percent"
                                    value-zone="America/Sao_Paulo"
                                    zone="America/Sao_Paulo"
                                    format="dd/MM/yyyy HH:mm"
                                    :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                    :hour-step="1"
                                    :minute-step="5"
                                    :min-datetime="new Date(new Date().setHours(new Date().getHours() + 1)).toISOString()"
                                    :week-start="7"
                                    :auto="true"
                                    use24-hour></datetime>
                                </div>
                            </div>
                        </div>

                        <div v-if="Commons.isMobile()" :class="{ 'uk-margin ukid-margin-bottom-40' : !permAccess, 'uk-margin uk-hidden ukid-margin-bottom-40' : permAccess }">
                            <span class="uk-label ukid-primary-label-color">Intervalo de Acesso</span>
                            <br />
                            DE:
                            <datetime
                            v-model="personV4.personV4Collection.accessStartDatetime"
                            type="datetime"
                            input-class="mx-input ukid-width-90-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy HH:mm"
                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                            :hour-step="1"
                            :minute-step="5"
                            :min-datetime="new Date(new Date().setMinutes(new Date().getMinutes() - 5)).toISOString()"
                            :max-datetime="personV4.personV4Collection.accessEndDatetime"
                            :week-start="7"
                            :auto="true"
                            use24-hour></datetime>
                            <br />
                            ATÉ:
                            <datetime
                            v-model="personV4.personV4Collection.accessEndDatetime"
                            type="datetime"                            
                            input-class="mx-input ukid-width-90-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy HH:mm"
                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                            :hour-step="1"
                            :minute-step="5"
                            :min-datetime="new Date(new Date().setHours(new Date().getHours() + 1)).toISOString()"
                            :week-start="7"
                            :auto="true"
                            use24-hour></datetime>
                        </div>
                        
                        <div v-if="user.workShift" class="uk-margin">
                            <div class="js-upload uk-placeholder uk-text-left">
                                <div class="uk-text-center" uk-grid>
                                    <div class="uk-width-auto@m">
                                        <img src="content/images/app-icon-ponto.png" width="70"  alt="" />
                                    </div>
                                    <div class="uk-width-1-4@m">
                                        <div class="uk-text-left uk-text-bold ukid-font-size-15">
                                            Preencha os campos abaixo somente se estiver usando unike.PONTO
                                        </div>
                                    </div>
                                </div>
                                <div class="ukid-text-alert-color ukid-margin-top-30">
                                    Preencha os campos abaixo com cuidado, os mesmos serão
                                    <br />
                                    utilizados para geração do arquivo de ponto (AFD)
                                </div>
                                <div uk-form-custom class="ukid-margin-top-40">
                                    <div v-show="errors.has('entryTime-input_field') || errors.has('exitTime-input_field')" class="ukid-width-80-percent uk-alert-danger" uk-alert>
                                        <b>ATENÇÃO:</b> Os 2 campos abaixo são obrigatórios
                                        <br /><br />
                                        Se não estiver usando os campos abaixo, o mesmos podem ser desabilitados 
                                        nas <router-link to="/user/register">configurações do usuário</router-link> em 
                                        "HABILITAR PREENCHIMENTO DE TURNO NO CADASTRO DE PESSOA"
                                    </div>                                    
                                    <div v-if="!Commons.isMobile()" class="uk-margin ukid-margin-bottom-20">
                                        <div>
                                            HORÁRIO DE ENTRADA:
                                            <datetime
                                            v-model="entryTime"
                                            name="entryTime-input_field"
                                            style="width:150px !important"
                                            type="time"
                                            input-class="mx-input ukid-width-90-percent"
                                            value-zone="America/Sao_Paulo"
                                            zone="America/Sao_Paulo"
                                            format="HH:mm"
                                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                            :hour-step="1"
                                            :minute-step="1"
                                            v-validate="'required'"
                                            :auto="true"
                                            use24-hour></datetime>
                                        </div>
                                        <br />
                                        <div>
                                            HORÁRIO DE SAÍDA:
                                            <datetime
                                            name="exitTime-input_field"
                                            v-model="exitTime"
                                            style="width:150px !important"
                                            type="time"
                                            input-class="mx-input ukid-width-90-percent"
                                            value-zone="America/Sao_Paulo"
                                            zone="America/Sao_Paulo"
                                            format="HH:mm"
                                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                            :hour-step="1"
                                            :minute-step="1"
                                            v-validate="'required'"
                                            :auto="true"
                                            use24-hour></datetime>
                                        </div>
                                    </div>
                                    <div>
                                        <div v-if="Commons.isMobile()" class="uk-margin ukid-margin-bottom-20">
                                            HORÁRIO DE ENTRADA:
                                            <datetime
                                            v-model="entryTime"
                                            type="time"
                                            input-class="mx-input ukid-width-90-percent"
                                            value-zone="America/Sao_Paulo"
                                            zone="America/Sao_Paulo"
                                            format="HH:mm"
                                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                            :hour-step="1"
                                            :minute-step="1"
                                            v-validate="'required'"
                                            :auto="true"
                                            use24-hour></datetime>
                                            <br />
                                            HORÁRIO DE SAÍDA:
                                            <datetime
                                            v-model="exitTime"
                                            type="time"
                                            input-class="mx-input ukid-width-90-percent"
                                            value-zone="America/Sao_Paulo"
                                            zone="America/Sao_Paulo"
                                            format="HH:mm"
                                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                            :hour-step="1"
                                            :minute-step="1"
                                            v-validate="'required'"
                                            :auto="true"
                                            use24-hour></datetime>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">Número do Documento</span><br />
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>                            
                                <input
                                    :class="defaultClass('uk-input ukid-focus ', errors.has('cpf-input_field'))" 
                                    v-model="personV4.personV4Collection.id19"
                                    v-validate="'required|min:3|max:20|numeric'"
                                    c-data-vv-as="field"
                                    id="cpf-input_field"
                                    name="cpf-input_field"
                                    type="text"
                                    placeholder="Máximo 20 caracteres com somente números">
                            </div>
                        </div>

                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color">Número do Cartão de Acesso</span><br />
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>                            
                                <input
                                    :class="defaultClass('uk-input ukid-focus ', errors.has('cardNumber-input_field'))" 
                                    v-model="personV4.personDocumentV4Collection.cardNumber"
                                    v-validate="'min:1|max:40|numeric'"
                                    c-data-vv-as="field"
                                    id="cardNumber-input_field"
                                    name="cardNumber-input_field"
                                    type="text" 
                                    placeholder="Não obrigatório, mínimo 1, máximo 40 caracteres com somente números">
                            </div>
                        </div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">Nome</span><br />
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:user"></span>
                                <input 
                                    :class="defaultClass('uk-input ', errors.has('name-input_field'))" 
                                    v-model="personV4.personDocumentV4Collection.name"
                                    v-validate="'required|min:5|max:80'"
                                    data-vv-as="field"
                                    id="name-input_field"
                                    name="name-input_field" 
                                    type="text" 
                                    placeholder="Mínimo 5, máximo 80 caracteres">
                            </div>
                        </div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color">Email</span><br />
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:mail"></span>
                                <input
                                    :class="defaultClass('uk-input ', errors.has('email-input_field'))"
                                    v-model="personV4.personDocumentV4Collection.email"
                                    v-validate="'min:10|max:100|email'"
                                    data-vv-as="field"
                                    id="email-input_field"
                                    name="email-input_field" 
                                    type="text" 
                                    placeholder="Não obrigatório, mínimo 10, máximo 100 caracteres">
                            </div>
                        </div>

                        <!-- Aditional fields -->
                        <div ref="ukidAddedFields">
                            <div v-for="jks in jokers.jokerList" :key="jks.fieldName">
                                <div :id="`ukid-add-field-${jks.fieldName}`" class="uk-margin">
                                    <span class="uk-label ukid-primary-label-color">{{jks.fieldDesc}}</span><br />                                        
                                    <input v-if="jks.required" v-validate="'required'" data-validate="'required'" :class="errors.has(`${jks.fieldName}-input_field`) ? 'uk-input ukid-form-error ukid-width-80-percent' : 'uk-input ukid-width-80-percent'" type="text" aria-required="true" aria-invalid="true" data-vv-as="field" :id="`${jks.fieldName}-input_field`" :name="`${jks.fieldName}-input_field`" :data-value="jks.fieldValue" v-model="jks.fieldValue" />
                                    <input v-else class="uk-input ukid-width-80-percent" type="text" aria-required="true" aria-invalid="true" data-vv-as="field" :id="`${jks.fieldName}-input_field`" :name="`${jks.fieldName}-input_field`" :data-value="jks.fieldValue" v-model="jks.fieldValue" />
                                    <!--span title="Ao remover esse atributo, o mesmo será removido para todos os registros desse formuário" uk-tooltip @click="removeAttribute(jks.fieldName)" class="ukid-icon-yellow-color ukid-cursor-pointer" uk-icon="icon:trash;ratio:2.2"></span-->
                                    <span title="Temporariamente desabilitado" uk-tooltip class="ukid-disable-color ukid-cursor-pointer" uk-icon="icon:trash;ratio:2.2"></span>
                                </div>
                            </div>
                        </div>

                        <group-component
                            ref="groupCpnt"
                            :id="'group-select_field_1'"
                            :name="'group-select_field_1'"
                            :required="'required'"
                            :label="'Grupos'"
                            :description="'Ao selecionar um ou mais grupos para a pessoa, a mesma<br /> ficará disponível para todos <br />usuário(s) que tenham permissão no(s) grupo(s) selecionados(s), exceto grupos padrão de sistema'"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :vm="vm"
                            :entity="personV4.personV4Collection">

                        </group-component>

                        <users-component
                            ref="usersCpnt"
                            :id="'users-select_field_1'"
                            :name="'users-select_field_1'"
                            :required="'required'"
                            :label="'Usuário'"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :vm="vm"
                            :entity="personV4.personV4Collection">

                        </users-component>

                        <div class="uk-flex uk-flex-right uk-visible@s">
                            <div class="uk-visible@m">
                                <router-link to="/home">
                                    <button class="uk-button uk-button-default uk-button-large uk-align-center">Cancelar</button>                            
                                </router-link>
                            </div>
                            <div class="uk-hidden@m">
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-default uk-button-large uk-align-center">Voltar</button>
                            </div>                            
                            <div>
                                <button type="submit" class="uk-button uk-button-save uk-button-default uk-button-large uk-margin-left">Salvar</button>
                            </div>
                        </div>

                        <div class="uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40">
                            <div>
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14">Voltar</button>
                            </div>                            
                            <div>
                                <button type="submit" class="uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14">Salvar</button>
                            </div>
                        </div>                        

                    </fieldset>

                </form>

            </div>
        </div>
    </div>

</template>
