<!--suppress JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection,HtmlUnknownAttribute,HtmlDeprecatedAttribute,HtmlUnknownTag,RegExpRedundantEscape,RegExpSimplifiable,RegExpUnnecessaryNonCapturingGroupSpellCheckingInspection-->

<style src="../../../content/css/vue-multiselect.min.css"></style>

<style v-if="Commons.isMobile()">
    .ck-editor {
        width: 99% !important;
    }
</style>

<script type="text/javascript">

    import Vue from 'vue'

    import App from '../../app.json'

    import UIkit from 'uikit'

    import CKEditor from '@ckeditor/ckeditor5-vue'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

    import UploadAdapter from './upload'

    import { vm } from '../../js/main.js'
    import { Strings, Commons } from '../../js/common'

    import Configutation from './configutation.vue'

    import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
    
    Vue.use( CKEditor )

    let uploadConfig = function(editor) {
                
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        
            // Configure the URL to the upload script in your back-end here!
            return new UploadAdapter(loader, App.host)

        }

    }

    export default {

        components: {

            Configutation

        },

        data() {

            return {
                App: App,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'pt-br',
                    extraPlugins: [
                        uploadConfig
                    ],
                    toolbar: {
                        items: [
                            '|',
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'blockQuote',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'insertTable',
                            '|',
                            'imageUpload',
                            'imageStyle:full',
                            'imageTextAlternative',
                            '|',
                            'link',
                            '|',
                            'undo',
                            'redo'
                        ]
                    }
                },
                searchParam: '',
                pagination: {
                    last_page: 0
                },                
                registers: {},
                user: {
                    id: null,
                    name: null,
                    username: null,
                    password: null,
                    enabledEncryptionAPI: false,
                    enabledPasswordExpiration: false,
                    totalPasswordNonRepetition: 3,
                    minPasswordLength: 8,
                    expirationDaysPassword: 60,
                    enabledCredentialLock: false,
                    blockedAccess: false,
                    totalPasswordFailures: 10,
                    lastPasswordTimestamp: null,
                    gallery: null,
                    unknownGallery: null,
                    blcGallery: null,
                    bodyScore: '0.95',
                    matcherScore: '0.75',
                    matcherScoreOneToN: '0.75',
                    unknownMatcherScoreOneToN: '0.65',
                    livenessScore: '0.79',
                    temperatureHighScore: '37.8',
                    temperatureAttentionScore: '37.0',
                    credentialEditing: false,
                    inheritOwner: false,
                    inheritChildren: false,
                    biIntegration: false,
                    externalIntegration: false,
                    saveUnknownPerson: true,
                    plusFaceOnInvite: false,
                    showRegistrationImageOnDetection: false,
                    gae: true,
                    mask: true,
                    deduplication: true,
                    facialDebug: false,
                    userBlcEnabled: true,
                    coreBlcEnabled: false,
                    generateAFD: false,
                    workShift: false,
                    imageInvite: null,
                    livenessWebCertificatesEnabled: false,
                    livenessWebCustomerId: null,
                    livenessWebUrl: null,
                    livenessWebToken: null,                    
                    emailBlockedPasswordTitle: App.messages.emailBlockedPasswordTitle,
                    emailBlockedPasswordMessage: App.messages.emailBlockedPasswordMessage,
                    emailWarnBlockedPasswordTitle: App.messages.emailWarnBlockedPasswordTitle,
                    emailWarnBlockedPasswordMessage: App.messages.emailWarnBlockedPasswordMessage,
                    emailExpirationPasswordMessage: App.messages.emailExpirationPasswordMessage,                    
                    emailTitleMarking: App.messages.emailTitleMarking,
                    emailTitleInvite: App.messages.emailTitleInvite,
                    emailTitleReInvite: App.messages.emailTitleReInvite,
                    emailMessageAfd: App.messages.emailAfd,
                    emailMessageMarking: App.messages.emailMarking,                    
                    emailMessageInvite: App.messages.emailInvite,
                    emailMessageReInvite: App.messages.emailReInvite,
                    whatsappMessageInvite: App.messages.whatsappInvite,
                    whatsappMessageReInvite: App.messages.whatsappReInvite,
                    whatsappMessageMarking: App.messages.whatsappMarking,
                    expirationDaysInvite: 20,
                    selectedCertificateInformation: [],
                    selectedCredentialWarningEmails: [],
                    selectedAfdEmails: [],
                    serverUrlUploadAfd: null,
                    parentList: [],
                    groupList: [],
                    roles: ['user']
                },
                auth: '',
                role: 'user',
                page: 1,                
                vm: vm,
                Commons: Commons,
                Strings: Strings

            }

        },

        created() {

            let _ = this

            _.auth = vm.user.auth

            _.list()
            
        },

        methods: {

            search() {

                let _ = this

                vm.spinnerSearch()
                
                setTimeout(function(){

                    _.list(undefined, 1, App.pagination.itensPerPage)

                    vm.spinnerSearch() 

                }, App.layout.form.interval)

            },

            list(page) {

                let _ = this

                if (page !== undefined) {
                
                    _.page = page

                }

                _.$http.get(

                    App.host.concat(App.listUserEndpoint) 
                    + '?pageNum=' + (page === undefined ? 1 : page)
                    + '&perPage=' + App.pagination.itensPerPage
                    + '&param=' + _.searchParam

                ).then(response => {

                    _.registers = response.body.data
                    _.pagination = response.body

                })

            },

            fill(object) {

                let _ = this
            
                _.errors.clear()

                vm.spinnerWithProgress(App.layout.progress.increment, App.layout.progress.interval)

                setTimeout(function(){
                    
                    let rolesSelect = document.querySelector('#roles-select_field')
                    
                    Array.from(rolesSelect.options).forEach(item => {

                        item.selected = false

                        object.roles.forEach(function(role) {
                            
                            if (role === item.value) {

                                item.selected = true

                            }

                        })

                    })
                                        
                }, App.layout.form.interval)

                if (!object.hasOwnProperty('imageInvite')) {
                 
                    object.imageInvite = null

                }

                _.user = object
                _.role = _.user.roles[0]

                if (Strings.isNullOrEmpty(_.user.emailBlockedPasswordTitle))
                    _.user.emailBlockedPasswordTitle = App.messages.emailBlockedPasswordTitle

                if (Strings.isNullOrEmpty(_.user.emailBlockedPasswordMessage))
                    _.user.emailBlockedPasswordMessage = App.messages.emailBlockedPasswordMessage

                if (Strings.isNullOrEmpty(_.user.emailWarnBlockedPasswordTitle))
                    _.user.emailWarnBlockedPasswordTitle = App.messages.emailWarnBlockedPasswordTitle
                    
                if (Strings.isNullOrEmpty(_.user.emailWarnBlockedPasswordMessage))
                    _.user.emailWarnBlockedPasswordMessage = App.messages.emailWarnBlockedPasswordMessage
                    
                if (Strings.isNullOrEmpty(_.user.emailExpirationPasswordMessage))
                    _.user.emailExpirationPasswordMessage = App.messages.emailExpirationPasswordMessage                    

                if (Strings.isNullOrEmpty(_.user.emailTitleMarking))
                    _.user.emailTitleMarking = App.messages.emailTitleMarking

                if (Strings.isNullOrEmpty(_.user.emailTitleInvite))
                    _.user.emailTitleInvite = App.messages.emailTitleInvite

                if (Strings.isNullOrEmpty(_.user.emailTitleReInvite))
                    _.user.emailTitleReInvite = App.messages.emailTitleReInvite                    

                if (Strings.isNullOrEmpty(_.user.whatsappMessageInvite))
                    _.user.whatsappMessageInvite = App.messages.whatsappInvite                    

                if (Strings.isNullOrEmpty(_.user.whatsappMessageInvite))
                    _.user.whatsappMessageInvite = App.messages.whatsappInvite

                if (Strings.isNullOrEmpty(_.user.whatsappMessageReInvite)) 
                    _.user.whatsappMessageReInvite = App.messages.whatsappReInvite

                if (Strings.isNullOrEmpty(_.user.emailMessageInvite)) 
                    _.user.emailMessageInvite = App.messages.emailInvite
                    
                if (Strings.isNullOrEmpty(_.user.emailMessageReInvite)) 
                    _.user.emailMessageReInvite = App.messages.emailReInvite

                if (Strings.isNullOrEmpty(_.user.emailMessageAfd)) 
                    _.user.emailMessageAfd = App.messages.emailAfd

                if (Strings.isNullOrEmpty(_.user.emailMessageMarking))
                    _.user.emailMessageMarking = App.messages.emailMarking

                if (Strings.isNullOrEmpty(_.user.whatsappMessageMarking))
                    _.user.whatsappMessageMarking = App.messages.whatsappMarking

                setTimeout(function(){

                    _.$refs.configuration.$refs.marks.listMarkings()
                    _.$refs.configuration.$refs.livenessConfig.listLivenessWebTransactions()

                }, 350)

                // Refresh user resolution
                vm.configDeviceResolution()

                // Update component group
                _.$refs.configuration.$refs.generalConfig.$refs.userGroupCpnt.refresh()

                document.querySelector('.ukid-focus').focus()
                        
            },

            del(object) {

                let _ = this

                _.$http.delete(App.host.concat(App.deleteUserEndpoint).concat(object.username)).then(response => {

                    UIkit.notification.closeAll()
                    UIkit.notification({ 
                        message: App.notification.del, 
                        status: 'primary', 
                        pos: 'top-center', 
                        timeout: App.notification.timeout 
                    })

                    if (_.pagination.current_page > 0 && _.page === _.pagination.last_page && _.pagination.data.length === 1) {

                        _.list(_.page - 1)
                        _.$refs.configuration.$refs.marks.listMarkings(_.$refs.configuration.$refs.marks.markingPage - 1)
                        _.$refs.configuration.$refs.livenessConfig.listLivenessWebTransactions(_.$refs.configuration.$refs.livenessConfig.livenessWebTransactionsPage - 1)

                    } else {

                        _.list(_.page)
                        _.$refs.configuration.$refs.marks.listMarkings(_.$refs.configuration.$refs.marks.markingPage - 1)
                        _.$refs.configuration.$refs.livenessConfig.listLivenessWebTransactions(_.$refs.configuration.$refs.livenessConfig.livenessWebTransactionsPage - 1)

                    }

                    if (!Commons.isMobile()) {
                     
                        _.clear(false)

                    }

                }).catch(function(err) {
                        
                    if (err.status === 403) {
                        
                        UIkit.modal.alert(App.messages.actionDisableAdm)
                            
                    } else {
                    
                        UIkit.modal.alert(err.body.description)

                    }

                })

                _.$refs.configuration.$refs.credential.listAllGalleries()
                _.$refs.configuration.$refs.credential.fillUsers()

            },

            save() {
                
                let _ = this;
                
                _.$validator.validateAll().then((result) => {

                    if (!result) return

                    if (Strings.isNullOrEmpty(_.user.emailBlockedPasswordTitle) 
                        || Strings.isNullOrEmpty(_.user.emailWarnBlockedPasswordTitle) 
                        || Strings.isNullOrEmpty(_.user.emailTitleMarking) 
                        || Strings.isNullOrEmpty(_.user.emailTitleInvite) 
                        || Strings.isNullOrEmpty(_.user.emailTitleReInvite)) {

                            UIkit.modal.alert('O campo "Título De Email Bloqueio De Password", "Título De Aviso De Expiração De Senha",  "Título do Email da Marcação de Ponto", "Título do Email do Convite" e "Título do Email de Renovação do Convite" devem ser preenchidos')

                            return

                    }

                    if (!Strings.contains(_.user.emailBlockedPasswordMessage, '{name}') 
                        || !Strings.contains(_.user.emailBlockedPasswordMessage, '{username}')) {

                            UIkit.modal.alert('O campo "Mensagem De Email Bloqueio De Password" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome da instituição<br />{username} - Será substituído pelo usuário de acesso')

                            return

                    }

                    if (!Strings.contains(_.user.emailWarnBlockedPasswordMessage, '{name}') 
                        || !Strings.contains(_.user.emailWarnBlockedPasswordMessage, '{days}')) {

                            UIkit.modal.alert('O campo "Mensagem De Aviso De Expiração De Senha" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome da instituição<br />{days} - Será substituído pelos dias restantes')

                            return

                    }

                    if (!Strings.contains(_.user.emailExpirationPasswordMessage, '{name}')) {

                            UIkit.modal.alert('O campo "Mensagem De Aviso De Expiração De Senha" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome da instituição')

                            return

                    }

                    if (!Strings.contains(_.user.whatsappMessageMarking, '{name}') 
                        || !Strings.contains(_.user.whatsappMessageMarking, '{camera}') 
                        || !Strings.contains(_.user.whatsappMessageMarking, '{inviting}') 
                        || !Strings.contains(_.user.whatsappMessageMarking, '{timestamp}')) {

                            UIkit.modal.alert('O campo "Mensagem de Marcação de Ponto por Whatsapp" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome do funcionário<br />{camera} - Será substituído pela última câmera de detecção<br />{inviting} - Será substituído pelo nome do local onde o ponto foi batido<br />{timestamp} - Será substituído pela data e hora do ponto')

                            return

                    }

                    if (!Strings.contains(_.user.whatsappMessageInvite, '{name}') 
                        || !Strings.contains(_.user.whatsappMessageInvite, '{inviting}')) {

                            UIkit.modal.alert('O campo "Mensagem de Convite Enviado por Whatsapp" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome do convidado<br />{inviting} - Será substituído pelo nome de quem está convidando')

                            return

                    }

                    if (!Strings.contains(_.user.whatsappMessageReInvite, '{name}') 
                        || !Strings.contains(_.user.whatsappMessageReInvite, '{inviting}')) {

                            UIkit.modal.alert('O campo "Mensagem de Renovação de Convite Enviado por Whatsapp" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome do convidado<br />{inviting} - Será substituído pelo nome de quem está convidando<br />{invitation_type} - Será substituído pelo tipo de convite (permanente ou limitado)')

                            return

                    }

                    if (!Strings.contains(_.user.emailMessageMarking, '{name}') 
                        || !Strings.contains(_.user.emailMessageMarking, '{camera}') 
                        || !Strings.contains(_.user.emailMessageMarking, '{inviting}') 
                        || !Strings.contains(_.user.emailMessageMarking, '{timestamp}')) {

                            UIkit.modal.alert('O campo "Mensagem de Marcação de Ponto por Email" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome do funcionário<br />{camera} - Será substituído pela última câmera de detecção<br />{inviting} - Será substituído pelo nome do local onde o ponto foi batido<br />{timestamp} - Será substituído pela data e hora do ponto')

                            return

                    }

                    if (!Strings.contains(_.user.emailMessageInvite, '{name}') 
                        || !Strings.contains(_.user.emailMessageInvite, '{inviting}')
                        || !Strings.contains(_.user.emailMessageInvite, '{link}')) {

                            UIkit.modal.alert('O campo "Mensagem de Convite Enviado por Email" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome do convidado<br />{inviting} - Será substituído pelo nome de quem está convidando<br />{link} - Será substituído pelo link de acesso ao convite')

                            return

                    }

                    if (!Strings.contains(_.user.emailMessageReInvite, '{name}') 
                        || !Strings.contains(_.user.emailMessageReInvite, '{inviting}')) {

                            UIkit.modal.alert('O campo "Mensagem de Renovação de Convite Enviado por Email" deve conter pelo menos os seguintes parâmetros:<br /><br />{name} - Será substituído pelo nome do convidado<br />{inviting} - Será substituído pelo nome de quem está convidando<br />{invitation_type} - Será substituído pelo tipo de convite (permanente ou limitado)')

                            return

                    }

                    if (_.role !== 'admin') {

                        _.user.galleries = ['illusion']

                    }
                        
                    _.user.roles[0] = _.role

                    _.$http.post(App.host.concat(App.saveUserEndpoint), _.user).then(response => {

                        UIkit.notification.closeAll()
                        UIkit.notification({ message: App.notification.save, status: 'primary', pos: 'top-center', timeout: App.notification.timeout});

                        _.list(_.page)

                        _.clear()

                    }).catch(function(err) {
                        
                        if (err.status === 403) {
                            
                            UIkit.modal.alert(App.messages.actionDisableAdm)

                        } else {
                         
                            UIkit.modal.alert(err.body.description)

                        }
                        
                    }) 

                    if (_.auth.includes("admin")) {

                        _.$refs.configuration.$refs.credential.listAllGalleries()

                    }

                }).catch((err) => {

                    UIkit.modal.alert(err.body.description)

                })

            },

            clear(focus) {

                let _ = this

                _.user = {}

                _.user.id = null
                _.user.name = null
                _.user.username = null
                _.user.password = null
                _.user.enabledEncryptionAPI = false
                _.user.enabledPasswordExpiration = false
                _.user.enabledPasswordExpiration = false
                _.user.totalPasswordNonRepetition = 3
                _.user.minPasswordLength = 8
                _.user.expirationDaysPassword = 60
                _.user.enabledCredentialLock = false
                _.user.enabledCredentialLock = false
                _.user.blockedAccess = false
                _.user.totalPasswordFailures = 10
                _.user.lastPasswordTimestamp = null
                _.user.gallery = null
                _.user.unknownGallery= null
                _.user.blcGallery = null
                _.user.bodyScore = '0.95'
                _.user.matcherScore = '0.75'
                _.user.matcherScoreOneToN = '0.75'
                _.user.unknownMatcherScoreOneToN = '0.65'
                _.user.livenessScore = '0.79'
                _.user.temperatureHighScore = '37.8'
                _.user.temperatureAttentionScore = '37.0'
                _.user.credentialEditing = false
                _.user.inheritOwner = false
                _.user.inheritChildren = false
                _.user.biIntegration = false
                _.user.externalIntegration = false
                _.user.plusFaceOnInvite = false
                _.user.showRegistrationImageOnDetection = false
                _.user.saveUnknownPerson = false
                _.user.gae = true
                _.user.mask = false
                _.user.deduplication = true
                _.user.facialDebug = false
                _.user.userBlcEnabled = false
                _.user.coreBlcEnabled = false
                _.user.resendInviteWhenDeletingUser = false
                _.user.generateAFD = false
                _.user.workShift = false
                _.user.roles = ['user']
                _.role  = 'user'
                _.user.imageInvite = null

                _.livenessWebCertificatesEnabled = false
                _.user.livenessWebCustomerId = null
                _.user.livenessWebUrl = null
                _.user.livenessWebToken = null

                _.user.emailBlockedPasswordTitle = App.messages.emailBlockedPasswordTitle
                _.user.emailBlockedPasswordMessage = App.messages.emailBlockedPasswordMessage
                _.user.emailWarnBlockedPasswordTitle = App.messages.emailWarnBlockedPasswordTitle
                _.user.emailWarnBlockedPasswordMessage = App.messages.emailWarnBlockedPasswordMessage
                _.user.emailExpirationPasswordMessage = App.messages.emailExpirationPasswordMessage

                _.user.emailTitleMarking = App.messages.emailTitleMarking
                _.user.emailTitleInvite = App.messages.emailTitleInvite
                _.user.emailTitleReInvite = App.messages.emailTitleReInvite

                _.user.emailMessageAfd = App.messages.emailAfd
                _.user.emailMessageMarking = App.messages.emailMarking
                _.user.emailMessageInvite = App.messages.emailInvite
                _.user.emailMessageReInvite = App.messages.emailReInvite

                _.user.whatsappMessageMarking = App.messages.whatsappMarking
                _.user.whatsappMessageInvite = App.messages.whatsappInvite
                _.user.whatsappMessageReInvite = App.messages.whatsappReInvite

                _.user.selectedCertificateInformation = []
                _.user.selectedCredentialWarningEmails = []
                _.user.selectedAfdEmails = []

                _.user.serverUrlUploadAfd = null

                _.user.expirationDaysInvite = 20

                _.errors.clear()

                document.querySelector('.ukid-form').reset()

                if (focus) {
                 
                    document.querySelector('.ukid-focus').focus()

                }

                document.querySelector('.ukid-register-menu').click()

                vm.configDeviceResolution()

            },

            modal(user) {

                let _ = this

                _.user = user

                UIkit.modal('#modal-overflow').show()

            },

            truncate(param, size) {
                
                if (param === null) return

                return (param.length > size) ? param.substr(0, size-1) + '...' : param;

            }

        }

    }

</script>

<template id="register-template">

    <div class="uk-child-width-expand@s ukid-background-white-color" uk-grid>

        <div id="modal-overflow" uk-modal>
            <div class="uk-modal-dialog">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <button v-on:click="del(user)" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>

            </div>
        </div>

        <div id="ukid-filter" class="ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color">

            <dl class="uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center">

                <div class="uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165">
                    <span class="uk-hidden ukid-spinner-sm" uk-spinner="ratio:2.6"></span>
                    <span class="ukid-icon-sm ukid-icon-purple-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:happy;ratio:3.6"></span>                    
                </div>
                <div class="uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl">
                    <br />
                    <div class="ukid-padding ukid-padding-10px ukid-font-size-15">
                        Criação, atualização e exclusão de usuários
                        <br />Total: {{pagination.databaseCount}}
                    </div>
                </div>

		{{role}}

                <br class="uk-hidden@m uk-hidden@l uk-hidden@xl" />
	
                <dt>
                    Pesquisar
                </dt>

                <br />                

                <dd>
                    <input v-on:keyup.enter="search()" v-model="searchParam" id="search-param-input" class="uk-input ukid-width-80-percent" type="text" placeholder="Pesquise qualquer termo">
                    <span v-on:click="search()" class="ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6" uk-icon="icon:search;ratio:1.4"></span>
                    <span class="uk-hidden ukid-search-spinner ukid-magin-left-6" uk-spinner="ratio:0.92"></span>                                    
                </dd>

                <button @click="clear(true)" type="button" class="uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13">
                    Iniciar Novo Registro
                </button>

                <dt v-for="user in registers" :key="user.username">
                    <div class="uk-label ukid-width-70-percent">
                        {{truncate(user.name, 20)}} - {{user.username}}
                        <br />  
                        <span v-if="user.parentList.length" class="ukid-text-alert-color ukid-font-size-9">
                            Proprietário - {{user.parentList.filter(item => 'DIRECT' === item.type)[0].username}}
                        </span>
                    </div>

                    <span 
                        v-if="!Commons.isObjectNullOrEmpty(user) && ( auth.includes('admin') || auth.includes('user') )"
                        @click="fill(Object.assign({}, user))"
                        class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" 
                        uk-icon="icon:file-edit;ratio:0.9"></span>

                    <span 
                        v-if="!Commons.isObjectNullOrEmpty(user) && auth.includes('user') && user.username !== vm.user.username"
                        @click="modal(Object.assign({}, user))" 
                        class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" 
                        uk-icon="icon:trash;ratio:0.9">
                    </span>

                    <span 
                        v-if="!Commons.isObjectNullOrEmpty(user) && auth.includes('user') && user.username === vm.user.username"
                        class="ukid-disable-color ukid-magin-left-6 ukid-cursor-pointer" 
                        uk-icon="icon:trash;ratio:0.9"  
                        title="Ação desabilitada<br />A exclusão desse usuário, removerá por completo seu acesso e todos os outros logins atrelados a ele" 
                        uk-tooltip>
                    </span>

                    <span 
                        v-if="!Commons.isObjectNullOrEmpty(user) && auth.includes('admin')"
                        @click="modal(Object.assign({}, user))"
                        class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" 
                        uk-icon="icon:trash;ratio:0.9"></span>
                </dt>
                
            </dl>
            
            <div v-show="!registers.length" class="uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30" uk-alert>
                <span class="uk-form-icon ukid-search-spinner ukid-magin-left-10 ukid-magin-right-10" uk-icon="icon:ban"></span>
                <span class="ukid-magin-left-20">
                    Nenhum registro encontrado
                </span>
            </div>

            <div v-show="pagination.last_page > 1" class="uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16">Pagina Atual: <b class="ukid-magin-left-10">{{page}}</b></div>

            <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>

                <paginate
                    v-model="page"
                    :page-count="pagination.last_page"
                    :click-handler="list"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>

            </ul>

        </div>        

        <div id="ukid-card-form" :class=" { 'uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-90-percent' : Commons.isMobile(), 'uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent' : !Commons.isMobile() } ">

            <div class="uk-card-header ukid-supress-border-bottom uk-visible@m">
                <div class="uk-grid-small uk-flex-middle ukid-min-height-150" uk-grid>
                    <div class="uk-width-auto ukid-min-width-165">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-purple-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:happy;ratio:5.2"></span> 
                    </div>
                    <div class="uk-width-expand">
                        <span class="ukid-font-size-18" v-if="auth.includes('admin')">Gestão de Usuário</span>
                        <span class="ukid-font-size-18" v-if="auth.includes('user')">Gerir Meu Usuário</span>
                        <span class="uk-margin-remove-top" v-if="auth.includes('admin')">
                            <span>
                                Criação, atualização e exclusão<br />
                                <b class="uk-text-primary">Administrator:</b> Possue permissão total para gerenciamento da API<br />
                                <b class="uk-text-primary">Common User:</b> Utilizado como acesso no front-end e SDK's<br />
                                <br />
                                <div class="uk-label ukid-label-purple-color">Usuários: {{pagination.databaseCount}}</div>
                            </span>
                        </span>
                        <p v-if="auth.includes('user')" class="uk-margin-remove-top">
                            <span>
                                Permite atualizar os parâmetros do usuário, incluindo threshold de detecção facial e temperatura dos terminais.<br />
                                Ao alterar esses parâmetros, tenha em mente que isso afetara todas as detecção de câmeras cftv (se estiver usando)<br />
                                e caso altere o threshold das temperaturas, isso afetara todos os terminais que estejam configurados com esse usuário
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <progress class="uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0" value="0" max="100"></progress> 

            <div :class="{ 'uk-card-footer ukid-supress-border-top' : !Commons.isMobile(), 'uk-card-footer ukid-supress-border-top ukid-card-footer-mobile' : Commons.isMobile() }">

                <form class="ukid-form" @submit.prevent="save">

                    <fieldset class="uk-fieldset">

                        <legend class="uk-legend">
                            <span class="ukid-font-size-25 uk-visible@m">
                                Formulário
                            </span>
                            <span class="uk-text-lowercase ukid-icon-purple-color ukid-font-size-25" v-if="user.name"><br />Editando {{user.name}}<br />Username: {{user.username}}</span>
                            <button @click="clear(true)" type="button" class="uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13">
                                Iniciar Novo Registro
                            </button>
                        </legend>

                        <configutation
                            ref="configuration"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :editor="editor"
                            :editor-config="editorConfig"
                            :user="user"
                            :auth="auth"
                            :role="role"
                            :vm="vm"
                            :errors="errors">
                        </configutation>

                        <div class="uk-flex uk-flex-right uk-visible@s ukid-margin-top-40">
                            <div class="uk-visible@m">
                                <router-link to="/home">
                                    <button class="uk-button uk-button-default uk-button-large uk-align-center">Cancelar</button>                            
                                </router-link>
                            </div>
                            <div class="uk-hidden@m">
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-default uk-button-large uk-align-center">Voltar</button>
                            </div>
                            <div>
                                <button type="submit" class="uk-button uk-button-default uk-button-large uk-margin-left">Salvar</button>
                            </div>
                        </div>

                        <div class="uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40">
                            <div>
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14">Voltar</button>
                            </div>
                            <div>
                                <button type="submit" class="uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14">Salvar</button>
                            </div>
                        </div>

                    </fieldset>

                </form>

            </div>
        </div>
    </div>

</template>
