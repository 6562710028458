var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-flex uk-flex-center ukid-background-white-color ukid-padding-top-40 ukid-min-height"
    },
    [
      _c(
        "div",
        {
          staticClass: "uk-flex uk-flex-center uk-grid-medium uk-text-center",
          attrs: { "uk-grid": "" }
        },
        [
          _vm.auth.includes("admin") || _vm.auth.includes("user")
            ? _c(
                "router-link",
                { staticClass: "uk-hidden", attrs: { to: "/group" } },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-text-alert-color",
                            attrs: { "uk-icon": "icon:git-fork;ratio:1.9" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", [_vm._v("Gestão de Grupos de Acesso")]),
                          _vm._v(" "),
                          _vm.auth.includes("admin")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-text-uppercase ukid-font-size-10"
                                },
                                [
                                  _vm._v(
                                    "\n                            Total de Grupos: " +
                                      _vm._s(_vm.groupCount) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "ukid-icon-purple-color",
                                  attrs: { href: "#" }
                                },
                                [_vm._v("Criação, atualização e exclusão")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Crie grupos de acesso e controle nesta sessão, após a criação vá até o módulo de \n                            "
                              ),
                              _c(
                                "b",
                                { staticClass: "ukid-text-alert-color" },
                                [_vm._v("Base de Passoas")]
                              ),
                              _vm._v(" / "),
                              _c(
                                "b",
                                { staticClass: "ukid-text-alert-color" },
                                [_vm._v("Gestão de Equipamentos")]
                              ),
                              _vm._v(
                                "\n                            e faça a segmentação da maneira que achar mais conveniente\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") || _vm.auth.includes("user")
            ? _c("router-link", { attrs: { to: "/user/register" } }, [
                _c(
                  "div",
                  { staticClass: "ukid-width-250-px ukid-height-300-px" },
                  [
                    _c(
                      "div",
                      { staticClass: "uk-card uk-card-default uk-card-body" },
                      [
                        _c("span", {
                          staticClass: "ukid-icon-purple-color",
                          attrs: { "uk-icon": "icon:happy;ratio:1.9" }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _vm.auth.includes("admin")
                          ? _c("span", [_vm._v("Gestão de Usuários")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auth.includes("user")
                          ? _c("span", [_vm._v("Gerir Meus Usuários")])
                          : _vm._e(),
                        _c("br"),
                        _vm._v(" "),
                        _vm.auth.includes("admin")
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "uk-text-uppercase ukid-font-size-10"
                              },
                              [
                                _vm._v(
                                  "\n                            Total de Usuários: " +
                                    _vm._s(_vm.userCount) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auth.includes("admin")
                          ? _c(
                              "p",
                              { staticClass: "ukid-font-size-11 uk-text-left" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "ukid-icon-purple-color",
                                    attrs: { href: "#" }
                                  },
                                  [_vm._v("Criação, atualização e exclusão")]
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                            Módulo responsável pelo controle e criação de acesso pelos SDK's acessado pelos clientes\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auth.includes("user")
                          ? _c(
                              "p",
                              { staticClass: "ukid-font-size-11 uk-text-left" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "ukid-icon-purple-color",
                                    attrs: { href: "#" }
                                  },
                                  [_vm._v("Atualização de parâmetros")]
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                            Permite atualizar os parâmetros do usuário, incluindo threshold de detecção facial e temperatura dos terminais\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin")
            ? _c(
                "router-link",
                {
                  attrs: {
                    to:
                      _vm.App.ffVersion == "4.5"
                        ? "/matcher/gallery/v3"
                        : "/matcher/gallery"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-yellow-color",
                            attrs: { "uk-icon": "icon:folder;ratio:2.0" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        Gestão de Galerias"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-text-uppercase ukid-font-size-10"
                            },
                            [
                              _vm._v(
                                "Galerias Disponíveis: " +
                                  _vm._s(_vm.galleryCount)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "ukid-icon-yellow-color",
                                  attrs: { href: "#" }
                                },
                                [_vm._v("Criação, atualização e exclusão")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Criação, atualização e exclusão"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            As galerias são armazenadas diretamente em nosso matcher"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            São utilizadas para segmentar biometrias\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") || _vm.auth.includes("user")
            ? _c(
                "router-link",
                {
                  attrs: {
                    to:
                      _vm.App.ffVersion == "4.5"
                        ? "/matcher/camera/v3"
                        : "/matcher/camera"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-red-color",
                            attrs: { "uk-icon": "icon:video-camera;ratio:2.4" }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "ukid-icon-red-color",
                            attrs: { "uk-icon": "icon:more-vertical;ratio:1.3" }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "ukid-icon-red-color",
                            attrs: { "uk-icon": "icon:tablet;ratio:1.5" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        Gestão CFTV / Contagem de Pessoas / Terminais"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-text-uppercase ukid-font-size-10"
                            },
                            [
                              _vm._v(
                                "Cameras Disponíveis: " +
                                  _vm._s(_vm.cameraCount)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "ukid-icon-red-color",
                                  attrs: { href: "#" }
                                },
                                [_vm._v("Criação, atualização e exclusão")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            O processo de criação das câmeras / terminais de reconhecimento é necessário para o correto funcionamento das detecções faciais / aferições de temperatura\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") || _vm.auth.includes("user")
            ? _c(
                "router-link",
                { staticClass: "uk-hidden", attrs: { to: "/beacon" } },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-pink-color",
                            attrs: { "uk-icon": "icon:location;ratio:1.6" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        Gerenciamento de Beacons"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-text-uppercase ukid-font-size-10"
                            },
                            [_vm._v("total: " + _vm._s(_vm.beaconCount))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c("a", { staticClass: "ukid-icon-pink-color" }, [
                                _vm._v(
                                  "Permite o cadastramento de sensores de proximidade"
                                )
                              ]),
                              _c("br"),
                              _vm._v(
                                "\n                            Para que a koruja reconheça um sensor é necessário que o mesmo esteja cadastrado em nossa base de dados\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") ||
          _vm.auth.includes("user") ||
          _vm.auth.includes("signer")
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: _vm.App.ffVersion == "4.5" ? "/person/v3" : "/person"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-green-color",
                            attrs: { "uk-icon": "icon:users;ratio:2.1" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        Nossa Base de Pessoas"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-text-uppercase ukid-font-size-10"
                            },
                            [_vm._v("total: " + _vm._s(_vm.personCount))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                { staticClass: "ukid-icon-green-color" },
                                [
                                  _vm._v(
                                    "Mostra pessoas cadastradas em nossa base de dados"
                                  )
                                ]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Permite realizar pesquisas e outras operações\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") ||
          _vm.auth.includes("user") ||
          _vm.auth.includes("signer")
            ? _c("router-link", { attrs: { to: "matcher/personv4list" } }, [
                _c(
                  "div",
                  { staticClass: "ukid-width-250-px ukid-height-300-px" },
                  [
                    _c(
                      "div",
                      { staticClass: "uk-card uk-card-default uk-card-body" },
                      [
                        _c("span", {
                          staticClass: "ukid-icon-green-color",
                          attrs: { "uk-icon": "icon:users;ratio:2.1" }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v("\n                        Lista de Pessoas"),
                        _c("br")
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") ||
          _vm.auth.includes("user") ||
          _vm.auth.includes("signer")
            ? _c(
                "router-link",
                {
                  staticClass: "uk-hidden",
                  attrs: {
                    to:
                      _vm.App.ffVersion == "4.5"
                        ? "/invite/form/v3"
                        : "/invite/form"
                  }
                },
                [
                  _vm.auth.includes("admin") ||
                  _vm.auth.includes("user") ||
                  _vm.auth.includes("signer")
                    ? _c(
                        "div",
                        { staticClass: "ukid-width-250-px ukid-height-300-px" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-card uk-card-default uk-card-body"
                            },
                            [
                              _c("span", {
                                staticClass: "ukid-icon-yellow-color",
                                attrs: { "uk-icon": "icon:social;ratio:1.9" }
                              }),
                              _vm._v(" "),
                              _c("br"),
                              _c("br"),
                              _vm._v("\n                        Unike Invite"),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "ukid-font-size-11 uk-text-left"
                                },
                                [
                                  _c(
                                    "a",
                                    { staticClass: "ukid-icon-yellow-color" },
                                    [_vm._v("INVITE")]
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                            Já imaginou poder convidar alguém a fazer um cadastramento facial em sua rede, por email e whatsapp?\n                        "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "ukid-width-250-px ukid-height-300-px" }, [
            _c("div", { attrs: { id: "modal-detect-home", "uk-modal": "" } }, [
              _c("div", { staticClass: "uk-modal-dialog" }, [
                _c("button", {
                  staticClass: "uk-modal-close-default",
                  attrs: { type: "button", "uk-close": "" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "uk-modal-body" }, [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "uk-modal-title uk-text-center ukid-font-size-18"
                    },
                    [
                      _vm._v(
                        "\n                                Como você deseja prosseguir?\n                            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-text-center" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/matcher/proximity/v3" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "uk-button uk-button-default uk-modal-close ukid-width-250-px",
                              attrs: { type: "button" }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "content/images/accompaniment-face.png",
                                  width: "26"
                                }
                              }),
                              _vm._v(
                                " Acompanhar Faces\n                                    "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-text-center ukid-margin-top-5" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/matcher/verify/v3" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "uk-button uk-button-default uk-modal-close ukid-width-250-px",
                              attrs: { type: "button" }
                            },
                            [
                              _c("span", {
                                staticClass: "ukid-white-color",
                                attrs: { "uk-icon": "icon:search;ratio:1.2" }
                              }),
                              _vm._v(
                                " Verificações Faciais\n                                    "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-text-center" },
                    [
                      _vm.auth.includes("admin") || _vm.auth.includes("user")
                        ? _c(
                            "router-link",
                            { attrs: { to: "/matcher/bodies/v3" } },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "uk-button uk-button-default uk-modal-close ukid-width-250-px ukid-margin-top-5",
                                  attrs: { type: "button" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "content/images/count-people-icon.png",
                                      width: "24"
                                    }
                                  }),
                                  _vm._v(
                                    " Contagem de Pessoas\n                                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ukid-detect ukid-icon-blue-color",
                attrs: { href: "#modal-detect-home", "uk-toggle": "" }
              },
              [
                _c(
                  "div",
                  { staticClass: "uk-card uk-card-default uk-card-body" },
                  [
                    _c("span", {
                      staticClass: "ukid-icon-blue-color",
                      attrs: { "uk-icon": "icon:search;ratio:2.0" }
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n                        Pessoas nas Proximidades"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-text-uppercase ukid-font-size-10" },
                      [
                        _vm._v(
                          "Faces Detectadas: " + _vm._s(_vm.detectedFacesCount)
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-text-uppercase ukid-font-size-10" },
                      [
                        _vm._v(
                          "Contagem de Pessoas: " +
                            _vm._s(_vm.detectedBodiesCount)
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0)
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.auth.includes("admin") || _vm.vm.user.sub === "unike_demo"
            ? _c(
                "router-link",
                { staticClass: "uk-hidden", attrs: { to: "/education/demo" } },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-tuscan-color",
                            attrs: { "uk-icon": "icon:desktop;ratio:2.0" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v("\n                        unike.EDU"),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                { staticClass: "ukid-icon-tuscan-color" },
                                [_vm._v("Validação em background")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Módulo de demonstração para instituições de ensino a distância\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.vm.user.sub === "teleperformance"
            ? _c(
                "router-link",
                { staticClass: "uk-hidden", attrs: { to: "/matcher/tp/v3" } },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("img", {
                            attrs: {
                              src: "content/images/teleperformance.png",
                              width: "50"
                            }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        POC Teleperformance"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                { staticClass: "ukid-icon-tuscan-color" },
                                [_vm._v("Validação em background")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Módulo de demonstração de capturas Teleperformance\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.vm.user.sub === "beachpark"
            ? _c(
                "router-link",
                {
                  staticClass: "uk-hidden",
                  attrs: { to: "/matcher/proximity/v3" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("img", {
                            attrs: {
                              src: "content/images/beachpark.png",
                              width: "45"
                            }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v("\n                        POC BeachPark"),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                { staticClass: "ukid-icon-tuscan-color" },
                                [_vm._v("Validação em background")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Módulo de demonstração de capturas Beachpark\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.vm.user.sub !== "teleperformance" &&
            _vm.auth.includes("admin")) ||
          _vm.auth.includes("user")
            ? _c(
                "router-link",
                { staticClass: "uk-hidden", attrs: { to: "/matcher/tp/v3" } },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-tuscan-color",
                            attrs: { "uk-icon": "icon:home;ratio:1.9" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v("\n                        unike.WORK"),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                { staticClass: "ukid-icon-tuscan-color" },
                                [_vm._v("Validação em background")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Módulo de demonstração home office de capturas faciais em background\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin")
            ? _c(
                "router-link",
                { staticClass: "uk-hidden", attrs: { to: "/call/demo" } },
                [
                  _c(
                    "div",
                    { staticClass: "ukid-width-250-px ukid-height-300-px" },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-card uk-card-default uk-card-body" },
                        [
                          _c("span", {
                            staticClass: "ukid-icon-tuscan-color",
                            attrs: { "uk-icon": "icon:video-camera;ratio:2.5" }
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        Módulo de Demo Conf. Call"
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ukid-font-size-11 uk-text-left" },
                            [
                              _c(
                                "a",
                                { staticClass: "ukid-icon-tuscan-color" },
                                [_vm._v("Validação em background")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Módulo de demonstração de captura de face em conference call\n                        "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.includes("admin") || _vm.auth.includes("user")
            ? _c("router-link", { attrs: { to: "/server/home" } }, [
                _c(
                  "div",
                  { staticClass: "ukid-width-250-px ukid-height-300-px" },
                  [
                    _c(
                      "div",
                      { staticClass: "uk-card uk-card-default uk-card-body" },
                      [
                        _c("span", {
                          staticClass: "ukid-icon-yellow-color",
                          attrs: { "uk-icon": "icon:server;ratio:1.8" }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n                        Server em Tempo Real"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "ukid-font-size-11 uk-text-left" },
                          [
                            _vm._v(
                              "\n                            Módulo de monitoramento do servidor"
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("a", { staticClass: "ukid-icon-yellow-color" }, [
                              _vm._v("CPU, Memória, Discos e Rede")
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("cookie-law", { attrs: { theme: "royal", buttonText: "Aceitar" } }, [
        _c("div", { attrs: { slot: "message" }, slot: "message" }, [
          _vm._v(
            '\n                Ao clicar em "Aceitar", você automaticamente concorda com nossa política de privacidade,'
          ),
          _c("br"),
          _vm._v(
            "\n                armazenamento local e todos os cookies no seu dispositivo para melhorar a navegação no site,"
          ),
          _c("br"),
          _vm._v(
            "\n                analisar a utilização do site, ajudar nas nossas iniciativas de marketing e melhorias em nossos algoritimos."
          ),
          _c("br"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "ukid-detect",
              attrs: {
                target: "_blank",
                href:
                  "https://docs.unike.tech/uploads/docs/politica_de_privacidade_unike.pdf"
              }
            },
            [_vm._v("Ler nossa política de privacidade")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "ukid-font-size-11 uk-text-left" }, [
      _c("a", { staticClass: "ukid-icon-blue-color" }, [
        _vm._v("Pessoas em tempo real")
      ]),
      _c("br"),
      _vm._v(
        "\n                            Módulo para detecção de pessoas nas proximidades das câmeras RTSP\n                        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { attrs: { id: "modal-accept-cookies", "uk-modal": "" } },
      [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-body" }, [
            _c(
              "h2",
              {
                staticClass: "uk-modal-title uk-text-center ukid-font-size-18"
              },
              [
                _vm._v(
                  "\n                        Aceite de armazenamento local e cookies\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-text-center" }, [
              _vm._v("\n                        Texto\n                    ")
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cd9690bc", { render: render, staticRenderFns: staticRenderFns })
  }
}