<!--suppress JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection,HtmlUnknownAttribute,HtmlDeprecatedAttribute,HtmlUnknownTag,RegExpRedundantEscape,RegExpSimplifiable,RegExpUnnecessaryNonCapturingGroupSpellCheckingInspection-->
<script>

import App from "../../app.json"

import Multiselect from 'vue-multiselect'

import GroupComponent from "../group/group-component"
import UsersComponent from "../user/users-component"

export default {

    inject: ['$validator'],

    props: ['app', 'strings', 'commons', 'camera', 'users', 'groups', 'persons', 'vm', 'labels_'],

    components: {

        Multiselect,
        groupComponent: GroupComponent,
        usersComponent: UsersComponent

    },

    data() {

        return {

            parent_: this.$parent,
            selectedGroups: []

        }

    },

    computed: {

        terminal_external_integration: {
            get() {
                return this.camera.terminal_external_integration.join(';').replace(/[\;]/g, "\n")
            },
            set(value) {
                this.camera.terminal_external_integration = value.replace(/(?:\r\n|\r|\n)/g, ";").split(';')
            }
        }

    },

    methods: {

        findPersonByParam(searchParam) {

            let _ = this

            if (_.strings.isNullOrEmpty(searchParam)) {

                _.isPersonLoading = true

                _.$http.get(

                    App.host.concat(App.listPersonEndpoint) + '?pageNum=1&perPage=300&param=' + searchParam

                ).then(response => {

                    _.persons = response.body.data
                    _.isPersonLoading = false

                })

            }

        },

        addTag(newTag) {

            newTag = newTag
                .replace('url e token do cliente', 'external_url')
                .replace('alertar qualquer evento', 'alert_always')
                .replace('alertar pessoa marcada', 'alert_marked_person')
                .replace('alertar se pessoas desconhecidas', 'alert_unknown')
                .replace('alertar se pessoas conhecidas', 'alert_known')
                .replace('alertar se pessoas em blc', 'alert_blc')
                .replace('imagem enviada para o cliente', 'image_type')
                .replace('latitude do equipamento', 'latitude')
                .replace('longitude do equipamento', 'longitude')
                .replace('alertar temperatura de atencao', 'alert_attention_temperature')
                .replace('alertar temperatura alta', 'alert_high_temperature')
                .replace('template de mensagem do whatsapp', 'template')
                .replace('parametros do template do whatsapp', 'parameters')
                .replace('telefones que receberao as mensagens', 'phones')

            this.camera.terminal_external_integration.push(
                newTag
                    .replace('sim', 'true')
                    .replace('nao', 'false')
                    .replace('não', 'false')
            )

        },

        checkType(param) {

            if (param === 'terminal') {

                this.camera.serialNumber = null
                this.camera.terminalPassword = null
                this.camera.terminal_external_integration = [
                    "external_url = https://covid.unike.tech:8443/whatsapp/image | 294b4ac62941deb73d6df28e14c9e2e5e2b00884ad7fffd0ea0dc469189ec63556af21c00aee361b7f89ad1aab4f92e07cebec9675129cd2ce2716817995c181",
                    "alert_always = false",
                    "alert_marked_person = false",
                    "alert_unknown = false",
                    "alert_known = false",
                    "alert_blc = false",
                    "image_type = url",
                    "latitude = -00.0000",
                    "longitude = -00.0000",
                    "alert_attention_temperature = false",
                    "alert_high_temperature = false",
                    "template = default_person_arrived_in_the_camera",
                    "parameters = {person_name} | {camera}",
                    "phones = +5511XXXXXXXXX | +5511YYYYYYYYY"
                ]

            } else if (param === 'count_people') {

                this.persons = []
                this.camera.terminalUrl = `${App.host}/`
                this.camera.serialNumber = 'xxxxxxxxxx'
                this.camera.terminalPassword = 'unike_1234'
                this.camera.selectedPersons = []
                this.camera.terminal_external_integration = [
                    "external_url = https://covid.unike.tech:8443/whatsapp/image | 294b4ac62941deb73d6df28e14c9e2e5e2b00884ad7fffd0ea0dc469189ec63556af21c00aee361b7f89ad1aab4f92e07cebec9675129cd2ce2716817995c181",
                    "alert_always = false",
                    "alert_marked_person = false",
                    "alert_unknown = false",
                    "alert_known = false",
                    "alert_blc = false",
                    "image_type = url",
                    "latitude = -00.0000",
                    "longitude = -00.0000",
                    "alert_attention_temperature = false",
                    "alert_high_temperature = false",
                    "template = default_person_arrived_in_the_camera",
                    "parameters = {person_name} | {camera}",
                    "phones = +5511XXXXXXXXX | +5511YYYYYYYYY"
                ]

            } else {

                this.persons = []
                this.camera.terminalUrl = `${App.host}/`
                this.camera.serialNumber = 'xxxxxxxxxx'
                this.camera.terminalPassword = 'unike_1234'
                this.camera.selectedPersons = []
                this.camera.terminal_external_integration = [
                    "external_url = https://covid.unike.tech:8443/whatsapp/image | 294b4ac62941deb73d6df28e14c9e2e5e2b00884ad7fffd0ea0dc469189ec63556af21c00aee361b7f89ad1aab4f92e07cebec9675129cd2ce2716817995c181",
                    "alert_always = false",
                    "alert_marked_person = false",
                    "alert_unknown = false",
                    "alert_known = false",
                    "alert_blc = false",
                    "image_type = url",
                    "latitude = -00.0000",
                    "longitude = -00.0000",
                    "alert_attention_temperature = false",
                    "alert_high_temperature = false",
                    "template = default_person_arrived_in_the_camera",
                    "parameters = {person_name} | {camera}",
                    "phones = +5511XXXXXXXXX | +5511YYYYYYYYY"
                ]

            }

        },

    }

}

</script>

<template id="camera-common-template">

    <div>

        <div v-show="camera.id" class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Worker ID
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Worker ID
            </span>
            <br />
            <span class="uk-visible@s ukid-font-size-17">
                <b>{{camera.id}}</b>
            </span>
            <span class="uk-hidden@s ukid-font-size-15">
                <b>{{camera.id}}</b>
            </span>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Tipo de Dispositivo
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Tipo de Dispositivo
            </span>
            <br />
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('enabled-input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                    <label>
                        <input v-model="camera.type" @click="checkType('cftv')" value="cftv" class="uk-radio" type="radio" id="device-cftv-input_field" name="device-type-input_field" checked> <span class="uk-visible@s">CCTV COM WORKER</span><span class="uk-hidden@s">CFTV</span>
                    </label>
                    <label>
                        <input v-model="camera.type" @click="checkType('count_people')" value="count_people" class="uk-radio" type="radio" id="device-pc-input_field" name="device-type-input_field"> <span class="uk-visible@s">CONTAGEM DE PESSOAS COM WORKER</span><span class="uk-hidden@s">C. DE PESSOAS</span>
                    </label>
                    <label>
                        <input v-model="camera.type" @click="checkType('terminal')" value="terminal" class="uk-radio device-type-input_terminal" type="radio" id="device-terminal-input_field" name="device-type-input_field"> <span class="uk-visible@s">TERMINAL FACIAL INTELIGENTE</span><span class="uk-hidden@s">T. FACIAL INTELIGENTE</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="camera.type === 'terminal' && camera.id">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    CONFIGURAÇÃO ISAPI
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-general_filter_min_quality-1'" :uk-toggle="'target: #ukid-plus-animation-general_filter_min_quality-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-general_filter_min_quality-1">
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Faces de baixíssima qualidade<br />
                    1 = Alta qualidade<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    Configuração ISAPI - Câmeras Atomatizadas
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-general_filter_min_quality-2'" :uk-toggle="'target: #ukid-plus-animation-general_filter_min_quality-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-general_filter_min_quality-2">
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Faces de baixíssima qualidade<br />
                    1 = Alta qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">

                <span class="uk-text-uppercase uk-text-bold ukid-icon-pink-color">Host:</span> <span class="ukid-font-size-15 ukid-text-alert-color">{{camera.terminalUrl.replace('/rest/v3/', '')}}</span>
                <br />
                <span class="uk-text-uppercase uk-text-bold ukid-icon-pink-color">Para detecção de faces:</span> <span class="ukid-font-size-15 ukid-text-alert-color">/ISAPI/faces/default/{{camera.id}}</span>
                <br />
                <span class="uk-text-uppercase uk-text-bold ukid-icon-pink-color">Para detecção de corpos:</span> <span class="ukid-font-size-15 ukid-text-alert-color">/ISAPI/bodies/default/{{camera.id}}</span>

            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s" v-if="camera.type === 'cftv'">
                Habilite o CFTV
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s" v-if="camera.type === 'cftv'">
                Habilite o CFTV
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s" v-if="camera.type === 'terminal'">
                Habilite o Terminal Facial
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s" v-if="camera.type === 'terminal'">
                Habilite o Terminal Facial
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s" v-if="camera.type === 'count_people'">
                Habilite a Contagem de Pessoas
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s" v-if="camera.type === 'count_people'">
                Habilite a Contagem de Pessoas
            </span>
            <br />
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('enabled-input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                    <label>
                        <input v-model="camera.enabled" value="true" class="uk-radio" type="radio" id="enabled-input_field" name="enabled-input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.enabled" value="false" class="uk-radio" type="radio" id="disabled-input_field" name="enabled-input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Habilite o HeadPose
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Habilite o HeadPose
            </span>
            <br />
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('enabled-input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                    <label>
                        <input v-model="camera.headPoseEnabled" value="true" class="uk-radio" type="radio" id="enabled-headpose-input_field" name="enabled-headpose-input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.headPoseEnabled" value="false" class="uk-radio" type="radio" id="disabled-headpose-input_field" name="enabled-headpose-input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin" v-if="camera.headPoseEnabled === 'true' || camera.headPoseEnabled === true">

            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Tolerância do Pitch, Roll e Yaw
            </span>
            <br />
            <div class="uk-inline ukid-width-250-px">
                <span class="uk-text-bolder ukid-text-alert-color ukid-text-uppercase ukid-font-size-10">
                    Tolerância Pitch<br />
                    Negativo: Para baixo<br />
                    Positivo: Para cima
                </span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10': errors.has('pitch-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10': !errors.has('pitch-input_field')
                    }"
                    v-model="camera.pitch"
                    v-validate="'required|min:1|max:11'"
                    c-data-vv-as="field"
                    id="pitch-input_field"
                    name="pitch-input_field"
                    type="text"
                    placeholder="Ex: Pitch de -1000,1000">
                <span class="uk-text-bolder ukid-text-alert-color ukid-text-uppercase ukid-font-size-10">
                    Tolerância Roll<br />
                    Negativo: Inclinação para direita<br />
                    Positivo: Inclinação para esquerda
                </span>
                <br />
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10': errors.has('roll-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10': !errors.has('roll-input_field')
                    }"
                    v-model="camera.roll"
                    v-validate="'required|min:1|max:11'"
                    c-data-vv-as="field"
                    id="roll-input_field"
                    name="roll-input_field"
                    type="text"
                    placeholder="Ex: Roll de -1000,1000">
                <span class="uk-text-bolder ukid-text-alert-color ukid-text-uppercase ukid-font-size-10">
                    Tolerância Yaw<br />
                    Negativo: Direita<br />
                    Positivo: Esquerda
                </span>
                <br />
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10': errors.has('yaw-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10': !errors.has('yaw-input_field')
                    }"
                    v-model="camera.yaw"
                    v-validate="'required|min:1|max:11'"
                    c-data-vv-as="field"
                    id="yaw-input_field"
                    name="yaw-input_field"
                    type="text"
                    placeholder="Ex: Yaw de -1000,1000">
            </div>

        </div>

        <div class="uk-margin" v-show="camera.type === 'terminal'">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    Qualidade da Face
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-general_filter_min_quality-1'" :uk-toggle="'target: #ukid-plus-animation-general_filter_min_quality-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-general_filter_min_quality-1">
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Faces de baixíssima qualidade<br />
                    1 = Alta qualidade<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    Qualidade da Face
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-general_filter_min_quality-2'" :uk-toggle="'target: #ukid-plus-animation-general_filter_min_quality-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-general_filter_min_quality-2">
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Faces de baixíssima qualidade<br />
                    1 = Alta qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('general_filter_min_quality_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('general_filter_min_quality_input_field')
                    }"
                    v-model="camera.general_filter_min_quality"
                    v-validate="'required|between:0,1'"
                    c-data-vv-as="field"
                    id="general_filter_min_quality_input_field"
                    name="general_filter_min_quality_input_field"
                    type="text"
                    placeholder="Entre 0 e 1" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
               <span class="ukid-icon-pink-color">
                    Tamanho Mínimo de Face
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-min-face-size-1'" :uk-toggle="'target: #ukid-plus-animation-filter-min-face-size-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-min-face-size-1">
                    Valor padrão: 0 (filtro desativado)<br />
                    Tamanho mínimo de uma face em pixels<br />
                    As faces subdimensionadas não são postadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    Tamanho Mínimo de Face
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-min-face-size-2'" :uk-toggle="'target: #ukid-plus-animation-filter-min-face-size-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-min-face-size-2">
                    Valor padrão: 0 (filtro desativado)<br />
                    Tamanho mínimo de uma face em pixels<br />
                    As faces subdimensionadas não são postadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('filter_min_face_size_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('filter_min_face_size_input_field')
                    }"
                    v-model="camera.general_filter_min_face_size"
                    v-validate="'required|min:0|max:1000'"
                    c-data-vv-as="field"
                    id="filter_min_face_size_input_field"
                    name="filter_min_face_size_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1000" />
            </div>
        </div>

        <users-component
            ref="usersCameraCpnt"
            :id="'camera-users-select_field_1'"
            :name="'camera-users-select_field_1'"
            :required="'required'"
            :label="'Usuário'"
            :app="app"
            :strings="strings"
            :commons="commons"
            :vm="vm"
            :entity="camera">

        </users-component>

        <group-component
            ref="cameraGroupCpnt"
            :id="'camera-group-select_field_1'"
            :name="'camera-group-select_field_1'"
            :required="'required'"
            :label="'Grupos de Sistema'"
            :description="'(Ctrl + Clique) para mais de um grupo'"
            :app="app"
            :strings="strings"
            :commons="commons"
            :vm="vm"
            :entity="camera"
            :parent_="parent_"
        >

        </group-component>

        <div class="uk-margin" v-if="camera.type === 'terminal'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Dispositivo Acessado Somente Por:<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-selected-persons-1'" :uk-toggle="'target: #ukid-plus-animation-selected-persons-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-selected-persons-1">
                    Se não preenchido, todos os cadastros<br />
                    terão acesso ao terminal
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Dispositivo Acessado Somente Por:<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-selected-persons-2'" :uk-toggle="'target: #ukid-plus-animation-selected-persons-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-selected-persons-2">
                    Se não preenchido, todos os cadastros<br />
                    terão acesso ao terminal
                </span>
            </span>
            <multiselect v-model="camera.selectedPersons"
                         :options="persons"
                         :multiple="true"
                         :close-on-select="false"
                         :clear-on-select="true"
                         :preserve-search="false"
                         :preselect-first="true"
                         :show-labels="false"
                         selectLabel="Enter ou clique para marcar"
                         deselectLabel="Enter ou clique para marcar"
                         selectedLabel="Pessoa marcada"
                         placeholder="Pesquise pessoas digitando aqui"
                         label="name"
                         track-by="name"
                         @search-change="findPersonByParam">
                <template slot="noResult">
                    <span>
                        Nenhuma pessoa encontrada
                    </span>
                </template>
                <template slot="noOptions">
                    <span>
                        Comece a digitar
                    </span>
                </template>
                <template slot="tag" slot-scope="{ option, remove }">
                    <div class="multiselect__tag uk-text-center ukid-width-270-px">
                        <div class="uk-flex uk-flex-center ukid-margin-bottom-10 ukid-height-85-px">
                            <div class="ukid-light-blue-color ukid-circular-person-dt ukid-circular-person-dt-resolution">
                                <img :src="option.photo" class="ukid-circular-portrait"  alt="" />
                            </div>
                        </div>
                        <span>
                            {{option.documentNumber}}<br />
                            {{option.name}}
                        </span>
                        <br />
                        <span class="ukid-cursor-pointer ukid-margin-top-10 ukid-margin-bottom-10" uk-icon="icon:trash;ratio:1.2" @click="remove(option)"></span>
                    </div>
                </template>
                <template slot="option" slot-scope="props">
                    <table>
                        <tr>
                            <td width="100">
                                <div class="uk-flex uk-flex-center ukid-margin-bottom-10 ukid-height-85-px">
                                    <div class="ukid-light-blue-color ukid-circular-person-dt ukid-circular-person-dt-resolution">
                                        <img :src="props.option.photo" class="ukid-circular-portrait"  alt="" />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                            Docto: {{props.option.documentNumber}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Nome: {{props.option.name}}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </template>
            </multiselect>
        </div>

        <div class="uk-margin" v-if="camera.type === 'terminal'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Serial Number do Terminal<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-serial-number-1'" :uk-toggle="'target: #ukid-plus-animation-serial-number-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-serial-number-1">
                    Para projetos específicos,<br />considerar o seguinte formato:<br />
                    {serialNumber},{CNPJ},{CODIGO_SETOR}<br />
                    0000000000,00000000000000,0000000000
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Serial Number do Terminal<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-serial-number-2'" :uk-toggle="'target: #ukid-plus-animation-serial-number-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-serial-number-2">
                    Para projetos específicos, considerar o seguinte formato:<br />
                    {serialNumber},{CNPJ},{CODIGO_SETOR}<br />
                    0000000000,00000000000000,0000000000
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('serial-number-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('serial-number-input_field')
                    }"
                    v-model="camera.serialNumber"
                    v-validate="'required|min:5|max:45'"
                    c-data-vv-as="field"
                    id="serial-number-input_field"
                    name="serial-number-input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 45">
            </div>
        </div>

        <div class="uk-margin" v-if="camera.type === 'terminal'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                URL do Terminal
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                URL do Terminal
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:server"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('terminal-url-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('terminal-url-input_field')
                    }"
                    v-model="camera.terminalUrl"
                    v-validate="'required|url|min:5|max:300'"
                    c-data-vv-as="field"
                    id="terminal-url-input_field"
                    name="terminal-url-input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 300">
            </div>
        </div>

        <div class="uk-margin" v-if="camera.type === 'terminal'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Senha<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-terminal-password-1'" :uk-toggle="'target: #ukid-plus-animation-terminal-password-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-terminal-password-1">
                    Será Criptografada - Decriptada no Terminal<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Senha<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-terminal-password-2'" :uk-toggle="'target: #ukid-plus-animation-terminal-password-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-terminal-password-2">
                    Será Criptografada - Decriptada no Terminal<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:lock"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('terminal-password-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('terminal-password-input_field')
                    }"
                    v-model="camera.terminalPassword"
                    v-validate="'required|min:5|max:120'"
                    c-data-vv-as="field"
                    id="terminal-password-input_field"
                    name="terminal-password-input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 120">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Meta<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-meta-1'" :uk-toggle="'target: #ukid-plus-animation-meta-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-meta-1">
                    Identificador Definido pelo Usuário<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Meta<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-meta-2'" :uk-toggle="'target: #ukid-plus-animation-meta-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-meta-2">
                    Identificador Definido pelo Usuário<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:warning"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('meta-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('meta-input_field')
                    }"
                    v-model="camera.meta"
                    v-validate="'required|min:5|max:50'"
                    c-data-vv-as="field"
                    id="meta-input_field"
                    name="meta-input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 50">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Local<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-place-1'" :uk-toggle="'target: #ukid-plus-animation-place-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-place-1">
                    Descrição curta<br />do localização da câmera
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Local<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-place-2'" :uk-toggle="'target: #ukid-plus-animation-place-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-place-2">
                    Descrição curta do localização da câmera
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:warning"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('place-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('place-input_field')
                    }"
                    v-model="camera.place"
                    v-validate="'required|min:5|max:50'"
                    c-data-vv-as="field"
                    id="place-input_field"
                    name="place-input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 50">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Tipo de Local
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Tipo de Local
            </span>
            <br />
            <select
                :class="{
                    'uk-select ukid-form-error uk-textarea ukid-width-100-percent ukid-font-size-16': errors.has('place_type-select_field'),
                    'uk-select ukid-width-100-percent uk-textarea ukid-font-size-16': !errors.has('place_type-select_field')
                }"
                rows="7"
                v-model="camera.place_type"
                v-validate="'required|min:1|max:30'"
                data-vv-as="field"
                id="place_type-select_field"
                name="place_type-select_field">
                <option value="">-</option>
                <option value="open_room_location">Sala Aberta</option>
                <option value="closed_room_location">Sala Fechada</option>
                <option value="open_location">Local Aberto</option>
                <option value="closed_location">Local Fechado</option>
                <option value="open_hall">Corredor Aberto</option>
                <option value="closed_hall">Corredor Fechado</option>
                <option value="courtyard">Pátio</option>
            </select>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Tipo de Câmera
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Tipo de Câmera
            </span>
            <br />
            <select
                :class="{
                    'uk-select ukid-form-error uk-textarea ukid-width-100-percent ukid-font-size-16': errors.has('camera_type-select_field'),
                    'uk-select ukid-width-100-percent uk-textarea ukid-font-size-16': !errors.has('camera_type-select_field')
                }"
                rows="7"
                v-model="camera.camera_type"
                v-validate="'required|min:1|max:30'"
                data-vv-as="field"
                id="camera_type-select_field"
                name="camera_type-select_field">
                <option value="">-</option>
                <option value="TPay">Terminal de Pagamento</option>
                <option value="TIn">Ponto Eletônico Entrada</option>
                <option value="TOut">Ponto Eletônico Saída</option>
                <option value="TAIn">Câmera de Acesso - Entrada</option>
                <option value="TAOut">Câmera de Acesso - Saída</option>
                <option value="In">Câmera CFTV / CCTV de Entrada</option>
                <option value="Out">Câmera CFTV / CCTV de Saída</option>
                <option value="Tracking">Câmera CFTV / CCTV de Rastreamento</option>
            </select>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Grupo - Para agrupamento<br />
                de determinadas câmeras
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Grupo - Para agrupamento de determinadas câmeras
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:warning"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('group-input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('group-input_field')
                    }"
                    v-model="camera.group"
                    v-validate="'required|min:5|max:30'"
                    c-data-vv-as="field"
                    id="group-input_field"
                    name="group-input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 30">
            </div>
        </div>

        <div class="uk-margin" v-if="camera.type === 'cftv'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Labels - Servem como filtros para as câmeras<br />
                cadastradas no Video Manager<br />
                Geralmente usado para separar<br />
                instâncias diferentes de GPU
                Json Livre de Ex: "gpu":"0" ou "gpu":"instancia_1"
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Labels - Servem como filtros para as câmeras cadastradas no Video Manager<br />
                Geralmente usado para separar instâncias diferentes de GPU<br />
                Json Livre de Ex: "gpu":"0" ou "gpu":"instancia_1"
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:server"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('labels_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('labels_input_field')
                    }"
                    v-model="labels_"
                    v-validate="'min:5|max:100'"
                    c-data-vv-as="field"
                    id="labels_input_field"
                    name="labels_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 100">
            </div>
        </div>

        <div class="uk-margin" v-if="camera.type !== 'terminal'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                URL - Stream da câmera<br />(RTSP ou HTTP)
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                URL - Stream da câmera(RTSP ou HTTP)
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:server"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('stream_url_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('stream_url_input_field')
                    }"
                    v-model="camera.stream_url"
                    v-validate="'required|min:5|max:300'"
                    c-data-vv-as="field"
                    id="stream_url_input_field"
                    name="stream_url_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 300">
            </div>
        </div>

        <div class="uk-margin" v-if="camera.type !== 'terminal'">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Router URL - Endereço do WEB HUB / API<br />que processa as imagens<br /><br />
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Router URL - Endereço do WEB HUB / API que processa as imagens
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:server"></span>
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('router_url_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('router_url_input_field')
                    }"
                    v-model="camera.router_url"
                    v-validate="'required|min:5|max:300'"
                    c-data-vv-as="field"
                    id="router_url_input_field"
                    name="router_url_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 300">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Cabeçalho<br />AFD<br />
                PONTO FACIAL<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-afd-header-1'" :uk-toggle="'target: #ukid-plus-animation-afd-header-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-afd-header-1">
                    Ex: 00000000011CNPJ__________000000000000{razao_social}00005000485012580{data_inicial_registros}{data_final_registro}{data_geracao_arquivo}{horario_geracao}
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Cabeçalho Arquivo AFD - PONTO FACIAL<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-afd-header-2'" :uk-toggle="'target: #ukid-plus-animation-afd-header-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-afd-header-2">
                    Formato de Exemplo:<br />
                    00000000011CNPJ__________000000000000{razao_social}00005000485012580{data_inicial_registros}{data_final_registro}{data_geracao_arquivo}{horario_geracao}
                </span>
            </span>
            <br />
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon"></span>
                <textarea
                    wrap="physical"
                    :class="{
                        'ukid-text-area-external ukid-form-error uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px': errors.has('afd_header_input_field'),
                        'ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px': !errors.has('afd_header_input_field')
                    }"
                    v-model="camera.afd_header"
                    v-validate="'min:10|max:289'"
                    c-data-vv-as="field"
                    id="afd_header_input_field"
                    name="afd_header_input_field"
                    type="text"
                    placeholder="Mínimo 232, máximo 232"></textarea>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Integração com Sistemas<br />Externos<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-external-integration-1'" :uk-toggle="'target: #ukid-plus-animation-external-integration-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-text-lowercase ukid-font-size-12" hidden id="ukid-plus-animation-external-integration-1">
                    <br />
                    <span>Integrar com endpoints<br /></span>
                    <span class="ukid-icon-pink-color">url e token do cliente <span class="ukid-icon-yellow-color">=</span> https://host_1 <span class="ukid-icon-yellow-color">|</span> token_host_1 <span class="ukid-icon-yellow-color"> <span class="ukid-icon-yellow-color">,</span> </span> https://host_2 <span class="ukid-icon-yellow-color">|</span> token_host_2<br /></span>
                    <br />
                    <span>Determina se a<br />integração sempre<br /></span>
                    <span class="ukid-icon-pink-color">alertar qualquer evento <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determina se haverá alerta<br />de pessoas marcadas<br /></span>
                    <span class="ukid-icon-pink-color">alertar pessoa marcada <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determinal se haverá alerta<br />de pessoa desconhecida<br /></span>
                    <span class="ukid-icon-pink-color">alertar se pessoas desconhecidas <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determinal se haverá alerta<br />de pessoa conhecida<br /></span>
                    <span class="ukid-icon-pink-color">alertar se pessoas conhecidas <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determinal se haverá alerta<br />de pessoa em blc<br /></span>
                    <span class="ukid-icon-pink-color">alertar se pessoas em blc <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Define se a foto na entrega<br />da mensagem será base64 ou url<br /></span>
                    <span class="ukid-icon-pink-color">imagem enviada para o cliente <span class="ukid-icon-yellow-color">=</span> BASE64<br /></span>
                    <br />
                    <span>Determina a latitude<br />do equipamento<br /></span>
                    <span class="ukid-icon-pink-color">latitude do equipamento <span class="ukid-icon-yellow-color">=</span> 123<br /></span>
                    <br />
                    <span>Determina a longitude<br />do equipamento<br /></span>
                    <span class="ukid-icon-pink-color">longitude do equipamento <span class="ukid-icon-yellow-color">=</span> 321<br /></span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Definido no cadastro<br />do usuário, alerta quando temperatura<br />de atenção é alcançada<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        alertar temperatura de atencao <span class="ukid-icon-yellow-color">=</span> nao<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'terminal'">
                        Definido no cadastrado usuário,<br />alerta quando temperatura<br />alta é alcançada<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        alertar temperatura alta <span class="ukid-icon-yellow-color">=</span> nao<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Template whatssapp<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        template de mensagem do whatsapp <span class="ukid-icon-yellow-color">=</span> default_person_arrived_in_the_camera<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Parâmetros whatssapp<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        parametros do template do whatsapp <span class="ukid-icon-yellow-color">=</span> {person_name} <span class="ukid-icon-yellow-color">|</span> {camera}<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Determina para quais<br />telefones enviar a mensagem<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        telefones que receberao as mensagens <span class="ukid-icon-yellow-color">=</span> +5511922221111  <span class="ukid-icon-yellow-color">|</span> +5511933331111<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        mensagem de alerta que será<br />recebida no Whatsapp ou SMS<br />
                    </span>
                    <span>
                        Pode determinar atributo customizado<br />
                        O atributo será enviado<br />no endpoint "external_url"<br />
                    </span>
                    <span class="ukid-icon-pink-color">my_attribute <span class="ukid-icon-yellow-color">=</span> hello world<br /></span>
                    <br />
                    <span>
                        O quê será entregue?<br />
                    </span>
                    <code style="background-color: transparent; text-transform: none !important;">
                    <br />
                    {<br />
                        &nbsp;&nbsp;documentNumber: <span class="ukid-icon-yellow-color">:</span> "123456",
                        <br />&nbsp;&nbsp;type: <span class="ukid-icon-yellow-color">:</span> "TIn",
                        <br />&nbsp;&nbsp;latitude: <span class="ukid-icon-yellow-color">:</span> "-23.4988",
                        <br />&nbsp;&nbsp;longitude: <span class="ukid-icon-yellow-color">:</span> "-46.6998",
                        <br />&nbsp;&nbsp;snapshotBase64: <span class="ukid-icon-yellow-color">:</span> "base64==", // ou não enviado
                        <br />&nbsp;&nbsp;photoBase64: <span class="ukid-icon-yellow-color">:</span> "base64==", // ou não enviado
                        <br />&nbsp;&nbsp;temperature: <span class="ukid-icon-yellow-color">:</span> "36.7",
                        <br />&nbsp;&nbsp;temperatureState: <span class="ukid-icon-yellow-color">:</span> "alert", // ou "fever"
                        <br />&nbsp;&nbsp;confidenceOnDevice: <span class="ukid-icon-yellow-color">:</span> "-46.6998",
                        <br />&nbsp;&nbsp;usingMask: <span class="ukid-icon-yellow-color">:</span> "true",
                        <br />&nbsp;&nbsp;totalBodies: <span class="ukid-icon-yellow-color">:</span> "100",
                        <br />&nbsp;&nbsp;snapshotUrl: <span class="ukid-icon-yellow-color">:</span> "https://upload.unike.tech/uploads/somenthing/hash.jpeg",
                        <br />&nbsp;&nbsp;bboxInfoList: <span class="ukid-icon-yellow-color">:</span> "[<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;snapshot: "https://upload.unike.tech/uploads/somenthing/hash.jpeg",<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;totalBodies: "100",<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;timestamp: "1661791078000",<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;bboxList: [<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;left: 14,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;top: 50,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;right: 290,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bottom: 91<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;]<br />
                        &nbsp;&nbsp;]",
                        <br />&nbsp;&nbsp;liveness: <span class="ukid-icon-yellow-color">:</span> "true"
                    <br />}</code>
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Integração Externas<br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-external-integration-2'" :uk-toggle="'target: #ukid-plus-animation-external-integration-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-text-lowercase ukid-font-size-12" hidden id="ukid-plus-animation-external-integration-2">
                    <br />
                    <span>Integrar com endpoints<br /></span>
                    <span class="ukid-icon-pink-color">url e token do cliente <span class="ukid-icon-yellow-color">=</span> https://host_1 <span class="ukid-icon-yellow-color">|</span> token_host_1 <span class="ukid-icon-yellow-color"> <span class="ukid-icon-yellow-color">,</span> </span> https://host_2 <span class="ukid-icon-yellow-color">|</span> token_host_2<br /></span>
                    <br />
                    <span>Determina se a integração sempre<br /></span>
                    <span class="ukid-icon-pink-color">alertar qualquer evento <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determina se haverá alerta de pessoas marcadas<br /></span>
                    <span class="ukid-icon-pink-color">alertar pessoa marcada <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determinal se haverá alerta de pessoa desconhecida<br /></span>
                    <span class="ukid-icon-pink-color">alertar se pessoas desconhecidas <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determinal se haverá alerta de pessoa conhecida<br /></span>
                    <span class="ukid-icon-pink-color">alertar se pessoas conhecidas <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Determinal se haverá alerta de pessoa em blc<br /></span>
                    <span class="ukid-icon-pink-color">alertar se pessoas em blc <span class="ukid-icon-yellow-color">=</span> nao<br /></span>
                    <br />
                    <span>Define se a foto na entrega da mensagem será base64 ou url<br /></span>
                    <span class="ukid-icon-pink-color">imagem enviada para o cliente <span class="ukid-icon-yellow-color">=</span> BASE64<br /></span>
                    <br />
                    <span>Determina a latitude do equipamento<br /></span>
                    <span class="ukid-icon-pink-color">latitude do equipamento <span class="ukid-icon-yellow-color">=</span> 123<br /></span>
                    <br />
                    <span>Determina a longitude do equipamento<br /></span>
                    <span class="ukid-icon-pink-color">longitude do equipamento <span class="ukid-icon-yellow-color">=</span> 321<br /></span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Definido no cadastro do usuário, alerta quando temperatura de atenção é alcançada<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        alertar temperatura de atencao <span class="ukid-icon-yellow-color">=</span> nao<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'terminal'">
                        Definido no cadastrado usuário, alerta quando temperatura alta é alcançada<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        alertar temperatura alta <span class="ukid-icon-yellow-color">=</span> nao<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Template whatssapp<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        template de mensagem do whatsapp <span class="ukid-icon-yellow-color">=</span> default_person_arrived_in_the_camera<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Parâmetros whatssapp<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        parametros do template do whatsapp <span class="ukid-icon-yellow-color">=</span> {person_name} <span class="ukid-icon-yellow-color">|</span> {camera}<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        Determina para quais telefones enviar a mensagem<br />
                    </span>
                    <span class="ukid-icon-pink-color" v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        telefones que receberao as mensagens <span class="ukid-icon-yellow-color">=</span> +5511922221111  <span class="ukid-icon-yellow-color">|</span> +5511933331111<br />
                    </span>
                    <br />
                    <span v-if="camera.type === 'cftv' || camera.type === 'terminal'">
                        mensagem de alerta que será recebida no Whatsapp ou SMS<br />
                    </span>
                    <span>
                        Pode determinar atributo customizado<br />
                        O atributo será enviado no endpoint "external_url"<br />
                    </span>
                    <span class="ukid-icon-pink-color">my_attribute <span class="ukid-icon-yellow-color">=</span> hello world<br /></span>
                    <br />
                    <span>
                        O quê será entregue?<br />
                    </span>
                    <code style="background-color: transparent; text-transform: none !important;">
                    <br />
                    {<br />
                        &nbsp;&nbsp;documentNumber: <span class="ukid-icon-yellow-color">:</span> "123456",
                        <br />&nbsp;&nbsp;type: <span class="ukid-icon-yellow-color">:</span> "TIn",
                        <br />&nbsp;&nbsp;latitude: <span class="ukid-icon-yellow-color">:</span> "-23.4988",
                        <br />&nbsp;&nbsp;longitude: <span class="ukid-icon-yellow-color">:</span> "-46.6998",
                        <br />&nbsp;&nbsp;snapshotBase64: <span class="ukid-icon-yellow-color">:</span> "base64==", // ou não enviado
                        <br />&nbsp;&nbsp;photoBase64: <span class="ukid-icon-yellow-color">:</span> "base64==", // ou não enviado
                        <br />&nbsp;&nbsp;temperature: <span class="ukid-icon-yellow-color">:</span> "36.7",
                        <br />&nbsp;&nbsp;temperatureState: <span class="ukid-icon-yellow-color">:</span> "alert", // ou "fever"
                        <br />&nbsp;&nbsp;confidenceOnDevice: <span class="ukid-icon-yellow-color">:</span> "-46.6998",
                        <br />&nbsp;&nbsp;usingMask: <span class="ukid-icon-yellow-color">:</span> "true",
                        <br />&nbsp;&nbsp;totalBodies: <span class="ukid-icon-yellow-color">:</span> "100",
                        <br />&nbsp;&nbsp;snapshotUrl: <span class="ukid-icon-yellow-color">:</span> "https://upload.unike.tech/uploads/somenthing/hash.jpeg",
                        <br />&nbsp;&nbsp;bboxInfoList: <span class="ukid-icon-yellow-color">:</span> "[<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;snapshot: "https://upload.unike.tech/uploads/somenthing/hash.jpeg",<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;totalBodies: "100",<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;timestamp: "1661791078000",<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;bboxList: [<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;left: 14,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;top: 50,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;right: 290,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bottom: 91<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;]<br />
                        &nbsp;&nbsp;]",
                        <br />&nbsp;&nbsp;liveness: <span class="ukid-icon-yellow-color">:</span> "true"
                    <br />}</code>
                </span>
            </span>

            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon"></span>
                <multiselect
                    id="ukid-external-integration"
                    @tag="addTag"
                    v-model="camera.terminal_external_integration"
                    selectLabel="Enter ou clique para marcar"
                    deselectLabel="Enter ou clique para marcar"
                    selectedLabel="Grupo marcada"
                    placeholder="Adicione aqui atributo = parametro"
                    :options="camera.terminal_external_integration"
                    :hide-selected="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :multiple="true"
                    :taggable="true">
                    <template slot="tag" slot-scope="{ option, remove  }">
                        <table>
                            <tr>
                                <td>
                                    <div class="multiselect__tag" style="display: flex; font-size: 16px; white-space: normal; width: -webkit-min-content; min-width: 370px">
                                        <span contenteditable style="color:#0f4226; word-break: break-word !important">
                                            {{
                                                option.split('=')[0].trim() === 'external_url' ? 'url e token do cliente' :
                                                option.split('=')[0].trim() === 'alert_always' ? 'alertar qualquer evento' :
                                                option.split('=')[0].trim() === 'alert_marked_person' ? 'alertar pessoa marcada' :
                                                option.split('=')[0].trim() === 'alert_unknown' ? 'alertar se pessoas desconhecidas' :
                                                option.split('=')[0].trim() === 'alert_known' ? 'alertar se pessoas conhecidas' :
                                                option.split('=')[0].trim() === 'alert_blc' ? 'alertar se pessoas em blc' :
                                                option.split('=')[0].trim() === 'image_type' ? 'imagem enviada para o cliente' :
                                                option.split('=')[0].trim() === 'latitude' ? 'latitude do equipamento' :
                                                option.split('=')[0].trim() === 'longitude' ? 'longitude do equipamento' :
                                                option.split('=')[0].trim() === 'alert_attention_temperature' ? 'alertar temperatura de atencao' :
                                                option.split('=')[0].trim() === 'alert_high_temperature' ? 'alertar temperatura alta' :
                                                option.split('=')[0].trim() === 'template' ? 'template de mensagem do whatsapp' :
                                                option.split('=')[0].trim() === 'parameters' ? 'parametros do template do whatsapp' :
                                                option.split('=')[0].trim() === 'phones' ? 'telefones que receberao as mensagens' : option.split('=')[0].trim()
                                            }}<span style="padding: 10px; color: darkslategrey">=</span>{{
                                                option.split('=')[1].trim() === 'true' ? 'sim' :
                                                option.split('=')[1].trim() === 'false' ? 'nao' :
                                                option.split('=')[1].trim() ? option.split('=')[1] : 'vazio'
                                            }}
                                        </span>
                                    </div>
                                </td>
                                <td><span class="ukid-cursor-pointer ukid-margin-bottom-10" uk-icon="icon:trash;ratio:0.9" @click="remove(option)"></span></td>
                            </tr>
                        </table>
                    </template>
                    <template slot="noOptions">
                        <span>
                            Comece a digitar
                        </span>
                    </template>
                </multiselect>
            </div>
        </div>

    </div>

</template>
