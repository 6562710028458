var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    !_vm.user.roles.includes("signer") && _vm.role !== "signer"
      ? _c("div", { staticClass: "uk-margin" }, [
          _c(
            "span",
            { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
            [_vm._v("\n            Dias para Expiração do Convite\n        ")]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.expirationDaysInvite,
                expression: "user.expirationDaysInvite"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|decimal:2|between:7,50",
                expression: "'required|decimal:2|between:7,50'"
              }
            ],
            class: {
              "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                "expirationDaysInvite-core-select_field"
              ),
              "uk-input ukid-width-100-percent": !_vm.errors.has(
                "expirationDaysInvite-core-select_field"
              )
            },
            attrs: {
              "c-data-vv-as": "field",
              id: "expirationDaysInvite-core-select_field",
              name: "expirationDaysInvite-core-select_field",
              type: "text",
              placeholder: "Entre 7 e 50 dias"
            },
            domProps: { value: _vm.user.expirationDaysInvite },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "expirationDaysInvite", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c("div", { staticClass: "js-upload uk-placeholder uk-text-center" }, [
        _vm.user.imageInvite
          ? _c("div", { staticClass: "uk-text-center" }, [
              _c(
                "div",
                {
                  staticClass: "uk-inline-clip uk-transition-toggle",
                  attrs: { tabindex: "0" }
                },
                [
                  _c("img", {
                    attrs: { src: _vm.user.imageInvite, width: "150" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default"
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "uk-h4 uk-margin-remove ukid-cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.inviteLogoRemove()
                            }
                          }
                        },
                        [
                          _c("span", {
                            attrs: { "uk-icon": "icon:trash;ratio:1.4" }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.user.imageInvite ? _c("br") : _vm._e(),
        _vm._v(" "),
        _vm.user.imageInvite ? _c("br") : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-15" }, [
          _vm._v(
            "\n                O tamanho ideal para a imagem de convite é de máximo 512px de largura\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { attrs: { "uk-icon": "icon:cloud-upload;ratio:1.3" } }),
        _vm._v(" "),
        _c("span", { staticClass: "uk-text-middle ukid-font-size-15" }, [
          _vm._v("Anexe sua imagem que será exibida no convite")
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("br")
      ]),
      _vm._v(" "),
      _c("progress", {
        staticClass: "uk-progress",
        attrs: { id: "ukid-js-progressbar", value: "0", max: "100", hidden: "" }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n            Dias para Expiração\n            "),
        _c("br"),
        _vm._v("\n            do Convite\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { "uk-form-custom": "" } }, [
      _c("input", { attrs: { type: "file", multiple: "" } }),
      _vm._v(" "),
      _c("span", { staticClass: "uk-link ukid-font-size-15" }, [
        _vm._v("selecione uma aqui")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-775a4ba0", { render: render, staticRenderFns: staticRenderFns })
  }
}