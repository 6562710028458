var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent"
        },
        [
          _c(
            "div",
            { staticClass: "uk-card-header ukid-supress-border-bottom" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-60",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-16 ukid-margin-top-40 uk-hidden@s"
                      },
                      [_vm._v("Lista de Pessoas")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Valor selecionado: " + _vm._s(_vm.selectedForUnion)
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-relative-container" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.id19ListSearch,
                            expression: "id19ListSearch"
                          }
                        ],
                        staticClass:
                          "uk-textarea ukid-focus uk-form-width-1-2 uk-height-small uk-border-rounded uk-custom-textarea",
                        domProps: { value: _vm.id19ListSearch },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.id19ListSearch = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "uk-button uk-flex uk-button-default uk-width-1-1 uk-button-large uk-border-rounded uk-button-scale",
                          on: {
                            click: function($event) {
                              return _vm.list()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right",
                            attrs: { "uk-icon": "icon:search; ratio:1.3" }
                          }),
                          _vm._v("\n              Filtrar\n            ")
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            class: {
                              "uk-button-delete":
                                _vm.selectedForUnion.length > 0,
                              "uk-button-disable":
                                _vm.selectedForUnion &&
                                _vm.selectedForUnion.length === 0,
                              "uk-button-scale": true,
                              "uk-button-large": true,
                              "uk-border-rounded": true
                            },
                            attrs: {
                              href: "#modal-delete-user",
                              "uk-toggle": "",
                              disabled:
                                _vm.selectedForUnion &&
                                _vm.selectedForUnion.length === 0
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "uk-margin-small-right",
                              attrs: { "uk-icon": "icon:trash;ratio:1.3" }
                            }),
                            _vm._v("\n                Deletar\n              ")
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-card-footer ukid-supress-border-top" }, [
            _c(
              "div",
              { staticClass: "uk-flex uk-flex-center ukid-padding-top-20" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-flex uk-flex-center uk-grid-medium uk-text-center",
                    attrs: { "uk-grid": "" }
                  },
                  _vm._l(_vm.registers, function(personv4) {
                    return _c(
                      "div",
                      {
                        key: personv4.personV4Collection.id19,
                        staticClass: "ukid-offcanvas-over"
                      },
                      [
                        _c("ModalDeleteUser", {
                          attrs: {
                            modalId: "modal-delete-user",
                            clear: _vm.clearSelectedProfiles,
                            userList: _vm.selectedForUnion,
                            person: personv4
                          }
                        }),
                        _vm._v(" "),
                        _c("Personcard", {
                          attrs: {
                            id:
                              "modal-media-image-" +
                              personv4.personV4Collection.id19,
                            loggedUser: _vm.loggedUser,
                            id19: personv4.personV4Collection.id19,
                            age: personv4.personV4Collection.age,
                            gender: personv4.personV4Collection.gender,
                            principalCrop:
                              personv4.personV4Collection.principalCrop,
                            collection: personv4.personV4Collection,
                            document: personv4.personDocumentV4Collection,
                            selectedForUnion: _vm.selectedForUnion,
                            filters: _vm.filters,
                            workList: personv4.personV4Collection.workList,
                            firstDetect:
                              personv4.personV4Collection.firstDetect,
                            person: personv4
                          },
                          on: {
                            "update:selectedForUnion":
                              _vm.handleSelectedForUnionUpdate
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-flex uk-flex-center" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.registers.length,
                expression: "!registers.length"
              }
            ],
            staticClass:
              "uk-alert-primary ukid-margin-top-30 ukid-label-blue-color uk-width-1-2 uk-border-rounded uk-height-small",
            attrs: { "uk-alert": "" }
          },
          [
            _c("p", { staticClass: "ukid-white-color" }, [
              _c("span", {
                staticClass: "uk-margin-small-right uk-icon",
                attrs: { "uk-icon": "warning" }
              }),
              _vm._v(
                "\n        " + _vm._s(_vm.App.proximity.nobody) + "\n      "
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "uk-navbar-item uk-logo ukid-white-color ukid-font-size-13",
            attrs: { href: "../" }
          },
          [
            _c("img", {
              staticClass: "uk-margin-small-right",
              attrs: { src: "content/images/unike-logo-un.png", width: "120" }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner",
          attrs: { "uk-spinner": "ratio:4" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:search;ratio:5.0" }
        })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f754bcaa", { render: render, staticRenderFns: staticRenderFns })
  }
}