var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_format\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-format-1",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-format-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_format\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-format-2",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-format-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(1)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.ffmpeg_format,
              expression: "camera.stream_settings.ffmpeg_format"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:3|max:100",
              expression: "'min:3|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "ffmpeg_format_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "ffmpeg_format_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "ffmpeg_format_input_field",
            name: "ffmpeg_format_input_field",
            type: "text",
            placeholder: "Mínimo 3, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings.ffmpeg_format },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "ffmpeg_format",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_params\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-params-1",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-params-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(2)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_params\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-params-2",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-params-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(3)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.ffmpeg_params,
              expression: "camera.stream_settings.ffmpeg_params"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "ffmpeg_params_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "ffmpeg_params_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "ffmpeg_params_input_field",
            name: "ffmpeg_params_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings.ffmpeg_params },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "ffmpeg_params",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                md_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-md-threshold-1",
              "uk-toggle":
                "target: #ukid-plus-animation-md-threshold-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(4)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                md_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-md-threshold-2",
              "uk-toggle":
                "target: #ukid-plus-animation-md-threshold-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(5)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.md_threshold,
              expression: "camera.stream_settings.md_threshold"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1|decimal:3",
              expression: "'required|between:0,1|decimal:3'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "md_threshold_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "md_threshold_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "md_threshold_input_field",
            name: "md_threshold_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: { value: _vm.camera.stream_settings.md_threshold },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "md_threshold",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                md_scale\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-md-scale-1",
              "uk-toggle":
                "target: #ukid-plus-animation-md-scale-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(6)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                md_scale\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-md-scale-2",
              "uk-toggle":
                "target: #ukid-plus-animation-md-scale-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(7)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.md_scale,
              expression: "camera.stream_settings.md_scale"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1|decimal:3",
              expression: "'required|between:0,1|decimal:3'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "md_scale_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "md_scale_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "md_scale_input_field",
            name: "md_scale_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: { value: _vm.camera.stream_settings.md_scale },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "md_scale",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                fd_frame_height\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-fd-frame-height-1",
              "uk-toggle":
                "target: #ukid-plus-animation-fd-frame-height-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(8)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                fd_frame_height\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-fd-frame-height-2",
              "uk-toggle":
                "target: #ukid-plus-animation-fd-frame-height-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(9)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.fd_frame_height,
              expression: "camera.stream_settings.fd_frame_height"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:-1,720",
              expression: "'required|between:-1,720'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "fd_frame_height_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "fd_frame_height_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "fd_frame_height_input_field",
            name: "fd_frame_height_input_field",
            type: "text",
            placeholder: "Entre -1 e 720"
          },
          domProps: { value: _vm.camera.stream_settings.fd_frame_height },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "fd_frame_height",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                uc_max_time_diff\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-uc-max-time-diff-1",
              "uk-toggle":
                "target: #ukid-plus-animation-suc-max-time-diff-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(10)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                uc_max_time_diff\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-uc-max-time-diff-2",
              "uk-toggle":
                "target: #ukid-plus-animation-uc-max-time-diff-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(11)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.uc_max_time_diff,
              expression: "camera.stream_settings.uc_max_time_diff"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1,200",
              expression: "'required|between:1,200'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "uc_max_time_diff_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "uc_max_time_diff_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "uc_max_time_diff_input_field",
            name: "uc_max_time_diff_input_field",
            type: "text",
            placeholder: "Entre 1 e 200"
          },
          domProps: { value: _vm.camera.stream_settings.uc_max_time_diff },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "uc_max_time_diff",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                uc_max_dup\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-uc-max-dup-1",
              "uk-toggle":
                "target: #ukid-plus-animation-uc-max-dup-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(12)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                uc_max_dup\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-uc-max-dup-2",
              "uk-toggle":
                "target: #ukid-plus-animation-uc-max-dup-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(13)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.uc_max_dup,
              expression: "camera.stream_settings.uc_max_dup"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1,20",
              expression: "'required|between:1,20'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "uc_max_dup_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "uc_max_dup_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "uc_max_dup_input_field",
            name: "uc_max_dup_input_field",
            type: "text",
            placeholder: "Entre 1 e 20"
          },
          domProps: { value: _vm.camera.stream_settings.uc_max_dup },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "uc_max_dup",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                uc_max_avg_shift\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-uc-max-avg-shift-1",
              "uk-toggle":
                "target: #ukid-plus-animation-uc-max-avg-shift-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(14)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                uc_max_avg_shift\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-uc-max-avg-shift-1",
              "uk-toggle":
                "target: #ukid-plus-animation-uc-max-avg-shift-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(15)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.uc_max_avg_shift,
              expression: "camera.stream_settings.uc_max_avg_shift"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1,100",
              expression: "'required|between:1,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "uc_max_avg_shift_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "uc_max_avg_shift_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "uc_max_avg_shift_input_field",
            name: "uc_max_avg_shift_input_field",
            type: "text",
            placeholder: "Entre 1 e 100"
          },
          domProps: { value: _vm.camera.stream_settings.uc_max_avg_shift },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "uc_max_avg_shift",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.det_period,
              expression: "camera.stream_settings.det_period"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "",
              expression: "''"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "det_period_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "det_period_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "det_period_input_field",
            name: "det_period_input_field",
            type: "text",
            placeholder: ""
          },
          domProps: { value: _vm.camera.stream_settings.det_period },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "det_period",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(16)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(17)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has("realtime_input_field"),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "realtime_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.realtime,
                    expression: "camera.stream_settings.realtime"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "realtime_input_field_yes",
                  name: "realtime_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.realtime, "true")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "realtime",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.realtime,
                    expression: "camera.stream_settings.realtime"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "realtime_input_field_no",
                  name: "realtime_input_field"
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.realtime, "false")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "realtime",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                npersons\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-npersons-1",
              "uk-toggle":
                "target: #ukid-plus-animation-npersons-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(18)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                npersons\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-npersons-2",
              "uk-toggle":
                "target: #ukid-plus-animation-npersons-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(19)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.npersons,
              expression: "camera.stream_settings.npersons"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1,100",
              expression: "'required|between:1,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "npersons_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "npersons_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "npersons_input_field",
            name: "npersons_input_field",
            type: "text",
            placeholder: "Entre 1 e 100"
          },
          domProps: { value: _vm.camera.stream_settings.npersons },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "npersons",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                tracker_threads\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-tracker-threads-1",
              "uk-toggle":
                "target: #ukid-plus-animation-tracker-threads-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(20)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                tracker_threads\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-tracker-threads-1",
              "uk-toggle":
                "target: #ukid-plus-animation-tracker-threads-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(21)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.tracker_threads,
              expression: "camera.stream_settings.tracker_threads"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1,100",
              expression: "'required|between:1,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "tracker_threads_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "tracker_threads_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "tracker_threads_input_field",
            name: "tracker_threads_input_field",
            type: "text",
            placeholder: "Entre 1 e 100"
          },
          domProps: { value: _vm.camera.stream_settings.tracker_threads },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "tracker_threads",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                disable_drops\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-disable-drops-1",
              "uk-toggle":
                "target: #ukid-plus-animation-disable-drops-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(22)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                disable_drops\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-disable-drops-2",
              "uk-toggle":
                "target: #ukid-plus-animation-disable-drops-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(23)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "disable_drops_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "disable_drops_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.disable_drops,
                    expression: "camera.stream_settings.disable_drops"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "disable_drops_input_field_yes",
                  name: "disable_drops_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.disable_drops,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "disable_drops",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.disable_drops,
                    expression: "camera.stream_settings.disable_drops"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "disable_drops_input_field_no",
                  name: "disable_drops_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.disable_drops,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "disable_drops",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                parse_sei\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-parse-sei-1",
              "uk-toggle":
                "target: #ukid-plus-animation-parse-sei-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(24)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                parse_sei\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-parse_sei-2",
              "uk-toggle":
                "target: #ukid-plus-animation-parse-sei-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(25)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has("parse_sei_input_field"),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "parse_sei_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.parse_sei,
                    expression: "camera.stream_settings.parse_sei"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "parse_sei_input_field_yes",
                  name: "parse_sei_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.parse_sei, "true")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "parse_sei",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.parse_sei,
                    expression: "camera.stream_settings.parse_sei"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "parse_sei_input_field_no",
                  name: "parse_sei_input_field"
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.parse_sei, "false")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "parse_sei",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                image_arg\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-image-arg-1",
              "uk-toggle":
                "target: #ukid-plus-animation-image-arg-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(26)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                image_arg\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-image-arg-2",
              "uk-toggle":
                "target: #ukid-plus-animation-image-arg-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(27)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.image_arg,
              expression: "camera.stream_settings.image_arg"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:1|max:20",
              expression: "'required|min:1|max:20'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "image_arg_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "image_arg_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "image_arg_input_field",
            name: "image_arg_input_field",
            type: "text",
            placeholder: "Mínimo 1, máximo 20"
          },
          domProps: { value: _vm.camera.stream_settings.image_arg },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "image_arg",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _vm._m(28),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-additional-headers-1",
              "uk-toggle":
                "target: #ukid-plus-animation-additional-headers-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(29)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _vm._m(30),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-additional-headers-2",
              "uk-toggle":
                "target: #ukid-plus-animation-additional-headers-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(31)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.additional_headers,
              expression: "additional_headers"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:0|max:2000",
              expression: "'min:0|max:2000'"
            }
          ],
          class: {
            "ukid-text-area-external uk-textarea ukid-form-error ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
              "additional_headers_input_field"
            ),
            "ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
              "additional_headers_input_field"
            )
          },
          attrs: {
            rows: "5",
            "c-data-vv-as": "field",
            id: "additional_headers_input_field",
            name: "additional_headers_input_field",
            type: "textarea"
          },
          domProps: { value: _vm.additional_headers },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.additional_headers = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                additional_body\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-additional-body-1",
              "uk-toggle":
                "target: #ukid-plus-animation-additional-body-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(32)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                additional_body\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-additional-body-2",
              "uk-toggle":
                "target: #ukid-plus-animation-additional-body-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(33)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.additional_body,
              expression: "additional_body"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:5|max:1000",
              expression: "'required|min:5|max:1000'"
            }
          ],
          class: {
            "ukid-text-area-external ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
              "additional_body_input_field"
            ),
            "ukid-text-area-external uk-input ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
              "additional_body_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "additional_body_input_field",
            name: "additional_body_input_field",
            type: "textarea",
            placeholder: "Mínimo 5, máximo 1000"
          },
          domProps: { value: _vm.additional_body },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.additional_body = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        { staticClass: "uk-label ukid-primary-label-color ukid-font-size-12" },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                api_timeout\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-api-timeout-1",
              "uk-toggle":
                "target: #ukid-plus-animation-api-timeout-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(34)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.api_timeout,
              expression: "camera.stream_settings.api_timeout"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1000,30000",
              expression: "'required|between:1000,30000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "api_timeout_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "api_timeout_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "api_timeout_input_field",
            name: "api_timeout_input_field",
            type: "text",
            placeholder: "Entre 1000 e 30000"
          },
          domProps: { value: _vm.camera.stream_settings.api_timeout },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "api_timeout",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                api_ssl_verify\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-api-ssl-verify-1",
              "uk-toggle":
                "target: #ukid-plus-animation-api-ssl-verify-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(35)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                api_ssl_verify\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-api-ssl-verify-2",
              "uk-toggle":
                "target: #ukid-plus-animation-api-ssl-verify-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(36)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "api_ssl_verify_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "api_ssl_verify_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.api_ssl_verify,
                    expression: "camera.stream_settings.api_ssl_verify"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "api_ssl_verify_input_field_yes",
                  name: "api_ssl_verify_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.api_ssl_verify,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "api_ssl_verify",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.api_ssl_verify,
                    expression: "camera.stream_settings.api_ssl_verify"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "api_ssl_verify_input_field_no",
                  name: "api_ssl_verify_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.api_ssl_verify,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "api_ssl_verify",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                post_uniq\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-post-uniq-1",
              "uk-toggle":
                "target: #ukid-plus-animation-post-uniq-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(37)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                post_uniq\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-post-uniq-1",
              "uk-toggle":
                "target: #ukid-plus-animation-post-uniq-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(38)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "post_uniq_verify_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "post_uniq_verify_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.post_uniq,
                    expression: "camera.stream_settings.post_uniq"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "post_uniq_verify_input_field_yes",
                  name: "post_uniq_verify_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.post_uniq, "true")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "post_uniq",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.post_uniq,
                    expression: "camera.stream_settings.post_uniq"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "post_uniq_verify_input_field_no",
                  name: "post_uniq_verify_input_field"
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.post_uniq, "false")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "post_uniq",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_score\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-min-score-1",
              "uk-toggle":
                "target: #ukid-plus-animation-min-score-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(39)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_score\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-api-ssl-verify-1",
              "uk-toggle":
                "target: #ukid-plus-animation-min-score-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(40)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.min_score,
              expression: "camera.stream_settings.min_score"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1",
              expression: "'required|between:0,1'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "min_score_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "min_score_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "min_score_input_field",
            name: "min_score_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1"
          },
          domProps: { value: _vm.camera.stream_settings.min_score },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "min_score",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_d_score\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-min-d-score-1",
              "uk-toggle":
                "target: #ukid-plus-animation-min-d-score-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(41)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_d_score\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-min-d-score-2",
              "uk-toggle":
                "target: #ukid-plus-animation-min-d-score-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(42)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.min_d_score,
              expression: "camera.stream_settings.min_d_score"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:-1000,0|decimal:3",
              expression: "'required|between:-1000,0|decimal:3'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "min_d_score_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "min_d_score_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "min_d_score_input_field",
            name: "min_d_score_input_field",
            type: "text",
            placeholder: "Mínimo -1000, máximo 0"
          },
          domProps: { value: _vm.camera.stream_settings.min_d_score },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "min_d_score",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_d_score\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-post-perm-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-post-perm-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(43)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_d_score\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-post-perm-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-post-perm-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(44)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "realtime_post_perm_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "realtime_post_perm_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.realtime_post_perm,
                    expression: "camera.stream_settings.realtime_post_perm"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "realtime_post_perm_input_field_yes",
                  name: "realtime_post_perm_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.realtime_post_perm,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "realtime_post_perm",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.realtime_post_perm,
                    expression: "camera.stream_settings.realtime_post_perm"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "realtime_post_perm_input_field_no",
                  name: "realtime_post_perm_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.realtime_post_perm,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "realtime_post_perm",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime_dly\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-dly-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-dly-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(45)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime_dly\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-dly-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-dly-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(46)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.realtime_dly,
              expression: "camera.stream_settings.realtime_dly"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,500|decimal:2",
              expression: "'required|between:0,500|decimal:2'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "realtime_dly_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "realtime_dly_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "realtime_dly_input_field",
            name: "realtime_dly_input_field",
            type: "text",
            placeholder: "Entre 0 e 500"
          },
          domProps: { value: _vm.camera.stream_settings.realtime_dly },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "realtime_dly",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                rot\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-rot-1",
              "uk-toggle":
                "target: #ukid-plus-animation-rot-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(47)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                rot\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-rot-2",
              "uk-toggle":
                "target: #ukid-plus-animation-rot-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(48)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.rot,
              expression: "camera.stream_settings.rot"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "rot_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "rot_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "rot_input_field",
            name: "rot_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings.rot },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.camera.stream_settings, "rot", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                roi\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-roi-1",
              "uk-toggle":
                "target: #ukid-plus-animation-roi-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(49)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                roi\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-roi-2",
              "uk-toggle":
                "target: #ukid-plus-animation-roi-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(50)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.roi,
              expression: "camera.stream_settings.roi"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "roi_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "roi_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "roi_input_field",
            name: "roi_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings.roi },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.camera.stream_settings, "roi", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                draw_track\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-draw-track-1",
              "uk-toggle":
                "target: #ukid-plus-animation-draw-track-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(51)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                draw_track\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-draw-track-2",
              "uk-toggle":
                "target: #ukid-plus-animation-draw-track-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(52)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "draw_track_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "draw_track_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.draw_track,
                    expression: "camera.stream_settings.draw_track"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "draw_track_input_field_yes",
                  name: "draw_track_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.draw_track, "true")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "draw_track",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.draw_track,
                    expression: "camera.stream_settings.draw_track"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "draw_track_input_field_no",
                  name: "draw_track_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings.draw_track,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "draw_track",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                send_track\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-send-track-1",
              "uk-toggle":
                "target: #ukid-plus-animation-send-track-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(53)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                send_track\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-send-track-2",
              "uk-toggle":
                "target: #ukid-plus-animation-send-track-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(54)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.send_track,
              expression: "camera.stream_settings.send_track"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "send_track_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "send_track_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "send_track_input_field",
            name: "send_track_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: { value: _vm.camera.stream_settings.send_track },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "send_track",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-min-face-size-1",
              "uk-toggle":
                "target: #ukid-plus-animation-min-face-size-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(55)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                min_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-min-face-size-2",
              "uk-toggle":
                "target: #ukid-plus-animation-min-face-size-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(56)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.min_face_size,
              expression: "camera.stream_settings.min_face_size"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "min_face_size_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "min_face_size_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "min_face_size_input_field",
            name: "min_face_size_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: { value: _vm.camera.stream_settings.min_face_size },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "min_face_size",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                max_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-max-face-size-1",
              "uk-toggle":
                "target: #ukid-plus-animation-max-face-size-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(57)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                max_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-max-face-size-2",
              "uk-toggle":
                "target: #ukid-plus-animation-max-face-size-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(58)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.max_face_size,
              expression: "camera.stream_settings.max_face_size"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "max_face_size_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "max_face_size_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "max_face_size_input_field",
            name: "max_face_size_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: { value: _vm.camera.stream_settings.max_face_size },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "max_face_size",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                overall\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-overall-1",
              "uk-toggle":
                "target: #ukid-plus-animation-overall-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(59)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                overall\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-overall-2",
              "uk-toggle":
                "target: #ukid-plus-animation-overall-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(60)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has("overall_input_field"),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "overall_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.overall,
                    expression: "camera.stream_settings.overall"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "overall_input_field_yes",
                  name: "overall_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.overall, "true")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "overall",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.overall,
                    expression: "camera.stream_settings.overall"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "overall_input_field_no",
                  name: "overall_input_field"
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.overall, "false")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "overall",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                only_norm\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-only-norm-1",
              "uk-toggle":
                "target: #ukid-plus-animation-only-norm-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(61)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                only_norm\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-only-norm-2",
              "uk-toggle":
                "target: #ukid-plus-animation-only-norm-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(62)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has("only_norm_input_field"),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "only_norm_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.only_norm,
                    expression: "camera.stream_settings.only_norm"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "only_norm_input_field_yes",
                  name: "only_norm_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.only_norm, "true")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "only_norm",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings.only_norm,
                    expression: "camera.stream_settings.only_norm"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "only_norm_input_field_no",
                  name: "only_norm_input_field"
                },
                domProps: {
                  checked: _vm._q(_vm.camera.stream_settings.only_norm, "false")
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings,
                      "only_norm",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.max_candidates,
              expression: "camera.stream_settings.max_candidates"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "",
              expression: "''"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "max_candidates_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "max_candidates_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "max_candidates_input_field",
            name: "max_candidates_input_field",
            type: "text",
            placeholder: ""
          },
          domProps: { value: _vm.camera.stream_settings.max_candidates },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "max_candidates",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(63),
      _vm._v(" "),
      _vm._m(64),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings.jpeg_quality,
              expression: "camera.stream_settings.jpeg_quality"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:30,100",
              expression: "'required|between:30,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "jpeg_quality_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "jpeg_quality_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "jpeg_quality_input_field",
            name: "jpeg_quality_input_field",
            type: "text",
            placeholder: "Entre 30 e 100"
          },
          domProps: { value: _vm.camera.stream_settings.jpeg_quality },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings,
                "jpeg_quality",
                $event.target.value
              )
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-format-1" }
      },
      [
        _vm._v("\n                Passe o formato FFMPEG"),
        _c("br"),
        _vm._v("(mxg, flv, etc.)"),
        _c("br"),
        _vm._v("\n                se não puder ser"),
        _c("br"),
        _vm._v("detectado automaticamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-format-2" }
      },
      [
        _vm._v("\n                Passe o formato FFMPEG (mxg, flv, etc.)"),
        _c("br"),
        _vm._v("\n                se não puder ser detectado automaticamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-params-1" }
      },
      [
        _vm._v(
          "\n                Lista de opções de ffmpeg de um fluxo de vídeo"
        ),
        _c("br"),
        _vm._v("\n                com seus valores como uma matriz"),
        _c("br"),
        _vm._v(
          '\n                key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]'
        ),
        _c("br"),
        _vm._v("\n                Confira o site FFmpeg "),
        _c(
          "a",
          {
            staticClass: "reference external",
            attrs: { href: "https://trac.ffmpeg.org" }
          },
          [_vm._v("web site")]
        ),
        _c("br"),
        _vm._v("\n                para a lista completa de opções."),
        _c("br"),
        _vm._v("\n                Valor padrão: opções não especificadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-params-2" }
      },
      [
        _vm._v(
          "\n                Lista de opções de ffmpeg de um fluxo de vídeo com seus"
        ),
        _c("br"),
        _vm._v(
          '\n                valores como uma matriz key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]'
        ),
        _c("br"),
        _vm._v("\n                Confira o site FFmpeg "),
        _c(
          "a",
          {
            staticClass: "reference external",
            attrs: { href: "https://trac.ffmpeg.org" }
          },
          [_vm._v("web site")]
        ),
        _c("br"),
        _vm._v(
          "\n                para a lista completa de opções. Valor padrão: opções não especificadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-md-threshold-1" }
      },
      [
        _vm._v("\n                Intensidade mínima de movimento a ser"),
        _c("br"),
        _vm._v("\n                detectada pelo detector de movimento"),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente."
        ),
        _c("br"),
        _vm._v("\n                Unidades empíricas: zero e números"),
        _c("br"),
        _vm._v("\n                racionais positivos."),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = detector desativado"),
        _c("br"),
        _vm._v("\n                0.002 = valor padrão"),
        _c("br"),
        _vm._v("\n                0.05 = intensidade mínima muito"),
        _c("br"),
        _vm._v("\n                alta para detectar movimento"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-md-threshold-2" }
      },
      [
        _vm._v(
          "\n                Intensidade mínima de movimento a ser detectada pelo detector de movimento"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente."
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: zero e números racionais positivos."
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = detector desativado"),
        _c("br"),
        _vm._v("\n                0.002 = valor padrão"),
        _c("br"),
        _vm._v(
          "\n                0.05 = intensidade mínima muito alta para detectar movimento"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-md-scale-1" }
      },
      [
        _vm._v("\n                Valor padrão: 1 (tamanho original)"),
        _c("br"),
        _vm._v("\n                Coeficiente de escala do frame de vídeo"),
        _c("br"),
        _vm._v("\n                para o detector de movimento, em relação"),
        _c("br"),
        _vm._v("\n                ao tamanho original de 0 a 1."),
        _c("br"),
        _vm._v("\n                Reduza a escala no caso de câmeras"),
        _c("br"),
        _vm._v("\n                de alta resolução, ou rostos próximos"),
        _c("br"),
        _vm._v("\n                ou se a carga da CPU for muito alta,"),
        _c("br"),
        _vm._v(
          "\n                para reduzir o consumo de recursos do sistema"
        ),
        _c("br"),
        _vm._v("\n                Verifique se o tamanho da face dimensionada"),
        _c("br"),
        _vm._v("\n                excede o valor mínimo do tamanho da face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-md-scale-2" }
      },
      [
        _vm._v("\n                Valor padrão: 1 (tamanho original)"),
        _c("br"),
        _vm._v(
          "\n                Coeficiente de escala do frame de vídeo para o detector de movimento,"
        ),
        _c("br"),
        _vm._v("\n                em relação ao tamanho original de 0 a 1."),
        _c("br"),
        _vm._v(
          "\n                Reduza a escala no caso de câmeras de alta resolução, ou rostos próximos"
        ),
        _c("br"),
        _vm._v(
          "\n                ou se a carga da CPU for muito alta, para reduzir o consumo de recursos do sistema"
        ),
        _c("br"),
        _vm._v(
          "\n                Verifique se o tamanho da face dimensionada excede o valor mínimo do tamanho da face"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-fd-frame-height-1" }
      },
      [
        _vm._v("\n                Valor padrão:"),
        _c("br"),
        _vm._v(
          "\n                -1 (valores negativos correspondem ao tamanho original)"
        ),
        _c("br"),
        _vm._v(
          "\n                640-720 (Valor ideal para reduzir a carga)\n                Altura do frame de vídeo para o"
        ),
        _c("br"),
        _vm._v("\n                rastreador de rosto, em pixels"),
        _c("br"),
        _vm._v("\n                Reduza a escala no caso de câmeras"),
        _c("br"),
        _vm._v("\n                de alta resolução ou feche rostos,"),
        _c("br"),
        _vm._v("\n                ou se a carga da CPU for muito alta,"),
        _c("br"),
        _vm._v(
          "\n                para reduzir o consumo de recursos do sistema."
        ),
        _c("br"),
        _vm._v("\n                Verifique se o tamanho da face dimensionada"),
        _c("br"),
        _vm._v("\n                excede o valor mínimo do tamanho da face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-fd-frame-height-2" }
      },
      [
        _vm._v("\n                Valor padrão:"),
        _c("br"),
        _vm._v(
          "\n                -1 (valores negativos correspondem ao tamanho original)"
        ),
        _c("br"),
        _vm._v(
          "\n                640-720 (Valor ideal para reduzir a carga)\n                Altura do frame de vídeo para o rastreador de rosto, em pixels"
        ),
        _c("br"),
        _vm._v(
          "\n                Reduza a escala no caso de câmeras de alta resolução"
        ),
        _c("br"),
        _vm._v(
          "\n                ou feche rostos, ou se a carga da CPU for muito alta,"
        ),
        _c("br"),
        _vm._v(
          "\n                para reduzir o consumo de recursos do sistema."
        ),
        _c("br"),
        _vm._v(
          "\n                Verifique se o tamanho da face dimensionada excede o valor mínimo do tamanho da face"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-uc-max-time-diff-1" }
      },
      [
        _vm._v("\n                Valor padrão: 30"),
        _c("br"),
        _vm._v("\n                Somente se post_uniq: true"),
        _c("br"),
        _vm._v("\n                (deduplicação de face ativada)"),
        _c("br"),
        _vm._v("\n                Período máximo de tempo em segundos durante"),
        _c("br"),
        _vm._v(
          "\n                o qual um número de faces semelhantes é considerado"
        ),
        _c("br"),
        _vm._v("\n                pertencente a uma pessoa"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-uc-max-time-diff-2" }
      },
      [
        _vm._v("\n                Valor padrão: 30"),
        _c("br"),
        _vm._v(
          "\n                Somente se post_uniq: true (deduplicação de face ativada)"
        ),
        _c("br"),
        _vm._v(
          "\n                Período máximo de tempo em segundos durante o qual um número de faces"
        ),
        _c("br"),
        _vm._v(
          "\n                semelhantes é considerado pertencente a uma pessoa"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-uc-max-dup-1" }
      },
      [
        _vm._v("\n                Valor padrão: 3"),
        _c("br"),
        _vm._v("\n                Somente se post_uniq: true"),
        _c("br"),
        _vm._v("\n                (deduplicação de face ativada)"),
        _c("br"),
        _vm._v("\n                Número máximo de faces durante o período"),
        _c("br"),
        _vm._v("\n                uc_max_time_diff lançado para uma pessoa"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-uc-max-dup-2" }
      },
      [
        _vm._v("\n                Valor padrão: 3 "),
        _c("br"),
        _vm._v(
          "\n                Somente se post_uniq: true (deduplicação de face ativada)"
        ),
        _c("br"),
        _vm._v(
          "\n                Número máximo de faces durante o período uc_max_time_diff lançado para uma pessoa"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-uc-max-avg-shift-1" }
      },
      [
        _vm._v("\n                Valor padrão: 10"),
        _c("br"),
        _vm._v("\n                Somente se post_uniq: true"),
        _c("br"),
        _vm._v("\n                (deduplicação de face ativada)"),
        _c("br"),
        _vm._v("\n                Distância em pixels dentro da qual um"),
        _c("br"),
        _vm._v("\n                número de faces semelhantes é considerado"),
        _c("br"),
        _vm._v("\n                pertencente a uma pessoa"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-uc-max-avg-shift-1" }
      },
      [
        _vm._v("\n                Valor padrão: 10"),
        _c("br"),
        _vm._v(
          "\n                Somente se post_uniq: true (deduplicação de face ativada)"
        ),
        _c("br"),
        _vm._v(
          "\n                Distância em pixels dentro da qual um número de faces"
        ),
        _c("br"),
        _vm._v(
          "\n                semelhantes é considerado pertencente a uma pessoa"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Ativa o modo em tempo real"),
        _c("br"),
        _vm._v("\n                para a melhor pesquisa de face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Ativa o modo em tempo real"),
        _c("br"),
        _vm._v("\n                para a melhor pesquisa de face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-npersons-1" }
      },
      [
        _vm._v("\n                Valor padrão: 4"),
        _c("br"),
        _vm._v("\n                Número máximo de faces rastreados"),
        _c("br"),
        _vm._v("\n                simultaneamente pelo rastreador de faces"),
        _c("br"),
        _vm._v(
          "\n                Este parâmetro afeta severamente o desempenho"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-npersons-2" }
      },
      [
        _vm._v("\n                Valor padrão: 4"),
        _c("br"),
        _vm._v(
          "\n                Número máximo de faces rastreados simultaneamente pelo rastreador de faces"
        ),
        _c("br"),
        _vm._v(
          "\n                Este parâmetro afeta severamente o desempenho"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-tracker-threads-1" }
      },
      [
        _vm._v("\n                Valor padrão: 1"),
        _c("br"),
        _vm._v("\n                Número de threads de rastreamento"),
        _c("br"),
        _vm._v("\n                para o rastreador de face"),
        _c("br"),
        _vm._v("\n                Este valor deve ser menor ou igual"),
        _c("br"),
        _vm._v("\n                ao valor do campo acima"),
        _c("br"),
        _vm._v(
          "\n                Recomendamos que você defina-os com o mesmo valor"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o número de encadeamentos de rastreamento for menor"
        ),
        _c("br"),
        _vm._v("\n                que o número máximo de faces rastreadas,"),
        _c("br"),
        _vm._v(
          "\n                o consumo de recursos será reduzido, assim como"
        ),
        _c("br"),
        _vm._v("\n                a velocidade de rastreamento"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-tracker-threads-1" }
      },
      [
        _vm._v("\n                Valor padrão: 1"),
        _c("br"),
        _vm._v(
          "\n                Número de threads de rastreamento para o rastreador de face"
        ),
        _c("br"),
        _vm._v(
          "\n                Este valor deve ser menor ou igual ao valor do campo acima"
        ),
        _c("br"),
        _vm._v(
          "\n                Recomendamos que você defina-os com o mesmo valor"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o número de encadeamentos de rastreamento for menor que o número máximo de faces rastreadas,"
        ),
        _c("br"),
        _vm._v(
          "\n                o consumo de recursos será reduzido, assim como a velocidade de rastreamento"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-disable-drops-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Permite postar todas as faces"),
        _c("br"),
        _vm._v("\n                apropriadas sem exclusões"),
        _c("br"),
        _vm._v(
          "\n                Por padrão, se o video-worker não tiver recursos"
        ),
        _c("br"),
        _vm._v("\n                suficientes para processar todos os frames"),
        _c("br"),
        _vm._v("\n                com faces ele eliminará alguns deles"),
        _c("br"),
        _vm._v("\n                Se esta opção estiver ativa, o video-worker"),
        _c("br"),
        _vm._v("\n                coloca frames ímpares na lista de espera"),
        _c("br"),
        _vm._v("\n                para processá-los posteriormente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-disable-drops-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v(
          "\n                Permite postar todas as faces apropriadas sem exclusões"
        ),
        _c("br"),
        _vm._v(
          "\n                Por padrão, se o video-worker não tiver recursos suficientes"
        ),
        _c("br"),
        _vm._v(
          "\n                para processar todos os frames com faces ele eliminará alguns deles"
        ),
        _c("br"),
        _vm._v(
          "\n                Se esta opção estiver ativa, o video-worker coloca frames ímpares na lista"
        ),
        _c("br"),
        _vm._v("\n                de espera para processá-los posteriormente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-parse-sei-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Permite postar todas as faces"),
        _c("br"),
        _vm._v("\n                apropriadas sem exclusões"),
        _c("br"),
        _vm._v(
          "\n                Por padrão, se o video-worker não tiver recursos"
        ),
        _c("br"),
        _vm._v(
          "\n                suficientes para processar todos os frames com faces"
        ),
        _c("br"),
        _vm._v("\n                ele eliminará alguns deles"),
        _c("br"),
        _vm._v(
          "\n                Se esta opção estiver ativa, o video-worker coloca"
        ),
        _c("br"),
        _vm._v(
          "\n                frames ímpares na lista de espera para processá-los posteriormente"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-parse-sei-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v(
          "\n                Permite postar todas as faces apropriadas sem exclusões"
        ),
        _c("br"),
        _vm._v(
          "\n                Por padrão, se o video-worker não tiver recursos suficientes"
        ),
        _c("br"),
        _vm._v(
          "\n                para processar todos os frames com faces ele eliminará alguns deles"
        ),
        _c("br"),
        _vm._v(
          "\n                Se esta opção estiver ativa, o video-worker coloca frames ímpares na lista"
        ),
        _c("br"),
        _vm._v("\n                de espera para processá-los posteriormente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-image-arg-1" }
      },
      [
        _vm._v("\n                Valor padrão: photo"),
        _c("br"),
        _vm._v("\n                Nome do argumento que contém uma bbox"),
        _c("br"),
        _vm._v("\n                com uma face, em uma solicitação ao WEB HUC"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-image-arg-2" }
      },
      [
        _vm._v("\n                Valor padrão: photo"),
        _c("br"),
        _vm._v("\n                Nome do argumento que contém uma bbox"),
        _c("br"),
        _vm._v("\n                com uma face, em uma solicitação ao WEB HUC"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _c("span", { staticClass: "ukid-icon-pink-color" }, [
        _vm._v("\n                    additional_headers"),
        _c("br"),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-additional-headers-1" }
      },
      [
        _vm._v("\n                Valor padrão: cabeçalhos não especificados"),
        _c("br"),
        _vm._v("\n                Campos de cabeçalho adicionais"),
        _c("br"),
        _vm._v("em uma solicitação"),
        _c("br"),
        _vm._v("\n                POST ao postar uma"),
        _c("br"),
        _vm._v("face para WEB HUC"),
        _c("br"),
        _vm._v("\n                Ex:"),
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "uk-text-lowercase" }, [
          _vm._v("\n                    Authorization=Token xxx"),
          _c("br"),
          _vm._v(
            "\n                    Accept=application/json\n                "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _c("span", { staticClass: "ukid-icon-pink-color" }, [
        _vm._v("\n                    additional_headers"),
        _c("br"),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-additional-headers-2" }
      },
      [
        _vm._v("\n                Valor padrão: cabeçalhos não especificados"),
        _c("br"),
        _vm._v(
          "\n                Campos de cabeçalho adicionais em uma solicitação"
        ),
        _c("br"),
        _vm._v("\n                POST ao postar uma face para WEB HUC"),
        _c("br"),
        _vm._v("\n                Ex:"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "uk-text-lowercase" }, [
          _vm._v("\n                    Authorization=Token xxx"),
          _c("br"),
          _vm._v(
            "\n                    Accept=application/json\n                "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-additional-body-1" }
      },
      [
        _vm._v("\n                Atributos:"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-lowercase ukid-font-size-11" }, [
          _vm._v("\n                      image_type=BASE64"),
          _c("br"),
          _vm._v("\n                      external_url=https://xxx"),
          _c("br"),
          _vm._v("\n                      external_token=token_xxx"),
          _c("br"),
          _vm._v("\n                      mf_selector="),
          _c("br"),
          _vm._v("\n                      detector=DEAFULT"),
          _c("br"),
          _vm._v("\n                      gallery=DEFAULT"),
          _c("br"),
          _vm._v("\n                      latitude="),
          _c("br"),
          _vm._v("\n                      longitude="),
          _c("br"),
          _c("br"),
          _vm._v(
            "\n                    Campos adicionais no body da solicitação"
          ),
          _c("br"),
          _vm._v(
            "\n                    ao postar uma face. Ex: uuid=xxx,some_name=yyy"
          ),
          _c("br"),
          _c("br")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-additional-body-2" }
      },
      [
        _vm._v("\n                Atributos:"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-lowercase ukid-font-size-11" }, [
          _vm._v("\n                      image_type=BASE64"),
          _c("br"),
          _vm._v("\n                      external_url=https://xxx"),
          _c("br"),
          _vm._v("\n                      external_token=token_xxx"),
          _c("br"),
          _vm._v("\n                      mf_selector="),
          _c("br"),
          _vm._v("\n                      detector=DEAFULT"),
          _c("br"),
          _vm._v("\n                      gallery=DEFAULT"),
          _c("br"),
          _vm._v("\n                      latitude="),
          _c("br"),
          _vm._v("\n                      longitude="),
          _c("br"),
          _c("br"),
          _vm._v(
            "\n                    Campos adicionais no body da solicitação"
          ),
          _c("br"),
          _vm._v(
            "\n                    ao postar uma face. Ex: uuid=xxx,some_name=yyy"
          ),
          _c("br"),
          _c("br")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-api-timeout-1" }
      },
      [
        _vm._v("\n                Valor padrão: 15000"),
        _c("br"),
        _vm._v("\n                Tempo limite para uma resposta face router"),
        _c("br"),
        _vm._v(
          "\n                a uma solicitação da API video-worker, em milissegundos"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o tempo limite expirar, o sistema registrará um erro"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-api-ssl-verify-1" }
      },
      [
        _vm._v("\n                Valor padrão: Habilitado"),
        _c("br"),
        _vm._v(
          "\n                Ativa a verificação do certificado https quando"
        ),
        _c("br"),
        _vm._v(
          "\n                video-worker e facerouter interagem por https"
        ),
        _c("br"),
        _c("br"),
        _vm._v("\n                Se não habilitado, um certificado"),
        _c("br"),
        _vm._v("\n                autoassinado também é aceito\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-api-ssl-verify-2" }
      },
      [
        _vm._v("\n                Valor padrão: Habilitado"),
        _c("br"),
        _vm._v(
          "\n                Ativa a verificação do certificado https quando"
        ),
        _c("br"),
        _vm._v(
          "\n                video-worker e facerouter interagem por https"
        ),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, um certificado autoassinado também é aceito\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-post-uniq-1" }
      },
      [
        _vm._v("\n                Valor padrão: Habilitado"),
        _c("br"),
        _vm._v("\n                Permite desduplicação de face, ou seja,"),
        _c("br"),
        _vm._v("\n                postar apenas um certo número de faces"),
        _c("br"),
        _vm._v("\n                pertencentes a uma pessoa, durante um"),
        _c("br"),
        _vm._v("\n                 determinado período de tempo."),
        _c("br"),
        _vm._v(
          "\n                Nesse caso, se video-worker postar uma face para"
        ),
        _c("br"),
        _vm._v(
          "\n                facerouter e depois rastrear outra dentro do período"
        ),
        _c("br"),
        _vm._v("\n                uc_max_time_diff, e a distância entre"),
        _c("br"),
        _vm._v("\n                as duas faces não exceder"),
        _c("br"),
        _vm._v(
          "\n                uc_max_avg_shift, o video-worker estima sua similaridade"
        ),
        _c("br"),
        _vm._v("\n                Se as faces forem semelhantes e o"),
        _c("br"),
        _vm._v("\n                número total de faces semelhantes"),
        _c("br"),
        _vm._v("\n                durante o período uc_max_time_diff não"),
        _c("br"),
        _vm._v("\n                exceder o número uc_max_dup,"),
        _c("br"),
        _vm._v("\n                o video-worker postará a outra face."),
        _c("br"),
        _vm._v(
          "\n                Caso contrário, a outra face não será lançada"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-post-uniq-1" }
      },
      [
        _vm._v("\n                Valor padrão: Habilitado"),
        _c("br"),
        _vm._v(
          "\n                Permite desduplicação de face, ou seja, postar apenas um certo número de faces"
        ),
        _c("br"),
        _vm._v(
          "\n                pertencentes a uma pessoa, durante um determinado período de tempo."
        ),
        _c("br"),
        _vm._v(
          "\n                Nesse caso, se video-worker postar uma face para facerouter e depois rastrear outra dentro do período"
        ),
        _c("br"),
        _vm._v(
          "\n                uc_max_time_diff, e a distância entre as duas faces não exceder"
        ),
        _c("br"),
        _vm._v(
          "\n                uc_max_avg_shift, o video-worker estima sua similaridade"
        ),
        _c("br"),
        _vm._v(
          "\n                Se as faces forem semelhantes e o número total de faces semelhantes"
        ),
        _c("br"),
        _vm._v(
          "\n                durante o período uc_max_time_diff não exceder o número uc_max_dup,"
        ),
        _c("br"),
        _vm._v(
          "\n                o video-worker postará a outra face. Caso contrário, a outra face não será lançada"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-min-score-1" }
      },
      [
        _vm._v("\n                Valor padrão: -7"),
        _c("br"),
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v("\n                Unidades empíricas: números racionais"),
        _c("br"),
        _vm._v("\n                negativos para zero"),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Face com baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Face com alta qualidade\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-min-score-2" }
      },
      [
        _vm._v("\n                Valor padrão: -7"),
        _c("br"),
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Face com baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Face com alta qualidade\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-min-d-score-1" }
      },
      [
        _vm._v("\n                Valor padrão: -1000"),
        _c("br"),
        _vm._v(
          "\n                Desvio máximo de face em sua posição frontal"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é aceita se seu desvio for menor que esse valor"
        ),
        _c("br"),
        _vm._v("\n                O desvio deve ser ajustado empiricamente"),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v(
          "\n                -3.5 = ângulos grandes da face, o reconhecimento"
        ),
        _c("br"),
        _vm._v("\n                da face pode ser ineficiente"),
        _c("br"),
        _vm._v("\n                -2.5 = desvio satisfatório"),
        _c("br"),
        _vm._v("\n                -0.05 = próximo à posição frontal"),
        _c("br"),
        _vm._v("\n                0 = face frontal"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-min-d-score-2" }
      },
      [
        _vm._v("\n                Valor padrão: -1000"),
        _c("br"),
        _vm._v(
          "\n                Desvio máximo de face em sua posição frontal"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é aceita se seu desvio for menor que esse valor"
        ),
        _c("br"),
        _vm._v("\n                O desvio deve ser ajustado empiricamente"),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v(
          "\n                -3.5 = ângulos grandes da face, o reconhecimento da face pode ser ineficiente"
        ),
        _c("br"),
        _vm._v("\n                -2.5 = desvio satisfatório"),
        _c("br"),
        _vm._v("\n                -0.05 = próximo à posição frontal"),
        _c("br"),
        _vm._v("\n                0 = face frontal"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-post-perm-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em"),
        _c("br"),
        _vm._v("\n                cada período de tempo realtime_dly"),
        _c("br"),
        _vm._v("\n                Se não habilitado, procure o melhor momento"),
        _c("br"),
        _vm._v("\n                dinamicamente e post em ordem"),
        _c("br"),
        _vm._v("\n                crescente de qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-post-perm-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em"),
        _c("br"),
        _vm._v("\n                cada período de tempo realtime_dly"),
        _c("br"),
        _vm._v("\n                Se não habilitado, procure o melhor momento"),
        _c("br"),
        _vm._v("\n                dinamicamente e post em ordem"),
        _c("br"),
        _vm._v("\n                crescente de qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-dly-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 - Em segundos"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em"),
        _c("br"),
        _vm._v("\n                cada período de tempo realtime_dly"),
        _c("br"),
        _vm._v("\n                Se não habilitado, procure o melhor momento"),
        _c("br"),
        _vm._v("\n                dinamicamente e post em ordem"),
        _c("br"),
        _vm._v("\n                crescente de qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-dly-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 - Em segundos"),
        _c("br"),
        _vm._v(
          "\n                Somente para o modo em tempo real habilitado"
        ),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_perm = Habilitado, define o período em milissegundos dentro do qual o rastreador de face pega"
        ),
        _c("br"),
        _vm._v("\n                o melhor momento e o posta no face router"),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_perm = Não habilitado, define o período mínimo de tempo entre 2 posts da mesma face com maior qualidade"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-rot-1" }
      },
      [
        _vm._v("\n                Valor padrão: retângulo não especificado"),
        _c("br"),
        _vm._v("\n                Permite detectar e rastrear faces apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de um retângulo de recorte WxH + X + Y"
        ),
        _c("br"),
        _vm._v(
          "\n                Você pode usar esta opção para reduzir a carga do video worker"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-rot-2" }
      },
      [
        _vm._v("\n                Valor padrão: retângulo não especificado"),
        _c("br"),
        _vm._v("\n                Permite detectar e rastrear faces apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de um retângulo de recorte WxH + X + Y"
        ),
        _c("br"),
        _vm._v(
          "\n                Você pode usar esta opção para reduzir a carga do video worker"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-roi-1" }
      },
      [
        _vm._v("\n                Valor padrão: retângulo não especificado"),
        _c("br"),
        _vm._v("\n                Ativar post de faces detectadas apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de uma região de interesse WxH + X + Y"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-roi-2" }
      },
      [
        _vm._v("\n                Valor padrão: retângulo não especificado"),
        _c("br"),
        _vm._v("\n                Ativar post de faces detectadas apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de uma região de interesse WxH + X + Y"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-draw-track-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Permite desenhar uma trilha"),
        _c("br"),
        _vm._v("\n                de movimento de face em um box"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-draw-track-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Permite desenhar uma trilha"),
        _c("br"),
        _vm._v("\n                de movimento de face em um box"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-send-track-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (array não postada)"),
        _c("br"),
        _vm._v(
          "\n                Permite postar uma trilha de movimento de face"
        ),
        _c("br"),
        _vm._v("\n                como matriz de coordenadas centrais do bbox"),
        _c("br"),
        _vm._v(
          "\n                Como o valor send_track, especifique o número"
        ),
        _c("br"),
        _vm._v("\n                de pontos na trilha de movimento"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-send-track-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (array não postada)"),
        _c("br"),
        _vm._v(
          "\n                Permite postar uma trilha de movimento de face"
        ),
        _c("br"),
        _vm._v("\n                como matriz de coordenadas centrais do bbox"),
        _c("br"),
        _vm._v(
          "\n                Como o valor send_track, especifique o número"
        ),
        _c("br"),
        _vm._v("\n                de pontos na trilha de movimento"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-min-face-size-1" }
      },
      [
        _vm._v(
          "\n                Valor padrão: Valor padrão: 0 (filtro desativado)"
        ),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-min-face-size-2" }
      },
      [
        _vm._v(
          "\n                Valor padrão: Valor padrão: 0 (filtro desativado)"
        ),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-max-face-size-1" }
      },
      [
        _vm._v(
          "\n                Valor padrão: Valor padrão: 0 (filtro desativado)"
        ),
        _c("br"),
        _vm._v("\n                Tamanho máximo de uma face em pixels"),
        _c("br"),
        _vm._v(
          "\n                As faces de grandes dimensões não são postadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-max-face-size-2" }
      },
      [
        _vm._v(
          "\n                Valor padrão: Valor padrão: 0 (filtro desativado)"
        ),
        _c("br"),
        _vm._v("\n                Tamanho máximo de uma face em pixels"),
        _c("br"),
        _vm._v(
          "\n                As faces de grandes dimensões não são postadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-overall-1" }
      },
      [
        _vm._v("\n                Valor padrão: Habilitado"),
        _c("br"),
        _vm._v("\n                Ativa o modo off-line para"),
        _c("br"),
        _vm._v("\n                a melhor pesquisa de face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-overall-2" }
      },
      [
        _vm._v("\n                Valor padrão: Habilitado"),
        _c("br"),
        _vm._v("\n                Ativa o modo off-line para"),
        _c("br"),
        _vm._v("\n                a melhor pesquisa de face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-only-norm-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Habilite a postagem apenas de imagens"),
        _c("br"),
        _vm._v("\n                de face normalizadas sem frames completos"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-only-norm-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Habilite a postagem apenas de imagens"),
        _c("br"),
        _vm._v("\n                de face normalizadas sem frames completos"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _vm._v("\n            Qualidade da compactação JPEG"),
        _c("br"),
        _vm._v("\n            do frame original, em porcentagem"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                jpeg_quality - Valor padrão: 95%\n            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n            Qualidade da compactação JPEG do frame original, em porcentagem"
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                jpeg_quality - Valor padrão: 95%\n            "
          )
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-eb8f41c8", { render: render, staticRenderFns: staticRenderFns })
  }
}