<!--
<style scoped>
    @import '../../../content/unike_control_dash/dist/assets/images/favicon.png';
    @import '../../../content/unike_control_dash/dist/assets/libs/jqvmap/jqvmap.min.css';
    @import '../../../content/unike_control_dash/dist/assets/libs/datatables/dataTables.bootstrap4.min.css';
    @import '../../../content/unike_control_dash/dist/assets/libs/datatables/responsive.bootstrap4.min.css';
    @import '../../../content/unike_control_dash/dist/assets/libs/chartist/chartist.min.css';
    @import '../../../content/unike_control_dash/dist/assets/css/bootstrap.min.css';
    @import '../../../content/unike_control_dash/dist/assets/css/icons.min.css';
    @import '../../../content/unike_control_dash/dist/assets/css/app.min.css';
</style>
-->

<script type="text/javascript">

    import App from '../../app.json'
    
    import UIkit from 'uikit'

    import { vm } from '../../js/main.js'
    import { Datetime } from 'vue-datetime'

    import { Strings } from '../../js/common.js'
    import { Commons } from '../../js/common.js'    

    export default {

        components: {
            datetime: Datetime
        },

        data() {

            return {
                App: App,
                startDate: null,
                endDate: null,
                unikeControlObject: null,
                vm: vm,
                filters: [],
                usersChildrenList: [vm.user.sub],
                users: {},                
                Commons: Commons,
                Strings: Strings,
                auth: '',                
            }

        },

        watch: {

            startDate: function(value) {
                
                if (!Strings.isNullOrEmpty(value)) {
                
                    this.list()

                    window.postMessage("loaded", "*")

                }

            },

            endDate: function(value) {
                
                if (!Strings.isNullOrEmpty(value)) {
                
                    this.list()

                    window.postMessage("loaded", "*")

                }

            }            

        },

        mounted() {

            let _ = this

            /**
             * Config initial filters and user of logged user
             */
            _.setFilters()
            _.fillUsers()
            
            var iframe = document.querySelector("#ukid-unike-control"), _window = iframe.contentWindow
                        
            window.addEventListener("message", function(e) {
                
                // wait for child to signal that it's loaded.
                if (e.data === "loaded") {
                    
                    _.list()
                    
                }
                
            })

            UIkit.modal(document.querySelector(`#ukid-modal-control-children-${vm.user.sub}`)).show()

        },

        methods: {

            list() {

                var _ = this

                var iframe = document.querySelector("#ukid-unike-control"), _window = iframe.contentWindow

                let endpoint = App.host.concat(App.unikeControlEndpoint)

                let startDate
                let endDate

                if ( (_.startDate !== null && _.startDate !== '') && (_.endDate !== null && _.endDate !== '') ) {

                    startDate = new Date(_.startDate)
                    endDate   = new Date(_.endDate)

                    endpoint = App.host.concat(App.unikeControlEndpoint) + '?startDate=' + startDate + '&endDate=' + endDate

                }

                _.$http.post(

                    endpoint,
                    {
                        startDate: startDate,
                        endDate: endDate,
                        usersChildrenList: _.usersChildrenList
                    }

                ).then(response => {
                    
                    _.unikeControlObject = response.body

                    _.unikeControlObject.startDate = _.startDate
                    _.unikeControlObject.endDate   = _.endDate

                    _window.postMessage( { call: 'sendObject', value: _.unikeControlObject }, '*')

                }).catch(function(err){
                    
                    if (err.body.status == 401) {

                        window.location.href = '/#/login'

                    }

                    console.info(err)

                }) 

            },

            /**
             * List all users
             */
            fillUsers() {

                let _ = this

                _.$http.get(

                    App.host.concat(App.listAllUserEndpoint)

                ).then(response => {

                    _.users = response.body

                })

            },

            /**
             * Saves user-selected
             */
            saveSelectedUsers() {

                let filterName = window.location.hostname + '_' + vm.user.sub + '_filters_dash_control'

                localStorage.setItem(filterName, JSON.stringify(this.usersChildrenList))

                this.setFilters()             
                this.list()

            },

            /**
             * Configure saved filter data for bound objects
             */
            setFilters() {

                let filterName = window.location.hostname + '_' + vm.user.sub + '_filters_dash_control'

                let json = localStorage.getItem(filterName)

                if (!Strings.isNullOrEmpty(json)) {
                                        
                    this.usersChildrenList = JSON.parse(json)

                }

                this.$forceUpdate()

            },

            selectAlUsers() {

                let _ = this

                let usernames = []

                _.users.forEach(function(item, index, object) { usernames[index] = item.username })

                _.usersChildrenList = usernames

            },

            clearFilter() {

                var _ = this

                _.startDate = null
                _.endDate = null

                window.postMessage("loaded", "*")

            }

        }

    }

</script>

<template id="control-template">

    <div class="ukid-dash">

        <!-- This is the modal -->
        <div :id="`ukid-modal-control-children-${vm.user.sub}`" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <div class="ukid-font-size-15">
                    Selecione um ou mais usuários<br />
                    <span class="ukid-text-alert-color ukid-font-size-9">
                        (Ctrl + Clique) para mais de um usuário
                    </span>
                </div>
                <div class="uk-margin">
                    <button @click="selectAlUsers()" class="uk-button uk-button-link ukid-font-size-9" type="button">Selecionar Tudo</button>
                    <div class="uk-inline ukid-width-100-percent">
                        <select
                            v-model="usersChildrenList"
                            multiple="multiple"
                            rows="7"
                            class="uk-select uk-textarea ukid-height-150-px"
                            v-validate="'required'" 
                            data-vv-as="field"
                            id="gallery-select_field_2"
                            name="gallery-select_field_2">
                                <option v-for="option in users" :value="option.username" :key="option.username">
                                    {{option.username == vm.user.sub ? `${option.name} - Usuário Logado` : `${option.name}`}}
                                </option>
                        </select>
                    </div>
                </div>
                <p class="uk-text-right">
                    <router-link to="/home">
                        <button class="uk-button uk-button-default uk-modal-close" type="button">Voltar</button>
                    </router-link>
                    <button @click="saveSelectedUsers()" class="uk-button uk-button-primary uk-modal-close" type="button">Continuar</button>
                </p>
            </div>
        </div>

        <table align="left">
            <tr>
                <td width="90">
                    <span title="Filtrar" uk-tooltip :uk-toggle="`target: #ukid-modal-control-children-${vm.user.sub}`" class="ukid-icon ukid-text-alert-color ukid-cursor-pointer ukid-magin-left-30 ukid-margin-top-20" uk-icon="icon:search;ratio:2.7"></span> 
                </td>
            </tr>
        </table>
        <table align="center" class="ukid-margin-top-10">            
            <tr>
                <td width="160">
                    DE:
                    <datetime
                    v-model="startDate"
                    type="date"                            
                    input-class="mx-input ukid-width-70-percent"
                    value-zone="America/Sao_Paulo"
                    zone="America/Sao_Paulo"
                    format="dd/MM/yyyy"
                    :phrases="{}"
                    :hour-step="1"
                    :minute-step="5"
                    :week-start="7"
                    :auto="true"
                    :max-datetime="endDate"
                    use24-hour></datetime>                                   
                </td>
                <td width="160">                    
                    ATÉ:
                    <datetime
                    v-model="endDate"
                    type="date"                            
                    input-class="mx-input ukid-width-70-percent"
                    value-zone="America/Sao_Paulo"
                    zone="America/Sao_Paulo"
                    format="dd/MM/yyyy"
                    :phrases="{}"
                    :hour-step="1"
                    :minute-step="5"
                    :week-start="7"
                    :auto="true"
                    :max-datetime="new Date().toISOString()"
                    :min-datetime="startDate"
                    use24-hour></datetime>                                                             
                </td>
                <td width="180">
                    <span title="Limpar datas e voltar ao filtro desta semana" uk-tooltip @click="clearFilter" class="ukid-icon ukid-cursor-pointer ukid-margin-top-20" uk-icon="icon:trash;ratio:2.7"></span> 
                </td>                
            </tr>
        </table>

        <iframe id="ukid-unike-control" name="ukid-unike-control" src="content/unike_control_dash/index.html" class="ukid-dash-iframe"></iframe>
        <!--<iframe id="ukid-unike-control" name="ukid-unike-control" src="https://192.168.5.31:3000" class="ukid-dash-iframe"></iframe>-->
    </div>

</template>
