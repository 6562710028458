<!--suppress SpellCheckingInspection, JSUnresolvedVariable, HtmlUnknownAttribute, HtmlRequiredAltAttribute, JSUnresolvedFunction -->
<script>

    import UIkit from 'uikit'

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'user', 'auth', 'role', 'vm'],
        
        components: {

        },

        data() {

            return {

            }

        },

        mounted() {

            let _ = this

            _.inviteLogoUpload()

        },

        created() {

        },

        methods: {

            inviteLogoUpload() {

                let _ = this

                let bar = document.getElementById('ukid-js-progressbar')

                UIkit.upload('.js-upload', {

                    url: `${_.app.host}/client/upload/invite`,
                    type: 'application/json',
                    method: 'POST',
                    name: 'photo',
                    allow: '*.(png|jpg|jpeg)',

                    concurrent: 1,
                    multiple: false,

                    fail: function(message) {

                        UIkit.modal.alert('Tipo de arquivo selecionado inválido: Arquivos aceitos: png, jpg e jpeg')

                    },

                    beforeSend: function(environment) {
                
                        environment.headers = {
                            Authorization: 'Bearer ' + sessionStorage.token
                        }

                    },

                    loadStart: function(e) {

                        bar.removeAttribute('hidden')

                        bar.max = e.total
                        bar.value = e.loaded

                    },

                    progress: function(e) {
                        
                        bar.max = e.total
                        bar.value = e.loaded

                    },

                    loadEnd: function(e) {
                    
                        bar.max = e.total
                        bar.value = e.loaded

                    },

                    completeAll: function(environment) {

                        setTimeout(function() { bar.setAttribute('hidden', 'hidden') }, 1000)

                        _.user.imageInvite = JSON.parse(environment.response).data

                    }

                })

            },

            inviteLogoRemove() {

                let _ = this

                _.$http.delete(`${_.app.host}/client/upload/invite?path=${_.user.imageInvite}`).then(response => {

                    _.user.imageInvite = null

                })

            },            

        }
        
    }

</script>

<template id="register-invite-template">

    <!-- Invite -->
    <li>
        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                Dias para Expiração do Convite
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                Dias para Expiração
                <br />
                do Convite
            </span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('expirationDaysInvite-core-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('expirationDaysInvite-core-select_field')
                }" 
                v-model="user.expirationDaysInvite"
                v-validate="'required|decimal:2|between:7,50'"
                c-data-vv-as="field"
                id="expirationDaysInvite-core-select_field"
                name="expirationDaysInvite-core-select_field"
                type="text"
                placeholder="Entre 7 e 50 dias" />
        </div>
        <div class="uk-margin">
            <div class="js-upload uk-placeholder uk-text-center">
                <div v-if="user.imageInvite" class="uk-text-center">
                    <div class="uk-inline-clip uk-transition-toggle" tabindex="0">
                        <img :src="user.imageInvite" width="150" />
                        <div class="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default">
                            <p @click="inviteLogoRemove()" class="uk-h4 uk-margin-remove ukid-cursor-pointer">
                                <span uk-icon="icon:trash;ratio:1.4"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <br v-if="user.imageInvite" />
                <br v-if="user.imageInvite" />
                <span class="ukid-font-size-15">
                    O tamanho ideal para a imagem de convite é de máximo 512px de largura
                </span>
                <br />                                
                <span uk-icon="icon:cloud-upload;ratio:1.3"></span>
                <span class="uk-text-middle ukid-font-size-15">Anexe sua imagem que será exibida no convite</span>
                <div uk-form-custom>
                    <input type="file" multiple>
                    <span class="uk-link ukid-font-size-15">selecione uma aqui</span>
                </div>
                <br />
            </div>
            <progress id="ukid-js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
        </div>
    </li>
</template>