var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_format\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-format-1",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-format-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_format\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-format-2",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-format-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(1)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.ffmpeg_format,
              expression: "body.stream_settings.ffmpeg_format"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:3|max:100",
              expression: "'min:3|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "ffmpeg_format_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "ffmpeg_format_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "ffmpeg_format_gpu_input_field",
            name: "ffmpeg_format_gpu_input_field",
            type: "text",
            placeholder: "Mínimo 3, máximo 100"
          },
          domProps: { value: _vm.body.stream_settings.ffmpeg_format },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings,
                "ffmpeg_format",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "fullframe_crop_rot_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "fullframe_crop_rot_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .fullframe_crop_rot,
                    expression:
                      "body.stream_settings.detectors.body.fullframe_crop_rot"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "fullframe_crop_rot_input_field_yes",
                  name: "fullframe_crop_rot_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.fullframe_crop_rot,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "fullframe_crop_rot",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .fullframe_crop_rot,
                    expression:
                      "body.stream_settings.detectors.body.fullframe_crop_rot"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "fullframe_crop_rot_input_field_no",
                  name: "fullframe_crop_rot_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.fullframe_crop_rot,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "fullframe_crop_rot",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "fullframe_use_png_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "fullframe_use_png_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body.fullframe_use_png,
                    expression:
                      "body.stream_settings.detectors.body.fullframe_use_png"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "fullframe_use_png_input_field_yes",
                  name: "fullframe_use_png_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.fullframe_use_png,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "fullframe_use_png",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body.fullframe_use_png,
                    expression:
                      "body.stream_settings.detectors.body.fullframe_use_png"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "fullframe_use_png_input_field_no",
                  name: "fullframe_use_png_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.fullframe_use_png,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "fullframe_use_png",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(6),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "post_best_track_frame_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "post_best_track_frame_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_best_track_frame,
                    expression:
                      "body.stream_settings.detectors.body.post_best_track_frame"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "post_best_track_frame_input_field_yes",
                  name: "post_best_track_frame_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_best_track_frame,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_best_track_frame",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_best_track_frame,
                    expression:
                      "body.stream_settings.detectors.body.post_best_track_frame"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "post_best_track_frame_input_field_no",
                  name: "post_best_track_frame_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_best_track_frame,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_best_track_frame",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(8),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "post_best_track_normalize_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "post_best_track_normalize_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_best_track_normalize,
                    expression:
                      "body.stream_settings.detectors.body.post_best_track_normalize"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "post_best_track_normalize_input_field_yes",
                  name: "post_best_track_normalize_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_best_track_normalize,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_best_track_normalize",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_best_track_normalize,
                    expression:
                      "body.stream_settings.detectors.body.post_best_track_normalize"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "post_best_track_normalize_input_field_no",
                  name: "post_best_track_normalize_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_best_track_normalize,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_best_track_normalize",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(10),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "post_last_track_frame_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "post_last_track_frame_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_last_track_frame,
                    expression:
                      "body.stream_settings.detectors.body.post_last_track_frame"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "post_last_track_frame_input_field_yes",
                  name: "post_last_track_frame_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_last_track_frame,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_last_track_frame",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_last_track_frame,
                    expression:
                      "body.stream_settings.detectors.body.post_last_track_frame"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "post_last_track_frame_input_field_no",
                  name: "post_last_track_frame_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_last_track_frame,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_last_track_frame",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(12),
      _vm._v(" "),
      _vm._m(13),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "post_first_track_frame_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "post_first_track_frame_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_first_track_frame,
                    expression:
                      "body.stream_settings.detectors.body.post_first_track_frame"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "post_first_track_frame_input_field_yes",
                  name: "post_first_track_frame_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_first_track_frame,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_first_track_frame",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .post_first_track_frame,
                    expression:
                      "body.stream_settings.detectors.body.post_first_track_frame"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "post_first_track_frame_input_field_no",
                  name: "post_first_track_frame_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .post_first_track_frame,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "post_first_track_frame",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(14),
      _vm._v(" "),
      _vm._m(15),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "track_interpolate_bboxes_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "track_interpolate_bboxes_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .track_interpolate_bboxes,
                    expression:
                      "body.stream_settings.detectors.body.track_interpolate_bboxes"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "track_interpolate_bboxes_input_field_yes",
                  name: "track_interpolate_bboxes_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .track_interpolate_bboxes,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "track_interpolate_bboxes",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .track_interpolate_bboxes,
                    expression:
                      "body.stream_settings.detectors.body.track_interpolate_bboxes"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "track_interpolate_bboxes_input_field_no",
                  name: "track_interpolate_bboxes_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .track_interpolate_bboxes,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "track_interpolate_bboxes",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(16),
      _vm._v(" "),
      _vm._m(17),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "track_send_history_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "track_send_history_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .track_send_history,
                    expression:
                      "body.stream_settings.detectors.body.track_send_history"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "track_send_history_input_field_yes",
                  name: "track_send_history_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.track_send_history,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "track_send_history",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .track_send_history,
                    expression:
                      "body.stream_settings.detectors.body.track_send_history"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "track_send_history_input_field_no",
                  name: "track_send_history_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.track_send_history,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "track_send_history",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(18),
      _vm._v(" "),
      _vm._m(19),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "track_deep_sort_filter_unconfirmed_tracks_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "track_deep_sort_filter_unconfirmed_tracks_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .track_deep_sort_filter_unconfirmed_tracks,
                    expression:
                      "body.stream_settings.detectors.body.track_deep_sort_filter_unconfirmed_tracks"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id:
                    "track_deep_sort_filter_unconfirmed_tracks_input_field_yes",
                  name: "track_deep_sort_filter_unconfirmed_tracks_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .track_deep_sort_filter_unconfirmed_tracks,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "track_deep_sort_filter_unconfirmed_tracks",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .track_deep_sort_filter_unconfirmed_tracks,
                    expression:
                      "body.stream_settings.detectors.body.track_deep_sort_filter_unconfirmed_tracks"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id:
                    "track_deep_sort_filter_unconfirmed_tracks_input_field_no",
                  name: "track_deep_sort_filter_unconfirmed_tracks_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .track_deep_sort_filter_unconfirmed_tracks,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "track_deep_sort_filter_unconfirmed_tracks",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                track_deep_sort_matching_threshold\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_deep_sort_matching_threshold-1",
              "uk-toggle":
                "target: #ukid-plus-animation-track_deep_sort_matching_threshold-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_deep_sort_matching_threshold-1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                track_deep_sort_matching_threshold\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_deep_sort_matching_threshold-2",
              "uk-toggle":
                "target: #ukid-plus-animation-track_deep_sort_matching_threshold-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_deep_sort_matching_threshold-2"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value:
                _vm.body.stream_settings.detectors.body
                  .track_deep_sort_matching_threshold,
              expression:
                "body.stream_settings.detectors.body.track_deep_sort_matching_threshold"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1",
              expression: "'required|between:0,1'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "track_deep_sort_matching_threshold_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "track_deep_sort_matching_threshold_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "track_deep_sort_matching_threshold_input_field",
            name: "track_deep_sort_matching_threshold_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: {
            value:
              _vm.body.stream_settings.detectors.body
                .track_deep_sort_matching_threshold
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "track_deep_sort_matching_threshold",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                track_max_duration_frames\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_max_duration_frames-1",
              "uk-toggle":
                "target: #ukid-plus-animation-track_max_duration_frames-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_max_duration_frames-1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                track_max_duration_frames\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_max_duration_frames-2",
              "uk-toggle":
                "target: #ukid-plus-animation-track_max_duration_frames-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_max_duration_frames-2"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value:
                _vm.body.stream_settings.detectors.body
                  .track_max_duration_frames,
              expression:
                "body.stream_settings.detectors.body.track_max_duration_frames"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,100",
              expression: "'required|between:0,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "track_max_duration_frames_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "track_max_duration_frames_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "track_max_duration_frames_input_field",
            name: "track_max_duration_frames_input_field",
            type: "text",
            placeholder: "Entre 0 e 100"
          },
          domProps: {
            value:
              _vm.body.stream_settings.detectors.body.track_max_duration_frames
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "track_max_duration_frames",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                track_miss_interval\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_miss_interval-1",
              "uk-toggle":
                "target: #ukid-plus-animation-track_miss_interval-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_miss_interval-1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                track_miss_interval\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_miss_interval-2",
              "uk-toggle":
                "target: #ukid-plus-animation-track_miss_interval-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_miss_interval-2"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value:
                _vm.body.stream_settings.detectors.body.track_miss_interval,
              expression:
                "body.stream_settings.detectors.body.track_miss_interval"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,100",
              expression: "'required|between:0,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "track_miss_interval_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "track_miss_interval_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "track_miss_interval_input_field",
            name: "track_miss_interval_input_field",
            type: "text",
            placeholder: "Entre 0 e 100"
          },
          domProps: {
            value: _vm.body.stream_settings.detectors.body.track_miss_interval
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "track_miss_interval",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                track_overlap_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_miss_interval-1",
              "uk-toggle":
                "target: #ukid-plus-animation-track_overlap_threshold-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_overlap_threshold-1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                track_overlap_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-track_miss_interval-2",
              "uk-toggle":
                "target: #ukid-plus-animation-track_overlap_threshold-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: {
              hidden: "",
              id: "ukid-plus-animation-track_overlap_threshold-2"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value:
                _vm.body.stream_settings.detectors.body.track_overlap_threshold,
              expression:
                "body.stream_settings.detectors.body.track_overlap_threshold"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1",
              expression: "'required|between:0,1'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "track_overlap_threshold_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "track_overlap_threshold_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "track_miss_interval_input_field",
            name: "track_miss_interval_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: {
            value:
              _vm.body.stream_settings.detectors.body.track_overlap_threshold
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "track_overlap_threshold",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.play_speed,
              expression: "body.stream_settings.play_speed"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:-1,10",
              expression: "'required|between:-1,10'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "play_speed_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "play_speed_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "play_speed_input_field",
            name: "play_speed_input_field",
            type: "text",
            placeholder: "Entre 30 e 100"
          },
          domProps: { value: _vm.body.stream_settings.play_speed },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings,
                "play_speed",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_quality\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-quality-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-quality-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(20)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_quality\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-quality-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-quality-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(21)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.detectors.body.filter_min_quality,
              expression:
                "body.stream_settings.detectors.body.filter_min_quality"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1",
              expression: "'required|between:0,1'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "filter_min_quality_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "filter_min_quality_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "filter_min_quality_input_field",
            name: "filter_min_quality_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: {
            value: _vm.body.stream_settings.detectors.body.filter_min_quality
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "filter_min_quality",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-face-size-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-face-size-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(22)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-face-size-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-face-size-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(23)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.detectors.body.filter_min_size,
              expression: "body.stream_settings.detectors.body.filter_min_size"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "filter_min_face_size_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "filter_min_face_size_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "filter_min_face_size_input_field",
            name: "filter_min_face_size_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: {
            value: _vm.body.stream_settings.detectors.body.filter_min_size
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "filter_min_size",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_max_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-max-face-size-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-max-face-size-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(24)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_max_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-max-face-size-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-max-face-size-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(25)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.detectors.body.filter_max_size,
              expression: "body.stream_settings.detectors.body.filter_max_size"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "filter_max_face_size_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "filter_max_face_size_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "filter_max_face_size_input_field",
            name: "filter_max_face_size_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: {
            value: _vm.body.stream_settings.detectors.body.filter_max_size
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "filter_max_size",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(26),
      _vm._v(" "),
      _vm._m(27),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.detectors.body.jpeg_quality,
              expression: "body.stream_settings.detectors.body.jpeg_quality"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:30,100",
              expression: "'required|between:30,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "jpeg_quality_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "jpeg_quality_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "jpeg_quality_gpu_input_field",
            name: "jpeg_quality_gpu_input_field",
            type: "text",
            placeholder: "Entre 30 e 100"
          },
          domProps: {
            value: _vm.body.stream_settings.detectors.body.jpeg_quality
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "jpeg_quality",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(28),
      _vm._v(" "),
      _vm._m(29),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "overall_only_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "overall_only_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body.stream_settings.detectors.body.overall_only,
                    expression:
                      "body.stream_settings.detectors.body.overall_only"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "overall_only_input_field_yes",
                  name: "overall_only_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.overall_only,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "overall_only",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body.stream_settings.detectors.body.overall_only,
                    expression:
                      "body.stream_settings.detectors.body.overall_only"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "overall_only_input_field_no",
                  name: "overall_only_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body.overall_only,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "overall_only",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_params\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-ffmpeg-params-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-ffmpeg-params-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(30)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_params\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-ffmpeg-params-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-ffmpeg-params-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(31)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.ffmpeg_params,
              expression: "body.stream_settings.ffmpeg_params"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "ffmpeg_params_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "ffmpeg_params_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "ffmpeg_params_gpu_input_field",
            name: "ffmpeg_params_gpu_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.body.stream_settings.ffmpeg_params },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings,
                "ffmpeg_params",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_timeout_ms\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-timeout-ms-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-timeout-ms-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(32)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_timeout_ms\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-timeout-ms-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-timeout-ms-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(33)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.router_timeout_ms,
              expression: "body.stream_settings.router_timeout_ms"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1000,30000",
              expression: "'required|between:1000,30000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "router_timeout_ms_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "router_timeout_ms_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "router_timeout_ms_input_field",
            name: "router_timeout_ms_input_field",
            type: "text",
            placeholder: "Entre 1000 e 30000"
          },
          domProps: { value: _vm.body.stream_settings.router_timeout_ms },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings,
                "router_timeout_ms",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_verify_ssl\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-verify-ssl-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-verify-ssl-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(34)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_verify_ssl\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-verify-ssl-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-verify-ssl-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(35)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "router_verify_ssl_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "router_verify_ssl_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body.stream_settings.router_verify_ssl,
                    expression: "body.stream_settings.router_verify_ssl"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "router_verify_ssl_input_field_yes",
                  name: "router_verify_ssl_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.router_verify_ssl,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings,
                      "router_verify_ssl",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body.stream_settings.router_verify_ssl,
                    expression: "body.stream_settings.router_verify_ssl"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "router_verify_ssl_input_field_no",
                  name: "router_verify_ssl_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.router_verify_ssl,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings,
                      "router_verify_ssl",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_headers\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-headers-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-headers-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(36)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_headers\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-headers-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-headers-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(37)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.router_headers,
              expression: "router_headers"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:0|max:2000",
              expression: "'min:0|max:2000'"
            }
          ],
          class: {
            "ukid-text-area-external uk-textarea ukid-form-error ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
              "router_headers_input_field"
            ),
            "ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
              "router_headers_input_field"
            )
          },
          attrs: {
            rows: "5",
            "c-data-vv-as": "field",
            id: "router_headers_input_field",
            name: "router_headers_input_field",
            type: "textarea"
          },
          domProps: { value: _vm.router_headers },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.router_headers = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_body\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-body-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-body-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(38)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_body\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-body-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-body-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(39)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.router_body,
              expression: "router_body"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:5|max:1000",
              expression: "'required|min:5|max:1000'"
            }
          ],
          class: {
            "ukid-text-area-external ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
              "router_headers_input_field"
            ),
            "ukid-text-area-external uk-input ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
              "router_headers_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "router_headers_input_field",
            name: "router_headers_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 1000"
          },
          domProps: { value: _vm.router_body },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.router_body = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                use_stream_timestamp\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-use-stream-timestamp-1",
              "uk-toggle":
                "target: #ukid-plus-animation-use-stream-timestamp-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(40)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                use_stream_timestamp\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-use-stream-timestamp-2",
              "uk-toggle":
                "target: #ukid-plus-animation-use-stream-timestamp-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(41)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "use_stream_timestamp_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "use_stream_timestamp_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body.stream_settings.use_stream_timestamp,
                    expression: "body.stream_settings.use_stream_timestamp"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "use_stream_timestamp_input_field_yes",
                  name: "use_stream_timestamp_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.use_stream_timestamp,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings,
                      "use_stream_timestamp",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body.stream_settings.use_stream_timestamp,
                    expression: "body.stream_settings.use_stream_timestamp"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "use_stream_timestamp_input_field_no",
                  name: "use_stream_timestamp_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.use_stream_timestamp,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings,
                      "use_stream_timestamp",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.start_stream_timestamp,
              expression: "body.stream_settings.start_stream_timestamp"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,10",
              expression: "'required|between:0,10'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "start_stream_timestamp_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "start_stream_timestamp_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "start_stream_timestamp_input_field",
            name: "start_stream_timestamp_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 10"
          },
          domProps: { value: _vm.body.stream_settings.start_stream_timestamp },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings,
                "start_stream_timestamp",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                imotion_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-imotion-threshold-1",
              "uk-toggle":
                "target: #ukid-plus-animation-imotion-threshold-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(42)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                imotion_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-imotion-threshold-2",
              "uk-toggle":
                "target: #ukid-plus-animation-imotion-threshold-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(43)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.imotion_threshold,
              expression: "body.stream_settings.imotion_threshold"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1|decimal:3",
              expression: "'required|between:0,1|decimal:3'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "imotion_threshold_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "imotion_threshold_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "imotion_threshold_input_field",
            name: "imotion_threshold_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: { value: _vm.body.stream_settings.imotion_threshold },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings,
                "imotion_threshold",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                roi\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-gpu-roi-1",
              "uk-toggle":
                "target: #ukid-plus-animation-gpu-roi-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(44)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                roi\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-gpu-roi-2",
              "uk-toggle":
                "target: #ukid-plus-animation-gpu-roi-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(45)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.body.stream_settings.detectors.body.roi,
              expression: "body.stream_settings.detectors.body.roi"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "roi_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "roi_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "roi_input_field",
            name: "roi_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.body.stream_settings.detectors.body.roi },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "roi",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                realtime_post_every_interval\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pei-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pei-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(46)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                realtime_post_every_interval\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pei-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pei-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(47)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "realtime_post_every_interval_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "realtime_post_every_interval_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .realtime_post_every_interval,
                    expression:
                      "body.stream_settings.detectors.body.realtime_post_every_interval"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "realtime_post_every_interval_input_field_yes",
                  name: "realtime_post_every_interval_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .realtime_post_every_interval,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "realtime_post_every_interval",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .realtime_post_every_interval,
                    expression:
                      "body.stream_settings.detectors.body.realtime_post_every_interval"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "realtime_post_every_interval_input_field_no",
                  name: "realtime_post_every_interval_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .realtime_post_every_interval,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "realtime_post_every_interval",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                realtime_post_first_immediately\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pei-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pei-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: { hidden: "", id: "ukid-plus-animation-realtime-pei-1" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                realtime_post_first_immediately - VALOR PADRÃO: DESABILITADO\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pei-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pei-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-font-size-9",
            attrs: { hidden: "", id: "ukid-plus-animation-realtime-pei-2" }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "realtime_post_first_immediately_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "realtime_post_first_immediately_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .realtime_post_first_immediately,
                    expression:
                      "body.stream_settings.detectors.body.realtime_post_first_immediately"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "realtime_post_first_immediately_input_field_yes",
                  name: "realtime_post_first_immediately_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .realtime_post_first_immediately,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "realtime_post_first_immediately",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.body.stream_settings.detectors.body
                        .realtime_post_first_immediately,
                    expression:
                      "body.stream_settings.detectors.body.realtime_post_first_immediately"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "realtime_post_first_immediately_input_field_no",
                  name: "realtime_post_first_immediately_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.body.stream_settings.detectors.body
                      .realtime_post_first_immediately,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.body.stream_settings.detectors.body,
                      "realtime_post_first_immediately",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime_post_interval\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pi-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pi-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(48)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime_post_interval\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pi-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pi-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(49)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value:
                _vm.body.stream_settings.detectors.body.realtime_post_interval,
              expression:
                "body.stream_settings.detectors.body.realtime_post_interval"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|decimal:2|between:0,500",
              expression: "'required|decimal:2|between:0,500'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "realtime_post_interval_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "realtime_post_interval_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "realtime_post_interval_input_field",
            name: "realtime_post_interval_input_field",
            type: "text",
            placeholder: "Entre 0 e 500"
          },
          domProps: {
            value:
              _vm.body.stream_settings.detectors.body.realtime_post_interval
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.body.stream_settings.detectors.body,
                "realtime_post_interval",
                $event.target.value
              )
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-format-1" }
      },
      [
        _vm._v("\n                Não preencha se não"),
        _c("br"),
        _vm._v("\n                tiver certeza do que está fazendo"),
        _c("br"),
        _vm._v("\n                Passe o formato FFMPEG (mxg, flv, etc.)"),
        _c("br"),
        _vm._v("\n                se não puder ser detectado automaticamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-format-2" }
      },
      [
        _vm._v(
          "\n                Não preencha se não tiver certeza do que está fazendo"
        ),
        _c("br"),
        _vm._v(
          "\n                Passe o formato FFMPEG (mxg, flv, etc.) se não puder"
        ),
        _c("br"),
        _vm._v("\n                ser detectado automaticamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                fullframe_crop_rot\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                fullframe_crop_rot - Valor padrão: Desabilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                fullframe_use_png\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                fullframe_use_png - Valor padrão: Desabilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                post_best_track_frame\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                post_best_track_frame - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                post_best_track_normalize\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                post_best_track_normalize - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                post_last_track_frame\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                post_last_track_frame - Valor padrão: Desabilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                post_first_track_frame\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                post_first_track_frame - Valor padrão: Desabilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                track_interpolate_bboxes\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                track_interpolate_bboxes - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v("\n                track_send_history\n            ")
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                track_send_history - Valor padrão: Desabilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                track_deep_sort_filter_unconfirmed_tracks\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                track_deep_sort_filter_unconfirmed_tracks - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter_min_quality-1" }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-quality-2" }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-face-size-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-face-size-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-max-face-size-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho máximo de uma face em pixels"),
        _c("br"),
        _vm._v(
          "\n                As faces de grandes dimensões não são postadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-max-face-size-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho máximo de uma face em pixels"),
        _c("br"),
        _vm._v(
          "\n                As faces de grandes dimensões não são postadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                jpeg_quality - Valor padrão: 95%\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n            Qualidade da compactação JPEG"),
        _c("br"),
        _vm._v("\n            do frame original, em porcentagem"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                jpeg_quality - Valor padrão: 95%\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n            Qualidade da compactação JPEG do frame original, em porcentagem"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                overall_only - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                overall_only - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-ffmpeg-params-1" }
      },
      [
        _vm._v("\n                Lista de opções de ffmpeg de um fluxo "),
        _c("br"),
        _vm._v("\n                de vídeo com seus valorescomo uma"),
        _c("br"),
        _vm._v(
          '\n                matriz key=value: ["rtsp_transpotr = tcp", ..,'
        ),
        _c("br"),
        _vm._v('\n                "ss = 00: 20: 00"]'),
        _c("br"),
        _vm._v("\n                Confira o site FFmpeg "),
        _c(
          "a",
          {
            staticClass: "ukid-icon-pink-color",
            attrs: { href: "https://trac.ffmpeg.org", target: "_blank" }
          },
          [_vm._v("web site")]
        ),
        _c("br"),
        _vm._v("\n                para a lista completa de opções."),
        _c("br"),
        _vm._v("\n                Valor padrão: opções não especificadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-ffmpeg-params-2" }
      },
      [
        _vm._v(
          "\n                Lista de opções de ffmpeg de um fluxo de vídeo com seus valores como uma matriz"
        ),
        _c("br"),
        _vm._v(
          '\n                key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]'
        ),
        _c("br"),
        _vm._v("\n                Confira o site FFmpeg "),
        _c(
          "a",
          {
            staticClass: "ukid-icon-pink-color",
            attrs: { href: "https://trac.ffmpeg.org", target: "_blank" }
          },
          [_vm._v("web site")]
        ),
        _c("br"),
        _vm._v(
          "\n                para a lista completa de opções. Valor padrão: opções não especificadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-timeout-ms-1" }
      },
      [
        _vm._v("\n                router_timeout_ms - Valor padrão: 15000"),
        _c("br"),
        _vm._v("\n                Tempo limite para uma resposta face router"),
        _c("br"),
        _vm._v(
          "\n                a uma solicitação da API video-worker, em milissegundos"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o tempo limite expirar, o sistema registrará um erro"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-timeout-ms-2" }
      },
      [
        _vm._v("\n                router_timeout_ms - Valor padrão: 15000"),
        _c("br"),
        _vm._v("\n                Tempo limite para uma resposta face router"),
        _c("br"),
        _vm._v(
          "\n                a uma solicitação da API video-worker, em milissegundos"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o tempo limite expirar, o sistema registrará um erro"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-verify-ssl-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v(
          "\n                Não habilitado - Um cert. autoassinado também é aceito\n                Ativa a verificação do certificado https quando"
        ),
        _c("br"),
        _vm._v(
          "\n                video-worker e facerouter interagem por https"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-verify-ssl-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v(
          "\n                Não habilitado - Um cert. autoassinado também é aceito\n                Ativa a verificação do certificado https quando"
        ),
        _c("br"),
        _vm._v(
          "\n                video-worker e facerouter interagem por https"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-headers-1" }
      },
      [
        _vm._v("\n                Valor padrão: cabeçalhos não especificados"),
        _c("br"),
        _vm._v("\n                Campos de cabeçalho adicionais em uma"),
        _c("br"),
        _vm._v(
          "\n                solicitação POST ao postar uma face para WEB HUC"
        ),
        _c("br"),
        _vm._v("\n                Ex: Authorization=Token xxx"),
        _c("br"),
        _vm._v("\n                Accept=application/json"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-headers-2" }
      },
      [
        _vm._v("\n                Valor padrão: cabeçalhos não especificados"),
        _c("br"),
        _vm._v("\n                Campos de cabeçalho adicionais em uma"),
        _c("br"),
        _vm._v(
          "\n                solicitação POST ao postar uma face para WEB HUC"
        ),
        _c("br"),
        _vm._v("\n                Ex: Authorization=Token xxx"),
        _c("br"),
        _vm._v("\n                Accept=application/json"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-body-1" }
      },
      [
        _vm._v("\n                Campos adicionais no body da solicitação"),
        _c("br"),
        _vm._v(
          "\n                ao postar uma face. Ex: uuid=xxx,some_name=yyy"
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "uk-text-lowercase ukid-font-size-11" }, [
          _vm._v("\n                    image_type=BASE64"),
          _c("br"),
          _vm._v("\n                    external_url=https://xxx"),
          _c("br"),
          _vm._v("\n                    external_token=token_xxx"),
          _c("br"),
          _vm._v("\n                    mf_selector="),
          _c("br"),
          _vm._v("\n                    detector=DEFAULT"),
          _c("br"),
          _vm._v("\n                    gallery=DEFAULT"),
          _c("br"),
          _vm._v("\n                    latitude="),
          _c("br"),
          _vm._v("\n                    longitude=\n                ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-body-2" }
      },
      [
        _vm._v("\n                Campos adicionais no body da solicitação"),
        _c("br"),
        _vm._v(
          "\n                ao postar uma face. Ex: uuid=xxx,some_name=yyy"
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "uk-text-lowercase ukid-font-size-11" }, [
          _vm._v("\n                    image_type=BASE64"),
          _c("br"),
          _vm._v("\n                    external_url=https://xxx"),
          _c("br"),
          _vm._v("\n                    external_token=token_xxx"),
          _c("br"),
          _vm._v("\n                    mf_selector="),
          _c("br"),
          _vm._v("\n                    detector=DEFAULT"),
          _c("br"),
          _vm._v("\n                    gallery=DEFAULT"),
          _c("br"),
          _vm._v("\n                    latitude="),
          _c("br"),
          _vm._v("\n                    longitude=\n                ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-use-stream-timestamp-1" }
      },
      [
        _vm._v("\n                Se habilitado, recupera e posta"),
        _c("br"),
        _vm._v("\n                data e hora de um stream de vídeo"),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, envia a data e a hora reais"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-use-stream-timestamp-2" }
      },
      [
        _vm._v("\n                Se habilitado, recupera e posta"),
        _c("br"),
        _vm._v("\n                data e hora de um stream de vídeo"),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, envia a data e a hora reais"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-imotion-threshold-1" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v("\n                Intensidade mínima de movimento"),
        _c("br"),
        _vm._v("\n                a ser detectada pelo detector de movimento"),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente."
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: zero e números racionais positivos."
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = detector desativado"),
        _c("br"),
        _vm._v("\n                0.002 = valor padrão"),
        _c("br"),
        _vm._v("\n                0.05 = intensidade mínima muito alta"),
        _c("br"),
        _vm._v("\n                para detectar movimento"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-imotion-threshold-2" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v(
          "\n                Intensidade mínima de movimento a ser detectada pelo detector de movimento"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente."
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: zero e números racionais positivos."
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = detector desativado"),
        _c("br"),
        _vm._v("\n                0.002 = valor padrão"),
        _c("br"),
        _vm._v(
          "\n                0.05 = intensidade mínima muito alta para detectar movimento"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-gpu-roi-1" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v("\n                Ativar post de faces detectadas apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de uma região de interesse WxH + X + Y"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-gpu-roi-2" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v(
          "\n                Ativar post de faces detectadas apenas dentro de uma"
        ),
        _c("br"),
        _vm._v("\n                região de interesse WxH + X + Y"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pei-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em cada"),
        _c("br"),
        _vm._v("\n                período de tempo realtime_post_interval"),
        _c("br"),
        _vm._v("\n                Se não habilitado, procure o melhor"),
        _c("br"),
        _vm._v("\n                momento dinamicamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pei-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em cada"),
        _c("br"),
        _vm._v("\n                período de tempo realtime_post_interval"),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, procure o melhor momento dinamicamente"
        ),
        _c("br"),
        _vm._v("\n                e post em ordem crescente de qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pi-1" }
      },
      [
        _vm._v("\n                Valor padrão: 1 - Em segundos"),
        _c("br"),
        _vm._v(
          "\n                Somente para o modo em tempo real habilitado"
        ),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Habilitado,"
        ),
        _c("br"),
        _vm._v("\n                define o período em milissegundos dentro"),
        _c("br"),
        _vm._v("\n                do qual o rastreador de face pega"),
        _c("br"),
        _vm._v("\n                o melhor momento e o posta no face router"),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Não habilitado,"
        ),
        _c("br"),
        _vm._v("\n                define o período mínimo de tempo entre"),
        _c("br"),
        _vm._v("\n                2 posts da mesma face com maior qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pi-2" }
      },
      [
        _vm._v("\n                Valor padrão: 1 - Em segundos"),
        _c("br"),
        _vm._v(
          "\n                Somente para o modo em tempo real habilitado"
        ),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Habilitado, define o período em milissegundos"
        ),
        _c("br"),
        _vm._v("\n                dentro do qual o rastreador de face pega"),
        _c("br"),
        _vm._v("\n                o melhor momento e o posta no face router"),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Não habilitado, define o período mínimo de tempo"
        ),
        _c("br"),
        _vm._v(
          "\n                entre 2 posts da mesma face com maior qualidade"
        ),
        _c("br")
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-379932d7", { render: render, staticRenderFns: staticRenderFns })
  }
}