<!--suppress SpellCheckingInspection -->

<script>

    import Credential from './credential'
    import GeneralConfig from './general-config'
    import Marks from './marks'
    import Invite from './invite'
    import InviteWhatsapp from './invite-whatsapp'
    import InviteEmail from './invite-email'
    import LivenessConfig from './liveness-config'

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'editor', 'editorConfig', 'user', 'auth', 'role', 'vm'],

        components: {
            Credential,
            GeneralConfig,
            Marks,
            Invite,
            InviteWhatsapp,
            InviteEmail,
            LivenessConfig
        }
        
    }

</script>

<template id="register-configuration-template">
    <div>
        <div class="uk-width-auto ukid-margin-top-30 ukid-margin-bottom-30">
            <ul class="uk-nav uk-nav-default" uk-switcher="connect: #component-switcher; animation: uk-animation-fade">
                <li>
                    <a href="#" class="ukid-register-menu ukid-text-alert-color">
                        <span uk-icon="icon:user;ratio:1.0"></span> Cadastro
                    </a>
                </li>
                <li>
                    <a href="#" class="ukid-text-alert-color">
                        <span uk-icon="icon:cog;ratio:1.0"></span> Config. Geral
                    </a>
                </li>
                <li>
                    <a href="#" class="ukid-text-alert-color">
                        <span uk-icon="icon:cog;ratio:1.0"></span> Config. Liveness
                    </a>
                </li>                
                <li>
                    <a href="#" class="ukid-text-alert-color">
                        <span uk-icon="icon:more;ratio:1.0"></span> unike.PONTO
                    </a>
                </li>                                
                <li>
                    <a href="#" class="ukid-text-alert-color">
                        <span uk-icon="icon:image;ratio:1.0"></span> Invite Validade / Logo
                    </a>
                </li>
                <li>
                    <a href="#" class="ukid-text-alert-color">
                        <span uk-icon="icon:whatsapp;ratio:1.0"></span> Invite Whatsapp
                    </a>
                </li>
                <li>
                    <a href="#" class="ukid-text-alert-color">
                        <span uk-icon="icon:mail;ratio:1.0"></span> Invite Email
                    </a>
                </li>
            </ul>
        </div>

        <div class="uk-width-expand@m">

            <ul id="component-switcher" class="uk-switcher">
                
                <credential
                    ref="credential"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">                    
                </credential>

                <general-config
                    ref="generalConfig"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :editor="editor"
                    :editor-config="editorConfig"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">                    
                </general-config>

                <liveness-config
                    ref="livenessConfig"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">
                </liveness-config>                

                <marks
                    ref="marks"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :editor="editor"
                    :editor-config="editorConfig"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">
                </marks>
                
                <invite
                    ref="invite"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">
                </invite>

                <inviteWhatsapp
                    ref="inviteWhatsapp"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">
                </inviteWhatsapp>

                <inviteEmail
                    ref="inviteEmail"
                    :app="app"
                    :strings="strings"
                    :commons="commons"
                    :editor="editor"
                    :editor-config="editorConfig"
                    :user="user"
                    :auth="auth"
                    :role="role"
                    :vm="vm">
                </inviteEmail>

            </ul>

        </div>
    </div>
</template>