export default class UploadAdapter {

    constructor( loader, host ) {
        // The file loader instance to use during the upload.
        this.loader = loader
        this.host = host
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                this._initRequest(this.host);
                this._initListeners( resolve, reject, file )
                this._sendRequest( file )
            } ) )
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort()
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest(host) {
        const xhr = this.xhr = new XMLHttpRequest()
        
        xhr.open( 'POST', host + '/client/upload/invite', true )

        xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        xhr.setRequestHeader('Access-Control-Allow-Headers', 'Origin, Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Origin, X-Requested-With, Content-Type')
        xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET, PUT, DELETE, OPTIONS')
        xhr.setRequestHeader('Authorization', 'Bearer ' + sessionStorage.token)

        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject, file ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;

        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;

            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            resolve( {
                default: response.data
            } );
        } );

        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    // Prepares the data and sends the request.
    _sendRequest( file ) {
        // Prepare the form data.
        const data = new FormData();

        data.append( 'photo', file );

        // Send the request.
        this.xhr.send( data );
    }
}