var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-devices", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog uk-modal-body" }, [
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Faces por Página")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Faces por Página")]
            ),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-22-percent uk-visible@s" },
              [
                _c("span", {
                  staticClass: "uk-form-icon",
                  attrs: { "uk-icon": "icon:list" }
                }),
                _vm._v(" "),
                _c("the-mask", {
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { mask: ["##", "##"] },
                  model: {
                    value: _vm.filters.imagesPerPage,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "imagesPerPage", $$v)
                    },
                    expression: "filters.imagesPerPage"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-50-percent uk-hidden@s" },
              [
                _c("span", {
                  staticClass: "uk-form-icon",
                  attrs: { "uk-icon": "icon:list" }
                }),
                _vm._v(" "),
                _c("the-mask", {
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { mask: ["##", "##"] },
                  model: {
                    value: _vm.filters.imagesPerPage,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "imagesPerPage", $$v)
                    },
                    expression: "filters.imagesPerPage"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Tamhanho da Face")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Tamhanho da Face")]
            ),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-50-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.imageSize,
                    expression: "filters.imageSize"
                  }
                ],
                staticClass: "uk-range",
                attrs: { type: "range", min: "220", max: "600", step: "1" },
                domProps: { value: _vm.filters.imageSize },
                on: {
                  __r: function($event) {
                    return _vm.$set(
                      _vm.filters,
                      "imageSize",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(
                " " + _vm._s(_vm.filters.imageSize) + "\n                    "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Tamhanho da Fonte")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Tamhanho da Fonte")]
            ),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-50-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.fontSize,
                    expression: "filters.fontSize"
                  }
                ],
                staticClass: "uk-range",
                attrs: { type: "range", min: "16", max: "50", step: "1" },
                domProps: { value: _vm.filters.fontSize },
                on: {
                  __r: function($event) {
                    return _vm.$set(
                      _vm.filters,
                      "fontSize",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(
                " " + _vm._s(_vm.filters.fontSize) + "\n                    "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-30-percent uk-visible@s" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.searchParam,
                      expression: "filters.searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.searchParam },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "searchParam", $event.target.value)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.searchParam,
                      expression: "filters.searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.searchParam },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "searchParam", $event.target.value)
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Faces Detectadas no Intervalo De:")]
            ),
            _vm._v(" "),
            _vm._m(1),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { attrs: { "uk-grid": "" } }, [
              _c(
                "div",
                { staticClass: "uk-inline ukid-width-20-percent uk-visible@s" },
                [
                  _vm._v(
                    "\n                            DE:\n                            "
                  ),
                  _c("datetime", {
                    attrs: {
                      type: "date",
                      "input-class": "mx-input ukid-width-100-percent",
                      "value-zone": "America/Sao_Paulo",
                      zone: "America/Sao_Paulo",
                      format: "dd/MM/yyyy",
                      phrases: {},
                      "hour-step": 1,
                      "minute-step": 5,
                      "week-start": 7,
                      auto: true,
                      "max-datetime": _vm.filters.endDate,
                      "use24-hour": ""
                    },
                    model: {
                      value: _vm.filters.startDate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "startDate", $$v)
                      },
                      expression: "filters.startDate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "uk-inline ukid-width-20-percent uk-visible@s" },
                [
                  _vm._v(
                    "\n                            ATÉ:\n                            "
                  ),
                  _c("datetime", {
                    attrs: {
                      type: "date",
                      "input-class": "mx-input ukid-width-100-percent",
                      "value-zone": "America/Sao_Paulo",
                      zone: "America/Sao_Paulo",
                      format: "dd/MM/yyyy",
                      phrases: {},
                      "hour-step": 1,
                      "minute-step": 5,
                      "week-start": 7,
                      auto: true,
                      "max-datetime": new Date().toISOString(),
                      "min-datetime": _vm.filters.startDate,
                      "use24-hour": ""
                    },
                    model: {
                      value: _vm.filters.endDate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "endDate", $$v)
                      },
                      expression: "filters.endDate"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _vm._v(
                  "\n                        DE:\n                        "
                ),
                _c("datetime", {
                  attrs: {
                    type: "date",
                    "input-class": "mx-input ukid-width-100-percent",
                    "value-zone": "America/Sao_Paulo",
                    zone: "America/Sao_Paulo",
                    format: "dd/MM/yyyy",
                    phrases: {},
                    "hour-step": 1,
                    "minute-step": 5,
                    "week-start": 7,
                    auto: true,
                    "max-datetime": _vm.filters.endDate,
                    "use24-hour": ""
                  },
                  model: {
                    value: _vm.filters.startDate,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "startDate", $$v)
                    },
                    expression: "filters.startDate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _c("br"),
                _vm._v(
                  "\n                        ATÉ:\n                        "
                ),
                _c("datetime", {
                  attrs: {
                    type: "date",
                    "input-class": "mx-input ukid-width-100-percent",
                    "value-zone": "America/Sao_Paulo",
                    zone: "America/Sao_Paulo",
                    format: "dd/MM/yyyy",
                    phrases: {},
                    "hour-step": 1,
                    "minute-step": 5,
                    "week-start": 7,
                    auto: true,
                    "max-datetime": new Date().toISOString(),
                    "min-datetime": _vm.filters.startDate,
                    "use24-hour": ""
                  },
                  model: {
                    value: _vm.filters.endDate,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "endDate", $$v)
                    },
                    expression: "filters.endDate"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Registros agrupados\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                        Registros agrupados\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.blnJoin,
                    expression: "filters.blnJoin"
                  }
                ],
                staticClass: "ukid-current-date uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.filters.blnJoin)
                    ? _vm._i(_vm.filters.blnJoin, null) > -1
                    : _vm._q(_vm.filters.blnJoin, "true")
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.filters.blnJoin,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.filters, "blnJoin", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.filters,
                            "blnJoin",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.filters, "blnJoin", $$c)
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Somente registros da data atual\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                        Somente registros da data atual\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.currentDate,
                    expression: "filters.currentDate"
                  }
                ],
                staticClass: "ukid-current-date uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.filters.currentDate)
                    ? _vm._i(_vm.filters.currentDate, null) > -1
                    : _vm._q(_vm.filters.currentDate, "true")
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.filters.currentDate,
                        $$el = $event.target,
                        $$c = $$el.checked ? "true" : "false"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.filters,
                              "currentDate",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.filters,
                              "currentDate",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.filters, "currentDate", $$c)
                      }
                    },
                    function($event) {
                      return _vm.currentDateFilter()
                    }
                  ]
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Todas as detecções\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                        Todas as detecções\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.personsNotDetected,
                    expression: "filters.personsNotDetected"
                  }
                ],
                staticClass: "uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.filters.personsNotDetected)
                    ? _vm._i(_vm.filters.personsNotDetected, null) > -1
                    : _vm._q(_vm.filters.personsNotDetected, "true")
                },
                on: {
                  click: function($event) {
                    return _vm.includePersonsNotDetected()
                  },
                  change: function($event) {
                    var $$a = _vm.filters.personsNotDetected,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.filters,
                            "personsNotDetected",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.filters,
                            "personsNotDetected",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.filters, "personsNotDetected", $$c)
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Todas as detecções menos Recusadas\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.personsNotDetectedWithoutRefusals,
                    expression: "filters.personsNotDetectedWithoutRefusals"
                  }
                ],
                staticClass: "uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.filters.personsNotDetectedWithoutRefusals
                  )
                    ? _vm._i(
                        _vm.filters.personsNotDetectedWithoutRefusals,
                        null
                      ) > -1
                    : _vm._q(
                        _vm.filters.personsNotDetectedWithoutRefusals,
                        "true"
                      )
                },
                on: {
                  click: function($event) {
                    return _vm.includePersonsNotDetectedWithoutRefusals()
                  },
                  change: function($event) {
                    var $$a = _vm.filters.personsNotDetectedWithoutRefusals,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.filters,
                            "personsNotDetectedWithoutRefusals",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.filters,
                            "personsNotDetectedWithoutRefusals",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.filters,
                        "personsNotDetectedWithoutRefusals",
                        $$c
                      )
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Somente BLC\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                        Somente BLC\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.blcDetected,
                    expression: "filters.blcDetected"
                  }
                ],
                staticClass: "uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.filters.blcDetected)
                    ? _vm._i(_vm.filters.blcDetected, null) > -1
                    : _vm._q(_vm.filters.blcDetected, "true")
                },
                on: {
                  click: function($event) {
                    return _vm.onlyBLC()
                  },
                  change: function($event) {
                    var $$a = _vm.filters.blcDetected,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.filters,
                            "blcDetected",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.filters,
                            "blcDetected",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.filters, "blcDetected", $$c)
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Faces Recusadas\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                        Faces Recusadas\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.personsRefusals,
                    expression: "filters.personsRefusals"
                  }
                ],
                staticClass: "uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.filters.personsRefusals)
                    ? _vm._i(_vm.filters.personsRefusals, null) > -1
                    : _vm._q(_vm.filters.personsRefusals, "true")
                },
                on: {
                  click: function($event) {
                    return _vm.onlyRefusals()
                  },
                  change: function($event) {
                    var $$a = _vm.filters.personsRefusals,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.filters,
                            "personsRefusals",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.filters,
                            "personsRefusals",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.filters, "personsRefusals", $$c)
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                        Registros com Problemas\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                        Registros com Problemas\n                    "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.personsWithProblems,
                    expression: "filters.personsWithProblems"
                  }
                ],
                staticClass: "uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.filters.personsWithProblems)
                    ? _vm._i(_vm.filters.personsWithProblems, null) > -1
                    : _vm._q(_vm.filters.personsWithProblems, "true")
                },
                on: {
                  click: function($event) {
                    return _vm.includePersonsWithProblems()
                  },
                  change: function($event) {
                    var $$a = _vm.filters.personsWithProblems,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.filters,
                            "personsWithProblems",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.filters,
                            "personsWithProblems",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.filters, "personsWithProblems", $$c)
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "uk-margin" },
            [
              _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
                _vm._v("Customers")
              ]),
              _c("br"),
              _vm._v(" "),
              _c(
                "multiselect",
                {
                  staticStyle: { color: "#444444 !important" },
                  attrs: {
                    options: ["TODOS"].concat(
                      _vm.customers.length > 0
                        ? _vm.customers.map(function(obj) {
                            return obj.user.toUpperCase()
                          })
                        : []
                    ),
                    searchable: true,
                    "close-on-select": true,
                    "show-labels": false,
                    placeholder: "Selecione o customer"
                  },
                  on: { input: _vm.handleCustomerSelection },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c("span", { staticClass: "multiselect__tag" }, [
                            _vm._v(
                              "\n                              " +
                                _vm._s(props.option) +
                                " -> selecionado\n                          "
                            )
                          ])
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.customerSelected,
                    callback: function($$v) {
                      _vm.customerSelected = $$v
                    },
                    expression: "customerSelected"
                  }
                },
                [
                  _vm._v(" "),
                  _c("template", { slot: "noResult" }, [
                    _c("span", [
                      _vm._v(
                        "\n                                  Nenhum customer encontrado\n                              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "noOptions" }, [
                    _c("span", [
                      _vm._v(
                        "\n                              Comece a digitar\n                          "
                      )
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "uk-panel uk-panel-scrollable" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-link ukid-font-size-9",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.selectAllDevices()
                  }
                }
              },
              [_vm._v("Selecionar Tudo")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-link ukid-font-size-9",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.desselectAllDevices()
                  }
                }
              },
              [_vm._v("Desmarcar Tudo")]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "uk-list" },
              _vm._l(_vm.devices, function(device) {
                return _c("li", { key: device.uuid }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.selectedDevices,
                          expression: "filters.selectedDevices"
                        }
                      ],
                      staticClass: "uk-checkbox",
                      attrs: { id: device.uuid, type: "checkbox" },
                      domProps: {
                        value: device.uuid,
                        checked: Array.isArray(_vm.filters.selectedDevices)
                          ? _vm._i(_vm.filters.selectedDevices, device.uuid) >
                            -1
                          : _vm.filters.selectedDevices
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.filters.selectedDevices,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = device.uuid,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.filters,
                                  "selectedDevices",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.filters,
                                  "selectedDevices",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.filters, "selectedDevices", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(
                      " " + _vm._s(device.type) + " - " + _vm._s(device.meta)
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "ukid-font-size-10" }, [
                      _vm._v(_vm._s(device.uuid))
                    ]),
                    _vm._v(" "),
                    _vm.sub.indexOf("admin") > -1
                      ? _c("span", { staticClass: "ukid-font-size-10" }, [
                          _vm._v(
                            "\n                                     - " +
                              _vm._s(
                                device.enabled ? "Habilitado" : "Desabilitado"
                              ) +
                              "\n                                "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _c("p", { staticClass: "uk-text-right uk-visible@s" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v("Não Salvar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary uk-modal-close ukid-magin-left-5",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveSelectedDevices()
                  }
                }
              },
              [_vm._v("Salvar")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary ukid-width-250-px ukid-margin-top-10",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.generateExcel()
                  }
                }
              },
              [_vm._v("Gerar Excel")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "uk-text-right uk-hidden@s" }, [
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-default uk-button-small uk-modal-close ukid-magin-right-5",
                attrs: { type: "button" }
              },
              [_vm._v("Não Salvar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary uk-button-small uk-modal-close ukid-magin-left-10",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveSelectedDevices()
                  }
                }
              },
              [_vm._v("Salvar")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary uk-button-small ukid-width-200-px ukid-margin-top-10",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.generateExcel()
                  }
                }
              },
              [_vm._v("Gerar Excel")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "modal-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonCancel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.del()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonDelete))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent"
        },
        [
          _c(
            "div",
            { staticClass: "uk-card-header ukid-supress-border-bottom" },
            [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-60",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-20 ukid-margin-top-40 uk-visible@s"
                      },
                      [_vm._v("Pessoas nas Proximidades")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-16 ukid-margin-top-40 uk-hidden@s"
                      },
                      [_vm._v("Pessoas nas Proximidades")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(
                          "                                \n                                Módulo piloto para detecção de pessoas nas proximidades das câmeras RTSP\n                                "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-label ukid-label-blue-color" },
                          [
                            _vm._v(
                              "\n                                    Total de Pessoas Até a Data de Hoje: " +
                                _vm._s(_vm.pagination.databaseCount) +
                                "\n                                    "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                    Total de Pessoas Cadastradas: " +
                                _vm._s(_vm.pagination.totalKnown) +
                                "\n                                    "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                    Total de Pessoas Desconhecidas: " +
                                _vm._s(_vm.pagination.totalUnknown) +
                                "\n                                    "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                    Total de Pessoas Recusadas: " +
                                _vm._s(_vm.pagination.totalRefusals) +
                                "\n                                    "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                    Total de Pessoas de Acordo com os Filtros: " +
                                _vm._s(_vm.pagination.total) +
                                "\n                                "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "uk-hidden@s ukid-font-size-13" },
                        [
                          _vm._v(
                            "\n                                Módulo piloto para detecção de pessoas nas proximidades das câmeras RTSP\n                                "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-label ukid-label-blue-color ukid-font-size-9"
                            },
                            [
                              _vm._v(
                                "\n                                    Total de Pessoas Até a Data de Hoje: " +
                                  _vm._s(_vm.pagination.databaseCount) +
                                  "\n                                    "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                    Total de Pessoas Cadastradas: " +
                                  _vm._s(_vm.pagination.totalKnown) +
                                  "\n                                    "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                    Total de Pessoas Desconhecidas: " +
                                  _vm._s(_vm.pagination.totalUnknown) +
                                  "\n                                    "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                    Total de Pessoas Recusadas: " +
                                  _vm._s(_vm.pagination.totalRefusals) +
                                  "\n                                    "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                    Total de Pessoas de Acordo com os Filtros: " +
                                  _vm._s(_vm.pagination.total) +
                                  "\n                                "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Valor selecionado: " + _vm._s(_vm.selectedForUnion)
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15",
                          attrs: { align: "right" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { "uk-toggle": "target: #modal-devices" },
                              on: {
                                click: function($event) {
                                  return _vm.setFilters()
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "uk-margin-small-right uk-icon",
                                attrs: { "uk-icon": "cog" }
                              }),
                              _vm._v(
                                "\n                                    Filtrar\n                                "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17",
                          attrs: { align: "right" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { "uk-toggle": "target: #modal-devices" },
                              on: {
                                click: function($event) {
                                  return _vm.setFilters()
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "uk-margin-small-right uk-icon",
                                attrs: { "uk-icon": "cog" }
                              }),
                              _vm._v(
                                "\n                                    Filtrar por Dispositivo\n                                "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0",
                          attrs: { "uk-toggle": "target: #modal-devices" },
                          on: {
                            click: function($event) {
                              return _vm.setFilters()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "cog" }
                          }),
                          _vm._v(
                            "\n                                Filtrar por Dispositivo\n                            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0",
                          on: {
                            click: function($event) {
                              return _vm.openFullScreen()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "tv" }
                          }),
                          _vm._v(
                            "\n                                Tela Cheia\n                            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0",
                          on: {
                            click: function($event) {
                              return _vm.closeFullScreen()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "close" }
                          }),
                          _vm._v(
                            "\n                                Sair da Tela Cheia\n                            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("br")
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ukid-font-size-14 ukid-icon-pink-color ukid-margin-top-10 uk-align-right ukid-margin-bottom-0"
                },
                [
                  _vm._v(
                    "\n                    Atenção aos filtros que você configurou\n                "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-card-footer ukid-supress-border-top" }, [
            _c(
              "div",
              { staticClass: "uk-flex uk-flex-center ukid-padding-top-20" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-flex uk-flex-center uk-grid-medium uk-text-center",
                    attrs: { "uk-grid": "" }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.registers.length,
                            expression: "!registers.length"
                          }
                        ],
                        staticClass:
                          "uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color",
                        attrs: { "uk-alert": "" }
                      },
                      [
                        _c("p", { staticClass: "ukid-white-color" }, [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "warning" }
                          }),
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.App.proximity.nobody) +
                              "\n                            "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.registers, function(detectedFaces) {
                      return _c(
                        "div",
                        {
                          key: detectedFaces.id,
                          staticClass: "ukid-offcanvas-over"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "uk-flex-top ukid-photo-zindex",
                              attrs: {
                                id: "modal-media-image-" + detectedFaces.id,
                                "uk-modal": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog ukid-zoom-photo uk-margin-auto-vertical"
                                },
                                [
                                  _c("button", {
                                    staticClass: "uk-modal-close-outside",
                                    attrs: { type: "button", "uk-close": "" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        id:
                                          "ukid-container-zoom-" +
                                          detectedFaces.id
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ukid-photo-effect-zoom",
                                        attrs: {
                                          crossorigin: "Anonymous",
                                          id:
                                            "ukid-image-face-zoom-" +
                                            detectedFaces.id,
                                          "uk-tooltip": "",
                                          src: detectedFaces.snapshot,
                                          width: "640",
                                          height: "480",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-overlay uk-light uk-position-bottom",
                                      style:
                                        "font-size:" +
                                        _vm.filters.fontSize +
                                        "px !important"
                                    },
                                    [
                                      _c("p", [
                                        detectedFaces.documentNumber
                                          ? _c("span", [
                                              _vm._v(
                                                "Docto : " +
                                                  _vm._s(
                                                    detectedFaces.documentNumber
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" "),
                                        detectedFaces.name
                                          ? _c("span", [
                                              _vm._v(
                                                " Nome : " +
                                                  _vm._s(detectedFaces.name)
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" "),
                                        detectedFaces.email
                                          ? _c("span", [
                                              _vm._v(
                                                " Email  : " +
                                                  _vm._s(detectedFaces.email)
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" "),
                                        detectedFaces.updateTimestamp
                                          ? _c("span", [
                                              _vm._v(
                                                " Data   : " +
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      new Date(
                                                        detectedFaces.updateTimestamp
                                                      ),
                                                      "DD/MM/YYYY HH:mm:ss"
                                                    )
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" "),
                                        detectedFaces.maskDetection
                                          ? _c("span", [
                                              _vm._v(
                                                " \n                                                Máscara : " +
                                                  _vm._s(
                                                    detectedFaces
                                                      .maskDetection[0]
                                                      .confidence * 100
                                                  ) +
                                                  "% \n                                                "
                                              ),
                                              detectedFaces.maskDetection[0]
                                                .name == "none"
                                                ? _c("span", [
                                                    _vm._v("não está usando")
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              detectedFaces.maskDetection[0]
                                                .name == "correct"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "usando corretamente"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              detectedFaces.maskDetection[0]
                                                .name == "incorrect"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "usando incorretamente"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        detectedFaces.captures[
                                          detectedFaces.captures.length - 1
                                        ].personAttributes.headpose != null
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                                HeadPose : PITCH = " +
                                                  _vm._s(
                                                    detectedFaces.captures[
                                                      detectedFaces.captures
                                                        .length - 1
                                                    ].personAttributes.headpose
                                                      .result.pitch
                                                  ) +
                                                  " ROLL = " +
                                                  _vm._s(
                                                    detectedFaces.captures[
                                                      detectedFaces.captures
                                                        .length - 1
                                                    ].personAttributes.headpose
                                                      .result.roll
                                                  ) +
                                                  " YAW = " +
                                                  _vm._s(
                                                    detectedFaces.captures[
                                                      detectedFaces.captures
                                                        .length - 1
                                                    ].personAttributes.headpose
                                                      .result.yaw
                                                  ) +
                                                  "\n                                            "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-flex-top ukid-photo-zindex",
                              attrs: {
                                id: "modal-media-emotions-" + detectedFaces.id,
                                "uk-modal": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-modal-body ukid-font-size-18",
                                      attrs: { "uk-overflow-auto": "" }
                                    },
                                    [
                                      _vm._m(8, true),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Sentimentos\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px"
                                        },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "uk-list ukid-margin-top-0 ukid-font-size-12"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    detectedFaces.captures[
                                                      detectedFaces.captures
                                                        .length - 1
                                                    ].personAttributes.emotion
                                                      .name
                                                  ) +
                                                  " -> " +
                                                  _vm._s(
                                                    _vm._f("numberFormat")(
                                                      parseFloat(
                                                        detectedFaces.captures[
                                                          detectedFaces.captures
                                                            .length - 1
                                                        ].personAttributes
                                                          .emotion.score * 100
                                                      ).toFixed(4),
                                                      "00.00"
                                                    )
                                                  ) +
                                                  "%\n                                            "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(9, true)
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-flex-top ukid-photo-zindex",
                              attrs: {
                                id: "modal-media-debug-" + detectedFaces.id,
                                "uk-modal": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-modal-body ukid-font-size-18",
                                      attrs: { "uk-overflow-auto": "" }
                                    },
                                    [
                                      _vm._m(10, true),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Debug\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px"
                                        },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "uk-list ukid-margin-top-0 ukid-font-size-12"
                                            },
                                            [
                                              _c("li", [
                                                detectedFaces.reasonRefusals
                                                  .length === 0 ||
                                                detectedFaces.reasonRefusals ==
                                                  null
                                                  ? _c("span", [
                                                      _c("img", {
                                                        staticClass:
                                                          "ukid-height-150-px",
                                                        attrs: {
                                                          src:
                                                            "data:image/png;base64," +
                                                            detectedFaces.person
                                                              .principalCrop
                                                              .base64Crop
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "    \n                                                "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticClass:
                                                    "ukid-height-150-px",
                                                  attrs: {
                                                    src:
                                                      "data:image/png;base64," +
                                                      detectedFaces.firstCropBase64
                                                  }
                                                }),
                                                _vm._v(
                                                  "    \n                                                    "
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "ukid-height-150-px",
                                                  attrs: {
                                                    src:
                                                      "data:image/png;base64," +
                                                      detectedFaces.cropBase64
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("br"),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("b", [
                                                  _vm._v(
                                                    "---Atributos da Detecção---"
                                                  )
                                                ]),
                                                _c("br"),
                                                _c("br"),
                                                _vm._v(" "),
                                                detectedFaces.person &&
                                                detectedFaces.person
                                                  .firstDetect &&
                                                detectedFaces.person.firstDetect
                                                  .timestamp
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " firstDetect: " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              new Date(
                                                                detectedFaces
                                                                  .person
                                                                  .firstDetect
                                                                  .timestamp * 1
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c("br"),
                                                _vm._v(" "),
                                                detectedFaces.person &&
                                                detectedFaces.person
                                                  .lastDetect &&
                                                detectedFaces.person.lastDetect
                                                  .timestamp
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " lastDetect: " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              new Date(
                                                                detectedFaces
                                                                  .person
                                                                  .lastDetect
                                                                  .timestamp * 1
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c("br"),
                                                _vm._v(" "),
                                                detectedFaces.person
                                                  .principalCrop
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " principalTimestamp: " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              new Date(
                                                                detectedFaces.person.principalCrop.timestamp
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                                    firstSnapshot: "
                                                ),
                                                detectedFaces.firstSnapshotDate &&
                                                detectedFaces.firstSnapshot
                                                  ? _c("span", [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              detectedFaces.firstSnapshot,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [_vm._v("clique aqui")]
                                                      ),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              new Date(
                                                                detectedFaces.firstSnapshotDate *
                                                                  1
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("sem imagem")
                                                    ]),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                                    lastSnapshot: "
                                                ),
                                                detectedFaces.lastSnapshotDate &&
                                                detectedFaces.snapshot
                                                  ? _c("span", [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              detectedFaces.snapshot,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [_vm._v("clique aqui")]
                                                      ),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              new Date(
                                                                detectedFaces.lastSnapshotDate *
                                                                  1
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("sem imagem")
                                                    ]),
                                                _c("br")
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                detectedFaces.reasonRefusals,
                                                function(rf) {
                                                  return _c(
                                                    "li",
                                                    { key: rf.id },
                                                    [
                                                      _c("span", [
                                                        _vm._v("snapshot: "),
                                                        detectedFaces.snapshot
                                                          ? _c("span", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      detectedFaces.snapshot,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "clique aqui"
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                "sem imagem"
                                                              )
                                                            ])
                                                      ]),
                                                      _c("br"),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("b", [
                                                        _vm._v(
                                                          "---Motivo da Recusa---"
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "type: " +
                                                            _vm._s(rf.type)
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "description: " +
                                                            _vm._s(
                                                              rf.description
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "cropESRate: " +
                                                            _vm._s(
                                                              rf.cropESRate
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "quality: " +
                                                            _vm._s(rf.quality)
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "cropWidth: " +
                                                            _vm._s(rf.cropWidth)
                                                        )
                                                      ]),
                                                      _vm._v(" - "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "cropHeight: " +
                                                            _vm._s(
                                                              rf.cropHeight
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      detectedFaces.camId
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "câmera: " +
                                                                _vm._s(
                                                                  detectedFaces.camId
                                                                )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              detectedFaces.person &&
                                              detectedFaces.person.principalCrop
                                                ? _c(
                                                    "li",
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "---Registro Principal ---"
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t    id19 Parent: "
                                                      ),
                                                      detectedFaces.person
                                                        .id19Parent
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  detectedFaces
                                                                    .person
                                                                    .id19Parent
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("br")
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "id19: " +
                                                            _vm._s(
                                                              detectedFaces.id19
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t    Age: "
                                                      ),
                                                      detectedFaces.person.age
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  detectedFaces
                                                                    .person.age
                                                                    .attributeValue
                                                                ) +
                                                                " ( " +
                                                                _vm._s(
                                                                  detectedFaces
                                                                    .person.age
                                                                    .perc !==
                                                                    null
                                                                    ? detectedFaces.person.age.perc.toFixed(
                                                                        4
                                                                      )
                                                                    : "N/A"
                                                                ) +
                                                                " ) "
                                                            ),
                                                            _c("br")
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t    Gender: "
                                                      ),
                                                      detectedFaces.person
                                                        .gender
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  detectedFaces
                                                                    .person
                                                                    .gender
                                                                    .attributeValue
                                                                ) +
                                                                " ( " +
                                                                _vm._s(
                                                                  detectedFaces
                                                                    .person
                                                                    .gender
                                                                    .perc !==
                                                                    null
                                                                    ? detectedFaces.person.gender.perc.toFixed(
                                                                        4
                                                                      )
                                                                    : "N/A"
                                                                ) +
                                                                " ) "
                                                            ),
                                                            _c("br")
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t            Principal Store: "
                                                      ),
                                                      detectedFaces.person
                                                        .principalStore
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                detectedFaces
                                                                  .person
                                                                  .principalStore
                                                                  .storeName
                                                              ) + " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n                                Qtt Detect: "
                                                      ),
                                                      detectedFaces.person.age
                                                        .qttDetect
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                detectedFaces
                                                                  .person.age
                                                                  .qttDetect
                                                              ) + " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" - Qtt Days: "),
                                                      detectedFaces.person
                                                        .principalStore
                                                        .sumDetect
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                detectedFaces
                                                                  .person
                                                                  .principalStore
                                                                  .sumDetect
                                                              ) + " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t    Work: "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        detectedFaces.person
                                                          .work,
                                                        function(work) {
                                                          return _c("span", [
                                                            work
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "Start Date: " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dateFormat"
                                                                        )(
                                                                          new Date(
                                                                            work.startDate
                                                                          ),
                                                                          "DD/MM/YYYY HH:mm:ss"
                                                                        )
                                                                      ) +
                                                                      " - End Date: "
                                                                  ),
                                                                  work.endDate
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "dateFormat"
                                                                                )(
                                                                                  new Date(
                                                                                    work.endDate
                                                                                  ),
                                                                                  "DD/MM/YYYY HH:mm:ss"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c("br")
                                                                ])
                                                              : _vm._e()
                                                          ])
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "CropESRate: " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropESRate
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Snapshot: "),
                                                        detectedFaces.person
                                                          .principalCrop
                                                          .snapshot
                                                          ? _c("span", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      detectedFaces
                                                                        .person
                                                                        .principalCrop
                                                                        .snapshot,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "clique aqui"
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                "sem imagem"
                                                              )
                                                            ])
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "CropQuality: " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropQuality
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "CropHeadPose: pitch " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropHeadPose
                                                                .result.pitch
                                                            ) +
                                                            " roll " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropHeadPose
                                                                .result.roll
                                                            ) +
                                                            " yaw " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropHeadPose
                                                                .result.yaw
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "CropWidth: " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropWidth
                                                            )
                                                        )
                                                      ]),
                                                      _vm._v(" - "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "CropHeight: " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cropHeight
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "câmera: " +
                                                            _vm._s(
                                                              detectedFaces
                                                                .person
                                                                .principalCrop
                                                                .cameraCrop.id
                                                                ? detectedFaces
                                                                    .person
                                                                    .principalCrop
                                                                    .cameraCrop
                                                                    .id
                                                                : "-"
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "createCropTimestamp: " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormat"
                                                              )(
                                                                new Date(
                                                                  detectedFaces.person.createTimestamp
                                                                ),
                                                                "DD/MM/YYYY HH:mm:ss"
                                                              )
                                                            )
                                                        )
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "updateCropTimestamp: " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormat"
                                                              )(
                                                                new Date(
                                                                  detectedFaces.person.principalCrop.timestamp
                                                                ),
                                                                "DD/MM/YYYY HH:mm:ss"
                                                              )
                                                            )
                                                        )
                                                      ])
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("li", [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t   Qtt Captures: " +
                                                    _vm._s(
                                                      detectedFaces.captures
                                                        .length
                                                    ) +
                                                    "\n\t\t\t\t\t\t"
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                detectedFaces.captures,
                                                function(cp) {
                                                  return _c("li", [
                                                    cp.personAttributes
                                                      ? _c("span", [
                                                          _c("b", [
                                                            _vm._v(
                                                              "---Array de Capturas---"
                                                            )
                                                          ]),
                                                          _c("br"),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        timestamp: " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "dateFormat"
                                                                )(
                                                                  new Date(
                                                                    cp.timestamp
                                                                  ),
                                                                  "DD/MM/YYYY HH:mm:ss"
                                                                )
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        cropDebug: "
                                                          ),
                                                          cp.personAttributes
                                                            .cropDebug
                                                            ? _c("span", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        cp
                                                                          .personAttributes
                                                                          .cropDebug,
                                                                      target:
                                                                        "_blank"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "clique aqui"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  "sem imagem"
                                                                )
                                                              ]),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        snapDebug: "
                                                          ),
                                                          cp.personAttributes
                                                            .snapDebug
                                                            ? _c("span", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        cp
                                                                          .personAttributes
                                                                          .snapDebug,
                                                                      target:
                                                                        "_blank"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "clique aqui"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  "sem imagem"
                                                                )
                                                              ]),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .crop ||
                                                          cp.personAttributes
                                                            .cropESRate
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " cropESRate: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .crop
                                                                        ? cp
                                                                            .personAttributes
                                                                            .crop
                                                                            .cropESRate
                                                                        : cp
                                                                            .personAttributes
                                                                            .cropESRate
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                            confidence: " +
                                                              _vm._s(
                                                                cp
                                                                  .personAttributes
                                                                  .confidence
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .crop ||
                                                          cp.personAttributes
                                                            .cropQuality
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " cropQuality: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .crop
                                                                        ? cp
                                                                            .personAttributes
                                                                            .crop
                                                                            .cropQuality
                                                                        : cp
                                                                            .personAttributes
                                                                            .cropQuality
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .age
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " age: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .age
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .emotion
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " emotion: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .emotion
                                                                        .name
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .emotion
                                                                        .score
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .gender
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " gender: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .gender
                                                                        .name
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .gender
                                                                        .score
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .beard
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " beard: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .beard
                                                                        .name
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .beard
                                                                        .score
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .glasses
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " glasses: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .glasses
                                                                        .name
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .glasses
                                                                        .score
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .medmask
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " medmask: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .medmask
                                                                        .name
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .medmask
                                                                        .score
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .crop ||
                                                          cp.personAttributes
                                                            .cropWidth
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " cropWidth: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .crop
                                                                        ? cp
                                                                            .personAttributes
                                                                            .crop
                                                                            .cropWidth
                                                                        : cp
                                                                            .personAttributes
                                                                            .cropWidth
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " - \n                            "
                                                          ),
                                                          cp.personAttributes
                                                            .crop ||
                                                          cp.personAttributes
                                                            .cropHeight
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " cropHeight: " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .crop
                                                                        ? cp
                                                                            .personAttributes
                                                                            .crop
                                                                            .cropHeight
                                                                        : cp
                                                                            .personAttributes
                                                                            .cropHeight
                                                                    )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          cp.personAttributes
                                                            .headpose != null
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n    \t\t\t\t\t\t\t\theadpose: pitch " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .headpose
                                                                        .result
                                                                        .pitch
                                                                    ) +
                                                                    " roll " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .headpose
                                                                        .result
                                                                        .roll
                                                                    ) +
                                                                    " yaw " +
                                                                    _vm._s(
                                                                      cp
                                                                        .personAttributes
                                                                        .headpose
                                                                        .result
                                                                        .yaw
                                                                    )
                                                                ),
                                                                _c("br")
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\n                                                        \n\t\t\t\t\t\t\tuserConfidence: " +
                                                              _vm._s(
                                                                cp
                                                                  .personAttributes
                                                                  .userConfidence
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        \n                                                        \n                                                        cameraId: " +
                                                              _vm._s(
                                                                cp
                                                                  .captureAttributes
                                                                  .camId
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\tcameraName: " +
                                                              _vm._s(
                                                                cp
                                                                  .captureAttributes
                                                                  .camName
                                                              )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n\n\t\t\t\t\t\t\tstartProcess: "
                                                          ),
                                                          cp.personAttributes
                                                            .startProcess
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormat"
                                                                      )(
                                                                        new Date(
                                                                          cp
                                                                            .personAttributes
                                                                            .startProcess *
                                                                            1
                                                                        ),
                                                                        "DD/MM/YYYY HH:mm:ss"
                                                                      )
                                                                    ) +
                                                                    "." +
                                                                    _vm._s(
                                                                      cp.personAttributes.startProcess.substring(
                                                                        cp
                                                                          .personAttributes
                                                                          .startProcess
                                                                          .length -
                                                                          3
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        endDetect: "
                                                          ),
                                                          cp.personAttributes
                                                            .endDetect
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormat"
                                                                      )(
                                                                        new Date(
                                                                          cp
                                                                            .personAttributes
                                                                            .endDetect *
                                                                            1
                                                                        ),
                                                                        "DD/MM/YYYY HH:mm:ss"
                                                                      )
                                                                    ) +
                                                                    "." +
                                                                    _vm._s(
                                                                      cp.personAttributes.endDetect.substring(
                                                                        cp
                                                                          .personAttributes
                                                                          .endDetect
                                                                          .length -
                                                                          3
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        endProcess: "
                                                          ),
                                                          cp.personAttributes
                                                            .endProcess
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormat"
                                                                      )(
                                                                        new Date(
                                                                          cp
                                                                            .personAttributes
                                                                            .endProcess *
                                                                            1
                                                                        ),
                                                                        "DD/MM/YYYY HH:mm:ss"
                                                                      )
                                                                    ) +
                                                                    "." +
                                                                    _vm._s(
                                                                      cp.personAttributes.endProcess.substring(
                                                                        cp
                                                                          .personAttributes
                                                                          .endProcess
                                                                          .length -
                                                                          3
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _vm._v(
                                                            "\n                                                        detectionTime: " +
                                                              _vm._s(
                                                                cp
                                                                  .personAttributes
                                                                  .endDetect -
                                                                  cp
                                                                    .personAttributes
                                                                    .startProcess
                                                              ) +
                                                              " ms "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                                        processingTime: " +
                                                              _vm._s(
                                                                cp
                                                                  .personAttributes
                                                                  .endProcess -
                                                                  cp
                                                                    .personAttributes
                                                                    .startProcess
                                                              ) +
                                                              " ms                                                    \n\t\t\t\t\t\t     "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(11, true)
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.$route.query.hasOwnProperty("debug")
                            ? _c(
                                "div",
                                {
                                  class:
                                    "uk-card uk-card-default uk-card-bod ukid-width-" +
                                    _vm.filters.imageSize +
                                    "-px"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ukid-padding-5px",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "ukid-font-size-17 ukid-cursor-pointer",
                                          attrs: {
                                            width: _vm.filters.imageSize - 40
                                          }
                                        },
                                        [
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { align: "left" } },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.selectedForUnion,
                                                        expression:
                                                          "selectedForUnion"
                                                      }
                                                    ],
                                                    staticClass: "uk-checkbox",
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      value: detectedFaces.id19,
                                                      checked: Array.isArray(
                                                        _vm.selectedForUnion
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedForUnion,
                                                            detectedFaces.id19
                                                          ) > -1
                                                        : _vm.selectedForUnion
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        var $$a =
                                                            _vm.selectedForUnion,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v =
                                                              detectedFaces.id19,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedForUnion = $$a.concat(
                                                                [$$v]
                                                              ))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedForUnion = $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                          }
                                                        } else {
                                                          _vm.selectedForUnion = $$c
                                                        }
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  detectedFaces.id19 ===
                                                  "unknown_refusal"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ukid-circular-person-edit ukid-label-alert-color"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "ukid-circular-portrait",
                                                            attrs: {
                                                              src:
                                                                "data:image/png;base64," +
                                                                detectedFaces.cropBase64
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : detectedFaces.id19[0] !==
                                                      "4"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ukid-circular-person-edit ukid-label-green-color"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "ukid-circular-portrait",
                                                            attrs: {
                                                              src:
                                                                _vm.loggedUser &&
                                                                _vm.loggedUser
                                                                  .showRegistrationImageOnDetection ===
                                                                  true
                                                                  ? detectedFaces.snapshot
                                                                  : "data:image/png;base64," +
                                                                    detectedFaces.cropBase64
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : detectedFaces.id19[0] ===
                                                      "4"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ukid-circular-person-edit ukid-label-gray-color"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "ukid-circular-portrait",
                                                            attrs: {
                                                              src:
                                                                "data:image/png;base64," +
                                                                detectedFaces.cropBase64
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ])
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        {
                                          style:
                                            "font-size:" +
                                            _vm.filters.fontSize +
                                            "px !important",
                                          attrs: { width: "200" }
                                        },
                                        [
                                          _c("tbody", [
                                            _c("tr", [
                                              detectedFaces.id19 ===
                                              "unknown_refusal"
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "ukid-icon-yellow-color ukid-capitalize",
                                                      style: {
                                                        paddingLeft: "0px"
                                                      },
                                                      attrs: { align: "center" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              new Date(
                                                                detectedFaces.updateTimestamp
                                                              ),
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                          )
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            detectedFaces.id19.toLowerCase()
                                                          ) +
                                                          "\n                                                    " +
                                                          _vm._s(
                                                            detectedFaces.camName
                                                          ) +
                                                          "\n\n                                                "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "ukid-white-color ukid-capitalize",
                                                      attrs: { align: "center" }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            paddingLeft: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t        " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "dateFormat"
                                                                )(
                                                                  new Date(
                                                                    detectedFaces.updateTimestamp
                                                                  ),
                                                                  "DD/MM/YYYY HH:mm:ss"
                                                                )
                                                              ) +
                                                              "\n                                                     "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            paddingLeft: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                detectedFaces
                                                                  .person
                                                                  .id19Parent
                                                                  ? detectedFaces
                                                                      .person
                                                                      .id19Parent
                                                                  : ""
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            paddingLeft: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                detectedFaces
                                                                  .id19[0] ===
                                                                  "4" ||
                                                                  detectedFaces
                                                                    .id19[0] ===
                                                                    "9"
                                                                  ? "Desconhecido"
                                                                  : detectedFaces.person.name.toLowerCase()
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            paddingLeft: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                         " +
                                                              _vm._s(
                                                                detectedFaces.camName
                                                                  ? detectedFaces.camName
                                                                  : detectedFaces.camId
                                                              ) +
                                                              "  "
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            paddingLeft: "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        Qtt Captures: " +
                                                              _vm._s(
                                                                detectedFaces
                                                                  .captures
                                                                  .length
                                                              ) +
                                                              "\n                                                     "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                            ])
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ukid-padding-7px ukid-icon-blue-color ukid-margin-negative-top-10"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { style: { paddingLeft: "10px" } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "ukid-icon-blue-color ukid-magin-right-10 ukid-margin-top-3",
                                                  attrs: {
                                                    title: "Ampliar a imagem",
                                                    "uk-tooltip": "",
                                                    href:
                                                      "#modal-media-image-" +
                                                      detectedFaces.id,
                                                    "uk-toggle": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.detect(
                                                        detectedFaces
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "uk-margin-small-right uk-icon",
                                                    attrs: {
                                                      "uk-icon":
                                                        "icon:image;ratio:0.98"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.loggedUser
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "#modal-media-debug-" +
                                                          detectedFaces.id,
                                                        "uk-toggle": ""
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "ukid-icon-blue-color ukid-magin-left-15",
                                                        attrs: {
                                                          title:
                                                            "Verificar Debug",
                                                          "uk-tooltip": "",
                                                          "uk-icon":
                                                            "icon:hashtag;ratio:1.2"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { attrs: { align: "center" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pagination.last_page > 1,
                    expression: "pagination.last_page > 1"
                  }
                ],
                staticClass: "uk-text-uppercase ukid-font-size-20"
              },
              [_vm._v("Pagina Atual: "), _c("b", [_vm._v(_vm._s(_vm.page))])]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pagination.last_page > 1,
                    expression: "pagination.last_page > 1"
                  }
                ],
                staticClass:
                  "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10",
                attrs: { "uk-margin": "" }
              },
              [
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.pagination.last_page,
                    "click-handler": _vm.list,
                    "prev-text": "<",
                    "next-text": ">",
                    "container-class":
                      "ukid-pagination uk-flex-center page-link ukid-margin-bottom-10"
                  },
                  model: {
                    value: _vm.page,
                    callback: function($$v) {
                      _vm.page = $$v
                    },
                    expression: "page"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1 && _vm.enableAutoList,
                  expression: "pagination.last_page > 1 && enableAutoList"
                }
              ],
              staticClass:
                "ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20",
              attrs: {
                "uk-margin": "",
                "uk-tooltip": "",
                title:
                  "Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui",
                "uk-icon": "icon:play-circle;ratio:2.7"
              },
              on: {
                click: function($event) {
                  return _vm.enableInterval()
                }
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
      _vm._v(
        "\n                        Pesquisar Por Parâmetro\n                        "
      ),
      _c("br"),
      _vm._v("\n                        (id19)\n                        "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-font-size-9 ukid-icon-pink-color" }, [
        _vm._v("\n                            OBS:"),
        _c("br"),
        _vm._v(
          '\n                             - Habilita "registros com problemas"'
        ),
        _c("br"),
        _vm._v(
          ' \n                             - Desabilita "todas as detecções"'
        ),
        _c("br"),
        _vm._v('\n                             - Desabilita "somente blc"'),
        _c("br"),
        _vm._v(
          "\n                             - Isso vai proporcionar um resultado melhor\n                        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
      },
      [_vm._v("Faces Detectadas"), _c("br"), _vm._v("no Intervalo De:")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
      },
      [
        _vm._v("\n                        Todas as detecções"),
        _c("br"),
        _vm._v("menos Recusadas\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-margin-bottom-10 uk-visible@s"
      },
      [
        _vm._v(
          "\n                    Escolha os dispositivos a serem assistidos:"
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-icon-pink-color" }, [
          _vm._v("Exemplo: Camera em frente ao balcão principal")
        ]),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-margin-bottom-10 uk-hidden@s ukid-font-size-10"
      },
      [
        _vm._v("\n                    Escolha os dispositivos"),
        _c("br"),
        _vm._v("a serem assistidos:"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-icon-pink-color" }, [
          _vm._v("Exemplo: Camera em frente"),
          _c("br"),
          _vm._v("ao balcão principal")
        ]),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "ukid-font-size-11 ukid-icon-pink-color" }, [
        _vm._v(
          "\n                        OBS: Os dados de dispositivos são salvos no armazenamento do navegador"
        ),
        _c("br"),
        _vm._v(
          " \n                        e não na sessão do usuário, possibilitando o monitoramento em máquinas diferentes                                                \n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "uk-navbar-item uk-logo ukid-white-color ukid-font-size-13",
            attrs: { href: "../" }
          },
          [
            _c("img", {
              staticClass: "uk-margin-small-right",
              attrs: { src: "content/images/unike-logo-un.png", width: "120" }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner",
          attrs: { "uk-spinner": "ratio:4" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:search;ratio:5.0" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "uk-margin-small-right uk-icon",
          attrs: { "uk-icon": "icon:heart;ratio:0.8" }
        }),
        _vm._v(
          "\n                                            Matriz de Emoções\n                                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-default uk-modal-close",
          attrs: { type: "button" }
        },
        [_vm._v("Fechar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "uk-margin-small-right uk-icon",
          attrs: { "uk-icon": "icon:hashtag;ratio:0.8" }
        }),
        _vm._v(
          "\n                                            Debug\n                                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-default uk-modal-close",
          attrs: { type: "button" }
        },
        [_vm._v("Fechar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-076cbfd2", { render: render, staticRenderFns: staticRenderFns })
  }
}