<!--suppress ALL-->

<style src="../../../content/css/vue-multiselect.min.css"></style>

<style scoped lang="scss">
@import '../../../content/css/temperature.scss';

.uk-custom-textarea {
  min-height: 100px;
  min-width: 100%;
  max-height: 300px;
  resize: vertical;
}

.uk-relative-container {
  position: relative;
  height: 25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: end;
}

.uk-absolute-container {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 100%;
}

.uk-button-scale {
  transition: transform 300ms ease-in;
  display: block;
  margin-top: 5px;
  font-size: 1.3rem;
  letter-spacing: 2px;
}

.uk-button-scale:hover {
  transform: scaleY(1.1) scaleX(1.03);

}

.uk-button-delete {
  display: flex;
  border: 1px solid red;
  background-color: transparent;
  color: red;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

uk-button-disable {
  display: flex;
  width: 20rem;
  border: 1px solid gray;
  background-color: transparent;
  color: gray;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
</style>

<script type="text/javascript">
import App from '../../app.json'
import UIkit from 'uikit'
import { vm } from '../../js/main'
import { Datetime } from 'vue-datetime'
import Multiselect from 'vue-multiselect'
import ModalDeleteUser from './modal-delete-user.vue'
import Personcard from './personcard.vue'

export default {

  components: {
    Multiselect,
    datetime: Datetime,
    ModalDeleteUser,
    Personcard
},

  /**
   * All objects required for this page to function properly
   */
  data() {

    return {

      App: App,
      pagination: {
        last_page: 0
      },
      id19ListSearch: '',
      registers: {},
      devices: {},
      units: {
        Celcius: "°C",
        Fahrenheit: "°F"
      },
      config: {
        minTemp: 34,
        maxTemp: 45,
        unit: "Celcius"
      },
      filters: {
        searchParam: '',
        blcDetected: false,
        personsRefusals: false,
        personsNotDetected: true,
        personsNotDetectedWithoutRefusals: true,
        personsWithProblems: false,
        currentDate: true,
        startDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
        endDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
        imageSize: '220',
        fontSize: '16',
        selectedDevices: ['7'],
        imagesPerPage: App.pagination.proximity.itensPerPage,
      },
      selectedForUnion: [],
      enableAutoList: false,
      controlStartDate: 0,
      controlEndDate: 0,
      sub: '',
      loggedUser: null,
      page: 1,
      socket: null,
      stomp: null

    }

  },

  /**
   * This method executes when the vue object is created.
   */
  created() {

    let _ = this

    /**
     * Get logged user
     */
    _.getLoggedUser()

    /**
     * Get user logged
     */
    _.sub = vm.user.sub

  },

  methods: {

    /**
     * Responsible for re-registering the records 
     * and re-activating the polling interval.
     */
    search() {

      let _ = this

      vm.spinnerSearch()

      _.enableInterval()

    },

    /**
     * List all devices relevant to or logged user
     */
    getLoggedUser() {

      let _ = this

      _.$http.get(

        App.host.concat(App.loggedUserEndpoint)

      ).then(response => {

        _.loggedUser = response.body

      }).catch(function (err) {

        UIkit.modal.alert(err.body.description)

      })

    },

    /**
     * This method is responsible for listing the real time 
     * detections that are happening in CCTV cameras, this method 
     * is based on the end user predefined filters
     * 
     * This method fills in the attributes (registers and pagination) of the data object.
     * 
     * @param page Pagination based page number
     */
    list() {

      let _ = this

      console.log(_.id19ListSearch,'hello')

      const formData = new FormData();

      const cleanedId19ListSearch = _.id19ListSearch.trim();

formData.append('id19List', cleanedId19ListSearch);

      /**
       * Post filter to unike server
       */
      _.$http.post(
        App.host.concat(
          App.personv4listEndpoint
        ),
        formData
      ).then(response => {

        console.log(response.body)

        /**
         * Update binded objects
         */
        _.registers = response.body
        _.pagination = response.body
        console.log(_.registers[0].personV4Collection.work)
      }).catch(function (err) {

        console.error(err)

      })

    },
    handleSelectedForUnionUpdate(id19) {
      if (this.selectedForUnion.includes(id19)) {
        this.selectedForUnion = this.selectedForUnion.filter(id => id !== id19);
      } else {
        this.selectedForUnion.push(id19);
      }
    },
    setWork(isWork) {

      let _ = this

      _.selectedForUnion.forEach(item => {

        console.log(item)

        _.$http.post(App.host.concat(App.setWork).concat(isWork).concat('/').concat(item)).then(response => {

          console.log(response.body)

          _.list(_.page)

          _.clear(false)

        }).catch(function (err) {

          UIkit.modal.alert(err.body.description)

        })
      });

    },
    del(documentNumber) {

      let _ = this

      _.$http.delete(App.host.concat(App.deleteProximityEndpoint).concat(documentNumber)).then(response => {

        /**
         * Close all visual notifications of uikit
         */
        UIkit.notification.closeAll()

        /**
         * Show delete notification to user
         */
        UIkit.notification({
          message: App.notification.del,
          status: 'primary',
          pos: 'top-center',
          timeout: App.notification.timeout
        })

        if (_.pagination.current_page > 0 && _.page == _.pagination.last_page && _.pagination.data.length == 1) {

          _.list(_.page - 1)

        } else {

          _.list(_.page)

        }

        _.clear(false)

      }).catch(function (err) {

        UIkit.modal.alert(err.body.description)

      })

    },

    detect(object) {

      /*
       * This document 
       */
      let _ = this

      /*
       * This div's contains tha image and div bbox  
       * The first image is shown on list, second is shown when user click on zoom
       */
      let containerDefault = document.querySelector('#ukid-container-zoom-' + object.id)
      let imgDefault = document.querySelector('#ukid-image-face-zoom-' + object.id)

      if (imgDefault === null || !imgDefault.complete) {

        setTimeout(function () { _.detect(object) }, 10)

        return

      }

      setTimeout(function () {

        /*
        * Canvas to image list and image zoom
        */
        let canvasDefault = document.createElement('canvas')
        canvasDefault.width = containerDefault.offsetWidth
        canvasDefault.height = containerDefault.offsetHeight

        /*
        * Context to image list and image zoom
        */
        let ctxDefault = canvasDefault.getContext('2d')
        ctxDefault.drawImage(imgDefault, 0, 0, containerDefault.offsetWidth, containerDefault.offsetHeight)

        canvasDefault.toBlob(blob => {

          var formData = new FormData()
          formData.append('threshold', '0.65')
          formData.append('photo', blob)

          _.$http.post(App.host.concat('/client/pay/detect/multipart'), formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(function (response) {

            _.bbox(containerDefault, response.data.bbox)

          })

        })

      }, 350)

    },

    bbox(container, object) {

      let _ = this

      container.querySelectorAll('div').forEach(e => e.parentNode.removeChild(e))

      let rect = document.createElement('div')

      rect.classList.add('rect')

      rect.style.width = (object.right - object.left) + 'px'
      rect.style.height = (object.bottom - object.top) + 'px'
      rect.style.left = (container.offsetTop + object.left) + 'px'
      rect.style.top = (container.offsetTop + object.top) + 'px'

      rect.style.borderColor = _.colorBox()

      container.appendChild(rect)

    },
    clearSelectedProfiles() {
      this.selectedForUnion = []
    },
    colorBox() {

      let colors = ['#fdecce', '#F5EA87', '#5CF77E', '#3acff8', '#7C5EC6', '#ff33ff', '#b366ff', '#bb4462', '#b0b036', '#f49e0b']

      return colors[Math.floor((Math.random() * 10))]

    }

  }

}

</script>

<template id="proximity-template">
  <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>
    <!-- Top of the layout proximity -->
    <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
      <div class="uk-card-header ukid-supress-border-bottom">
        <div class="ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50">
          <a href="../" class="uk-navbar-item uk-logo ukid-white-color ukid-font-size-13">
            <img src="content/images/unike-logo-un.png" width="120" class="uk-margin-small-right">
          </a>
        </div>
        <div class="uk-grid-small uk-flex-middle ukid-min-height-60" uk-grid>
          <div class="uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50">
            <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
            <span class="ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6"
              uk-icon="icon:search;ratio:5.0"></span>
          </div>
          <div class="uk-width-expand">
            <span class="ukid-font-size-16 ukid-margin-top-40 uk-hidden@s">Lista de Pessoas</span>
            <br />
            <p>Valor selecionado: {{ selectedForUnion }}</p>

            <div class="uk-relative-container ">
              <textarea v-model="id19ListSearch"
                class="uk-textarea ukid-focus uk-form-width-1-2 uk-height-small uk-border-rounded uk-custom-textarea"></textarea>
              <button @click="list()"
                class="uk-button uk-flex uk-button-default uk-width-1-1   uk-button-large uk-border-rounded uk-button-scale">
                <span class="uk-margin-small-right" uk-icon="icon:search; ratio:1.3"></span>
                Filtrar
              </button>
              <div>

                <button :href="'#modal-delete-user'" uk-toggle :class="{
                  'uk-button-delete': selectedForUnion.length > 0,
                  'uk-button-disable': selectedForUnion && selectedForUnion.length === 0,
                  'uk-button-scale': true,
                  'uk-button-large': true,
                  'uk-border-rounded': true
                }" :disabled="selectedForUnion && selectedForUnion.length === 0"> <span class="uk-margin-small-right"
                    uk-icon="icon:trash;ratio:1.3"></span>
                  Deletar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-card-footer ukid-supress-border-top">
        <div class="uk-flex uk-flex-center ukid-padding-top-20">
          <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
            
            <div v-for="personv4 in registers" :key="personv4.personV4Collection.id19" class="ukid-offcanvas-over">
              <ModalDeleteUser :modalId="'modal-delete-user'" :clear="clearSelectedProfiles"
                :userList="selectedForUnion" :person="personv4" />
            <Personcard
              :id="'modal-media-image-' + personv4.personV4Collection.id19"
              :loggedUser="loggedUser"
              :id19="personv4.personV4Collection.id19"
              :age="personv4.personV4Collection.age"
              :gender="personv4.personV4Collection.gender"
              :principalCrop="personv4.personV4Collection.principalCrop"
              :collection="personv4.personV4Collection"
              :document="personv4.personDocumentV4Collection"
              :selectedForUnion="selectedForUnion"
              :filters="filters"
              :workList="personv4.personV4Collection.workList"
              :firstDetect="personv4.personV4Collection.firstDetect"
              :person="personv4"
              @update:selectedForUnion="handleSelectedForUnionUpdate"
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-flex uk-flex-center ">
      <div v-show="!registers.length"
        class="uk-alert-primary ukid-margin-top-30 ukid-label-blue-color uk-width-1-2 uk-border-rounded uk-height-small"
        uk-alert>
        <p class="ukid-white-color">
          <span class="uk-margin-small-right uk-icon" uk-icon="warning"></span>
          {{ App.proximity.nobody }}
        </p>
      </div>
    </div>
  </div>
</template>

