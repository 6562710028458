var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.emailTitleInvite,
            expression: "user.emailTitleInvite"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:10|max:100",
            expression: "'required|min:10|max:100'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "email-title-invite-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "email-title-invite-select_field"
          )
        },
        attrs: {
          "c-data-vv-as": "field",
          id: "email-title-invite-select_field",
          name: "email-title-invite-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 1000"
        },
        domProps: { value: _vm.user.emailTitleInvite },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "emailTitleInvite", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.emailTitleReInvite,
            expression: "user.emailTitleReInvite"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:10|max:100",
            expression: "'required|min:10|max:100'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "email-title-reinvite-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "email-title-reinvite-select_field"
          )
        },
        attrs: {
          "c-data-vv-as": "field",
          id: "email-title-reinvite-select_field",
          name: "email-title-reinvite-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 1000"
        },
        domProps: { value: _vm.user.emailTitleReInvite },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "emailTitleReInvite", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "uk-margin" },
      [
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _c("ckeditor", {
          attrs: { editor: _vm.editor, config: _vm.editorConfig },
          model: {
            value: _vm.user.emailMessageInvite,
            callback: function($$v) {
              _vm.$set(_vm.user, "emailMessageInvite", $$v)
            },
            expression: "user.emailMessageInvite"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "uk-margin" },
      [
        _vm._m(9),
        _vm._v(" "),
        _vm._m(10),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._m(11),
        _vm._v(" "),
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _c("ckeditor", {
          attrs: { editor: _vm.editor, config: _vm.editorConfig },
          model: {
            value: _vm.user.emailMessageReInvite,
            callback: function($$v) {
              _vm.$set(_vm.user, "emailMessageReInvite", $$v)
            },
            expression: "user.emailMessageReInvite"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Título do Email do Convite\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Título do Email do Convite\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do convidado"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome de quem está convidando"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invite_number}")]),
      _vm._v(" - Será substituído número do documento"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{link}")]),
      _vm._v(" - Será substituído pelo link de acesso ao convite"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invitation_type}")]),
      _vm._v(
        " - Será substituído pelo tipo de convite (permanente ou limitado)\n        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v(
          "\n            Título do Email de Renovação do Convite\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Título do Email"),
        _c("br"),
        _vm._v("de Renovação do Convite\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do convidado"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome de quem está convidando"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invite_number}")]),
      _vm._v(" - Será substituído número do documento"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{link}")]),
      _vm._v(" - Será substituído pelo link de acesso ao convite"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invitation_type}")]),
      _vm._v(
        " - Será substituído pelo tipo de convite (permanente ou limitado)\n        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Mensagem do Convite Enviado Por Email\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Mensagem do"),
        _c("br"),
        _vm._v("Convite Enviado"),
        _c("br"),
        _vm._v("Por Email\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do convidado"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome de quem está convidando"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invite_number}")]),
      _vm._v(" - Será substituído número do documento"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{link}")]),
      _vm._v(" - Será substituído pelo link de acesso ao convite"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invitation_type}")]),
      _vm._v(
        " - Será substituído pelo tipo de convite (permanente ou limitado)\n        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v(
          "\n            Mensagem de Renovação de Convite Enviado Por Email\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Mensagem de"),
        _c("br"),
        _vm._v("Renovação de Convite"),
        _c("br"),
        _vm._v("Por Email\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do convidado"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome de quem está convidando"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invite_number}")]),
      _vm._v(" - Será substituído número do documento"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{link}")]),
      _vm._v(" - Será substituído pelo link de acesso ao convite"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{invitation_type}")]),
      _vm._v(
        " - Será substituído pelo tipo de convite (permanente ou limitado)\n        "
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68d965af", { render: render, staticRenderFns: staticRenderFns })
  }
}