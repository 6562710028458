<script>

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'user', 'auth', 'role', 'vm'],
        
        data() {

            return {

            }

        },

        created() {
        
        },

        methods: {

        }
        
    }

</script>

<template id="register-invite-whatsapp-template">

    <!--  Invite Whatsapp -->
    <li>
        
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:whatsapp;ratio:1.2"></span>
                Mensagem do Convite Enviado Por Whatsapp
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:whatsapp;ratio:1.2"></span>
                Mensagem do<br />Convite Enviado<br />Por Whatsapp
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do convidado<br />
                <b>{inviting}</b> - Será substituído pelo nome de quem está convidando<br />
                <b>{invite_number}</b> - Será substituído número do documento<br />
                <b>{link}</b> - Será substituído pelo link de acesso ao convite<br />
                <b>{invitation_type}</b> - Será substituído pelo tipo de convite (permanente ou limitado)
            </span>
            <textarea 
                wrap="physical"
                :class="{
                    'ukid-form-error uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px': errors.has('whatsapp-invite_input_field'), 
                    'uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px': !errors.has('whatsapp-invite_input_field')
                }" 
                v-model="user.whatsappMessageInvite"
                v-validate="'required|min:10|max:2000'"
                c-data-vv-as="field"
                id="whatsapp-invite_input_field"
                name="whatsapp-invite_input_field"
                type="text"
                placeholder="Mínimo 10, máximo 2000"></textarea>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:whatsapp;ratio:1.2"></span>
                Mensagem de Renovação de Convite Enviado Por Whatsapp
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:whatsapp;ratio:1.2"></span>
                Mensagem de Renovação<br />de Convite Enviado<br />Por Whatsapp
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do convidado<br />
                <b>{inviting}</b> - Será substituído pelo nome de quem está convidando<br />
                <b>{invite_number}</b> - Será substituído número do documento<br />
                <b>{link}</b> - Será substituído pelo link de acesso ao convite<br />
                <b>{invitation_type}</b> - Será substituído pelo tipo de convite (permanente ou limitado)
            </span>
            <textarea 
                wrap="physical"
                :class="{
                    'ukid-form-error uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px': errors.has('whatsapp-reinvite_input_field'), 
                    'uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px': !errors.has('whatsapp-reinvite_input_field')
                }" 
                v-model="user.whatsappMessageReInvite"
                v-validate="'required|min:10|max:2000'"
                c-data-vv-as="field"
                id="whatsapp-reinvite_input_field"
                name="whatsapp-reinvite_input_field"
                type="text"
                placeholder="Mínimo 10, máximo 2000"></textarea>
        </div>

    </li>

</template>