<script type="text/javascript">

    import UIkit from 'uikit'

    import Auth from '../../js/auth.js'

    import App from '../../app.json'

    import { router, vm } from '../../js/main.js' 

    export default {

        data() {

            return {

                App: App,
                
                vm: vm,
                router: router,
                
                credentials: {                    
                    username: null,
                    password: null,
                    new_password: null
                },

                error: null

            }

        },

        created() {
            
            let _ = this

            document.querySelector('#app').style.display = 'block'
            document.querySelector('.ukid-footer').style.display = 'none'

        },

        mounted() {

            let _ = this

            if (!vm.user) {

                router.replace('/login')

            } else {

                _.credentials.username = _.vm.user.sub

            }

        },

        methods: {

            login() {
                
                let _ = this

                var credentials = {
                    username: _.credentials.username,
                    password: _.credentials.password,
                    new_password: _.credentials.new_password
                }

                if (!credentials.password) {

                    _.$refs.passwordInput.classList.add('ukid-login-form-danger')

                    return

                } else {

                    _.$refs.passwordInput.classList.remove('ukid-login-form-danger')

                }

                if (credentials.password == credentials.new_password) {

                    _.$refs.checkRulePassword.classList.add('uk-hidden')
                    _.$refs.checkPassword.classList.remove('uk-hidden')

                    _.$refs.passwordInput.classList.add('ukid-login-form-danger')
                    _.$refs.newPasswordInput.classList.add('ukid-login-form-danger')

                    return

                } else {

                    _.$refs.checkPassword.classList.add('uk-hidden')

                    _.$refs.passwordInput.classList.remove('ukid-login-form-danger')
                    _.$refs.newPasswordInput.classList.remove('ukid-login-form-danger')

                }

                if (credentials.new_password.length < vm.user.minPasswordLength) { 

                    _.$refs.checkPassword.classList.add('uk-hidden')
                    _.$refs.checkRulePassword.classList.remove('uk-hidden')

                    return

                } else {

                    _.$refs.checkRulePassword.classList.add('uk-hidden')
                
                }

                UIkit.notification( { message: App.notification.newPasswordSending } )

                if (_.$route.query.hasOwnProperty('redir') && _.$route.query.redir.length > 0) {

                    Auth.changePassword(_, credentials, '/' + _.$route.query.redir, _.showErrorNotification);

                    return

                }

                Auth.changePassword(_, credentials, '/login', _.showErrorNotification)

            },

            showErrorNotification(response) {
                
                UIkit.notification.closeAll()

                if (response.status == 401 || response.status == 403) {

                    UIkit.notification( { message: '<div uk-icon="icon:ban"></div> Password atual incorreto' } )

                } else if (response.status == 500) {

                    UIkit.notification( { 
                        message: `<div uk-icon="icon:ban"></div> ${response.body.description}`, 
                        status: null, 
                        pos: 'top-center', 
                        timeout: 9000
                    } )

                }
                
            }

        }

    }

</script>

<template id="new-password-template">

    <div v-if="vm.user" class="ukid-padding-top-30 ukid-min-height">

        <div class="uk-flex uk-flex-center">
            <div class="uk-alert-primary ukid-margin-bottom-20 ukid-width-30-percent ukid-padding-20px" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                AVISO
                <br />
                <p>
                    Sua credencial de acesso foi configurada para troca de password de {{vm.user.expirationDaysPassword}} em {{vm.user.expirationDaysPassword}} dias. 
                    O padrão de password foi configurado para conter no mínimo {{vm.user.minPasswordLength}} caracteres e não repetir as últimos {{vm.user.totalPasswordNonRepetition}} utilizados
                </p>
            </div>
        </div>

        <div class="uk-flex uk-flex-center">

            <form id="form" class="ukid-width-300-px" v-on:submit.prevent>
                <fieldset class="uk-fieldset">
                    
                    <div class="uk-margin">    
                        <span class="ukid-white-color ukid-font-size-14">Password Atual</span>
                        <div class="uk-inline ukid-width-100-percent ukid-margin-top-10">
                            <span class="uk-form-icon" uk-icon="icon:unlock"></span>
                            <input ref="passwordInput" v-model="credentials.password" class="uk-input ukid-width-300-px" type="password" placeholder="Senha Atual">
                        </div>
                    </div>

                    <div class="uk-margin">    
                        <span class="ukid-white-color ukid-font-size-14">Novo Password</span>
                        <div class="uk-inline ukid-width-100-percent ukid-margin-top-10">
                            <span class="uk-form-icon" uk-icon="icon:lock"></span>                            
                            <input ref="newPasswordInput" v-model="credentials.new_password" class="uk-input ukid-width-300-px" type="password" placeholder="Nova Senha">
                        </div>
                    </div>

                    <div v-if="error && (error.status == 401 || error.status == 403)" class="ukid-login-text-danger uk-text-center">
                        <p>Password atual incorreto</p>
                    </div>

                    <div ref="checkPassword" class="ukid-login-text-danger uk-text-center uk-hidden">
                        <p>O novo password deve ser diferente dos últimos {{vm.user.totalPasswordNonRepetition}} utilizados</p>
                    </div>

                    <div ref="checkRulePassword" class="ukid-login-text-danger uk-text-center uk-hidden">
                        <p>O novo password deve conter no mínimo {{vm.user.minPasswordLength}} caracteres</p>
                    </div>                    

                    <div class="ukid-padding-top-20">
                        <button v-on:click="login" class="uk-button uk-button-default uk-button-large ukid-width-300-px ukid-font-size-14">
                            <span class="ukid-white-color">Atualizar</span>
                        </button>
                    </div>
                </fieldset>                
            </form>          
        </div>

    </div>

</template>