var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _vm.auth.includes("admin") && _vm.role !== "signer"
      ? _c(
          "div",
          { staticClass: "uk-margin" },
          [
            _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
              _vm._v("Galeria")
            ]),
            _c("br"),
            _vm._v(" "),
            _c(
              "multiselect",
              {
                staticStyle: { color: "#444444 !important" },
                attrs: {
                  options:
                    _vm.galleries.length > 0
                      ? _vm.galleries.map(function(obj) {
                          return obj.name
                        })
                      : [],
                  searchable: true,
                  "close-on-select": true,
                  "show-labels": false,
                  placeholder: "Selecione a galeria"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c("span", { staticClass: "multiselect__tag" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.option) +
                                " -> selecionado\n                "
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4278043662
                ),
                model: {
                  value: _vm.user.gallery,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "gallery", $$v)
                  },
                  expression: "user.gallery"
                }
              },
              [
                _vm._v(" "),
                _c("template", { slot: "noResult" }, [
                  _c("span", [
                    _vm._v(
                      "\n                        Nenhuma galeria encontrada\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("template", { slot: "noOptions" }, [
                  _c("span", [
                    _vm._v(
                      "\n                    Comece a digitar\n                "
                    )
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.auth.includes("admin") && _vm.role !== "signer"
      ? _c(
          "div",
          { staticClass: "uk-margin" },
          [
            _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
              _vm._v("Galeria de Desconhecidos")
            ]),
            _c("br"),
            _vm._v(" "),
            _c(
              "multiselect",
              {
                staticStyle: { color: "#444444 !important" },
                attrs: {
                  options:
                    _vm.galleries.length > 0
                      ? _vm.galleries.map(function(obj) {
                          return obj.name
                        })
                      : [],
                  searchable: true,
                  "close-on-select": true,
                  "show-labels": false,
                  placeholder: "Selecione a galeria"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c("span", { staticClass: "multiselect__tag" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.option) +
                                " -> selecionado\n                "
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4278043662
                ),
                model: {
                  value: _vm.user.unknownGallery,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "unknownGallery", $$v)
                  },
                  expression: "user.unknownGallery"
                }
              },
              [
                _vm._v(" "),
                _c("template", { slot: "noResult" }, [
                  _c("span", [
                    _vm._v(
                      "\n                        Nenhuma galeria encontrada\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("template", { slot: "noOptions" }, [
                  _c("span", [
                    _vm._v(
                      "\n                    Comece a digitar\n                "
                    )
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.auth.includes("admin") && _vm.role !== "signer"
      ? _c(
          "div",
          { staticClass: "uk-margin" },
          [
            _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
              _vm._v("Galeria BLC")
            ]),
            _c("br"),
            _vm._v(" "),
            _c(
              "multiselect",
              {
                staticStyle: { color: "#444444 !important" },
                attrs: {
                  options:
                    _vm.galleries.length > 0
                      ? _vm.galleries.map(function(obj) {
                          return obj.name
                        })
                      : [],
                  searchable: true,
                  "close-on-select": true,
                  "show-labels": false,
                  placeholder: "Selecione a galeria"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c("span", { staticClass: "multiselect__tag" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.option) +
                                " -> selecionado\n                "
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4278043662
                ),
                model: {
                  value: _vm.user.blcGallery,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "blcGallery", $$v)
                  },
                  expression: "user.blcGallery"
                }
              },
              [
                _vm._v(" "),
                _c("template", { slot: "noResult" }, [
                  _c("span", [
                    _vm._v(
                      "\n                        Nenhuma galeria encontrada\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("template", { slot: "noOptions" }, [
                  _c("span", [
                    _vm._v(
                      "\n                    Comece a digitar\n                "
                    )
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
        _vm._v("Nome")
      ]),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("span", {
          staticClass: "uk-form-icon",
          attrs: { "uk-icon": "icon:warning" }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.name,
              expression: "user.name"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:5|max:40",
              expression: "'required|min:5|max:40'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
              "user-name-input_field"
            ),
            "uk-input ukid-width-100-percent": !_vm.errors.has(
              "user-name-input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "user-name-input_field",
            name: "user-name-input_field",
            type: "text",
            placeholder: "Máximo 40 caracteres"
          },
          domProps: { value: _vm.user.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.user, "name", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _vm.auth.includes("admin") || _vm.auth.includes("user")
      ? _c("div", { staticClass: "uk-margin" }, [
          _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
            _vm._v("Username")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
            _c("span", {
              staticClass: "uk-form-icon",
              attrs: { "uk-icon": "icon:user" }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.username,
                  expression: "user.username"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:3|max:35",
                  expression: "'required|min:3|max:35'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "username-input_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "username-input_field"
                )
              },
              attrs: {
                "data-vv-as": "field",
                id: "username-input_field",
                name: "username-input_field",
                type: "text",
                placeholder: "Mínimo 3, máximo 35 caracteres"
              },
              domProps: { value: _vm.user.username },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "username", $event.target.value)
                }
              }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
        _vm._v("Password")
      ]),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("span", {
          staticClass: "uk-form-icon",
          attrs: { "uk-icon": "icon:unlock" }
        }),
        _vm._v(" "),
        _vm.user.id
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "min:4|max:30",
                  expression: "'min:4|max:30'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "password-input_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "password-input_field"
                )
              },
              attrs: {
                "data-vv-as": "field",
                id: "password-input_field",
                name: "password-input_field",
                type: "password",
                placeholder:
                  "Se não preenchido manterá a senha atual, máximo 30 caracteres"
              },
              domProps: { value: _vm.user.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.user.id
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:4|max:30",
                  expression: "'required|min:4|max:30'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "password-input_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "password-input_field"
                )
              },
              attrs: {
                "data-vv-as": "field",
                id: "password-input_field",
                name: "password-input_field",
                type: "password",
                placeholder:
                  "Se não preenchido manterá a senha atual, máximo 30 caracteres"
              },
              domProps: { value: _vm.user.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "password", $event.target.value)
                }
              }
            })
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _vm.vm.user.auth.includes("admin") ||
    _vm.strings.isNullOrEmpty(_vm.vm.user.owner) ||
      _vm.vm.user.sub === _vm.vm.user.owner
      ? _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-margin",
              scopedSlots: _vm._u(
                [
                  {
                    key: "tag",
                    fn: function(ref) {
                      var option = ref.option
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "multiselect__tag uk-text-center ukid-width-270-px"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(option) +
                                  "\n                        "
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2345987975
              )
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.emailsCredentialWarning,
                  multiple: true,
                  taggable: true,
                  selectLabel: "Enter para marcar",
                  deselectLabel: "Enter para desmarcar",
                  selectedLabel: "Email marcada",
                  "tag-placeholder": "Adicionar email",
                  placeholder: "Adicionar email"
                },
                on: { tag: _vm.addCredentialEmail },
                model: {
                  value: _vm.user.selectedCredentialWarningEmails,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "selectedCredentialWarningEmails", $$v)
                  },
                  expression: "user.selectedCredentialWarningEmails"
                }
              }),
              _vm._v(" "),
              _c("template", { slot: "noResult" }, [
                _c("span", [
                  _vm._v(
                    "\n                        Nenhuma email encontrado\n                    "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("template", { slot: "noOptions" }, [
                _c("span", [
                  _vm._v(
                    "\n                        Comece a digitar\n                    "
                  )
                ])
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(3),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "div",
                {
                  class: {
                    "ukid-width-100-percent": _vm.errors.has(
                      "enabled-input_field"
                    ),
                    "uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                      "enabled-input_field"
                    )
                  }
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.enabledEncryptionAPI,
                          expression: "user.enabledEncryptionAPI"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "true",
                        type: "radio",
                        id: "enabled-encryption-api-type-input_yes_field",
                        name: "enabled-encryption-api-type-input_field"
                      },
                      domProps: {
                        checked: _vm._q(_vm.user.enabledEncryptionAPI, "true")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "enabledEncryptionAPI",
                            "true"
                          )
                        }
                      }
                    }),
                    _vm._v(" SIM\n                    ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.enabledEncryptionAPI,
                          expression: "user.enabledEncryptionAPI"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "false",
                        type: "radio",
                        id: "enabled-encryption-api-type-input_no_field",
                        name: "enabled-encryption-api-type-input_field",
                        checked: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.user.enabledEncryptionAPI, "false")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "enabledEncryptionAPI",
                            "false"
                          )
                        }
                      }
                    }),
                    _vm._v(" NÃO\n                    ")
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(5),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n                Habilitar Bloqueio de Credenciais?\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "div",
                {
                  class: {
                    "ukid-width-100-percent": _vm.errors.has(
                      "enabled-input_field"
                    ),
                    "uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                      "enabled-input_field"
                    )
                  }
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.enabledCredentialLock,
                          expression: "user.enabledCredentialLock"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "true",
                        type: "radio",
                        id: "enabled-credential-lock-type-input_yes_field",
                        name: "enabled-credential-lock-type-input"
                      },
                      domProps: {
                        checked: _vm._q(_vm.user.enabledCredentialLock, "true")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "enabledCredentialLock",
                            "true"
                          )
                        }
                      }
                    }),
                    _vm._v(" SIM\n                    ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.enabledCredentialLock,
                          expression: "user.enabledCredentialLock"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "false",
                        type: "radio",
                        id: "enabled-credential-lock-type-input_no_field",
                        name: "enabled-credential-lock-type-input",
                        checked: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.user.enabledCredentialLock, "false")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "enabledCredentialLock",
                            "false"
                          )
                        }
                      }
                    }),
                    _vm._v(" NÃO\n                    ")
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(6),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n                Habilitar Expiração de Password\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "div",
                {
                  class: {
                    "ukid-width-100-percent": _vm.errors.has(
                      "enabled-input_field"
                    ),
                    "uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                      "enabled-input_field"
                    )
                  }
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.enabledPasswordExpiration,
                          expression: "user.enabledPasswordExpiration"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "true",
                        type: "radio",
                        id: "enabled-password-expiration-type-input_yes_field",
                        name: "enabled-password-expiration-type-input_field"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.user.enabledPasswordExpiration,
                          "true"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "enabledPasswordExpiration",
                            "true"
                          )
                        }
                      }
                    }),
                    _vm._v(" SIM\n                    ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.enabledPasswordExpiration,
                          expression: "user.enabledPasswordExpiration"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "false",
                        type: "radio",
                        id: "enabled-password-expiration-type-input_no_field",
                        name: "enabled-password-expiration-type-input_field",
                        checked: ""
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.user.enabledPasswordExpiration,
                          "false"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.user,
                            "enabledPasswordExpiration",
                            "false"
                          )
                        }
                      }
                    }),
                    _vm._v(" NÃO\n                    ")
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
              [_vm._v("\n                Credencial Bloqueada?\n            ")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [_vm._v("\n                Credencial Bloqueada?\n            ")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "div",
                {
                  class: {
                    "ukid-width-100-percent": _vm.errors.has(
                      "enabled-input_field"
                    ),
                    "uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                      "enabled-input_field"
                    )
                  }
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.blockedAccess,
                          expression: "user.blockedAccess"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "true",
                        type: "radio",
                        id: "blocked-access-type-input_yes_field",
                        name: "blocked-access-type-input_field"
                      },
                      domProps: {
                        checked: _vm._q(_vm.user.blockedAccess, "true")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.user, "blockedAccess", "true")
                        }
                      }
                    }),
                    _vm._v(" SIM\n                    ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.blockedAccess,
                          expression: "user.blockedAccess"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "false",
                        type: "radio",
                        id: "blocked-access-type-input_no_field",
                        name: "blocked-access-type-input_field",
                        checked: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.user.blockedAccess, "false")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.user, "blockedAccess", "false")
                        }
                      }
                    }),
                    _vm._v(" NÃO\n                    ")
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm.user.lastPasswordTimestamp
            ? _c("div", { staticClass: "uk-margin" }, [
                _vm._m(7),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
                  },
                  [
                    _vm._v(
                      "\n                Data da Última Troca de Senha\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-inline ukid-text-alert-color ukid-width-100-percent"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            new Date(_vm.user.lastPasswordTimestamp),
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        ) +
                        "\n            "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(8),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n                Tamanho Mínimo do Password\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.minPasswordLength,
                    expression: "user.minPasswordLength"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:2|numeric",
                    expression: "'required|max:2|numeric'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                    "minPasswordLength-input_field"
                  ),
                  "uk-input ukid-width-100-percent": !_vm.errors.has(
                    "minPasswordLength-input_field"
                  )
                },
                attrs: {
                  "data-vv-as": "field",
                  id: "minPasswordLength-input_field",
                  name: "minPasswordLength-input_field",
                  type: "text",
                  placeholder:
                    "Número mínimo de caracteres que o password deve conter"
                },
                domProps: { value: _vm.user.minPasswordLength },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "minPasswordLength", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(9),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n                Dias para Expiração do Password\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.expirationDaysPassword,
                    expression: "user.expirationDaysPassword"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:3|numeric",
                    expression: "'required|max:3|numeric'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                    "expirationDaysPassword-input_field"
                  ),
                  "uk-input ukid-width-100-percent": !_vm.errors.has(
                    "expirationDaysPassword-input_field"
                  )
                },
                attrs: {
                  "data-vv-as": "field",
                  id: "expirationDaysPassword-input_field",
                  name: "expirationDaysPassword-input_field",
                  type: "text",
                  placeholder: "Dias para expiração do password"
                },
                domProps: { value: _vm.user.expirationDaysPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.user,
                      "expirationDaysPassword",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(10),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n                Password Não Pode Repetir os Últimos\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.totalPasswordNonRepetition,
                    expression: "user.totalPasswordNonRepetition"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:2|numeric",
                    expression: "'required|max:2|numeric'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                    "totalPasswordNonRepetition-input_field"
                  ),
                  "uk-input ukid-width-100-percent": !_vm.errors.has(
                    "totalPasswordNonRepetition-input_field"
                  )
                },
                attrs: {
                  "data-vv-as": "field",
                  id: "totalPasswordNonRepetition-input_field",
                  name: "totalPasswordNonRepetition-input_field",
                  type: "text",
                  placeholder: "Valor de não repetição"
                },
                domProps: { value: _vm.user.totalPasswordNonRepetition },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.user,
                      "totalPasswordNonRepetition",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _vm._m(11),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.totalPasswordFailures,
                    expression: "user.totalPasswordFailures"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:2|numeric",
                    expression: "'required|max:2|numeric'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                    "totalPasswordFailures-input_field"
                  ),
                  "uk-input ukid-width-100-percent": !_vm.errors.has(
                    "totalPasswordFailures-input_field"
                  )
                },
                attrs: {
                  "data-vv-as": "field",
                  id: "totalPasswordFailures-input_field",
                  name: "totalPasswordFailures-input_field",
                  type: "text",
                  placeholder: "Total de chances"
                },
                domProps: { value: _vm.user.totalPasswordFailures },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.user,
                      "totalPasswordFailures",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-margin" }, [
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("article", { staticClass: "uk-comment" }, [
          _c("header", { staticClass: "uk-comment-header" }, [
            _c(
              "div",
              {
                staticClass: "uk-grid-medium uk-flex-middle",
                attrs: { "uk-grid": "" }
              },
              [
                _c("div", { staticClass: "uk-width-expand" }, [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "uk-comment-title uk-margin-remove ukid-white-color"
                    },
                    [
                      _c(
                        "a",
                        { staticClass: "uk-link-reset", attrs: { href: "#" } },
                        [_vm._v("OPÇÕES DE SEGURANÇA")]
                      )
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-comment-body" }, [
            _c("p", [
              _vm._v(
                "\n                            Os campos abaixo podem ser utilizados para aumentar a segurança da credencial que está sendo criada. \n                            "
              ),
              _c("br"),
              _vm._v(
                "\n                            Se habilitar a criptografia na API, lembre-se que você terá que implementar do lado de sua aplicação, o decript para os dados sensiveis.\n                            "
              ),
              _c("br"),
              _vm._v(
                "\n                            Caso opte pela opção acima entre em contato com "
              ),
              _c("a", { attrs: { href: "mailto:suporte@unike.tech" } }, [
                _vm._v("suporte@unike.tech")
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
      },
      [
        _vm._v("\n                Enviar Avisos"),
        _c("br"),
        _vm._v("de Eventos da"),
        _c("br"),
        _vm._v("Credencial Para:\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
      },
      [
        _vm._v(
          "\n                Enviar Avisos de Eventos da Credencial Para:\n                "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-text-alert-color" }, [
          _vm._v(
            "\n                    Ex: Credenciais próximas do vencimento ou avisos de bloqueio\n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Habilitar"),
        _c("br"),
        _vm._v("Criptografia na API\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
      },
      [
        _vm._v(
          "\n                Habilitar Criptografia na API\n                "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-text-alert-color" }, [
          _vm._v(
            "\n                    Os dados considerados sensiveis, serão criptografados no retorno da API\n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Habilitar Bloqueio"),
        _c("br"),
        _vm._v("de Credenciais?\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Habilitar Expiração"),
        _c("br"),
        _vm._v("de Password\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Data da Última"),
        _c("br"),
        _vm._v("Troca de Senha\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Tamanho Mínimo"),
        _c("br"),
        _vm._v("do Password\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Dias para Expiração"),
        _c("br"),
        _vm._v("do Password\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                Password Não Pode"),
        _c("br"),
        _vm._v("Repetir os Últimos\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
      _vm._v("\n                Chances para Autenticação\n                "),
      _c("br"),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "uk-label ukid-font-size-9 ukid-text-alert-color" },
        [
          _vm._v(
            "\n                    Após as tentativas a credencial será bloqueada\n                "
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38155324", { render: render, staticRenderFns: staticRenderFns })
  }
}