<!--suppress JSUnresolvedVariable, JSUnresolvedFunction, SpellCheckingInspection, HtmlUnknownAttribute, HtmlDeprecatedAttribute, HtmlUnknownTag-->

<style src="../../../content/css/vue-multiselect.min.css"></style>

<style type="text/css"></style>

<script type="text/javascript">

    import UIkit from 'uikit'

    import App from '../../app.json'

    import { vm } from '../../js/main.js'

    import { Commons, Strings } from '../../js/common.js'

    import CameraCommonFields from "./camera-common-fields"
    import CameraCpuFields from "./camera-cpu-fields.vue"
    import CameraGpuFields from "./camera-gpu-fields.vue"
    import CameraBodyGpuFields from "./camera-body-gpu-fields.vue";

    export default {

        components: {
            CameraBodyGpuFields,
            CameraCommonFields,
            CameraGpuFields,
            CameraCpuFields
        },

        data() {

            return {

                App: App,
                searchParam: '',
                pagination: {
                    last_page: 0
                },                
                registers: {},
                persons: [],
                isPersonLoading: false,
                body: {
                    stream_settings: {
                        detectors: {
                            face: null,
                            body: {
                                filter_max_size: 8192,
                                filter_min_quality: 0.45,
                                filter_min_size: 1,
                                fullframe_crop_rot: false,
                                fullframe_use_png: false,
                                jpeg_quality: 95,
                                overall_only: false,
                                post_best_track_frame: true,
                                post_best_track_normalize: true,
                                post_first_track_frame: false,
                                post_last_track_frame: false,
                                realtime_post_every_interval: false,
                                realtime_post_first_immediately: false,
                                realtime_post_interval: 1,
                                roi: '',
                                track_interpolate_bboxes: true,
                                track_max_duration_frames: 0,
                                track_miss_interval: 1,
                                track_overlap_threshold: 0.25,
                                track_send_history: false,
                                tracker_type: "simple_iou",
                                track_deep_sort_matching_threshold: 0.65,
                                track_deep_sort_filter_unconfirmed_tracks: true
                            },
                            car: null
                        },
                        router_headers: [],
                        router_body: [
                            "detector=BODIES"
                        ],
                        disable_drops: false,
                        ffmpeg_format: '',
                        ffmpeg_params: [],
                        imotion_threshold: 0,
                        play_speed: -1,
                        rot: '',
                        router_timeout_ms: 15000,
                        router_verify_ssl: false,
                        start_stream_timestamp: 0,
                        stream_data_filter: '',
                        use_stream_timestamp: false,
                        video_transform: '',
                        enable_recorder: false
                    }
                },
                camera: {
                    id: null,                    
                    enabled: true,
                    headPoseEnabled: false,
                    pitch: '',
                    roll: '',
                    yaw: '',
                    general_filter_min_quality: 0.50,
                    general_filter_min_face_size: 70,
                    type: 'terminal',
                    arch: 'gpu',
                    terminal_external_integration: [
                        "external_url = https://notify.unike.tech/whatsapp/image | 294b4ac62941deb73d6df28e14c9e2e5e2b00884ad7fffd0ea0dc469189ec63556af21c00aee361b7f89ad1aab4f92e07cebec9675129cd2ce2716817995c181",
                        "alert_always = false",
                        "alert_marked_person = false",
                        "alert_unknown = false",
                        "alert_known = false",
                        "alert_blc = false",
                        "image_type = url",
                        "latitude = -00.0000",
                        "longitude = -00.0000",
                        "alert_attention_temperature = false",
                        "alert_high_temperature = false",
                        "template = default_person_arrived_in_the_camera",
                        "parameters = {person_name} | {camera}",
                        "phones = +5511XXXXXXXXX | +5511YYYYYYYYY"
                    ],
                    group: 'default',
                    serialNumber: null,
                    terminalUrl: `${App.host}/`,
                    terminalPassword: '123456',
                    afd_header: null,
                    groupList: [],
                    selectedPersons: [],
                    place: '',
                    place_type: '',
                    camera_type: '',
                    user: vm.user.sub,
                    stream_url: '',
                    router_url: '',
                    labels: '',
                    single_pass: false,
                    stream_settings: {
                        ffmpeg_params: [],
                        md_threshold: 0.002,
                        md_scale: 0.3,
                        fd_frame_height: -1,
                        uc_max_time_diff: 30,
                        uc_max_dup: 3,
                        uc_max_avg_shift: 10,
                        det_period: 8,
                        realtime: false,
                        npersons: 4,
                        disable_drops: false,
                        tracker_threads: 4,
                        parse_sei: false,
                        image_arg: "photo",
                        additional_headers: [],
                        additional_body: [
                            "mf_selector=",
                            "detector=DEFAULT_CFTV",
                            "gallery=" + vm.user.username.toUpperCase(),
                            "gallery_unknown=" + `${vm.user.username.toUpperCase()}_UNKNOWN`,
                            "gallery_blc=" + `${vm.user.username.toUpperCase()}_BLC`
                        ],
                        api_timeout: 15000,
                        api_ssl_verify: false,
                        post_uniq: true,
                        min_score: 0.30,
                        min_d_score: -0.05,
                        realtime_dly: 0,
                        realtime_post_perm: false,
                        rot: '',
                        roi: '',
                        draw_track: false,
                        send_track: 0,
                        min_face_size: 0,
                        max_face_size: 0,
                        skip_count: 0,
                        overall: true,
                        only_norm: false,
                        max_candidates: 0,
                        jpeg_quality: 95,
                        ffmpeg_format: ''
                    },
                    stream_settings_gpu: {
                        play_speed: -1,
                        filter_min_quality: 0.30,
                        filter_min_face_size: 80,
                        filter_max_face_size: 8192,
                        normalized_only: false,
                        jpeg_quality: 95,
                        overall_only: true,
                        use_stream_timestamp: false,
                        ffmpeg_params: [],
                        router_timeout_ms: 15000,
                        router_verify_ssl: false,
                        router_headers: [],
                        router_body: [
                            "mf_selector=",
                            "detector=DEFAULT_CFTV",
                            "gallery=" + vm.user.sub.toUpperCase(),
                            "gallery_unknown=" + `${vm.user.username.toUpperCase()}_UNKNOWN`,
                            "gallery_blc=" + `${vm.user.username.toUpperCase()}_BLC`
                        ],
                        start_stream_timestamp: 0,
                        imotion_threshold: 0,
                        skip_count: 0,
                        rot: '',
                        roi: '',
                        realtime_post_interval: 0,
                        realtime_post_every_interval: false,
                        ffmpeg_format: ''
                    },
                    status: null,
                    status_msg: null,
                    statistic: {
                        processed_duration: null,
                        faces_posted: null,
                        faces_failed: null,
                        faces_not_posted: null,
                        processing_fps: null,
                        frames_dropped: null,
                        frames_processed: null,
                        frames_imotion_skipped: null,
                        decoding_soft_errors: null,
                        job_starts: null,
                        faces_checked: 0,
                        faces_not_checked: 0
                    },
                    restream_url: null,
                    worker_id: null,
                    version: null
                },
                labels_: '"default":"true"',
                sub: '',
                users: null,
                groups: null,
                page: 1,
                vm: vm,
                Commons: Commons,
                Strings: Strings

            }

        },

        created() {

            let _ = this

            if (vm.user.auth.includes('admin') || vm.user.auth.includes('user')) {

                _.fillUsers()

            }

            _.fillGroups()
            _.list()

            _.enableInterval()

        },

        methods: {

            search() {

                let vue = this

                vm.spinnerSearch()

                setTimeout(function(){

                    vue.list(undefined, 1, App.pagination.itensPerPage)

                    vm.spinnerSearch()

                }, App.layout.form.interval)

            },

            list(page) {

                if (page !== undefined) {

                    this.page = page

                }

                this.$http.get(

                    App.host.concat(App.listCameraEndpoint)
                    + '?pageNum=' + (page === undefined ? 1 : page)
                    + '&perPage=' + App.pagination.itensPerPage
                    + '&param=' + this.searchParam

                ).then(response => {

                    this.registers = response.body.data
                    this.pagination = response.body

                }).catch( function(err){

                    UIkit.modal.alert(App.messages.errorTitle.concat(App.messages.listError))

                })

            },

            fillUsers() {

                this.$http.get(

                    App.host.concat(App.listAllUserEndpoint)

                ).then(response => {

                    this.users = response.body

                })

            },

            fillGroups() {

                let _ = this

                _.$http.get(

                    App.host.concat(App.listAllGroupEndpoint)

                ).then(response => {

                    _.groups = response.body

                })

            },

            fill(object) {

                let _ = this

                _.errors.clear()

                vm.spinnerWithProgress(App.layout.progress.increment, App.layout.progress.interval)

                _.camera = object

                if (!Strings.isNullOrEmpty(_.camera.labels)) {

                    _.labels_ = JSON.stringify(_.camera.labels)
                        .replace('{', '')
                        .replace('}', '')

                }

                if (_.camera.type === 'terminal') {

                    _.camera.labels = JSON.parse('{ "terminal": "true" }')

                }

                if (_.camera.type === 'count_people') {

                    _.camera.labels = JSON.parse('{ "count_people": "true" }')

                }

                // Refresh user resolution
                vm.configDeviceResolution()

                // Update component group
                _.$refs.cameraCommonFields.$refs.usersCameraCpnt.refresh()
                _.$refs.cameraCommonFields.$refs.cameraGroupCpnt.refresh()

                document.querySelector('.ukid-focus').focus()

            },

            del(object) {

                let _ = this

                this.$http.delete(App.host.concat(App.deleteCameraEndpoint).concat(object.id)).then(response => {

                    UIkit.notification.closeAll()
                    UIkit.notification({ message: App.notification.del, status: 'primary', pos: 'top-center', timeout: App.notification.timeout });

                    if (_.pagination.current_page > 0 && _.page === _.pagination.last_page && _.pagination.data.length === 1) {

                        _.list(_.page - 1)

                    } else {

                        _.list(_.page)

                    }

                    if (!Commons.isMobile())
                        _.clear(false)

                }).catch(function(err) {

                    UIkit.modal.alert(err.body.description)

                })

            },

            save() {

                let _ = this;

                _.$validator.validateAll().then((result) => {

                    try {

                        UIkit.notification('<span uk-icon="icon: clock;ratio:1.2"></span> <span class="ukid-font-size-15">Validando...</span><br /><br />', { timeout: 5000 })

                        if (!Strings.isNullOrEmpty(_.labels_)) {

                            _.camera.labels = JSON.parse('{' + _.labels_ + '}')

                            if (_.camera.type === 'cftv') {

                                document.querySelector('#labels_input_field').focus()

                            }

                        }

                    } catch (err) {

                        UIkit.modal.alert('Você optou por utilizar o campo Labels. É necessário que o mesmo seja preechido com uma string válida conforme a instrução na label do campo')

                        return

                    }

                    if (_.camera.type !== 'terminal') {

                        _.errors.items.forEach(function(object) {

                            if (object.field === 'serial-number-input_field' || object.field === 'terminal-url-input_field' || object.field === 'password-input_field') {

                                _.errors.remove(object.field)

                            }

                        })

                    }

                    if (_.camera.headPoseEnabled === 'true') {

                        let error = 'Você optou por utilizar o headpose. É necessário que os campos pitch, roll e yaw estejam devidamente preenchidos'

                        try {

                            if (
                                !Strings.isNumber(_.camera.pitch.split(',')[0])
                                || !Strings.isNumber(_.camera.pitch.split(',')[1])
                                || !Strings.isNumber(_.camera.roll.split(',')[0])
                                || !Strings.isNumber(_.camera.roll.split(',')[1])
                                || !Strings.isNumber(_.camera.yaw.split(',')[0])
                                || !Strings.isNumber(_.camera.yaw.split(',')[1])
                            ) {

                                UIkit.modal.alert(error)

                                return

                            }

                        } catch (err) {

                            console.info(err)

                            UIkit.modal.alert(error)

                            return

                        }

                    }

                    console.debug(`ukid -> errors found ${JSON.stringify(_.errors)}`)

                    if (!result && _.errors.items.length) {

                        UIkit.notification.closeAll()
                        UIkit.notification('<span uk-icon="icon: warning;ratio:1.2"></span> <span class="ukid-font-size-15">Erros encontrados...</span><br /><br />', { timeout: 500 })

                        document.querySelector('#' + _.errors.items[0].field).focus()

                        return

                    }

                    if (_.camera.type === 'terminal') {

                        _.camera.labels = JSON.parse('{ "terminal": "true" }')

                    }

                    if (_.camera.type === 'count_people') {

                        _.camera.stream_settings_gpu = null
                        _.camera.stream_settings = _.body.stream_settings
                        _.camera.labels = JSON.parse('{ "count_people": "true" }')

                    }

                    if (_.vm.user.auth.includes('signer')) {

                        _.camera.user = _.vm.user.owner

                    }

                    /*
                     * Clear statistics data
                     */
                    _.camera.status = null
                    _.camera.status_msg = null
                    _.camera.statistic = null
                    _.camera.restream_url = null
                    _.camera.worker_id = null
                    _.camera.version = null

                    this.$http.post(App.host.concat(App.saveCameraEndpoint), _.camera).then(response => {

                        UIkit.notification.closeAll()
                        UIkit.notification({ message: App.notification.save, status: 'primary', pos: 'top-center', timeout: App.notification.timeout});

                        _.list(_.page)

                    }).catch( function(err){

                        UIkit.modal.alert(App.messages.errorTitle.concat(err.body.description))

                    })

                }).catch((err) => {

                    console.error(err)

                })

            },

            clear(focus) {

                let _ = this

                _.camera = {}

                // COMMON
                _.camera.id = null
                _.camera.type = 'terminal'
                _.camera.user = _.vm.user.sub
                _.cameraheadPoseEnabled = false
                _.camerapitch = ''
                _.cameraroll = ''
                _.camerayaw = ''
                _.camera.general_filter_min_quality = 0.50
                _.camera.serialNumber = null
                _.camera.terminalUrl = `${App.host}/`
                _.camera.terminalPassword = null
                _.camera.afd_header = null
                _.camera.groupList = []
                _.camera.selectedPersons = []
                _.camera.terminal_external_integration = [
                    "external_url = https://notify.unike.tech/whatsapp/image | 294b4ac62941deb73d6df28e14c9e2e5e2b00884ad7fffd0ea0dc469189ec63556af21c00aee361b7f89ad1aab4f92e07cebec9675129cd2ce2716817995c181",
                    "alert_always = false",
                    "alert_marked_person = false",
                    "alert_unknown = false",
                    "alert_known = false",
                    "alert_blc = false",
                    "image_type = url",
                    "latitude = -00.0000",
                    "longitude = -00.0000",
                    "alert_attention_temperature = false",
                    "alert_high_temperature = false",
                    "template = default_person_arrived_in_the_camera",
                    "parameters = {person_name} | {camera}",
                    "phones = +5511XXXXXXXXX | +5511YYYYYYYYY"
                ]
                _.camera.meta = null
                _.camera.place = null
                _.camera.camera_type = ''
                _.camera.worker_id = null
                _.camera.status = null
                _.camera.status_msg = null
                _.camera.restream_url = null
                _.camera.version = null
                _.camera.stream_url = null
                _.camera.router_url = null
                _.camera.labels = ''
                _.labels_ = '"default":"true"'
                _.camera.single_pass = false
                _.camera.group = 'default'

                // CPU MODE
                _.camera.stream_settings = {}
                _.camera.stream_settings.ffmpeg_params = []
                _.camera.stream_settings.md_threshold = 0.002
                _.camera.stream_settings.md_scale = 0.3
                _.camera.stream_settings.fd_frame_height = -1
                _.camera.stream_settings.uc_max_time_diff = 30
                _.camera.stream_settings.uc_max_dup = 3
                _.camera.stream_settings.uc_max_avg_shift = 10
                _.camera.stream_settings.det_period = 8
                _.camera.stream_settings.realtime = true
                _.camera.stream_settings.npersons = 4
                _.camera.stream_settings.disable_drops = false
                _.camera.stream_settings.tracker_threads = 4
                _.camera.stream_settings.parse_sei = false
                _.camera.stream_settings.image_arg = 'photo'
                _.camera.stream_settings.additional_headers = []
                _.camera.stream_settings.additional_body = [
                    'mf_selector=',
                    'detector=DEFAULT',
                    'gallery=DEFAULT',
                    "gallery_unknown=" + `${vm.user.username.toUpperCase()}_UNKNOWN`,
                    "gallery_blc=" + `${vm.user.username.toUpperCase()}_BLC`,
                ]
                _.camera.stream_settings.api_timeout = 15000
                _.camera.stream_settings.api_ssl_verify = false
                _.camera.stream_settings.post_uniq = true
                _.camera.stream_settings.min_score = 0.30
                _.camera.stream_settings.min_d_score = -0.05
                _.camera.stream_settings.realtime_dly = 1
                _.camera.stream_settings.realtime_post_perm = false
                _.camera.stream_settings.rot = null
                _.camera.stream_settings.roi = null
                _.camera.stream_settings.draw_track = false
                _.camera.stream_settings.send_track = 0
                _.camera.stream_settings.min_face_size = 0
                _.camera.stream_settings.max_face_size = 0
                _.camera.stream_settings.skip_count = 0
                _.camera.stream_settings.overall = true
                _.camera.stream_settings.only_norm = false
                _.camera.stream_settings.max_candidates = 0
                _.camera.stream_settings.jpeg_quality = 95
                _.camera.stream_settings.ffmpeg_format = null

                // GPU MODE
                _.camera.stream_settings_gpu = {}
                _.camera.stream_settings_gpu.play_speed = -1
                _.camera.stream_settings_gpu.filter_min_quality = 0.30
                _.camera.stream_settings_gpu.filter_min_face_size = 80
                _.camera.stream_settings_gpu.filter_max_face_size = 8192
                _.camera.stream_settings_gpu.normalized_only = false
                _.camera.stream_settings_gpu.jpeg_quality = 95
                _.camera.stream_settings_gpu.overall_only = false
                _.camera.stream_settings_gpu.use_stream_timestamp = false
                _.camera.stream_settings_gpu.ffmpeg_params = []
                _.camera.stream_settings_gpu.router_timeout_ms = 15000
                _.camera.stream_settings_gpu.router_verify_ssl = false
                _.camera.stream_settings_gpu.router_headers = []
                _.camera.stream_settings_gpu.router_body = [
                    'mf_selector=',
                    'detector=DEFAULT',
                    'gallery=DEFAULT',
                    "gallery_unknown=" + `${vm.user.username.toUpperCase()}_UNKNOWN`,
                    "gallery_blc=" + `${vm.user.username.toUpperCase()}_BLC`,
                ]
                _.camera.stream_settings_gpu.start_stream_timestamp = 0
                _.camera.stream_settings_gpu.imotion_threshold = 0
                _.camera.stream_settings_gpu.skip_count = 0
                _.camera.stream_settings_gpu.rot = null
                _.camera.stream_settings_gpu.roi = null
                _.camera.stream_settings_gpu.realtime_post_interval = 1
                _.camera.stream_settings_gpu.realtime_post_every_interval = false
                _.camera.stream_settings_gpu.ffmpeg_format = null

                // STATISTIC
                _.camera.statistic = {}
                _.camera.statistic.processed_duration = null
                _.camera.statistic.faces_posted = null
                _.camera.statistic.faces_failed = null
                _.camera.statistic.faces_not_posted = null
                _.camera.statistic.processing_fps = null
                _.camera.statistic.frames_dropped = null
                _.camera.statistic.frames_processed = null
                _.camera.statistic.frames_imotion_skipped = null
                _.camera.statistic.decoding_soft_errors = null
                _.camera.statistic.job_starts = null
                _.camera.statistic.faces_checked = 0
                _.camera.statistic.faces_not_checked = 0

                _.persons = []

                _.errors.clear()

                document.querySelector('.ukid-form').reset()

                if (focus) {

                    document.querySelector('.ukid-focus').focus()

                }

                setTimeout(function(){

                    document.querySelector('#enabled-input_field').checked = true

                }, 1000)

                vm.configDeviceResolution()

                _.camera.enabled = true

                document.querySelector('.device-type-input_terminal').click()

            },

            modal(camera) {

                this.camera = camera

                UIkit.modal('#modal-overflow').show()

            },

            enableInterval() {

                let _ = this

                setInterval(function(){

                    _.list(_.page)

                }, App.layout.camera.interval)

            },

            findPersonByParam(searchParam) {
                
                if (!Strings.isNullOrEmpty(searchParam)) {

                    this.isPersonLoading = true
                    
                    this.$http.get(

                        App.host.concat(App.listPersonEndpoint) + '?pageNum=1&perPage=300&param=' + searchParam

                    ).then(response => {

                        this.persons = response.body.data            
                        this.isPersonLoading = false
                                            
                    })
                    
                }

            },

            removePerson() {

                this.camera.selectedPersons = []

            },

            isOnline(camera) {

                let _ = this

                if (!camera.hasOwnProperty('lastTransaction')) {

                    return false

                } else if (_.$moment(_.$moment()).diff(camera.lastTransaction, 'minutes') >= 30) {

                    return false

                } else if (_.$moment(_.$moment()).diff(camera.lastTransaction, 'minutes') < 30) {

                    return true

                }

            },

            selectArch(arch) {

                this.camera.arch = arch

            }

        }

    }

</script>

<template id="cameras-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>

        <div id="modal-overflow" uk-modal>
            <div class="uk-modal-dialog">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <button v-on:click="del(camera)" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>

            </div>
        </div>

        <div id="ukid-filter" class="ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color">

            <dl class="uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center">

                <div class="uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165">
                    <span class="uk-hidden ukid-spinner-sm" uk-spinner="ratio:2.6"></span>
                    <span class="ukid-icon-sm ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:camera;ratio:3.6"></span>                    
                </div>

                <div class="uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl">
                    <br />
                    <div class="ukid-padding ukid-padding-10px ukid-font-size-15">
                        Criação, atualização e exclusão de cameras cftv
                        <br />Total: {{pagination.databaseCount}}
                    </div>
                </div>

                <br class="uk-hidden@m uk-hidden@l uk-hidden@xl" />

                <dt>
                    Pesquisar
                </dt>

                <br />

                <dd>
                    <input v-on:keyup.enter="search()" v-model="searchParam" id="search-param-input" class="uk-input ukid-width-80-percent" type="text" placeholder="Pesquise qualquer termo">
                    <span v-on:click="search()" class="ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6" uk-icon="icon:search;ratio:1.4"></span>
                    <span class="uk-hidden ukid-search-spinner ukid-magin-left-6" uk-spinner="ratio:0.92"></span>
                </dd>

                <button @click="clear(true)" type="button" class="uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13">
                    Iniciar Novo Registro
                </button>

                <dt v-for="camera in registers" :key="camera.meta">

                    <div class="uk-label ukid-width-70-percent ukid-padding-15px ukid-font-size-11">
                        
                        <span class="ukid-white-color ukid-margin-bottom-5" uk-icon="icon:video-camera;ratio:1.2" v-if="!camera.type || camera.type === 'cftv'"></span>

                        <span class="ukid-text-success-color ukid-margin-bottom-5" uk-icon="icon:tablet;ratio:1.0" uk-tooltip="Terminal está online" v-if="isOnline(camera) === true && camera.type === 'terminal'"></span>
                        <span class="ukid-text-alert-color ukid-margin-bottom-5" uk-icon="icon:tablet;ratio:1.0" uk-tooltip="Terminal off-line. Sem atividade a mais de 30 minutos" v-if="isOnline(camera) === false && camera.type === 'terminal'"></span>
                        
                        <span class="ukid-white-color ukid-margin-bottom-5" uk-icon="icon:more-vertical;ratio:1.0" v-if="camera.type === 'count_people'"></span><br />

                        <span v-if="!camera.type || camera.type === 'cftv'">CFTV / CCTV ( WORKER )<br /></span>
                        <span v-if="camera.type === 'count_people'">CONTAGEM DE PESSOAS ( WORKER )<br /></span>
                        <span v-if="camera.type === 'terminal'">CFTV / CCTV COM FACIAL ou TERMINAL FACIAL<br /></span>

                        {{camera.meta}}<br />
                        
                        <span class="ukid-text-lowercase">{{camera.id}}</span><br />
                        <span v-if="camera.type === 'terminal'" class="ukid-text-upercase">SN - {{camera.serialNumber}}</span><br />
                        <span class="ukid-text-alert-color ukid-font-size-9">{{camera.user}}</span><br />
                        <div class="ukid-width-100-percent ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-camera-plus-animation' + camera.id" :uk-toggle="'target: #ukid-camera-plus-animation-' + camera.id + '; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:plus;ratio:1.0"></div>
                        <span class="ukid-width-100-percent ukid-white-color ukid-font-size-9" hidden :id="'ukid-camera-plus-animation-' + camera.id">
                            <br /><br />
                            Grupos dessa Câmera
                            <br />
                            <span v-for="group in groups" :key="group.id">
                                <span v-html="camera.groupList.includes(group.name) ? '- ' + group.description + '<br />' : ''"></span>
                            </span>
                        </span>

                        <hr class="ukid-camera-hr" />
                        <table class="ukid-magin-left-20 ukid-font-size-9">
                            <tbody>
                                <tr v-if="!camera.type || camera.type === 'cftv'">
                                    <td align="left" width="50%">STATUS</td>
                                    <td align="left" width="50%" class="ukid-padding-left-5">
                                        {{camera.status}}
                                        <span v-show="camera.status_msg" class="ukid-camera-status">{{camera.status_msg}}</span>
                                    </td>
                                </tr>
                                <tr v-if="camera.type === 'terminal'">
                                    <td align="left" width="50%">STATUS</td>
                                    <td align="left" width="50%" class="ukid-padding-left-5">
                                        {{camera.enabled ? 'Habilitado' : 'Não Habilitado'}}
                                    </td>
                                </tr>                                
                                <tr v-if="camera.type === 'count_people'">
                                    <td align="left">PESSOAS JÁ CONTABILIZADAS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.count_people}}</td>
                                </tr>
                                <tr v-if="camera.type === 'terminal'">
                                    <td align="left">FACES VERIFICADAS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.faces_checked}}</td>
                                </tr>
                                <tr v-if="camera.type === 'terminal'">
                                    <td align="left">FACES NÃO VERIFICADAS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.faces_not_checked}}</td>
                                </tr>
                                <tr v-if="!camera.type || camera.type === 'cftv' || camera.type === 'terminal'">
                                    <td align="left">                                        
                                        FACES POSTADAS
                                    </td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.faces_posted}}</td>
                                </tr>
                                <tr v-if="!camera.type || camera.type === 'cftv'">
                                    <td align="left">FACES NÃO POSTADAS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.faces_not_posted}}</td>
                                </tr>
                                <tr v-if="!camera.type || camera.type === 'cftv'">
                                    <td align="left" colspan="2">                                        
                                        <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-' + camera.id" :uk-toggle="'target: #ukid-plus-animation-' + camera.id + '; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:plus;ratio:1.0"></span>                                        
                                    </td>
                                </tr>                                
                                <tr :id="'ukid-plus-animation-' + camera.id" v-if="!camera.type || camera.type === 'cftv'" hidden>
                                    <td align="left">FRAMES EM PROCESSAMENTO</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.processing_fps}}</td>
                                </tr>
                                <tr :id="'ukid-plus-animation-' + camera.id" v-if="!camera.type || camera.type === 'cftv'" hidden>
                                    <td align="left">FRAMES PROCESSADOS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.frames_processed}}</td>
                                </tr>
                                <tr v-if="camera.type === 'count_people'">
                                    <td align="left">FRAMES PROCESSADOS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.frames_processed}}</td>
                                </tr>                                
                                <tr :id="'ukid-plus-animation-' + camera.id" v-if="!camera.type || camera.type === 'cftv'" hidden>
                                    <td align="left">FRAMES DESCARTADOS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.frames_dropped}}</td>
                                </tr>
                                <tr :id="'ukid-plus-animation-' + camera.id" v-if="!camera.type || camera.type === 'cftv'" hidden>
                                    <td align="left">ERROS DE DECODE</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.decoding_soft_errors}}</td>
                                </tr>                                
                                <tr :id="'ukid-plus-animation-' + camera.id" v-if="!camera.type || camera.type === 'cftv'" hidden>
                                    <td align="left">JOBS INICIADOS</td>
                                    <td align="left" class="ukid-padding-left-5">{{camera.statistic.job_starts}}</td>
                                </tr>                                                                                                                                                              
                            </tbody>
                        </table>
                    </div>
                    <span v-if="camera" @click="fill(Object.assign({}, camera))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:file-edit;ratio:0.9"></span> 
                    <span v-if="camera" @click="modal(Object.assign({}, camera))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:trash;ratio:0.9"></span>
                </dt>

            </dl>

            <div v-show="!registers.length" class="uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30" uk-alert>
                <span class="uk-form-icon ukid-search-spinner ukid-magin-left-10 ukid-magin-right-10" uk-icon="icon:ban"></span>
                <span class="ukid-magin-left-20">
                    Nenhum registro encontrado
                </span>
            </div>

            <div v-show="pagination.last_page > 1" class="uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16">Pagina Atual: <b class="ukid-magin-left-10">{{page}}</b></div>

            <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>

                <paginate
                    v-model="page"
                    :page-count="pagination.last_page"
                    :click-handler="list"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>

            </ul>

        </div>        

        <div id="ukid-card-form" class="uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent">

            <div class="uk-card-header ukid-supress-border-bottom uk-visible@m">
                <div class="uk-grid-small uk-flex-middle ukid-min-height-150" uk-grid>
                    <div class="uk-width-auto ukid-min-width-165">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:video-camera;ratio:6.8" v-if="!camera.type || camera.type === 'cftv'"></span>
                        <span class="ukid-icon ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:more-vertical;ratio:5.2" v-if="camera.type === 'count_people'"></span>
                        <span class="ukid-icon ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:tablet;ratio:5.2" v-if="camera.type === 'terminal'"></span>
                    </div>
                    <div class="uk-width-expand">
                        <span class="ukid-font-size-18">Gestão CFTV / Contage de Pessoas / Terminais</span>
                        <div class="uk-margin-remove-top">
                            <span>
                                Criação, atualização e exclusão<br />
                                <span class="ukid-font-size-12">
                                    "CFTV / CCTV" -> Necessário para o video woker (detector de faces em tempo real)<br />
                                    "Contagem de Pessoas" -> Necessário para processo de contagem de pessoas, também utiliza cameras de CFTV / CCTV<br />
                                    "Terminais" -> Necessário para correto funcionamento nos terminais de reconhecimento facial / aferição de temperatura da Unike
                                </span>
                                <br />
                                <div class="uk-label ukid-label-red-color">Câmeras: {{pagination.databaseCount}}</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <progress class="uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0" value="0" max="100"></progress> 

            <div :class="{ 'uk-card-footer ukid-supress-border-top' : !Commons.isMobile(), 'uk-card-footer ukid-supress-border-top ukid-card-footer-cam-mobile' : Commons.isMobile() }">

                <form class="ukid-form" @submit.prevent="save">

                    <fieldset class="uk-fieldset">
                        
                        <div>
                            <legend class="uk-legend">

                                <span class="ukid-font-size-25 uk-visible@m">Formulário</span>

                                <div uk-sticky="offset: 170" class="uk-visible@s">
                                    <div>
                                        <button type="submit" class="uk-button uk-button-primary uk-align-right ukid-margin-bottom-0 ukid-font-size-13">Salvar</button>
                                    </div>

                                    <div class="uk-visible@m">
                                        <router-link to="/home">
                                            <button class="uk-button uk-button-primary uk-align-right ukid-margin-bottom-0 ukid-font-size-13">Cancelar</button>
                                        </router-link>
                                    </div>

                                    <div class="uk-hidden@m">
                                        <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-primary uk-align-right ukid-margin-bottom-0 ukid-font-size-13">Voltar</button>
                                    </div>
                                </div>

                                <button @click="clear(true)" type="button" class="uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13">
                                    Iniciar Novo Registro
                                </button>

                            </legend>
                        </div>

                        <camera-common-fields
                            ref="cameraCommonFields"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :users="users"
                            :groups="groups"
                            :camera="camera"
                            :persons="persons"
                            :vm="vm"
                            :isPersonLoading="isPersonLoading"
                            :labels_="labels_"
                            :errors="errors"
                        >

                        </camera-common-fields>

                        <br />

                        <span class="ukid-vd-params ukid-font-size-25 uk-visible@m" v-if="camera.type === 'cftv' || camera.type === 'count_people'">Parâmetros do Video Worker</span>

                        <br /><br />

                        <camera-gpu-fields
                            v-if="camera.type === 'cftv'"
                            ref="cameraGpuFields"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :camera="camera"
                            :vm="vm"
                            :errors="errors"
                        >

                        </camera-gpu-fields>

                        <camera-body-gpu-fields
                            v-if="camera.type === 'count_people'"
                            ref="cameraBodyGpuFields"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :body="body"
                            :vm="vm"
                            :errors="errors"
                        >

                        </camera-body-gpu-fields>

                        <!--ul uk-tab class="uk-visible@s" v-if="camera.type === 'cftv'">
                            <li>
                                <a href="#">
                                    <span class="uk-margin-small-right ukid-white-color uk-icon" uk-icon="icon:settings;ratio:1.7"></span>
                                    <span class="ukid-font-size-18">
                                        Para projetos baseados em CPU
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <span class="uk-margin-small-right ukid-white-color uk-icon" uk-icon="icon:settings;ratio:1.7"></span>
                                    <span class="ukid-font-size-18">
                                        Para projetos baseados em GPU
                                    </span>
                                </a>
                            </li>
                        </ul-->

                        <!--ul uk-tab class="uk-hidden@s" v-if="camera.type === 'cftv'">
                            <li>
                                <a @click="selectArch('CPU')" href="#" :class="{ 'uk-active' : camera.arch === 'cpu' }"><span class="ukid-font-size-18">CPU</span></a>
                            </li>
                            <li>
                                <a @click="selectArch('GPU')" href="#" :class="{ 'uk-active' : camera.arch === 'gpu' }"><span class="ukid-font-size-18">GPU</span></a>
                            </li>
                        </ul-->

                        <!--ul class="uk-switcher uk-margin" style="touch-action: pan-y pinch-zoom;" v-if="camera.type === 'cftv'"-->

                            <!-- CPU MODE -->
                            <!--li>
                                <camera-cpu-fields
                                    ref="cameraCpuFields"
                                    :app="App"
                                    :strings="Strings"
                                    :commons="Commons"
                                    :camera="camera"
                                    :vm="vm"
                                    :errors="errors"
                                >

                                </camera-cpu-fields>
                            </li-->
                            <!-- CPU MODE -->

                            <!-- GPU MODE -->
                            <!--li>
                                <camera-gpu-fields
                                    ref="cameraGpuFields"
                                    :app="App"
                                    :strings="Strings"
                                    :commons="Commons"
                                    :camera="camera"
                                    :vm="vm"
                                    :errors="errors"
                                >

                                </camera-gpu-fields>
                            </li-->
                            <!-- GPU MODE -->

                        <!--/ul-->

                        <div class="uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40">
                            <div>
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14">Voltar</button>
                            </div>
                            <div>
                                <button type="submit" class="uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14">Salvar</button>
                            </div>
                        </div>

                    </fieldset>

                </form>

            </div>
        </div>
    </div>

</template>

