<script>

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'editor', 'editorConfig', 'user', 'auth', 'role', 'vm'],
        
        data() {

            return {

            }

        },

        created() {
        
        },

        methods: {

        }
        
    }

</script>

<template id="register-whatsapp-email-template">

    <!--  Invite Email -->
    <li>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Título do Email do Convite
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Título do Email do Convite
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do convidado<br />
                <b>{inviting}</b> - Será substituído pelo nome de quem está convidando<br />
                <b>{invite_number}</b> - Será substituído número do documento<br />
                <b>{link}</b> - Será substituído pelo link de acesso ao convite<br />
                <b>{invitation_type}</b> - Será substituído pelo tipo de convite (permanente ou limitado)
            </span>
            <br /><br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('email-title-invite-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('email-title-invite-select_field')
                }" 
                v-model="user.emailTitleInvite"
                v-validate="'required|min:10|max:100'"
                c-data-vv-as="field"
                id="email-title-invite-select_field"
                name="email-title-invite-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 1000" />
        </div>

        <div class="uk-margin">                            
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Título do Email de Renovação do Convite
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Título do Email<br />de Renovação do Convite
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do convidado<br />
                <b>{inviting}</b> - Será substituído pelo nome de quem está convidando<br />
                <b>{invite_number}</b> - Será substituído número do documento<br />
                <b>{link}</b> - Será substituído pelo link de acesso ao convite<br />
                <b>{invitation_type}</b> - Será substituído pelo tipo de convite (permanente ou limitado)
            </span>
            <br /><br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('email-title-reinvite-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('email-title-reinvite-select_field')
                }" 
                v-model="user.emailTitleReInvite"
                v-validate="'required|min:10|max:100'"
                c-data-vv-as="field"
                id="email-title-reinvite-select_field"
                name="email-title-reinvite-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 1000" />
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem do Convite Enviado Por Email
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem do<br />Convite Enviado<br />Por Email
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do convidado<br />
                <b>{inviting}</b> - Será substituído pelo nome de quem está convidando<br />
                <b>{invite_number}</b> - Será substituído número do documento<br />
                <b>{link}</b> - Será substituído pelo link de acesso ao convite<br />
                <b>{invitation_type}</b> - Será substituído pelo tipo de convite (permanente ou limitado)
            </span>
            <br /><br />
            <ckeditor :editor="editor" v-model="user.emailMessageInvite" :config="editorConfig"></ckeditor>
        </div>
        
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem de Renovação de Convite Enviado Por Email
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem de<br />Renovação de Convite<br />Por Email
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do convidado<br />
                <b>{inviting}</b> - Será substituído pelo nome de quem está convidando<br />
                <b>{invite_number}</b> - Será substituído número do documento<br />
                <b>{link}</b> - Será substituído pelo link de acesso ao convite<br />
                <b>{invitation_type}</b> - Será substituído pelo tipo de convite (permanente ou limitado)
            </span>
            <br /><br />
            <ckeditor :editor="editor" v-model="user.emailMessageReInvite" :config="editorConfig"></ckeditor>
        </div>
    </li>

</template>