// noinspection JSUnresolvedVariable,DuplicatedCode,JSUndeclaredVariable,JSSuspiciousNameCombination

export var Strings = {

    nullToEmpty: function(str) {

        if (str === undefined || str === null || str === 'null' || str === '')
            return ''

        return this.trim(str)

    },

    trim: function(str) {

        if (str === undefined || str === null || str === '')
            return null

        return str.replace(/^\s+|\s+$/gm, '');

    },

    contains: function (_default, wanted) {

        if (_default === undefined || wanted === undefined) {

            return false

        }

        return _default.indexOf(wanted) !== -1;

    },

    isNullOrEmpty: function (str) {

        return (!str || /^\s*$/.test(str))

    },

    formatDate: function (str) {

        let d = new Date(str || Date.now()), mes = '' + (d.getMonth() + 1), dia = '' + d.getDate(), ano = d.getFullYear()

        if (mes.length < 2) mes = '0' + mes
        if (dia.length < 2) dia = '0' + dia

        return [dia, mes, ano].join('/')

    },

    removeSuffix(str, suffix) {

        if (!str || !suffix) {

            return str

        }

        if (str.toUpperCase().endsWith(suffix.toUpperCase())) {

            return str.substring(0, str.length - suffix.length)

        } else {

            return str

        }

    },

    isNumber(value) {

        if ((undefined === value) || (null === value)) {

            return false

        }

        return !isNaN( value ) && !isNaN( parseFloat( value ) )

    }

}

export var Commons = {

    isObjectNullOrEmpty: function (obj) {

        return obj === null || obj === undefined

    },

    toJsonSelect: function(list, id, text) {

        let json = '['

        $.each(list, function (i, v) {

            json += '{ "value":"' + v[id] + '", "text":"' + v[text] + '"}, '

        })

        json = json.substring(0, json.lastIndexOf(','))

        json += ']'

        return JSON.parse(json)

    },

    getBrowser: function() {
        
        let isOpera = false

        try {             
            isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
        } catch(err) {
        }        

        // Firefox 1.0+
        let isFirefox = typeof InstallTrigger !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]" 
        let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') === -1 &&
               navigator.userAgent.indexOf('FxiOS') === -1

        // Internet Explorer 6-11
        let isIE = /*@cc_on!@*/!!document.documentMode;

        // Edge 20+
        let isEdge = !isIE && !!window.StyleMedia;

        // Chrome 1 - 79
        let isChrome = false
        
        try {         
            isChrome = (!!window.chrome.webstore || !!window.chrome.runtime || /Chrome/i.test(navigator.userAgent)) 
        } catch(err) {
        }

        // Edge (based on chromium) detection
        let isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

        // Blink engine detection
        let isBlink = (isChrome || isOpera) && !!window.CSS;
    
        return isOpera ? 'Opera' : isFirefox ? 'Firefox' : isSafari ? 'Safari' : isIE ? 'IE' : isEdge ? 'Edge' : isChrome ? 'Chrome' : isEdgeChromium ? 'EdgeChromium' : isBlink ? 'Blink' : 'unknown'

    },

    isMobile: function() {
        let userAgent = navigator.userAgent.toLowerCase();

        if (/iPad/.test(navigator.platform)) {
            return true
        }

        if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
            return true
        }

        return userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i) !== -1;

    },

    isAndroid: function() {
        let nav = navigator.userAgent.toLowerCase();
        return nav.indexOf("android") > -1;
    },

    convertDataURIToBinaryBuffer: function(dataURI) {

      let base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;

      let base64 = dataURI.substring(base64Index);

      return Buffer.from(base64, 'base64');

    },

    getImageOrientation: function(file, callback) {
        
        let reader = new FileReader();

        reader.onload = function(e) {

            let view = new DataView(e.target.result);

            // if (view.getUint16(0, false) !== 0xFFD8)
                // return callback(-2);

            while (offset < length) {

                let marker = view.getUint16(offset, false)

                offset += 2

                if (marker === 0xFFE1) {

                    if (view.getUint32(offset += 2, false) !== 0x45786966)
                        return callback(-1)

                    let little = view.getUint16(offset += 6, false) === 0x4949

                    offset += view.getUint32(offset + 4, little)

                    let tags = view.getUint16(offset, little)

                    offset += 2

                    for (let i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) === 0x0112)
                            return callback(view.getUint16(offset + (i * 12) + 8, little))
                } else if ((marker & 0xFF00) !== 0xFF00)
                    break
                else
                    offset += view.getUint16(offset, false)
            }

            return callback(-1)

        };

        reader.readAsArrayBuffer(file)

    },

    rotateBase64Image: function(base64, canvas, orientation, w, h, callback) {

        let image = new Image()

        image.src = base64

        image.onload = function () {

            let context = canvas.getContext("2d");

            let cal = commons.calculatePhoto(this.width, this.height, w, h)
            canvas.width = cal.width;
            canvas.height = cal.height;

            let width = cal.width,
                height = cal.height;

            // Set proper canvas dimensions before transform & export
            if ([5, 6, 7, 8].indexOf(orientation) > -1) {
                canvas.width = height
                canvas.height = width
            } else {
                canvas.width = width
                canvas.height = height
            }

            // transform context before drawing image
            switch (orientation) {
                case 2:
                    context.transform(-1, 0, 0, 1, width, 0)
                    break;
                case 3:
                    context.transform(-1, 0, 0, -1, width, height)
                    break;
                case 4:
                    context.transform(1, 0, 0, -1, 0, height)
                    break;
                case 5:
                    context.transform(0, 1, 1, 0, 0, 0)
                    break;
                case 6:                    
                    context.transform(0, 1, -1, 0, height, 0)
                    break;
                case 7:
                    context.transform(0, -1, -1, 0, height, width)
                    break;
                case 8:                    
                    context.transform(0, -1, 1, 0, 0, width)
                    break;
                default:
                    context.transform(1, 0, 0, 1, 0, 0)
            }

            // Draw image
            if (w > 0 && h > 0) {
                context.drawImage(image, 0, 0, cal.width, cal.height)
            } else {
                context.drawImage(image)
            }

            callback(canvas.toDataURL('image/jpeg'))

        }
        
    },

    calculatePhoto: function(srcWidth, srcHeight, maxWidth, maxHeight) {

        let ratio = [maxWidth / srcWidth, maxHeight / srcHeight]

        ratio = Math.min(ratio[0], ratio[1])

        return {
            width: srcWidth * ratio,
            height: srcHeight * ratio
        }

    },

    isBase64: function(base64) {

        let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

        base64regex.test(base64)
        
    },
    
    isCPF: function(cpf) {

        if ( !cpf || cpf.length !== 11
            || cpf === "00000000000"
            || cpf === "11111111111"
            || cpf === "22222222222"
            || cpf === "33333333333"
            || cpf === "44444444444"
            || cpf === "55555555555"
            || cpf === "66666666666"
            || cpf === "77777777777"
            || cpf === "88888888888"
            || cpf === "99999999999" )
            return false
            let _sum = 0
            let _rest
            for (let i = 1; i <= 9; i++)
            _sum = _sum + parseInt(cpf.substring(i-1, i)) * (11 - i)
            _rest = (_sum * 10) % 11
            if ((_rest === 10) || (_rest === 11))  _rest = 0
            if (_rest !== parseInt(cpf.substring(9, 10)) ) return false
            _sum = 0
            for (let i = 1; i <= 10; i++)
            _sum = _sum + parseInt(cpf.substring(i-1, i)) * (12 - i)
            _rest = (_sum * 10) % 11
            if ((_rest === 10) || (_rest === 11))  _rest = 0
            return _rest === parseInt(cpf.substring(10, 11))

    }

}