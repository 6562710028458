var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uk-flex-top ukid-photo-zindex",
      attrs: { id: _vm.modalId, "uk-modal": "" }
    },
    [
      _c(
        "div",
        {
          staticClass: "uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
        },
        [
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px"
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass: "uk-list ukid-margin-top-0 ukid-font-size-12"
                    },
                    [
                      _c("li", [
                        _c("img", {
                          staticClass: "ukid-height-150-px",
                          attrs: {
                            src:
                              "data:image/png;base64," +
                              _vm.person.principalCrop.base64Crop
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v("\n              id19 Related: "),
                        _vm.person.id19
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.person.id19Related[0]))
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              id19: "),
                        _vm.person.id19
                          ? _c("span", [_vm._v(" " + _vm._s(_vm.person.id19))])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              Customer: "),
                        _vm.person.user
                          ? _c("span", [_vm._v(" " + _vm._s(_vm.person.user))])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              First Detect: "),
                        _vm.person.firstDetect
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      new Date(
                                        _vm.person.firstDetect.timestamp
                                      ),
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  )
                              )
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              Principal Crop: "),
                        _vm.person.principalCrop
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      new Date(
                                        _vm.person.principalCrop.timestamp
                                      ),
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  )
                              )
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              Qtt Captures: "),
                        _vm.person.age.qttDetect
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.person.age.qttDetect))
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              Work: "),
                        _c("br"),
                        _vm._v(" "),
                        _vm.person.workList.length > 0
                          ? _c(
                              "span",
                              [
                                _vm._l(_vm.person.workList, function(w) {
                                  return _c("span", { key: w.startDate }, [
                                    _vm._v(
                                      "\n                  Inicio: " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            new Date(w.startDate),
                                            "DD/MM/YYYY HH:mm:ss"
                                          )
                                        ) +
                                        " "
                                    ),
                                    w.endDate
                                      ? _c("span", [
                                          _vm._v(
                                            " - Fim: " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(w.endDate),
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br")
                                  ])
                                }),
                                _vm._v(" "),
                                _c("br")
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _vm.person
                        ? _c("li", [
                            _c("b", [_vm._v("--- Links ---")]),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(" Snapshot: "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      _vm.person.principalCrop &&
                                      _vm.person.principalCrop.snapshot,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("clique\n                  aqui")]
                              ),
                              _vm._v(" "),
                              _c("br")
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("b", [_vm._v("--- Atributos Estimados ---")]),
                            _c("br"),
                            _c("br"),
                            _vm._v("\n              Sexo: "),
                            _vm.person.gender
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.person.gender.attributeValue) +
                                      " ( " +
                                      _vm._s(
                                        _vm.person.gender.perc !== null
                                          ? parseFloat(
                                              _vm.person.gender.perc * 1.0
                                            ).toFixed(4)
                                          : "N/A"
                                      ) +
                                      " ) "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Idade: "),
                            _vm.person.age
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.person.age.attributeValue) +
                                      " ( " +
                                      _vm._s(
                                        _vm.person.age.perc !== null
                                          ? parseFloat(
                                              _vm.person.age.perc * 1.0
                                            ).toFixed(4)
                                          : "N/A"
                                      ) +
                                      " ) "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Loja Principal: "),
                            _vm.person.principalStore
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalStore.storeName
                                    ) + " "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              ESRATE: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropESRate
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalCrop.cropESRate
                                    ) + " "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Quality: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropQuality
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalCrop.cropQuality
                                    ) + "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Width: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropWidth
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.person.principalCrop.cropWidth) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Height: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropHeight
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalCrop.cropHeight
                                    ) + " "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Pitch: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropHeadPose
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalCrop.cropHeadPose
                                        .result.pitch
                                    ) + "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Roll: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropHeadPose
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalCrop.cropHeadPose
                                        .result.roll
                                    ) + "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n              Yaw: "),
                            _vm.person.principalCrop &&
                            _vm.person.principalCrop.cropHeadPose
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.person.principalCrop.cropHeadPose
                                        .result.yaw
                                    ) + "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.document
                        ? _c("li", [
                            _c("b", [
                              _vm._v("--- Atributos da Biográficos ---")
                            ]),
                            _c("br"),
                            _c("br"),
                            _vm._v("\n              nome: "),
                            _vm.document.name
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.document.name) + " ")
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm._v("\n              email: "),
                            _vm.document.email
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.document.email) + " ")
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm._v("\n              cpf: "),
                            _vm.document.cpf
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.document.cpf) + " ")
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm._v("\n              nasc: "),
                            _vm.document.dateOfDate
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          new Date(_vm.document.dateOfDate),
                                          "DD/MM/YYYY HH: mm: ss"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm._v("\n              empregado: "),
                            _vm.document.isEmployee != null
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.document.isEmployee) + " "
                                  )
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "desconhecido: " + _vm._s(_vm.person.unknown)
                              ),
                              _c("br")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c("b", [_vm._v(" --- Dados de Loja ---")]),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c("b", [_vm._v(" Loja Principal ")]),
                        _c("br"),
                        _vm._v("\n              ID Loja: "),
                        _vm.person.principalStore.storeId
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.person.principalStore.storeId) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n              Loja: "),
                        _vm.person.principalStore.storeName
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.person.principalStore.storeName) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _vm.person.principalStore.storeResume
                          ? _c(
                              "div",
                              [
                                _c("b", [_vm._v(" Resumo com todas lojas")]),
                                _c("br"),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.person.principalStore.storeResume,
                                  function(item, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(
                                        "\n      ID Loja: " +
                                          _vm._s(item.storeId)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n      Loja: " +
                                          _vm._s(item.storeName)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n      qtt Detect: " +
                                          _vm._s(item.qttDetect)
                                      ),
                                      _c("br"),
                                      _c("br")
                                    ])
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(1)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "uk-margin-small-right uk-icon",
          attrs: { "uk-icon": "icon:hashtag;ratio:0.8" }
        }),
        _vm._v("\n          Dados\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-default uk-modal-close",
          attrs: { type: "button" }
        },
        [_vm._v("Fechar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e8f097ae", { render: render, staticRenderFns: staticRenderFns })
  }
}