var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.camera.id,
              expression: "camera.id"
            }
          ],
          staticClass: "uk-margin"
        },
        [
          _c(
            "span",
            {
              staticClass:
                "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
            },
            [_vm._v("\n            Worker ID\n        ")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
            },
            [_vm._v("\n            Worker ID\n        ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-visible@s ukid-font-size-17" }, [
            _c("b", [_vm._v(_vm._s(_vm.camera.id))])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "uk-hidden@s ukid-font-size-15" }, [
            _c("b", [_vm._v(_vm._s(_vm.camera.id))])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [_vm._v("\n            Tipo de Dispositivo\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [_vm._v("\n            Tipo de Dispositivo\n        ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c(
            "div",
            {
              class: {
                "ukid-width-100-percent": _vm.errors.has("enabled-input_field"),
                "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                  "enabled-input_field"
                )
              }
            },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.type,
                      expression: "camera.type"
                    }
                  ],
                  staticClass: "uk-radio",
                  attrs: {
                    value: "cftv",
                    type: "radio",
                    id: "device-cftv-input_field",
                    name: "device-type-input_field",
                    checked: ""
                  },
                  domProps: { checked: _vm._q(_vm.camera.type, "cftv") },
                  on: {
                    click: function($event) {
                      return _vm.checkType("cftv")
                    },
                    change: function($event) {
                      return _vm.$set(_vm.camera, "type", "cftv")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "uk-visible@s" }, [
                  _vm._v("CCTV COM WORKER")
                ]),
                _c("span", { staticClass: "uk-hidden@s" }, [_vm._v("CFTV")])
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.type,
                      expression: "camera.type"
                    }
                  ],
                  staticClass: "uk-radio",
                  attrs: {
                    value: "count_people",
                    type: "radio",
                    id: "device-pc-input_field",
                    name: "device-type-input_field"
                  },
                  domProps: {
                    checked: _vm._q(_vm.camera.type, "count_people")
                  },
                  on: {
                    click: function($event) {
                      return _vm.checkType("count_people")
                    },
                    change: function($event) {
                      return _vm.$set(_vm.camera, "type", "count_people")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "uk-visible@s" }, [
                  _vm._v("CONTAGEM DE PESSOAS COM WORKER")
                ]),
                _c("span", { staticClass: "uk-hidden@s" }, [
                  _vm._v("C. DE PESSOAS")
                ])
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.type,
                      expression: "camera.type"
                    }
                  ],
                  staticClass: "uk-radio device-type-input_terminal",
                  attrs: {
                    value: "terminal",
                    type: "radio",
                    id: "device-terminal-input_field",
                    name: "device-type-input_field"
                  },
                  domProps: { checked: _vm._q(_vm.camera.type, "terminal") },
                  on: {
                    click: function($event) {
                      return _vm.checkType("terminal")
                    },
                    change: function($event) {
                      return _vm.$set(_vm.camera, "type", "terminal")
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "uk-visible@s" }, [
                  _vm._v("TERMINAL FACIAL INTELIGENTE")
                ]),
                _c("span", { staticClass: "uk-hidden@s" }, [
                  _vm._v("T. FACIAL INTELIGENTE")
                ])
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.camera.type === "terminal" && _vm.camera.id
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
              },
              [
                _c("span", { staticClass: "ukid-icon-pink-color" }, [
                  _vm._v("\n                CONFIGURAÇÃO ISAPI\n            ")
                ]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                  attrs: {
                    href: "#ukid-plus-animation-general_filter_min_quality-1",
                    "uk-toggle":
                      "target: #ukid-plus-animation-general_filter_min_quality-1; animation: uk-animation-fade; style: display:block;",
                    "uk-icon": "icon:comments;ratio:1.0"
                  }
                }),
                _c("br"),
                _vm._v(" "),
                _vm._m(0)
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
              },
              [
                _c("span", { staticClass: "ukid-icon-pink-color" }, [
                  _vm._v(
                    "\n                Configuração ISAPI - Câmeras Atomatizadas\n            "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                  attrs: {
                    href: "#ukid-plus-animation-general_filter_min_quality-2",
                    "uk-toggle":
                      "target: #ukid-plus-animation-general_filter_min_quality-2; animation: uk-animation-fade; style: display:block;",
                    "uk-icon": "icon:comments;ratio:1.0"
                  }
                }),
                _c("br"),
                _vm._v(" "),
                _vm._m(1)
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "span",
                {
                  staticClass:
                    "uk-text-uppercase uk-text-bold ukid-icon-pink-color"
                },
                [_vm._v("Host:")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "ukid-font-size-15 ukid-text-alert-color" },
                [
                  _vm._v(
                    _vm._s(_vm.camera.terminalUrl.replace("/rest/v3/", ""))
                  )
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "uk-text-uppercase uk-text-bold ukid-icon-pink-color"
                },
                [_vm._v("Para detecção de faces:")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "ukid-font-size-15 ukid-text-alert-color" },
                [_vm._v("/ISAPI/faces/default/" + _vm._s(_vm.camera.id))]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "uk-text-uppercase uk-text-bold ukid-icon-pink-color"
                },
                [_vm._v("Para detecção de corpos:")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "ukid-font-size-15 ukid-text-alert-color" },
                [_vm._v("/ISAPI/bodies/default/" + _vm._s(_vm.camera.id))]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _vm.camera.type === "cftv"
          ? _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
              },
              [_vm._v("\n            Habilite o CFTV\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.camera.type === "cftv"
          ? _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [_vm._v("\n            Habilite o CFTV\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.camera.type === "terminal"
          ? _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
              },
              [_vm._v("\n            Habilite o Terminal Facial\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.camera.type === "terminal"
          ? _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [_vm._v("\n            Habilite o Terminal Facial\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.camera.type === "count_people"
          ? _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
              },
              [_vm._v("\n            Habilite a Contagem de Pessoas\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.camera.type === "count_people"
          ? _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [_vm._v("\n            Habilite a Contagem de Pessoas\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c(
            "div",
            {
              class: {
                "ukid-width-100-percent": _vm.errors.has("enabled-input_field"),
                "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                  "enabled-input_field"
                )
              }
            },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.enabled,
                      expression: "camera.enabled"
                    }
                  ],
                  staticClass: "uk-radio",
                  attrs: {
                    value: "true",
                    type: "radio",
                    id: "enabled-input_field",
                    name: "enabled-input_field",
                    checked: ""
                  },
                  domProps: { checked: _vm._q(_vm.camera.enabled, "true") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.camera, "enabled", "true")
                    }
                  }
                }),
                _vm._v(" Habilitar\n                ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.enabled,
                      expression: "camera.enabled"
                    }
                  ],
                  staticClass: "uk-radio",
                  attrs: {
                    value: "false",
                    type: "radio",
                    id: "disabled-input_field",
                    name: "enabled-input_field"
                  },
                  domProps: { checked: _vm._q(_vm.camera.enabled, "false") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.camera, "enabled", "false")
                    }
                  }
                }),
                _vm._v(" Não Habilitar\n                ")
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [_vm._v("\n            Habilite o HeadPose\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [_vm._v("\n            Habilite o HeadPose\n        ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c(
            "div",
            {
              class: {
                "ukid-width-100-percent": _vm.errors.has("enabled-input_field"),
                "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                  "enabled-input_field"
                )
              }
            },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.headPoseEnabled,
                      expression: "camera.headPoseEnabled"
                    }
                  ],
                  staticClass: "uk-radio",
                  attrs: {
                    value: "true",
                    type: "radio",
                    id: "enabled-headpose-input_field",
                    name: "enabled-headpose-input_field",
                    checked: ""
                  },
                  domProps: {
                    checked: _vm._q(_vm.camera.headPoseEnabled, "true")
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.camera, "headPoseEnabled", "true")
                    }
                  }
                }),
                _vm._v(" Habilitar\n                ")
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.camera.headPoseEnabled,
                      expression: "camera.headPoseEnabled"
                    }
                  ],
                  staticClass: "uk-radio",
                  attrs: {
                    value: "false",
                    type: "radio",
                    id: "disabled-headpose-input_field",
                    name: "enabled-headpose-input_field"
                  },
                  domProps: {
                    checked: _vm._q(_vm.camera.headPoseEnabled, "false")
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.camera, "headPoseEnabled", "false")
                    }
                  }
                }),
                _vm._v(" Não Habilitar\n                ")
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.camera.headPoseEnabled === "true" ||
      _vm.camera.headPoseEnabled === true
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n            Tolerância do Pitch, Roll e Yaw\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-250-px" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.pitch,
                    expression: "camera.pitch"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:1|max:11",
                    expression: "'required|min:1|max:11'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10": _vm.errors.has(
                    "pitch-input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10": !_vm.errors.has(
                    "pitch-input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "pitch-input_field",
                  name: "pitch-input_field",
                  type: "text",
                  placeholder: "Ex: Pitch de -1000,1000"
                },
                domProps: { value: _vm.camera.pitch },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "pitch", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.roll,
                    expression: "camera.roll"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:1|max:11",
                    expression: "'required|min:1|max:11'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10": _vm.errors.has(
                    "roll-input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10": !_vm.errors.has(
                    "roll-input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "roll-input_field",
                  name: "roll-input_field",
                  type: "text",
                  placeholder: "Ex: Roll de -1000,1000"
                },
                domProps: { value: _vm.camera.roll },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "roll", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.yaw,
                    expression: "camera.yaw"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:1|max:11",
                    expression: "'required|min:1|max:11'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10": _vm.errors.has(
                    "yaw-input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-margin-bottom-10": !_vm.errors.has(
                    "yaw-input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "yaw-input_field",
                  name: "yaw-input_field",
                  type: "text",
                  placeholder: "Ex: Yaw de -1000,1000"
                },
                domProps: { value: _vm.camera.yaw },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "yaw", $event.target.value)
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.camera.type === "terminal",
              expression: "camera.type === 'terminal'"
            }
          ],
          staticClass: "uk-margin"
        },
        [
          _c(
            "span",
            {
              staticClass:
                "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
            },
            [
              _c("span", { staticClass: "ukid-icon-pink-color" }, [
                _vm._v("\n                Qualidade da Face\n            ")
              ]),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("span", {
                staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                attrs: {
                  href: "#ukid-plus-animation-general_filter_min_quality-1",
                  "uk-toggle":
                    "target: #ukid-plus-animation-general_filter_min_quality-1; animation: uk-animation-fade; style: display:block;",
                  "uk-icon": "icon:comments;ratio:1.0"
                }
              }),
              _c("br"),
              _vm._v(" "),
              _vm._m(5)
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
            },
            [
              _c("span", { staticClass: "ukid-icon-pink-color" }, [
                _vm._v("\n                Qualidade da Face\n            ")
              ]),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("span", {
                staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                attrs: {
                  href: "#ukid-plus-animation-general_filter_min_quality-2",
                  "uk-toggle":
                    "target: #ukid-plus-animation-general_filter_min_quality-2; animation: uk-animation-fade; style: display:block;",
                  "uk-icon": "icon:comments;ratio:1.0"
                }
              }),
              _c("br"),
              _vm._v(" "),
              _vm._m(6)
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.camera.general_filter_min_quality,
                  expression: "camera.general_filter_min_quality"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|between:0,1",
                  expression: "'required|between:0,1'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                  "general_filter_min_quality_input_field"
                ),
                "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                  "general_filter_min_quality_input_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "general_filter_min_quality_input_field",
                name: "general_filter_min_quality_input_field",
                type: "text",
                placeholder: "Entre 0 e 1"
              },
              domProps: { value: _vm.camera.general_filter_min_quality },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.camera,
                    "general_filter_min_quality",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
          },
          [
            _c("span", { staticClass: "ukid-icon-pink-color" }, [
              _vm._v("\n                Tamanho Mínimo de Face\n            ")
            ]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-filter-min-face-size-1",
                "uk-toggle":
                  "target: #ukid-plus-animation-filter-min-face-size-1; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _vm._m(7)
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
          },
          [
            _c("span", { staticClass: "ukid-icon-pink-color" }, [
              _vm._v("\n                Tamanho Mínimo de Face\n            ")
            ]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-filter-min-face-size-2",
                "uk-toggle":
                  "target: #ukid-plus-animation-filter-min-face-size-2; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _vm._m(8)
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.general_filter_min_face_size,
                expression: "camera.general_filter_min_face_size"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:0|max:1000",
                expression: "'required|min:0|max:1000'"
              }
            ],
            class: {
              "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                "filter_min_face_size_input_field"
              ),
              "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                "filter_min_face_size_input_field"
              )
            },
            attrs: {
              "c-data-vv-as": "field",
              id: "filter_min_face_size_input_field",
              name: "filter_min_face_size_input_field",
              type: "text",
              placeholder: "Mínimo 0, máximo 1000"
            },
            domProps: { value: _vm.camera.general_filter_min_face_size },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.camera,
                  "general_filter_min_face_size",
                  $event.target.value
                )
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("users-component", {
        ref: "usersCameraCpnt",
        attrs: {
          id: "camera-users-select_field_1",
          name: "camera-users-select_field_1",
          required: "required",
          label: "Usuário",
          app: _vm.app,
          strings: _vm.strings,
          commons: _vm.commons,
          vm: _vm.vm,
          entity: _vm.camera
        }
      }),
      _vm._v(" "),
      _c("group-component", {
        ref: "cameraGroupCpnt",
        attrs: {
          id: "camera-group-select_field_1",
          name: "camera-group-select_field_1",
          required: "required",
          label: "Grupos de Sistema",
          description: "(Ctrl + Clique) para mais de um grupo",
          app: _vm.app,
          strings: _vm.strings,
          commons: _vm.commons,
          vm: _vm.vm,
          entity: _vm.camera,
          parent_: _vm.parent_
        }
      }),
      _vm._v(" "),
      _vm.camera.type === "terminal"
        ? _c(
            "div",
            { staticClass: "uk-margin" },
            [
              _c(
                "span",
                {
                  staticClass:
                    "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
                },
                [
                  _vm._v("\n            Dispositivo Acessado Somente Por:"),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                    attrs: {
                      href: "#ukid-plus-animation-selected-persons-1",
                      "uk-toggle":
                        "target: #ukid-plus-animation-selected-persons-1; animation: uk-animation-fade; style: display:block;",
                      "uk-icon": "icon:comments;ratio:1.0"
                    }
                  }),
                  _c("br"),
                  _vm._v(" "),
                  _vm._m(9)
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
                },
                [
                  _vm._v("\n            Dispositivo Acessado Somente Por:"),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                    attrs: {
                      href: "#ukid-plus-animation-selected-persons-2",
                      "uk-toggle":
                        "target: #ukid-plus-animation-selected-persons-2; animation: uk-animation-fade; style: display:block;",
                      "uk-icon": "icon:comments;ratio:1.0"
                    }
                  }),
                  _c("br"),
                  _vm._v(" "),
                  _vm._m(10)
                ]
              ),
              _vm._v(" "),
              _c(
                "multiselect",
                {
                  attrs: {
                    options: _vm.persons,
                    multiple: true,
                    "close-on-select": false,
                    "clear-on-select": true,
                    "preserve-search": false,
                    "preselect-first": true,
                    "show-labels": false,
                    selectLabel: "Enter ou clique para marcar",
                    deselectLabel: "Enter ou clique para marcar",
                    selectedLabel: "Pessoa marcada",
                    placeholder: "Pesquise pessoas digitando aqui",
                    label: "name",
                    "track-by": "name"
                  },
                  on: { "search-change": _vm.findPersonByParam },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "tag",
                        fn: function(ref) {
                          var option = ref.option
                          var remove = ref.remove
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "multiselect__tag uk-text-center ukid-width-270-px"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-flex uk-flex-center ukid-margin-bottom-10 ukid-height-85-px"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ukid-light-blue-color ukid-circular-person-dt ukid-circular-person-dt-resolution"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "ukid-circular-portrait",
                                          attrs: { src: option.photo, alt: "" }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(option.documentNumber)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(option.name) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass:
                                    "ukid-cursor-pointer ukid-margin-top-10 ukid-margin-bottom-10",
                                  attrs: { "uk-icon": "icon:trash;ratio:1.2" },
                                  on: {
                                    click: function($event) {
                                      return remove(option)
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "option",
                        fn: function(props) {
                          return [
                            _c("table", [
                              _c("tr", [
                                _c("td", { attrs: { width: "100" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-flex uk-flex-center ukid-margin-bottom-10 ukid-height-85-px"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ukid-light-blue-color ukid-circular-person-dt ukid-circular-person-dt-resolution"
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "ukid-circular-portrait",
                                            attrs: {
                                              src: props.option.photo,
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("table", [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                                        Docto: " +
                                            _vm._s(
                                              props.option.documentNumber
                                            ) +
                                            "\n                                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                                        Nome: " +
                                            _vm._s(props.option.name) +
                                            "\n                                    "
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    375893234
                  ),
                  model: {
                    value: _vm.camera.selectedPersons,
                    callback: function($$v) {
                      _vm.$set(_vm.camera, "selectedPersons", $$v)
                    },
                    expression: "camera.selectedPersons"
                  }
                },
                [
                  _c("template", { slot: "noResult" }, [
                    _c("span", [
                      _vm._v(
                        "\n                    Nenhuma pessoa encontrada\n                "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "noOptions" }, [
                    _c("span", [
                      _vm._v(
                        "\n                    Comece a digitar\n                "
                      )
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.camera.type === "terminal"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
              },
              [
                _vm._v("\n            Serial Number do Terminal"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                  attrs: {
                    href: "#ukid-plus-animation-serial-number-1",
                    "uk-toggle":
                      "target: #ukid-plus-animation-serial-number-1; animation: uk-animation-fade; style: display:block;",
                    "uk-icon": "icon:comments;ratio:1.0"
                  }
                }),
                _c("br"),
                _vm._v(" "),
                _vm._m(11)
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v("\n            Serial Number do Terminal"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                  attrs: {
                    href: "#ukid-plus-animation-serial-number-2",
                    "uk-toggle":
                      "target: #ukid-plus-animation-serial-number-2; animation: uk-animation-fade; style: display:block;",
                    "uk-icon": "icon:comments;ratio:1.0"
                  }
                }),
                _c("br"),
                _vm._v(" "),
                _vm._m(12)
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.serialNumber,
                    expression: "camera.serialNumber"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:5|max:45",
                    expression: "'required|min:5|max:45'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                    "serial-number-input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                    "serial-number-input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "serial-number-input_field",
                  name: "serial-number-input_field",
                  type: "text",
                  placeholder: "Mínimo 5, máximo 45"
                },
                domProps: { value: _vm.camera.serialNumber },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "serialNumber", $event.target.value)
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.camera.type === "terminal"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
              },
              [_vm._v("\n            URL do Terminal\n        ")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [_vm._v("\n            URL do Terminal\n        ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("span", {
                staticClass: "uk-form-icon",
                attrs: { "uk-icon": "icon:server" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.terminalUrl,
                    expression: "camera.terminalUrl"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|url|min:5|max:300",
                    expression: "'required|url|min:5|max:300'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                    "terminal-url-input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                    "terminal-url-input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "terminal-url-input_field",
                  name: "terminal-url-input_field",
                  type: "text",
                  placeholder: "Mínimo 5, máximo 300"
                },
                domProps: { value: _vm.camera.terminalUrl },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "terminalUrl", $event.target.value)
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.camera.type === "terminal"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
              },
              [
                _vm._v("\n            Senha"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                  attrs: {
                    href: "#ukid-plus-animation-terminal-password-1",
                    "uk-toggle":
                      "target: #ukid-plus-animation-terminal-password-1; animation: uk-animation-fade; style: display:block;",
                    "uk-icon": "icon:comments;ratio:1.0"
                  }
                }),
                _c("br"),
                _vm._v(" "),
                _vm._m(13)
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v("\n            Senha"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
                  attrs: {
                    href: "#ukid-plus-animation-terminal-password-2",
                    "uk-toggle":
                      "target: #ukid-plus-animation-terminal-password-2; animation: uk-animation-fade; style: display:block;",
                    "uk-icon": "icon:comments;ratio:1.0"
                  }
                }),
                _c("br"),
                _vm._v(" "),
                _vm._m(14)
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("span", {
                staticClass: "uk-form-icon",
                attrs: { "uk-icon": "icon:lock" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.terminalPassword,
                    expression: "camera.terminalPassword"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:5|max:120",
                    expression: "'required|min:5|max:120'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                    "terminal-password-input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                    "terminal-password-input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "terminal-password-input_field",
                  name: "terminal-password-input_field",
                  type: "text",
                  placeholder: "Mínimo 5, máximo 120"
                },
                domProps: { value: _vm.camera.terminalPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.camera,
                      "terminalPassword",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [
            _vm._v("\n            Meta"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-meta-1",
                "uk-toggle":
                  "target: #ukid-plus-animation-meta-1; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _vm._m(15)
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [
            _vm._v("\n            Meta"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-meta-2",
                "uk-toggle":
                  "target: #ukid-plus-animation-meta-2; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _vm._m(16)
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c("span", {
            staticClass: "uk-form-icon",
            attrs: { "uk-icon": "icon:warning" }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.meta,
                expression: "camera.meta"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:5|max:50",
                expression: "'required|min:5|max:50'"
              }
            ],
            class: {
              "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                "meta-input_field"
              ),
              "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                "meta-input_field"
              )
            },
            attrs: {
              "c-data-vv-as": "field",
              id: "meta-input_field",
              name: "meta-input_field",
              type: "text",
              placeholder: "Mínimo 5, máximo 50"
            },
            domProps: { value: _vm.camera.meta },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.camera, "meta", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [
            _vm._v("\n            Local"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-place-1",
                "uk-toggle":
                  "target: #ukid-plus-animation-place-1; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _vm._m(17)
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [
            _vm._v("\n            Local"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-place-2",
                "uk-toggle":
                  "target: #ukid-plus-animation-place-2; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "ukid-font-size-9",
                attrs: { hidden: "", id: "ukid-plus-animation-place-2" }
              },
              [
                _vm._v(
                  "\n                Descrição curta do localização da câmera\n            "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c("span", {
            staticClass: "uk-form-icon",
            attrs: { "uk-icon": "icon:warning" }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.place,
                expression: "camera.place"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:5|max:50",
                expression: "'required|min:5|max:50'"
              }
            ],
            class: {
              "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                "place-input_field"
              ),
              "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                "place-input_field"
              )
            },
            attrs: {
              "c-data-vv-as": "field",
              id: "place-input_field",
              name: "place-input_field",
              type: "text",
              placeholder: "Mínimo 5, máximo 50"
            },
            domProps: { value: _vm.camera.place },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.camera, "place", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [_vm._v("\n            Tipo de Local\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [_vm._v("\n            Tipo de Local\n        ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.place_type,
                expression: "camera.place_type"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:1|max:30",
                expression: "'required|min:1|max:30'"
              }
            ],
            class: {
              "uk-select ukid-form-error uk-textarea ukid-width-100-percent ukid-font-size-16": _vm.errors.has(
                "place_type-select_field"
              ),
              "uk-select ukid-width-100-percent uk-textarea ukid-font-size-16": !_vm.errors.has(
                "place_type-select_field"
              )
            },
            attrs: {
              rows: "7",
              "data-vv-as": "field",
              id: "place_type-select_field",
              name: "place_type-select_field"
            },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.camera,
                  "place_type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "open_room_location" } }, [
              _vm._v("Sala Aberta")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "closed_room_location" } }, [
              _vm._v("Sala Fechada")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "open_location" } }, [
              _vm._v("Local Aberto")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "closed_location" } }, [
              _vm._v("Local Fechado")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "open_hall" } }, [
              _vm._v("Corredor Aberto")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "closed_hall" } }, [
              _vm._v("Corredor Fechado")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "courtyard" } }, [_vm._v("Pátio")])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [_vm._v("\n            Tipo de Câmera\n        ")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [_vm._v("\n            Tipo de Câmera\n        ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.camera_type,
                expression: "camera.camera_type"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:1|max:30",
                expression: "'required|min:1|max:30'"
              }
            ],
            class: {
              "uk-select ukid-form-error uk-textarea ukid-width-100-percent ukid-font-size-16": _vm.errors.has(
                "camera_type-select_field"
              ),
              "uk-select ukid-width-100-percent uk-textarea ukid-font-size-16": !_vm.errors.has(
                "camera_type-select_field"
              )
            },
            attrs: {
              rows: "7",
              "data-vv-as": "field",
              id: "camera_type-select_field",
              name: "camera_type-select_field"
            },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.camera,
                  "camera_type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "TPay" } }, [
              _vm._v("Terminal de Pagamento")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "TIn" } }, [
              _vm._v("Ponto Eletônico Entrada")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "TOut" } }, [
              _vm._v("Ponto Eletônico Saída")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "TAIn" } }, [
              _vm._v("Câmera de Acesso - Entrada")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "TAOut" } }, [
              _vm._v("Câmera de Acesso - Saída")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "In" } }, [
              _vm._v("Câmera CFTV / CCTV de Entrada")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Out" } }, [
              _vm._v("Câmera CFTV / CCTV de Saída")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Tracking" } }, [
              _vm._v("Câmera CFTV / CCTV de Rastreamento")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _vm._m(18),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [
            _vm._v(
              "\n            Grupo - Para agrupamento de determinadas câmeras\n        "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c("span", {
            staticClass: "uk-form-icon",
            attrs: { "uk-icon": "icon:warning" }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.group,
                expression: "camera.group"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|min:5|max:30",
                expression: "'required|min:5|max:30'"
              }
            ],
            class: {
              "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                "group-input_field"
              ),
              "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                "group-input_field"
              )
            },
            attrs: {
              "c-data-vv-as": "field",
              id: "group-input_field",
              name: "group-input_field",
              type: "text",
              placeholder: "Mínimo 5, máximo 30"
            },
            domProps: { value: _vm.camera.group },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.camera, "group", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.camera.type === "cftv"
        ? _c("div", { staticClass: "uk-margin" }, [
            _vm._m(19),
            _vm._v(" "),
            _vm._m(20),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("span", {
                staticClass: "uk-form-icon",
                attrs: { "uk-icon": "icon:server" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.labels_,
                    expression: "labels_"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min:5|max:100",
                    expression: "'min:5|max:100'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                    "labels_input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                    "labels_input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "labels_input_field",
                  name: "labels_input_field",
                  type: "text",
                  placeholder: "Mínimo 5, máximo 100"
                },
                domProps: { value: _vm.labels_ },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.labels_ = $event.target.value
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.camera.type !== "terminal"
        ? _c("div", { staticClass: "uk-margin" }, [
            _vm._m(21),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n            URL - Stream da câmera(RTSP ou HTTP)\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("span", {
                staticClass: "uk-form-icon",
                attrs: { "uk-icon": "icon:server" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_url,
                    expression: "camera.stream_url"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:5|max:300",
                    expression: "'required|min:5|max:300'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                    "stream_url_input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                    "stream_url_input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "stream_url_input_field",
                  name: "stream_url_input_field",
                  type: "text",
                  placeholder: "Mínimo 5, máximo 300"
                },
                domProps: { value: _vm.camera.stream_url },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "stream_url", $event.target.value)
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.camera.type !== "terminal"
        ? _c("div", { staticClass: "uk-margin" }, [
            _vm._m(22),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
              },
              [
                _vm._v(
                  "\n            Router URL - Endereço do WEB HUB / API que processa as imagens\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c("span", {
                staticClass: "uk-form-icon",
                attrs: { "uk-icon": "icon:server" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.router_url,
                    expression: "camera.router_url"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:5|max:300",
                    expression: "'required|min:5|max:300'"
                  }
                ],
                class: {
                  "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                    "router_url_input_field"
                  ),
                  "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                    "router_url_input_field"
                  )
                },
                attrs: {
                  "c-data-vv-as": "field",
                  id: "router_url_input_field",
                  name: "router_url_input_field",
                  type: "text",
                  placeholder: "Mínimo 5, máximo 300"
                },
                domProps: { value: _vm.camera.router_url },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.camera, "router_url", $event.target.value)
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [
            _vm._v("\n            Cabeçalho"),
            _c("br"),
            _vm._v("AFD"),
            _c("br"),
            _vm._v("\n            PONTO FACIAL"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-afd-header-1",
                "uk-toggle":
                  "target: #ukid-plus-animation-afd-header-1; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "ukid-font-size-9",
                attrs: { hidden: "", id: "ukid-plus-animation-afd-header-1" }
              },
              [
                _vm._v(
                  "\n                Ex: 00000000011CNPJ__________000000000000{razao_social}00005000485012580{data_inicial_registros}{data_final_registro}{data_geracao_arquivo}{horario_geracao}\n            "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [
            _vm._v("\n            Cabeçalho Arquivo AFD - PONTO FACIAL"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-afd-header-2",
                "uk-toggle":
                  "target: #ukid-plus-animation-afd-header-2; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _vm._m(23)
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
          _c("span", { staticClass: "uk-form-icon" }),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.camera.afd_header,
                expression: "camera.afd_header"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "min:10|max:289",
                expression: "'min:10|max:289'"
              }
            ],
            class: {
              "ukid-text-area-external ukid-form-error uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
                "afd_header_input_field"
              ),
              "ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
                "afd_header_input_field"
              )
            },
            attrs: {
              wrap: "physical",
              "c-data-vv-as": "field",
              id: "afd_header_input_field",
              name: "afd_header_input_field",
              type: "text",
              placeholder: "Mínimo 232, máximo 232"
            },
            domProps: { value: _vm.camera.afd_header },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.camera, "afd_header", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin" }, [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [
            _vm._v("\n            Integração com Sistemas"),
            _c("br"),
            _vm._v("Externos"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-external-integration-1",
                "uk-toggle":
                  "target: #ukid-plus-animation-external-integration-1; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "ukid-text-lowercase ukid-font-size-12",
                attrs: {
                  hidden: "",
                  id: "ukid-plus-animation-external-integration-1"
                }
              },
              [
                _c("br"),
                _vm._v(" "),
                _vm._m(24),
                _vm._v(" "),
                _vm._m(25),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(26),
                _vm._v(" "),
                _vm._m(27),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(28),
                _vm._v(" "),
                _vm._m(29),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(30),
                _vm._v(" "),
                _vm._m(31),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(32),
                _vm._v(" "),
                _vm._m(33),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(34),
                _vm._v(" "),
                _vm._m(35),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(36),
                _vm._v(" "),
                _vm._m(37),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(38),
                _vm._v(" "),
                _vm._m(39),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(40),
                _vm._v(" "),
                _vm._m(41),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v("\n                    Definido no cadastro"),
                      _c("br"),
                      _vm._v("do usuário, alerta quando temperatura"),
                      _c("br"),
                      _vm._v("de atenção é alcançada"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    alertar temperatura de atencao "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" nao"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v(
                        "\n                    Definido no cadastrado usuário,"
                      ),
                      _c("br"),
                      _vm._v("alerta quando temperatura"),
                      _c("br"),
                      _vm._v("alta é alcançada"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v("\n                    alertar temperatura alta "),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" nao"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v("\n                    Template whatssapp"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    template de mensagem do whatsapp "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" default_person_arrived_in_the_camera"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v("\n                    Parâmetros whatssapp"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    parametros do template do whatsapp "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" {person_name} "),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("|")
                      ]),
                      _vm._v(" {camera}"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v("\n                    Determina para quais"),
                      _c("br"),
                      _vm._v("telefones enviar a mensagem"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    telefones que receberao as mensagens "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" +5511922221111  "),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("|")
                      ]),
                      _vm._v(" +5511933331111"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v(
                        "\n                    mensagem de alerta que será"
                      ),
                      _c("br"),
                      _vm._v("recebida no Whatsapp ou SMS"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(42),
                _vm._v(" "),
                _vm._m(43),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(44),
                _vm._v(" "),
                _vm._m(45)
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
          },
          [
            _vm._v("\n            Integração Externas"),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
              attrs: {
                href: "#ukid-plus-animation-external-integration-2",
                "uk-toggle":
                  "target: #ukid-plus-animation-external-integration-2; animation: uk-animation-fade; style: display:block;",
                "uk-icon": "icon:comments;ratio:1.0"
              }
            }),
            _c("br"),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "ukid-text-lowercase ukid-font-size-12",
                attrs: {
                  hidden: "",
                  id: "ukid-plus-animation-external-integration-2"
                }
              },
              [
                _c("br"),
                _vm._v(" "),
                _vm._m(46),
                _vm._v(" "),
                _vm._m(47),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(48),
                _vm._v(" "),
                _vm._m(49),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(50),
                _vm._v(" "),
                _vm._m(51),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(52),
                _vm._v(" "),
                _vm._m(53),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(54),
                _vm._v(" "),
                _vm._m(55),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(56),
                _vm._v(" "),
                _vm._m(57),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(58),
                _vm._v(" "),
                _vm._m(59),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(60),
                _vm._v(" "),
                _vm._m(61),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(62),
                _vm._v(" "),
                _vm._m(63),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v(
                        "\n                    Definido no cadastro do usuário, alerta quando temperatura de atenção é alcançada"
                      ),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    alertar temperatura de atencao "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" nao"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v(
                        "\n                    Definido no cadastrado usuário, alerta quando temperatura alta é alcançada"
                      ),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v("\n                    alertar temperatura alta "),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" nao"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v("\n                    Template whatssapp"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    template de mensagem do whatsapp "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" default_person_arrived_in_the_camera"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v("\n                    Parâmetros whatssapp"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    parametros do template do whatsapp "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" {person_name} "),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("|")
                      ]),
                      _vm._v(" {camera}"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v(
                        "\n                    Determina para quais telefones enviar a mensagem"
                      ),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", { staticClass: "ukid-icon-pink-color" }, [
                      _vm._v(
                        "\n                    telefones que receberao as mensagens "
                      ),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("=")
                      ]),
                      _vm._v(" +5511922221111  "),
                      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
                        _vm._v("|")
                      ]),
                      _vm._v(" +5511933331111"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.camera.type === "cftv" || _vm.camera.type === "terminal"
                  ? _c("span", [
                      _vm._v(
                        "\n                    mensagem de alerta que será recebida no Whatsapp ou SMS"
                      ),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(64),
                _vm._v(" "),
                _vm._m(65),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(66),
                _vm._v(" "),
                _vm._m(67)
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "uk-inline ukid-width-100-percent" },
          [
            _c("span", { staticClass: "uk-form-icon" }),
            _vm._v(" "),
            _c(
              "multiselect",
              {
                attrs: {
                  id: "ukid-external-integration",
                  selectLabel: "Enter ou clique para marcar",
                  deselectLabel: "Enter ou clique para marcar",
                  selectedLabel: "Grupo marcada",
                  placeholder: "Adicione aqui atributo = parametro",
                  options: _vm.camera.terminal_external_integration,
                  "hide-selected": true,
                  "close-on-select": false,
                  "clear-on-select": false,
                  multiple: true,
                  taggable: true
                },
                on: { tag: _vm.addTag },
                scopedSlots: _vm._u([
                  {
                    key: "tag",
                    fn: function(ref) {
                      var option = ref.option
                      var remove = ref.remove
                      return [
                        _c("table", [
                          _c("tr", [
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "multiselect__tag",
                                  staticStyle: {
                                    display: "flex",
                                    "font-size": "16px",
                                    "white-space": "normal",
                                    width: "-webkit-min-content",
                                    "min-width": "370px"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#0f4226",
                                        "word-break": "break-word !important"
                                      },
                                      attrs: { contenteditable: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            option.split("=")[0].trim() ===
                                              "external_url"
                                              ? "url e token do cliente"
                                              : option.split("=")[0].trim() ===
                                                "alert_always"
                                              ? "alertar qualquer evento"
                                              : option.split("=")[0].trim() ===
                                                "alert_marked_person"
                                              ? "alertar pessoa marcada"
                                              : option.split("=")[0].trim() ===
                                                "alert_unknown"
                                              ? "alertar se pessoas desconhecidas"
                                              : option.split("=")[0].trim() ===
                                                "alert_known"
                                              ? "alertar se pessoas conhecidas"
                                              : option.split("=")[0].trim() ===
                                                "alert_blc"
                                              ? "alertar se pessoas em blc"
                                              : option.split("=")[0].trim() ===
                                                "image_type"
                                              ? "imagem enviada para o cliente"
                                              : option.split("=")[0].trim() ===
                                                "latitude"
                                              ? "latitude do equipamento"
                                              : option.split("=")[0].trim() ===
                                                "longitude"
                                              ? "longitude do equipamento"
                                              : option.split("=")[0].trim() ===
                                                "alert_attention_temperature"
                                              ? "alertar temperatura de atencao"
                                              : option.split("=")[0].trim() ===
                                                "alert_high_temperature"
                                              ? "alertar temperatura alta"
                                              : option.split("=")[0].trim() ===
                                                "template"
                                              ? "template de mensagem do whatsapp"
                                              : option.split("=")[0].trim() ===
                                                "parameters"
                                              ? "parametros do template do whatsapp"
                                              : option.split("=")[0].trim() ===
                                                "phones"
                                              ? "telefones que receberao as mensagens"
                                              : option.split("=")[0].trim()
                                          )
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            padding: "10px",
                                            color: "darkslategrey"
                                          }
                                        },
                                        [_vm._v("=")]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          option.split("=")[1].trim() === "true"
                                            ? "sim"
                                            : option.split("=")[1].trim() ===
                                              "false"
                                            ? "nao"
                                            : option.split("=")[1].trim()
                                            ? option.split("=")[1]
                                            : "vazio"
                                        ) +
                                          "\n                                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("span", {
                                staticClass:
                                  "ukid-cursor-pointer ukid-margin-bottom-10",
                                attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                                on: {
                                  click: function($event) {
                                    return remove(option)
                                  }
                                }
                              })
                            ])
                          ])
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.camera.terminal_external_integration,
                  callback: function($$v) {
                    _vm.$set(_vm.camera, "terminal_external_integration", $$v)
                  },
                  expression: "camera.terminal_external_integration"
                }
              },
              [
                _vm._v(" "),
                _c("template", { slot: "noOptions" }, [
                  _c("span", [
                    _vm._v(
                      "\n                        Comece a digitar\n                    "
                    )
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: {
          hidden: "",
          id: "ukid-plus-animation-general_filter_min_quality-1"
        }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: {
          hidden: "",
          id: "ukid-plus-animation-general_filter_min_quality-2"
        }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-text-bolder ukid-text-alert-color ukid-text-uppercase ukid-font-size-10"
      },
      [
        _vm._v("\n                Tolerância Pitch"),
        _c("br"),
        _vm._v("\n                Negativo: Para baixo"),
        _c("br"),
        _vm._v("\n                Positivo: Para cima\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-text-bolder ukid-text-alert-color ukid-text-uppercase ukid-font-size-10"
      },
      [
        _vm._v("\n                Tolerância Roll"),
        _c("br"),
        _vm._v("\n                Negativo: Inclinação para direita"),
        _c("br"),
        _vm._v(
          "\n                Positivo: Inclinação para esquerda\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-text-bolder ukid-text-alert-color ukid-text-uppercase ukid-font-size-10"
      },
      [
        _vm._v("\n                Tolerância Yaw"),
        _c("br"),
        _vm._v("\n                Negativo: Direita"),
        _c("br"),
        _vm._v("\n                Positivo: Esquerda\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: {
          hidden: "",
          id: "ukid-plus-animation-general_filter_min_quality-1"
        }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: {
          hidden: "",
          id: "ukid-plus-animation-general_filter_min_quality-2"
        }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-face-size-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-face-size-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-selected-persons-1" }
      },
      [
        _vm._v("\n                Se não preenchido, todos os cadastros"),
        _c("br"),
        _vm._v("\n                terão acesso ao terminal\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-selected-persons-2" }
      },
      [
        _vm._v("\n                Se não preenchido, todos os cadastros"),
        _c("br"),
        _vm._v("\n                terão acesso ao terminal\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-serial-number-1" }
      },
      [
        _vm._v("\n                Para projetos específicos,"),
        _c("br"),
        _vm._v("considerar o seguinte formato:"),
        _c("br"),
        _vm._v("\n                {serialNumber},{CNPJ},{CODIGO_SETOR}"),
        _c("br"),
        _vm._v(
          "\n                0000000000,00000000000000,0000000000\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-serial-number-2" }
      },
      [
        _vm._v(
          "\n                Para projetos específicos, considerar o seguinte formato:"
        ),
        _c("br"),
        _vm._v("\n                {serialNumber},{CNPJ},{CODIGO_SETOR}"),
        _c("br"),
        _vm._v(
          "\n                0000000000,00000000000000,0000000000\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-terminal-password-1" }
      },
      [
        _vm._v("\n                Será Criptografada - Decriptada no Terminal"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-terminal-password-2" }
      },
      [
        _vm._v("\n                Será Criptografada - Decriptada no Terminal"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-meta-1" }
      },
      [
        _vm._v("\n                Identificador Definido pelo Usuário"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-meta-2" }
      },
      [
        _vm._v("\n                Identificador Definido pelo Usuário"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-place-1" }
      },
      [
        _vm._v("\n                Descrição curta"),
        _c("br"),
        _vm._v("do localização da câmera\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
      },
      [
        _vm._v("\n            Grupo - Para agrupamento"),
        _c("br"),
        _vm._v("\n            de determinadas câmeras\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
      },
      [
        _vm._v("\n            Labels - Servem como filtros para as câmeras"),
        _c("br"),
        _vm._v("\n            cadastradas no Video Manager"),
        _c("br"),
        _vm._v("\n            Geralmente usado para separar"),
        _c("br"),
        _vm._v(
          '\n            instâncias diferentes de GPU\n            Json Livre de Ex: "gpu":"0" ou "gpu":"instancia_1"\n        '
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
      },
      [
        _vm._v(
          "\n            Labels - Servem como filtros para as câmeras cadastradas no Video Manager"
        ),
        _c("br"),
        _vm._v(
          "\n            Geralmente usado para separar instâncias diferentes de GPU"
        ),
        _c("br"),
        _vm._v(
          '\n            Json Livre de Ex: "gpu":"0" ou "gpu":"instancia_1"\n        '
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
      },
      [
        _vm._v("\n            URL - Stream da câmera"),
        _c("br"),
        _vm._v("(RTSP ou HTTP)\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
      },
      [
        _vm._v("\n            Router URL - Endereço do WEB HUB / API"),
        _c("br"),
        _vm._v("que processa as imagens"),
        _c("br"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-afd-header-2" }
      },
      [
        _vm._v("\n                Formato de Exemplo:"),
        _c("br"),
        _vm._v(
          "\n                00000000011CNPJ__________000000000000{razao_social}00005000485012580{data_inicial_registros}{data_final_registro}{data_geracao_arquivo}{horario_geracao}\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Integrar com endpoints"), _c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("url e token do cliente "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" https://host_1 "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("|")]),
      _vm._v(" token_host_1 "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(",")])
      ]),
      _vm._v(" https://host_2 "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("|")]),
      _vm._v(" token_host_2"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determina se a"),
      _c("br"),
      _vm._v("integração sempre"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar qualquer evento "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determina se haverá alerta"),
      _c("br"),
      _vm._v("de pessoas marcadas"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar pessoa marcada "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determinal se haverá alerta"),
      _c("br"),
      _vm._v("de pessoa desconhecida"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar se pessoas desconhecidas "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determinal se haverá alerta"),
      _c("br"),
      _vm._v("de pessoa conhecida"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar se pessoas conhecidas "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determinal se haverá alerta"),
      _c("br"),
      _vm._v("de pessoa em blc"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar se pessoas em blc "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Define se a foto na entrega"),
      _c("br"),
      _vm._v("da mensagem será base64 ou url"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("imagem enviada para o cliente "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" BASE64"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determina a latitude"),
      _c("br"),
      _vm._v("do equipamento"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("latitude do equipamento "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" 123"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determina a longitude"),
      _c("br"),
      _vm._v("do equipamento"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("longitude do equipamento "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" 321"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("\n                    Pode determinar atributo customizado"),
      _c("br"),
      _vm._v("\n                    O atributo será enviado"),
      _c("br"),
      _vm._v('no endpoint "external_url"'),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("my_attribute "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" hello world"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("\n                    O quê será entregue?"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "code",
      {
        staticStyle: {
          "background-color": "transparent",
          "text-transform": "none !important"
        }
      },
      [
        _c("br"),
        _vm._v("\n                {"),
        _c("br"),
        _vm._v("\n                      documentNumber: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "123456",\n                    '),
        _c("br"),
        _vm._v("  type: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "TIn",\n                    '),
        _c("br"),
        _vm._v("  latitude: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "-23.4988",\n                    '),
        _c("br"),
        _vm._v("  longitude: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "-46.6998",\n                    '),
        _c("br"),
        _vm._v("  snapshotBase64: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "base64==", // ou não enviado\n                    '),
        _c("br"),
        _vm._v("  photoBase64: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "base64==", // ou não enviado\n                    '),
        _c("br"),
        _vm._v("  temperature: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "36.7",\n                    '),
        _c("br"),
        _vm._v("  temperatureState: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "alert", // ou "fever"\n                    '),
        _c("br"),
        _vm._v("  confidenceOnDevice: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "-46.6998",\n                    '),
        _c("br"),
        _vm._v("  usingMask: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "true",\n                    '),
        _c("br"),
        _vm._v("  totalBodies: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "100",\n                    '),
        _c("br"),
        _vm._v("  snapshotUrl: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(
          ' "https://upload.unike.tech/uploads/somenthing/hash.jpeg",\n                    '
        ),
        _c("br"),
        _vm._v("  bboxInfoList: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "['),
        _c("br"),
        _vm._v(
          '\n                            snapshot: "https://upload.unike.tech/uploads/somenthing/hash.jpeg",'
        ),
        _c("br"),
        _vm._v('\n                            totalBodies: "100",'),
        _c("br"),
        _vm._v('\n                            timestamp: "1661791078000",'),
        _c("br"),
        _vm._v("\n                            bboxList: ["),
        _c("br"),
        _vm._v("\n                                  left: 14,"),
        _c("br"),
        _vm._v("\n                                  top: 50,"),
        _c("br"),
        _vm._v("\n                                  right: 290,"),
        _c("br"),
        _vm._v("\n                                  bottom: 91"),
        _c("br"),
        _vm._v("\n                            ]"),
        _c("br"),
        _vm._v('\n                      ]",\n                    '),
        _c("br"),
        _vm._v("  liveness: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "true"\n                '),
        _c("br"),
        _vm._v("}")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Integrar com endpoints"), _c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("url e token do cliente "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" https://host_1 "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("|")]),
      _vm._v(" token_host_1 "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(",")])
      ]),
      _vm._v(" https://host_2 "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("|")]),
      _vm._v(" token_host_2"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Determina se a integração sempre"), _c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar qualquer evento "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determina se haverá alerta de pessoas marcadas"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar pessoa marcada "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determinal se haverá alerta de pessoa desconhecida"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar se pessoas desconhecidas "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determinal se haverá alerta de pessoa conhecida"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar se pessoas conhecidas "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determinal se haverá alerta de pessoa em blc"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("alertar se pessoas em blc "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" nao"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Define se a foto na entrega da mensagem será base64 ou url"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("imagem enviada para o cliente "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" BASE64"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Determina a latitude do equipamento"), _c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("latitude do equipamento "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" 123"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Determina a longitude do equipamento"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("longitude do equipamento "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" 321"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("\n                    Pode determinar atributo customizado"),
      _c("br"),
      _vm._v(
        '\n                    O atributo será enviado no endpoint "external_url"'
      ),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-icon-pink-color" }, [
      _vm._v("my_attribute "),
      _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v("=")]),
      _vm._v(" hello world"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("\n                    O quê será entregue?"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "code",
      {
        staticStyle: {
          "background-color": "transparent",
          "text-transform": "none !important"
        }
      },
      [
        _c("br"),
        _vm._v("\n                {"),
        _c("br"),
        _vm._v("\n                      documentNumber: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "123456",\n                    '),
        _c("br"),
        _vm._v("  type: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "TIn",\n                    '),
        _c("br"),
        _vm._v("  latitude: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "-23.4988",\n                    '),
        _c("br"),
        _vm._v("  longitude: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "-46.6998",\n                    '),
        _c("br"),
        _vm._v("  snapshotBase64: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "base64==", // ou não enviado\n                    '),
        _c("br"),
        _vm._v("  photoBase64: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "base64==", // ou não enviado\n                    '),
        _c("br"),
        _vm._v("  temperature: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "36.7",\n                    '),
        _c("br"),
        _vm._v("  temperatureState: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "alert", // ou "fever"\n                    '),
        _c("br"),
        _vm._v("  confidenceOnDevice: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "-46.6998",\n                    '),
        _c("br"),
        _vm._v("  usingMask: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "true",\n                    '),
        _c("br"),
        _vm._v("  totalBodies: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "100",\n                    '),
        _c("br"),
        _vm._v("  snapshotUrl: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(
          ' "https://upload.unike.tech/uploads/somenthing/hash.jpeg",\n                    '
        ),
        _c("br"),
        _vm._v("  bboxInfoList: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "['),
        _c("br"),
        _vm._v(
          '\n                            snapshot: "https://upload.unike.tech/uploads/somenthing/hash.jpeg",'
        ),
        _c("br"),
        _vm._v('\n                            totalBodies: "100",'),
        _c("br"),
        _vm._v('\n                            timestamp: "1661791078000",'),
        _c("br"),
        _vm._v("\n                            bboxList: ["),
        _c("br"),
        _vm._v("\n                                  left: 14,"),
        _c("br"),
        _vm._v("\n                                  top: 50,"),
        _c("br"),
        _vm._v("\n                                  right: 290,"),
        _c("br"),
        _vm._v("\n                                  bottom: 91"),
        _c("br"),
        _vm._v("\n                            ]"),
        _c("br"),
        _vm._v('\n                      ]",\n                    '),
        _c("br"),
        _vm._v("  liveness: "),
        _c("span", { staticClass: "ukid-icon-yellow-color" }, [_vm._v(":")]),
        _vm._v(' "true"\n                '),
        _c("br"),
        _vm._v("}")
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7b8b51a6", { render: render, staticRenderFns: staticRenderFns })
  }
}