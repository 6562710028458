var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent"
        },
        [
          _c(
            "div",
            { staticClass: "uk-card-header ukid-supress-border-bottom" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-60",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-width-expand ukid-margin-top-30" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "ukid-font-size-20 ukid-margin-top-30 uk-visible@s"
                        },
                        [_vm._v("Demo CALL")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "ukid-font-size-14 ukid-margin-top-30 uk-hidden@s"
                        },
                        [_vm._v("Demo CALL")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin-remove-top" }, [
                        _c("span", { staticClass: "uk-visible@s" }, [
                          _vm._v(
                            "                                \n                            Módulo de demonstração das imagens coletadas em conference call\n                            "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "uk-label ukid-label-tuscan-color" },
                            [
                              _vm._v(
                                "Sessões geradas no momento: " +
                                  _vm._s(_vm.pagination.databaseCount)
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "uk-hidden@s ukid-font-size-11" },
                          [
                            _vm._v(
                              "                                \n                            Módulo de demonstração das imagens coletadas em conference call\n                            "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-label ukid-label-tuscan-color ukid-font-size-10"
                              },
                              [
                                _vm._v(
                                  "Sessões neste momento: " +
                                    _vm._s(_vm.pagination.databaseCount)
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.registers.length,
                                expression: "registers.length"
                              }
                            ],
                            staticClass:
                              "uk-button uk-button-default uk-button-large uk-align-right uk-visible@m",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.del()
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "ukid-icon",
                              attrs: { "uk-icon": "icon:trash;ratio:1.2" }
                            }),
                            _vm._v(
                              " \n                            Excluir Processo\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.registers.length,
                                expression: "registers.length"
                              }
                            ],
                            staticClass:
                              "uk-button uk-button-default uk-button-large uk-align-right uk-visible@m",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.finalize()
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "ukid-icon",
                              attrs: { "uk-icon": "icon:upload;ratio:1.3" }
                            }),
                            _vm._v(
                              " \n                            Finalizar Processo\n                        "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.registers.length,
                      expression: "registers.length"
                    }
                  ],
                  staticClass:
                    "uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-16"
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.del()
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "uk-margin-small-right uk-icon",
                        attrs: { "uk-icon": "trash" }
                      }),
                      _vm._v(
                        "\n                    Excluir Processo\n                "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.registers.length,
                      expression: "registers.length"
                    }
                  ],
                  staticClass:
                    "uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-15 ukid-font-size-16"
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.finalize()
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "uk-margin-small-right uk-icon",
                        attrs: { "uk-icon": "icon:upload;ratio:1.2" }
                      }),
                      _vm._v(
                        "\n                    Finaliz Processo\n                "
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-card-footer ukid-supress-border-top" }, [
            _c("div", { staticClass: "uk-flex uk-flex-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-flex uk-flex-center uk-grid-medium uk-text-center",
                  attrs: { "uk-grid": "" }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.registers.length,
                          expression: "!registers.length"
                        }
                      ],
                      staticClass:
                        "uk-alert-primary ukid-magin-left-35 ukid-margin-top-50 ukid-label-tuscan-color",
                      attrs: { "uk-alert": "" }
                    },
                    [
                      _c("p", { staticClass: "ukid-white-color" }, [
                        _c("span", {
                          staticClass: "uk-margin-small-right uk-icon",
                          attrs: { "uk-icon": "warning" }
                        }),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.App.education.noSession) +
                            "\n                        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.registers, function(session) {
                    return _c(
                      "div",
                      { key: session._id, staticClass: "ukid-width-300-px" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex-top ukid-photo-zindex",
                            attrs: {
                              id: "modal-media-image-" + session._id,
                              "uk-modal": ""
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ukid-light-blue-color uk-flex uk-flex-center uk-modal-dialog uk-width-auto uk-margin-auto-vertical ukid-photo-ead-effect"
                              },
                              [
                                _c("button", {
                                  staticClass: "uk-modal-close-outside",
                                  attrs: { type: "button", "uk-close": "" }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  attrs: {
                                    src:
                                      "data:image/png;base64," + session.photo,
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "uk-card uk-card-default uk-card-body ukid-width-300-px"
                          },
                          [
                            _c("div", { staticClass: "ukid-ead-effect" }, [
                              _c("img", {
                                staticClass: "ukid-ead-effect",
                                attrs: {
                                  src:
                                    "data:image/png;base64," +
                                    session.normalized
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-flex uk-flex-center uk-label ukid-label-blue-color uk-padding-small ukid-margin-negative-top-20"
                              },
                              [
                                _c(
                                  "table",
                                  { staticClass: "ukid-font-size-10" },
                                  [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          { attrs: { align: "right" } },
                                          [_vm._v("FFID:")]
                                        ),
                                        _vm._v(" "),
                                        _c("td", { attrs: { align: "left" } }, [
                                          _vm._v(_vm._s(session.ffid))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c(
                                          "th",
                                          { attrs: { align: "right" } },
                                          [_vm._v("N. Docto:")]
                                        ),
                                        _vm._v(" "),
                                        _c("td", { attrs: { align: "left" } }, [
                                          _vm._v(_vm._s(session.documentNumber))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c(
                                          "th",
                                          { attrs: { align: "right" } },
                                          [_vm._v("Score:")]
                                        ),
                                        _vm._v(" "),
                                        _c("td", { attrs: { align: "left" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("numberFormat")(
                                                session.confidence * 100,
                                                "00"
                                              )
                                            ) + "%"
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c(
                                          "th",
                                          { attrs: { align: "right" } },
                                          [_vm._v("Verificação:")]
                                        ),
                                        _vm._v(" "),
                                        _c("td", { attrs: { align: "left" } }, [
                                          _vm._v(
                                            _vm._s(
                                              session.verified
                                                ? "Passou"
                                                : "Não Passou"
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _vm._m(1, true),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td", { attrs: { colspan: "2" } }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ukid-white-color",
                                              attrs: {
                                                href:
                                                  "#modal-media-image-" +
                                                  session._id,
                                                "uk-toggle": ""
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "uk-margin-small-right uk-icon",
                                                attrs: { "uk-icon": "image" }
                                              }),
                                              _vm._v(
                                                "\n                                                    Live Foto\n                                                "
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.pagination.last_page,
                  "click-handler": _vm.list,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(2)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner",
          attrs: { "uk-spinner": "ratio:4" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon ukid-icon-tuscan-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:desktop;ratio:4.5" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-hidden" }, [
      _c("video", { attrs: { id: "ukid-camera-stream" } }),
      _vm._v(" "),
      _c("canvas", { attrs: { id: "ukid-canvas" } })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23d44ff2", { render: render, staticRenderFns: staticRenderFns })
  }
}