var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uk-child-width-expand@s ukid-background-white-color",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "ukid-add-fields-modal", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-body" }, [
            _c(
              "div",
              {
                staticClass:
                  "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent"
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "uk-margin" }, [
                  _c(
                    "span",
                    { staticClass: "uk-label ukid-primary-label-color" },
                    [_vm._v("Descrição do Campo")]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-inline ukid-width-100-percent" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "min:3|max:20",
                            expression: "'min:3|max:20'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.aditionalFileds.fieldDesc,
                            expression: "aditionalFileds.fieldDesc"
                          }
                        ],
                        staticClass:
                          "uk-input ukid-focus ukid-width-100-percent",
                        attrs: {
                          "c-data-vv-as": "field",
                          id: "descField-input_field",
                          name: "descField-input_field",
                          type: "text",
                          placeholder: "Descrição do campo sem espaços"
                        },
                        domProps: { value: _vm.aditionalFileds.fieldDesc },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.aditionalFileds,
                              "fieldDesc",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-margin" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-inline ukid-width-100-percent" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "min:3|max:20",
                            expression: "'min:3|max:20'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.aditionalFileds.fieldName,
                            expression: "aditionalFileds.fieldName"
                          }
                        ],
                        staticClass:
                          "uk-input ukid-focus ukid-width-100-percent",
                        attrs: {
                          "c-data-vv-as": "field",
                          id: "nameField-input_field",
                          name: "nameField-input_field",
                          type: "text",
                          placeholder: "Descrição do campo sem espaços"
                        },
                        domProps: { value: _vm.aditionalFileds.fieldName },
                        on: {
                          keydown: function($event) {
                            return _vm.checkAttributeName($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.aditionalFileds,
                              "fieldName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-margin" }, [
                  _c(
                    "span",
                    { staticClass: "uk-label ukid-primary-label-color" },
                    [_vm._v("Valor do Campo")]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-inline ukid-width-100-percent" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "min:3|max:100",
                            expression: "'min:3|max:100'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.aditionalFileds.fieldValue,
                            expression: "aditionalFileds.fieldValue"
                          }
                        ],
                        staticClass:
                          "uk-input ukid-focus ukid-width-100-percent",
                        attrs: {
                          "c-data-vv-as": "field",
                          id: "valueField-input_field",
                          name: "valueField-input_field",
                          type: "text",
                          placeholder: "Valor do campo"
                        },
                        domProps: { value: _vm.aditionalFileds.fieldValue },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.aditionalFileds,
                              "fieldValue",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-margin" }, [
                  _c(
                    "span",
                    { staticClass: "uk-label ukid-primary-label-color" },
                    [_vm._v("Tornar Campo Obrigatório?")]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aditionalFileds.required,
                          expression: "aditionalFileds.required"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "true",
                        type: "radio",
                        id: "valueFieldRequired-input_yes_field",
                        name: "value-field-required-type-input_field"
                      },
                      domProps: {
                        checked: _vm._q(_vm.aditionalFileds.required, "true")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.aditionalFileds,
                            "required",
                            "true"
                          )
                        }
                      }
                    }),
                    _vm._v(" SIM\n                        ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aditionalFileds.required,
                          expression: "aditionalFileds.required"
                        }
                      ],
                      staticClass: "uk-radio",
                      attrs: {
                        value: "false",
                        type: "radio",
                        id: "valueFieldRequired-input_no_field",
                        name: "value-field-required-type-input_field",
                        checked: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.aditionalFileds.required, "false")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.aditionalFileds,
                            "required",
                            "false"
                          )
                        }
                      }
                    }),
                    _vm._v(" NÃO\n                        ")
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v("Cancelar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.addField()
                  }
                }
              },
              [_vm._v("Adicionar")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "ukid-modal-person-overflow", "uk-modal": "" } },
        [
          _c("div", { staticClass: "uk-modal-dialog" }, [
            _c("button", {
              staticClass: "uk-modal-close-default",
              attrs: { type: "button", "uk-close": "" }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "uk-modal-body ukid-font-size-18",
                attrs: { "uk-overflow-auto": "" }
              },
              [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-default uk-modal-close",
                  attrs: { type: "button" }
                },
                [_vm._v(_vm._s(_vm.App.messages.popoUpButtonCancel))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-primary uk-modal-close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.del(Object.assign({}, _vm.personV4))
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.App.messages.popoUpButtonDelete))]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color",
          attrs: { id: "ukid-filter" }
        },
        [
          _c(
            "dl",
            {
              staticClass:
                "uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center"
            },
            [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl"
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ukid-padding ukid-padding-10px ukid-font-size-15"
                    },
                    [
                      _vm._v(
                        "\n                    Criação, atualização e exclusão de pessoas\n                    "
                      ),
                      _c("br"),
                      _vm._v(
                        "Total: " +
                          _vm._s(_vm.pagination.databaseCount) +
                          "\n                "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("br", { staticClass: "uk-hidden@m uk-hidden@l uk-hidden@xl" }),
              _vm._v(" "),
              _c("dt", [_vm._v("\n                Pesquisar\n            ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParam,
                      expression: "searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-width-80-percent",
                  attrs: {
                    id: "search-param-input",
                    type: "text",
                    placeholder: "Pesquise qualquer termo"
                  },
                  domProps: { value: _vm.searchParam },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchParam = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6",
                  attrs: { "uk-icon": "icon:search;ratio:1.4" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "uk-hidden ukid-search-spinner ukid-magin-left-6",
                  attrs: { "uk-spinner": "ratio:0.92" }
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.clear(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Iniciar Novo Registro\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.registers, function(personV4) {
                return _c("dt", { key: personV4.personV4Collection.id }, [
                  _c("div", { staticClass: "uk-flex uk-flex-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "uk-flex uk-flex-center ukid-height-90-px"
                      },
                      [
                        personV4.personV4Collection.principalCrop.snapshot &&
                        personV4.personV4Collection.highlightBlcFace
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ukid-light-red-color ukid-circular-person-dt ukid-circular-person-dt-resolution",
                                attrs: {
                                  title:
                                    "Esta pessoa foi marcada como ponto de atenção",
                                  "uk-tooltip": ""
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "ukid-circular-portrait",
                                  attrs: {
                                    src:
                                      personV4.personV4Collection.principalCrop
                                        .snapshot
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        personV4.personV4Collection.principalCrop.snapshot &&
                        !personV4.personV4Collection.highlightBlcFace
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ukid-light-blue-color ukid-circular-person-dt ukid-circular-person-dt-resolution"
                              },
                              [
                                !_vm.isBase64(
                                  personV4.personV4Collection.principalCrop
                                    .snapshot
                                )
                                  ? _c("img", {
                                      staticClass: "ukid-circular-portrait",
                                      attrs: {
                                        src:
                                          personV4.personV4Collection
                                            .principalCrop.snapshot
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isBase64(
                                  personV4.personV4Collection.principalCrop
                                    .snapshot
                                )
                                  ? _c("img", {
                                      staticClass: "ukid-circular-portrait",
                                      attrs: {
                                        src:
                                          "data:image/jpeg;base64," +
                                          personV4.personV4Collection
                                            .principalCrop.snapshot
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "uk-label ukid-width-55-percent ukid-magin-left-5 ukid-padding-top-15 ukid-font-size-10"
                      },
                      [
                        personV4.personV4Collection.checkedOnPublicDatabase ===
                        true
                          ? _c("span", [
                              _c("img", {
                                attrs: {
                                  title: "Verificado em base pública",
                                  "uk-tooltip": "",
                                  src:
                                    "content/images/checked-public-database-icon.png",
                                  width: "26"
                                }
                              }),
                              _vm._v(" "),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ukid-font-size-9 ukid-text-lowercase"
                          },
                          [_vm._v(_vm._s(personV4.personV4Collection.id))]
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(personV4.personV4Collection.id19)
                        ),
                        _c("br"),
                        _vm._v(" "),
                        personV4.personDocumentV4Collection.cardNumber
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  personV4.personDocumentV4Collection.cardNumber
                                )
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(personV4.personDocumentV4Collection.name)
                        ),
                        _c("br"),
                        _vm._v(" "),
                        !_vm.Strings.isNullOrEmpty(
                          personV4.personDocumentV4Collection.email
                        )
                          ? _c(
                              "a",
                              {
                                staticClass: "ukid-white-color uk-visible@s",
                                attrs: {
                                  href:
                                    "mailto:" +
                                    personV4.personDocumentV4Collection.email
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.truncate(
                                      personV4.personDocumentV4Collection.email,
                                      40
                                    )
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.Strings.isNullOrEmpty(
                          personV4.personDocumentV4Collection.email
                        )
                          ? _c(
                              "a",
                              {
                                staticClass: "ukid-white-color uk-hidden@s",
                                attrs: {
                                  href:
                                    "mailto:" +
                                    personV4.personDocumentV4Collection.email
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.truncate(
                                      personV4.personDocumentV4Collection.email,
                                      28
                                    )
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.Strings.isNullOrEmpty(
                          personV4.personDocumentV4Collection.email
                        )
                          ? _c("br")
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                new Date(
                                  personV4.personV4Collection.createTimestamp
                                ),
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            ) +
                            "\n                        "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "ukid-text-alert-color ukid-font-size-9"
                          },
                          [
                            _vm._v(
                              "proprietário: " +
                                _vm._s(personV4.personV4Collection.user)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "ukid-icon-yellow-color ukid-cursor-pointer",
                          attrs: {
                            href:
                              "#ukid-person-plus-animation" +
                              personV4.personV4Collection.id,
                            "uk-toggle":
                              "target: #ukid-person-plus-animation-" +
                              personV4.personV4Collection.id +
                              "; animation: uk-animation-fade; style: display:block;",
                            "uk-icon": "icon:plus;ratio:1.0"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "ukid-width-100-percent ukid-font-size-9",
                            attrs: {
                              hidden: "",
                              id:
                                "ukid-person-plus-animation-" +
                                personV4.personV4Collection.id
                            }
                          },
                          [
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              "\n                            Grupos dessa Pessoa\n                            "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _vm._l(_vm.groups, function(group) {
                              return _c("span", { key: group.id }, [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      personV4.personV4Collection.groupList &&
                                        personV4.personV4Collection.groupList.includes(
                                          group.name
                                        )
                                        ? "- " + group.description + "<br />"
                                        : ""
                                    )
                                  }
                                })
                              ])
                            })
                          ],
                          2
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ukid-margin-top-28" }, [
                      personV4
                        ? _c("span", {
                            staticClass:
                              "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                            attrs: { "uk-icon": "icon:file-edit;ratio:0.9" },
                            on: {
                              click: function($event) {
                                _vm.fill(Object.assign({}, personV4))
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      personV4
                        ? _c("span", {
                            staticClass:
                              "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                            attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                            on: {
                              click: function($event) {
                                _vm.modal(Object.assign({}, personV4))
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registers.length,
                  expression: "!registers.length"
                }
              ],
              staticClass:
                "uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30",
              attrs: { "uk-alert": "" }
            },
            [
              _c("span", {
                staticClass:
                  "uk-form-icon ukid-search-spinner ukid-magin-left-10 ukid-magin-right-10",
                attrs: { "uk-icon": "icon:ban" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ukid-magin-left-20" }, [
                _vm._v(
                  "\n                Nenhum registro encontrado\n            "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16"
            },
            [
              _vm._v("Pagina Atual: "),
              _c("b", { staticClass: "ukid-magin-left-10" }, [
                _vm._v(_vm._s(_vm.page))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.pagination.last_page,
                  "click-handler": _vm.list,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-90-percent": _vm.Commons.isMobile(),
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent": !_vm.Commons.isMobile()
          },
          attrs: { id: "ukid-card-form" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-card-header ukid-supress-border-bottom uk-visible@m"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-150",
                  attrs: { "uk-grid": "" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "uk-width-auto ukid-min-width-165" },
                    [
                      _c("span", {
                        staticClass: "uk-hidden ukid-spinner",
                        attrs: { "uk-spinner": "ratio:4" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ukid-icon ukid-width-136-px" },
                        [
                          !(
                            "snapshot" in
                            _vm.personV4.personV4Collection.principalCrop
                          ) ||
                          _vm.isBase64(
                            _vm.personV4.personV4Collection.principalCrop
                              .snapshot
                          )
                            ? _c("span", {
                                staticClass:
                                  "ukid-icon ukid-icon-green-color ukid-magin-left-5 ukid-margin-top-6",
                                attrs: { "uk-icon": "icon:users;ratio:5.2" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.personV4.personV4Collection.principalCrop
                            .snapshot &&
                          !_vm.isBase64(
                            _vm.personV4.personV4Collection.principalCrop
                              .snapshot
                          ) &&
                          _vm.personV4.personV4Collection.highlightBlcFace
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ukid-light-red-color ukid-circular-person-edit ukid-circular-person-dt-resolution ukid-circular-person-edit-resolution",
                                  attrs: {
                                    title:
                                      "Esta pessoa foi marcada como ponto de atenção",
                                    "uk-tooltip": ""
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "ukid-circular-portrait",
                                    attrs: {
                                      src:
                                        _vm.personV4.personV4Collection
                                          .principalCrop.snapshot,
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.personV4.personV4Collection.principalCrop
                            .snapshot &&
                          !_vm.isBase64(
                            _vm.personV4.personV4Collection.principalCrop
                              .snapshot
                          ) &&
                          !_vm.personV4.personV4Collection.highlightBlcFace
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ukid-light-blue-color ukid-circular-person-edit ukid-circular-person-dt-resolution ukid-circular-person-edit-resolution"
                                },
                                [
                                  _c("img", {
                                    staticClass: "ukid-circular-portrait",
                                    attrs: {
                                      src:
                                        _vm.personV4.personV4Collection
                                          .principalCrop.snapshot,
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c("span", { staticClass: "ukid-font-size-18" }, [
                      _vm._v("Gestão de Pessoas")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", [
                        _vm._v(
                          "\n                            Criação, atualização e exclusão"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                            Módulo responsável pelo cadastramento facial de pessoas"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                            As galerias atuam como um segmentador de faces, ao selecioná-la, tenha certeza de sua escolha\n                            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-label-green-color" },
                          [
                            _vm._v(
                              "Pessoas: " + _vm._s(_vm.pagination.databaseCount)
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("progress", {
            staticClass:
              "uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0",
            attrs: { value: "0", max: "100" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "uk-card-footer ukid-supress-border-top": !_vm.Commons.isMobile(),
                "uk-card-footer ukid-supress-border-top ukid-card-footer-mobile": _vm.Commons.isMobile()
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "ukid-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "uk-fieldset" },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.clear(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Iniciar Novo Registro\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(5)
                      ]),
                      _vm._v(" "),
                      !_vm.isBase64(
                        _vm.personV4.personV4Collection.principalCrop.snapshot
                      )
                        ? _c(
                            "div",
                            {
                              staticClass: "uk-hidden@m ukid-width-136-px",
                              attrs: { align: "center" }
                            },
                            [
                              _vm.personV4.personV4Collection.principalCrop
                                .snapshot &&
                              _vm.personV4.personV4Collection.highlightBlcFace
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ukid-light-red-color ukid-circular-person-edit",
                                      attrs: {
                                        title:
                                          "Esta pessoa foi marcada como ponto de atenção",
                                        "uk-tooltip": ""
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ukid-circular-portrait",
                                        attrs: {
                                          src:
                                            _vm.personV4.personV4Collection
                                              .principalCrop.snapshot,
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.personV4.personV4Collection.principalCrop
                                .snapshot &&
                              !_vm.personV4.personV4Collection.highlightBlcFace
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ukid-light-blue-color ukid-circular-person-edit"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ukid-circular-portrait",
                                        attrs: {
                                          src:
                                            _vm.personV4.personV4Collection
                                              .principalCrop.snapshot,
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.visibleSnaphost,
                              expression: "visibleSnaphost"
                            }
                          ],
                          staticClass: "uk-margin ukid-margin-bottom-50"
                        },
                        [
                          _c("div", { staticClass: "ukid-video-camera" }, [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("span", { staticClass: "ukid-video-buttom" }, [
                              _c("span", {
                                staticClass: "ukid-cursor-pointer",
                                attrs: { "uk-icon": "icon:camera;ratio:2.7" },
                                on: { click: _vm.takeSnapshot }
                              })
                            ]),
                            _vm._v(" "),
                            _vm._m(7)
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.personV4.personV4Collection.id,
                              expression: "personV4.personV4Collection.id"
                            }
                          ],
                          staticClass: "uk-margin"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "uk-label ukid-primary-label-color"
                            },
                            [_vm._v("ID de Registro")]
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline ukid-width-100-percent ukid-font-size-16"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.personV4.personV4Collection.id)
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.personV4.personV4Collection.ffid,
                              expression: "personV4.personV4Collection.ffid"
                            }
                          ],
                          staticClass: "uk-margin"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "uk-label ukid-primary-label-color"
                            },
                            [_vm._v("Face ID")]
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline ukid-width-100-percent ukid-font-size-16"
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.personV4.personV4Collection.ffid)
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.visibleSnaphost,
                              expression: "!visibleSnaphost"
                            }
                          ],
                          staticClass: "uk-margin"
                        },
                        [
                          _vm._m(8),
                          _vm._v(" "),
                          _c("br", { staticClass: "uk-visible@s" }),
                          _vm._v(" "),
                          _vm._m(9),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-inline ukid-container",
                              attrs: {
                                title:
                                  "Clique para utilizar selecionar um arquivo",
                                "uk-tooltip": ""
                              }
                            },
                            [
                              _c("base64-upload", {
                                attrs: {
                                  imageStyle: {
                                    "-moz-opacity": "0.90",
                                    filter: "alpha(opacity=90)",
                                    "-khtml-opacity": "0.90",
                                    opacity: "0.90",
                                    "border-radius": "3%",
                                    width: "40%"
                                  },
                                  imageSrc:
                                    "../../content/images/photo-icon.png"
                                },
                                on: { change: _vm.onChangeImage }
                              }),
                              _vm._v(" "),
                              _c("canvas", { staticClass: "canvas uk-hidden" })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.visibleSnaphost && !_vm.Commons.isMobile(),
                            expression:
                              "!visibleSnaphost && !Commons.isMobile()"
                          }
                        ],
                        staticClass:
                          "ukid-icon-tuscan-color ukid-cursor-pointer",
                        attrs: {
                          title: "Clique para utilizar a WebCam",
                          "uk-tooltip": "",
                          "uk-icon": "icon:camera;ratio:2.7"
                        },
                        on: { click: _vm.startCamera }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _vm._m(10),
                        _vm._v(" "),
                        _vm._m(11),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "ukid-width-100-percent": _vm.errors.has(
                                    "enabled-input_field"
                                  ),
                                  "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                    "enabled-input_field"
                                  )
                                }
                              },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.personV4.personV4Collection
                                            .twoFactorAuthentication,
                                        expression:
                                          "personV4.personV4Collection.twoFactorAuthentication"
                                      }
                                    ],
                                    staticClass: "uk-radio",
                                    attrs: {
                                      value: "true",
                                      type: "radio",
                                      id:
                                        "twoFactor-authentication-input-yes_field",
                                      name:
                                        "twoFactor-authentication-type-input_field"
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.personV4.personV4Collection
                                          .twoFactorAuthentication,
                                        "true"
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.personV4.personV4Collection,
                                          "twoFactorAuthentication",
                                          "true"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    " SIM\n                                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.personV4.personV4Collection
                                            .twoFactorAuthentication,
                                        expression:
                                          "personV4.personV4Collection.twoFactorAuthentication"
                                      }
                                    ],
                                    staticClass: "uk-radio",
                                    attrs: {
                                      value: "false",
                                      type: "radio",
                                      id:
                                        "twoFactor-authentication-type-input-no_field",
                                      name:
                                        "twoFactor-authentication-type-input_field",
                                      checked: ""
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.personV4.personV4Collection
                                          .twoFactorAuthentication,
                                        "false"
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$set(
                                          _vm.personV4.personV4Collection,
                                          "twoFactorAuthentication",
                                          "false"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    " NÃO\n                                "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _vm._m(12),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.personV4.personV4Collection
                                  .recordMarkedForAlert,
                              expression:
                                "personV4.personV4Collection.recordMarkedForAlert"
                            }
                          ],
                          ref: "recordMarkedForAlertEl",
                          staticClass: "uk-checkbox ukid-margin-top-10",
                          attrs: {
                            "true-value": "true",
                            "false-value": "false",
                            type: "checkbox"
                          },
                          domProps: {
                            checked:
                              _vm.personV4.personV4Collection
                                .recordMarkedForAlert,
                            checked: Array.isArray(
                              _vm.personV4.personV4Collection
                                .recordMarkedForAlert
                            )
                              ? _vm._i(
                                  _vm.personV4.personV4Collection
                                    .recordMarkedForAlert,
                                  null
                                ) > -1
                              : _vm._q(
                                  _vm.personV4.personV4Collection
                                    .recordMarkedForAlert,
                                  "true"
                                )
                          },
                          on: {
                            change: function($event) {
                              var $$a =
                                  _vm.personV4.personV4Collection
                                    .recordMarkedForAlert,
                                $$el = $event.target,
                                $$c = $$el.checked ? "true" : "false"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.personV4.personV4Collection,
                                      "recordMarkedForAlert",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.personV4.personV4Collection,
                                      "recordMarkedForAlert",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.personV4.personV4Collection,
                                  "recordMarkedForAlert",
                                  $$c
                                )
                              }
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _vm._m(13),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.personV4.personV4Collection
                                  .highlightBlcFace,
                              expression:
                                "personV4.personV4Collection.highlightBlcFace"
                            }
                          ],
                          ref: "recordHighlightBlcFace",
                          staticClass: "uk-checkbox ukid-margin-top-10",
                          attrs: {
                            "true-value": "true",
                            "false-value": "false",
                            type: "checkbox"
                          },
                          domProps: {
                            checked:
                              _vm.personV4.personV4Collection.highlightBlcFace,
                            checked: Array.isArray(
                              _vm.personV4.personV4Collection.highlightBlcFace
                            )
                              ? _vm._i(
                                  _vm.personV4.personV4Collection
                                    .highlightBlcFace,
                                  null
                                ) > -1
                              : _vm._q(
                                  _vm.personV4.personV4Collection
                                    .highlightBlcFace,
                                  "true"
                                )
                          },
                          on: {
                            change: function($event) {
                              var $$a =
                                  _vm.personV4.personV4Collection
                                    .highlightBlcFace,
                                $$el = $event.target,
                                $$c = $$el.checked ? "true" : "false"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.personV4.personV4Collection,
                                      "highlightBlcFace",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.personV4.personV4Collection,
                                      "highlightBlcFace",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.personV4.personV4Collection,
                                  "highlightBlcFace",
                                  $$c
                                )
                              }
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-primary-label-color" },
                          [
                            _vm._v(
                              "\n                            Acesso Permanente?\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("input", {
                          staticClass:
                            "ukid-permanent-access uk-checkbox ukid-margin-top-10",
                          attrs: { type: "checkbox" },
                          on: {
                            click: function($event) {
                              return _vm.permanentAccess()
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      !_vm.Commons.isMobile()
                        ? _c(
                            "div",
                            {
                              class: {
                                "uk-margin ukid-margin-bottom-40": !_vm.permAccess,
                                "uk-margin uk-hidden ukid-margin-bottom-40":
                                  _vm.permAccess
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-primary-label-color"
                                },
                                [_vm._v("Intervalo de Acesso")]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("div", { attrs: { "uk-grid": "" } }, [
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      "\n                                DE:\n                                "
                                    ),
                                    _c("datetime", {
                                      attrs: {
                                        type: "datetime",
                                        "input-class":
                                          "mx-input ukid-width-100-percent",
                                        "value-zone": "America/Sao_Paulo",
                                        zone: "America/Sao_Paulo",
                                        format: "dd/MM/yyyy HH:mm",
                                        phrases: {
                                          ok: "Definir Hora",
                                          cancel: "Sair"
                                        },
                                        "hour-step": 1,
                                        "minute-step": 5,
                                        "min-datetime": new Date(
                                          new Date().setMinutes(
                                            new Date().getMinutes() - 5
                                          )
                                        ).toISOString(),
                                        "max-datetime":
                                          _vm.personV4.personV4Collection
                                            .accessEndDatetime,
                                        "week-start": 7,
                                        auto: true,
                                        "use24-hour": ""
                                      },
                                      model: {
                                        value:
                                          _vm.personV4.personV4Collection
                                            .accessStartDatetime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.personV4.personV4Collection,
                                            "accessStartDatetime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "personV4.personV4Collection.accessStartDatetime"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      "\n                                ATÉ:\n                                "
                                    ),
                                    _c("datetime", {
                                      attrs: {
                                        type: "datetime",
                                        "input-class":
                                          "mx-input ukid-width-100-percent",
                                        "value-zone": "America/Sao_Paulo",
                                        zone: "America/Sao_Paulo",
                                        format: "dd/MM/yyyy HH:mm",
                                        phrases: {
                                          ok: "Definir Hora",
                                          cancel: "Sair"
                                        },
                                        "hour-step": 1,
                                        "minute-step": 5,
                                        "min-datetime": new Date(
                                          new Date().setHours(
                                            new Date().getHours() + 1
                                          )
                                        ).toISOString(),
                                        "week-start": 7,
                                        auto: true,
                                        "use24-hour": ""
                                      },
                                      model: {
                                        value:
                                          _vm.personV4.personV4Collection
                                            .accessEndDatetime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.personV4.personV4Collection,
                                            "accessEndDatetime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "personV4.personV4Collection.accessEndDatetime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.Commons.isMobile()
                        ? _c(
                            "div",
                            {
                              class: {
                                "uk-margin ukid-margin-bottom-40": !_vm.permAccess,
                                "uk-margin uk-hidden ukid-margin-bottom-40":
                                  _vm.permAccess
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-primary-label-color"
                                },
                                [_vm._v("Intervalo de Acesso")]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                        DE:\n                        "
                              ),
                              _c("datetime", {
                                attrs: {
                                  type: "datetime",
                                  "input-class":
                                    "mx-input ukid-width-90-percent",
                                  "value-zone": "America/Sao_Paulo",
                                  zone: "America/Sao_Paulo",
                                  format: "dd/MM/yyyy HH:mm",
                                  phrases: {
                                    ok: "Definir Hora",
                                    cancel: "Sair"
                                  },
                                  "hour-step": 1,
                                  "minute-step": 5,
                                  "min-datetime": new Date(
                                    new Date().setMinutes(
                                      new Date().getMinutes() - 5
                                    )
                                  ).toISOString(),
                                  "max-datetime":
                                    _vm.personV4.personV4Collection
                                      .accessEndDatetime,
                                  "week-start": 7,
                                  auto: true,
                                  "use24-hour": ""
                                },
                                model: {
                                  value:
                                    _vm.personV4.personV4Collection
                                      .accessStartDatetime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.personV4.personV4Collection,
                                      "accessStartDatetime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "personV4.personV4Collection.accessStartDatetime"
                                }
                              }),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                        ATÉ:\n                        "
                              ),
                              _c("datetime", {
                                attrs: {
                                  type: "datetime",
                                  "input-class":
                                    "mx-input ukid-width-90-percent",
                                  "value-zone": "America/Sao_Paulo",
                                  zone: "America/Sao_Paulo",
                                  format: "dd/MM/yyyy HH:mm",
                                  phrases: {
                                    ok: "Definir Hora",
                                    cancel: "Sair"
                                  },
                                  "hour-step": 1,
                                  "minute-step": 5,
                                  "min-datetime": new Date(
                                    new Date().setHours(
                                      new Date().getHours() + 1
                                    )
                                  ).toISOString(),
                                  "week-start": 7,
                                  auto: true,
                                  "use24-hour": ""
                                },
                                model: {
                                  value:
                                    _vm.personV4.personV4Collection
                                      .accessEndDatetime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.personV4.personV4Collection,
                                      "accessEndDatetime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "personV4.personV4Collection.accessEndDatetime"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.workShift
                        ? _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "js-upload uk-placeholder uk-text-left"
                              },
                              [
                                _vm._m(14),
                                _vm._v(" "),
                                _vm._m(15),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ukid-margin-top-40",
                                    attrs: { "uk-form-custom": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.errors.has(
                                                "entryTime-input_field"
                                              ) ||
                                              _vm.errors.has(
                                                "exitTime-input_field"
                                              ),
                                            expression:
                                              "errors.has('entryTime-input_field') || errors.has('exitTime-input_field')"
                                          }
                                        ],
                                        staticClass:
                                          "ukid-width-80-percent uk-alert-danger",
                                        attrs: { "uk-alert": "" }
                                      },
                                      [
                                        _c("b", [_vm._v("ATENÇÃO:")]),
                                        _vm._v(
                                          " Os 2 campos abaixo são obrigatórios\n                                    "
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                    Se não estiver usando os campos abaixo, o mesmos podem ser desabilitados \n                                    nas "
                                        ),
                                        _c(
                                          "router-link",
                                          { attrs: { to: "/user/register" } },
                                          [_vm._v("configurações do usuário")]
                                        ),
                                        _vm._v(
                                          ' em \n                                    "HABILITAR PREENCHIMENTO DE TURNO NO CADASTRO DE PESSOA"\n                                '
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.Commons.isMobile()
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "uk-margin ukid-margin-bottom-20"
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  "\n                                        HORÁRIO DE ENTRADA:\n                                        "
                                                ),
                                                _c("datetime", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  staticStyle: {
                                                    width: "150px !important"
                                                  },
                                                  attrs: {
                                                    name:
                                                      "entryTime-input_field",
                                                    type: "time",
                                                    "input-class":
                                                      "mx-input ukid-width-90-percent",
                                                    "value-zone":
                                                      "America/Sao_Paulo",
                                                    zone: "America/Sao_Paulo",
                                                    format: "HH:mm",
                                                    phrases: {
                                                      ok: "Definir Hora",
                                                      cancel: "Sair"
                                                    },
                                                    "hour-step": 1,
                                                    "minute-step": 1,
                                                    auto: true,
                                                    "use24-hour": ""
                                                  },
                                                  model: {
                                                    value: _vm.entryTime,
                                                    callback: function($$v) {
                                                      _vm.entryTime = $$v
                                                    },
                                                    expression: "entryTime"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  "\n                                        HORÁRIO DE SAÍDA:\n                                        "
                                                ),
                                                _c("datetime", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  staticStyle: {
                                                    width: "150px !important"
                                                  },
                                                  attrs: {
                                                    name:
                                                      "exitTime-input_field",
                                                    type: "time",
                                                    "input-class":
                                                      "mx-input ukid-width-90-percent",
                                                    "value-zone":
                                                      "America/Sao_Paulo",
                                                    zone: "America/Sao_Paulo",
                                                    format: "HH:mm",
                                                    phrases: {
                                                      ok: "Definir Hora",
                                                      cancel: "Sair"
                                                    },
                                                    "hour-step": 1,
                                                    "minute-step": 1,
                                                    auto: true,
                                                    "use24-hour": ""
                                                  },
                                                  model: {
                                                    value: _vm.exitTime,
                                                    callback: function($$v) {
                                                      _vm.exitTime = $$v
                                                    },
                                                    expression: "exitTime"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm.Commons.isMobile()
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "uk-margin ukid-margin-bottom-20"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        HORÁRIO DE ENTRADA:\n                                        "
                                              ),
                                              _c("datetime", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "time",
                                                  "input-class":
                                                    "mx-input ukid-width-90-percent",
                                                  "value-zone":
                                                    "America/Sao_Paulo",
                                                  zone: "America/Sao_Paulo",
                                                  format: "HH:mm",
                                                  phrases: {
                                                    ok: "Definir Hora",
                                                    cancel: "Sair"
                                                  },
                                                  "hour-step": 1,
                                                  "minute-step": 1,
                                                  auto: true,
                                                  "use24-hour": ""
                                                },
                                                model: {
                                                  value: _vm.entryTime,
                                                  callback: function($$v) {
                                                    _vm.entryTime = $$v
                                                  },
                                                  expression: "entryTime"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(
                                                "\n                                        HORÁRIO DE SAÍDA:\n                                        "
                                              ),
                                              _c("datetime", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "time",
                                                  "input-class":
                                                    "mx-input ukid-width-90-percent",
                                                  "value-zone":
                                                    "America/Sao_Paulo",
                                                  zone: "America/Sao_Paulo",
                                                  format: "HH:mm",
                                                  phrases: {
                                                    ok: "Definir Hora",
                                                    cancel: "Sair"
                                                  },
                                                  "hour-step": 1,
                                                  "minute-step": 1,
                                                  auto: true,
                                                  "use24-hour": ""
                                                },
                                                model: {
                                                  value: _vm.exitTime,
                                                  callback: function($$v) {
                                                    _vm.exitTime = $$v
                                                  },
                                                  expression: "exitTime"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-primary-label-color" },
                          [_vm._v("Número do Documento")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.personV4.personV4Collection.id19,
                                  expression: "personV4.personV4Collection.id19"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:3|max:20|numeric",
                                  expression: "'required|min:3|max:20|numeric'"
                                }
                              ],
                              class: _vm.defaultClass(
                                "uk-input ukid-focus ",
                                _vm.errors.has("cpf-input_field")
                              ),
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "cpf-input_field",
                                name: "cpf-input_field",
                                type: "text",
                                placeholder:
                                  "Máximo 20 caracteres com somente números"
                              },
                              domProps: {
                                value: _vm.personV4.personV4Collection.id19
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.personV4.personV4Collection,
                                    "id19",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-primary-label-color" },
                          [_vm._v("Número do Cartão de Acesso")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.personV4.personDocumentV4Collection
                                      .cardNumber,
                                  expression:
                                    "personV4.personDocumentV4Collection.cardNumber"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:1|max:40|numeric",
                                  expression: "'min:1|max:40|numeric'"
                                }
                              ],
                              class: _vm.defaultClass(
                                "uk-input ukid-focus ",
                                _vm.errors.has("cardNumber-input_field")
                              ),
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "cardNumber-input_field",
                                name: "cardNumber-input_field",
                                type: "text",
                                placeholder:
                                  "Não obrigatório, mínimo 1, máximo 40 caracteres com somente números"
                              },
                              domProps: {
                                value:
                                  _vm.personV4.personDocumentV4Collection
                                    .cardNumber
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.personV4.personDocumentV4Collection,
                                    "cardNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-primary-label-color" },
                          [_vm._v("Nome")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:user" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.personV4.personDocumentV4Collection
                                      .name,
                                  expression:
                                    "personV4.personDocumentV4Collection.name"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:5|max:80",
                                  expression: "'required|min:5|max:80'"
                                }
                              ],
                              class: _vm.defaultClass(
                                "uk-input ",
                                _vm.errors.has("name-input_field")
                              ),
                              attrs: {
                                "data-vv-as": "field",
                                id: "name-input_field",
                                name: "name-input_field",
                                type: "text",
                                placeholder: "Mínimo 5, máximo 80 caracteres"
                              },
                              domProps: {
                                value:
                                  _vm.personV4.personDocumentV4Collection.name
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.personV4.personDocumentV4Collection,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-primary-label-color" },
                          [_vm._v("Email")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:mail" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.personV4.personDocumentV4Collection
                                      .email,
                                  expression:
                                    "personV4.personDocumentV4Collection.email"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:10|max:100|email",
                                  expression: "'min:10|max:100|email'"
                                }
                              ],
                              class: _vm.defaultClass(
                                "uk-input ",
                                _vm.errors.has("email-input_field")
                              ),
                              attrs: {
                                "data-vv-as": "field",
                                id: "email-input_field",
                                name: "email-input_field",
                                type: "text",
                                placeholder:
                                  "Não obrigatório, mínimo 10, máximo 100 caracteres"
                              },
                              domProps: {
                                value:
                                  _vm.personV4.personDocumentV4Collection.email
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.personV4.personDocumentV4Collection,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { ref: "ukidAddedFields" },
                        _vm._l(_vm.jokers.jokerList, function(jks) {
                          return _c("div", { key: jks.fieldName }, [
                            _c(
                              "div",
                              {
                                staticClass: "uk-margin",
                                attrs: { id: "ukid-add-field-" + jks.fieldName }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "uk-label ukid-primary-label-color"
                                  },
                                  [_vm._v(_vm._s(jks.fieldDesc))]
                                ),
                                _c("br"),
                                _vm._v(" "),
                                jks.required
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: jks.fieldValue,
                                          expression: "jks.fieldValue"
                                        }
                                      ],
                                      class: _vm.errors.has(
                                        jks.fieldName + "-input_field"
                                      )
                                        ? "uk-input ukid-form-error ukid-width-80-percent"
                                        : "uk-input ukid-width-80-percent",
                                      attrs: {
                                        "data-validate": "'required'",
                                        type: "text",
                                        "aria-required": "true",
                                        "aria-invalid": "true",
                                        "data-vv-as": "field",
                                        id: jks.fieldName + "-input_field",
                                        name: jks.fieldName + "-input_field",
                                        "data-value": jks.fieldValue
                                      },
                                      domProps: { value: jks.fieldValue },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            jks,
                                            "fieldValue",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: jks.fieldValue,
                                          expression: "jks.fieldValue"
                                        }
                                      ],
                                      staticClass:
                                        "uk-input ukid-width-80-percent",
                                      attrs: {
                                        type: "text",
                                        "aria-required": "true",
                                        "aria-invalid": "true",
                                        "data-vv-as": "field",
                                        id: jks.fieldName + "-input_field",
                                        name: jks.fieldName + "-input_field",
                                        "data-value": jks.fieldValue
                                      },
                                      domProps: { value: jks.fieldValue },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            jks,
                                            "fieldValue",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass:
                                    "ukid-disable-color ukid-cursor-pointer",
                                  attrs: {
                                    title: "Temporariamente desabilitado",
                                    "uk-tooltip": "",
                                    "uk-icon": "icon:trash;ratio:2.2"
                                  }
                                })
                              ]
                            )
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("group-component", {
                        ref: "groupCpnt",
                        attrs: {
                          id: "group-select_field_1",
                          name: "group-select_field_1",
                          required: "required",
                          label: "Grupos",
                          description:
                            "Ao selecionar um ou mais grupos para a pessoa, a mesma<br /> ficará disponível para todos <br />usuário(s) que tenham permissão no(s) grupo(s) selecionados(s), exceto grupos padrão de sistema",
                          app: _vm.App,
                          strings: _vm.Strings,
                          commons: _vm.Commons,
                          vm: _vm.vm,
                          entity: _vm.personV4.personV4Collection
                        }
                      }),
                      _vm._v(" "),
                      _c("users-component", {
                        ref: "usersCpnt",
                        attrs: {
                          id: "users-select_field_1",
                          name: "users-select_field_1",
                          required: "required",
                          label: "Usuário",
                          app: _vm.App,
                          strings: _vm.Strings,
                          commons: _vm.Commons,
                          vm: _vm.vm,
                          entity: _vm.personV4.personV4Collection
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uk-flex uk-flex-right uk-visible@s" },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-visible@m" },
                            [
                              _c("router-link", { attrs: { to: "/home" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "uk-button uk-button-default uk-button-large uk-align-center"
                                  },
                                  [_vm._v("Cancelar")]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-hidden@m" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-default uk-button-large uk-align-center",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(16)
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40"
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(17)
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-margin ukid-font-size-10" }, [
      _vm._v(
        "\n                        Ao adicionar um campo nesta sessão o mesmo ficará disponível para todos os próximos cadastros\n                        "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n                        Campos que podem ser criados e que são reconhecidos pelo unike.CORE:"
      ),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-text-alert-color" }, [
        _vm._v(" - pisNumber")
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-text-alert-color" }, [
        _vm._v(" - phoneNumber")
      ]),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n                        Para usar um dos campos acima, basta inserir a descrição acima em "NOME DO CAMPO"\n                    '
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
      _vm._v("\n                            Nome do Campo"),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-font-size-10" }, [
        _vm._v(
          '\n                                Somente letras, números e underscore "_"\n                            '
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "ukid-modal-invite", "uk-modal": "" } }, [
      _c("div", { staticClass: "uk-modal-dialog" }, [
        _c("button", {
          staticClass: "uk-modal-close-default",
          attrs: { type: "button", "uk-close": "" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "uk-modal-header" }, [
          _c("h2", { staticClass: "uk-modal-title" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-modal-body" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner-sm",
          attrs: { "uk-spinner": "ratio:2.6" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon-sm ukid-icon-green-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:users;ratio:3.6" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "uk-legend" }, [
      _c("span", { staticClass: "ukid-font-size-25 uk-visible@m" }, [
        _vm._v("Formulário")
      ]),
      _vm._v(" "),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "uk-align-right",
        attrs: { href: "#ukid-add-fields-modal", "uk-toggle": "" }
      },
      [
        _c("span", {
          staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
          attrs: { "uk-icon": "icon:plus;ratio:2.2" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-video-text" }, [
      _vm._v(
        "\n                                Certifique-se que toda a face esteja no frame,"
      ),
      _c("br"),
      _vm._v("não faltando nenhuma das partes\n                            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("canvas", {
        staticClass: "uk-hidden",
        attrs: { id: "ukid-camera-canvas", width: "640", height: "480" }
      }),
      _vm._v(" "),
      _c("video", {
        staticStyle: { "border-radius": "20px" },
        attrs: {
          id: "ukid-camera-stream",
          crossorigin: "anonymous",
          width: "640",
          height: "480"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _vm._v(
          "\n                            Clique na imagem circular para carregar uma foto\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            Clique na câmera para carregar a webcam\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                            Clique na imagem para"),
        _c("br"),
        _vm._v("carregar uma foto\n                        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v(
          "\n                            Duplo Fator de Autenticaçãos\n                            "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-text-alert-color" }, [
          _vm._v("\n                                OBS: Valor padrão NÃO"),
          _c("br"),
          _vm._v(
            "\n                                Para SIM, consulte a equipe da"
          ),
          _c("br"),
          _vm._v(
            "\n                                unike.TECH para maiores esclarecimentos\n                            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _vm._v(
          "\n                            Duplo Fator de Autenticação\n                            "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-text-alert-color" }, [
          _vm._v("\n                                OBS: Valor padrão NÃO"),
          _c("br"),
          _vm._v(
            "\n                                Para SIM, consulte a equipe da"
          ),
          _c("br"),
          _vm._v(
            "\n                                unike.TECH para maiores esclarecimentos\n                            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
      _vm._v(
        "\n                            Marcar Registro para Envio de Alerta\n                            "
      ),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-font-size-9 ukid-text-alert-color" }, [
        _vm._v("\n                                OBS: Quando marcado, envia"),
        _c("br"),
        _vm._v(
          "\n                                alertas por mensagens SMS / Whatsapp"
        ),
        _c("br"),
        _vm._v(
          "\n                                quando a pessoa é identificada\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
      _vm._v(
        "\n                            Cadastrar Registro em BLC\n                            "
      ),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-font-size-9 ukid-text-alert-color" }, [
        _vm._v(
          "\n                                OBS: Quando marcado, o registro"
        ),
        _c("br"),
        _vm._v("\n                                vai também para uma BLC."),
        _c("br"),
        _vm._v(
          "\n                                Quando identificado ficará marcado."
        ),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "uk-text-center", attrs: { "uk-grid": "" } },
      [
        _c("div", { staticClass: "uk-width-auto@m" }, [
          _c("img", {
            attrs: {
              src: "content/images/app-icon-ponto.png",
              width: "70",
              alt: ""
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-1-4@m" }, [
          _c(
            "div",
            { staticClass: "uk-text-left uk-text-bold ukid-font-size-15" },
            [
              _vm._v(
                "\n                                        Preencha os campos abaixo somente se estiver usando unike.PONTO\n                                    "
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ukid-text-alert-color ukid-margin-top-30" },
      [
        _vm._v(
          "\n                                Preencha os campos abaixo com cuidado, os mesmos serão\n                                "
        ),
        _c("br"),
        _vm._v(
          "\n                                utilizados para geração do arquivo de ponto (AFD)\n                            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-save uk-button-default uk-button-large uk-margin-left",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0a8da202", { render: render, staticRenderFns: staticRenderFns })
  }
}