import { Commons } from './common';

/**
 * name: Daniel Fonseca
 * date:  25/12/2018
 * version: 1.0
 */
export var cm = {

    matcherEndpoint: '',
    matcherToken: '',
    documentNumber: '',
    width: 640,
    height: 640,
    stream: undefined,
    worker: undefined,

    validateBrowser: function() {

        navigator.getMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)

        var video = document.querySelector('#ukid-camera-stream')


        // Success Callback
        var funSuccess = function(stream) {

            // Create an object URL for the video stream and
            // set it as src of our HTLM video element.
            try {
                video.src = window.URL.createObjectURL(stream)
            } catch (error) { 
                console.info(error)                   
                video.srcObject = stream                        
            }                    

            // Play the video element to start the stream.
            // video.play()

            cm.stream = stream
            
        }
        
        // Error Callback
        var funError = function(err) {
            alert("There was an error with accessing the camera stream: " + err.name, err);
        }

        if (!navigator.getMedia && !navigator.mediaDevices) {

            alert("Your browser doesn't have support for the navigator.getUserMedia interface.");

        } else {

            if (!navigator.mediaDevices) {

                // Request the camera
                navigator.getMedia(

                    {
                        video: true
                    },

                    funSuccess,

                    funError

                );

            } else {

                navigator.mediaDevices.getUserMedia({

                        video: true

                }).then(

                    funSuccess, 

                    funError

                );                

            }

        }

    },

    startCameraProcess: function() {

        var video =  document.querySelector('#ukid-camera-stream')

        // Success Callback
        var funSuccess = function(stream) {
            
            try {
                video.src = window.URL.createObjectURL(stream)
            } catch (error) { 
                console.info(error)                   
                video.srcObject = stream                        
            } 

            cm.stream = stream

            // Play the video element to show the stream to the user.
            video.play()

        }
        
        // Error Callback
        var funError = function(err) {

            // Most common errors are PermissionDenied and DevicesNotFound.
            console.error(err)

        }

        if (!navigator.mediaDevices) {

            navigator.getUserMedia(
            
                // Options
                {
                    video: true
                },
    
                funSuccess,
            
                funError
    
            )

        } else {

            navigator.mediaDevices.getUserMedia({

                    video: true

            }).then(

                funSuccess, 

                funError

            );

        }

    
    },

    startCapture: function() {

        if (typeof(Worker) !== "undefined") {

            if (typeof(worker) == "undefined") {

                cm.worker = new Worker('./app/js/camerawk.js') 

                cm.worker.onmessage = function(event) {

                    cm.takeSnapshot(cm.documentNumber)

                }

            }

        } else {

            alert('Sorry... No Web Worker support')

        }

    },

    takeSnapshot: function(documentNumber) {

        var p = 0.000001; 
        var min = 3000 + p, max = 100000 - p;

        setTimeout(function() {

            var hiddenCanvas = document.querySelector('#ukid-canvas'),
            video = document.querySelector('#ukid-camera-stream'),

            // Get the exact size of the video element
            width = cm.width,
            height = cm.height,

            // Context object for working with the canvas
            context = hiddenCanvas.getContext('2d')
            
            // Set the canvas to the same dimensions as the video
            hiddenCanvas.width = width
            hiddenCanvas.height = height
            
            var image = new Image()

            setTimeout(function() {

                image.setAttribute('src', hiddenCanvas.toDataURL('image/png'))
                image.addEventListener('load', function(event) {

                    // Draw a copy of the current frame from the video on the canvas
                    context.drawImage(video, 0, 0, width, height)
                    
                    // Set the dataURL as source of an image element, showing the captured photo
                    // image.setAttribute('src', hiddenCanvas.toDataURL('image/png'))
                    cm.postToMatcher(hiddenCanvas.toDataURL('image/png'))
                    
                })
                
            }, 2000)

        }, 3000) // Math.floor(Math.random() * (max-min) + min))

    },

    videStop: function() {

        try {

            cm.stream.getTracks().forEach(function(track) {
                track.stop();
            })

            cm.worker.terminate()
            cm.worker = undefined
            
        } catch (err) {
        }     
    
    },

    // Send small image to a server of your choice
    postToMatcher: function(base64) {
        
        var createRequest = function(method, url, token) {

            var request = new XMLHttpRequest();

            request.open(method, url, true);

            request.setRequestHeader("Content-Type", "application/json");
            request.setRequestHeader("Authorization", "Bearer " + token);

            return request;

        };

        var xhr = createRequest("post", cm.matcherEndpoint, cm.matcherToken);

        xhr.onreadystatechange = function() {

            if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 204)) {

                // response
                var json = JSON.parse(xhr.responseText);

            }

        };

        xhr.send(

            JSON.stringify(
                {
                    "documentNumber": cm.documentNumber, 
                    "type": "ead", 
                    "photo": base64.replace('data:image/png;base64,', '')
                }
            )

        );

    }    

}