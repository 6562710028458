var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _c(
        "div",
        { staticClass: "uk-margin" },
        [
          _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
            _vm._v("Tipo de Regra")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.roles, function(role) {
            return _c(
              "div",
              {
                key: role.value,
                staticClass: "uk-grid-small ukid-font-size-11",
                attrs: { "uk-grid": "" }
              },
              [
                _c("div", { staticClass: "ukid-width-80-percent" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-card uk-card-primary uk-card-small ukid-card-roles"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-text-left@s uk-card-body" },
                        [
                          _c("span", { staticClass: "ukid-label-roles" }, [
                            _vm._v(_vm._s(role.description))
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        " +
                              _vm._s(role.longDescription) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: {
                "ukid-focus uk-select ukid-form-error uk-textarea ukid-width-100-percent ukid-font-size-16": _vm.errors.has(
                  "roles-select_field"
                ),
                "ukid-focus uk-select uk-textarea ukid-width-100-percent ukid-font-size-16": !_vm.errors.has(
                  "roles-select_field"
                )
              },
              attrs: {
                rows: "7",
                "data-vv-as": "field",
                id: "roles-select_field",
                name: "roles-select_field"
              },
              on: { change: _vm.setRole }
            },
            [
              _c(
                "option",
                {
                  attrs: { value: "" },
                  domProps: { selected: _vm.role === "" }
                },
                [_vm._v("-")]
              ),
              _vm._v(" "),
              _vm._l(_vm.roles, function(option) {
                return _c(
                  "option",
                  { key: option.value, domProps: { value: option.value } },
                  [_vm._v(_vm._s(option.description))]
                )
              })
            ],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("group-component", {
        ref: "userGroupCpnt",
        attrs: {
          id: "user_group-select_field_1",
          name: "user_group-select_field_1",
          label: "Grupos",
          description: "(Ctrl + Clique) para mais de um grupo",
          parent_: _vm.parent_,
          app: _vm.app,
          strings: _vm.strings,
          commons: _vm.commons,
          vm: _vm.vm,
          noDefault: true,
          entity: _vm.user
        }
      }),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Título de Email Bloqueio de Password")]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.emailBlockedPasswordTitle,
                  expression: "user.emailBlockedPasswordTitle"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "email-blocked-title_text_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "email-blocked-title_text_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "email-blocked-title_text_field",
                name: "email-blocked-title_text_field",
                type: "text",
                placeholder: ""
              },
              domProps: { value: _vm.user.emailBlockedPasswordTitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.user,
                    "emailBlockedPasswordTitle",
                    $event.target.value
                  )
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c(
            "div",
            { staticClass: "uk-margin" },
            [
              _c(
                "span",
                {
                  staticClass: "uk-label ukid-primary-label-color uk-visible@s"
                },
                [_vm._v("Mensagem de Email Bloqueio de Password")]
              ),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("ckeditor", {
                attrs: { editor: _vm.editor, config: _vm.editorConfig },
                model: {
                  value: _vm.user.emailBlockedPasswordMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "emailBlockedPasswordMessage", $$v)
                  },
                  expression: "user.emailBlockedPasswordMessage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Título de Aviso de Expiração de Password")]
            ),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.emailWarnBlockedPasswordTitle,
                  expression: "user.emailWarnBlockedPasswordTitle"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "email-password-expiration_text_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "email-password-expiration_text_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "email-password-expiration_text_field",
                name: "email-password-expiration_text_field",
                type: "text",
                placeholder: ""
              },
              domProps: { value: _vm.user.emailWarnBlockedPasswordTitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.user,
                    "emailWarnBlockedPasswordTitle",
                    $event.target.value
                  )
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c(
            "div",
            { staticClass: "uk-margin" },
            [
              _c(
                "span",
                {
                  staticClass: "uk-label ukid-primary-label-color uk-visible@s"
                },
                [_vm._v("Mensagem de Aviso de Expiração de Password")]
              ),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("ckeditor", {
                attrs: { editor: _vm.editor, config: _vm.editorConfig },
                model: {
                  value: _vm.user.emailWarnBlockedPasswordMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "emailWarnBlockedPasswordMessage", $$v)
                  },
                  expression: "user.emailWarnBlockedPasswordMessage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c(
            "div",
            { staticClass: "uk-margin" },
            [
              _c(
                "span",
                {
                  staticClass: "uk-label ukid-primary-label-color uk-visible@s"
                },
                [_vm._v("Mensagem de Próximidade da Expiração do Password")]
              ),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("ckeditor", {
                attrs: { editor: _vm.editor, config: _vm.editorConfig },
                model: {
                  value: _vm.user.emailExpirationPasswordMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "emailExpirationPasswordMessage", $$v)
                  },
                  expression: "user.emailExpirationPasswordMessage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Threshold de Detecção de Corpos")]
            ),
            _vm._v(" "),
            _vm._m(8),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.bodyScore,
                  expression: "user.bodyScore"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "decimal:2|between:0.80,1",
                  expression: "'decimal:2|between:0.80,1'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "body-core-select_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "body-core-select_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "body-core-select_field",
                name: "body-core-select_field",
                type: "text",
                placeholder: "Entre .80 e 1"
              },
              domProps: { value: _vm.user.bodyScore },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "bodyScore", $event.target.value)
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Threshold de Detecção Facial ( 1:1 )")]
            ),
            _vm._v(" "),
            _vm._m(9),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.matcherScore,
                  expression: "user.matcherScore"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|decimal:2|between:0.30,1",
                  expression: "'required|decimal:2|between:0.30,1'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "matcherScore-select_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "matcherScore-select_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "matcherScore-select_field",
                name: "matcherScore-select_field",
                type: "text",
                placeholder: "Entre 0.30 e 1"
              },
              domProps: { value: _vm.user.matcherScore },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "matcherScore", $event.target.value)
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Threshold de Identificação Facial ( 1:N )")]
            ),
            _vm._v(" "),
            _vm._m(10),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.matcherScoreOneToN,
                  expression: "user.matcherScoreOneToN"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|decimal:2|between:0.30,1",
                  expression: "'required|decimal:2|between:0.30,1'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "matcherScoreOneToN-select_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "matcherScoreOneToN-select_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "matcherScoreOneToN-select_field",
                name: "matcherScoreOneToN-select_field",
                type: "text",
                placeholder: "Entre 0.30 e 1"
              },
              domProps: { value: _vm.user.matcherScoreOneToN },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "matcherScoreOneToN", $event.target.value)
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "Threshold de Identificação Facial de Pessoas Desconhecidas ( 1:N )"
                )
              ]
            ),
            _vm._v(" "),
            _vm._m(11),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.unknownMatcherScoreOneToN,
                  expression: "user.unknownMatcherScoreOneToN"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|decimal:2|between:0.30,1",
                  expression: "'required|decimal:2|between:0.30,1'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "unknownMatcherScoreOneToN-select_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "unknownMatcherScoreOneToN-select_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "unknownMatcherScoreOneToN-select_field",
                name: "unknownMatcherScoreOneToN-select_field",
                type: "text",
                placeholder: "Entre 0.30 e 1"
              },
              domProps: { value: _vm.user.unknownMatcherScoreOneToN },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.user,
                    "unknownMatcherScoreOneToN",
                    $event.target.value
                  )
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Threshold de Temperatura Alta")]
            ),
            _vm._v(" "),
            _vm._m(12),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.temperatureHighScore,
                  expression: "user.temperatureHighScore"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|decimal:2|between:35.7,43.0",
                  expression: "'required|decimal:2|between:35.7,43.0'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "temperatureHighScore-core-select_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "temperatureHighScore-core-select_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "temperatureHighScore-core-select_field",
                name: "temperatureHighScore-core-select_field",
                type: "text",
                placeholder: "Entre 35.7 e 43.0"
              },
              domProps: { value: _vm.user.temperatureHighScore },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.user,
                    "temperatureHighScore",
                    $event.target.value
                  )
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Threshold de Temperatura de Atenção")]
            ),
            _vm._v(" "),
            _vm._m(13),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.temperatureAttentionScore,
                  expression: "user.temperatureAttentionScore"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|decimal:2|between:35.7,43.0",
                  expression: "'required|decimal:2|between:35.7,43.0'"
                }
              ],
              class: {
                "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
                  "temperatureAttentionScore-core-select_field"
                ),
                "uk-input ukid-width-100-percent": !_vm.errors.has(
                  "temperatureAttentionScore-core-select_field"
                )
              },
              attrs: {
                "c-data-vv-as": "field",
                id: "temperatureAttentionScore-core-select_field",
                name: "temperatureAttentionScore-core-select_field",
                type: "text",
                placeholder: "Entre 35.7 e 43.0"
              },
              domProps: { value: _vm.user.temperatureAttentionScore },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.user,
                    "temperatureAttentionScore",
                    $event.target.value
                  )
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(14),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Consulta em Minha BLC\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.userBlcEnabled,
                                    expression: "user.userBlcEnabled"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "user-blc-enabled-input_yes_field",
                                  name: "user-blc-enabled-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.userBlcEnabled,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "userBlcEnabled",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.userBlcEnabled,
                                    expression: "user.userBlcEnabled"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "user-blc-enabled-input_no_field",
                                  name: "user-blc-enabled-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.userBlcEnabled,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "userBlcEnabled",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user && _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(15),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Consulta em BLC Geral\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.coreBlcEnabled,
                                    expression: "user.coreBlcEnabled"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "core-blc-enabled-input_yes_field",
                                  name: "core-blc-enabled-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.coreBlcEnabled,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "coreBlcEnabled",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.coreBlcEnabled,
                                    expression: "user.coreBlcEnabled"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "core-blc-enabled-input_no_field",
                                  name: "core-blc-enabled-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.coreBlcEnabled,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "coreBlcEnabled",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(16),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Herdar Registros do Proprietário\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.inheritOwner,
                                    expression: "user.inheritOwner"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "inherit-owner-type-input_yes_field",
                                  name: "inherit-owner-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.inheritOwner, "true")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "inheritOwner",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.inheritOwner,
                                    expression: "user.inheritOwner"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "inherit-owner-type-input_no_field",
                                  name: "inherit-owner-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.inheritOwner,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "inheritOwner",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(17),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Herdar Registros dos Filhos\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.inheritChildren,
                                    expression: "user.inheritChildren"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "inherit-children-type-input_yes_field",
                                  name: "inherit-children-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.inheritChildren,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "inheritChildren",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.inheritChildren,
                                    expression: "user.inheritChildren"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "inherit-children-type-input_no_field",
                                  name: "inherit-children-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.inheritChildren,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "inheritChildren",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user && _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(18),
                    _vm._v(" "),
                    _vm._m(19),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.biIntegration,
                                    expression: "user.biIntegration"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "bi-type-input_yes_field",
                                  name: "bi-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.biIntegration,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "biIntegration",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.biIntegration,
                                    expression: "user.biIntegration"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "bi-type-input-no_field",
                                  name: "bi-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.biIntegration,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "biIntegration",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user && _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(20),
                    _vm._v(" "),
                    _vm._m(21),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.externalIntegration,
                                    expression: "user.externalIntegration"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "external-type-input-yes_field",
                                  name: "external-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.externalIntegration,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "externalIntegration",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.externalIntegration,
                                    expression: "user.externalIntegration"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "external-type-input-no_field",
                                  name: "external-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.externalIntegration,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "externalIntegration",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user && _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-hidden@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Edição de Credenciais\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Edição de Credenciais\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.credentialEditing,
                                    expression: "user.credentialEditing"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "credential-editing-input-yes_field",
                                  name: "credential-editing-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.credentialEditing,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "credentialEditing",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.credentialEditing,
                                    expression: "user.credentialEditing"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "credential-editing-type-input-no_field",
                                  name: "credential-editing-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.credentialEditing,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "credentialEditing",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(22),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Salvar Pessoas Desconhecidas na Identificação\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.saveUnknownPerson,
                                    expression: "user.saveUnknownPerson"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "save-unknown-input-yes_field",
                                  name: "save-unknown-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.saveUnknownPerson,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "saveUnknownPerson",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.saveUnknownPerson,
                                    expression: "user.saveUnknownPerson"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "save-unknown-input-no_field",
                                  name: "save-unknown-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.saveUnknownPerson,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "saveUnknownPerson",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(23),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Faces Adicionais no Convite\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.plusFaceOnInvite,
                                    expression: "user.plusFaceOnInvite"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "plus-face-input-yes_field",
                                  name: "plus-face-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.plusFaceOnInvite,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "plusFaceOnInvite",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.plusFaceOnInvite,
                                    expression: "user.plusFaceOnInvite"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "plus-face-input-no_field",
                                  name: "plus-face-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.plusFaceOnInvite,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "plusFaceOnInvite",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _vm._m(24),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Mostrar Face do Cadastro na Detecção\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "enabled-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.showRegistrationImageOnDetection,
                                    expression:
                                      "user.showRegistrationImageOnDetection"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "show-registration-image-input-yes_field",
                                  name:
                                    "show-registration-image-detection-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.showRegistrationImageOnDetection,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "showRegistrationImageOnDetection",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.showRegistrationImageOnDetection,
                                    expression:
                                      "user.showRegistrationImageOnDetection"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "show-registration-image-input-no_field",
                                  name:
                                    "show-registration-image-detection-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.showRegistrationImageOnDetection,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "showRegistrationImageOnDetection",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user && _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Detecção de Genero, Idade e Emoções\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(25),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "gae-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.gae,
                                    expression: "user.gae"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "gae-type-input-yes_field",
                                  name: "gae-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.gae, "true")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(_vm.user, "gae", "true")
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.gae,
                                    expression: "user.gae"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "gae-type-input-no_field",
                                  name: "gae-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.gae, "false")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(_vm.user, "gae", "false")
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user && _vm.auth.includes("admin")
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Detecção de Máscara\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-hidden@s"
                      },
                      [
                        _vm._v(
                          "\n                        Detecção de Máscara\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "mask-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.mask,
                                    expression: "user.mask"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "mask-type-input-yes_field",
                                  name: "mask-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.mask, "true")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(_vm.user, "mask", "true")
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.mask,
                                    expression: "user.mask"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "mask-type-input-no_field",
                                  name: "mask-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.mask, "false")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(_vm.user, "mask", "false")
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Deduplicação de Face no Cadastro\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(26),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "deduplication-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.deduplication,
                                    expression: "user.deduplication"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "deduplication-type-input-yes_field",
                                  name: "deduplication-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.deduplication,
                                    "true"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "deduplication",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.deduplication,
                                    expression: "user.deduplication"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "deduplication-type-input-no_field",
                                  name: "deduplication-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.user.deduplication,
                                    "false"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "deduplication",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.user.roles.includes("signer") && _vm.role !== "signer"
        ? _c("div", { staticClass: "uk-margin" }, [
            _c("div", { staticClass: "ukid-width-80-percent" }, [
              _c(
                "div",
                {
                  staticClass:
                    "uk-card uk-card-primary uk-card-small ukid-card-roles"
                },
                [
                  _c("div", { staticClass: "uk-text-left@s uk-card-body" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "uk-label ukid-primary-label-color uk-visible@s"
                      },
                      [
                        _vm._v(
                          "\n                        Habilitar Debug Facial nas Identificações\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(27),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-inline ukid-width-100-percent" },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "ukid-width-100-percent": _vm.errors.has(
                                "enabled-input_field"
                              ),
                              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                                "facialDebug-input_field"
                              )
                            }
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.facialDebug,
                                    expression: "user.facialDebug"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "true",
                                  type: "radio",
                                  id: "facialDebug-type-input-yes_field",
                                  name: "facialDebug-type-input_field",
                                  checked: ""
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.facialDebug, "true")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "facialDebug",
                                      "true"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" SIM\n                            ")
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.facialDebug,
                                    expression: "user.facialDebug"
                                  }
                                ],
                                staticClass: "uk-radio",
                                attrs: {
                                  value: "false",
                                  type: "radio",
                                  id: "facialDebug-type-input-no_field",
                                  name: "facialDebug-type-input_field"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.facialDebug, "false")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.user,
                                      "facialDebug",
                                      "false"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" NÃO\n                            ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Título de Email"), _c("br"), _vm._v("Bloqueio de Password")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Mensagem de Email"), _c("br"), _vm._v("Bloqueio de Password")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome da instituição"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{username}")]),
      _vm._v(
        " - Será substituído pela username de acesso da instituição\n        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Título de Aviso"), _c("br"), _vm._v("de Expiração de Password")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("Mensagem de Aviso de"),
        _c("br"),
        _vm._v("Expiração de Password")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome da instituição"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{days}")]),
      _vm._v(" - Será substituído pelos dias restantes\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("Mensagem de Próximidade da"),
        _c("br"),
        _vm._v("Expiração do Password")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome da instituição\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Threshold de Detecção"), _c("br"), _vm._v("de Corpos")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Threshold de Detecção"), _c("br"), _vm._v("Facial ( 1:1 )")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Threshold de Identificação"), _c("br"), _vm._v("Facial ( 1:N )")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("Threshold de Identificação Facial"),
        _c("br"),
        _vm._v("de Pessoas Desconhecidas ( 1:N )")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Threshold de"), _c("br"), _vm._v("Temperatura Alta")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [_vm._v("Threshold de"), _c("br"), _vm._v("Temperatura de Atenção")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Habilitar Consulta"),
        _c("br"),
        _vm._v("em Minha BLC\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Habilitar Consulta"),
        _c("br"),
        _vm._v("em BLC Geral\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Herdar Registros"),
        _c("br"),
        _vm._v("do Proprietário\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Herdar Registros"),
        _c("br"),
        _vm._v("dos Filhos\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v(
          "\n                        Habilitar B.I\n                        "
        ),
        _c("span", { staticClass: "ukid-icon-purple-color" }, [
          _c("br"),
          _vm._v("Precisa estar habilitado"),
          _c("br"),
          _vm._v("nas propriedades do servidor\n                        ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
      },
      [
        _vm._v(
          "\n                        Habilitar B.I\n                        "
        ),
        _c(
          "span",
          { staticClass: "ukid-icon-purple-color ukid-font-size-10" },
          [
            _c("br"),
            _vm._v(
              "Precisa estar habilitado nas propriedades do servidor \n                        "
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v(
          "\n                        Habilitar Integrações Externas\n                        "
        ),
        _c(
          "span",
          { staticClass: "ukid-icon-purple-color ukid-font-size-10" },
          [
            _c("br"),
            _vm._v("Precisa estar habilitado"),
            _c("br"),
            _vm._v("nas propriedades do servidor\n                        ")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _vm._v(
          "\n                        Habilitar Integrações Externas\n                        "
        ),
        _c(
          "span",
          { staticClass: "ukid-icon-purple-color ukid-font-size-10" },
          [
            _c("br"),
            _vm._v(
              "Precisa estar habilitado nas propriedades do servidor \n                        "
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Salvar Pessoas Desconhecidas"),
        _c("br"),
        _vm._v(
          "\n                        na Identificação\n                    "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Habilitar Faces"),
        _c("br"),
        _vm._v(
          "\n                        Adicionais no Convite\n                    "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Mostrar Face"),
        _c("br"),
        _vm._v(
          "\n                        do Cadastro na Detecção\n                    "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Detecção de Genero,"),
        _c("br"),
        _vm._v("Idade e Emoções\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Habilitar Deduplicação"),
        _c("br"),
        _vm._v("de Face no Cadastro\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n                        Habilitar Debug Facial"),
        _c("br"),
        _vm._v("nas Identificações\n                    ")
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-58812e5e", { render: render, staticRenderFns: staticRenderFns })
  }
}