<style scoped lang="scss">
    @import '../../../content/css/temperature.scss';
</style>

<script type="text/javascript">

    import App from '../../app.json'
    import UIkit from 'uikit'

    import { vm } from '../../js/main.js'
    import { Datetime } from 'vue-datetime'

    import { Strings } from '../../js/common.js'

    export default {

        components: {
            datetime: Datetime
        },

        /**
         * All objects required for this page to function properly
         */
        data() {

            return {

                App: App,
                pagination: {
                    last_page: 0
                },
                registers: {},                
                filters: {
                    searchParam: '',
                    startDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    endDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    imageSize: '310',
                    fontSize: '14',
                    imagesPerPage: App.pagination.proximity.itensPerPage,
                },
                enableAutoList: false,
                controlStartDate: 0,
                controlEndDate: 0,
                sub: '',
                loggedUser: null,
                page: 1,
                socket: null,
                stomp: null

            }

        },
        
        /**
         * This method executes when the vue object is created.
         */
        created() {

            let _ = this
            
            /**
             * Get logged user
             */
            _.getLoggedUser()

            /**
             * Config initial filters
             */
            _.setFilters()

            /** 
             * Active the search list
             */
            _.list(undefined)
            _.enableInterval()
            
            /**
             * Get user logged
             */
            _.sub = vm.user.sub
                    
        },
        
        methods: {

            /**
             * Responsible for re-registering the records 
             * and re-activating the polling interval.
             */
            search() {

                let _ = this

                vm.spinnerSearch()

                _.enableInterval()

            },

            /**
             * List all devices relevant to or logged user
             */
            getLoggedUser() {
            
                let _ = this

                _.$http.get(

                    App.host.concat(App.loggedUserEndpoint)

                ).then(response => {

                    _.loggedUser = response.body
                                        
                }).catch( function(err){

                    UIkit.modal.alert(err.body.description)

                }) 

            },

            /**
             * This method is responsible for listing the real time 
             * detections that are happening in CCTV cameras, this method 
             * is based on the end user predefined filters
             * 
             * This method fills in the attributes (registers and pagination) of the data object.
             * 
             * @param page Pagination based page number
             */
            list(page) {
            
                let _ = this

                if (page !== undefined) {
                                        
                    _.page = page

                    _.clearInterval()

                }
                
                let startDate = new Date(_.filters.startDate)
                let endDate   = new Date(_.filters.endDate)

                /**
                 * Filter of search
                 */
                var filter_ = {

                    pageNum: (page === undefined ? 1 : page), // Number of page
                    perPage: _.filters.imagesPerPage, // Total itens per page
                    param: _.filters.searchParam, // Some string of research
                    startDate: startDate.getTime(), // Search per some start date
                    endDate: endDate.getTime() // Search per some end date

                }

                /**
                 * Post filter to unike server
                 */
                _.$http.post(
                    App.host.concat(
                        App.verifyEndpoint + "?nocache=" + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))
                    ), 
                    filter_
                ).then(response => {

                    /**
                     * Update binded objects
                     */
                    _.registers = response.body.data
                    _.pagination = response.body

                }).catch( function(err){

                    console.error(err)

                })

                if (page !== undefined) {
                                        
                    _.page = page

                    _.clearInterval()

                }

            },

            generateExcel() {

                let _ = this
        
                let startDate = new Date(_.filters.startDate)
                let endDate   = new Date(_.filters.endDate)

                var modal_ = UIkit.modal.dialog(App.notification.download, { escClose: false, bgClose: false })

                /**
                 * Filter of search
                 */
                var filter_ = {

                    pageNum: 1, // Number of page
                    perPage: 1, // Total itens per page
                    param: _.filters.searchParam, // Some string of research
                    personsNotDetected: _.filters.personsNotDetected, // Enable unknown people filter
                    blcDetected: _.filters.blcDetected, // Enable blacklist people filter
                    devices: _.filters.selectedDevices, // Search per list of camera or proximity devices 
                    startDate: startDate.getTime(), // Search per some start date
                    endDate: endDate.getTime() // Search per some end date

                }

                /**
                 * Post filter to unike server
                 */
                _.$http.post(
                    App.host.concat(
                        App.generateReportEndpoint + "?nocache=" + Math.abs(Math.floor(Math.random() * 777 * 777 * 777 * 777))
                    ), 
                    filter_,
                    {
                        responseType: 'arraybuffer'
                    }
                ).then(response => {

                    let blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})

                    let filename = 'unike_faces_detection.xls'
                    
                    let result = document.createElement('a')
                    result.href = window.URL.createObjectURL(blob)
                    result.download = filename
                    
                    result.click()
                    
                    setTimeout(function(){ modal_.hide() }, 300)
                    
                }).catch(function(err){

                    setTimeout(function(){ 
                        modal_.hide()
                        UIkit.modal.alert(App.notification.reportDownloadError)
                    }, 300)

                }) 
                
            },

            /**
             * Saves user-selected filter data to browser database
             */
            saveFilters() {

                let filterName = window.location.hostname + '_' + vm.user.sub + '_filters_verify'

                localStorage.setItem(filterName, JSON.stringify(this.filters))

                this.setFilters()
                
                this.list(this.page)

                this.clearInterval()
                this.enableInterval()

            },

            /**
             * Configure saved filter data for bound objects
             */
            setFilters() {

                let filterName = window.location.hostname + '_' + vm.user.sub + '_filters_verify'

                let json = localStorage.getItem(filterName)

                if (!Strings.isNullOrEmpty(json)) {

                    this.filters = JSON.parse(json)

                    if (!this.filters.hasOwnProperty('imageSize'))
                        this.filters.imageSize = 310

                    if (!this.filters.hasOwnProperty('fontSize'))
                        this.filters.fontSize = 14

                    localStorage.setItem(filterName, JSON.stringify(this.filters))

                }

                this.$forceUpdate()

            },

            currentDateFilter(isCurrentDate) {

                let _ = this
                
                if (isCurrentDate !== undefined) _.filters.currentDate = isCurrentDate

                if (_.filters.currentDate === true || _.filters.currentDate === "true") {

                    if (_.$moment(_.filters.endDate).isSameOrAfter(_.filters.startDate, 'day')) {
                     
                        _.filters.endDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()

                    }
                    
                    if (_.$moment(_.filters.startDate).isBefore(_.filters.endDate)) {

                        _.filters.startDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()

                    }

                }

                document.querySelector('.ukid-dates-interval').classList.remove(
                    'uk-animation-shake', 
                    'uk-animation-reverse'
                )

                setTimeout(function(){
                    document.querySelector('.ukid-dates-interval').classList.add(
                        'uk-animation-shake', 
                        'uk-animation-reverse'
                    )
                }, 500)

            },

            clearInterval() {

                let _ = this

                for (var i = 1; i < 99999; i++) {
                    
                    window.clearInterval(i);

                    _.enableAutoList = true
                    
                }

            },

            enableInterval() {
                
                let _ = this
    
                _.page = 1

                _.list(_.page)

                _.enableAutoList = false

                setInterval(function(){
                
                    _.list(undefined)

                }, App.layout.proximity.interval)
                                
            },            

            openFullScreen() {

                this.filters.imagesPerPage = App.pagination.proximity.itensPerPageFullscreen

                if (document.documentElement.requestFullscreen) {

                    document.documentElement.requestFullscreen()

                } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */

                    edocument.documentElementlem.mozRequestFullScreen()

                } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */

                    document.documentElement.webkitRequestFullscreen()

                } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */

                    document.documentElement.msRequestFullscreen()

                }

                document.querySelector('.ukid-open-fullscreen-buttom').classList.add('uk-hidden')
                document.querySelector('.ukid-close-fullscreen-buttom').classList.remove('uk-hidden')

                try { 

                    document.querySelector('#ukid-top').classList.add('uk-hidden')
                    document.querySelector('#ukid-footer').classList.add('uk-hidden')

                } catch (ignored) {

                }

                document.querySelector('.ukid-logo-fullscreen').classList.remove('uk-hidden')
                 
            },

            closeFullScreen() {

                this.filters.imagesPerPage = App.pagination.proximity.itensPerPage

                if (document.exitFullscreen) {

                    document.exitFullscreen()

                } else if (document.mozCancelFullScreen) { /* Firefox */

                    document.mozCancelFullScreen()

                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */

                    document.webkitExitFullscreen() 
                    
                } else if (document.msExitFullscreen) { /* IE/Edge */

                    document.msExitFullscreen()

                }

                document.querySelector('.ukid-close-fullscreen-buttom').classList.add('uk-hidden')
                document.querySelector('.ukid-open-fullscreen-buttom').classList.remove('uk-hidden')
                document.querySelector('.ukid-logo-fullscreen').classList.add('uk-hidden')

                try { 

                    document.querySelector('#ukid-top').classList.remove('uk-hidden') 
                    document.querySelector('#ukid-footer').classList.remove('uk-hidden')
                    
                } catch (ignored) {                    
                }
                
            }

        }

    }

</script>

<template id="verify-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>
        
        <!-- Start Modal filters -->
        <div id="modal-devices" uk-modal>
            
            <div class="uk-modal-dialog uk-modal-body">
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Faces por Página</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Faces por Página</span><br />
                    <div class="uk-inline ukid-width-22-percent uk-visible@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="filters.imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-50-percent uk-hidden@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="filters.imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                </div>
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Tamhanho da Face</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Tamhanho da Face</span><br />
                    <div class="uk-inline ukid-width-50-percent">
                        <input class="uk-range" type="range" v-model="filters.imageSize" min="220" max="600" step="1"> {{filters.imageSize}}
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Tamhanho da Fonte</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Tamhanho da Fonte</span><br />
                    <div class="uk-inline ukid-width-50-percent">
                        <input class="uk-range" type="range" v-model="filters.fontSize" min="14" max="50" step="1"> {{filters.fontSize}}
                    </div>
                </div>                
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color">
                        Nome ou CPF
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-30-percent uk-visible@s">
                        <input type="text" v-model="filters.searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        <input type="text" v-model="filters.searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Somente registros da data atual
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Somente registros da data atual
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-50-percent">
                        <button @click="currentDateFilter(true)" class="uk-button uk-button-primary" type="button">Somente data atual</button>
                    </div>
                </div>
                <div class="uk-margin">   
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Faces Verificadas no Intervalo De:</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Faces Verificadas<br />no Intervalo De:</span><br /><br />
                    <div class="ukid-dates-interval" uk-grid>
                        <div class="uk-inline ukid-width-20-percent uk-visible@s">
                            DE:
                            <datetime
                            v-model="filters.startDate"
                            type="date"
                            input-class="mx-input ukid-width-100-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy"
                            :phrases="{}"
                            :hour-step="1"
                            :minute-step="5"
                            :week-start="7"
                            :auto="true"
                            :max-datetime="filters.endDate"
                            use24-hour></datetime>
                        </div>
                        <div></div>
                        <div class="uk-inline ukid-width-20-percent uk-visible@s">
                            ATÉ:
                            <datetime
                            v-model="filters.endDate"
                            type="date"                            
                            input-class="mx-input ukid-width-100-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy"
                            :phrases="{}"
                            :hour-step="1"
                            :minute-step="5"
                            :week-start="7"
                            :auto="true"
                            :max-datetime="new Date().toISOString()"
                            :min-datetime="filters.startDate"
                            use24-hour></datetime>                        
                        </div>
                    </div>
                   <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        DE:
                        <datetime
                        v-model="filters.startDate"
                        type="date"
                        input-class="mx-input ukid-width-100-percent"
                        value-zone="America/Sao_Paulo"
                        zone="America/Sao_Paulo"
                        format="dd/MM/yyyy"
                        :phrases="{}"
                        :hour-step="1"
                        :minute-step="5"
                        :week-start="7"
                        :auto="true"
                        :max-datetime="filters.endDate"
                        use24-hour></datetime>                       
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s"> 
                        <br />
                        ATÉ:
                        <datetime
                        v-model="filters.endDate"
                        type="date"
                        input-class="mx-input ukid-width-100-percent"
                        value-zone="America/Sao_Paulo"
                        zone="America/Sao_Paulo"
                        format="dd/MM/yyyy"
                        :phrases="{}"
                        :hour-step="1"
                        :minute-step="5"
                        :week-start="7"
                        :auto="true"
                        :max-datetime="new Date().toISOString()"
                        :min-datetime="filters.startDate"
                        use24-hour></datetime>
                    </div>                     
                </div>
                <p class="uk-text-right uk-visible@s">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Não Salvar</button>
                    <button @click="saveFilters()" class="uk-button uk-button-primary uk-modal-close ukid-magin-left-5" type="button">Salvar</button>
                </p>
                <p class="uk-text-right uk-hidden@s">                    
                    <button class="uk-button uk-button-default uk-button-small uk-modal-close ukid-magin-right-5" type="button">Não Salvar</button>
                    <button @click="saveFilters()" class="uk-button uk-button-primary uk-button-small uk-modal-close ukid-magin-left-10" type="button">Salvar</button>
                </p>                
            </div>

        </div>
        <!-- End Modal filters -->

        <!-- Delete modal -->
        <div id="modal-overflow" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <button v-on:click="del()" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>
            </div>
        </div>

        <!-- Top of the layout proximity -->
        <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
            <div class="uk-card-header ukid-supress-border-bottom">
                <div class="ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50">
                    <a href="../" class="uk-navbar-item uk-logo ukid-white-color ukid-font-size-13">
                        <img src="content/images/unike-logo-un.png" width="120" class="uk-margin-small-right">
                    </a>
                </div>
                <div class="uk-grid-small uk-flex-middle ukid-min-height-60" uk-grid>                                    
                    <div class="uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:search;ratio:5.0"></span> 
                    </div>
                    <div class="uk-width-expand">                        
                        <span class="ukid-font-size-20 ukid-margin-top-40 uk-visible@s">Pessoas nas Verificadas</span>
                        <span class="ukid-font-size-16 ukid-margin-top-40 uk-hidden@s">Pessoas nas Verificadas</span>
                        <div class="uk-margin-remove-top">
                            <span class="uk-visible@s">
                                Exibição de pessoas verificadas
                                <br />
                                <div class="uk-label ukid-label-blue-color">
                                    Total de Pessoas Até a Data de Hoje: {{pagination.databaseCount}}
                                    <br />
                                    Total de Pessoas de Acordo com os Filtros: {{pagination.total}}
                                </div>
                            </span>
                            <span class="uk-hidden@s ukid-font-size-13">
                                Exibição de pessoas verificadas
                                <br />
                                <div class="uk-label ukid-label-blue-color ukid-font-size-9">
                                    Total de Pessoas Até a Data de Hoje: {{pagination.databaseCount}}
                                    <br />
                                    Total de Pessoas de Acordo com os Filtros: {{pagination.total}}
                                </div>
                            </span>                            
                            <div class="uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15" align="right">
                                <a @click="setFilters()" uk-toggle="target: #modal-devices">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar
                                </a>
                            </div>
                            <div class="uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17" align="right">
                                <a @click="setFilters()" uk-toggle="target: #modal-devices">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar
                                </a>
                            </div>                            
                            <button @click="setFilters()" uk-toggle="target: #modal-devices" class="uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                Filtrar
                            </button>
                            <button @click="openFullScreen()" class="ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="tv"></span>
                                Tela Cheia
                            </button>
                            <button @click="closeFullScreen()" class="ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="close"></span>
                                Sair da Tela Cheia
                            </button>
                            <br />
                        </div>
                    </div>
                </div>
                <div class="ukid-font-size-14 ukid-icon-pink-color ukid-margin-top-10 uk-align-right ukid-margin-bottom-0">
                    Atenção aos filtros que você configurou
                </div>
            </div>

            <div class="uk-card-footer ukid-supress-border-top">

                <div class="uk-flex uk-flex-center ukid-padding-top-20">
                    <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
                        <div v-show="!registers.length" class="uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color" uk-alert>                            
                            <p class="ukid-white-color">
                                <span class="uk-margin-small-right uk-icon" uk-icon="warning"></span>
                                {{App.proximity.nobody}}
                            </p>
                        </div>

                        <!-- For on all verify faces according to filters defined by final user -->
                        <div v-for="verifyFaces in registers" :key="verifyFaces.id" class="ukid-offcanvas-over">
                            
                            <!-- Modal show face zoom -->
                            <div :id="'modal-media-image-' + verifyFaces.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog ukid-zoom-photo uk-margin-auto-vertical">
                                    <button class="uk-modal-close-outside" type="button" uk-close></button>
                                    <div :id="'ukid-container-zoom-' + verifyFaces.id">
                                        <table>
                                            <tr>
                                                <td>
                                                    <img crossorigin="Anonymous" :id="'ukid-request-image-face-zoom-' + verifyFaces.id" :src="verifyFaces.requestPhoto" class="ukid-photo-effect-zoom" width="340" alt="">
                                                </td>
                                                <td>
                                                    <img crossorigin="Anonymous" :id="'ukid-image-face-zoom-' + verifyFaces.id" :src="verifyFaces.photo" class="ukid-photo-effect-zoom" width="340" alt="">
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="uk-overlay uk-light uk-position-bottom" :style="'font-size:'+filters.fontSize+'px !important'">
                                        <span class="uk-text-uppercase">Registro Consultado</span>
                                        <p>
                                            <span> Docto : {{verifyFaces.documentNumber}}</span><br />
                                            <span> Nome : {{verifyFaces.name}}</span><br />
                                            <span> Resultado : {{verifyFaces.verified === true ? 'Passou' : 'Não Passou'}}</span><br />
                                            <span> Data: {{new Date(verifyFaces.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><br />
                                            <span v-if="verifyFaces.error"> Erro: {{verifyFaces.error}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div :class="'uk-card uk-card-default uk-card-bod ukid-width-'+filters.imageSize+'-px'">
                                <div align="center" class="ukid-padding-5px">
                                    <table class="ukid-font-size-17 ukid-cursor-pointer" :width="filters.imageSize - 40">
                                        <tbody>
                                            <tr>
                                                <td align="left">
                                                    <div uk-tooltip title="Foto enviada para comparação" :class="{ 'ukid-circular-person-edit ukid-label-green-color' : verifyFaces.verified === true, 'ukid-circular-person-edit ukid-label-alert-color' : verifyFaces.verified === false }">
                                                        <img :src="verifyFaces.requestPhoto" class="ukid-circular-portrait" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div uk-tooltip title="Foto do cadastro" :class="{ 'ukid-circular-person-edit ukid-label-green-color' : verifyFaces.verified === true, 'ukid-circular-person-edit ukid-label-alert-color' : verifyFaces.verified === false }">
                                                        <img :src="verifyFaces.photo" class="ukid-circular-portrait" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="310" :style="'font-size:'+filters.fontSize+'px !important'">
                                        <tbody :class="{ 'ukid-icon-green-color ukid-capitalize' : verifyFaces.verified === true, 'ukid-icon-alert-color ukid-capitalize' : verifyFaces.verified === false }">
                                            <tr>
                                                <td class="uk-text-uppercase" align="center" colspan="2">
                                                    Registro Consultado
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center">
                                                    Docto: {{verifyFaces.documentNumber.toLowerCase()}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center">
                                                    Nome: {{verifyFaces.name.toLowerCase()}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center">
                                                    Resultado: {{verifyFaces.verified === true ? 'Passou' : 'Não Passou'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center">
                                                    Data: {{new Date(verifyFaces.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                                </td>
                                            </tr>
                                            <tr v-if="verifyFaces.error">
                                                <td align="center" class="ukid-text-alert-color uk-text-lowercase">
                                                    Erro: {{verifyFaces.error.toLowerCase()}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div align="center">
                <div v-show="pagination.last_page > 1" class="uk-text-uppercase ukid-font-size-20">Pagina Atual: <b>{{page}}</b></div>
                <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10" uk-margin>
                    <paginate
                        v-model="page"
                        :page-count="pagination.last_page"
                        :click-handler="list"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-10'">
                    </paginate>
                </ul>                
                <span 
                    uk-margin
                    uk-tooltip 
                    v-show="pagination.last_page > 1 && enableAutoList" 
                    title="Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui"  
                    @click="enableInterval()" 
                    class="ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20" 
                    uk-icon="icon:play-circle;ratio:2.7"></span>
            </div>

        </div> 
    </div>

</template>
