<!--suppress SpellCheckingInspection, HtmlUnknownAttribute, JSUnresolvedVariable -->
<script>

    import Multiselect from 'vue-multiselect'
    import UsersComponent from "../user/users-component"

    export default {

        inject: ['$validator'],
        
        props: ['app', 'strings', 'commons', 'user', 'auth', 'role', 'vm'],
        
        components: {

            Multiselect,
            usersComponent: UsersComponent,

        },

        data() {

            return {

                users: null,
                galleries: '',
                emails: [],
                emailsCredentialWarning: [],

            }

        },

        created() {

            let _ = this

            if (_.auth.includes("admin")) {

                _.listAllGalleries()
                _.fillUsers()
                
            }            

        },

        methods: {

            fillUsers() {

                let _ = this

                _.$http.get(

                    _.app.host.concat(_.app.listAllUserEndpoint)

                ).then(response => {

                    _.users = response.body
                    
                })

            },

            listAllGalleries() {

                let _ = this

                _.$http.get(

                    _.app.host.concat(_.app.listAllGalleryEndpoint)

                ).then(response => {

                    _.galleries = response.body

                })

            },

            addCredentialEmail(email) {

                let _ = this

                _.emails.push(email)
                _.user.selectedCredentialWarningEmails.push(email)

            }

        }
        
    }

</script>

<template id="register-credential-template">

    <!--  Credentials -->
    <li>
        <div class="uk-margin" v-if="auth.includes('admin') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color">Galeria</span><br />
            <multiselect v-model="user.gallery"
                         style="color: #444444 !important;"
                         :options="galleries.length > 0 ? galleries.map(function(obj) { return obj.name }) : []"
                         :searchable="true"
                         :close-on-select="true"
                         :show-labels="false"
                         placeholder="Selecione a galeria">

                <template slot="singleLabel" slot-scope="props">
                    <span class="multiselect__tag">
                        {{ props.option }} -> selecionado
                    </span>
                </template>
                <template slot="noResult">
                        <span>
                            Nenhuma galeria encontrada
                        </span>
                </template>
                <template slot="noOptions">
                    <span>
                        Comece a digitar
                    </span>
                </template>
            </multiselect>
        </div>

        <div class="uk-margin" v-if="auth.includes('admin') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color">Galeria de Desconhecidos</span><br />
            <multiselect v-model="user.unknownGallery"
                         style="color: #444444 !important;"
                         :options="galleries.length > 0 ? galleries.map(function(obj) { return obj.name }) : []"
                         :searchable="true"
                         :close-on-select="true"
                         :show-labels="false"
                         placeholder="Selecione a galeria">

                <template slot="singleLabel" slot-scope="props">
                    <span class="multiselect__tag">
                        {{ props.option }} -> selecionado
                    </span>
                </template>
                <template slot="noResult">
                        <span>
                            Nenhuma galeria encontrada
                        </span>
                </template>
                <template slot="noOptions">
                    <span>
                        Comece a digitar
                    </span>
                </template>
            </multiselect>
        </div>

        <div class="uk-margin" v-if="auth.includes('admin') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color">Galeria BLC</span><br />
            <multiselect v-model="user.blcGallery"
                         style="color: #444444 !important;"
                         :options="galleries.length > 0 ? galleries.map(function(obj) { return obj.name }) : []"
                         :searchable="true"
                         :close-on-select="true"
                         :show-labels="false"
                         placeholder="Selecione a galeria">

                <template slot="singleLabel" slot-scope="props">
                    <span class="multiselect__tag">
                        {{ props.option }} -> selecionado
                    </span>
                </template>
                <template slot="noResult">
                        <span>
                            Nenhuma galeria encontrada
                        </span>
                </template>
                <template slot="noOptions">
                    <span>
                        Comece a digitar
                    </span>
                </template>
            </multiselect>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color">Nome</span><br />
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:warning"></span>                            
                <input 
                    :class="{
                        'ukid-form-error uk-input ukid-width-100-percent': errors.has('user-name-input_field'),
                        'uk-input ukid-width-100-percent': !errors.has('user-name-input_field')
                    }" 
                    v-model="user.name"
                    v-validate="'required|min:5|max:40'"
                    c-data-vv-as="field"
                    id="user-name-input_field"
                    name="user-name-input_field"
                    type="text" 
                    placeholder="Máximo 40 caracteres">
            </div>
        </div>

        <div class="uk-margin" v-if="auth.includes('admin') || auth.includes('user')">
            <span class="uk-label ukid-primary-label-color">Username</span><br />
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:user"></span>
                <input 
                    :class="{
                        'ukid-form-error uk-input ukid-width-100-percent': errors.has('username-input_field'), 
                        'uk-input ukid-width-100-percent': !errors.has('username-input_field')
                    }"
                    v-model="user.username"
                    v-validate="'required|min:3|max:35'"
                    data-vv-as="field"
                    id="username-input_field"
                    name="username-input_field" 
                    type="text"
                    placeholder="Mínimo 3, máximo 35 caracteres">
            </div>
        </div>

        <div class="uk-margin">   
            <span class="uk-label ukid-primary-label-color">Password</span><br />
            <div class="uk-inline ukid-width-100-percent">
                <span class="uk-form-icon" uk-icon="icon:unlock"></span>
                <input
                    v-if="user.id" 
                    :class="{
                        'ukid-form-error uk-input ukid-width-100-percent': errors.has('password-input_field'), 
                        'uk-input ukid-width-100-percent': !errors.has('password-input_field')
                    }"
                    v-model="user.password"
                    v-validate="'min:4|max:30'" 
                    data-vv-as="field"
                    id="password-input_field"
                    name="password-input_field"
                    type="password" 
                    placeholder="Se não preenchido manterá a senha atual, máximo 30 caracteres">
                <input
                    v-if="!user.id" 
                    :class="{
                        'ukid-form-error uk-input ukid-width-100-percent': errors.has('password-input_field'), 
                        'uk-input ukid-width-100-percent': !errors.has('password-input_field')
                    }"
                    v-model="user.password"
                    v-validate="'required|min:4|max:30'" 
                    data-vv-as="field"
                    id="password-input_field"
                    name="password-input_field"
                    type="password" 
                    placeholder="Se não preenchido manterá a senha atual, máximo 30 caracteres">
            </div>
        </div>

        <div v-if="vm.user.auth.includes('admin') || (strings.isNullOrEmpty(vm.user.owner) || vm.user.sub === vm.user.owner)">

            <div class="uk-margin">
                <div class="uk-inline ukid-width-100-percent">
                    <article class="uk-comment">
                        <header class="uk-comment-header">
                            <div class="uk-grid-medium uk-flex-middle" uk-grid>
                                <div class="uk-width-expand">
                                    <h4 class="uk-comment-title uk-margin-remove ukid-white-color"><a class="uk-link-reset" href="#">OPÇÕES DE SEGURANÇA</a></h4>
                                </div>
                            </div>
                        </header>
                        <div class="uk-comment-body">
                            <p>
                                Os campos abaixo podem ser utilizados para aumentar a segurança da credencial que está sendo criada. 
                                <br />
                                Se habilitar a criptografia na API, lembre-se que você terá que implementar do lado de sua aplicação, o decript para os dados sensiveis.
                                <br />
                                Caso opte pela opção acima entre em contato com <a href="mailto:suporte@unike.tech">suporte@unike.tech</a>
                            </p>
                        </div>
                    </article>
                </div>
            </div>

            <div class="uk-margin">
                <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                    Enviar Avisos<br />de Eventos da<br />Credencial Para:
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Enviar Avisos de Eventos da Credencial Para:
                    <br />
                    <span class="ukid-font-size-9 ukid-text-alert-color">
                        Ex: Credenciais próximas do vencimento ou avisos de bloqueio
                    </span>                                                 
                </span>

                <multiselect v-model="user.selectedCredentialWarningEmails"
                            :options="emailsCredentialWarning"
                            :multiple="true" 
                            :taggable="true"
                            selectLabel="Enter para marcar"
                            deselectLabel="Enter para desmarcar"
                            selectedLabel="Email marcada"
                            tag-placeholder="Adicionar email" 
                            placeholder="Adicionar email"
                            @tag="addCredentialEmail"></multiselect>

                    <template slot="noResult">
                        <span>
                            Nenhuma email encontrado
                        </span>
                    </template>

                    <template slot="noOptions">
                        <span>
                            Comece a digitar
                        </span>
                    </template>

                    <template slot="tag" slot-scope="{ option }">
                        <div class="multiselect__tag uk-text-center ukid-width-270-px">
                            <span>
                                {{option}}
                            </span>
                        </div>
                    </template>                                           

            </div>

            <div class="uk-margin">
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Habilitar<br />Criptografia na API
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Habilitar Criptografia na API
                    <br />
                    <span class="ukid-font-size-9 ukid-text-alert-color">
                        Os dados considerados sensiveis, serão criptografados no retorno da API
                    </span>                                            
                </span>
                <div class="uk-inline ukid-width-100-percent">
                    <div :class="{
                            'ukid-width-100-percent': errors.has('enabled-input_field'), 
                            'uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                        <label>
                            <input v-model="user.enabledEncryptionAPI" value="true" class="uk-radio" type="radio" id="enabled-encryption-api-type-input_yes_field" name="enabled-encryption-api-type-input_field"> SIM
                        </label>
                        <label>
                            <input v-model="user.enabledEncryptionAPI" value="false" class="uk-radio" type="radio" id="enabled-encryption-api-type-input_no_field" name="enabled-encryption-api-type-input_field" checked> NÃO
                        </label>
                    </div>
                </div>
            </div>

            <div class="uk-margin">
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Habilitar Bloqueio<br />de Credenciais?
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Habilitar Bloqueio de Credenciais?
                </span>
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <div :class="{
                            'ukid-width-100-percent': errors.has('enabled-input_field'), 
                            'uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                        <label>
                            <input v-model="user.enabledCredentialLock" value="true" class="uk-radio" type="radio" id="enabled-credential-lock-type-input_yes_field" name="enabled-credential-lock-type-input"> SIM
                        </label>
                        <label>
                            <input v-model="user.enabledCredentialLock" value="false" class="uk-radio" type="radio" id="enabled-credential-lock-type-input_no_field" name="enabled-credential-lock-type-input" checked> NÃO
                        </label>
                    </div>
                </div>
            </div>

            <div class="uk-margin">
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Habilitar Expiração<br />de Password
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Habilitar Expiração de Password
                </span>
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <div :class="{
                            'ukid-width-100-percent': errors.has('enabled-input_field'), 
                            'uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                        <label>
                            <input v-model="user.enabledPasswordExpiration" value="true" class="uk-radio" type="radio" id="enabled-password-expiration-type-input_yes_field" name="enabled-password-expiration-type-input_field"> SIM
                        </label>
                        <label>
                            <input v-model="user.enabledPasswordExpiration" value="false" class="uk-radio" type="radio" id="enabled-password-expiration-type-input_no_field" name="enabled-password-expiration-type-input_field" checked> NÃO
                        </label>
                    </div>
                </div>
            </div>

            <div class="uk-margin">
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Credencial Bloqueada?
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Credencial Bloqueada?
                </span>
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <div :class="{
                            'ukid-width-100-percent': errors.has('enabled-input_field'), 
                            'uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('enabled-input_field')}">
                        <label>
                            <input v-model="user.blockedAccess" value="true" class="uk-radio" type="radio" id="blocked-access-type-input_yes_field" name="blocked-access-type-input_field"> SIM
                        </label>
                        <label>
                            <input v-model="user.blockedAccess" value="false" class="uk-radio" type="radio" id="blocked-access-type-input_no_field" name="blocked-access-type-input_field" checked> NÃO
                        </label>
                    </div>
                </div>
            </div>                                    

            <div v-if="user.lastPasswordTimestamp" class="uk-margin">
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Data da Última<br />Troca de Senha
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Data da Última Troca de Senha
                </span>

                <div class="uk-inline ukid-text-alert-color ukid-width-100-percent">
                    {{new Date(user.lastPasswordTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                </div>
            </div>

            <div class="uk-margin">   
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Tamanho Mínimo<br />do Password
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Tamanho Mínimo do Password
                </span>                                        
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <input
                        :class="{
                            'ukid-form-error uk-input ukid-width-100-percent': errors.has('minPasswordLength-input_field'), 
                            'uk-input ukid-width-100-percent': !errors.has('minPasswordLength-input_field')
                        }"                                  
                        v-model="user.minPasswordLength"
                        v-validate="'required|max:2|numeric'" 
                        data-vv-as="field"
                        id="minPasswordLength-input_field"
                        name="minPasswordLength-input_field"
                        type="text" 
                        placeholder="Número mínimo de caracteres que o password deve conter">
                </div>
            </div>

            <div class="uk-margin">
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Dias para Expiração<br />do Password
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Dias para Expiração do Password
                </span>                                         
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <input
                        :class="{
                            'ukid-form-error uk-input ukid-width-100-percent': errors.has('expirationDaysPassword-input_field'), 
                            'uk-input ukid-width-100-percent': !errors.has('expirationDaysPassword-input_field')
                        }"                                  
                        v-model="user.expirationDaysPassword"
                        v-validate="'required|max:3|numeric'" 
                        data-vv-as="field"
                        id="expirationDaysPassword-input_field"
                        name="expirationDaysPassword-input_field"
                        type="text" 
                        placeholder="Dias para expiração do password">
                </div>
            </div>

            <div class="uk-margin">   
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Password Não Pode<br />Repetir os Últimos
                </span>
                <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                    Password Não Pode Repetir os Últimos
                </span>                                        
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <input
                        :class="{
                            'ukid-form-error uk-input ukid-width-100-percent': errors.has('totalPasswordNonRepetition-input_field'), 
                            'uk-input ukid-width-100-percent': !errors.has('totalPasswordNonRepetition-input_field')
                        }"                                  
                        v-model="user.totalPasswordNonRepetition"
                        v-validate="'required|max:2|numeric'" 
                        data-vv-as="field"
                        id="totalPasswordNonRepetition-input_field"
                        name="totalPasswordNonRepetition-input_field"
                        type="text" 
                        placeholder="Valor de não repetição">
                </div>
            </div>

            <div class="uk-margin">   
                <span class="uk-label ukid-primary-label-color">
                    Chances para Autenticação
                    <br />
                    <span class="uk-label ukid-font-size-9 ukid-text-alert-color">
                        Após as tentativas a credencial será bloqueada
                    </span>                                                
                </span>
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <input
                        :class="{
                            'ukid-form-error uk-input ukid-width-100-percent': errors.has('totalPasswordFailures-input_field'), 
                            'uk-input ukid-width-100-percent': !errors.has('totalPasswordFailures-input_field')
                        }"                                  
                        v-model="user.totalPasswordFailures"
                        v-validate="'required|max:2|numeric'" 
                        data-vv-as="field"
                        id="totalPasswordFailures-input_field"
                        name="totalPasswordFailures-input_field"
                        type="text" 
                        placeholder="Total de chances">
                </div>
            </div>
        </div>

    </li>

</template>