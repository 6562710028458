var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ukid-padding-top-30 ukid-min-height" }, [
    _c("div", { staticClass: "uk-flex uk-flex-center" }, [
      _c(
        "form",
        {
          staticClass: "ukid-width-300-px",
          attrs: { id: "form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login()
            }
          }
        },
        [
          _c("fieldset", { staticClass: "uk-fieldset" }, [
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "span",
                { staticClass: "ukid-white-color ukid-font-size-14" },
                [_vm._v("Username")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-inline ukid-width-100-percent ukid-margin-top-10"
                },
                [
                  _c("span", {
                    staticClass: "uk-form-icon",
                    attrs: { "uk-icon": "icon:user" }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.credentials.username,
                        expression: "credentials.username"
                      }
                    ],
                    staticClass: "uk-input ukid-width-300-px",
                    attrs: {
                      id: "username-input",
                      type: "text",
                      placeholder: "Usuário"
                    },
                    domProps: { value: _vm.credentials.username },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.credentials,
                          "username",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin" }, [
              _c(
                "span",
                { staticClass: "ukid-white-color ukid-font-size-14" },
                [_vm._v("Password")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-inline ukid-width-100-percent ukid-margin-top-10"
                },
                [
                  _c("span", {
                    staticClass: "uk-form-icon",
                    attrs: { "uk-icon": "icon:unlock" }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.credentials.password,
                        expression: "credentials.password"
                      }
                    ],
                    staticClass: "uk-input ukid-width-300-px",
                    attrs: {
                      id: "password-input",
                      type: "password",
                      placeholder: "Senha"
                    },
                    domProps: { value: _vm.credentials.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.credentials,
                          "password",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _vm.error && _vm.error.status == 401
              ? _c(
                  "div",
                  { staticClass: "ukid-login-text-danger uk-text-center" },
                  [
                    _c("p", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.error.body.description)
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0)
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ukid-padding-top-20" }, [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-default uk-button-large ukid-width-300-px ukid-font-size-14",
          attrs: { type: "submit" }
        },
        [_c("span", { staticClass: "ukid-white-color" }, [_vm._v("Logar")])]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-09d264bf", { render: render, staticRenderFns: staticRenderFns })
  }
}