var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: _vm.modalId, "uk-modal": "" } }, [
    _c(
      "div",
      {
        staticClass:
          "uk-modal-dialog uk-modal-body uk-width-auto uk-margin-auto-vertical modal-height uk-border-rounded"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "body-text", staticStyle: { "font-weight": "bold" } },
          [
            _vm._v(
              "Esta ação removerá permanentemente os dados. Deseja\n            confirmar?"
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "body-text" }, [
          _vm._v("Cadastros: " + _vm._s(_vm.userList))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "uk-text-right" }),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row-reverse",
              gap: "1rem"
            }
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "button uk-border-rounded animated button-default uk-modal-close",
                on: { click: _vm.deleteGlobal }
              },
              [
                _c("span", {
                  staticClass: "uk-margin-small-right",
                  attrs: { "uk-icon": "icon:check; ratio:1" }
                }),
                _vm._v("\n                Confirmar\n            ")
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "button uk-border-rounded button-delete animated uk-modal-close",
                on: { click: _vm.clear }
              },
              [
                _c("span", {
                  staticClass: "uk-margin-small-right",
                  attrs: { "uk-icon": "icon:refresh; ratio:1" }
                }),
                _vm._v("\n                Cancelar\n            ")
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("p")
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-container" }, [
      _c("h2", { staticClass: "uk-text-bolder title" }, [_vm._v("Atenção")]),
      _vm._v(" "),
      _c("h2", {
        staticClass: "uk-modal-close animated",
        attrs: { "uk-icon": "icon: close; ratio: 1.7" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1602b503", { render: render, staticRenderFns: staticRenderFns })
  }
}