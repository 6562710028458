var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-invite-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.App.messages.popoUpButtonCancel) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.del(_vm.invitePerson.id)
                  }
                }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.App.messages.popoUpButtonDelete) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "modal-overflow-resend", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.App.messages.popoUpButtonCancel) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: { click: _vm.save }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.App.messages.popoUpButtonOk) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color",
          attrs: { id: "ukid-filter" }
        },
        [
          _c(
            "dl",
            {
              staticClass:
                "uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center"
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl"
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ukid-padding ukid-padding-10px ukid-font-size-15"
                    },
                    [
                      _vm._v(
                        "\n                    Criação, atualização e exclusão de galerias\n                    "
                      ),
                      _c("br"),
                      _vm._v(
                        "Total: " +
                          _vm._s(_vm.pagination.databaseCount) +
                          "\n                "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("br", { staticClass: "uk-hidden@m uk-hidden@l uk-hidden@xl" }),
              _vm._v(" "),
              _c("dt", [_vm._v("\n                Pesquisar\n            ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParam,
                      expression: "searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-width-80-percent",
                  attrs: {
                    id: "search-param-input",
                    type: "text",
                    placeholder: "Pesquise qualquer termo"
                  },
                  domProps: { value: _vm.searchParam },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchParam = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6",
                  attrs: { "uk-icon": "icon:search;ratio:1.4" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "uk-hidden ukid-search-spinner ukid-magin-left-6",
                  attrs: { "uk-spinner": "ratio:0.92" }
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.clear(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Iniciar Novo Registro\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.registers, function(invitePerson) {
                return _c("dt", { key: invitePerson.id }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-label ukid-width-70-percent ukid-padding-10px"
                    },
                    [
                      invitePerson.documentNumber
                        ? _c("span", [
                            _vm._v(
                              "N. Docto: " + _vm._s(invitePerson.documentNumber)
                            ),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      invitePerson.name
                        ? _c("span", [
                            _vm._v("Nome: " + _vm._s(invitePerson.name)),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      invitePerson.email
                        ? _c("span", [
                            _vm._v("Email: " + _vm._s(invitePerson.email)),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      invitePerson.phone
                        ? _c("span", [
                            _vm._v("Tel: " + _vm._s(invitePerson.phone)),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      invitePerson.datetime
                        ? _c("span", [
                            _vm._v(
                              "Envio: " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    new Date(invitePerson.datetime),
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                )
                            ),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      invitePerson.concludedDatetime
                        ? _c("span", [
                            _vm._v(
                              "Aceite: " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    new Date(invitePerson.concludedDatetime),
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                )
                            ),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "ukid-icon-yellow-color ukid-cursor-pointer",
                        attrs: {
                          href: "#ukid-plus-animation-" + invitePerson.id,
                          "uk-toggle":
                            "target: #ukid-plus-animation-" +
                            invitePerson.id +
                            "; animation: uk-animation-fade; style: display:block;",
                          "uk-icon": "icon:plus;ratio:1.0"
                        }
                      }),
                      _vm._v(" "),
                      invitePerson.concluded && !invitePerson.renovation
                        ? _c("span", {
                            staticClass:
                              "ukid-invite-success ukid-magin-left-10",
                            attrs: {
                              title: "Cadastro Concluído",
                              "uk-tooltip": "",
                              "uk-icon": "icon:check;ratio:1.2"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !invitePerson.concluded
                        ? _c("span", {
                            staticClass:
                              "ukid-invite-waiting ukid-magin-left-10",
                            attrs: {
                              title: "Aguardando Cadastramento",
                              "uk-tooltip": "",
                              "uk-icon": "icon:future;ratio:0.9"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      invitePerson.concluded && invitePerson.renovation
                        ? _c("span", {
                            staticClass: "ukid-invite-success",
                            attrs: {
                              title: "Renovação Concluída",
                              "uk-tooltip": "",
                              "uk-icon": "icon:refresh;ratio:1.0"
                            }
                          })
                        : _vm._e(),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticClass:
                            "ukid-width-100-percent ukid-font-size-9",
                          attrs: {
                            hidden: "",
                            id: "ukid-plus-animation-" + invitePerson.id
                          }
                        },
                        _vm._l(invitePerson.users, function(users) {
                          return _c("tr", { key: users.user }, [
                            _c(
                              "td",
                              { attrs: { align: "right", width: "65%" } },
                              [_vm._v("Cadastro esperado para:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "ukid-padding-left-5",
                                attrs: { align: "left", width: "35%" }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(users) +
                                    "\n                            "
                                )
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  ),
                  _vm._v(" "),
                  invitePerson && !invitePerson.concluded
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: {
                          "uk-icon": "icon:forward;ratio:1.1",
                          title: invitePerson.concluded
                            ? "Renovar Convite"
                            : "Reenviar Convite",
                          "uk-tooltip": ""
                        },
                        on: {
                          click: function($event) {
                            _vm.resend(Object.assign({}, invitePerson))
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  invitePerson
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:file-edit;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.fill(Object.assign({}, invitePerson))
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  invitePerson
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.modal(Object.assign({}, invitePerson))
                          }
                        }
                      })
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registers.length,
                  expression: "!registers.length"
                }
              ],
              staticClass:
                "uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30",
              attrs: { "uk-alert": "" }
            },
            [
              _c("span", {
                staticClass:
                  "uk-form-icon ukid-search-spinner ukid-magin-left-10",
                attrs: { "uk-icon": "icon:ban" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ukid-magin-left-20" }, [
                _vm._v(
                  "\n                Nenhum registro encontrado\n            "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16"
            },
            [
              _vm._v("Pagina Atual: "),
              _c("b", { staticClass: "ukid-magin-left-10" }, [
                _vm._v(_vm._s(_vm.page))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "v-model": _vm.page,
                  "page-count": _vm.pagination.last_page,
                  "click-handler": _vm.list,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-90-percent": _vm.Commons.isMobile(),
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent": !_vm.Commons.isMobile()
          },
          attrs: { id: "ukid-card-form" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-card-header ukid-supress-border-bottom uk-visible@m"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-150",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c("span", { staticClass: "ukid-font-size-18" }, [
                      _vm._v("Gestão de Convites para Cadastramento Facial")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", [
                        _vm._v(
                          "                                \n                            Criação, atualização e exclusão de convites de cadastramento facial"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                            Este módulo permite o envio de convites para cadastramento facial"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                            Após o preenchimeto dos campos abaixo, um convite será enviado a pessoa candidata a cadastramento facial"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-label ukid-label-yellow-color" },
                          [
                            _vm._v(
                              "Convites: " +
                                _vm._s(_vm.pagination.databaseCount)
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("progress", {
            staticClass:
              "uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0",
            attrs: { value: "0", max: "100" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "uk-card-footer ukid-supress-border-top": !_vm.Commons.isMobile(),
                "uk-card-footer ukid-supress-border-top ukid-card-footer-mobile": _vm.Commons.isMobile()
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "ukid-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "uk-fieldset" },
                    [
                      _c("legend", { staticClass: "uk-legend" }, [
                        _c(
                          "span",
                          { staticClass: "ukid-font-size-25 uk-visible@m" },
                          [_vm._v("Formulário")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.clear(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Iniciar Novo Registro\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          { staticClass: "uk-label ukid-primary-label-color" },
                          [
                            _vm._v(
                              "\n                            Acesso Permanente\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("input", {
                          staticClass:
                            "ukid-permanent-access uk-checkbox ukid-margin-top-10",
                          attrs: { type: "checkbox" },
                          on: { click: _vm.permanentAccess }
                        })
                      ]),
                      _vm._v(" "),
                      !_vm.Commons.isMobile()
                        ? _c(
                            "div",
                            {
                              class: {
                                "uk-margin ukid-margin-bottom-40": !_vm.permAccess,
                                "uk-margin uk-hidden ukid-margin-bottom-40":
                                  _vm.permAccess
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-primary-label-color"
                                },
                                [_vm._v("Intervalo de Acesso")]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("div", { attrs: { "uk-grid": "" } }, [
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      "\n                                DE:\n                                "
                                    ),
                                    _c("datetime", {
                                      attrs: {
                                        type: "datetime",
                                        "input-class":
                                          "mx-input ukid-width-100-percent",
                                        "value-zone": "America/Sao_Paulo",
                                        zone: "America/Sao_Paulo",
                                        format: "dd/MM/yyyy HH:mm",
                                        phrases: {
                                          ok: "Definir Hora",
                                          cancel: "Sair"
                                        },
                                        "hour-step": 1,
                                        "minute-step": 5,
                                        "min-datetime": new Date(
                                          new Date().setMinutes(
                                            new Date().getMinutes() - 5
                                          )
                                        ).toISOString(),
                                        "week-start": 7,
                                        auto: true,
                                        "use24-hour": ""
                                      },
                                      model: {
                                        value:
                                          _vm.invitePerson.accessStartDatetime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.invitePerson,
                                            "accessStartDatetime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "invitePerson.accessStartDatetime"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      "\n                                ATÉ:\n                                "
                                    ),
                                    _c("datetime", {
                                      attrs: {
                                        type: "datetime",
                                        "input-class":
                                          "mx-input ukid-width-100-percent",
                                        "value-zone": "America/Sao_Paulo",
                                        zone: "America/Sao_Paulo",
                                        format: "dd/MM/yyyy HH:mm",
                                        phrases: {
                                          ok: "Definir Hora",
                                          cancel: "Sair"
                                        },
                                        "hour-step": 1,
                                        "minute-step": 5,
                                        "min-datetime": new Date(
                                          new Date().setHours(
                                            new Date().getHours() + 1
                                          )
                                        ).toISOString(),
                                        "week-start": 7,
                                        auto: true,
                                        "use24-hour": ""
                                      },
                                      model: {
                                        value:
                                          _vm.invitePerson.accessEndDatetime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.invitePerson,
                                            "accessEndDatetime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "invitePerson.accessEndDatetime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.Commons.isMobile()
                        ? _c(
                            "div",
                            {
                              class: {
                                "uk-margin ukid-margin-bottom-40": !_vm.permAccess,
                                "uk-margin uk-hidden ukid-margin-bottom-40":
                                  _vm.permAccess
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "uk-label ukid-primary-label-color"
                                },
                                [_vm._v("Intervalo de Acesso")]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                        DE:\n                        "
                              ),
                              _c("datetime", {
                                attrs: {
                                  type: "datetime",
                                  "input-class":
                                    "mx-input ukid-width-90-percent",
                                  "value-zone": "America/Sao_Paulo",
                                  zone: "America/Sao_Paulo",
                                  format: "dd/MM/yyyy HH:mm",
                                  phrases: {
                                    ok: "Definir Hora",
                                    cancel: "Sair"
                                  },
                                  "hour-step": 1,
                                  "minute-step": 5,
                                  "min-datetime": new Date(
                                    new Date().setMinutes(
                                      new Date().getMinutes() - 5
                                    )
                                  ).toISOString(),
                                  "week-start": 7,
                                  auto: true,
                                  "use24-hour": ""
                                },
                                model: {
                                  value: _vm.invitePerson.accessStartDatetime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.invitePerson,
                                      "accessStartDatetime",
                                      $$v
                                    )
                                  },
                                  expression: "invitePerson.accessStartDatetime"
                                }
                              }),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                        ATÉ:\n                        "
                              ),
                              _c("datetime", {
                                attrs: {
                                  type: "datetime",
                                  "input-class":
                                    "mx-input ukid-width-90-percent",
                                  "value-zone": "America/Sao_Paulo",
                                  zone: "America/Sao_Paulo",
                                  format: "dd/MM/yyyy HH:mm",
                                  phrases: {
                                    ok: "Definir Hora",
                                    cancel: "Sair"
                                  },
                                  "hour-step": 1,
                                  "minute-step": 5,
                                  "min-datetime": new Date(
                                    new Date().setHours(
                                      new Date().getHours() + 1
                                    )
                                  ).toISOString(),
                                  "week-start": 7,
                                  auto: true,
                                  "use24-hour": ""
                                },
                                model: {
                                  value: _vm.invitePerson.accessEndDatetime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.invitePerson,
                                      "accessEndDatetime",
                                      $$v
                                    )
                                  },
                                  expression: "invitePerson.accessEndDatetime"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _vm._m(4),
                        _vm._v(" "),
                        _vm._m(5),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.invitePerson.documentNumber,
                                  expression: "invitePerson.documentNumber"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:3|max:40|numeric",
                                  expression: "'required|min:3|max:40|numeric'"
                                }
                              ],
                              class: {
                                "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                                  "documentNumber-input_field"
                                ),
                                "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                                  "documentNumber-input_field"
                                )
                              },
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "documentNumber-input_field",
                                name: "documentNumber-input_field",
                                type: "text",
                                placeholder:
                                  "Mínimo 3, máximo 40, número pelo qual o usuário se identifica"
                              },
                              domProps: {
                                value: _vm.invitePerson.documentNumber
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.invitePerson,
                                    "documentNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ukid-width-100-percent ukid-margin-top-10"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-default uk-button-small ukid-font-size-14",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.fillByDocumentNumber(
                                      _vm.invitePerson.documentNumber
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                Verificar Pessoa\n                            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.person.documentNumber,
                                expression: "person.documentNumber"
                              }
                            ],
                            staticClass:
                              "uk-inline ukid-width-100-percent ukid-margin-top-10"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Pessoa Encontrada: " +
                                  _vm._s(_vm.person.documentNumber) +
                                  " - " +
                                  _vm._s(
                                    _vm.person.name !== "no_name_available"
                                      ? _vm.person.name
                                      : "Nome indisponível"
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.person.documentNumber,
                                    expression: "person.documentNumber"
                                  }
                                ],
                                staticClass:
                                  "ukid-width-60-px ukid-margin-top-10"
                              },
                              [
                                _vm.person.photo && _vm.person.highlightBlcFace
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ukid-light-red-color ukid-circular-person-edit",
                                        attrs: {
                                          title:
                                            "Esta pessoa foi marcada como ponto de atenção",
                                          "uk-tooltip": ""
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "ukid-circular-portrait",
                                          attrs: { src: _vm.person.photo }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.person.photo && !_vm.person.highlightBlcFace
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ukid-light-blue-color ukid-circular-person-edit"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "ukid-circular-portrait",
                                          attrs: { src: _vm.person.photo }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
                          },
                          [
                            _vm._v(
                              "\n                            Nome do Convidado\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(6),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.invitePerson.name,
                                  expression: "invitePerson.name"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:3|max:80",
                                  expression: "'required|min:3|max:80'"
                                }
                              ],
                              class: {
                                "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                                  "name-input_field"
                                ),
                                "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                                  "name-input_field"
                                )
                              },
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "name-input_field",
                                name: "name-input_field",
                                type: "text",
                                placeholder:
                                  "Mínimo 3, máximo 80, nome pelo qual o usuário se identifica"
                              },
                              domProps: { value: _vm.invitePerson.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.invitePerson,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
                          },
                          [
                            _vm._v(
                              "\n                            Cartão de Acesso (Para Controle de Acesso)\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(7),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.invitePerson.cardNumber,
                                  expression: "invitePerson.cardNumber"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:3|max:40|numeric",
                                  expression: "'min:3|max:40|numeric'"
                                }
                              ],
                              class: {
                                "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                                  "cardNumber-input_field"
                                ),
                                "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                                  "cardNumber-input_field"
                                )
                              },
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "cardNumber-input_field",
                                name: "cardNumber-input_field",
                                type: "text",
                                placeholder:
                                  "Mínimo 3, máximo 40, não obrigatório"
                              },
                              domProps: { value: _vm.invitePerson.cardNumber },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.invitePerson,
                                    "cardNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _vm._m(8),
                        _vm._v(" "),
                        _vm._m(9),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("email-input_field"),
                                expression: "errors.has('email-input_field')"
                              }
                            ],
                            staticClass: "uk-alert-danger ukid-margin-top-5",
                            attrs: { "uk-alert": "" }
                          },
                          [
                            _c("b", [_vm._v("ATENÇÃO:")]),
                            _vm._v(" Email Inválido\n                        ")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.invitePerson.email,
                                  expression: "invitePerson.email"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:3|max:80|email",
                                  expression: "'required|min:3|max:80|email'"
                                }
                              ],
                              class: {
                                "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                                  "email-input_field"
                                ),
                                "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                                  "email-input_field"
                                )
                              },
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "email-input_field",
                                name: "email-input_field",
                                type: "text",
                                placeholder:
                                  "Mínimo 3, máximo 80, Não use acentos"
                              },
                              domProps: { value: _vm.invitePerson.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.invitePerson,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
                          },
                          [
                            _vm._v(
                              "\n                            Telefone do Convidado\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(10),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("phone-input_field"),
                                expression: "errors.has('phone-input_field')"
                              }
                            ],
                            staticClass: "uk-alert-danger ukid-margin-top-5",
                            attrs: { "uk-alert": "" }
                          },
                          [
                            _c("b", [_vm._v("ATENÇÃO:")]),
                            _vm._v(" Ex: 11988442299\n                        ")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-inline ukid-width-100-percent" },
                          [
                            _c("span", {
                              staticClass: "uk-form-icon",
                              attrs: { "uk-icon": "icon:warning" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.invitePerson.phone,
                                  expression: "invitePerson.phone"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:11|max:11|numeric",
                                  expression: "'min:11|max:11|numeric'"
                                }
                              ],
                              class: {
                                "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
                                  "phone-input_field"
                                ),
                                "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
                                  "phone-input_field"
                                )
                              },
                              attrs: {
                                "c-data-vv-as": "field",
                                id: "phone-input_field",
                                name: "phone-input_field",
                                type: "text",
                                placeholder: "Ex: 11988997723, não obrigatório"
                              },
                              domProps: { value: _vm.invitePerson.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.invitePerson,
                                    "phone",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("group-component", {
                        ref: "inviteGroupCpnt",
                        attrs: {
                          id: "invite-group-select_field",
                          name: "invite-group-select_field",
                          label: "Grupos",
                          description:
                            "(Ctrl + Clique) para mais de um grupo<br />Ao definir um grupo no convite, o cadastro facial<br />do convidado ficará disponível para o(s)<br />usuário(s) do(s) grupo(s) definidos(s)",
                          app: _vm.App,
                          strings: _vm.Strings,
                          commons: _vm.Commons,
                          vm: _vm.vm,
                          entity: _vm.invitePerson
                        }
                      }),
                      _vm._v(" "),
                      _c("users-component", {
                        ref: "inviteUsersCpnt",
                        attrs: {
                          id: "invite-users-select_field_1",
                          name: "invite-users-select_field_1",
                          required: "required",
                          label: "Usuário",
                          app: _vm.App,
                          strings: _vm.Strings,
                          commons: _vm.Commons,
                          vm: _vm.vm,
                          entity: _vm.invitePerson
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uk-flex uk-flex-right uk-visible@s" },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-visible@m" },
                            [
                              _c("router-link", { attrs: { to: "/home" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "uk-button uk-button-default uk-button-large uk-align-center"
                                  },
                                  [_vm._v("Cancelar")]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-hidden@m" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-default uk-button-large uk-align-center",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(11)
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40"
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(12)
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "uk-modal-body ukid-font-size-18",
        attrs: { "uk-overflow-auto": "" }
      },
      [
        _c("p", [
          _vm._v(
            "\n                    Tenha ciência de que ao fazer esta operação para uma pessoa já cadastrada, apenas estará renovando seu acesso.\n                    Nenhuma ação do convidado será necessária, pois sua face já se encontra em nossa base\n                "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner-sm",
          attrs: { "uk-spinner": "ratio:2.6" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon-sm ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:social;ratio:3.6" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-width-auto ukid-min-width-165" }, [
      _c("span", {
        staticClass: "uk-hidden ukid-spinner",
        attrs: { "uk-spinner": "ratio:4" }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "ukid-icon ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6",
        attrs: { "uk-icon": "icon:social;ratio:5.2" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-margin" }, [
      _c("div", { staticClass: "js-upload uk-placeholder uk-text-center" }, [
        _c(
          "a",
          {
            staticClass: "ukid-font-size-15",
            attrs: {
              href:
                "https://upload-staging.unike.tech/uploads/docs/unike-invite-modelo.xlsx"
            }
          },
          [_vm._v("baixar planilha modelo")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { attrs: { "uk-icon": "icon:cloud-upload;ratio:1.3" } }),
        _vm._v(" "),
        _c("span", { staticClass: "uk-text-middle ukid-font-size-15" }, [
          _vm._v("Envie a planilha baixada e devidamente preenchida aqui")
        ]),
        _vm._v(" "),
        _c("div", { attrs: { "uk-form-custom": "" } }, [
          _c("input", { attrs: { type: "file", multiple: "" } }),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "uk-link ukid-cursor-pointer ukid-font-size-15" },
            [_vm._v("selecione aqui")]
          )
        ]),
        _vm._v(" "),
        _c("br")
      ]),
      _vm._v(" "),
      _c("progress", {
        staticClass: "uk-progress",
        attrs: {
          id: "ukid-js-progressbar-invite",
          value: "0",
          max: "100",
          hidden: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n                            Número do Documento do Convidado\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            CPF, RG ou OUTROS\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            (Sem traços ou pontos)\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n                            Número\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            do Documento / Convidado\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            CPF, RG ou OUTROS\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            (Sem traços ou pontos)\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n                            Nome\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            do Convidado\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n                            Cartão de Acesso\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            (Para Controle de Acesso)\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _vm._v("\n                            Email do Convidado"),
        _c("br"),
        _vm._v(
          "\n                            OBS: Sem Acentos\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n                            Email\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            do Convidado\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            OBS: Sem Acentos                                \n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12"
      },
      [
        _vm._v(
          "\n                            Telefone\n                            "
        ),
        _c("br"),
        _vm._v(
          "\n                            do Convidado\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-default uk-button-large uk-margin-left",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-aad27ad2", { render: render, staticRenderFns: staticRenderFns })
  }
}