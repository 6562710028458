<script>

    import UIkit from 'uikit'

    import Multiselect from 'vue-multiselect'

    import { Datetime } from 'vue-datetime'

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'editor', 'editorConfig', 'user', 'auth', 'role', 'vm'],
        
        components: {

            Multiselect,
            datetime: Datetime,

        },

        data() {

            return {

                emails: [],
                markings: null,
                markingPage: 1,
                markingsPagination: {
                    last_page: 0
                },
                generateAfdFilter: {
                    startDate: null,
                    endDate: null                    
                }

            }

        },

        created() {

            let _ = this

            _.listMarkings()

        },

        methods: {

            listMarkings(markingPage) {

                let _ = this

                if (markingPage !== undefined) {
                
                    _.markingPage = markingPage

                }
                
                _.$http.get(

                    _.app.host.concat(_.app.listMarkingEndpoint)
                    + '?pageNum=' + (markingPage === undefined ? 1 : markingPage)
                    + '&perPage=' + _.app.pagination.itensPerPage
                    + '&username=' + (!_.strings.isNullOrEmpty(_.user.username) ? _.user.username : _.vm.user.sub)

                ).then(response => {

                    _.markings = response.body.data
                    _.markingsPagination = response.body
                    
                })

            },

            downloadMarkFile(marking, startDate, endDate) {

                let _ = this
            
                var modal_
                var endpoint

                if (startDate !== null && endDate !== null) {

                    endpoint = `${_.app.host}${_.app.downloadMarkingEndpoint}${_.vm.user.sub}/${new Date(startDate).getTime()}/${new Date(endDate).getTime()}`

                } else {

                    endpoint = `${_.app.host}${_.app.downloadMarkingEndpoint}${marking.id}`

                }

                _.$http.get(endpoint, {

                    before(request) {

                        modal_ = UIkit.modal.dialog(_.app.notification.download, { escClose: false, bgClose: false })

                    }

                }).then(response => {
                    
                    let blob = new Blob([response.data], {type: 'text/plain'})

                    var filename
                    
                    if (startDate !== null && endDate !== null) {
                    
                        filename = `afd-${this.$moment(startDate).format("DD/MM/YYYY HH:mm")}_${this.$moment(endDate).format("DD/MM/YYYY HH:mm")}_${_.vm.user.sub}.txt`

                    } else {
                        
                        filename = marking.name
                        
                    }

                    let result = document.createElement('a')
                    result.href = window.URL.createObjectURL(blob)
                    result.download = filename
                    result.click()

                    modal_.hide()

                }).catch( function(err){

                    modal_.hide()

                    if (err.status == 404) {

                        alert('Nenhum registro encontrado')

                    } else {

                        alert(err.body.description)

                    }
                    
                }) 

            },

            addEmail(email) {

                let _ = this

                _.emails.push(email)
                _.user.selectedAfdEmails.push(email)

            }            

        }
        
    }

</script>

<template id="register-marks-template">

    <!-- Marks -->
    <li>
        
        <span class="ukid-icon-purple-color ukid-margin-top-40 ukid-font-size-25">
            Ponto e Comunicação com RH
        </span>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                Habilitar Preenchimento de Turno no Cadastro de Pessoa
                <br />
                <span class="ukid-text-alert-color ukid-font-size-9">
                    Utilize essa opção para sistemas de marcação de ponto
                    <br />
                    Geralmente utilizado para sistemas de ponto por CFTV
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                Habilitar Preenchimento de
                <br />
                Turno no Cadastro de Pessoa
                <br />
                <span class="ukid-text-alert-color ukid-font-size-9">
                    Utilize essa opção para
                    <br />
                    sistemas de marcação de ponto
                    <br />
                    Geralmente utilizado para
                    <br />
                    sistemas de ponto por CFTV
                </span>
            </span>
            <br />
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('enabled-input_field'), 
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('workshift-type-input_field')}">
                    <label>
                        <input v-model="user.workShift" value=true class="uk-radio" type="radio" id="workshift-type-input-yes_field" name="workshift-type-input_field"> SIM
                    </label>
                    <label>
                        <input v-model="user.workShift" value=false class="uk-radio" type="radio" id="workshift-type-input-no_field" name="workshift-type-input_field" checked> NÃO
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                Habilitar Geração De Arquivo AFD
                <br />
                <span class="ukid-text-alert-color ukid-font-size-9">
                    Utilize essa opção para sistemas de marcação de ponto
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                Habilitar Geração de
                <br />
                Arquivo AFD
                <br />
                <span class="ukid-text-alert-color ukid-font-size-9">
                    Utilize essa opção para
                    <br />
                    sistemas de marcação de ponto
                </span>
            </span>
            <br />
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('enabled-input_field'), 
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('afd-cron-type-input_field')}">
                    <label>
                        <input v-model="user.generateAFD" value=true class="uk-radio" type="radio" id="afd-cron-type-input-yes_field" name="afd-cron-type-input_field"> SIM
                    </label>
                    <label>
                        <input v-model="user.generateAFD" value=false class="uk-radio" type="radio" id="afd-cron-type-input-no_field" name="afd-cron-type-input_field" checked> NÃO
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:server;ratio:1.2"></span>
                URL de envio do AFD:
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:server;ratio:1.2"></span>
                URL de envio do AFD:
            </span>
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('server-url-afd-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('server-url-afd-select_field')
                }" 
                v-model="user.serverUrlUploadAfd"
                v-validate="'url|min:10|max:300'"
                c-data-vv-as="field"
                id="server-url-afd-select_field"
                name="server-url-afd-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 300" />
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
                Enviar Arquivos AFD Para:
            </span>
            <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
                Enviar Arquivos AFD Para:
                <br />
                <span class="ukid-text-alert-color ukid-font-size-10">
                    Os arquivos AFD serão disparados para
                    <br />
                    os emails configurados no campo abaixo
                </span>
            </span>

            <multiselect v-model="user.selectedAfdEmails"
                        :options="emails"
                        :multiple="true" 
                        :taggable="true"
                        selectLabel="Enter para marcar"
                        deselectLabel="Enter para desmarcar"
                        selectedLabel="Email marcado"
                        tag-placeholder="Adicionar email" 
                        placeholder="Adicionar email"
                        @tag="addEmail"></multiselect>

                <template slot="noResult">
                    <span>
                        Nenhuma email encontrado
                    </span>
                </template>

                <template slot="noOptions">
                    <span>
                        Comece a digitar
                    </span>
                </template>

                <template slot="tag" slot-scope="{ option }">
                    <div class="multiselect__tag uk-text-center ukid-width-270-px">
                        <span>
                            {{option}}
                        </span>
                    </div>
                </template>

        </div>

        <div class="uk-margin">                            
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem de Recebimento de Arquivo AFD por Email
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem de Recebimento<br />de Arquivo AFD por Email
            </span>
            <br /><br />
            <ckeditor :editor="editor" v-model="user.emailMessageAfd" :config="editorConfig"></ckeditor>
        </div>

        <br />

        <hr class="uk-divider-icon">

        <span class="ukid-icon-purple-color ukid-margin-top-40 ukid-font-size-25">
            Comunicação com Funcionário
        </span>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Título do Email da Marcação de Ponto
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Título do Email da<br />Marcação de Ponto
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do funcionário<br />
                <b>{inviting}</b> - Será substituído pelo nome do local onde o ponto foi batido<br />
                <b>{timestamp}</b> - Será substituído pela data do ponto<br />
            </span>
            <br /><br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('email-title-marking-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('email-title-marking-select_field')
                }" 
                v-model="user.emailTitleMarking"
                v-validate="'required|min:10|max:100'"
                c-data-vv-as="field"
                id="email-title-marking-select_field"
                name="email-title-marking-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 1000" />
        </div>

        <div class="uk-margin">                            
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem de Marcação de Ponto Por Email
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:mail;ratio:1.2"></span>
                Mensagem de Marcação<br />de Ponto Por Email
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do funcionário<br />
                <b>{camera}</b> - Será substituído pela última câmera de detecção<br />
                <b>{inviting}</b> - Será substituído pelo nome do local onde o ponto foi batido<br />
                <b>{timestamp}</b> - Será substituído pela data e hora do ponto<br />
            </span>
            <br /><br />
            <ckeditor :editor="editor" v-model="user.emailMessageMarking" :config="editorConfig"></ckeditor>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:whatsapp;ratio:1.2"></span>
                Mensagem de Marcação de Ponto por Whatsapp
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:whatsapp;ratio:1.2"></span>
                Mensagem de Marcação<br />de Ponto por Whatsapp
            </span>
            <br />
            <span class="ukid-font-size-10">
                <b>Chaves de Substituição:</b><br />
                <b>{name}</b> - Será substituído pelo nome do funcionário<br />
                <b>{camera}</b> - Será substituído pela última câmera de detecção<br />
                <b>{inviting}</b> - Será substituído pelo nome do local onde o ponto foi batido<br />
                <b>{timestamp}</b> - Será substituído pela data e hora do ponto<br />
            </span>
            <br /><br />
            <textarea 
                wrap="physical"
                :class="{
                    'ukid-form-error uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px': errors.has('whatsapp-marking_input_field'), 
                    'uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px': !errors.has('whatsapp-marking_input_field')
                }" 
                v-model="user.whatsappMessageMarking"
                v-validate="'required|min:10|max:2000'"
                c-data-vv-as="field"
                id="whatsapp-marking_input_field"
                name="whatsapp-marking_input_field"
                type="text"
                placeholder="Mínimo 10, máximo 2000"></textarea>
        </div>

        <br />

        <hr v-if="user.username === vm.user.sub" class="uk-divider-icon">

        <span v-if="user.username === vm.user.sub" class="ukid-icon-purple-color ukid-margin-top-40 ukid-font-size-25">
            Pontos Gerados
        </span>

        <div v-if="markings !== null && markings.length > 0" class="ukid-margin-top-20">

            <table class="uk-table uk-table-small">
                <thead>
                    <tr>
                        <th class="uk-width-small  uk-text-left ukid-text-alert-color">
                            Gerar uma arquivo AFD manualmente
                            <br />
                            Não será gravado, somente download
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Data Inicio
                            <datetime
                                v-model="generateAfdFilter.startDate"
                                name="start-date-input_field"
                                style="width:160px !important"
                                type="datetime"
                                input-class="mx-input ukid-width-100-percent"
                                value-zone="America/Sao_Paulo"
                                zone="America/Sao_Paulo"
                                format="dd/MM/yyyy HH:mm"
                                :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                :hour-step="1"
                                :minute-step="10"
                                :max-datetime="new Date().toISOString()"
                                :auto="true"></datetime>
                            <br />
                            Data Fim
                            <datetime
                                name="end-date-input_field"
                                v-model="generateAfdFilter.endDate"
                                style="width:160px !important"
                                type="datetime"
                                input-class="mx-input ukid-width-100-percent"
                                value-zone="America/Sao_Paulo"
                                zone="America/Sao_Paulo"
                                format="dd/MM/yyyy HH:mm"
                                :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                :hour-step="1"
                                :minute-step="1"
                                :max-datetime="new Date().toISOString()"
                                :auto="true"></datetime>
                        </td>
                    </tr>
                    <tr>
                        <td align="left" colspan="2">
                            <button @click.prevent="downloadMarkFile(null, generateAfdFilter.startDate, generateAfdFilter.endDate)" type="buttom" class="uk-button uk-button-default uk-button-large ukid-magin-left-5">Baixar</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="uk-table uk-table-small uk-table-striped">
                <thead>
                    <tr>
                        <th class="uk-width-small uk-text-center">
                            Data / Arquivo / Download
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="marking in markings" :key="marking.id">
                        <td class="uk-table-expand uk-text-center">
                            {{new Date(marking.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                            <br />
                            {{marking.name}} 
                            <br />
                            <span title="Baixar Arquivo" uk-tooltip @click="downloadMarkFile(marking, null, null)" class="ukid-text-alert-color ukid-cursor-pointer ukid-magin-left-6" uk-icon="icon:download;ratio:1.0"></span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-show="markingsPagination.last_page > 1" class="uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16">
                Pagina Atual: <b class="ukid-magin-left-10">{{markingPage}}</b>
            </div>

            <ul v-show="markingsPagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>
                <paginate
                    v-model="markingPage"
                    :page-count="markingsPagination.last_page"
                    :click-handler="listMarkings"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>
            </ul>

        </div>
        <div v-else-if="user.username === vm.user.sub">
            Nunhum arquivo AFD gerado
        </div>

    </li>

</template>