var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-devices", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog uk-modal-body" }, [
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label uk-margin-small ukid-primary-label-color uk-visible@s"
              },
              [_vm._v("Snapshots por Página")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Snapshots por Página")]
            ),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-22-percent uk-visible@s" },
              [
                _c("span", {
                  staticClass: "uk-form-icon",
                  attrs: { "uk-icon": "icon:list" }
                }),
                _vm._v(" "),
                _c("the-mask", {
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { mask: ["##", "##"] },
                  model: {
                    value: _vm.imagesPerPage,
                    callback: function($$v) {
                      _vm.imagesPerPage = $$v
                    },
                    expression: "imagesPerPage"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-50-percent uk-hidden@s" },
              [
                _c("span", {
                  staticClass: "uk-form-icon",
                  attrs: { "uk-icon": "icon:list" }
                }),
                _vm._v(" "),
                _c("the-mask", {
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { mask: ["##", "##"] },
                  model: {
                    value: _vm.imagesPerPage,
                    callback: function($$v) {
                      _vm.imagesPerPage = $$v
                    },
                    expression: "imagesPerPage"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Corpos Detectados no Intervalo De:")]
            ),
            _vm._v(" "),
            _vm._m(0),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { attrs: { "uk-grid": "" } }, [
              _c(
                "div",
                { staticClass: "uk-inline ukid-width-20-percent uk-visible@s" },
                [
                  _vm._v(
                    "\n                        DE:\n                        "
                  ),
                  _c("datetime", {
                    attrs: {
                      type: "date",
                      "input-class": "mx-input ukid-width-100-percent",
                      "value-zone": "America/Sao_Paulo",
                      zone: "America/Sao_Paulo",
                      format: "dd/MM/yyyy",
                      phrases: {},
                      "hour-step": 1,
                      "minute-step": 5,
                      "week-start": 7,
                      auto: true,
                      "max-datetime": _vm.endDate,
                      "use24-hour": ""
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "uk-inline ukid-width-20-percent uk-visible@s" },
                [
                  _vm._v(
                    "\n                        ATÉ:\n                        "
                  ),
                  _c("datetime", {
                    attrs: {
                      type: "date",
                      "input-class": "mx-input ukid-width-100-percent",
                      "value-zone": "America/Sao_Paulo",
                      zone: "America/Sao_Paulo",
                      format: "dd/MM/yyyy",
                      phrases: {},
                      "hour-step": 1,
                      "minute-step": 5,
                      "week-start": 7,
                      auto: true,
                      "max-datetime": new Date().toISOString(),
                      "min-datetime": _vm.startDate,
                      "use24-hour": ""
                    },
                    model: {
                      value: _vm.endDate,
                      callback: function($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _vm._v("\n                    DE:\n                    "),
                _c("datetime", {
                  attrs: {
                    type: "date",
                    "input-class": "mx-input ukid-width-100-percent",
                    "value-zone": "America/Sao_Paulo",
                    zone: "America/Sao_Paulo",
                    format: "dd/MM/yyyy",
                    phrases: {},
                    "hour-step": 1,
                    "minute-step": 5,
                    "week-start": 7,
                    auto: true,
                    "max-datetime": _vm.endDate,
                    "use24-hour": ""
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _c("br"),
                _vm._v("\n                    ATÉ:\n                    "),
                _c("datetime", {
                  attrs: {
                    type: "date",
                    "input-class": "mx-input ukid-width-100-percent",
                    "value-zone": "America/Sao_Paulo",
                    zone: "America/Sao_Paulo",
                    format: "dd/MM/yyyy",
                    phrases: {},
                    "hour-step": 1,
                    "minute-step": 5,
                    "week-start": 7,
                    auto: true,
                    "max-datetime": new Date().toISOString(),
                    "min-datetime": _vm.startDate,
                    "use24-hour": ""
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              {
                staticClass:
                  "uk-label uk-margin-small ukid-primary-label-color uk-visible@s"
              },
              [
                _vm._v(
                  "\n                    Somente registros da data atual\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                    Somente registros da data atual\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-22-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentDate,
                    expression: "currentDate"
                  }
                ],
                staticClass: "uk-checkbox",
                attrs: {
                  "true-value": "true",
                  "false-value": "false",
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.currentDate)
                    ? _vm._i(_vm.currentDate, null) > -1
                    : _vm._q(_vm.currentDate, "true")
                },
                on: {
                  click: function($event) {
                    return _vm.currentDateFilter("true")
                  },
                  change: function($event) {
                    var $$a = _vm.currentDate,
                      $$el = $event.target,
                      $$c = $$el.checked ? "true" : "false"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.currentDate = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.currentDate = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.currentDate = $$c
                    }
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "uk-panel uk-panel-scrollable" }, [
            _c(
              "ul",
              { staticClass: "uk-list" },
              _vm._l(_vm.devices, function(device) {
                return _c("li", { key: device.uuid }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDevices,
                          expression: "selectedDevices"
                        }
                      ],
                      staticClass: "uk-checkbox",
                      attrs: { id: device.uuid, type: "checkbox" },
                      domProps: {
                        value: device.uuid,
                        checked: Array.isArray(_vm.selectedDevices)
                          ? _vm._i(_vm.selectedDevices, device.uuid) > -1
                          : _vm.selectedDevices
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectedDevices,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = device.uuid,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectedDevices = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedDevices = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedDevices = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      " " + _vm._s(device.type) + " - " + _vm._s(device.meta)
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "ukid-font-size-10" }, [
                      _vm._v(_vm._s(device.uuid))
                    ]),
                    _vm._v(" "),
                    _vm.sub.indexOf("admin") > -1
                      ? _c("span", { staticClass: "ukid-font-size-10" }, [
                          _vm._v(
                            "\n                                 - " +
                              _vm._s(
                                device.enabled ? "Habilitado" : "Desabilitado"
                              ) +
                              "\n                            "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("p", { staticClass: "uk-text-right uk-visible@s" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v("Não Salvar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveSelectedDevices()
                  }
                }
              },
              [_vm._v("Salvar")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "uk-text-right uk-hidden@s" }, [
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-default uk-button-small uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v("Não Salvar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary uk-button-small uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveSelectedDevices()
                  }
                }
              },
              [_vm._v("Salvar")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "modal-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonCancel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.del()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonDelete))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent"
        },
        [
          _c(
            "div",
            { staticClass: "uk-card-header ukid-supress-border-bottom" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-60",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-20 ukid-margin-top-40 uk-visible@s"
                      },
                      [_vm._v("Pessoas nas Proximidades")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-16 ukid-margin-top-40 uk-hidden@s"
                      },
                      [_vm._v("Pessoas nas Proximidades")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(
                          "                                \n                            Módulo piloto para detecção de corpos nas proximidades das câmeras RTSP\n                            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-label ukid-label-blue-color" },
                          [
                            _vm._v(
                              "Pessoas neste Momento: " +
                                _vm._s(_vm.pagination.databaseCount)
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "uk-hidden@s ukid-font-size-13" },
                        [
                          _vm._v(
                            "                                \n                            Módulo piloto para detecção de corpos nas proximidades das câmeras RTSP\n                            "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-label ukid-label-blue-color ukid-font-size-9"
                            },
                            [
                              _vm._v(
                                "Snapshots neste Momento: " +
                                  _vm._s(_vm.pagination.databaseCount)
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _vm._m(7),
                      _vm._v(" "),
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0",
                          on: {
                            click: function($event) {
                              return _vm.openFullScreen()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "tv" }
                          }),
                          _vm._v(
                            "\n                            Tela Cheia\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0",
                          on: {
                            click: function($event) {
                              return _vm.closeFullScreen()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "close" }
                          }),
                          _vm._v(
                            "\n                            Sair da Tela Cheia\n                        "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-card-footer ukid-supress-border-top" }, [
            _c(
              "div",
              { staticClass: "uk-flex uk-flex-center ukid-padding-top-20" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-flex uk-flex-center uk-grid-medium uk-text-center",
                    attrs: { "uk-grid": "" }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.registers.length,
                            expression: "!registers.length"
                          }
                        ],
                        staticClass:
                          "uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color",
                        attrs: { "uk-alert": "" }
                      },
                      [
                        _c("p", { staticClass: "ukid-white-color" }, [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "warning" }
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.App.proximity.nobody) +
                              "\n                        "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.registers, function(detectedBodies) {
                      return _c("div", { key: detectedBodies.id }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex-top ukid-photo-zindex",
                            attrs: {
                              id: "modal-media-image-" + detectedBodies.id,
                              "uk-modal": ""
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
                              },
                              [
                                _c("button", {
                                  staticClass: "uk-modal-close-outside",
                                  attrs: { type: "button", "uk-close": "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-inline ukid-square-person-edit ukid-light-blue-color",
                                    attrs: {
                                      id:
                                        "ukid-container-zoom-" +
                                        detectedBodies.id
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        crossorigin: "Anonymous",
                                        id:
                                          "ukid-image-bodies-zoom-" +
                                          detectedBodies.id,
                                        "uk-tooltip": "",
                                        src:
                                          detectedBodies.snapshot +
                                          "?nocache=" +
                                          Math.abs(
                                            Math.floor(
                                              Math.random() *
                                                7773777377737773777377737773
                                            )
                                          ),
                                        alt: ""
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-overlay uk-light uk-position-bottom ukid-font-size-18"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "ukid-white-color ukid-background-transparent"
                                      },
                                      [
                                        detectedBodies.totalBodies
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ukid-font-size-30 ukid-padding-15px"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      detectedBodies.totalBodies
                                                    ) +
                                                    " pessoas visíveis para contagem\n                                        "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        detectedBodies.totalBodies
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ukid-font-size-30 ukid-padding-15px"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Última atualização em " +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        new Date(
                                                          detectedBodies.updateTimestamp
                                                        ),
                                                        "DD/MM/YYYY HH:mm:ss"
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex-top ukid-photo-zindex",
                            attrs: {
                              id: "modal-media-cameras-" + detectedBodies.id,
                              "uk-modal": ""
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-modal-body ukid-font-size-18",
                                    attrs: { "uk-overflow-auto": "" }
                                  },
                                  [
                                    _vm._m(9, true),
                                    _vm._v(" "),
                                    _vm._m(10, true),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px"
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "uk-list ukid-margin-top-0 ukid-font-size-12"
                                          },
                                          _vm._l(
                                            detectedBodies.bboxInfoList,
                                            function(bboxInfo) {
                                              return _c(
                                                "li",
                                                { key: bboxInfo.timestamp },
                                                [
                                                  _vm._v(
                                                    "\n                                                Pessoas visiveis para contagem -> " +
                                                      _vm._s(
                                                        bboxInfo.totalBodies
                                                      ) +
                                                      " -> " +
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          new Date(
                                                            bboxInfo.timestamp
                                                          ),
                                                          "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(11, true)
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.$route.query.hasOwnProperty("debug")
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "uk-card uk-card-default uk-card-bod ukid-width-600-px"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ukid-padding-5px",
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass: "ukid-font-size-17",
                                        attrs: { width: "100%" }
                                      },
                                      [
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "ukid-font-size-20",
                                                attrs: { align: "center" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      detectedBodies.totalBodies
                                                    ) +
                                                    " pessoas visíveis para contagem\n                                        "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { align: "left" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "uk-inline ukid-square-person-edit ukid-light-blue-color",
                                                    attrs: {
                                                      id:
                                                        "ukid-container-" +
                                                        detectedBodies.id
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        crossorigin:
                                                          "Anonymous",
                                                        id:
                                                          "ukid-image-bodies-" +
                                                          detectedBodies.id,
                                                        src:
                                                          detectedBodies.snapshot +
                                                          "?nocache=" +
                                                          Math.abs(
                                                            Math.floor(
                                                              Math.random() *
                                                                7773777377737773777377737773
                                                            )
                                                          )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass: "ukid-font-size-15",
                                        attrs: { width: "100%" }
                                      },
                                      [
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "ukid-icon-blue-color ukid-font-size-13",
                                                attrs: { align: "center" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Camera: " +
                                                    _vm._s(
                                                      detectedBodies.camName
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ukid-padding-7px ukid-icon-blue-color ukid-margin-negative-top-10"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ukid-icon-blue-color ukid-font-size-13"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    new Date(
                                                      detectedBodies.updateTimestamp
                                                    ),
                                                    "DD/MM/YYYY HH:mm:ss"
                                                  )
                                                ) +
                                                "\n                                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "ukid-icon-blue-color ukid-magin-right-10 ukid-margin-top-3",
                                            attrs: {
                                              title: "Ampliar a imagem",
                                              "uk-tooltip": "",
                                              href:
                                                "#modal-media-image-" +
                                                detectedBodies.id,
                                              "uk-toggle": ""
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "uk-margin-small-right uk-icon",
                                              attrs: {
                                                "uk-icon":
                                                  "icon:image;ratio:0.98"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "#modal-media-cameras-" +
                                                detectedBodies.id,
                                              "uk-toggle": ""
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "ukid-icon-blue-color",
                                              attrs: {
                                                title: "Verificar eventos",
                                                "uk-icon":
                                                  "icon:camera;ratio:1.0"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    })
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { attrs: { align: "center" } }, [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pagination.last_page > 1,
                    expression: "pagination.last_page > 1"
                  }
                ],
                staticClass:
                  "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10",
                attrs: { "uk-margin": "" }
              },
              [
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.pagination.last_page,
                    "click-handler": _vm.list,
                    "prev-text": "<",
                    "next-text": ">",
                    "container-class":
                      "ukid-pagination uk-flex-center page-link ukid-margin-bottom-10"
                  },
                  model: {
                    value: _vm.page,
                    callback: function($$v) {
                      _vm.page = $$v
                    },
                    expression: "page"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1 && _vm.enableAutoList,
                  expression: "pagination.last_page > 1 && enableAutoList"
                }
              ],
              staticClass:
                "ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20",
              attrs: {
                "uk-margin": "",
                "uk-tooltip": "",
                title:
                  "Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui",
                "uk-icon": "icon:play-circle;ratio:2.7"
              },
              on: {
                click: function($event) {
                  return _vm.enableInterval()
                }
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
      },
      [_vm._v("Corpos Detectados"), _c("br"), _vm._v("no Intervalo De:")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label uk-margin-small ukid-primary-label-color ukid-margin-bottom-10 uk-visible@s"
      },
      [
        _vm._v("\n                Escolha os dispositivos a serem assistidos:"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-icon-pink-color" }, [
          _vm._v("Exemplo: Camera em frente ao balcão principal")
        ]),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label uk-margin-small ukid-primary-label-color ukid-margin-bottom-10 uk-hidden@s ukid-font-size-10"
      },
      [
        _vm._v("\n                Escolha os dispositivos"),
        _c("br"),
        _vm._v("a serem assistidos:"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-9 ukid-icon-pink-color" }, [
          _vm._v("Exemplo: Camera em frente"),
          _c("br"),
          _vm._v("ao balcão principal")
        ]),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "ukid-font-size-11 ukid-icon-pink-color" }, [
        _vm._v(
          "\n                    OBS: Os dados de dispositivos são salvos no armazenamento do navegador"
        ),
        _c("br"),
        _vm._v(
          " \n                    e não na sessão do usuário, possibilitando o monitoramento em computadores diferentes                                                \n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "uk-navbar-item uk-logo ukid-white-color ukid-font-size-13",
            attrs: { href: "../" }
          },
          [
            _c("img", {
              staticClass: "uk-margin-small-right",
              attrs: { src: "content/images/unike-logo-un.png", width: "120" }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner",
          attrs: { "uk-spinner": "ratio:4" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:search;ratio:5.0" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15",
        attrs: { align: "right" }
      },
      [
        _c("a", { attrs: { "uk-toggle": "target: #modal-devices" } }, [
          _c("span", {
            staticClass: "uk-margin-small-right uk-icon",
            attrs: { "uk-icon": "cog" }
          }),
          _vm._v(
            "\n                                Filtrar\n                            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17",
        attrs: { align: "right" }
      },
      [
        _c("a", { attrs: { "uk-toggle": "target: #modal-devices" } }, [
          _c("span", {
            staticClass: "uk-margin-small-right uk-icon",
            attrs: { "uk-icon": "cog" }
          }),
          _vm._v(
            "\n                                Filtrar por Dispositivo\n                            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0",
        attrs: { "uk-toggle": "target: #modal-devices" }
      },
      [
        _c("span", {
          staticClass: "uk-margin-small-right uk-icon",
          attrs: { "uk-icon": "cog" }
        }),
        _vm._v(
          "\n                            Filtrar por Dispositivo\n                        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label uk-margin-small ukid-primary-label-color uk-visible@s"
      },
      [
        _c("span", {
          staticClass: "uk-margin-small-right uk-icon",
          attrs: { "uk-icon": "icon:camera;ratio:0.8" }
        }),
        _vm._v(
          "\n                                        Informações de detecções da câmera\n                                    "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-12"
      },
      [
        _vm._v(
          "                                            \n                                        Informações de"
        ),
        _c("br"),
        _vm._v("detecções da câmera\n                                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-default uk-modal-close",
          attrs: { type: "button" }
        },
        [_vm._v("Fechar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1ad51957", { render: render, staticRenderFns: staticRenderFns })
  }
}