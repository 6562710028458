<!--suppress ALL-->

<!-- force load css -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import App from "../../app.json"

import Multiselect from 'vue-multiselect'

export default {

    inject: ['$validator'],

    props: ['label', 'description', 'app', 'strings', 'commons', 'entity', 'vm', 'groups', 'id', 'name', 'required', 'parent_', 'noDefault'],

    components: {

        Multiselect

    },

    data() {

        return {

            groupList: null,
            selectedGroups: []

        }

    },

    created() {

        let _ = this

        _.required = `'${_.required}'`

        _.fillGroups()

    },

    watch: {

        groups() {

            this.selectedGroups = this.groupList.filter(item => this.entity.groupList.includes(item.id))

        }

    },

    methods: {

        /**
         * List all groups
         */
        fillGroups() {

            let _ = this

            _.$http.get(

                App.host.concat(App.listAllGroupEndpoint)

            ).then(response => {

                _.groupList = response.body

                if (_.noDefault === true) {

                    _.groupList = _.groupList.filter( item => item.user !== '*' )

                }

                try {
                    // Set this object to use on parent component
                    ( _.parent_ !== undefined && _.parent_ !== null )
                        ? _.parent_.groups = response.body
                        : _.$parent.groups = response.body
                } catch (err) {
                }

                // Default group
                this.selectedGroups = this.groupList.filter(item => item.name === 'vst')

            })

        },

        selectFill(action) {

            this.selectedGroups = this.groupList.filter(item => this.entity.groupList.includes(item.id))

        },

        selectAction(action) {

            this.entity.groupList.push(action.id)

        },

        removeAction(action) {

            let index = this.entity.groupList.indexOf(action.id)

            if (index !== -1) {

                this.entity.groupList.splice(index, 1)

            }

        },

        refresh() {

            let _ = this

            setTimeout(function () {

                _.selectFill()

            }, 500)

        }

    }

}

</script>

<template id="group-component-template">

    <div class="uk-margin">
        <span class="uk-label ukid-primary-label-color">
            {{label}}<br />
            <span class="ukid-text-alert-color ukid-font-size-9" v-html="description"></span>
        </span>
        <br />
        <span v-if="errors.first(id)" class="ukid-text-fail-color">Selecione o(s) grupo(s)</span>
        <multiselect
            @input="selectFill"
            @select="selectAction"
            @remove="removeAction"
            :id="id"
            :name="name"
            v-model="selectedGroups"
            v-validate="required"
            selectLabel="Enter ou clique para marcar"
            deselectLabel="Enter ou clique para marcar"
            selectedLabel="Grupo marcada"
            placeholder="Você pode pesquisar um grupo"
            label="description"
            track-by="id"
            :options="groupList === null ? [] : groupList"
            :hide-selected="true"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :taggable="true">
            <template slot="option" slot-scope="props">
                <span v-if="props.option.description !== undefined">
                    {{props.option.description  + ' -> ' + (props.option.user !== '*' ? props.option.permission ? 'Leitura e Escrita' : 'Somente Leitura' : 'Padrão do Sistema')}}
                </span>
            </template>
            <template slot="noResult">
                <span>
                    Nenhum grupo encontrado
                </span>
            </template>
            <template slot="noOptions">
                <span>
                    Comece a digitar
                </span>
            </template>
        </multiselect>
    </div>

</template>