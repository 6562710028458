<!--suppress JSUnresolvedVariable, JSUnresolvedFunction, SpellCheckingInspection, HtmlUnknownAttribute, HtmlDeprecatedAttribute, HtmlUnknownTag, RegExpRedundantEscape, RegExpSimplifiable, RegExpUnnecessaryNonCapturingGroup-->

<script>

export default {

    inject: ['$validator'],

    props: ['app', 'strings', 'commons', 'camera', 'vm'],

    computed: {

        additional_headers: {
            get() {
                return this.camera.stream_settings.additional_headers.join(';').replace(/[\;]/g, "\n")
            },
            set(value) {
                this.camera.stream_settings.additional_headers = value.replace(/(?:\r\n|\r|\n)/g, ";").split(';')
            }
        },
        additional_body: {
            get() {
                return this.camera.stream_settings.additional_body.join(';').replace(/[\;]/g, "\n")
            },
            set(value) {
                this.camera.stream_settings.additional_body = value.replace(/(?:\r\n|\r|\n)/g, ";").split(';')
            }
        }

    }

}

</script>

<template id="camera-cpu-template">

    <div>
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    ffmpeg_format
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-ffmpeg-format-1'" :uk-toggle="'target: #ukid-plus-animation-ffmpeg-format-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-ffmpeg-format-1">
                    Passe o formato FFMPEG<br />(mxg, flv, etc.)<br />
                    se não puder ser<br />detectado automaticamente<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    ffmpeg_format
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-ffmpeg-format-2'" :uk-toggle="'target: #ukid-plus-animation-ffmpeg-format-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-ffmpeg-format-2">
                    Passe o formato FFMPEG (mxg, flv, etc.)<br />
                    se não puder ser detectado automaticamente<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('ffmpeg_format_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('ffmpeg_format_input_field')
                    }"
                    v-model="camera.stream_settings.ffmpeg_format"
                    v-validate="'min:3|max:100'"
                    c-data-vv-as="field"
                    id="ffmpeg_format_input_field"
                    name="ffmpeg_format_input_field"
                    type="text"
                    placeholder="Mínimo 3, máximo 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    ffmpeg_params
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-ffmpeg-params-1'" :uk-toggle="'target: #ukid-plus-animation-ffmpeg-params-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-ffmpeg-params-1">
                    Lista de opções de ffmpeg de um fluxo de vídeo<br />
                    com seus valores como uma matriz<br />
                    key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]<br />
                    Confira o site FFmpeg <a class="reference external" href="https://trac.ffmpeg.org">web site</a><br />
                    para a lista completa de opções.<br />
                    Valor padrão: opções não especificadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    ffmpeg_params
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-ffmpeg-params-2'" :uk-toggle="'target: #ukid-plus-animation-ffmpeg-params-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-ffmpeg-params-2">
                    Lista de opções de ffmpeg de um fluxo de vídeo com seus<br />
                    valores como uma matriz key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]<br />
                    Confira o site FFmpeg <a class="reference external" href="https://trac.ffmpeg.org">web site</a><br />
                    para a lista completa de opções. Valor padrão: opções não especificadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('ffmpeg_params_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('ffmpeg_params_input_field')
                    }"
                    v-model="camera.stream_settings.ffmpeg_params"
                    v-validate="'min:5|max:100'"
                    c-data-vv-as="field"
                    id="ffmpeg_params_input_field"
                    name="ffmpeg_params_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 100">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    md_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-md-threshold-1'" :uk-toggle="'target: #ukid-plus-animation-md-threshold-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-md-threshold-1">
                    Intensidade mínima de movimento a ser<br />
                    detectada pelo detector de movimento<br />
                    O valor limite deve ser ajustado empiricamente.<br />
                    Unidades empíricas: zero e números<br />
                    racionais positivos.<br />
                    Marcos:<br />
                    0 = detector desativado<br />
                    0.002 = valor padrão<br />
                    0.05 = intensidade mínima muito<br />
                    alta para detectar movimento<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    md_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-md-threshold-2'" :uk-toggle="'target: #ukid-plus-animation-md-threshold-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-md-threshold-2">
                    Intensidade mínima de movimento a ser detectada pelo detector de movimento<br />
                    O valor limite deve ser ajustado empiricamente.<br />
                    Unidades empíricas: zero e números racionais positivos.<br />
                    Marcos:<br />
                    0 = detector desativado<br />
                    0.002 = valor padrão<br />
                    0.05 = intensidade mínima muito alta para detectar movimento<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('md_threshold_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('md_threshold_input_field')
                    }"
                    v-model="camera.stream_settings.md_threshold"
                    v-validate="'required|between:0,1|decimal:3'"
                    c-data-vv-as="field"
                    id="md_threshold_input_field"
                    name="md_threshold_input_field"
                    type="text"
                    placeholder="Entre 0 e 1">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    md_scale
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-md-scale-1'" :uk-toggle="'target: #ukid-plus-animation-md-scale-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-md-scale-1">
                    Valor padrão: 1 (tamanho original)<br />
                    Coeficiente de escala do frame de vídeo<br />
                    para o detector de movimento, em relação<br />
                    ao tamanho original de 0 a 1.<br />
                    Reduza a escala no caso de câmeras<br />
                    de alta resolução, ou rostos próximos<br />
                    ou se a carga da CPU for muito alta,<br />
                    para reduzir o consumo de recursos do sistema<br />
                    Verifique se o tamanho da face dimensionada<br />
                    excede o valor mínimo do tamanho da face<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    md_scale
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-md-scale-2'" :uk-toggle="'target: #ukid-plus-animation-md-scale-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-md-scale-2">
                    Valor padrão: 1 (tamanho original)<br />
                    Coeficiente de escala do frame de vídeo para o detector de movimento,<br />
                    em relação ao tamanho original de 0 a 1.<br />
                    Reduza a escala no caso de câmeras de alta resolução, ou rostos próximos<br />
                    ou se a carga da CPU for muito alta, para reduzir o consumo de recursos do sistema<br />
                    Verifique se o tamanho da face dimensionada excede o valor mínimo do tamanho da face<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('md_scale_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('md_scale_input_field')
                    }"
                    v-model="camera.stream_settings.md_scale"
                    v-validate="'required|between:0,1|decimal:3'"
                    c-data-vv-as="field"
                    id="md_scale_input_field"
                    name="md_scale_input_field"
                    type="text"
                    placeholder="Entre 0 e 1">
            </div>
        </div>
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    fd_frame_height
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-fd-frame-height-1'" :uk-toggle="'target: #ukid-plus-animation-fd-frame-height-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-fd-frame-height-1">
                    Valor padrão:<br />
                    -1 (valores negativos correspondem ao tamanho original)<br />
                    640-720 (Valor ideal para reduzir a carga)
                    Altura do frame de vídeo para o<br />
                    rastreador de rosto, em pixels<br />
                    Reduza a escala no caso de câmeras<br />
                    de alta resolução ou feche rostos,<br />
                    ou se a carga da CPU for muito alta,<br />
                    para reduzir o consumo de recursos do sistema.<br />
                    Verifique se o tamanho da face dimensionada<br />
                    excede o valor mínimo do tamanho da face<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    fd_frame_height
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-fd-frame-height-2'" :uk-toggle="'target: #ukid-plus-animation-fd-frame-height-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-fd-frame-height-2">
                    Valor padrão:<br />
                    -1 (valores negativos correspondem ao tamanho original)<br />
                    640-720 (Valor ideal para reduzir a carga)
                    Altura do frame de vídeo para o rastreador de rosto, em pixels<br />
                    Reduza a escala no caso de câmeras de alta resolução<br />
                    ou feche rostos, ou se a carga da CPU for muito alta,<br />
                    para reduzir o consumo de recursos do sistema.<br />
                    Verifique se o tamanho da face dimensionada excede o valor mínimo do tamanho da face<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('fd_frame_height_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('fd_frame_height_input_field')
                    }"
                    v-model="camera.stream_settings.fd_frame_height"
                    v-validate="'required|between:-1,720'"
                    c-data-vv-as="field"
                    id="fd_frame_height_input_field"
                    name="fd_frame_height_input_field"
                    type="text"
                    placeholder="Entre -1 e 720">
            </div>
        </div>
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    uc_max_time_diff
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-uc-max-time-diff-1'" :uk-toggle="'target: #ukid-plus-animation-suc-max-time-diff-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-uc-max-time-diff-1">
                    Valor padrão: 30<br />
                    Somente se post_uniq: true<br />
                    (deduplicação de face ativada)<br />
                    Período máximo de tempo em segundos durante<br />
                    o qual um número de faces semelhantes é considerado<br />
                    pertencente a uma pessoa<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    uc_max_time_diff
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-uc-max-time-diff-2'" :uk-toggle="'target: #ukid-plus-animation-uc-max-time-diff-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-uc-max-time-diff-2">
                    Valor padrão: 30<br />
                    Somente se post_uniq: true (deduplicação de face ativada)<br />
                    Período máximo de tempo em segundos durante o qual um número de faces<br />
                    semelhantes é considerado pertencente a uma pessoa<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('uc_max_time_diff_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('uc_max_time_diff_input_field')
                    }"
                    v-model="camera.stream_settings.uc_max_time_diff"
                    v-validate="'required|between:1,200'"
                    c-data-vv-as="field"
                    id="uc_max_time_diff_input_field"
                    name="uc_max_time_diff_input_field"
                    type="text"
                    placeholder="Entre 1 e 200">
            </div>
        </div>
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    uc_max_dup
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-uc-max-dup-1'" :uk-toggle="'target: #ukid-plus-animation-uc-max-dup-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-uc-max-dup-1">
                    Valor padrão: 3<br />
                    Somente se post_uniq: true<br />
                    (deduplicação de face ativada)<br />
                    Número máximo de faces durante o período<br />
                    uc_max_time_diff lançado para uma pessoa<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    uc_max_dup
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-uc-max-dup-2'" :uk-toggle="'target: #ukid-plus-animation-uc-max-dup-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-uc-max-dup-2">
                    Valor padrão: 3 <br />
                    Somente se post_uniq: true (deduplicação de face ativada)<br />
                    Número máximo de faces durante o período uc_max_time_diff lançado para uma pessoa<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('uc_max_dup_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('uc_max_dup_input_field')
                    }"
                    v-model="camera.stream_settings.uc_max_dup"
                    v-validate="'required|between:1,20'"
                    c-data-vv-as="field"
                    id="uc_max_dup_input_field"
                    name="uc_max_dup_input_field"
                    type="text"
                    placeholder="Entre 1 e 20">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    uc_max_avg_shift
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-uc-max-avg-shift-1'" :uk-toggle="'target: #ukid-plus-animation-uc-max-avg-shift-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-uc-max-avg-shift-1">
                    Valor padrão: 10<br />
                    Somente se post_uniq: true<br />
                    (deduplicação de face ativada)<br />
                    Distância em pixels dentro da qual um<br />
                    número de faces semelhantes é considerado<br />
                    pertencente a uma pessoa<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    uc_max_avg_shift
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-uc-max-avg-shift-1'" :uk-toggle="'target: #ukid-plus-animation-uc-max-avg-shift-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-uc-max-avg-shift-1">
                    Valor padrão: 10<br />
                    Somente se post_uniq: true (deduplicação de face ativada)<br />
                    Distância em pixels dentro da qual um número de faces<br />
                    semelhantes é considerado pertencente a uma pessoa<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('uc_max_avg_shift_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('uc_max_avg_shift_input_field')
                    }"
                    v-model="camera.stream_settings.uc_max_avg_shift"
                    v-validate="'required|between:1,100'"
                    c-data-vv-as="field"
                    id="uc_max_avg_shift_input_field"
                    name="uc_max_avg_shift_input_field"
                    type="text"
                    placeholder="Entre 1 e 100">
            </div>
        </div>

        <div class="uk-margin uk-hidden">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('det_period_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('det_period_input_field')
                    }"
                    v-model="camera.stream_settings.det_period"
                    v-validate="''"
                    c-data-vv-as="field"
                    id="det_period_input_field"
                    name="det_period_input_field"
                    type="text"
                    placeholder="">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    realtime
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-1'" :uk-toggle="'target: #ukid-plus-animation-realtime-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-1">
                    Valor padrão: Não habilitado<br />
                    Ativa o modo em tempo real<br />
                    para a melhor pesquisa de face<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    realtime
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-2'" :uk-toggle="'target: #ukid-plus-animation-realtime-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-2">
                    Valor padrão: Não habilitado<br />
                    Ativa o modo em tempo real<br />
                    para a melhor pesquisa de face<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('realtime_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('realtime_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.realtime" value="true" class="uk-radio" type="radio" id="realtime_input_field_yes" name="realtime_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.realtime" value="false" class="uk-radio" type="radio" id="realtime_input_field_no" name="realtime_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    npersons
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-npersons-1'" :uk-toggle="'target: #ukid-plus-animation-npersons-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-npersons-1">
                    Valor padrão: 4<br />
                    Número máximo de faces rastreados<br />
                    simultaneamente pelo rastreador de faces<br />
                    Este parâmetro afeta severamente o desempenho<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    npersons
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-npersons-2'" :uk-toggle="'target: #ukid-plus-animation-npersons-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-npersons-2">
                    Valor padrão: 4<br />
                    Número máximo de faces rastreados simultaneamente pelo rastreador de faces<br />
                    Este parâmetro afeta severamente o desempenho<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('npersons_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('npersons_input_field')
                    }"
                    v-model="camera.stream_settings.npersons"
                    v-validate="'required|between:1,100'"
                    c-data-vv-as="field"
                    id="npersons_input_field"
                    name="npersons_input_field"
                    type="text"
                    placeholder="Entre 1 e 100">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    tracker_threads
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-tracker-threads-1'" :uk-toggle="'target: #ukid-plus-animation-tracker-threads-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-tracker-threads-1">
                    Valor padrão: 1<br />
                    Número de threads de rastreamento<br />
                    para o rastreador de face<br />
                    Este valor deve ser menor ou igual<br />
                    ao valor do campo acima<br />
                    Recomendamos que você defina-os com o mesmo valor<br />
                    Se o número de encadeamentos de rastreamento for menor<br />
                    que o número máximo de faces rastreadas,<br />
                    o consumo de recursos será reduzido, assim como<br />
                    a velocidade de rastreamento<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    tracker_threads
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-tracker-threads-1'" :uk-toggle="'target: #ukid-plus-animation-tracker-threads-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-tracker-threads-1">
                    Valor padrão: 1<br />
                    Número de threads de rastreamento para o rastreador de face<br />
                    Este valor deve ser menor ou igual ao valor do campo acima<br />
                    Recomendamos que você defina-os com o mesmo valor<br />
                    Se o número de encadeamentos de rastreamento for menor que o número máximo de faces rastreadas,<br />
                    o consumo de recursos será reduzido, assim como a velocidade de rastreamento<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('tracker_threads_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('tracker_threads_input_field')
                    }"
                    v-model="camera.stream_settings.tracker_threads"
                    v-validate="'required|between:1,100'"
                    c-data-vv-as="field"
                    id="tracker_threads_input_field"
                    name="tracker_threads_input_field"
                    type="text"
                    placeholder="Entre 1 e 100">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    disable_drops
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-disable-drops-1'" :uk-toggle="'target: #ukid-plus-animation-disable-drops-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-disable-drops-1">
                    Valor padrão: Não habilitado<br />
                    Permite postar todas as faces<br />
                    apropriadas sem exclusões<br />
                    Por padrão, se o video-worker não tiver recursos<br />
                    suficientes para processar todos os frames<br />
                    com faces ele eliminará alguns deles<br />
                    Se esta opção estiver ativa, o video-worker<br />
                    coloca frames ímpares na lista de espera<br />
                    para processá-los posteriormente<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    disable_drops
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-disable-drops-2'" :uk-toggle="'target: #ukid-plus-animation-disable-drops-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-disable-drops-2">
                    Valor padrão: Não habilitado<br />
                    Permite postar todas as faces apropriadas sem exclusões<br />
                    Por padrão, se o video-worker não tiver recursos suficientes<br />
                    para processar todos os frames com faces ele eliminará alguns deles<br />
                    Se esta opção estiver ativa, o video-worker coloca frames ímpares na lista<br />
                    de espera para processá-los posteriormente<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('disable_drops_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('disable_drops_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.disable_drops" value="true" class="uk-radio" type="radio" id="disable_drops_input_field_yes" name="disable_drops_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.disable_drops" value="false" class="uk-radio" type="radio" id="disable_drops_input_field_no" name="disable_drops_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin uk-hidden">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    parse_sei
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-parse-sei-1'" :uk-toggle="'target: #ukid-plus-animation-parse-sei-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-parse-sei-1">
                    Valor padrão: Não habilitado<br />
                    Permite postar todas as faces<br />
                    apropriadas sem exclusões<br />
                    Por padrão, se o video-worker não tiver recursos<br />
                    suficientes para processar todos os frames com faces<br />
                    ele eliminará alguns deles<br />
                    Se esta opção estiver ativa, o video-worker coloca<br />
                    frames ímpares na lista de espera para processá-los posteriormente<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    parse_sei
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-parse_sei-2'" :uk-toggle="'target: #ukid-plus-animation-parse-sei-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-parse-sei-2">
                    Valor padrão: Não habilitado<br />
                    Permite postar todas as faces apropriadas sem exclusões<br />
                    Por padrão, se o video-worker não tiver recursos suficientes<br />
                    para processar todos os frames com faces ele eliminará alguns deles<br />
                    Se esta opção estiver ativa, o video-worker coloca frames ímpares na lista<br />
                    de espera para processá-los posteriormente<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('parse_sei_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('parse_sei_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.parse_sei" value="true" class="uk-radio" type="radio" id="parse_sei_input_field_yes" name="parse_sei_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.parse_sei" value="false" class="uk-radio" type="radio" id="parse_sei_input_field_no" name="parse_sei_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    image_arg
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-image-arg-1'" :uk-toggle="'target: #ukid-plus-animation-image-arg-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-image-arg-1">
                    Valor padrão: photo<br />
                    Nome do argumento que contém uma bbox<br />
                    com uma face, em uma solicitação ao WEB HUC<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    image_arg
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-image-arg-2'" :uk-toggle="'target: #ukid-plus-animation-image-arg-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-image-arg-2">
                    Valor padrão: photo<br />
                    Nome do argumento que contém uma bbox<br />
                    com uma face, em uma solicitação ao WEB HUC<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('image_arg_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('image_arg_input_field')
                    }"
                    v-model="camera.stream_settings.image_arg"
                    v-validate="'required|min:1|max:20'"
                    c-data-vv-as="field"
                    id="image_arg_input_field"
                    name="image_arg_input_field"
                    type="text"
                    placeholder="Mínimo 1, máximo 20">
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    <span class="ukid-icon-pink-color">
                        additional_headers<br /><br />
                    </span>
                </span>
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-additional-headers-1'" :uk-toggle="'target: #ukid-plus-animation-additional-headers-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-additional-headers-1">
                    Valor padrão: cabeçalhos não especificados<br />
                    Campos de cabeçalho adicionais<br />em uma solicitação<br />
                    POST ao postar uma<br />face para WEB HUC<br />
                    Ex:<br /><br />
                    <span class="uk-text-lowercase">
                        Authorization=Token xxx<br />
                        Accept=application/json
                    </span>
                    <br /><br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    <span class="ukid-icon-pink-color">
                        additional_headers<br /><br />
                    </span>
                </span>
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-additional-headers-2'" :uk-toggle="'target: #ukid-plus-animation-additional-headers-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-additional-headers-2">
                    Valor padrão: cabeçalhos não especificados<br />
                    Campos de cabeçalho adicionais em uma solicitação<br />
                    POST ao postar uma face para WEB HUC<br />
                    Ex:<br />
                    <span class="uk-text-lowercase">
                        Authorization=Token xxx<br />
                        Accept=application/json
                    </span>
                    <br /><br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <textarea
                    :class="{
                        'ukid-text-area-external uk-textarea ukid-form-error ukid-focus ukid-width-100-percent ukid-height-150-px': errors.has('additional_headers_input_field'),
                        'ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px': !errors.has('additional_headers_input_field')
                    }"
                    rows="5"
                    v-model="additional_headers"
                    v-validate="'min:0|max:2000'"
                    c-data-vv-as="field"
                    id="additional_headers_input_field"
                    name="additional_headers_input_field"
                    type="textarea"></textarea>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    additional_body
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-additional-body-1'" :uk-toggle="'target: #ukid-plus-animation-additional-body-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-additional-body-1">
                    Atributos:<br />
                    <span class="ukid-text-lowercase ukid-font-size-11">
                        &nbsp;&nbsp;image_type=BASE64<br />
                        &nbsp;&nbsp;external_url=https://xxx<br />
                        &nbsp;&nbsp;external_token=token_xxx<br />
                        &nbsp;&nbsp;mf_selector=<br />
                        &nbsp;&nbsp;detector=DEAFULT<br />
                        &nbsp;&nbsp;gallery=DEFAULT<br />
                        &nbsp;&nbsp;latitude=<br />
                        &nbsp;&nbsp;longitude=<br /><br />
                        Campos adicionais no body da solicitação<br />
                        ao postar uma face. Ex: uuid=xxx,some_name=yyy<br /><br />
                    </span>
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    additional_body
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-additional-body-2'" :uk-toggle="'target: #ukid-plus-animation-additional-body-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-additional-body-2">
                    Atributos:<br />
                    <span class="ukid-text-lowercase ukid-font-size-11">
                        &nbsp;&nbsp;image_type=BASE64<br />
                        &nbsp;&nbsp;external_url=https://xxx<br />
                        &nbsp;&nbsp;external_token=token_xxx<br />
                        &nbsp;&nbsp;mf_selector=<br />
                        &nbsp;&nbsp;detector=DEAFULT<br />
                        &nbsp;&nbsp;gallery=DEFAULT<br />
                        &nbsp;&nbsp;latitude=<br />
                        &nbsp;&nbsp;longitude=<br /><br />
                        Campos adicionais no body da solicitação<br />
                        ao postar uma face. Ex: uuid=xxx,some_name=yyy<br /><br />
                    </span>
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <textarea
                    :class="{
                        'ukid-text-area-external ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-height-150-px': errors.has('additional_body_input_field'),
                        'ukid-text-area-external uk-input ukid-focus ukid-width-100-percent ukid-height-150-px': !errors.has('additional_body_input_field')
                    }"
                    v-model="additional_body"
                    v-validate="'required|min:5|max:1000'"
                    c-data-vv-as="field"
                    id="additional_body_input_field"
                    name="additional_body_input_field"
                    type="textarea"
                    placeholder="Mínimo 5, máximo 1000"></textarea>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    api_timeout
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-api-timeout-1'" :uk-toggle="'target: #ukid-plus-animation-api-timeout-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-api-timeout-1">
                    Valor padrão: 15000<br />
                    Tempo limite para uma resposta face router<br />
                    a uma solicitação da API video-worker, em milissegundos<br />
                    Se o tempo limite expirar, o sistema registrará um erro<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('api_timeout_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('api_timeout_input_field')
                    }"
                    v-model="camera.stream_settings.api_timeout"
                    v-validate="'required|between:1000,30000'"
                    c-data-vv-as="field"
                    id="api_timeout_input_field"
                    name="api_timeout_input_field"
                    type="text"
                    placeholder="Entre 1000 e 30000" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    api_ssl_verify
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-api-ssl-verify-1'" :uk-toggle="'target: #ukid-plus-animation-api-ssl-verify-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-api-ssl-verify-1">
                    Valor padrão: Habilitado<br />
                    Ativa a verificação do certificado https quando<br />
                    video-worker e facerouter interagem por https<br /><br />
                    Se não habilitado, um certificado<br />
                    autoassinado também é aceito
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    api_ssl_verify
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-api-ssl-verify-2'" :uk-toggle="'target: #ukid-plus-animation-api-ssl-verify-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-api-ssl-verify-2">
                    Valor padrão: Habilitado<br />
                    Ativa a verificação do certificado https quando<br />
                    video-worker e facerouter interagem por https<br />
                    Se não habilitado, um certificado autoassinado também é aceito
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('api_ssl_verify_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('api_ssl_verify_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.api_ssl_verify" value="true" class="uk-radio" type="radio" id="api_ssl_verify_input_field_yes" name="api_ssl_verify_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.api_ssl_verify" value="false" class="uk-radio" type="radio" id="api_ssl_verify_input_field_no" name="api_ssl_verify_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    post_uniq
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-post-uniq-1'" :uk-toggle="'target: #ukid-plus-animation-post-uniq-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-post-uniq-1">
                    Valor padrão: Habilitado<br />
                    Permite desduplicação de face, ou seja,<br />
                    postar apenas um certo número de faces<br />
                    pertencentes a uma pessoa, durante um<br />
                     determinado período de tempo.<br />
                    Nesse caso, se video-worker postar uma face para<br />
                    facerouter e depois rastrear outra dentro do período<br />
                    uc_max_time_diff, e a distância entre<br />
                    as duas faces não exceder<br />
                    uc_max_avg_shift, o video-worker estima sua similaridade<br />
                    Se as faces forem semelhantes e o<br />
                    número total de faces semelhantes<br />
                    durante o período uc_max_time_diff não<br />
                    exceder o número uc_max_dup,<br />
                    o video-worker postará a outra face.<br />
                    Caso contrário, a outra face não será lançada<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    post_uniq
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-post-uniq-1'" :uk-toggle="'target: #ukid-plus-animation-post-uniq-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-post-uniq-1">
                    Valor padrão: Habilitado<br />
                    Permite desduplicação de face, ou seja, postar apenas um certo número de faces<br />
                    pertencentes a uma pessoa, durante um determinado período de tempo.<br />
                    Nesse caso, se video-worker postar uma face para facerouter e depois rastrear outra dentro do período<br />
                    uc_max_time_diff, e a distância entre as duas faces não exceder<br />
                    uc_max_avg_shift, o video-worker estima sua similaridade<br />
                    Se as faces forem semelhantes e o número total de faces semelhantes<br />
                    durante o período uc_max_time_diff não exceder o número uc_max_dup,<br />
                    o video-worker postará a outra face. Caso contrário, a outra face não será lançada<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('post_uniq_verify_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('post_uniq_verify_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.post_uniq" value="true" class="uk-radio" type="radio" id="post_uniq_verify_input_field_yes" name="post_uniq_verify_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.post_uniq" value="false" class="uk-radio" type="radio" id="post_uniq_verify_input_field_no" name="post_uniq_verify_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    min_score
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-min-score-1'" :uk-toggle="'target: #ukid-plus-animation-min-score-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-min-score-1">
                    Valor padrão: -7<br />
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais<br />
                    negativos para zero<br />
                    Marcos:<br />
                    0 = Face com baixíssima qualidade<br />
                    1 = Face com alta qualidade
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    min_score
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-api-ssl-verify-1'" :uk-toggle="'target: #ukid-plus-animation-min-score-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-min-score-2">
                    Valor padrão: -7<br />
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Face com baixíssima qualidade<br />
                    1 = Face com alta qualidade
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('min_score_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('min_score_input_field')
                    }"
                    v-model="camera.stream_settings.min_score"
                    v-validate="'required|between:0,1'"
                    c-data-vv-as="field"
                    id="min_score_input_field"
                    name="min_score_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    min_d_score
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-min-d-score-1'" :uk-toggle="'target: #ukid-plus-animation-min-d-score-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-min-d-score-1">
                    Valor padrão: -1000<br />
                    Desvio máximo de face em sua posição frontal<br />
                    Uma face é aceita se seu desvio for menor que esse valor<br />
                    O desvio deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    -3.5 = ângulos grandes da face, o reconhecimento<br />
                    da face pode ser ineficiente<br />
                    -2.5 = desvio satisfatório<br />
                    -0.05 = próximo à posição frontal<br />
                    0 = face frontal<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    min_d_score
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-min-d-score-2'" :uk-toggle="'target: #ukid-plus-animation-min-d-score-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-min-d-score-2">
                    Valor padrão: -1000<br />
                    Desvio máximo de face em sua posição frontal<br />
                    Uma face é aceita se seu desvio for menor que esse valor<br />
                    O desvio deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    -3.5 = ângulos grandes da face, o reconhecimento da face pode ser ineficiente<br />
                    -2.5 = desvio satisfatório<br />
                    -0.05 = próximo à posição frontal<br />
                    0 = face frontal<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('min_d_score_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('min_d_score_input_field')
                    }"
                    v-model="camera.stream_settings.min_d_score"
                    v-validate="'required|between:-1000,0|decimal:3'"
                    c-data-vv-as="field"
                    id="min_d_score_input_field"
                    name="min_d_score_input_field"
                    type="text"
                    placeholder="Mínimo -1000, máximo 0" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    min_d_score
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-post-perm-1'" :uk-toggle="'target: #ukid-plus-animation-realtime-post-perm-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-post-perm-1">
                    Valor padrão: Não habilitado<br />
                    Modo em tempo real<br />
                    Poste as melhores capturas obtidas em<br />
                    cada período de tempo realtime_dly<br />
                    Se não habilitado, procure o melhor momento<br />
                    dinamicamente e post em ordem<br />
                    crescente de qualidade<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    min_d_score
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-post-perm-2'" :uk-toggle="'target: #ukid-plus-animation-realtime-post-perm-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-post-perm-2">
                    Valor padrão: Não habilitado<br />
                    Modo em tempo real<br />
                    Poste as melhores capturas obtidas em<br />
                    cada período de tempo realtime_dly<br />
                    Se não habilitado, procure o melhor momento<br />
                    dinamicamente e post em ordem<br />
                    crescente de qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('realtime_post_perm_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('realtime_post_perm_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.realtime_post_perm" value="true" class="uk-radio" type="radio" id="realtime_post_perm_input_field_yes" name="realtime_post_perm_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.realtime_post_perm" value="false" class="uk-radio" type="radio" id="realtime_post_perm_input_field_no" name="realtime_post_perm_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    realtime_dly
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-dly-1'" :uk-toggle="'target: #ukid-plus-animation-realtime-dly-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-dly-1">
                    Valor padrão: 0 - Em segundos<br />
                    Modo em tempo real<br />
                    Poste as melhores capturas obtidas em<br />
                    cada período de tempo realtime_dly<br />
                    Se não habilitado, procure o melhor momento<br />
                    dinamicamente e post em ordem<br />
                    crescente de qualidade<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    realtime_dly
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-dly-2'" :uk-toggle="'target: #ukid-plus-animation-realtime-dly-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-dly-2">
                    Valor padrão: 0 - Em segundos<br />
                    Somente para o modo em tempo real habilitado<br />
                    Se realtime_post_perm = Habilitado, define o período em milissegundos dentro do qual o rastreador de face pega<br />
                    o melhor momento e o posta no face router<br />
                    Se realtime_post_perm = Não habilitado, define o período mínimo de tempo entre 2 posts da mesma face com maior qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('realtime_dly_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('realtime_dly_input_field')
                    }"
                    v-model="camera.stream_settings.realtime_dly"
                    v-validate="'required|between:0,500|decimal:2'"
                    c-data-vv-as="field"
                    id="realtime_dly_input_field"
                    name="realtime_dly_input_field"
                    type="text"
                    placeholder="Entre 0 e 500" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    rot
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-rot-1'" :uk-toggle="'target: #ukid-plus-animation-rot-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-rot-1">
                    Valor padrão: retângulo não especificado<br />
                    Permite detectar e rastrear faces apenas<br />
                    dentro de um retângulo de recorte WxH + X + Y<br />
                    Você pode usar esta opção para reduzir a carga do video worker<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    rot
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-rot-2'" :uk-toggle="'target: #ukid-plus-animation-rot-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-rot-2">
                    Valor padrão: retângulo não especificado<br />
                    Permite detectar e rastrear faces apenas<br />
                    dentro de um retângulo de recorte WxH + X + Y<br />
                    Você pode usar esta opção para reduzir a carga do video worker<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('rot_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('rot_input_field')
                    }"
                    v-model="camera.stream_settings.rot"
                    v-validate="'min:5|max:100'"
                    c-data-vv-as="field"
                    id="rot_input_field"
                    name="rot_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    roi
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-roi-1'" :uk-toggle="'target: #ukid-plus-animation-roi-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-roi-1">
                    Valor padrão: retângulo não especificado<br />
                    Ativar post de faces detectadas apenas<br />
                    dentro de uma região de interesse WxH + X + Y<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    roi
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-roi-2'" :uk-toggle="'target: #ukid-plus-animation-roi-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-roi-2">
                    Valor padrão: retângulo não especificado<br />
                    Ativar post de faces detectadas apenas<br />
                    dentro de uma região de interesse WxH + X + Y<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('roi_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('roi_input_field')
                    }"
                    v-model="camera.stream_settings.roi"
                    v-validate="'min:5|max:100'"
                    c-data-vv-as="field"
                    id="roi_input_field"
                    name="roi_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    draw_track
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-draw-track-1'" :uk-toggle="'target: #ukid-plus-animation-draw-track-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-draw-track-1">
                    Valor padrão: Não habilitado<br />
                    Permite desenhar uma trilha<br />
                    de movimento de face em um box<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    draw_track
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-draw-track-2'" :uk-toggle="'target: #ukid-plus-animation-draw-track-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-draw-track-2">
                    Valor padrão: Não habilitado<br />
                    Permite desenhar uma trilha<br />
                    de movimento de face em um box<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('draw_track_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('draw_track_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.draw_track" value="true" class="uk-radio" type="radio" id="draw_track_input_field_yes" name="draw_track_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.draw_track" value="false" class="uk-radio" type="radio" id="draw_track_input_field_no" name="draw_track_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    send_track
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-send-track-1'" :uk-toggle="'target: #ukid-plus-animation-send-track-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-send-track-1">
                    Valor padrão: 0 (array não postada)<br />
                    Permite postar uma trilha de movimento de face<br />
                    como matriz de coordenadas centrais do bbox<br />
                    Como o valor send_track, especifique o número<br />
                    de pontos na trilha de movimento<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    send_track
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-send-track-2'" :uk-toggle="'target: #ukid-plus-animation-send-track-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-send-track-2">
                    Valor padrão: 0 (array não postada)<br />
                    Permite postar uma trilha de movimento de face<br />
                    como matriz de coordenadas centrais do bbox<br />
                    Como o valor send_track, especifique o número<br />
                    de pontos na trilha de movimento<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('send_track_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('send_track_input_field')
                    }"
                    v-model="camera.stream_settings.send_track"
                    v-validate="'required|min:0|max:1000'"
                    c-data-vv-as="field"
                    id="send_track_input_field"
                    name="send_track_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1000" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    min_face_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-min-face-size-1'" :uk-toggle="'target: #ukid-plus-animation-min-face-size-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-min-face-size-1">
                    Valor padrão: Valor padrão: 0 (filtro desativado)<br />
                    Tamanho mínimo de uma face em pixels<br />
                    As faces subdimensionadas não são postadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    min_face_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-min-face-size-2'" :uk-toggle="'target: #ukid-plus-animation-min-face-size-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-min-face-size-2">
                    Valor padrão: Valor padrão: 0 (filtro desativado)<br />
                    Tamanho mínimo de uma face em pixels<br />
                    As faces subdimensionadas não são postadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('min_face_size_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('min_face_size_input_field')
                    }"
                    v-model="camera.stream_settings.min_face_size"
                    v-validate="'required|min:0|max:1000'"
                    c-data-vv-as="field"
                    id="min_face_size_input_field"
                    name="min_face_size_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1000" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    max_face_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-max-face-size-1'" :uk-toggle="'target: #ukid-plus-animation-max-face-size-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-max-face-size-1">
                    Valor padrão: Valor padrão: 0 (filtro desativado)<br />
                    Tamanho máximo de uma face em pixels<br />
                    As faces de grandes dimensões não são postadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    max_face_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-max-face-size-2'" :uk-toggle="'target: #ukid-plus-animation-max-face-size-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-max-face-size-2">
                    Valor padrão: Valor padrão: 0 (filtro desativado)<br />
                    Tamanho máximo de uma face em pixels<br />
                    As faces de grandes dimensões não são postadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('max_face_size_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('max_face_size_input_field')
                    }"
                    v-model="camera.stream_settings.max_face_size"
                    v-validate="'required|min:0|max:1000'"
                    c-data-vv-as="field"
                    id="max_face_size_input_field"
                    name="max_face_size_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1000" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    overall
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-overall-1'" :uk-toggle="'target: #ukid-plus-animation-overall-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-overall-1">
                    Valor padrão: Habilitado<br />
                    Ativa o modo off-line para<br />
                    a melhor pesquisa de face<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    overall
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-overall-2'" :uk-toggle="'target: #ukid-plus-animation-overall-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-overall-2">
                    Valor padrão: Habilitado<br />
                    Ativa o modo off-line para<br />
                    a melhor pesquisa de face<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                                                        'ukid-width-100-percent': errors.has('overall_input_field'),
                                                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('overall_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.overall" value="true" class="uk-radio" type="radio" id="overall_input_field_yes" name="overall_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.overall" value="false" class="uk-radio" type="radio" id="overall_input_field_no" name="overall_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    only_norm
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-only-norm-1'" :uk-toggle="'target: #ukid-plus-animation-only-norm-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-only-norm-1">
                    Valor padrão: Não habilitado<br />
                    Habilite a postagem apenas de imagens<br />
                    de face normalizadas sem frames completos<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    only_norm
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-only-norm-2'" :uk-toggle="'target: #ukid-plus-animation-only-norm-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-only-norm-2">
                    Valor padrão: Não habilitado<br />
                    Habilite a postagem apenas de imagens<br />
                    de face normalizadas sem frames completos<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                    'ukid-width-100-percent': errors.has('only_norm_input_field'),
                    'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('only_norm_input_field')}">
                    <label>
                        <input v-model="camera.stream_settings.only_norm" value="true" class="uk-radio" type="radio" id="only_norm_input_field_yes" name="only_norm_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="camera.stream_settings.only_norm" value="false" class="uk-radio" type="radio" id="only_norm_input_field_no" name="only_norm_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin uk-hidden">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('max_candidates_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('max_candidates_input_field')
                    }"
                    v-model="camera.stream_settings.max_candidates"
                    v-validate="''"
                    c-data-vv-as="field"
                    id="max_candidates_input_field"
                    name="max_candidates_input_field"
                    type="text"
                    placeholder="" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                Qualidade da compactação JPEG<br />
                do frame original, em porcentagem<br />
                <span class="ukid-icon-pink-color">
                    jpeg_quality - Valor padrão: 95%
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                Qualidade da compactação JPEG do frame original, em porcentagem<br />
                <span class="ukid-icon-pink-color">
                    jpeg_quality - Valor padrão: 95%
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('jpeg_quality_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('jpeg_quality_input_field')
                    }"
                    v-model="camera.stream_settings.jpeg_quality"
                    v-validate="'required|between:30,100'"
                    c-data-vv-as="field"
                    id="jpeg_quality_input_field"
                    name="jpeg_quality_input_field"
                    type="text"
                    placeholder="Entre 30 e 100" />
            </div>
        </div>
    </div>

</template>