<template>
    <div :id="modalId" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-width-auto uk-margin-auto-vertical modal-height uk-border-rounded">
            <div class="title-container">
                <h2 class="uk-text-bolder title ">Histórico</h2>
                <h2 class="uk-modal-close animated" uk-icon="icon: close; ratio: 1.7"></h2>
            </div>
            <div v-if="localWorkList.length === 0">
                <date-picker :lang="lang" type="datetime" placeholder="Data de inicio"
                    @input="checkValidate(index, dateType, $event)"></date-picker>
            </div>
            <div v-else>
                <div v-for="(date, index) in localWorkList" :key="index" class="uk-flex uk-margin-bottom">
                    <div class="uk-margin-bottom" v-for="(dateValue, dateType) in date" :key="dateType">
                        <span class="uk-margin-right animated" @click="removeItem(index)" v-if="dateType === 'startDate'"
                            uk-icon="icon: trash; ratio: 1.5 "></span>
                        <date-picker :lang="lang" :value="localWorkList[index][dateType]"
                        @clear="removeDatePickerItem(index, dateType)"
                            @input="checkValidate(index, dateType, $event)" type="datetime"
                            :placeholder="dateType === 'endDate' ? 'Data de fim' : 'Data de início'"></date-picker>
                        <span class=" uk-margin-left uk-margin-right" v-if="dateType === 'startDate'"
                            uk-icon="icon: more; "></span>
                    </div>
                </div>
                <div v-if="error">
                    <span class="error-message">{{ error }}</span>
                </div>
                <p class="uk-text-right">
                    <button @click="sentEdit"
                        class="uk-button uk-button-default uk-width-1-2   uk-button-large uk-border-rounded animated ">
                        <span class="uk-margin-small-right " uk-icon="icon:pencil; ratio:1.3"></span>
                        Editar
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import App from '../../app.json'
import { vm } from '../../js/main'
import UIkit from 'uikit'

export default {
    data() {
        return {
            App: App,
            lang: 'pt-br',
            localWorkList: this.createLocalWorkList([...this.workList]),
            error: null
        };
    },
    components: { DatePicker, UIkit },
    props: {
        modalId: String,
        workList: Array,
        id19: String
    },
    methods: {
        checkValidate(index, dateType, newDate) {
            this.error = null;
            const otherDateType = dateType === 'startDate' ? 'endDate' : 'startDate';
            const otherTimestamp = this.localWorkList[index][otherDateType];
            const currentTimestamp = new Date(newDate).getTime();
            const previousDates = this.localWorkList.slice(0, index);
            const hasInvalidPreviousDate = previousDates.some(dateObj => {
                const startDateTimestamp = new Date(dateObj.startDate).getTime();
                const endDateTimestamp = new Date(dateObj.endDate).getTime();
                return (
                    (dateType === 'startDate' && startDateTimestamp > currentTimestamp) ||
                    (dateType === 'endDate' && endDateTimestamp > currentTimestamp)
                );
            });
            if (hasInvalidPreviousDate) {
                this.error = 'Uma data inserida posteriormente não pode ser menor que uma data preenchida.';
                return;
            }
            if (
                (dateType === 'startDate' && otherTimestamp && currentTimestamp > otherTimestamp) ||
                (dateType === 'endDate' && otherTimestamp && currentTimestamp < otherTimestamp)
            ) {
                console.log(currentTimestamp, otherTimestamp)
                this.error = 'A data não atende aos critérios de validação.';
                return;
            }
            this.$set(this.localWorkList[index], dateType, currentTimestamp);
            this.localWorkList = this.createLocalWorkList([...this.localWorkList]);
        },

        removeDatePickerItem(index, dateType) {
        if (index >= 0 && index < this.localWorkList.length) {
            if (dateType in this.localWorkList[index]) {
                this.$set(this.localWorkList[index], dateType, null);
            }
        }
    },
        sentEdit() {
            UIkit.notification.closeAll()     
            const transformedDates = this.localWorkList.filter((item, key) => item.startDate !== null || item.endDate !== null).map(item => {
                    Object.keys(item).forEach(chave => item[chave] === null && delete item[chave]);
                    return item;
                })
                console.log(transformedDates)

            this.$http.post(App.host.concat(App.sentWorkDate).concat(this.id19), transformedDates,
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${vm.token}` } })
                .then(response => {
                    console.log('Edição bem-sucedida!', response.body);
                    UIkit.notification({
                        message: App.notification.workList,
                        status: 'primary',
                        pos: 'top-center',
                        timeout: App.notification.timeout
                    })
                })
                .catch(error => {
                    console.error('Erro ao editar histórico:', error);
                    UIkit.modal.alert(error)
                });
        },

        createLocalWorkList(workList) {
            if (workList.length === 0) {
                workList = [{ startDate: null, endDate: null }];
            } else {
                const lastIndex = workList.length - 1;
                if (lastIndex >= 0) {
                    const lastItem = workList[lastIndex];
                    if (lastItem.startDate && !lastItem.endDate) {
                        this.$set(lastItem, 'endDate', null);
                    } else if (lastItem.endDate && lastItem.startDate) {
                        const newItem = { startDate: null, endDate: null };
                        this.$set(workList, lastIndex + 1, newItem);
                    }
                }
            }
            return workList;
        }
        ,
        removeItem(index) {
            if (index !== 0) {
                const updatedList = [...this.localWorkList];
                updatedList.splice(index, 1);
                this.localWorkList = updatedList;
                return;
            }
            this.localWorkList[0].startDate = null;
            this.localWorkList[0].endDate = null;
        },
    },
    watch: {
        workList: {
            handler() {
                this.localWorkList = this.createLocalWorkList([...this.localWorkList]);
            },
            deep: true,
        },
    }

};
</script>
  
<style scoped lang="scss">
.modal-height {
    min-height: 40rem;
}

.animated {
    transition: transform 300ms ease-in;
    cursor: pointer
}

.animated:hover {
    transform: scaleY(1.1) scaleX(1.1);
}

.uk-button-scale {
    display: block;
    margin-top: 5px;
    font-size: 1.3rem;
    letter-spacing: 2px;
}

.title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.title {
    font-size: 2rem;
    font-weight: 600;
}

.error-message {
    color: red;
}
</style>
  