<template>
  <a :href="'#history-' + id19" uk-toggle>
    <div class="tooltip-container">
      <svg :class="{ 'work': work, 'alternative': !work }" width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="user">
        <circle v-if="work" fill="none" stroke="#B2EB89" stroke-width="1.1" cx="9.9" cy="6.4" r="4.4"></circle>
        <circle v-else fill="none" stroke="#1E87F0" stroke-width="1.1" cx="9.9" cy="6.4" r="4.4"></circle>
        <path v-if="work" fill="none" stroke="#B2EB89" stroke-width="1.1" d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"></path>
        <path v-else fill="none" stroke="#1E87F0" stroke-width="1.1" d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"></path>
      </svg>
      <div class="tooltip">
        Histórico Funcionário
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'WorkList',

  props: {
    work: Boolean,
    id19: String
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(96, 94, 231);
  border-radius: 2px;
  padding: 5px;
  color: #ffffff;
  font-size: 1rem;
  display: none;
}

.tooltip-container:hover .tooltip {
  display: block;
}

.alternative circle {
  stroke: #1E87F0;
}

.alternative path {
  stroke: #1E87F0;
}
</style>
