var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uk-flex-top", attrs: { id: _vm.id, "uk-modal": "" } },
    [
      _c(
        "div",
        {
          staticClass:
            "ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog ukid-zoom-photo-415px uk-margin-auto-vertical"
        },
        [
          _c("button", {
            staticClass: "uk-modal-close-outside",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "ukid-container-zoom-" + _vm.id19 } }, [
            _c("img", {
              staticClass: "ukid-photo-effect-zoom",
              attrs: {
                crossorigin: "Anonymous",
                id: "ukid-image-face-zoom-" + _vm.id19,
                "uk-tooltip": "",
                src: _vm.principalCrop && _vm.principalCrop.snapshot,
                width: "640",
                height: "480",
                alt: ""
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16ed5d3a", { render: render, staticRenderFns: staticRenderFns })
  }
}