<template>
  <div :id="modalId" class="uk-flex-top ukid-photo-zindex" uk-modal>
    <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
      <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
        <span class="uk-label ukid-primary-label-color uk-visible@s">
          <span class="uk-margin-small-right uk-icon" uk-icon="icon:hashtag;ratio:0.8"></span>
          Dados
        </span>
        <br />
        <div class="uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px">
          <ul class="uk-list ukid-margin-top-0 ukid-font-size-12">
            <li>
              <img :src="'data:image/png;base64,' + person.principalCrop.base64Crop" class="ukid-height-150-px" />
              <br /><br />
              id19 Related: <span v-if="person.id19"> {{ person.id19Related[0] }}</span><br />
              id19: <span v-if="person.id19"> {{ person.id19 }}</span><br />
              Customer: <span v-if="person.user"> {{ person.user }}</span><br />
              First Detect: <span v-if="person.firstDetect"> {{ new Date(person.firstDetect.timestamp) |
                dateFormat('DD/MM/YYYY HH:mm:ss') }}</span><br />
              Principal Crop: <span v-if="person.principalCrop"> {{ new Date(person.principalCrop.timestamp) |
                dateFormat('DD/MM/YYYY HH:mm:ss') }}</span><br />
              Qtt Captures: <span v-if="person.age.qttDetect"> {{ person.age.qttDetect }}</span><br />
              Work: <br />
              <span v-if="person.workList.length > 0">
                <span v-for="w in person.workList" :key="w.startDate">
                  Inicio: {{ new Date(w.startDate) | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span v-if="w.endDate"> - Fim: {{
                    new Date(w.endDate) | dateFormat('DD/MM/YYYY HH:mm:ss') }} </span> <br />
                </span>
                <br />
              </span>
              <br />
            </li>
            <li v-if="person">
              <b>--- Links ---</b><br /><br />
              <span> Snapshot: <a :href="person.principalCrop && person.principalCrop.snapshot" target="_blank">clique
                  aqui</a> <br /> </span>
              <br />
              <b>--- Atributos Estimados ---</b><br /><br />
              Sexo: <span v-if="person.gender">{{ person.gender.attributeValue }} ( {{ person.gender.perc !== null ?
                parseFloat(person.gender.perc * 1.0).toFixed(4) : 'N/A' }} ) </span> <br />
              Idade: <span v-if="person.age">{{ person.age.attributeValue }} ( {{ person.age.perc !== null ?
                parseFloat(person.age.perc * 1.0).toFixed(4) : 'N/A' }} ) </span> <br />
              Loja Principal: <span v-if="person.principalStore">{{ person.principalStore.storeName }} </span> <br />
              ESRATE: <span
                v-if="person.principalCrop && person.principalCrop.cropESRate">{{ person.principalCrop.cropESRate }} </span>
              <br />
              Quality: <span
                v-if="person.principalCrop && person.principalCrop.cropQuality">{{ person.principalCrop.cropQuality }}
              </span> <br />
              Width: <span
                v-if="person.principalCrop && person.principalCrop.cropWidth">{{ person.principalCrop.cropWidth }} </span>
              <br />
              Height: <span
                v-if="person.principalCrop && person.principalCrop.cropHeight">{{ person.principalCrop.cropHeight }} </span>
              <br />
              Pitch: <span
                v-if="person.principalCrop && person.principalCrop.cropHeadPose">{{ person.principalCrop.cropHeadPose.result.pitch }}
              </span> <br />
              Roll: <span
                v-if="person.principalCrop && person.principalCrop.cropHeadPose">{{ person.principalCrop.cropHeadPose.result.roll }}
              </span> <br />
              Yaw: <span
                v-if="person.principalCrop && person.principalCrop.cropHeadPose">{{ person.principalCrop.cropHeadPose.result.yaw }}
              </span> <br />
              <br />
            </li>
            <li v-if="document">
              <b>--- Atributos da Biográficos ---</b><br /><br />
              nome: <span v-if="document.name"> {{ document.name }} </span><br />
              email: <span v-if="document.email"> {{ document.email }} </span><br />
              cpf: <span v-if="document.cpf"> {{ document.cpf }} </span><br />
              nasc: <span v-if="document.dateOfDate"> {{ new Date(document.dateOfDate) | dateFormat('DD/MM/YYYY HH: mm: ss')}} </span><br />
              empregado: <span v-if="document.isEmployee != null"> {{ document.isEmployee }} </span><br />
              <span>desconhecido: {{ person.unknown }}<br /></span>
            </li>
              <li>
              <b> --- Dados de Loja ---</b><br /><br />
              <b> Loja Principal </b><br />
              ID Loja: <span v-if="person.principalStore.storeId"> {{ person.principalStore.storeId }} </span><br />
              Loja: <span v-if="person.principalStore.storeName"> {{ person.principalStore.storeName }} </span><br /><br />
              <div v-if="person.principalStore.storeResume">
                <b> Resumo com todas lojas</b><br />
    <div v-for="(item, index) in person.principalStore.storeResume" :key="index">
      ID Loja: {{ item.storeId }}<br />
      Loja: {{ item.storeName }}<br />
      qtt Detect: {{ item.qttDetect }}<br /><br />
    </div>
  </div>
            </li>
        </ul>
      </div>
    </div>
    <div class="uk-modal-footer uk-text-right">
      <button class="uk-button uk-button-default uk-modal-close" type="button">Fechar</button>
    </div>
  </div>
</div></template>
  
<script>
export default {
  props: {
    modalId: String,
    person: Object,
    document: Object
  },
};
</script>
  