<template>
    <div :id="modalId" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-width-auto uk-margin-auto-vertical modal-height uk-border-rounded">
            <div class="title-container">
                <h2 class="uk-text-bolder title ">Atenção</h2>
                <h2 class="uk-modal-close animated" uk-icon="icon: close; ratio: 1.7"></h2>
            </div>
            <span class="body-text" style="font-weight: bold;">Esta ação removerá permanentemente os dados. Deseja
                confirmar?</span>
            <div class="body-text">Cadastros: {{ userList }}</div>
            <p class="uk-text-right">
            <div style="display: flex;flex-direction: row-reverse; gap:1rem">
                <button @click="deleteGlobal" class="button uk-border-rounded animated button-default uk-modal-close" >
                    <span class="uk-margin-small-right " uk-icon="icon:check; ratio:1"></span>
                    Confirmar
                </button>
                <button @click="clear" class="button uk-border-rounded button-delete animated uk-modal-close ">
                    <span class="uk-margin-small-right " uk-icon="icon:refresh; ratio:1"></span>
                    Cancelar
                </button>
            </div>
            </p>
        </div>
    </div>
</template>    
<script>
import App from '../../app.json'
import UIkit from 'uikit'
import { vm } from '../../js/main'
export default {
    data() {
        return {
            App: App,
            pagination: {
                last_page: 0
            },
        }
    },
    props: {
        modalId: String,
        userList: Array,
        clear: Function,
        person: Object
    },
    methods: {
        logged() {
            console.log(userList)
        },
        deleteGlobal() {
            console.log(this.person)

             let _ = this
             let endpoint = App.host.concat(App.deleteGlobalPersonEndpoint)
             this.userList.forEach(id => {
               let url = endpoint.concat(id).concat('/').concat(this.person.personDocumentV4Collection.user)
               _.$http.delete(url)
                 .then(response => {
                   UIkit.notification.closeAll()

                   UIkit.notification({
                     message: App.notification.del,
                     status: 'primary',
                     pos: 'top-center',
                     timeout: App.notification.timeout
                     
                   })
                   console.log(response)
                   if (_.pagination.current_page > 0 && _.page == _.pagination.last_page && _.pagination.data.length == 1) {
                     _.list(_.page - 1)
                   } else {
                     _.list(_.page)
                   }
                   _.clear(false)
                 })
                 .catch(error => {
                   console.error(`Erro ao fazer DELETE para ${url}: ${error}`)
                   UIkit.modal.alert(err.body.description)
                 })
             })
        }
    }
};
</script>
    
<style scoped lang="scss">
.modal-height {
    min-height: 40rem;
}

.animated {
    transition: transform 300ms ease-in;
    cursor: pointer
}

.button {
    padding: 1rem;
    background-color: transparent;

}
.button-default{
    color:white;
    border:1px solid white
}
.animated:hover {
    transform: scaleY(1.1) scaleX(1.1);
}

.body-text {
    font-size: 1.3rem;
    margin-bottom: 1.4rem;
}


.uk-button-scale {
    display: block;
    margin-top: 5px;
    font-size: 1.3rem;
    letter-spacing: 2px;
    width: 3rem;
}

.button-delete {
    display: flex;
    border: 1px solid red;
    color: red;
    cursor: pointer;
    align-items: center;
    justify-content: center;

}

.title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.title {
    font-size: 2rem;
    font-weight: 600;
}

</style>
  