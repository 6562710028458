var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonCancel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.del(_vm.camera)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonDelete))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color",
          attrs: { id: "ukid-filter" }
        },
        [
          _c(
            "dl",
            {
              staticClass:
                "uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl"
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ukid-padding ukid-padding-10px ukid-font-size-15"
                    },
                    [
                      _vm._v(
                        "\n                    Criação, atualização e exclusão de cameras cftv\n                    "
                      ),
                      _c("br"),
                      _vm._v(
                        "Total: " +
                          _vm._s(_vm.pagination.databaseCount) +
                          "\n                "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("br", { staticClass: "uk-hidden@m uk-hidden@l uk-hidden@xl" }),
              _vm._v(" "),
              _c("dt", [_vm._v("\n                Pesquisar\n            ")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParam,
                      expression: "searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-width-80-percent",
                  attrs: {
                    id: "search-param-input",
                    type: "text",
                    placeholder: "Pesquise qualquer termo"
                  },
                  domProps: { value: _vm.searchParam },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchParam = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6",
                  attrs: { "uk-icon": "icon:search;ratio:1.4" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "uk-hidden ukid-search-spinner ukid-magin-left-6",
                  attrs: { "uk-spinner": "ratio:0.92" }
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.clear(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                Iniciar Novo Registro\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.registers, function(camera) {
                return _c("dt", { key: camera.meta }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-label ukid-width-70-percent ukid-padding-15px ukid-font-size-11"
                    },
                    [
                      !camera.type || camera.type === "cftv"
                        ? _c("span", {
                            staticClass:
                              "ukid-white-color ukid-margin-bottom-5",
                            attrs: { "uk-icon": "icon:video-camera;ratio:1.2" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOnline(camera) === true &&
                      camera.type === "terminal"
                        ? _c("span", {
                            staticClass:
                              "ukid-text-success-color ukid-margin-bottom-5",
                            attrs: {
                              "uk-icon": "icon:tablet;ratio:1.0",
                              "uk-tooltip": "Terminal está online"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOnline(camera) === false &&
                      camera.type === "terminal"
                        ? _c("span", {
                            staticClass:
                              "ukid-text-alert-color ukid-margin-bottom-5",
                            attrs: {
                              "uk-icon": "icon:tablet;ratio:1.0",
                              "uk-tooltip":
                                "Terminal off-line. Sem atividade a mais de 30 minutos"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      camera.type === "count_people"
                        ? _c("span", {
                            staticClass:
                              "ukid-white-color ukid-margin-bottom-5",
                            attrs: { "uk-icon": "icon:more-vertical;ratio:1.0" }
                          })
                        : _vm._e(),
                      _c("br"),
                      _vm._v(" "),
                      !camera.type || camera.type === "cftv"
                        ? _c("span", [
                            _vm._v("CFTV / CCTV ( WORKER )"),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      camera.type === "count_people"
                        ? _c("span", [
                            _vm._v("CONTAGEM DE PESSOAS ( WORKER )"),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      camera.type === "terminal"
                        ? _c("span", [
                            _vm._v("CFTV / CCTV COM FACIAL ou TERMINAL FACIAL"),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v("\n\n                    " + _vm._s(camera.meta)),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "ukid-text-lowercase" }, [
                        _vm._v(_vm._s(camera.id))
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      camera.type === "terminal"
                        ? _c("span", { staticClass: "ukid-text-upercase" }, [
                            _vm._v("SN - " + _vm._s(camera.serialNumber))
                          ])
                        : _vm._e(),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ukid-text-alert-color ukid-font-size-9"
                        },
                        [_vm._v(_vm._s(camera.user))]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "ukid-width-100-percent ukid-icon-yellow-color ukid-cursor-pointer",
                        attrs: {
                          href: "#ukid-camera-plus-animation" + camera.id,
                          "uk-toggle":
                            "target: #ukid-camera-plus-animation-" +
                            camera.id +
                            "; animation: uk-animation-fade; style: display:block;",
                          "uk-icon": "icon:plus;ratio:1.0"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "ukid-width-100-percent ukid-white-color ukid-font-size-9",
                          attrs: {
                            hidden: "",
                            id: "ukid-camera-plus-animation-" + camera.id
                          }
                        },
                        [
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            "\n                        Grupos dessa Câmera\n                        "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _vm._l(_vm.groups, function(group) {
                            return _c("span", { key: group.id }, [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    camera.groupList.includes(group.name)
                                      ? "- " + group.description + "<br />"
                                      : ""
                                  )
                                }
                              })
                            ])
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "ukid-camera-hr" }),
                      _vm._v(" "),
                      _c(
                        "table",
                        { staticClass: "ukid-magin-left-20 ukid-font-size-9" },
                        [
                          _c("tbody", [
                            !camera.type || camera.type === "cftv"
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { align: "left", width: "50%" } },
                                    [_vm._v("STATUS")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left", width: "50%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(camera.status) +
                                          "\n                                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: camera.status_msg,
                                              expression: "camera.status_msg"
                                            }
                                          ],
                                          staticClass: "ukid-camera-status"
                                        },
                                        [_vm._v(_vm._s(camera.status_msg))]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            camera.type === "terminal"
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { align: "left", width: "50%" } },
                                    [_vm._v("STATUS")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left", width: "50%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            camera.enabled
                                              ? "Habilitado"
                                              : "Não Habilitado"
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            camera.type === "count_people"
                              ? _c("tr", [
                                  _c("td", { attrs: { align: "left" } }, [
                                    _vm._v("PESSOAS JÁ CONTABILIZADAS")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(camera.statistic.count_people)
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            camera.type === "terminal"
                              ? _c("tr", [
                                  _c("td", { attrs: { align: "left" } }, [
                                    _vm._v("FACES VERIFICADAS")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(camera.statistic.faces_checked)
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            camera.type === "terminal"
                              ? _c("tr", [
                                  _c("td", { attrs: { align: "left" } }, [
                                    _vm._v("FACES NÃO VERIFICADAS")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          camera.statistic.faces_not_checked
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type ||
                            camera.type === "cftv" ||
                            camera.type === "terminal"
                              ? _c("tr", [
                                  _c("td", { attrs: { align: "left" } }, [
                                    _vm._v(
                                      "                                        \n                                    FACES POSTADAS\n                                "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(camera.statistic.faces_posted)
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c("tr", [
                                  _c("td", { attrs: { align: "left" } }, [
                                    _vm._v("FACES NÃO POSTADAS")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          camera.statistic.faces_not_posted
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { align: "left", colspan: "2" } },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "ukid-icon-yellow-color ukid-cursor-pointer",
                                        attrs: {
                                          href:
                                            "#ukid-plus-animation-" + camera.id,
                                          "uk-toggle":
                                            "target: #ukid-plus-animation-" +
                                            camera.id +
                                            "; animation: uk-animation-fade; style: display:block;",
                                          "uk-icon": "icon:plus;ratio:1.0"
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c(
                                  "tr",
                                  {
                                    attrs: {
                                      id: "ukid-plus-animation-" + camera.id,
                                      hidden: ""
                                    }
                                  },
                                  [
                                    _c("td", { attrs: { align: "left" } }, [
                                      _vm._v("FRAMES EM PROCESSAMENTO")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ukid-padding-left-5",
                                        attrs: { align: "left" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            camera.statistic.processing_fps
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c(
                                  "tr",
                                  {
                                    attrs: {
                                      id: "ukid-plus-animation-" + camera.id,
                                      hidden: ""
                                    }
                                  },
                                  [
                                    _c("td", { attrs: { align: "left" } }, [
                                      _vm._v("FRAMES PROCESSADOS")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ukid-padding-left-5",
                                        attrs: { align: "left" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            camera.statistic.frames_processed
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            camera.type === "count_people"
                              ? _c("tr", [
                                  _c("td", { attrs: { align: "left" } }, [
                                    _vm._v("FRAMES PROCESSADOS")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ukid-padding-left-5",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          camera.statistic.frames_processed
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c(
                                  "tr",
                                  {
                                    attrs: {
                                      id: "ukid-plus-animation-" + camera.id,
                                      hidden: ""
                                    }
                                  },
                                  [
                                    _c("td", { attrs: { align: "left" } }, [
                                      _vm._v("FRAMES DESCARTADOS")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ukid-padding-left-5",
                                        attrs: { align: "left" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            camera.statistic.frames_dropped
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c(
                                  "tr",
                                  {
                                    attrs: {
                                      id: "ukid-plus-animation-" + camera.id,
                                      hidden: ""
                                    }
                                  },
                                  [
                                    _c("td", { attrs: { align: "left" } }, [
                                      _vm._v("ERROS DE DECODE")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ukid-padding-left-5",
                                        attrs: { align: "left" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            camera.statistic
                                              .decoding_soft_errors
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !camera.type || camera.type === "cftv"
                              ? _c(
                                  "tr",
                                  {
                                    attrs: {
                                      id: "ukid-plus-animation-" + camera.id,
                                      hidden: ""
                                    }
                                  },
                                  [
                                    _c("td", { attrs: { align: "left" } }, [
                                      _vm._v("JOBS INICIADOS")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ukid-padding-left-5",
                                        attrs: { align: "left" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(camera.statistic.job_starts)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  camera
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:file-edit;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.fill(Object.assign({}, camera))
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  camera
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.modal(Object.assign({}, camera))
                          }
                        }
                      })
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registers.length,
                  expression: "!registers.length"
                }
              ],
              staticClass:
                "uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30",
              attrs: { "uk-alert": "" }
            },
            [
              _c("span", {
                staticClass:
                  "uk-form-icon ukid-search-spinner ukid-magin-left-10 ukid-magin-right-10",
                attrs: { "uk-icon": "icon:ban" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ukid-magin-left-20" }, [
                _vm._v(
                  "\n                Nenhum registro encontrado\n            "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16"
            },
            [
              _vm._v("Pagina Atual: "),
              _c("b", { staticClass: "ukid-magin-left-10" }, [
                _vm._v(_vm._s(_vm.page))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.pagination.last_page,
                  "click-handler": _vm.list,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent",
          attrs: { id: "ukid-card-form" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-card-header ukid-supress-border-bottom uk-visible@m"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-150",
                  attrs: { "uk-grid": "" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "uk-width-auto ukid-min-width-165" },
                    [
                      _c("span", {
                        staticClass: "uk-hidden ukid-spinner",
                        attrs: { "uk-spinner": "ratio:4" }
                      }),
                      _vm._v(" "),
                      !_vm.camera.type || _vm.camera.type === "cftv"
                        ? _c("span", {
                            staticClass:
                              "ukid-icon ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6",
                            attrs: { "uk-icon": "icon:video-camera;ratio:6.8" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.camera.type === "count_people"
                        ? _c("span", {
                            staticClass:
                              "ukid-icon ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6",
                            attrs: { "uk-icon": "icon:more-vertical;ratio:5.2" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.camera.type === "terminal"
                        ? _c("span", {
                            staticClass:
                              "ukid-icon ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6",
                            attrs: { "uk-icon": "icon:tablet;ratio:5.2" }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c("span", { staticClass: "ukid-font-size-18" }, [
                      _vm._v("Gestão CFTV / Contage de Pessoas / Terminais")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", [
                        _vm._v(
                          "\n                            Criação, atualização e exclusão"
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-label ukid-label-red-color" },
                          [
                            _vm._v(
                              "Câmeras: " + _vm._s(_vm.pagination.databaseCount)
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("progress", {
            staticClass:
              "uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0",
            attrs: { value: "0", max: "100" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "uk-card-footer ukid-supress-border-top": !_vm.Commons.isMobile(),
                "uk-card-footer ukid-supress-border-top ukid-card-footer-cam-mobile": _vm.Commons.isMobile()
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "ukid-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "uk-fieldset" },
                    [
                      _c("div", [
                        _c("legend", { staticClass: "uk-legend" }, [
                          _c(
                            "span",
                            { staticClass: "ukid-font-size-25 uk-visible@m" },
                            [_vm._v("Formulário")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "uk-visible@s",
                              attrs: { "uk-sticky": "offset: 170" }
                            },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "uk-visible@m" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/home" } },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "uk-button uk-button-primary uk-align-right ukid-margin-bottom-0 ukid-font-size-13"
                                        },
                                        [_vm._v("Cancelar")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "uk-hidden@m" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "uk-button uk-button-primary uk-align-right ukid-margin-bottom-0 ukid-font-size-13",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.vm.configDeviceResolution()
                                      }
                                    }
                                  },
                                  [_vm._v("Voltar")]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.clear(true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Iniciar Novo Registro\n                            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("camera-common-fields", {
                        ref: "cameraCommonFields",
                        attrs: {
                          app: _vm.App,
                          strings: _vm.Strings,
                          commons: _vm.Commons,
                          users: _vm.users,
                          groups: _vm.groups,
                          camera: _vm.camera,
                          persons: _vm.persons,
                          vm: _vm.vm,
                          isPersonLoading: _vm.isPersonLoading,
                          labels_: _vm.labels_,
                          errors: _vm.errors
                        }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.camera.type === "cftv" ||
                      _vm.camera.type === "count_people"
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "ukid-vd-params ukid-font-size-25 uk-visible@m"
                            },
                            [_vm._v("Parâmetros do Video Worker")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _vm.camera.type === "cftv"
                        ? _c("camera-gpu-fields", {
                            ref: "cameraGpuFields",
                            attrs: {
                              app: _vm.App,
                              strings: _vm.Strings,
                              commons: _vm.Commons,
                              camera: _vm.camera,
                              vm: _vm.vm,
                              errors: _vm.errors
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.camera.type === "count_people"
                        ? _c("camera-body-gpu-fields", {
                            ref: "cameraBodyGpuFields",
                            attrs: {
                              app: _vm.App,
                              strings: _vm.Strings,
                              commons: _vm.Commons,
                              body: _vm.body,
                              vm: _vm.vm,
                              errors: _vm.errors
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40"
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(3)
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner-sm",
          attrs: { "uk-spinner": "ratio:2.6" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon-sm ukid-icon-red-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:camera;ratio:3.6" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-12" }, [
      _vm._v(
        '\n                                "CFTV / CCTV" -> Necessário para o video woker (detector de faces em tempo real)'
      ),
      _c("br"),
      _vm._v(
        '\n                                "Contagem de Pessoas" -> Necessário para processo de contagem de pessoas, também utiliza cameras de CFTV / CCTV'
      ),
      _c("br"),
      _vm._v(
        '\n                                "Terminais" -> Necessário para correto funcionamento nos terminais de reconhecimento facial / aferição de temperatura da Unike\n                            '
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-primary uk-align-right ukid-margin-bottom-0 ukid-font-size-13",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c715a08", { render: render, staticRenderFns: staticRenderFns })
  }
}