<script type="text/javascript">

    import UIkit from 'uikit'

    import App from '../../app.json'

    import { GChart } from 'vue-google-charts'

    export default {

        components: {

            GChart

        },

        data() {

            return {

                rerender: 0,
                
                processorInfo: '',
                ethernetInfo: '',
                videoCardInfo: '',

                gaugeMemoryData: [
                    ['Label', 'Value'],
                    ['Memory', 0]
                ],

                gaugeVideoMemoryData: [
                    ['Label', 'Value'],
                    ['Video Memory', 0]
                ],

                gaugeCpuData: [
                    ['Label', 'Value'],
                    ['CPU', 0]
                ],
                
                gaugeGpuData: [
                    ['Label', 'Value'],
                    ['GPU', 0]
                ],

                gaugeNetworkData: [
                    ['Label', 'Value'],
                    ['Network', 0]
                ],
                
                gaugeDiskSdaData: [
                    ['Label', 'Value'],
                    ['Disk', 0]
                ],

                gaugeDiskMntData: [
                    ['Label', 'Value'],
                    ['Disk', 0]
                ],                 

                gaugeOptions: {
                    redFrom: 90, 
                    redTo: 100,
                    yellowFrom:75, 
                    yellowTo: 90,
                    minorTicks: 5,
                    animation: {
                        duration: 777,
                        easing: 'linear'
                    },
                    legend: {
                        textStyle: {
                            color: '#95ED7B',
                            fontSize: 12
                        }
                    },
                    hAxis: {
                        textStyle: { 
                            color: '#95ED7B',
                            fontSize: 12
                        },
                        gridlines: {
                            color: 'transparent'
                        }                        
                    },
                    vAxis: {
                        textStyle: { 
                            color: '#95ED7B',
                            fontSize: 12
                        },
                        gridlines: {
                            color: 'transparent'
                        }                        
                    },
                    height: 280
                }

            }

        },

        created() {

            this.enableInterval()

        },

        methods: {

            list() {

                var this_ = this

                this.$http.get(

                    App.host.concat(App.serverInfoEndpoint)

                ).then(response => {

                    this_.gaugeCpuData[0] = response.body[0].label
                    this_.gaugeCpuData[1] = response.body[0].cpu
                    this_.gaugeGpuData[0] = response.body[1].label
                    this_.gaugeGpuData[1] = response.body[1].cpu
                    this_.gaugeMemoryData[0] = response.body[2].label
                    this_.gaugeMemoryData[1] = response.body[2].memory
                    this_.gaugeVideoMemoryData[0] = response.body[3].label
                    this_.gaugeVideoMemoryData[1] = response.body[3].memory
                    this_.gaugeDiskSdaData[0] = response.body[4].label
                    this_.gaugeDiskSdaData[1] = response.body[4].disk
                    this_.gaugeDiskMntData[0] = response.body[5].label
                    this_.gaugeDiskMntData[1] = response.body[5].disk
                    

                    this_.processorInfo = response.body[6].replace('\n', '').trim()
                    this_.videoCardInfo = response.body[7].replace('\n', '').trim()
                    this_.ethernetInfo  = response.body[8].trim()

                }).catch( function(err) {

                    console.info(err)

                    UIkit.modal.alert(err.body.description)

                }) 

            },

            enableInterval() {
                
                let _ = this

                setInterval(function(){

                    _.rerender = _.rerender + 1

                    _.list()
                                                    
                }, App.layout.server.interval)
                
            }

        }

    }

</script>

<template id="bi-home-template">

    <div class="uk-child-width-expand@s ukid-background-white-color" uk-grid>

        <div class="uk-section ukid-background-white-color">

            <div class="uk-container">

                   <div uk-scrollspy="cls:uk-animation-fade" class="ukid-margin-bottom-30 ukid-font-size-18">
                      STATUS ATUAL DO SERVIDOR:<br /><br />
                      PROCESSOR INFO: {{processorInfo}}<br />
                      ETHERNET INFO: {{ethernetInfo}}<br />
                   </div>
                   
                   <div class="uk-grid-match uk-child-width-1-3@m" uk-grid>
                    <div class="ukid-width-25-percent">
                        <div align="center" id="teste">
                            <GChart :key="rerender" type="Gauge" :data="gaugeCpuData" :options="gaugeOptions" :settings="{ packages: ['gauge'] }" />
                        </div>
                    </div>
                    <div class="ukid-width-25-percent">
                        <div align="center">
                            <GChart :key="rerender" type="Gauge" :data="gaugeMemoryData" :options="gaugeOptions" :settings="{ packages: ['gauge'] }" />
                        </div>
                    </div>
                    <div class="ukid-width-25-percent">
                        <div align="center">
                            <GChart :key="rerender" type="Gauge" :data="gaugeDiskSdaData" :options="gaugeOptions" :settings="{ packages: ['gauge'] }" />
                        </div>
                    </div>
                    <div class="ukid-width-25-percent">
                        <div align="center">
                            <GChart :key="rerender" type="Gauge" :data="gaugeDiskMntData" :options="gaugeOptions" :settings="{ packages: ['gauge'] }" />
                        </div>
                    </div>
                    <div class="ukid-width-25-percent">
                        <div align="center">
                            <GChart :key="rerender" type="Gauge" :data="gaugeGpuData" :options="gaugeOptions" :settings="{ packages: ['gauge'] }" />
                        </div>
                    </div>
                    <div class="ukid-width-25-percent">
                        <div align="center">
                            <GChart :key="rerender" type="Gauge" :data="gaugeVideoMemoryData" :options="gaugeOptions" :settings="{ packages: ['gauge'] }" />
                        </div>
                    </div>
                </div>
           
            </div> 

        </div>
    
    </div>

</template>