<!--suppress ALL-->

<!-- force load css -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import App from "../../app.json"

import Multiselect from 'vue-multiselect'

export default {

    inject: ['$validator'],

    props: ['label', 'description', 'app', 'strings', 'commons', 'entity', 'vm', 'users', 'id', 'name', 'required', 'multiple', 'parent_'],

    components: {

        Multiselect

    },

    data() {

        return {

            userList: null,
            selectedUsers: []

        }

    },

    created() {

        let _ = this

        _.required = `'${_.required}'`

        _.fillUsers()

    },

    watch: {

        users() {

            let _ = this

            if (_.multiple === true) {

                _.selectedUsers = _.userList.filter(item => _.entity.userList.includes(item.username))

            } else {

                if (_.entity.hasOwnProperty('users')) {

                    this.entity.users.forEach(u => _.selectedUsers.push( _.userList.filter( item => u === item.username ) ) )

                } else {

                    this.selectedUsers = this.userList.filter(item => this.entity.user === item.username)

                }

            }

        }

    },

    methods: {

        /**
         * List all groups
         */
        fillUsers() {

            let _ = this

            _.$http.get(

                App.host.concat(App.listAllUserEndpoint)

            ).then(response => {

                _.userList = response.body

                _.userList.forEach(function(obj) {

                    if (obj.username === _.vm.user.sub) {

                        if (_.multiple === true) {

                            _.entity.userList = _.userList.filter(item => obj.username === item.username).map(item => item.username)

                        } else {

                            if (_.entity.hasOwnProperty('users')) {

                                _.entity.users = [ obj.username ]

                            } else {

                                _.entity.user = obj.username

                            }

                            _.selectedUsers = _.userList.filter(item => obj.username === item.username)

                        }

                    }


                })

                try {
                    // Set this object to use on parent component
                    ( _.parent_ !== undefined && _.parent_ !== null )
                        ? _.parent_.users = response.body
                        : _.$parent.users = response.body
                } catch (err) {
                }

            })

        },

        selectFill(action) {

            let _ = this

            if (_.multiple === true) {

                _.selectedUsers = _.userList.filter(item => _.entity.userList.includes(item.username)).map(item => item.username)

            } else {

                if (_.entity.hasOwnProperty('users')) {

                    _.entity.users.forEach(u => _.selectedUsers.push( _.userList.filter(item => u === item.username ) ) )

                } else {

                    _.selectedUsers = _.userList.filter(item => _.entity.user == item.username)

                }

            }

        },

        selectAction(action) {

            if (this.multiple === true) {

                this.entity.userList.push(action.username)

            } else {

                if (this.entity.hasOwnProperty('users')) {

                    this.entity.users = [ action.username ]

                } else {

                    this.entity.user = action.username

                }

            }

        },

        removeAction(action) {

            if (this.multiple === true) {

                let index = this.entity.userList.indexOf(action)

                if (index !== -1) {

                   this.entity.userList.splice(index, 1)

                }

            } else {

                if (this.entity.hasOwnProperty('users')) {

                    this.entity.users = []

                } else {

                    this.entity.user = ''

                }

            }

        },

        refresh() {

            let _ = this

            setTimeout(function () {

                _.selectFill()

            }, 500)

        },

        truncate(param, size) {

            return (param.length > size) ? param.substr(0, size-1) + '...' : param;

        },

    }

}

</script>

<template id="users-component-template">

    <div class="uk-margin" v-if="strings.isNullOrEmpty(entity.user) || vm.isEditableForMe(entity.user)">
        <span class="uk-label ukid-primary-label-color">
            {{label}}<br />
            <span class="ukid-text-alert-color ukid-font-size-9" v-html="description">
            </span>
        </span>
        <br />
        <span v-if="errors.first(id)" class="ukid-text-fail-color">Selecione o(s) usuários(s)</span>
        <multiselect v-model="selectedUsers"
                     @input="selectFill"
                     @select="selectAction"
                     @remove="removeAction"
                     v-validate="'required'"
                     style="color: #444444 !important;"
                     track-by="username"
                     :id="id"
                     :name="name"
                     :options="userList === null ? [] : userList"
                     :hide-selected="multiple === true"
                     :searchable="true"
                     :close-on-select="true"
                     :show-labels="false"
                     :multiple="multiple === true"
                     :taggable="multiple === true"
                     placeholder="Selecione o usuário">

            <template slot="option" slot-scope="props">
                <span>
                    usuário: {{ props.option.username }}
                </span>
            </template>

            <template slot="singleLabel" slot-scope="props" v-if="multiple !== true">
                <span class="multiselect__tag">
                    <span>
                        usuário: {{ props.option.username }} -> selecionado
                    </span>
                </span>
            </template>

            <template slot="noResult">
                <span>
                    Nenhum usuário encontrada
                </span>
            </template>

            <template slot="noOptions">
                <span>
                    Comece a digitar
                </span>
            </template>

        </multiselect>

    </div>

    <div class="uk-margin" v-else>

        <span class="uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s">
            Usuário
        </span>
        <span class="uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s">
            Usuário
        </span>
        <br />
        <span class="uk-visible@s ukid-font-size-17">
            <b>CADASTRADO POR: <span class="ukid-text-alert-color ukid-text-uppercase">{{entity.user}}</span></b>
        </span>
        <span class="uk-hidden@s ukid-font-size-15">
            <b>CADASTRADO POR: <span class="ukid-text-alert-color ukid-text-uppercase">{{entity.user}}</span></b>
        </span>
    </div>

</template>