<!--suppress ALL -->

<script type="text/javascript">

    import App from '../../app.json'

    import UIkit from 'uikit'

    import { vm } from '../../js/main.js'
    import { Strings, Commons } from '../../js/common.js'
    import { Datetime } from 'vue-datetime'

    import GroupComponent from '../group/group-component'
    import UsersComponent from '../user/users-component'

    export default {

        components: {

            datetime: Datetime,
            groupComponent: GroupComponent,
            usersComponent: UsersComponent

        },

        data() {

            return {

                App: App,
                searchParam: '',
                pagination: {
                    last_page: 0
                },
                selectedGroups: [],
                groups: null,
                registers: {},
                person: {},
                invitePerson: {
                    id: null,
                    documentNumber: null,
                    name: null,
                    cardNumber: null,
                    email: null,
                    phone: null,
                    concluded: null,
                    accessStartDatetime: new Date().toISOString(),
                    accessEndDatetime: new Date(new Date().setHours( new Date().getHours() + 2 )).toISOString(),
                    groupList: [],
                    users: vm.user.auth.includes('signer') ? [vm.user.owner] : [vm.user.sub]
                },
                permAccess: false,
                users: {},
                page: 1,
                vm: vm,
                sub: null,
                Strings: Strings,
                Commons: Commons

            }

        },
        
        mounted() {

            let _ = this

            _.inviteSpreadsheetUpload()

        },

        created() {

            let _ = this

            _.sub = vm.user.sub

            _.list()
            _.fillUsers()
            _.enableInterval()

        },

        methods: {

            search() {

                let _ = this

                vm.spinnerSearch()
                
                setTimeout(function(){

                    _.list(undefined, 1, App.pagination.itensPerPage)

                    vm.spinnerSearch() 

                }, App.layout.form.interval)

            },

            list(page) {
                
                let _ = this

                if (page !== undefined) _.page = page

                _.$http.get(

                    App.host.concat(App.listInvitePersonEndpoint) 
                    + '?pageNum=' + (page === undefined ? 1 : page)
                    + '&perPage=' + App.pagination.itensPerPage
                    + '&param=' + _.searchParam

                ).then(response => {

                    _.registers = response.body.data
                    _.pagination = response.body
                                        
                }).catch( function(err){
                        
                    UIkit.modal.alert(err.body.description)

                }) 

            },   

            fillByDocumentNumber(e) {

                let _ = this

                let value = e

                if (e.target !== undefined) {

                    value = e.target.value.trim()

                }

                if (value !== '') {

                    let modal = null;

                    if (!Commons.isMobile()) 
                        modal = UIkit.modal.dialog(App.notification.inviteFormLoadPerson, { escClose: false, bgClose: false })
                    else
                        modal = UIkit.modal.dialog(App.notification.inviteFormLoadPerson, { escClose: false, bgClose: false })

                    _.$http.get(

                        App.host.concat(App.findByDocumentNumberPersonEndpoint.concat(value)) 

                    ).then(response => {

                        _.person = response.body

                        if (_.person.name) _.invitePerson.name  = _.person.name
                        if (_.person.email) _.invitePerson.email = _.person.email

                        setTimeout(function(){ modal.hide() }, 250)
                        
                    }, response => {

                        modal.hide()

                        if (err.body.status !== 404) {

                            UIkit.modal.alert(response.data)

                        }

                    })

                }

            },

            fillUsers() {

                this.$http.get(

                    App.host.concat(App.listAllUserEndpoint)

                ).then(response => {

                    let _ = this

                    _.users = response.body
                })

            },

            fill(object) {

                let _ = this

                _.errors.clear()
                
                vm.spinnerWithProgress(App.layout.progress.increment, App.layout.progress.interval)

                _.invitePerson = object
            
                if (!_.invitePerson.hasOwnProperty("accessStartDatetime") || !_.invitePerson.hasOwnProperty("accessEndDatetime")) {

                    document.querySelector('.ukid-permanent-access').checked = true
                    
                    _.permanentAccess()

                } else {

                    _.invitePerson.accessStartDatetime = new Date(_.invitePerson.accessStartDatetime).toISOString()
                    _.invitePerson.accessEndDatetime = new Date(_.invitePerson.accessEndDatetime).toISOString()
                    
                }

                _.fillByDocumentNumber(_.invitePerson.documentNumber)

                vm.configDeviceResolution()

            },

            del(objectId) {

                let _ = this

                _.$http.delete(App.host.concat(App.deleteInvitePersonEndpoint).concat(objectId)).then(response => {

                    UIkit.notification.closeAll()
                    UIkit.notification({ 
                        message: App.notification.del, 
                        status: 'primary', 
                        pos: 'top-center', 
                        timeout: App.notification.timeout 
                    });

                    if (_.pagination.current_page > 0 && _.page == _.pagination.last_page && _.pagination.data.length == 1) {

                        _.list(_.page - 1)

                    } else {

                        _.list(_.page)

                    }

                    if (!Commons.isMobile())
                        _.clear(false)

                }).catch(function(err) {
                        
                    UIkit.modal.alert(err.body.description)

                })    
            
            },

            save() {
                
                let _ = this;

                var modal = null

                _.$validator.validateAll().then((result) => {

                    if (!result) return

                    if (!Commons.isMobile()) 
                        modal = UIkit.modal.dialog(App.notification.inviteSending, { escClose: false, bgClose: false })
                    else
                        modal = UIkit.modal.dialog(App.notification.inviteSendingMobile, { escClose: false, bgClose: false })
                    
                    _.invitePerson.documentNumber = Strings.trim(_.invitePerson.documentNumber)
                    _.invitePerson.name  = Strings.trim(_.invitePerson.name)
                    _.invitePerson.email = Strings.trim(_.invitePerson.email)
                    _.invitePerson.phone = Strings.trim(_.invitePerson.phone)

                    _.$http.post(App.host.concat(App.saveInvitePersonEndpoint), _.invitePerson).then(response => {

                        UIkit.notification.closeAll()
                        UIkit.notification({ 
                            message: App.notification.save, 
                            status: 'primary', 
                            pos: 'top-center', 
                            timeout: App.notification.timeout
                        })

                        _.list(_.page)
                        
                        _.clear()

                        modal.hide()
                                            
                    }).catch( function(err){
                        
                        UIkit.modal.alert(App.messages.errorTitle.concat(err.body.description))

                    })                   

                }).catch((response) => {
                })               

            },

            clear(focus) {

                let _ = this;

                _.invitePerson = {}
                _.person = {}

                _.invitePerson.id = null
                _.invitePerson.name = null
                _.invitePerson.email = null
                _.invitePerson.documentNumber = null
                _.invitePerson.cardNumber = null
                _.invitePerson.phone = null

                if (_.vm.user.auth.includes('signer')) {

                    _.invitePerson.users = [_.vm.user.owner]

                } else {

                    _.invitePerson.users = [_.vm.user.sub]

                }
                
                _.invitePerson.accessStartDatetime = new Date().toISOString(),
                _.invitePerson.accessEndDatetime = new Date(new Date().setHours(new Date().getHours() + 2)).toISOString()                

                _.errors.clear()

                document.querySelector('.ukid-form').reset()

                _.permanentAccess()

                vm.configDeviceResolution()

            },

            permanentAccess() {

                let _ = this

                if (document.querySelector('.ukid-permanent-access').checked) {

                    _.invitePerson.accessStartDatetime = null
                    _.invitePerson.accessEndDatetime   = null

                    _.permAccess = true

                } else {

                    _.invitePerson.accessStartDatetime = new Date().toISOString()
                    _.invitePerson.accessEndDatetime   = new Date(new Date().setHours(new Date().getHours() + 2)).toISOString()
                    
                    _.permAccess = false

                }

            },            

            modal(invitePerson) {

                let _ = this;

                _.invitePerson = invitePerson
            
                UIkit.modal('#modal-invite-overflow').show()

            },

            enableInterval() {
                
                let _ = this

                setInterval(function(){

                    _.list(_.page)

                }, App.layout.camera.interval)
                
            },
            
            resend(invitePerson) {

                let _ = this;

                _.invitePerson = invitePerson

                _.save()
                
            },

            inviteSpreadsheetUpload() {

                let _ = this

                let bar = document.getElementById('ukid-js-progressbar-invite')

                UIkit.upload('.js-upload', {

                    url: `${App.host}/client/upload/invite/spreadsheet`,
                    type: 'application/json',
                    method: 'POST',
                    name: 'file',
                    allow: '*.(xls|xlsx)',

                    concurrent: 1,
                    multiple: false,

                    fail: function(message) {

                        UIkit.modal.alert('Tipo de arquivo selecionado inválido: Arquivos aceitos: xls e xlsx')

                    },

                    beforeSend: function(environment) {
                
                        environment.headers = {
                            Authorization: 'Bearer ' + sessionStorage.token
                        }

                    },

                    loadStart: function(e) {

                        bar.removeAttribute('hidden')

                        bar.max = e.total
                        bar.value = e.loaded

                    },

                    progress: function(e) {
                        
                        bar.max = e.total
                        bar.value = e.loaded

                    },

                    loadEnd: function(e) {
                    
                        bar.max = e.total
                        bar.value = e.loaded

                    },

                    completeAll: function(environment) {

                        console.info(`Result upload: ${environment.response}`)

                        setTimeout(function() { bar.setAttribute('hidden', 'hidden') }, 1000)

                        if (JSON.parse(environment.response).code === '200') {

                            UIkit.modal.alert(App.notification.inviteBatchSuccess)

                        }

                    }

                })

            }

        }

    }

</script>

<template id="invite-form-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>

        <div id="modal-invite-overflow" uk-modal>
            <div class="uk-modal-dialog">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">
                        {{App.messages.popoUpButtonCancel}}
                    </button>
                    <button v-on:click="del(invitePerson.id)" class="uk-button uk-button-primary uk-modal-close" type="button">
                        {{App.messages.popoUpButtonDelete}}
                    </button>
                </div>

            </div>
        </div>

        <div id="modal-overflow-resend" uk-modal>
            <div class="uk-modal-dialog">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>
                        Tenha ciência de que ao fazer esta operação para uma pessoa já cadastrada, apenas estará renovando seu acesso.
                        Nenhuma ação do convidado será necessária, pois sua face já se encontra em nossa base
                    </p>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">
                        {{App.messages.popoUpButtonCancel}}
                    </button>
                    <button v-on:click="save" class="uk-button uk-button-primary uk-modal-close" type="button">
                        {{App.messages.popoUpButtonOk}}
                    </button>
                </div>

            </div>
        </div>        

        <div id="ukid-filter" class="ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color">

            <dl class="uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center">

               <div class="uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165">
                    <span class="uk-hidden ukid-spinner-sm" uk-spinner="ratio:2.6"></span>
                    <span class="ukid-icon-sm ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:social;ratio:3.6"></span>                    
                </div>

                <div class="uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl">
                    <br />
                    <div class="ukid-padding ukid-padding-10px ukid-font-size-15">
                        Criação, atualização e exclusão de galerias
                        <br />Total: {{pagination.databaseCount}}
                    </div>
                </div>

                <br class="uk-hidden@m uk-hidden@l uk-hidden@xl" />

                <dt>
                    Pesquisar
                </dt>

                <br />                

                <dd>
                    <input v-on:keyup.enter="search()" v-model="searchParam" id="search-param-input" class="uk-input ukid-width-80-percent" type="text" placeholder="Pesquise qualquer termo">
                    <span v-on:click="search()" class="ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6" uk-icon="icon:search;ratio:1.4"></span>
                    <span class="uk-hidden ukid-search-spinner ukid-magin-left-6" uk-spinner="ratio:0.92"></span>
                </dd>

                <button @click="clear(true)" type="button" class="uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13">
                    Iniciar Novo Registro
                </button>

                <dt v-for="invitePerson in registers" :key="invitePerson.id">
                    <div class="uk-label ukid-width-70-percent ukid-padding-10px">
                        <span v-if="invitePerson.documentNumber">N. Docto: {{invitePerson.documentNumber}}<br /></span>
                        <span v-if="invitePerson.name">Nome: {{invitePerson.name}}<br /></span>
                        <span v-if="invitePerson.email">Email: {{invitePerson.email}}<br /></span>
                        <span v-if="invitePerson.phone">Tel: {{invitePerson.phone}}<br /></span>
                        <span v-if="invitePerson.datetime">Envio: {{new Date(invitePerson.datetime) | dateFormat('DD/MM/YYYY HH:mm:ss')}}<br /></span>
                        <span v-if="invitePerson.concludedDatetime">Aceite: {{new Date(invitePerson.concludedDatetime) | dateFormat('DD/MM/YYYY HH:mm:ss')}}<br /></span>
                        <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-' + invitePerson.id" :uk-toggle="'target: #ukid-plus-animation-' + invitePerson.id + '; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:plus;ratio:1.0"></span>
                        <span title="Cadastro Concluído" uk-tooltip v-if="invitePerson.concluded && !invitePerson.renovation" class="ukid-invite-success ukid-magin-left-10" uk-icon="icon:check;ratio:1.2" />
                        <span title="Aguardando Cadastramento" uk-tooltip  v-if="!invitePerson.concluded" class="ukid-invite-waiting ukid-magin-left-10" uk-icon="icon:future;ratio:0.9" />
                        <span title="Renovação Concluída" uk-tooltip v-if="invitePerson.concluded && invitePerson.renovation" class="ukid-invite-success" uk-icon="icon:refresh;ratio:1.0"></span><br />
                        <table class="ukid-width-100-percent ukid-font-size-9" hidden :id="'ukid-plus-animation-' + invitePerson.id">
                            <tr v-for="users in invitePerson.users" :key="users.user">
                                <td align="right" width="65%">Cadastro esperado para:</td>
                                <td align="left" width="35%" class="ukid-padding-left-5">
                                    {{users}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <span v-if="invitePerson && !invitePerson.concluded" @click="resend(Object.assign({}, invitePerson))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:forward;ratio:1.1" :title="invitePerson.concluded ? 'Renovar Convite' : 'Reenviar Convite'" uk-tooltip></span>
                    <span v-if="invitePerson" @click="fill(Object.assign({}, invitePerson))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:file-edit;ratio:0.9"></span> 
                    <span v-if="invitePerson" @click="modal(Object.assign({}, invitePerson))" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:trash;ratio:0.9"></span>                    
                </dt>

            </dl>
            
            <div v-show="!registers.length" class="uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30" uk-alert>
                <span class="uk-form-icon ukid-search-spinner ukid-magin-left-10" uk-icon="icon:ban"></span>
                <span class="ukid-magin-left-20">
                    Nenhum registro encontrado
                </span>
            </div>

            <div v-show="pagination.last_page > 1" class="uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16">Pagina Atual: <b class="ukid-magin-left-10">{{page}}</b></div>

            <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>

                <paginate
                    :v-model="page"
                    :page-count="pagination.last_page"
                    :click-handler="list"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>

            </ul>

        </div>        

        <div id="ukid-card-form" :class=" { 'uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-90-percent' : Commons.isMobile(), 'uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent' : !Commons.isMobile() } ">

            <div class="uk-card-header ukid-supress-border-bottom uk-visible@m">
                <div class="uk-grid-small uk-flex-middle ukid-min-height-150" uk-grid>
                    <div class="uk-width-auto ukid-min-width-165">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:social;ratio:5.2"></span> 
                    </div>
                    <div class="uk-width-expand">
                        <span class="ukid-font-size-18">Gestão de Convites para Cadastramento Facial</span>
                        <div class="uk-margin-remove-top">
                            <span>                                
                                Criação, atualização e exclusão de convites de cadastramento facial<br />
                                Este módulo permite o envio de convites para cadastramento facial<br />
                                Após o preenchimeto dos campos abaixo, um convite será enviado a pessoa candidata a cadastramento facial<br />
                                <div class="uk-label ukid-label-yellow-color">Convites: {{pagination.databaseCount}}</div>
                            </span>                            
                        </div>
                    </div>
                </div>
            </div>

            <progress class="uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0" value="0" max="100"></progress> 
            
            <!--<invite-form :Commons="Commons" :sub="sub" :users="users" :person="person" :invitePerson="invitePerson" :permAccess="permAccess"></invite-form>-->
        
            <div :class="{ 'uk-card-footer ukid-supress-border-top' : !Commons.isMobile(), 'uk-card-footer ukid-supress-border-top ukid-card-footer-mobile' : Commons.isMobile() }">

                <form class="ukid-form" @submit.prevent="save">

                    <fieldset class="uk-fieldset">

                        <legend class="uk-legend">
                            <span class="ukid-font-size-25 uk-visible@m">Formulário</span>
                            <button @click="clear(true)" type="button" class="uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13">
                                Iniciar Novo Registro
                            </button>
                        </legend>

                        <div class="uk-margin">
                            <div class="js-upload uk-placeholder uk-text-center">
                                <a class="ukid-font-size-15" href="https://upload-staging.unike.tech/uploads/docs/unike-invite-modelo.xlsx">baixar planilha modelo</a>
                                <br />
                                <span uk-icon="icon:cloud-upload;ratio:1.3"></span>
                                <span class="uk-text-middle ukid-font-size-15">Envie a planilha baixada e devidamente preenchida aqui</span>
                                <div uk-form-custom>
                                    <input type="file" multiple>
                                    <a class="uk-link ukid-cursor-pointer ukid-font-size-15">selecione aqui</a>
                                </div>
                                <br />
                            </div>
                            <progress id="ukid-js-progressbar-invite" class="uk-progress" value="0" max="100" hidden></progress>
                        </div>

                        <div class="uk-margin">
                           <span class="uk-label ukid-primary-label-color">
                                Acesso Permanente
                            </span>
                            <br />
                            <input @click="permanentAccess" class="ukid-permanent-access uk-checkbox ukid-margin-top-10" type="checkbox">
                        </div>

                        <div v-if="!Commons.isMobile()" :class="{ 'uk-margin ukid-margin-bottom-40' : !permAccess, 'uk-margin uk-hidden ukid-margin-bottom-40' : permAccess }">
                            <span class="uk-label ukid-primary-label-color">Intervalo de Acesso</span>
                            <br />
                            <div uk-grid>
                                <div>
                                    DE:
                                    <datetime
                                    v-model="invitePerson.accessStartDatetime"
                                    type="datetime"
                                    input-class="mx-input ukid-width-100-percent"
                                    value-zone="America/Sao_Paulo"
                                    zone="America/Sao_Paulo"
                                    format="dd/MM/yyyy HH:mm"
                                    :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                    :hour-step="1"
                                    :minute-step="5"
                                    :min-datetime="new Date(new Date().setMinutes(new Date().getMinutes() - 5)).toISOString()"
                                    :week-start="7"
                                    :auto="true"
                                    use24-hour></datetime>
                                </div>
                                <div></div>
                                <div>
                                    ATÉ:
                                    <datetime
                                    v-model="invitePerson.accessEndDatetime"
                                    type="datetime"
                                    input-class="mx-input ukid-width-100-percent"
                                    value-zone="America/Sao_Paulo"
                                    zone="America/Sao_Paulo"
                                    format="dd/MM/yyyy HH:mm"
                                    :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                                    :hour-step="1"
                                    :minute-step="5"
                                    :min-datetime="new Date(new Date().setHours(new Date().getHours() + 1)).toISOString()"
                                    :week-start="7"
                                    :auto="true"
                                    use24-hour></datetime>
                                </div>
                            </div>
                        </div>

                        <div v-if="Commons.isMobile()" :class="{ 'uk-margin ukid-margin-bottom-40' : !permAccess, 'uk-margin uk-hidden ukid-margin-bottom-40' : permAccess }">
                            <span class="uk-label ukid-primary-label-color">Intervalo de Acesso</span>
                            <br />
                            DE:
                            <datetime
                            v-model="invitePerson.accessStartDatetime"
                            type="datetime"
                            input-class="mx-input ukid-width-90-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy HH:mm"
                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                            :hour-step="1"
                            :minute-step="5"
                            :min-datetime="new Date(new Date().setMinutes(new Date().getMinutes() - 5)).toISOString()"
                            :week-start="7"
                            :auto="true"
                            use24-hour></datetime>
                            <br />
                            ATÉ:
                            <datetime
                            v-model="invitePerson.accessEndDatetime"
                            type="datetime"
                            input-class="mx-input ukid-width-90-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy HH:mm"
                            :phrases="{ok: 'Definir Hora', cancel: 'Sair'}"
                            :hour-step="1"
                            :minute-step="5"
                            :min-datetime="new Date(new Date().setHours(new Date().getHours() + 1)).toISOString()"
                            :week-start="7"
                            :auto="true"
                            use24-hour></datetime>
                        </div>

                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                                Número do Documento do Convidado
                                <br />
                                CPF, RG ou OUTROS
                                <br />
                                (Sem traços ou pontos)
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                Número
                                <br />
                                do Documento / Convidado
                                <br />
                                CPF, RG ou OUTROS
                                <br />
                                (Sem traços ou pontos)
                            </span> 
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>
                                <input                                     
                                    :class="{
                                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('documentNumber-input_field'), 
                                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('documentNumber-input_field')
                                    }" 
                                    v-model="invitePerson.documentNumber"
                                    v-validate="'required|min:3|max:40|numeric'"
                                    c-data-vv-as="field"
                                    id="documentNumber-input_field"
                                    name="documentNumber-input_field"
                                    type="text" 
                                    placeholder="Mínimo 3, máximo 40, número pelo qual o usuário se identifica">
                            </div>
                            <div class="ukid-width-100-percent ukid-margin-top-10">                                
                                <button type="button" @click="fillByDocumentNumber(invitePerson.documentNumber)" class="uk-button uk-button-default uk-button-small ukid-font-size-14">
                                    Verificar Pessoa
                                </button>
                            </div>
                            <div v-show="person.documentNumber" class="uk-inline ukid-width-100-percent ukid-margin-top-10">
                                <span>Pessoa Encontrada: {{person.documentNumber}} - {{person.name !== 'no_name_available' ? person.name : 'Nome indisponível'}}</span>
                                <div v-show="person.documentNumber" class="ukid-width-60-px ukid-margin-top-10">
                                    <div v-if="person.photo && person.highlightBlcFace" title="Esta pessoa foi marcada como ponto de atenção" uk-tooltip class="ukid-light-red-color ukid-circular-person-edit">
                                        <img :src="person.photo" class="ukid-circular-portrait" />
                                    </div>                            
                                    <div v-if="person.photo && !person.highlightBlcFace" class="ukid-light-blue-color ukid-circular-person-edit">
                                        <img :src="person.photo" class="ukid-circular-portrait" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="uk-margin">
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                                Nome do Convidado
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                Nome
                                <br />
                                do Convidado
                            </span> 
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>
                                <input 
                                    :class="{
                                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('name-input_field'), 
                                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('name-input_field')
                                    }" 
                                    v-model="invitePerson.name"
                                    v-validate="'required|min:3|max:80'"
                                    c-data-vv-as="field"
                                    id="name-input_field"
                                    name="name-input_field"
                                    type="text" 
                                    placeholder="Mínimo 3, máximo 80, nome pelo qual o usuário se identifica">
                            </div>
                        </div> 

                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                                Cartão de Acesso (Para Controle de Acesso)
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                Cartão de Acesso
                                <br />
                                (Para Controle de Acesso)
                            </span>                          
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>                                                            
                                <input 
                                    :class="{
                                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('cardNumber-input_field'), 
                                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('cardNumber-input_field')
                                    }" 
                                    v-model="invitePerson.cardNumber"
                                    v-validate="'min:3|max:40|numeric'"
                                    c-data-vv-as="field"
                                    id="cardNumber-input_field"
                                    name="cardNumber-input_field"
                                    type="text" 
                                    placeholder="Mínimo 3, máximo 40, não obrigatório">
                            </div>
                        </div>

                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                                Email do Convidado<br />
                                OBS: Sem Acentos
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                Email
                                <br />
                                do Convidado
                                <br />
                                OBS: Sem Acentos                                
                            </span>
                            <div v-show="errors.has('email-input_field')" class="uk-alert-danger ukid-margin-top-5" uk-alert>
                                <b>ATENÇÃO:</b> Email Inválido
                            </div>                            
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>                                                            
                                <input 
                                    :class="{
                                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('email-input_field'), 
                                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('email-input_field')
                                    }" 
                                    v-model="invitePerson.email"
                                    v-validate="'required|min:3|max:80|email'"
                                    c-data-vv-as="field"
                                    id="email-input_field"
                                    name="email-input_field"
                                    type="text" 
                                    placeholder="Mínimo 3, máximo 80, Não use acentos">
                            </div>
                        </div>

                        <div class="uk-margin">    
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                                Telefone do Convidado
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                Telefone
                                <br />
                                do Convidado
                            </span>
                            <div v-show="errors.has('phone-input_field')" class="uk-alert-danger ukid-margin-top-5" uk-alert>
                                <b>ATENÇÃO:</b> Ex: 11988442299
                            </div>
                            <div class="uk-inline ukid-width-100-percent">
                                <span class="uk-form-icon" uk-icon="icon:warning"></span>                                                            
                                <input 
                                    :class="{
                                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('phone-input_field'), 
                                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('phone-input_field')
                                    }" 
                                    v-model="invitePerson.phone"
                                    v-validate="'min:11|max:11|numeric'"
                                    c-data-vv-as="field"
                                    id="phone-input_field"
                                    name="phone-input_field"
                                    type="text" 
                                    placeholder="Ex: 11988997723, não obrigatório">
                            </div>                                                                                                                   
                        </div>

                        <group-component
                            ref="inviteGroupCpnt"
                            :id="'invite-group-select_field'"
                            :name="'invite-group-select_field'"
                            :label="'Grupos'"
                            :description="'(Ctrl + Clique) para mais de um grupo<br />Ao definir um grupo no convite, o cadastro facial<br />do convidado ficará disponível para o(s)<br />usuário(s) do(s) grupo(s) definidos(s)'"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :vm="vm"
                            :entity="invitePerson">

                        </group-component>

                        <users-component
                            ref="inviteUsersCpnt"
                            :id="'invite-users-select_field_1'"
                            :name="'invite-users-select_field_1'"
                            :required="'required'"
                            :label="'Usuário'"
                            :app="App"
                            :strings="Strings"
                            :commons="Commons"
                            :vm="vm"
                            :entity="invitePerson">

                        </users-component>

                        <div class="uk-flex uk-flex-right uk-visible@s">                        
                            <div class="uk-visible@m">
                                <router-link to="/home">
                                    <button class="uk-button uk-button-default uk-button-large uk-align-center">Cancelar</button>                            
                                </router-link>
                            </div>
                            <div class="uk-hidden@m">
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-default uk-button-large uk-align-center">Voltar</button>
                            </div>
                            <div>
                                <button type="submit" class="uk-button uk-button-default uk-button-large uk-margin-left">Salvar</button>
                            </div>
                        </div>

                        <div class="uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40">
                            <div>
                                <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14">Voltar</button>
                            </div>                            
                            <div>
                                <button type="submit" class="uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14">Salvar</button>
                            </div>
                        </div>                          

                    </fieldset>

                </form>

            </div>
            
        </div>
    </div>

</template>
