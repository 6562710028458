var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ukid-dash" }, [
    _c(
      "div",
      {
        attrs: {
          id: "ukid-modal-control-children-" + _vm.vm.user.sub,
          "uk-modal": ""
        }
      },
      [
        _c("div", { staticClass: "uk-modal-dialog uk-modal-body" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-link ukid-font-size-9",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.selectAlUsers()
                  }
                }
              },
              [_vm._v("Selecionar Tudo")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.usersChildrenList,
                      expression: "usersChildrenList"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "uk-select uk-textarea ukid-height-150-px",
                  attrs: {
                    multiple: "multiple",
                    rows: "7",
                    "data-vv-as": "field",
                    id: "gallery-select_field_2",
                    name: "gallery-select_field_2"
                  },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.usersChildrenList = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.users, function(option) {
                  return _c(
                    "option",
                    {
                      key: option.username,
                      domProps: { value: option.username }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            option.username == _vm.vm.user.sub
                              ? option.name + " - Usuário Logado"
                              : "" + option.name
                          ) +
                          "\n                            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "uk-text-right" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _c(
                  "button",
                  {
                    staticClass: "uk-button uk-button-default uk-modal-close",
                    attrs: { type: "button" }
                  },
                  [_vm._v("Voltar")]
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-primary uk-modal-close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.saveSelectedUsers()
                    }
                  }
                },
                [_vm._v("Continuar")]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("table", { attrs: { align: "left" } }, [
      _c("tr", [
        _c("td", { attrs: { width: "90" } }, [
          _c("span", {
            staticClass:
              "ukid-icon ukid-text-alert-color ukid-cursor-pointer ukid-magin-left-30 ukid-margin-top-20",
            attrs: {
              title: "Filtrar",
              "uk-tooltip": "",
              "uk-toggle":
                "target: #ukid-modal-control-children-" + _vm.vm.user.sub,
              "uk-icon": "icon:search;ratio:2.7"
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "ukid-margin-top-10", attrs: { align: "center" } },
      [
        _c("tr", [
          _c(
            "td",
            { attrs: { width: "160" } },
            [
              _vm._v("\n                DE:\n                "),
              _c("datetime", {
                attrs: {
                  type: "date",
                  "input-class": "mx-input ukid-width-70-percent",
                  "value-zone": "America/Sao_Paulo",
                  zone: "America/Sao_Paulo",
                  format: "dd/MM/yyyy",
                  phrases: {},
                  "hour-step": 1,
                  "minute-step": 5,
                  "week-start": 7,
                  auto: true,
                  "max-datetime": _vm.endDate,
                  "use24-hour": ""
                },
                model: {
                  value: _vm.startDate,
                  callback: function($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            { attrs: { width: "160" } },
            [
              _vm._v(
                "                    \n                ATÉ:\n                "
              ),
              _c("datetime", {
                attrs: {
                  type: "date",
                  "input-class": "mx-input ukid-width-70-percent",
                  "value-zone": "America/Sao_Paulo",
                  zone: "America/Sao_Paulo",
                  format: "dd/MM/yyyy",
                  phrases: {},
                  "hour-step": 1,
                  "minute-step": 5,
                  "week-start": 7,
                  auto: true,
                  "max-datetime": new Date().toISOString(),
                  "min-datetime": _vm.startDate,
                  "use24-hour": ""
                },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("td", { attrs: { width: "180" } }, [
            _c("span", {
              staticClass: "ukid-icon ukid-cursor-pointer ukid-margin-top-20",
              attrs: {
                title: "Limpar datas e voltar ao filtro desta semana",
                "uk-tooltip": "",
                "uk-icon": "icon:trash;ratio:2.7"
              },
              on: { click: _vm.clearFilter }
            })
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("iframe", {
      staticClass: "ukid-dash-iframe",
      attrs: {
        id: "ukid-unike-control",
        name: "ukid-unike-control",
        src: "content/unike_control_dash/index.html"
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ukid-font-size-15" }, [
      _vm._v("\n                Selecione um ou mais usuários"),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
        _vm._v(
          "\n                    (Ctrl + Clique) para mais de um usuário\n                "
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-54834692", { render: render, staticRenderFns: staticRenderFns })
  }
}