<template>
    <div :id="id" class="uk-flex-top " uk-modal>
            <div
                class="ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog ukid-zoom-photo-415px uk-margin-auto-vertical">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div :id="'ukid-container-zoom-' + id19">
                    <img crossorigin="Anonymous" :id="'ukid-image-face-zoom-' + id19" uk-tooltip
                        :src="principalCrop && principalCrop.snapshot" class="ukid-photo-effect-zoom" width="640"
                        height="480" alt="">
                </div>
            </div>
        </div>
</template>
<script>
export default {
  
    props: {
        id: String,
        id19: String,
        principalCrop: Object,
    },

    methods: {
        handleCheckboxChange() {
            this.$emit('update:selectedForUnion', this.id19);
        }
    }
};
</script>
    
<style scoped lang="scss">
.custom-card-body {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
}

.custom-column {
    display: flex;
    flex-direction: column;
    width: 100%
}

.custom-label-value {
    display: flex;
    justify-content: space-between;
}

.custom-label-value label {
    font-weight: bold;
    margin-right: 10px;
}

.custom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.custom-icon-container {
    display: flex;
}

.custom-icon {
    font-size: 24px;
}
</style>
  