export var configEffect = function(theme) {

    if (theme !== 'content/css/template_dinamic_gradient.css') {

        document.querySelector('.ukid-geometric-movs').style.display = 'none'

    } else {

        document.querySelector('.ukid-geometric-movs').style.display = 'block'

    }

}

export var setTheme = function(theme) {

    let _user = JSON.parse(sessionStorage.user)

    localStorage.setItem(window.location.hostname + '_' + _user.sub + '_theme', theme)
    localStorage.setItem('login_theme', theme)
    
    document.querySelector('#tmpl').setAttribute('href', theme)

    configEffect(theme)

}

export var showFastMenu = function(path) {

    let defaultMenu = document.querySelector('#ukid-default-menu')
    let fastMenu = document.querySelector('#ukid-fast-menu')    
    
    if (path === '/login' || path ===  '/home' || path === '/new/password') {
        
        fastMenu.classList.add('uk-hidden')
        defaultMenu.classList.remove('uk-hidden')

    } else {

        fastMenu.classList.remove('uk-hidden')
        defaultMenu.classList.add('uk-hidden')

    }

}

export var configFastMenu = function() {

    let _user = JSON.parse(sessionStorage.user)

    if (_user !== null && _user !== undefined) {

        document.querySelector('#ukid-router-link-gallery').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-group').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-user').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-camera').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-person').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-invite').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-intelligence').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-proximity').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-work').classList.add('uk-hidden')
        document.querySelector('#ukid-router-link-server').classList.add('uk-hidden')        

        if (_user.auth.includes('admin')) {

            document.querySelector('#ukid-router-link-gallery').classList.remove('uk-hidden')

        }

        if (_user.auth.includes('admin') || _user.auth.includes('user')) {

            document.querySelector('#ukid-router-link-group').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-user').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-camera').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-person').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-invite').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-intelligence').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-proximity').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-work').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-server').classList.remove('uk-hidden')

        }

        if (_user.auth.includes('signer')) {

            document.querySelector('#ukid-router-link-user').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-person').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-invite').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-intelligence').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-proximity').classList.remove('uk-hidden')

        }

        if (_user.auth.includes('facial_tracking')) {

            document.querySelector('#ukid-router-link-intelligence').classList.remove('uk-hidden')
            document.querySelector('#ukid-router-link-proximity').classList.remove('uk-hidden') 

        }

    }

}