<template>
    <div>
    
        <ModalDebug :modalId="'modal-debug-' + id19" :person="collection" :document="document" />
        <ModalMedia
        :id="'media-'+ id19"
        :id19="id19"
        :principalCrop="principalCrop"
        />
        <ModalWorkList :modalId="'history-' + id19" :workList="workList" :id19="id19" />
        <div v-if="!$route.query.hasOwnProperty('debug')"
            :class="'uk-card uk-card-default uk-card-bod ukid-width-' + filters.imageSize + '-px'">
            <div align="center" class="ukid-padding-5px">
                <table class="ukid-font-size-17 ukid-cursor-pointer" :width="filters.imageSize - 40">
                    <tbody>
                        <tr>
                            <td align="left">
                                <input :value="id19" :checked="selectedForUnion.includes(id19)"
                                    @change="handleCheckboxChange" class="uk-checkbox" type="checkbox">
                                <div class="ukid-circular-person-edit ukid-label-alert-color">
                                    <a  :href="'#media-' + id19"  uk-tooltip uk-toggle>

                                    <img :src="'data:image/png;base64,' + principalCrop.base64Crop"
                                        class="ukid-circular-portrait" />
                                    </a>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="custom-card-body">
                    <div class="custom-row">
                        <div class="custom-column">
                            <div class="custom-label-value">
                                <label>Captura Inicial:</label>
                                <span v-if="id19">{{ new Date(firstDetect.timestamp) |
                          dateFormat('DD/MM/YYYY')
                        }}</span>
                            </div>
                            <div class="custom-label-value" v-if="age">
                                <label>Idade:</label>
                                <span>{{ age.attributeValue !== 'NaN' ? age.attributeValue : 'Desconhecido' }}</span>
                            </div>
                            <div class="custom-label-value" v-if="gender">
                                <label>Gênero:</label>
                                <span>{{ gender.attributeValue !== 'NaN' ? gender.attributeValue : 'Desconhecido' }}</span>
                            </div>
                        </div>
                        <div class="custom-column">
                            <div class="custom-label-value" v-if="id19">
                                <label>Id19:</label>
                                <span>{{ id19 }}</span>
                            </div>
                        </div>

                        <div class="custom-column">
                        <div class="custom-label-value" v-if="age">
                                <label>Qtt Capturas:</label>
                                <span>{{ age.qttDetect }}</span>
                            </div>
                        </div>

                        <div class="custom-column">
                            <div class="custom-label-value" v-if="age">
                                <label>Qtt Dias:</label>
                                <span>{{ person.personV4Collection.principalStore.sumDetect }}</span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="custom-footer">
                    <div v-if="loggedUser" class="custom-icon-container">
                        <a :href="'#modal-debug-' + id19" uk-toggle>
                            <span class="custom-icon" title="Verificar Dados" uk-tooltip
                                uk-icon="icon:hashtag;ratio:1.2"></span>
                        </a>
                    </div>
                    <div v-if="loggedUser" class="custom-icon-container">
                            <UserIcon :id19="id19" :work="workList.length>0" />
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>    
<script>
import ModalDebug from './modal-debug.vue'
import ModalWorkList from './modal-workList.vue'
import App from '../../app.json'
import UserIcon from '../../../content/svgs/user.vue'
import ModalMedia from './modal-media.vue'
export default {
    components: {
        UserIcon,
        ModalMedia,
        ModalDebug,
        ModalWorkList,
    },
    data() {
        return {
            App: App,
        }
    },
    props: {
        id: String,
        loggedUser: Object,
        id19: String,
        age: Object,
        gender: Object,
        principalCrop: Object,
        collection: Object,
        document: Object,
        selectedForUnion: Array,
        filters: Object,
        workList: Array,
        firstDetect: Object,
        person: Object
    },

    methods: {
        handleCheckboxChange() {
            console.log(this.person)
            this.$emit('update:selectedForUnion', this.id19);
        }
    }
};
</script>
    
<style scoped lang="scss">
.custom-card-body {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
}

.custom-column {
    display: flex;
    flex-direction: column;
    width: 100%
}

.custom-label-value {
    display: flex;
    justify-content: space-between;
}

.custom-label-value label {
    font-weight: bold;
    margin-right: 10px;
}

.custom-footer {
    display: flex;
    justify-content: space-around;
    gap:1rem;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.custom-icon-container {
    display: flex;
}

.custom-icon {
    font-size: 24px;
}
</style>
  