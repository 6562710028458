<script>

    import UIkit from 'uikit'

    import Multiselect from 'vue-multiselect'

    export default {

        inject: ['$validator'],

        props: ['app', 'strings', 'commons', 'user', 'auth', 'role', 'vm'],

        components: {

            Multiselect

        },

        data() {

            return {
                
                certificateInformations: [],
                livenessWebTransactionsPage: 1,
                livenessWebTransactions: {
                    id: null,
                    transactionId: null,
                    createTimestamp: null,
                    updateTimestamp: null,
                    status: null,
                    feedback: null,
                    user: null
                },
                livenessWebTransactionsPagination: {
                    last_page: 0
                },
                livenessWebFilter: {
                    transactionId: null
                },
                livenessWebForm: {
                    quantity: 0
                }

            }

        },

        created() {

            let _ = this

            _.listLivenessWebTransactions()

        },

        methods: {

            transactionsGenerate() {

                let _ = this
              
                let modal = UIkit.modal.dialog(_.app.notification.livenessWebGenerateTransactions, { escClose: false, bgClose: false })

                if (!_.strings.isNullOrEmpty()) {

                    UIkit.modal.alert("Usuário indisponível. Selecione um usuário para prosseguir")

                    return

                }

                _.$http.post(

                    `${_.app.host}${_.app.livenessWebTransactionsGenerateEndpoint}${_.user.username}/${_.livenessWebForm.quantity}`

                ).then(() => {

                    UIkit.modal.alert(_.app.notification.livenessWebTransactionsGenerated)

                    _.listLivenessWebTransactions()

                    modal.hide()

                }).catch(function(err) {

                    modal.hide()

                    UIkit.modal.alert(err.body.description)

                })

            },

            listLivenessWebTransactions(livenessWebTransactionsPage) {

                let _ = this

                let modal = UIkit.modal.dialog(_.app.notification.loading, { escClose: false, bgClose: false })

                if (livenessWebTransactionsPage !== undefined) {
                
                    _.livenessWebTransactionsPage = livenessWebTransactionsPage

                }
                
                _.$http.get(

                    _.app.host.concat(_.app.livenessWebTransactionsEndpoint)
                    + '?pageNum=' + (livenessWebTransactionsPage === undefined ? 1 : livenessWebTransactionsPage)
                    + '&perPage=' + _.app.pagination.itensPerPage
                    + '&param=' + _.strings.nullToEmpty(_.livenessWebFilter.transactionId)
                    + '&username=' + _.strings.nullToEmpty(_.user.username)

                ).then(response => {

                    _.livenessWebTransactions = response.body.data
                    _.livenessWebTransactionsPagination = response.body

                    setTimeout(() => modal.hide(), 500)

                }).catch(function(err) {

                    setTimeout(() => modal.hide(), 500)

                    UIkit.modal.alert(err.body.description)

                })

            },

            addCertificateInfo(info) {

                let _ = this
                
                _.certificateInformations.push(info)
                _.user.selectedCertificateInformation.push(info)

            },

            uuidv4() {

                let _ = this

                const a = crypto.getRandomValues(new Uint16Array(8))

                let i = 0

                _.user.livenessWebCustomerId = '00-0-4-1-000'.replace(/[^-]/g, 
                        s => (a[i++] + s * 0x10000 >> s).toString(16).padStart(4, '0')
                )

                _.$forceUpdate()

            }

        }
        
    }

</script>

<template id="register-liveness-web-template">

    <!--  Liveness -->
    <li>

        <div class="uk-margin" v-if="!user.roles.includes('signer') && role !== 'signer'">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                Threshold de Liveness CFTV
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                Threshold de<br />Liveness CFTV
            </span>
            <br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-40-percent': errors.has('liveness-core-select_field'), 
                    'uk-input ukid-width-40-percent': !errors.has('liveness-core-select_field')
                }" 
                v-model="user.livenessScore"
                v-validate="'required|decimal:2|between:0.30,1'"
                c-data-vv-as="field"
                id="liveness-core-select_field"
                name="liveness-core-select_field"
                type="text"
                placeholder="Entre 0.30 e 1" />
        </div>

        <div class="uk-margin">
            <span class="ukid-primary-label-color">
                LIVENESS WEB
            </span>
        </div>

        <div class="uk-margin">

            <div v-if="auth.includes('admin')">
                <span class="uk-label ukid-primary-label-color uk-visible@s">
                    Habilitar validação de certificado
                    <br />
                    <span class="ukid-text-alert-color ukid-font-size-9">
                        Utilize essa opção quando o cliente está em ambiente de DEV                       
                    </span>
                </span>
                <span class="uk-label ukid-primary-label-color uk-hidden@s">
                    Habilitar Preenchimento de
                    <br />
                    Turno no Cadastro de Pessoa
                    <br />
                    <span class="ukid-text-alert-color ukid-font-size-9">
                        Utilize essa opção<br />
                        quando o cliente está<br />
                        em ambiente de DEV                       
                    </span>
                </span>
                <br />
                <div class="uk-inline ukid-width-100-percent">
                    <div :class="{
                            'ukid-width-100-percent': errors.has('enabled-input_field'), 
                            'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('liveness-web-certificates-enabled-input_field')}">
                        <label>
                            <input v-model="user.livenessWebCertificatesEnabled" value="true" class="uk-radio" type="radio" id="liveness-web-certificates-enabled-input-yes" name="liveness-web-certificates-enabled-input_field"> SIM
                        </label>
                        <label>
                            <input v-model="user.livenessWebCertificatesEnabled" value="false" class="uk-radio" type="radio" id="liveness-web-certificates-enabled-input-no" name="liveness-web-certificates-enabled-input_field" checked> NÃO
                        </label>
                    </div>
                </div>
            </div>

            <div v-if="auth.includes('admin') && ( user.livenessWebCertificatesEnabled === true || user.livenessWebCertificatesEnabled === 'true' )">

                <div class="ukid-width-80-percent ukid-font-size-11">
                    <span class="uk-label ukid-primary-label-color ukid-font-size-12">
                        Inserir informações de certificado:                        
                    </span>
                    <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                        <div class="uk-text-left@s uk-card-body ukid-font-size-15">
                            <span class="ukid-label-roles">Exemplo ( Em Única Linha )</span>
                            <br /><br />
                            subject: CN=*.unike.tec, issuer: CN=AlphaSSL CA - SHA256 - G2, fingerprint: A3:37:C1:BF, serialNumber: 646C21
                        </div>
                    </div>
                </div>
                <br />
                <multiselect v-model="user.selectedCertificateInformation"
                            :options="certificateInformations"
                            :multiple="true" 
                            :taggable="true"
                            selectLabel="Enter para marcar"
                            deselectLabel="Enter para desmarcar"
                            selectedLabel="Email marcado"
                            tag-placeholder="Adicionar informação do certificado" 
                            placeholder="Adicionar informação do certificado"
                            @tag="addCertificateInfo"></multiselect>

                    <template slot="noResult">
                        <span>
                            Nenhuma AKI encontrado
                        </span>
                    </template>

                    <template slot="noOptions">
                        <span>
                            Comece a digitar
                        </span>
                    </template>

                    <template slot="tag" slot-scope="{ option }">
                        <div class="multiselect__tag uk-text-center ukid-width-270-px">
                            <span>
                                {{option}}
                            </span>
                        </div>
                    </template>

                <br /><br />

            </div>

            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color"></span>
                ID do Cliente
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color"></span>
                ID do Cliente
            </span>
            <br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('liveness-web-customer-id-select_field'),
                    'uk-input ukid-width-100-percent': !errors.has('liveness-web-customer-id-select_field')
                }"
                :disabled="!auth.includes('admin')"
                v-model="user.livenessWebCustomerId"
                v-validate="'min:10|max:100'"
                c-data-vv-as="field"
                id="liveness-web-customer-id-select_field"
                name="liveness-web-customer-id-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 1000" />
            <br /><br />
            <button v-if="auth.includes('admin')" @click.prevent="uuidv4" type="buttom" class="uk-button uk-button-default uk-button-medium">Gerar</button>
            <br /><br />
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:server;ratio:1.2"></span>
                Url do seu serviço para resposta de liveness
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:server;ratio:1.2"></span>
                Url do seu serviço
                <br />
                para resposta de liveness
            </span>
            <br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('liveness-web-url-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('liveness-web-url-select_field')
                }" 
                v-model="user.livenessWebUrl"
                v-validate="'url|min:10|max:1000'"
                c-data-vv-as="field"
                id="liveness-web-url-select_field"
                name="liveness-web-url-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 1000" />
        </div>
        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-visible@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:lock;ratio:1.2"></span>
                Token ( Prefix: Basic )
            </span>
            <span class="uk-label ukid-primary-label-color uk-hidden@s">
                <span class="ukid-icon-tuscan-color" uk-icon="icon:lock;ratio:1.2"></span>
                Token ( Prefix: Basic )
            </span>
            <br />
            <input 
                :class="{
                    'ukid-form-error uk-input ukid-width-100-percent': errors.has('liveness-web-token-select_field'), 
                    'uk-input ukid-width-100-percent': !errors.has('liveness-web-token-select_field')
                }" 
                v-model="user.livenessWebToken"
                v-validate="'min:10|max:500'"
                c-data-vv-as="field"
                id="liveness-web-token-select_field"
                name="liveness-web-token-select_field"
                type="text"
                placeholder="Mínimo 10, máximo 500" />
        </div>

        <br />

        <div class="uk-margin">
            <div v-if="auth.includes('admin')" class="uk-margin">
                <div class="ukid-width-90-percent">
                    <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                        <div class="uk-text-left@s uk-card-body">
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-color-17">
                                Detectamos que você está logado como admin<br /> 
                                Isso possibilita que você gere id's de transação para uso de Liveness WEB
                            </span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-color-17">                
                                Detectamos que você
                                <br />
                                está logado como admin
                                <br />
                                Isso possibilita que você gere
                                <br />
                                id's de transação para
                                <br />
                                uso do Liveness WEB
                            </span>
                            <span v-if="!strings.isNullOrEmpty(user.username)" class="uk-text-uppercase ukid-text-alert-color ukid-font-color-17">
                                <br />
                                <b>{{user.name}} - {{user.username}}</b>
                            </span>
                            <br />
                            <div class="uk-margin">
                                <span class="uk-label ukid-primary-label-color uk-visible@s">Quantidade de Transações</span>
                                <span class="uk-label ukid-primary-label-color uk-hidden@s">Quantidade de<br />Transações</span>
                                <br />
                                <input 
                                    :class="{
                                        'ukid-form-error uk-input ukid-width-70-percent': errors.has('livenessWebForm-quantity-select_field'), 
                                        'uk-input ukid-width-70-percent': !errors.has('livenessWebForm-quantity-select_field')
                                    }" 
                                    v-model="livenessWebForm.quantity"
                                    v-validate="'numeric|min:1|max:1000000'"
                                    c-data-vv-as="field"
                                    id="livenessWebForm-quantity-select_field"
                                    name="livenessWebForm-quantity-select_field"
                                    type="text"
                                    placeholder="Entre 1 e 1000000" />
                            </div>

                            <button @click.prevent="transactionsGenerate" type="buttom" class="uk-button uk-button-default uk-button-large">Gerar Id's</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <div v-if="!auth.includes('signer')" class="uk-margin">
                <div class="ukid-width-90-percent">
                    <div class="uk-card uk-card-primary uk-card-small ukid-card-roles">
                        <div class="uk-text-left@s uk-card-body">
                            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-color-17">Buscar um id de transação específico</span>
                            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-color-17">
                                Buscar um id de transação
                                <br />
                                específico
                            </span>
                            <br />
                            <input 
                                class="uk-input ukid-width-70-percent" 
                                v-model="livenessWebFilter.transactionId"
                                v-validate="'min:1|max:150'"
                                c-data-vv-as="field"
                                id="liveness-web-transaction-id-select_field"
                                name="liveness-web-transaction-id-select_field"
                                type="text"
                                placeholder="Mínimo 1, máximo 150" />

                            <br /><br />

                            <button @click.prevent="listLivenessWebTransactions()" type="buttom" class="uk-button uk-button-default uk-button-large">Pesquisar</button>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="livenessWebTransactions !== null && livenessWebTransactions.length > 0" class="ukid-margin-top-20">

            <table class="uk-table uk-table-small uk-table-striped">
                <thead>
                    <tr>
                        <th class="uk-width-small uk-text-center">
                            Data / Id de Transação / Status / Feedback
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="transaction in livenessWebTransactions" :key="transaction.id">
                        <td class="uk-table-expand uk-text-center">
                            {{new Date(transaction.updateTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                            <br />
                            {{transaction.transactionId}} 
                            <br />
                            <span v-if="transaction.status === 'SENT'" class="ukid-text-success-color uk-text-uppercase">Enviado</span>
                            <span v-else class="ukid-text-alert-color uk-text-uppercase">Não enviado</span>
                            <br />
                            {{transaction.feedback}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-show="livenessWebTransactionsPagination.last_page > 1" class="uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16">
                Pagina Atual: <b class="ukid-magin-left-10">{{livenessWebTransactionsPage}}</b>
            </div>

            <ul v-show="livenessWebTransactionsPagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>
                <paginate
                    v-model="livenessWebTransactionsPage"
                    :page-count="livenessWebTransactionsPagination.last_page"
                    :click-handler="listLivenessWebTransactions"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                </paginate>
            </ul>

        </div>
        <div v-else>
            <br /><br />
            Nunhum id de transação diponível ainda
        </div>

    </li>

</template>