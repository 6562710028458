    <script type="text/javascript">

        import UIkit from 'uikit'

        import App from '../../app.json'

        import { vm } from '../../js/main.js'

        import { Commons } from '../../js/common.js';

        export default {

            components: {
            },

            data() {

                return {

                    App: App,
                    searchParam: '',
                    pagination: {
                        last_page: 0
                    },                
                    registers: {},
                    gallery: {
                        id: null,
                        name: '',
                        faces: '0'
                    },
                    page: 1,
                    vm: vm,
                    Commons: Commons

                }

            },

            created() {

                this.list()

            },

            methods: {

                search() {

                    let _ = this

                    vm.spinnerSearch()
                    
                    setTimeout(function(){

                        _.list(undefined, 1, App.pagination.itensPerPage)

                        vm.spinnerSearch() 

                    }, App.layout.form.interval)

                },

                list(page) {
                    
                    let _ = this

                    if (page !== undefined) {
                    
                        _.page = page

                    }

                    _.$http.get(

                        App.host.concat(App.listGalleryEndpoint) 
                        + '?pageNum=' + (page === undefined ? 1 : page)
                        + '&perPage=' + App.pagination.itensPerPage
                        + '&param=' + _.searchParam

                    ).then(response => {

                        _.registers = response.body.data
                        _.pagination = response.body
                                            
                    }).catch( function(err){
                            
                        UIkit.modal.alert(err.body.description)

                    }) 

                },   

                fill(object) {

                    let _ = this

                    _.errors.clear()
                    
                    vm.spinnerWithProgress(App.layout.progress.increment, App.layout.progress.interval)

                    _.gallery = object

                    vm.configDeviceResolution()

                    document.querySelector('.ukid-focus').focus()
                            
                },

                del(object) {

                    let _ = this

                    _.$http.delete(App.host.concat(App.deleteGalleryEndpoint).concat(object.name)).then(response => {

                        UIkit.notification.closeAll()
                        UIkit.notification({ message: App.notification.del, status: 'primary', pos: 'top-center', timeout: App.notification.timeout });

                        if (_.pagination.current_page > 0 && _.page == _.pagination.last_page && _.pagination.data.length == 1) {

                            _.list(_.page - 1)

                        } else {

                            _.list(_.page)

                        }

                        if (!Commons.isMobile())
                        _.clear(false)

                    }).catch(function(err) {
                            
                        UIkit.modal.alert(err.body.description)

                    })    
                
                },

                save() {
                    
                    let _ = this;

                    _.$validator.validateAll().then((result) => {

                        if (!result) {
                        
                            return;
                            
                        }

                        _.$http.post(App.host.concat(App.saveGalleryEndpoint), _.gallery).then(response => {

                            UIkit.notification.closeAll()
                            UIkit.notification({ message: App.notification.save, status: 'primary', pos: 'top-center', timeout: App.notification.timeout});

                            _.list(_.page)
                                                
                        }).catch( function(err){
                            
                            UIkit.modal.alert(App.messages.errorTitle.concat(err.body.description))

                        })                   

                    }).catch((response) => {

                    })               

                },

                clear(focus) {

                    let _ = this;

                    _.gallery = {}
                    _.errors.clear()

                    document.querySelector('.ukid-form').reset()

                    if (focus) {

                        document.querySelector('.ukid-focus').focus()

                    }

                    vm.configDeviceResolution()

                },

                modal(gallery) {

                    let _ = this;

                    _.gallery = gallery

                    UIkit.modal('#modal-overflow').show()

                }

            }

        }

    </script>

    <template id="gallery-template">

        <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>

            <div id="modal-overflow" uk-modal>
                <div class="uk-modal-dialog">

                    <button class="uk-modal-close-default" type="button" uk-close></button>

                    <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                        <p>{{App.messages.delGalleryAsk}}<br />{{App.messages.delGalleryWarn1}}<br />{{App.messages.delGalleryWarn2}}</p>
                    </div>

                    <div class="uk-modal-footer uk-text-right">
                        <button class="uk-button uk-button-default uk-modal-close" type="button">
                            {{App.messages.popoUpButtonCancel}}
                        </button>
                        <button v-on:click="del(Object.assign({}, gallery))" class="uk-button uk-button-primary uk-modal-close" type="button">
                            {{App.messages.popoUpButtonDelete}}
                        </button>
                    </div>

                </div>
            </div>

            <div id="ukid-filter" class="ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color">

                <dl class="uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center">

                <div class="uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165">
                        <span class="uk-hidden ukid-spinner-sm" uk-spinner="ratio:2.6"></span>
                        <span class="ukid-icon-sm ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:folder;ratio:3.6"></span>                    
                    </div>

                    <div class="uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl">
                        <br />
                        <div class="ukid-padding ukid-padding-10px ukid-font-size-15">
                            Criação, atualização e exclusão de galerias
                            <br />Total: {{pagination.databaseCount}}
                        </div>
                    </div>

                    <br class="uk-hidden@m uk-hidden@l uk-hidden@xl" />

                    <dt>
                        Pesquisar
                    </dt>

                    <br />

                    <dd>
                        <input v-on:keyup.enter="search()" v-model="searchParam" id="search-param-input" class="uk-input ukid-width-80-percent" type="text" placeholder="Pesquise qualquer termo">
                        <span v-on:click="search()" class="ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6" uk-icon="icon:search;ratio:1.4"></span>
                        <span class="uk-hidden ukid-search-spinner ukid-magin-left-6" uk-spinner="ratio:0.92"></span>
                    </dd>

                    <button @click="clear(true)" type="button" class="uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13">
                        Iniciar Novo Registro
                    </button>

                    <dt v-for="gallery in registers" :key="gallery.name">
                        <div class="uk-label ukid-width-70-percent ukid-padding-10px">
                            {{gallery.name}}
                            <br />
                            Faces Cadastradas: {{gallery.faces}}
                        </div>                    
                        <span @click="fill(gallery)" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:file-edit;ratio:0.9"></span> 
                        <span v-if="gallery" @click="modal(gallery)" class="ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer" uk-icon="icon:trash;ratio:0.9"></span>                    
                    </dt>

                </dl>
                
                <div v-show="!registers.length" class="uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30" uk-alert>
                    <span class="uk-form-icon ukid-search-spinner ukid-magin-left-10" uk-icon="icon:ban"></span>
                    <span class="ukid-magin-left-20">
                        Nenhum registro encontrado
                    </span>
                </div>

                <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30" uk-margin>

                    <paginate
                        :v-model="page"
                        :page-count="pagination.last_page"
                        :click-handler="list"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-30'">
                    </paginate>

                </ul>

            </div>        

            <div id="ukid-card-form" class="uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent">

                <div class="uk-card-header ukid-supress-border-bottom uk-visible@m">
                    <div class="uk-grid-small uk-flex-middle ukid-min-height-150" uk-grid>
                        <div class="uk-width-auto ukid-min-width-165">
                            <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                            <span class="ukid-icon ukid-icon-yellow-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:folder;ratio:5.2"></span> 
                        </div>
                        <div class="uk-width-expand">
                            <span class="ukid-font-size-18">Gestão de Galerias</span>
                            <p class="uk-margin-remove-top">
                                <span>                                
                                    Criação, atualização e exclusão<br />
                                    As galerias são armazenadas diretamente em nosso matcher<br />
                                    São utilizadas para segmentar biometrias
                                    <br />
                                    <div class="uk-label ukid-label-yellow-color">Galerias: {{pagination.databaseCount}}</div>
                                </span>                            
                            </p>
                        </div>
                    </div>
                </div>

                <progress class="uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0" value="0" max="100"></progress> 

                <div :class="{ 'uk-card-footer ukid-supress-border-top' : !Commons.isMobile(), 'uk-card-footer ukid-supress-border-top ukid-card-footer-mobile' : Commons.isMobile() }">

                    <form class="ukid-form" @submit.prevent="save">

                        <fieldset class="uk-fieldset">

                            <legend class="uk-legend">                            
                                <span class="ukid-font-size-25 uk-visible@m">Formulário</span>
                                <button @click="clear(true)" type="button" class="uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13">
                                    Iniciar Novo Registro
                                </button>                                                              
                            </legend>

                            <div class="uk-margin">    
                                <span class="uk-label uk-margin ukid-primary-label-color">Nome da Galeria</span><br />
                                <div v-show="errors.has('name-input_field')" class="uk-alert-danger uk-visible@s" uk-alert>
                                    <b>ATENÇÃO:</b> A criação da galeria exige de 3 a 20 digitos, permite letras, números e underscore _
                                </div>
                                <div v-show="errors.has('name-input_field')" class="uk-alert-danger uk-hidden@s ukid-font-size-10" uk-alert>
                                    <b>ATENÇÃO:</b> A criação da galeria exige de 3 a 20 digitos, permite letras, números e underscore _
                                </div>
                                <div class="uk-inline ukid-width-100-percent">
                                    <span class="uk-form-icon" uk-icon="icon:warning"></span>
                                    <input 
                                        :class="{
                                            'ukid-form-error uk-form-large uk-input ukid-focus ukid-width-100-percent': errors.has('name-input_field'), 
                                            'uk-input uk-form-large ukid-focus ukid-width-100-percent': !errors.has('name-input_field')
                                        }" 
                                        v-model="gallery.name"
                                        v-validate="'required|alpha_dash|min:3|max:100|regex:^([a-zA-Z0-9_]+)$'"
                                        c-data-vv-as="field"
                                        id="name-input_field"
                                        name="name-input_field"
                                        type="text" 
                                        placeholder="Mínimo 3, máximo 100">
                                </div>
                            </div>

                            <div class="uk-flex uk-flex-right uk-visible@m">
                                <div class="uk-visible@m">
                                    <router-link to="/home">
                                        <button class="uk-button uk-button-default uk-button-large uk-align-center">Cancelar</button>
                                    </router-link>
                                </div>
                                <div class="uk-hidden@m">
                                    <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-default uk-button-large uk-align-center">Voltar</button>
                                </div>
                                <div>
                                    <button type="submit" class="uk-button uk-button-default uk-button-large uk-margin-left">Salvar</button>
                                </div>
                            </div>

                            <div class="uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40">
                                <div>
                                    <button type="button" @click="vm.configDeviceResolution()" class="uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14">Voltar</button>
                                </div>
                                <div>
                                    <button type="submit" class="uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14">Salvar</button>
                                </div>
                            </div>

                        </fieldset>

                    </form>

                </div>
            </div>
        </div>

    </template>
