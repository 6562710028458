var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                skip_count\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-skip_count-format-1",
              "uk-toggle":
                "target: #ukid-plus-animation-skip_count-format-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                skip_count\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-skip_count-format-2",
              "uk-toggle":
                "target: #ukid-plus-animation-skip_count-format-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(1)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.skip_count,
              expression: "camera.stream_settings_gpu.skip_count"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,50",
              expression: "'required|between:0,50'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "skip_count_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "skip_count_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "skip_count_gpu_input_field",
            name: "skip_count_gpu_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 50"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.skip_count },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "skip_count",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_format\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-format-1",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-format-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(2)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_format\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-ffmpeg-format-2",
              "uk-toggle":
                "target: #ukid-plus-animation-ffmpeg-format-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(3)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.ffmpeg_format,
              expression: "camera.stream_settings_gpu.ffmpeg_format"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:3|max:100",
              expression: "'min:3|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "ffmpeg_format_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "ffmpeg_format_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "ffmpeg_format_gpu_input_field",
            name: "ffmpeg_format_gpu_input_field",
            type: "text",
            placeholder: "Mínimo 3, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.ffmpeg_format },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "ffmpeg_format",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.play_speed,
              expression: "camera.stream_settings_gpu.play_speed"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:-1,10",
              expression: "'required|between:-1,10'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "play_speed_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "play_speed_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "play_speed_input_field",
            name: "play_speed_input_field",
            type: "text",
            placeholder: "Entre 30 e 100"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.play_speed },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "play_speed",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_quality\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-quality-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-quality-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(4)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_quality\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-quality-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-quality-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(5)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.filter_min_quality,
              expression: "camera.stream_settings_gpu.filter_min_quality"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1",
              expression: "'required|between:0,1'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "filter_min_quality_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "filter_min_quality_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "filter_min_quality_input_field",
            name: "filter_min_quality_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: {
            value: _vm.camera.stream_settings_gpu.filter_min_quality
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "filter_min_quality",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-face-size-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-face-size-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(6)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_min_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-min-face-size-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-min-face-size-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(7)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.filter_min_face_size,
              expression: "camera.stream_settings_gpu.filter_min_face_size"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "filter_min_face_size_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "filter_min_face_size_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "filter_min_face_size_input_field",
            name: "filter_min_face_size_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: {
            value: _vm.camera.stream_settings_gpu.filter_min_face_size
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "filter_min_face_size",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_max_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-max-face-size-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-max-face-size-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(8)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                filter_max_face_size\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-max-face-size-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-max-face-size-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(9)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.filter_max_face_size,
              expression: "camera.stream_settings_gpu.filter_max_face_size"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:0|max:1000",
              expression: "'required|min:0|max:1000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "filter_max_face_size_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "filter_max_face_size_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "filter_max_face_size_input_field",
            name: "filter_max_face_size_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 1000"
          },
          domProps: {
            value: _vm.camera.stream_settings_gpu.filter_max_face_size
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "filter_max_face_size",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(10),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "normalized_only_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "normalized_only_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.normalized_only,
                    expression: "camera.stream_settings_gpu.normalized_only"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "normalized_only_input_field_yes",
                  name: "normalized_only_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.normalized_only,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "normalized_only",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.normalized_only,
                    expression: "camera.stream_settings_gpu.normalized_only"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "normalized_only_input_field_no",
                  name: "normalized_only_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.normalized_only,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "normalized_only",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(12),
      _vm._v(" "),
      _vm._m(13),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.jpeg_quality,
              expression: "camera.stream_settings_gpu.jpeg_quality"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:30,100",
              expression: "'required|between:30,100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "jpeg_quality_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "jpeg_quality_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "jpeg_quality_gpu_input_field",
            name: "jpeg_quality_gpu_input_field",
            type: "text",
            placeholder: "Entre 30 e 100"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.jpeg_quality },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "jpeg_quality",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(14),
      _vm._v(" "),
      _vm._m(15),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "overall_only_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "overall_only_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.overall_only,
                    expression: "camera.stream_settings_gpu.overall_only"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "overall_only_input_field_yes",
                  name: "overall_only_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.overall_only,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "overall_only",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.overall_only,
                    expression: "camera.stream_settings_gpu.overall_only"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "overall_only_input_field_no",
                  name: "overall_only_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.overall_only,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "overall_only",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_params\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-ffmpeg-params-1",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-ffmpeg-params-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(16)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                ffmpeg_params\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-filter-ffmpeg-params-2",
              "uk-toggle":
                "target: #ukid-plus-animation-filter-ffmpeg-params-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(17)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.ffmpeg_params,
              expression: "camera.stream_settings_gpu.ffmpeg_params"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "ffmpeg_params_gpu_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "ffmpeg_params_gpu_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "ffmpeg_params_gpu_input_field",
            name: "ffmpeg_params_gpu_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.ffmpeg_params },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "ffmpeg_params",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_timeout_ms\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-timeout-ms-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-timeout-ms-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(18)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_timeout_ms\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-timeout-ms-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-timeout-ms-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(19)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.router_timeout_ms,
              expression: "camera.stream_settings_gpu.router_timeout_ms"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:1000,30000",
              expression: "'required|between:1000,30000'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "router_timeout_ms_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "router_timeout_ms_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "router_timeout_ms_input_field",
            name: "router_timeout_ms_input_field",
            type: "text",
            placeholder: "Entre 1000 e 30000"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.router_timeout_ms },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "router_timeout_ms",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_verify_ssl\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-verify-ssl-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-verify-ssl-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(20)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_verify_ssl\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-verify-ssl-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-verify-ssl-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(21)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "router_verify_ssl_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "router_verify_ssl_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.router_verify_ssl,
                    expression: "camera.stream_settings_gpu.router_verify_ssl"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "router_verify_ssl_input_field_yes",
                  name: "router_verify_ssl_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.router_verify_ssl,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "router_verify_ssl",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.router_verify_ssl,
                    expression: "camera.stream_settings_gpu.router_verify_ssl"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "router_verify_ssl_input_field_no",
                  name: "router_verify_ssl_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.router_verify_ssl,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "router_verify_ssl",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_headers\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-headers-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-headers-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(22)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_headers\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-headers-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-headers-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(23)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.router_headers,
              expression: "router_headers"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:0|max:2000",
              expression: "'min:0|max:2000'"
            }
          ],
          class: {
            "ukid-text-area-external uk-textarea ukid-form-error ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
              "router_headers_input_field"
            ),
            "ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
              "router_headers_input_field"
            )
          },
          attrs: {
            rows: "5",
            "c-data-vv-as": "field",
            id: "router_headers_input_field",
            name: "router_headers_input_field",
            type: "textarea"
          },
          domProps: { value: _vm.router_headers },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.router_headers = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_body\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-body-1",
              "uk-toggle":
                "target: #ukid-plus-animation-router-body-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(24)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                router_body\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-router-body-2",
              "uk-toggle":
                "target: #ukid-plus-animation-router-body-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(25)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.router_body,
              expression: "router_body"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|min:5|max:1000",
              expression: "'required|min:5|max:1000'"
            }
          ],
          class: {
            "ukid-text-area-external ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-height-150-px": _vm.errors.has(
              "router_headers_input_field"
            ),
            "ukid-text-area-external uk-input ukid-focus ukid-width-100-percent ukid-height-150-px": !_vm.errors.has(
              "router_headers_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "router_headers_input_field",
            name: "router_headers_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 1000"
          },
          domProps: { value: _vm.router_body },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.router_body = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                use_stream_timestamp\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-use-stream-timestamp-1",
              "uk-toggle":
                "target: #ukid-plus-animation-use-stream-timestamp-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(26)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                use_stream_timestamp\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-use-stream-timestamp-2",
              "uk-toggle":
                "target: #ukid-plus-animation-use-stream-timestamp-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(27)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "use_stream_timestamp_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "use_stream_timestamp_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.use_stream_timestamp,
                    expression:
                      "camera.stream_settings_gpu.use_stream_timestamp"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "use_stream_timestamp_input_field_yes",
                  name: "use_stream_timestamp_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.use_stream_timestamp,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "use_stream_timestamp",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.camera.stream_settings_gpu.use_stream_timestamp,
                    expression:
                      "camera.stream_settings_gpu.use_stream_timestamp"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "use_stream_timestamp_input_field_no",
                  name: "use_stream_timestamp_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.use_stream_timestamp,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "use_stream_timestamp",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin uk-hidden" }, [
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.start_stream_timestamp,
              expression: "camera.stream_settings_gpu.start_stream_timestamp"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,10",
              expression: "'required|between:0,10'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "start_stream_timestamp_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "start_stream_timestamp_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "start_stream_timestamp_input_field",
            name: "start_stream_timestamp_input_field",
            type: "text",
            placeholder: "Mínimo 0, máximo 10"
          },
          domProps: {
            value: _vm.camera.stream_settings_gpu.start_stream_timestamp
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "start_stream_timestamp",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                imotion_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-imotion-threshold-1",
              "uk-toggle":
                "target: #ukid-plus-animation-imotion-threshold-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(28)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                imotion_threshold\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-imotion-threshold-2",
              "uk-toggle":
                "target: #ukid-plus-animation-imotion-threshold-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(29)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.imotion_threshold,
              expression: "camera.stream_settings_gpu.imotion_threshold"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|between:0,1|decimal:3",
              expression: "'required|between:0,1|decimal:3'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "imotion_threshold_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "imotion_threshold_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "imotion_threshold_input_field",
            name: "imotion_threshold_input_field",
            type: "text",
            placeholder: "Entre 0 e 1"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.imotion_threshold },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "imotion_threshold",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                rot\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-gpu-rot-1",
              "uk-toggle":
                "target: #ukid-plus-animation-gpu-rot-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(30)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                rot\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-gpu-rot-2",
              "uk-toggle":
                "target: #ukid-plus-animation-gpu-rot-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(31)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.rot,
              expression: "camera.stream_settings_gpu.rot"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "rot_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "rot_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "rot_input_field",
            name: "rot_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.rot },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "rot",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                roi\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-gpu-roi-1",
              "uk-toggle":
                "target: #ukid-plus-animation-gpu-roi-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(32)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                roi\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-gpu-roi-2",
              "uk-toggle":
                "target: #ukid-plus-animation-gpu-roi-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(33)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.roi,
              expression: "camera.stream_settings_gpu.roi"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "min:5|max:100",
              expression: "'min:5|max:100'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "roi_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "roi_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "roi_input_field",
            name: "roi_input_field",
            type: "text",
            placeholder: "Mínimo 5, máximo 100"
          },
          domProps: { value: _vm.camera.stream_settings_gpu.roi },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "roi",
                $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                realtime_post_every_interval\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pei-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pei-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(34)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v(
              "\n                realtime_post_every_interval\n            "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pei-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pei-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(35)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has(
                "realtime_post_every_interval_input_field"
              ),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "realtime_post_every_interval_input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.camera.stream_settings_gpu
                        .realtime_post_every_interval,
                    expression:
                      "camera.stream_settings_gpu.realtime_post_every_interval"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "realtime_post_every_interval_input_field_yes",
                  name: "realtime_post_every_interval_input_field",
                  checked: ""
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.realtime_post_every_interval,
                    "true"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "realtime_post_every_interval",
                      "true"
                    )
                  }
                }
              }),
              _vm._v(" Habilitar\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.camera.stream_settings_gpu
                        .realtime_post_every_interval,
                    expression:
                      "camera.stream_settings_gpu.realtime_post_every_interval"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "realtime_post_every_interval_input_field_no",
                  name: "realtime_post_every_interval_input_field"
                },
                domProps: {
                  checked: _vm._q(
                    _vm.camera.stream_settings_gpu.realtime_post_every_interval,
                    "false"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.$set(
                      _vm.camera.stream_settings_gpu,
                      "realtime_post_every_interval",
                      "false"
                    )
                  }
                }
              }),
              _vm._v(" Não Habilitar\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime_post_interval\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pi-1",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pi-1; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(36)
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
        },
        [
          _c("span", { staticClass: "ukid-icon-pink-color" }, [
            _vm._v("\n                realtime_post_interval\n            ")
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", {
            staticClass: "ukid-icon-yellow-color ukid-cursor-pointer",
            attrs: {
              href: "#ukid-plus-animation-realtime-pi-2",
              "uk-toggle":
                "target: #ukid-plus-animation-realtime-pi-2; animation: uk-animation-fade; style: display:block;",
              "uk-icon": "icon:comments;ratio:1.0"
            }
          }),
          _c("br"),
          _vm._v(" "),
          _vm._m(37)
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.camera.stream_settings_gpu.realtime_post_interval,
              expression: "camera.stream_settings_gpu.realtime_post_interval"
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|decimal:2|between:0,500",
              expression: "'required|decimal:2|between:0,500'"
            }
          ],
          class: {
            "ukid-form-error uk-input ukid-focus ukid-width-100-percent": _vm.errors.has(
              "realtime_post_interval_input_field"
            ),
            "uk-input ukid-focus ukid-width-100-percent": !_vm.errors.has(
              "realtime_post_interval_input_field"
            )
          },
          attrs: {
            "c-data-vv-as": "field",
            id: "realtime_post_interval_input_field",
            name: "realtime_post_interval_input_field",
            type: "text",
            placeholder: "Entre 0 e 500"
          },
          domProps: {
            value: _vm.camera.stream_settings_gpu.realtime_post_interval
          },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.camera.stream_settings_gpu,
                "realtime_post_interval",
                $event.target.value
              )
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-skip_count-format-1" }
      },
      [
        _vm._v(
          "\n                Pula alguns frames e mantém em cache para envio posterior"
        ),
        _c("br"),
        _vm._v(
          "\n                Ex: Uma câmera com 10 fps e skip_count = 5 vai pular 5 frames e processar 5"
        ),
        _c("br"),
        _vm._v(
          "\n                Os 5 frames que foram pulados serão enviados posteriormente"
        ),
        _c("br"),
        _vm._v(
          "\n                Isso diminui a quantidade de chamadas para backend\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-skip_count-format-2" }
      },
      [
        _vm._v(
          "\n                Pula alguns frames e mantém em cache para envio posterior"
        ),
        _c("br"),
        _vm._v(
          "\n                Ex: Uma câmera com 10 fps e skip_count = 5 vai pular 5 frames e processar 5"
        ),
        _c("br"),
        _vm._v(
          "\n                Os 5 frames que foram pulados serão enviados posteriormente"
        ),
        _c("br"),
        _vm._v(
          "\n                Isso diminui a quantidade de chamadas para backend\n            "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-format-1" }
      },
      [
        _vm._v("\n                Não preencha se não"),
        _c("br"),
        _vm._v("\n                tiver certeza do que está fazendo"),
        _c("br"),
        _vm._v("\n                Passe o formato FFMPEG (mxg, flv, etc.)"),
        _c("br"),
        _vm._v("\n                se não puder ser detectado automaticamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-ffmpeg-format-2" }
      },
      [
        _vm._v(
          "\n                Não preencha se não tiver certeza do que está fazendo"
        ),
        _c("br"),
        _vm._v(
          "\n                Passe o formato FFMPEG (mxg, flv, etc.) se não puder"
        ),
        _c("br"),
        _vm._v("\n                ser detectado automaticamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter_min_quality-1" }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-quality-2" }
      },
      [
        _vm._v(
          "\n                Valor limite mínimo para uma qualidade de imagem facial"
        ),
        _c("br"),
        _vm._v(
          "\n                Uma face é postada se tiver melhor qualidade"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente"
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: números racionais negativos para zero"
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = Faces de baixíssima qualidade"),
        _c("br"),
        _vm._v("\n                1 = Alta qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-face-size-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-min-face-size-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho mínimo de uma face em pixels"),
        _c("br"),
        _vm._v("\n                As faces subdimensionadas não são postadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-max-face-size-1" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho máximo de uma face em pixels"),
        _c("br"),
        _vm._v(
          "\n                As faces de grandes dimensões não são postadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-max-face-size-2" }
      },
      [
        _vm._v("\n                Valor padrão: 0 (filtro desativado)"),
        _c("br"),
        _vm._v("\n                Tamanho máximo de uma face em pixels"),
        _c("br"),
        _vm._v(
          "\n                As faces de grandes dimensões não são postadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                normalized_only - Valor padrão: Não habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n            Habilite a postagem apenas de imagens"),
        _c("br"),
        _vm._v("\n            de face normalizadas sem frames completos"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                normalized_only - Valor padrão: Não habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n            Habilite a postagem apenas de imagens de face normalizadas sem frames completos\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                jpeg_quality - Valor padrão: 95%\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n            Qualidade da compactação JPEG"),
        _c("br"),
        _vm._v("\n            do frame original, em porcentagem"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                jpeg_quality - Valor padrão: 95%\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n            Qualidade da compactação JPEG do frame original, em porcentagem"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                overall_only - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n            Ativa o modo off-line para"),
        _c("br"),
        _vm._v("\n            a melhor pesquisa de face"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12"
      },
      [
        _c("span", { staticClass: "ukid-icon-pink-color" }, [
          _vm._v(
            "\n                overall_only - Valor padrão: Habilitado\n            "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n            Ativa o modo off-line para a melhor pesquisa de face"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-ffmpeg-params-1" }
      },
      [
        _vm._v("\n                Lista de opções de ffmpeg de um fluxo "),
        _c("br"),
        _vm._v("\n                de vídeo com seus valorescomo uma"),
        _c("br"),
        _vm._v(
          '\n                matriz key=value: ["rtsp_transpotr = tcp", ..,'
        ),
        _c("br"),
        _vm._v('\n                "ss = 00: 20: 00"]'),
        _c("br"),
        _vm._v("\n                Confira o site FFmpeg "),
        _c(
          "a",
          {
            staticClass: "ukid-icon-pink-color",
            attrs: { href: "https://trac.ffmpeg.org", target: "_blank" }
          },
          [_vm._v("web site")]
        ),
        _c("br"),
        _vm._v("\n                para a lista completa de opções."),
        _c("br"),
        _vm._v("\n                Valor padrão: opções não especificadas"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-filter-ffmpeg-params-2" }
      },
      [
        _vm._v(
          "\n                Lista de opções de ffmpeg de um fluxo de vídeo com seus valores como uma matriz"
        ),
        _c("br"),
        _vm._v(
          '\n                key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]'
        ),
        _c("br"),
        _vm._v("\n                Confira o site FFmpeg "),
        _c(
          "a",
          {
            staticClass: "ukid-icon-pink-color",
            attrs: { href: "https://trac.ffmpeg.org", target: "_blank" }
          },
          [_vm._v("web site")]
        ),
        _c("br"),
        _vm._v(
          "\n                para a lista completa de opções. Valor padrão: opções não especificadas"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-timeout-ms-1" }
      },
      [
        _vm._v("\n                router_timeout_ms - Valor padrão: 15000"),
        _c("br"),
        _vm._v("\n                Tempo limite para uma resposta face router"),
        _c("br"),
        _vm._v(
          "\n                a uma solicitação da API video-worker, em milissegundos"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o tempo limite expirar, o sistema registrará um erro"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-timeout-ms-2" }
      },
      [
        _vm._v("\n                router_timeout_ms - Valor padrão: 15000"),
        _c("br"),
        _vm._v("\n                Tempo limite para uma resposta face router"),
        _c("br"),
        _vm._v(
          "\n                a uma solicitação da API video-worker, em milissegundos"
        ),
        _c("br"),
        _vm._v(
          "\n                Se o tempo limite expirar, o sistema registrará um erro"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-verify-ssl-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v(
          "\n                Não habilitado - Um cert. autoassinado também é aceito\n                Ativa a verificação do certificado https quando"
        ),
        _c("br"),
        _vm._v(
          "\n                video-worker e facerouter interagem por https"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-verify-ssl-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v(
          "\n                Não habilitado - Um cert. autoassinado também é aceito\n                Ativa a verificação do certificado https quando"
        ),
        _c("br"),
        _vm._v(
          "\n                video-worker e facerouter interagem por https"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-headers-1" }
      },
      [
        _vm._v("\n                Valor padrão: cabeçalhos não especificados"),
        _c("br"),
        _vm._v("\n                Campos de cabeçalho adicionais em uma"),
        _c("br"),
        _vm._v(
          "\n                solicitação POST ao postar uma face para WEB HUC"
        ),
        _c("br"),
        _vm._v("\n                Ex: Authorization=Token xxx"),
        _c("br"),
        _vm._v("\n                Accept=application/json"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-headers-2" }
      },
      [
        _vm._v("\n                Valor padrão: cabeçalhos não especificados"),
        _c("br"),
        _vm._v("\n                Campos de cabeçalho adicionais em uma"),
        _c("br"),
        _vm._v(
          "\n                solicitação POST ao postar uma face para WEB HUC"
        ),
        _c("br"),
        _vm._v("\n                Ex: Authorization=Token xxx"),
        _c("br"),
        _vm._v("\n                Accept=application/json"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-body-1" }
      },
      [
        _vm._v("\n                Campos adicionais no body da solicitação"),
        _c("br"),
        _vm._v(
          "\n                ao postar uma face. Ex: uuid=xxx,some_name=yyy"
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-11" }, [
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("image_type=")
          ]),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("BASE64")]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("external_url=https://xxx")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("external_token=token_xxx")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("mf_selector=")
          ]),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("DEFAULT")]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [
            _vm._v("DEFAULT_CFTV")
          ]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("ALL")]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("NANO")]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("detector=")
          ]),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("DEFAULT")]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [
            _vm._v("DEFAULT_CFTV")
          ]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [
            _vm._v("FACES_AND_BODIES")
          ]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("BODIES")]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("gallery=")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("latitude=")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("longitude=")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-router-body-2" }
      },
      [
        _vm._v("\n                Campos adicionais no body da solicitação"),
        _c("br"),
        _vm._v(
          "\n                ao postar uma face. Ex: uuid=xxx,some_name=yyy"
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-font-size-11" }, [
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("image_type=")
          ]),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("BASE64")]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("external_url=https://xxx")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("external_token=token_xxx")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("mf_selector=")
          ]),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("DEFAULT")]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [
            _vm._v("DEFAULT_CFTV")
          ]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("ALL")]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("NANO")]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("detector=")
          ]),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("DEFAULT")]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [
            _vm._v("DEFAULT_CFTV")
          ]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [
            _vm._v("FACES_AND_BODIES")
          ]),
          _vm._v(" or "),
          _c("span", { staticClass: "uk-text-uppercase" }, [_vm._v("BODIES")]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("latitude=")
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "uk-text-lowercase" }, [
            _vm._v("longitude=")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-use-stream-timestamp-1" }
      },
      [
        _vm._v("\n                Se habilitado, recupera e posta"),
        _c("br"),
        _vm._v("\n                data e hora de um stream de vídeo"),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, envia a data e a hora reais"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-use-stream-timestamp-2" }
      },
      [
        _vm._v("\n                Se habilitado, recupera e posta"),
        _c("br"),
        _vm._v("\n                data e hora de um stream de vídeo"),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, envia a data e a hora reais"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-imotion-threshold-1" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v("\n                Intensidade mínima de movimento"),
        _c("br"),
        _vm._v("\n                a ser detectada pelo detector de movimento"),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente."
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: zero e números racionais positivos."
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = detector desativado"),
        _c("br"),
        _vm._v("\n                0.002 = valor padrão"),
        _c("br"),
        _vm._v("\n                0.05 = intensidade mínima muito alta"),
        _c("br"),
        _vm._v("\n                para detectar movimento"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-imotion-threshold-2" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v(
          "\n                Intensidade mínima de movimento a ser detectada pelo detector de movimento"
        ),
        _c("br"),
        _vm._v(
          "\n                O valor limite deve ser ajustado empiricamente."
        ),
        _c("br"),
        _vm._v(
          "\n                Unidades empíricas: zero e números racionais positivos."
        ),
        _c("br"),
        _vm._v("\n                Marcos:"),
        _c("br"),
        _vm._v("\n                0 = detector desativado"),
        _c("br"),
        _vm._v("\n                0.002 = valor padrão"),
        _c("br"),
        _vm._v(
          "\n                0.05 = intensidade mínima muito alta para detectar movimento"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-gpu-rot-1" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v("\n                Permite detectar e rastrear faces apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de um retângulo de recorte WxH + X + Y"
        ),
        _c("br"),
        _vm._v("\n                Você pode usar esta opção para reduzir"),
        _c("br"),
        _vm._v("\n                a carga do video worker"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-gpu-rot-2" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v(
          "\n                Permite detectar e rastrear faces apenas dentro de um"
        ),
        _c("br"),
        _vm._v("\n                retângulo de recorte WxH + X + Y"),
        _c("br"),
        _vm._v(
          "\n                Você pode usar esta opção para reduzir a carga do video worker"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-gpu-roi-1" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v("\n                Ativar post de faces detectadas apenas"),
        _c("br"),
        _vm._v(
          "\n                dentro de uma região de interesse WxH + X + Y"
        ),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-gpu-roi-2" }
      },
      [
        _vm._v("\n                Valor padrão: Região não especificada"),
        _c("br"),
        _vm._v(
          "\n                Ativar post de faces detectadas apenas dentro de uma"
        ),
        _c("br"),
        _vm._v("\n                região de interesse WxH + X + Y"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pei-1" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em cada"),
        _c("br"),
        _vm._v("\n                período de tempo realtime_post_interval"),
        _c("br"),
        _vm._v("\n                Se não habilitado, procure o melhor"),
        _c("br"),
        _vm._v("\n                momento dinamicamente"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pei-2" }
      },
      [
        _vm._v("\n                Valor padrão: Não habilitado"),
        _c("br"),
        _vm._v("\n                Modo em tempo real"),
        _c("br"),
        _vm._v("\n                Poste as melhores capturas obtidas em cada"),
        _c("br"),
        _vm._v("\n                período de tempo realtime_post_interval"),
        _c("br"),
        _vm._v(
          "\n                Se não habilitado, procure o melhor momento dinamicamente"
        ),
        _c("br"),
        _vm._v("\n                e post em ordem crescente de qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pi-1" }
      },
      [
        _vm._v("\n                Valor padrão: 1 - Em segundos"),
        _c("br"),
        _vm._v(
          "\n                Somente para o modo em tempo real habilitado"
        ),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Habilitado,"
        ),
        _c("br"),
        _vm._v("\n                define o período em milissegundos dentro"),
        _c("br"),
        _vm._v("\n                do qual o rastreador de face pega"),
        _c("br"),
        _vm._v("\n                o melhor momento e o posta no face router"),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Não habilitado,"
        ),
        _c("br"),
        _vm._v("\n                define o período mínimo de tempo entre"),
        _c("br"),
        _vm._v("\n                2 posts da mesma face com maior qualidade"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "ukid-font-size-9",
        attrs: { hidden: "", id: "ukid-plus-animation-realtime-pi-2" }
      },
      [
        _vm._v("\n                Valor padrão: 1 - Em segundos"),
        _c("br"),
        _vm._v(
          "\n                Somente para o modo em tempo real habilitado"
        ),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Habilitado, define o período em milissegundos"
        ),
        _c("br"),
        _vm._v("\n                dentro do qual o rastreador de face pega"),
        _c("br"),
        _vm._v("\n                o melhor momento e o posta no face router"),
        _c("br"),
        _vm._v(
          "\n                Se realtime_post_every_interval = Não habilitado, define o período mínimo de tempo"
        ),
        _c("br"),
        _vm._v(
          "\n                entre 2 posts da mesma face com maior qualidade"
        ),
        _c("br")
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-11af8598", { render: render, staticRenderFns: staticRenderFns })
  }
}