<script type="text/javascript">

    import UIkit from 'uikit'

    import CookieLaw from 'vue-cookie-law'

    import App from '../../app.json'

    import { vm } from '../../js/main.js'

    import { setTheme, configEffect } from '../../js/unike-ui'

    export default {

        components: { 
        
            CookieLaw

        },

        data() {

            return {
                App: App,
                setTheme: setTheme,
                personCount: 0,
                galleryCount: 0,
                beaconCount: 0,
                userCount: 0,
                groupCount: 0,
                cameraCount: 0,
                detectedFacesCount: 0,
                detectedBodiesCount: 0,
                samplesList: null,
                vm: vm,                
                auth: ''
            }

        },

        created() {

            let _ = this

            _.list()

            _.auth = vm.user.auth

        },

        mounted() {
            
            let _ = this

            configEffect(localStorage.getItem(window.location.hostname + '_' + vm.user.sub + '_theme'))     

        },

        methods: {

            list() {

                var this_ = this

                this.$http.get(

                    App.host.concat(App.homeEndpoint)

                ).then(response => {

                    this_.personCount = response.body.personCount
                    this_.galleryCount = response.body.galleryCount
                    this_.beaconCount = response.body.beaconCount
                    this_.userCount = response.body.userCount
                    this_.cameraCount = response.body.cameraCount
                    this_.detectedFacesCount = response.body.detectedFacesCount
                    this_.detectedBodiesCount = response.body.detectedBodiesCount                    
                    this_.samplesList = response.body.samplesList

                }).catch(function(err) {
                    
                    if (err.body.status == 401) {
                        
                        UIkit.modal.alert('<span class="ukid-font-size-17">A sessão expirou ou talvez esse usuário não tenha autorização para usar interface web</span>')

                        window.location.href = '/#/login'

                    }

                    console.info(err)

                }) 

            },

            loc(url) {

                window.open(url, '_blank')

            }

        }

    }

</script>

<template id="home-template">
    
    <div class="uk-flex uk-flex-center ukid-background-white-color ukid-padding-top-40 ukid-min-height">

        <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
<!--
            <div class="ukid-width-290-px" v-if="auth.includes('admin') || auth.includes('user') || auth.includes('signer')">
                <div class="uk-card uk-card-default uk-card-body">
                    <span class="ukid-icon-dark-green-color" uk-icon="icon:lifesaver;ratio:1.8"></span>
                    <br /><br />
                    Nossos Samples e Docs<br /><br />
                    <div v-for="sample in samplesList" :key="sample.name" class="ukid-font-size-11 uk-text-left">
                        <span uk-icon="icon:download;ratio:1" class="ukid-icon-blue-water-color"></span>
                        <span @click="loc(sample.url)" class="ukid-set-theme-tooltip ukid-cursor-pointer ukid-icon-blue-water-color ukid-font-size-11 uk-text-center">
                            <span v-if="sample.name.includes('doc terminal termografico')">
                                <b class="ukid-icon-purple-color ukid-font-size-11">Docto terminal de aferição de temperatura</b><br /><br />
                                <img src="content/images/terminal_icon.png" class="ukid-view-logo-un uk-margin-small-right" width="60" />
                            </span>                        
                             {{sample.name}}
                        </span>
                        <br />
                    </div>
                </div>
            </div>

            <div class="ukid-width-250-px ukid-height-300-px">
                <div class="uk-card uk-card-default uk-card-body">
                    <span class="ukid-icon-blue-water-color" uk-icon="icon:image;ratio:1.7"></span>
                    <br /><br />
                    Troque seu tema<br />
                    <p class="ukid-font-size-11 uk-text-left">
                        Se o tema claro te incomodar utilize o modo escuro para uma melhor experiência
                    </p>
                    <div class="ukid-font-size-12 uk-text-left">
                        <span uk-icon="icon:paint-bucket;ratio:0.8" class="ukid-icon-blue-water-color"></span>
                        <span class="ukid-set-theme-tooltip ukid-cursor-pointer ukid-icon-blue-water-color ukid-font-size-13" @click="setTheme('content/css/template.css')">
                            <span>
                                <img src="content/images/default-theme-image.png" class="ukid-view-logo-un uk-margin-small-right" width="300" />
                            </span>                        
                            Tema Padrão
                        </span>
                        <br />
                        <span uk-icon="icon:paint-bucket;ratio:0.8" class="ukid-icon-blue-water-color"></span>
                        <span class="ukid-set-theme-tooltip ukid-cursor-pointer ukid-icon-blue-water-color ukid-font-size-13" @click="setTheme('content/css/template_dark.css')">
                            <span>
                                <img src="content/images/dark-theme-image.png" class="ukid-view-logo-un uk-margin-small-right" width="300" />
                            </span>                                                
                            Tema Escuro
                        </span>
                        <br />
                        <span uk-icon="icon:paint-bucket;ratio:0.8" class="ukid-icon-blue-water-color"></span>
                        <span class="ukid-set-theme-tooltip ukid-cursor-pointer ukid-icon-blue-water-color ukid-font-size-13" @click="setTheme('content/css/template_seven_mystic.css')">
                            <span>
                                <img src="content/images/seven-theme-image.png" class="ukid-view-logo-un uk-margin-small-right" width="300" />
                            </span>                        
                            Tema Seven Mystic
                        </span>
                        <br />
                        <span uk-icon="icon:paint-bucket;ratio:0.8" class="ukid-icon-blue-water-color"></span>
                        <span class="ukid-set-theme-tooltip ukid-cursor-pointer ukid-icon-blue-water-color ukid-font-size-13" @click="setTheme('content/css/template_infinity_blue.css')">
                            <span>
                                <img src="content/images/infinity-theme-image.png" class="ukid-view-logo-un uk-margin-small-right" width="300" />
                            </span>
                            Tema Infinity Blue
                        </span>
                        <br />
                        <span uk-icon="icon:paint-bucket;ratio:0.8" class="ukid-icon-blue-water-color"></span>
                        <span class="ukid-set-theme-tooltip ukid-cursor-pointer ukid-icon-blue-water-color ukid-font-size-13" @click="setTheme('content/css/template_dinamic_gradient.css')">
                            <span class="ukid-primary-icon-dark-color ukid-width-200-px ukid-height-40-px">
                                Thema dinamicro sem pré-visualização
                            </span>
                            Tema Dynamic Gradient
                        </span>
                    </div>
                </div>
            </div>
-->
            <router-link to="/group" v-if="auth.includes('admin') || auth.includes('user')" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-text-alert-color" uk-icon="icon:git-fork;ratio:1.9"></span>
                        <br /><br />
                        <span>Gestão de Grupos de Acesso</span>
                        <div class="uk-text-uppercase ukid-font-size-10" v-if="auth.includes('admin')">
                            Total de Grupos: {{groupCount}}
                        </div>
                        <p class="ukid-font-size-11 uk-text-left">
                            <a href="#" class="ukid-icon-purple-color">Criação, atualização e exclusão</a><br />
                            Crie grupos de acesso e controle nesta sessão, após a criação vá até o módulo de 
                            <b class="ukid-text-alert-color">Base de Passoas</b> / <b class="ukid-text-alert-color">Gestão de Equipamentos</b>
                            e faça a segmentação da maneira que achar mais conveniente
                        </p>
                    </div>
                </div>
            </router-link>

            <router-link to="/user/register" v-if="auth.includes('admin') || auth.includes('user')">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-purple-color" uk-icon="icon:happy;ratio:1.9"></span>
                        <br /><br />
                        <span v-if="auth.includes('admin')">Gestão de Usuários</span>
                        <span v-if="auth.includes('user')">Gerir Meus Usuários</span><br />
                        <div class="uk-text-uppercase ukid-font-size-10" v-if="auth.includes('admin')">
                            Total de Usuários: {{userCount}}
                        </div>
                        <p class="ukid-font-size-11 uk-text-left" v-if="auth.includes('admin')">
                            <a href="#" class="ukid-icon-purple-color">Criação, atualização e exclusão</a><br />
                            Módulo responsável pelo controle e criação de acesso pelos SDK's acessado pelos clientes
                        </p>
                        <p class="ukid-font-size-11 uk-text-left" v-if="auth.includes('user')">
                            <a href="#" class="ukid-icon-purple-color">Atualização de parâmetros</a><br />
                            Permite atualizar os parâmetros do usuário, incluindo threshold de detecção facial e temperatura dos terminais
                        </p>                         
                    </div>
                </div>
            </router-link>

            <router-link :to="App.ffVersion == '4.5' ? '/matcher/gallery/v3' : '/matcher/gallery'" v-if="auth.includes('admin')">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-yellow-color" uk-icon="icon:folder;ratio:2.0"></span>
                        <br /><br />
                        Gestão de Galerias<br />
                        <div class="uk-text-uppercase ukid-font-size-10">Galerias Disponíveis: {{galleryCount}}</div>
                        <p class="ukid-font-size-11 uk-text-left">
                            <a href="#" class="ukid-icon-yellow-color">Criação, atualização e exclusão</a><br />
                            Criação, atualização e exclusão<br />
                            As galerias são armazenadas diretamente em nosso matcher<br />
                            São utilizadas para segmentar biometrias
                        </p>
                    </div>
                </div>
            </router-link>

            <router-link :to="App.ffVersion == '4.5' ? '/matcher/camera/v3' : '/matcher/camera'" v-if="auth.includes('admin') || auth.includes('user')">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-red-color" uk-icon="icon:video-camera;ratio:2.4"></span>
                        <span class="ukid-icon-red-color" uk-icon="icon:more-vertical;ratio:1.3"></span>
                        <span class="ukid-icon-red-color" uk-icon="icon:tablet;ratio:1.5"></span>                        
                        <br /><br />
                        Gestão CFTV / Contagem de Pessoas / Terminais<br />
                        <div class="uk-text-uppercase ukid-font-size-10">Cameras Disponíveis: {{cameraCount}}</div>
                        <p class="ukid-font-size-11 uk-text-left">
                            <a href="#" class="ukid-icon-red-color">Criação, atualização e exclusão</a><br />
                            O processo de criação das câmeras / terminais de reconhecimento é necessário para o correto funcionamento das detecções faciais / aferições de temperatura
                        </p>
                    </div>
                </div>
            </router-link>

           <router-link to="/beacon" v-if="auth.includes('admin') || auth.includes('user')" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-pink-color" uk-icon="icon:location;ratio:1.6"></span>
                        <br /><br />
                        Gerenciamento de Beacons<br />
                        <div class="uk-text-uppercase ukid-font-size-10">total: {{beaconCount}}</div>
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-pink-color">Permite o cadastramento de sensores de proximidade</a><br />
                            Para que a koruja reconheça um sensor é necessário que o mesmo esteja cadastrado em nossa base de dados
                        </p> 
                    </div>
                </div>
            </router-link>  

            <router-link :to="App.ffVersion == '4.5' ? '/person/v3' : '/person'" v-if="auth.includes('admin') || auth.includes('user') || auth.includes('signer')">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-green-color" uk-icon="icon:users;ratio:2.1"></span>
                        <br /><br />
                        Nossa Base de Pessoas<br />
                        <div class="uk-text-uppercase ukid-font-size-10">total: {{personCount}}</div>
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-green-color">Mostra pessoas cadastradas em nossa base de dados</a><br />
                            Permite realizar pesquisas e outras operações
                        </p> 
                    </div>
                </div>
            </router-link>

	    <router-link to="matcher/personv4list" v-if="auth.includes('admin') || auth.includes('user') || auth.includes('signer')">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-green-color" uk-icon="icon:users;ratio:2.1"></span>
                        <br /><br />
                        Lista de Pessoas<br />
                    </div>
                </div>
            </router-link>            

	    <router-link :to="App.ffVersion == '4.5' ? '/invite/form/v3' : '/invite/form'" v-if="auth.includes('admin') || auth.includes('user') || auth.includes('signer')" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px" v-if="auth.includes('admin') || auth.includes('user') || auth.includes('signer')">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-yellow-color" uk-icon="icon:social;ratio:1.9"></span>
                        <br /><br />
                        Unike Invite<br />
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-yellow-color">INVITE</a><br />
                            Já imaginou poder convidar alguém a fazer um cadastramento facial em sua rede, por email e whatsapp?
                        </p> 
                    </div>
                </div>
            </router-link>
<!--
            <div class="ukid-width-250-px ukid-height-300-px">
                <div id="modal-bi" uk-modal>
                    <div class="uk-modal-dialog">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-body">
                            <h2 class="uk-modal-title uk-text-center ukid-font-size-18">
                                Como você deseja prosseguir?
                            </h2>
                            <div class="uk-text-center">
                                <router-link to="/bi/control" v-if="auth.includes('admin') || auth.includes('user') || auth.includes('signer')">
                                    <button class="uk-button uk-button-default uk-modal-close ukid-width-250-px" type="button">
                                        Dashboard Unike Control
                                    </button>
                                </router-link><br />
                            </div>
                        </div>
                    </div>
                </div>
                <a class="ukid-detect ukid-icon-blue-color" href="#modal-bi" uk-toggle>
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-gray-color" uk-icon="icon:gitter;ratio:1.9"></span>
                        <br /><br />
                        Unike Intelligence<br />
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-gray-color">B.I</a><br />
                            Módulo para construção de informações inteligentes
                        </p> 
                    </div>
                </a>
            </div>
-->
            <div class="ukid-width-250-px ukid-height-300-px">
                <div id="modal-detect-home" uk-modal>
                    <div class="uk-modal-dialog">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-body">
                            <h2 class="uk-modal-title uk-text-center ukid-font-size-18">
                                Como você deseja prosseguir?
                            </h2>
                            <div class="uk-text-center">
                                <router-link to="/matcher/proximity/v3">
                                    <button class="uk-button uk-button-default uk-modal-close ukid-width-250-px" type="button">
                                        <img src="content/images/accompaniment-face.png" width="26" /> Acompanhar Faces
                                    </button>
                                </router-link>
                            </div>
                            <div class="uk-text-center ukid-margin-top-5">
                                <router-link to="/matcher/verify/v3">
                                    <button class="uk-button uk-button-default uk-modal-close ukid-width-250-px" type="button">
                                        <span class="ukid-white-color" uk-icon="icon:search;ratio:1.2"></span> Verificações Faciais
                                    </button>
                                </router-link>
                            </div>
                            <div class="uk-text-center">
                                <router-link to="/matcher/bodies/v3" v-if="auth.includes('admin') || auth.includes('user')">
                                    <button class="uk-button uk-button-default uk-modal-close ukid-width-250-px ukid-margin-top-5" type="button">
                                        <img src="content/images/count-people-icon.png" width="24" /> Contagem de Pessoas
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="ukid-detect ukid-icon-blue-color" href="#modal-detect-home" uk-toggle>
                    <div class="uk-card uk-card-default uk-card-body">                    
                        <span class="ukid-icon-blue-color" uk-icon="icon:search;ratio:2.0"></span>
                        <br /><br />
                        Pessoas nas Proximidades<br />
                        <div class="uk-text-uppercase ukid-font-size-10">Faces Detectadas: {{detectedFacesCount}}</div>
                        <div class="uk-text-uppercase ukid-font-size-10">Contagem de Pessoas: {{detectedBodiesCount}}</div>
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-blue-color">Pessoas em tempo real</a><br />
                            Módulo para detecção de pessoas nas proximidades das câmeras RTSP
                        </p>                    
                    </div>
                </a>
            </div>

            <router-link to="/education/demo" v-if="auth.includes('admin') || vm.user.sub === 'unike_demo'" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-tuscan-color" uk-icon="icon:desktop;ratio:2.0"></span>
                        <br /><br />
                        unike.EDU<br />        
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-tuscan-color">Validação em background</a><br />
                            Módulo de demonstração para instituições de ensino a distância
                        </p> 
                    </div>
                </div>
            </router-link>

            <router-link to="/matcher/tp/v3" v-if="vm.user.sub === 'teleperformance'" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">                        
                        <img src="content/images/teleperformance.png" width="50" />
                        <br /><br />
                        POC Teleperformance<br />
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-tuscan-color">Validação em background</a><br />
                            Módulo de demonstração de capturas Teleperformance
                        </p> 
                    </div>
                </div>
            </router-link>

            <router-link to="/matcher/proximity/v3" v-if="vm.user.sub === 'beachpark'" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <img src="content/images/beachpark.png" width="45" />
                        <br /><br />
                        POC BeachPark<br />
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-tuscan-color">Validação em background</a><br />
                            Módulo de demonstração de capturas Beachpark
                        </p> 
                    </div>
                </div>
            </router-link>            

            <router-link to="/matcher/tp/v3" v-if="vm.user.sub !== 'teleperformance' && auth.includes('admin') || auth.includes('user')" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-tuscan-color" uk-icon="icon:home;ratio:1.9"></span>
                        <br /><br />
                        unike.WORK<br />        
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-tuscan-color">Validação em background</a><br />
                            Módulo de demonstração home office de capturas faciais em background
                        </p> 
                    </div>
                </div>
            </router-link>

            <router-link to="/call/demo" v-if="auth.includes('admin')" class="uk-hidden">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-tuscan-color" uk-icon="icon:video-camera;ratio:2.5"></span>
                        <br /><br />
                        Módulo de Demo Conf. Call<br />        
                        <p class="ukid-font-size-11 uk-text-left">
                            <a class="ukid-icon-tuscan-color">Validação em background</a><br />
                            Módulo de demonstração de captura de face em conference call
                        </p> 
                    </div>
                </div>
            </router-link>            

            <router-link to="/server/home" v-if="auth.includes('admin') || auth.includes('user')">
                <div class="ukid-width-250-px ukid-height-300-px">
                    <div class="uk-card uk-card-default uk-card-body">
                        <span class="ukid-icon-yellow-color" uk-icon="icon:server;ratio:1.8"></span>
                        <br /><br />
                        Server em Tempo Real<br />                        
                        <p class="ukid-font-size-11 uk-text-left">
                            Módulo de monitoramento do servidor<br />
                            <a class="ukid-icon-yellow-color">CPU, Memória, Discos e Rede</a>
                        </p>
                    </div>
                </div>
            </router-link>
            
        </div>

        <div id="modal-accept-cookies" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body">
                    <h2 class="uk-modal-title uk-text-center ukid-font-size-18">
                        Aceite de armazenamento local e cookies
                    </h2>
                    <div class="uk-text-center">
                        Texto
                    </div>
                </div>
            </div>
        </div>

        <cookie-law theme="royal" buttonText="Aceitar">           
            <div slot="message">
                Ao clicar em "Aceitar", você automaticamente concorda com nossa política de privacidade,<br />
                armazenamento local e todos os cookies no seu dispositivo para melhorar a navegação no site,<br />
                analisar a utilização do site, ajudar nas nossas iniciativas de marketing e melhorias em nossos algoritimos.<br />
                <a class="ukid-detect" target="_blank" href="https://docs.unike.tech/uploads/docs/politica_de_privacidade_unike.pdf">Ler nossa política de privacidade</a>
            </div>
        </cookie-law>

    </div>
    
</template>
