var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uk-child-width-expand@s ukid-background-white-color",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { staticClass: "uk-section ukid-background-white-color" }, [
        _c("div", { staticClass: "uk-container" }, [
          _c(
            "div",
            {
              staticClass: "ukid-margin-bottom-30 ukid-font-size-18",
              attrs: { "uk-scrollspy": "cls:uk-animation-fade" }
            },
            [
              _vm._v("\n                  STATUS ATUAL DO SERVIDOR:"),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n                  PROCESSOR INFO: " +
                  _vm._s(_vm.processorInfo)
              ),
              _c("br"),
              _vm._v(
                "\n                  ETHERNET INFO: " + _vm._s(_vm.ethernetInfo)
              ),
              _c("br")
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-grid-match uk-child-width-1-3@m",
              attrs: { "uk-grid": "" }
            },
            [
              _c("div", { staticClass: "ukid-width-25-percent" }, [
                _c(
                  "div",
                  { attrs: { align: "center", id: "teste" } },
                  [
                    _c("GChart", {
                      key: _vm.rerender,
                      attrs: {
                        type: "Gauge",
                        data: _vm.gaugeCpuData,
                        options: _vm.gaugeOptions,
                        settings: { packages: ["gauge"] }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ukid-width-25-percent" }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c("GChart", {
                      key: _vm.rerender,
                      attrs: {
                        type: "Gauge",
                        data: _vm.gaugeMemoryData,
                        options: _vm.gaugeOptions,
                        settings: { packages: ["gauge"] }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ukid-width-25-percent" }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c("GChart", {
                      key: _vm.rerender,
                      attrs: {
                        type: "Gauge",
                        data: _vm.gaugeDiskSdaData,
                        options: _vm.gaugeOptions,
                        settings: { packages: ["gauge"] }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ukid-width-25-percent" }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c("GChart", {
                      key: _vm.rerender,
                      attrs: {
                        type: "Gauge",
                        data: _vm.gaugeDiskMntData,
                        options: _vm.gaugeOptions,
                        settings: { packages: ["gauge"] }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ukid-width-25-percent" }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c("GChart", {
                      key: _vm.rerender,
                      attrs: {
                        type: "Gauge",
                        data: _vm.gaugeGpuData,
                        options: _vm.gaugeOptions,
                        settings: { packages: ["gauge"] }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ukid-width-25-percent" }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c("GChart", {
                      key: _vm.rerender,
                      attrs: {
                        type: "Gauge",
                        data: _vm.gaugeVideoMemoryData,
                        options: _vm.gaugeOptions,
                        settings: { packages: ["gauge"] }
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-abe28a04", { render: render, staticRenderFns: staticRenderFns })
  }
}