var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uk-child-width-expand@s ukid-background-white-color",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonCancel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.del(_vm.user)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonDelete))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ukid-width-100-percent ukid-padding-top-35 ukid-v-scroll ukid-filter ukid-filter-color",
          attrs: { id: "ukid-filter" }
        },
        [
          _c(
            "dl",
            {
              staticClass:
                "uk-description-list uk-description-list-divider ukid-magin-left-6 uk-text-center"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-width-expand uk-hidden@m uk-hidden@l uk-hidden@xl"
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ukid-padding ukid-padding-10px ukid-font-size-15"
                    },
                    [
                      _vm._v(
                        "\n                        Criação, atualização e exclusão de usuários\n                        "
                      ),
                      _c("br"),
                      _vm._v(
                        "Total: " +
                          _vm._s(_vm.pagination.databaseCount) +
                          "\n                    "
                      )
                    ]
                  )
                ]
              ),
              _vm._v("\n\n\t\t" + _vm._s(_vm.role) + "\n\n                "),
              _c("br", { staticClass: "uk-hidden@m uk-hidden@l uk-hidden@xl" }),
              _vm._v(" "),
              _c("dt", [
                _vm._v("\n                    Pesquisar\n                ")
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParam,
                      expression: "searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-width-80-percent",
                  attrs: {
                    id: "search-param-input",
                    type: "text",
                    placeholder: "Pesquise qualquer termo"
                  },
                  domProps: { value: _vm.searchParam },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchParam = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ukid-search-button ukid-primary-icon-color ukid-cursor-pointer ukid-magin-left-6",
                  attrs: { "uk-icon": "icon:search;ratio:1.4" },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "uk-hidden ukid-search-spinner ukid-magin-left-6",
                  attrs: { "uk-spinner": "ratio:0.92" }
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "uk-button uk-hidden@m uk-hidden@l uk-hidden@xl uk-button-link uk-align-center ukid-margin-bottom-0 ukid-font-size-13",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.clear(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    Iniciar Novo Registro\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.registers, function(user) {
                return _c("dt", { key: user.username }, [
                  _c("div", { staticClass: "uk-label ukid-width-70-percent" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.truncate(user.name, 20)) +
                        " - " +
                        _vm._s(user.username) +
                        "\n                        "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    user.parentList.length
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "ukid-text-alert-color ukid-font-size-9"
                          },
                          [
                            _vm._v(
                              "\n                            Proprietário - " +
                                _vm._s(
                                  user.parentList.filter(function(item) {
                                    return "DIRECT" === item.type
                                  })[0].username
                                ) +
                                "\n                        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  !_vm.Commons.isObjectNullOrEmpty(user) &&
                  (_vm.auth.includes("admin") || _vm.auth.includes("user"))
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:file-edit;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.fill(Object.assign({}, user))
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.Commons.isObjectNullOrEmpty(user) &&
                  _vm.auth.includes("user") &&
                  user.username !== _vm.vm.user.username
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.modal(Object.assign({}, user))
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.Commons.isObjectNullOrEmpty(user) &&
                  _vm.auth.includes("user") &&
                  user.username === _vm.vm.user.username
                    ? _c("span", {
                        staticClass:
                          "ukid-disable-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: {
                          "uk-icon": "icon:trash;ratio:0.9",
                          title:
                            "Ação desabilitada<br />A exclusão desse usuário, removerá por completo seu acesso e todos os outros logins atrelados a ele",
                          "uk-tooltip": ""
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.Commons.isObjectNullOrEmpty(user) &&
                  _vm.auth.includes("admin")
                    ? _c("span", {
                        staticClass:
                          "ukid-primary-icon-color ukid-magin-left-6 ukid-cursor-pointer",
                        attrs: { "uk-icon": "icon:trash;ratio:0.9" },
                        on: {
                          click: function($event) {
                            _vm.modal(Object.assign({}, user))
                          }
                        }
                      })
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.registers.length,
                  expression: "!registers.length"
                }
              ],
              staticClass:
                "uk-alert-primary uk-flex uk-flex-center ukid-magin-left-20 ukid-magin-right-30 ukid-margin-top-30",
              attrs: { "uk-alert": "" }
            },
            [
              _c("span", {
                staticClass:
                  "uk-form-icon ukid-search-spinner ukid-magin-left-10 ukid-magin-right-10",
                attrs: { "uk-icon": "icon:ban" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ukid-magin-left-20" }, [
                _vm._v(
                  "\n                    Nenhum registro encontrado\n                "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16"
            },
            [
              _vm._v("Pagina Atual: "),
              _c("b", { staticClass: "ukid-magin-left-10" }, [
                _vm._v(_vm._s(_vm.page))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1,
                  expression: "pagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.pagination.last_page,
                  "click-handler": _vm.list,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-90-percent": _vm.Commons.isMobile(),
            "uk-card uk-card-default uk-container uk-align-center uk-visible@m uk-width-1-1 ukid-width-70-percent": !_vm.Commons.isMobile()
          },
          attrs: { id: "ukid-card-form" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-card-header ukid-supress-border-bottom uk-visible@m"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-150",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _vm.auth.includes("admin")
                      ? _c("span", { staticClass: "ukid-font-size-18" }, [
                          _vm._v("Gestão de Usuário")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.auth.includes("user")
                      ? _c("span", { staticClass: "ukid-font-size-18" }, [
                          _vm._v("Gerir Meu Usuário")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.auth.includes("admin")
                      ? _c("span", { staticClass: "uk-margin-remove-top" }, [
                          _c("span", [
                            _vm._v(
                              "\n                                Criação, atualização e exclusão"
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("b", { staticClass: "uk-text-primary" }, [
                              _vm._v("Administrator:")
                            ]),
                            _vm._v(
                              " Possue permissão total para gerenciamento da API"
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("b", { staticClass: "uk-text-primary" }, [
                              _vm._v("Common User:")
                            ]),
                            _vm._v(
                              " Utilizado como acesso no front-end e SDK's"
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "uk-label ukid-label-purple-color"
                              },
                              [
                                _vm._v(
                                  "Usuários: " +
                                    _vm._s(_vm.pagination.databaseCount)
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.auth.includes("user")
                      ? _c("p", { staticClass: "uk-margin-remove-top" }, [
                          _vm._m(2)
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("progress", {
            staticClass:
              "uk-progress ukid-progress ukid-margin-bottom-0 ukid-margin-top-0",
            attrs: { value: "0", max: "100" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "uk-card-footer ukid-supress-border-top": !_vm.Commons.isMobile(),
                "uk-card-footer ukid-supress-border-top ukid-card-footer-mobile": _vm.Commons.isMobile()
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "ukid-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "uk-fieldset" },
                    [
                      _c("legend", { staticClass: "uk-legend" }, [
                        _c(
                          "span",
                          { staticClass: "ukid-font-size-25 uk-visible@m" },
                          [
                            _vm._v(
                              "\n                                Formulário\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.user.name
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "uk-text-lowercase ukid-icon-purple-color ukid-font-size-25"
                              },
                              [
                                _c("br"),
                                _vm._v("Editando " + _vm._s(_vm.user.name)),
                                _c("br"),
                                _vm._v("Username: " + _vm._s(_vm.user.username))
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-primary uk-align-right uk-visible@m ukid-margin-bottom-0 ukid-font-size-13",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.clear(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                Iniciar Novo Registro\n                            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("configutation", {
                        ref: "configuration",
                        attrs: {
                          app: _vm.App,
                          strings: _vm.Strings,
                          commons: _vm.Commons,
                          editor: _vm.editor,
                          "editor-config": _vm.editorConfig,
                          user: _vm.user,
                          auth: _vm.auth,
                          role: _vm.role,
                          vm: _vm.vm,
                          errors: _vm.errors
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-flex uk-flex-right uk-visible@s ukid-margin-top-40"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "uk-visible@m" },
                            [
                              _c("router-link", { attrs: { to: "/home" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "uk-button uk-button-default uk-button-large uk-align-center"
                                  },
                                  [_vm._v("Cancelar")]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-hidden@m" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-default uk-button-large uk-align-center",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(3)
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-flex uk-flex-center uk-hidden@s ukid-width-100-percent ukid-margin-top-40"
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-link uk-button-medium uk-align-left ukid-font-size-14",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.vm.configDeviceResolution()
                                  }
                                }
                              },
                              [_vm._v("Voltar")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(4)
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "uk-width-auto uk-hidden@m uk-hidden@l uk-hidden@xl ukid-min-width-165"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner-sm",
          attrs: { "uk-spinner": "ratio:2.6" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon-sm ukid-icon-purple-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:happy;ratio:3.6" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uk-width-auto ukid-min-width-165" }, [
      _c("span", {
        staticClass: "uk-hidden ukid-spinner",
        attrs: { "uk-spinner": "ratio:4" }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "ukid-icon ukid-icon-purple-color ukid-magin-left-5 ukid-margin-top-6",
        attrs: { "uk-icon": "icon:happy;ratio:5.2" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(
        "\n                                Permite atualizar os parâmetros do usuário, incluindo threshold de detecção facial e temperatura dos terminais."
      ),
      _c("br"),
      _vm._v(
        "\n                                Ao alterar esses parâmetros, tenha em mente que isso afetara todas as detecção de câmeras cftv (se estiver usando)"
      ),
      _c("br"),
      _vm._v(
        "\n                                e caso altere o threshold das temperaturas, isso afetara todos os terminais que estejam configurados com esse usuário\n                            "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-default uk-button-large uk-margin-left",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass:
            "uk-button uk-button-save uk-button-link uk-button-medium uk-align-right ukid-font-size-14",
          attrs: { type: "submit" }
        },
        [_vm._v("Salvar")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c096b0c", { render: render, staticRenderFns: staticRenderFns })
  }
}