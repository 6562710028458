<script type="text/javascript">

    import UIkit from 'uikit'

    import App from '../../app.json'

    import Auth from '../../js/auth.js'

    import { Strings } from '../../js/common.js'

    export default {

        data() {

            return {

                App: App,

                credentials: {
                    username: null,
                    password: null
                },

                Strings: Strings,

                error: null

            }

        },

        created() {
            
            document.querySelector('#app').style.display = 'block';
            document.querySelector('.ukid-footer').style.display = 'none';
            
        },

        methods: {

            login() {

                UIkit.notification( { message: App.notification.loginSending } )

                var credentials = {
                    username: this.credentials.username,
                    password: this.credentials.password
                }

                if (!credentials.username) {

                    document.querySelector('#username-input').classList.add('ukid-login-form-danger')
                    return

                } else {

                    document.querySelector('#username-input').classList.remove('ukid-login-form-danger')

                }

                if (!credentials.password) {

                    document.querySelector('#password-input').classList.add('ukid-login-form-danger')
                    return

                } else {

                    document.querySelector('#password-input').classList.remove('ukid-login-form-danger')

                }

                if (this.$route.query.hasOwnProperty('redir') && this.$route.query.redir.length > 0) {

                    Auth.login(this, credentials, '/' + this.$route.query.redir)
                    return

                }

                Auth.login(this, credentials, '/home')

            }

        }
    }

</script>

<template id="login-template">

    <div class="ukid-padding-top-30 ukid-min-height">
        
        <div class="uk-flex uk-flex-center">
            <form id="form" class="ukid-width-300-px" v-on:submit.prevent="login()">
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">    
                        <span class="ukid-white-color ukid-font-size-14">Username</span>
                        <div class="uk-inline ukid-width-100-percent ukid-margin-top-10">
                            <span class="uk-form-icon" uk-icon="icon:user"></span>                            
                            <input class="uk-input ukid-width-300-px" id="username-input" v-model="credentials.username" type="text" placeholder="Usuário">
                        </div>
                    </div>

                    <div class="uk-margin">    
                        <span class="ukid-white-color ukid-font-size-14">Password</span>
                        <div class="uk-inline ukid-width-100-percent ukid-margin-top-10">
                            <span class="uk-form-icon" uk-icon="icon:unlock"></span>                            
                            <input class="uk-input ukid-width-300-px" id="password-input" v-model="credentials.password" type="password" placeholder="Senha">
                        </div>
                    </div>

                    <div v-if="error && error.status == 401" class="ukid-login-text-danger uk-text-center">
                        <p>
                            <span v-html="error.body.description"></span>
                        </p>
                    </div>
            
                    <div class="ukid-padding-top-20">
                        <button  type="submit" class="uk-button uk-button-default uk-button-large ukid-width-300-px ukid-font-size-14">
                            <span class="ukid-white-color">Logar</span>
                        </button>
                    </div>
                </fieldset>                
            </form>          
        </div>

    </div>

</template>
