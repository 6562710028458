<!--suppress JSUnresolvedVariable, JSCheckFunctionSignatures, JSUnresolvedFunction, SpellCheckingInspection -->
<script type="text/javascript">

import App from '../../app.json'
import UIkit from 'uikit'

import { vm } from '../../js/main.js'
import { Datetime } from 'vue-datetime'

export default {

    components: {

        datetime: Datetime

    },

    /**
     * All objects required for this page to function properly
     */
    data() {

        return {

            App: App,
            searchParam: '',
            pagination: {
                last_page: 0
            },
            registers: {
            },
            oldRegisters: [],
            devices: {},
            detectRequest: {
                photo: '',
            },
            selectedDevices: ['777'],
            updateFilters: false,
            blcDetected: false,
            enableAutoList: false,
            controlStartDate: 0,
            controlEndDate: 0,
            startDate: new Date(new Date().setUTCHours(-3)).toISOString(),
            endDate: new Date(new Date().setUTCHours(-3)).toISOString(),
            currentDate: true,
            imagesPerPage: App.pagination.bodies.itensPerPage,
            sub: '',
            page: 1

        }

    },

    watch: {

        startDate: function(value, old) {

            if (value !== old && this.controlStartDate > 0) {

                this.currentDateFilter('false')

            }

            this.startDate = value
            this.controlStartDate++

        },

        endDate: function(value, old) {

            if (value !== old && this.controlEndDate > 0) {

                this.currentDateFilter('false')

            }

            this.endDate = value
            this.controlEndDate++

        }

    },

    /**
     * This method executes when the vue object is created.
     */
    created() {

        let _ = this

        /**
         * Active the search list
         */
        _.enableInterval()

        /**
         * Get all devices of the logged user
         */
        _.devices = _.listDevices()

        /**
         * Get user logged
         */
        _.sub = vm.user.sub

        /**
         * Config initial filters
         */
        _.setFilters()

    },

    methods: {

        /**
         * Responsible for re-registering the records
         * and re-activating the polling interval.
         */
        search() {

            let _ = this

            vm.spinnerSearch()

            _.enableInterval()

        },

        /**
         * This method is responsible for listing the real time
         * detections that are happening in CCTV cameras, this method
         * is based on the end user predefined filters
         *
         * This method fills in the attributes (registers and pagination) of the data object.
         *
         * @param page Pagination based page number
         */
        list(page) {

            let _ = this

            let startDate = new Date(_.startDate)
            let endDate   = new Date(_.endDate)

            if (page !== undefined) {

                _.page = page

                _.clearInterval()

            }

            // Filter of search
            let filter_ = {

                pageNum: (page === undefined ? 1 : page), // Number of page
                perPage: _.imagesPerPage, // Total itens per page
                param: _.searchParam, // Some string of research
                blcDetected: _.blcDetected, // Enable blacklist people filter
                devices: _.selectedDevices, // Search per list of camera or proximity devices
                startDate: startDate.getTime(), // Search per some start date
                endDate: endDate.getTime() // Search per some end date

            }

            // Post filter to unike server
            _.$http.post(
                App.host.concat(
                    App.bodiesEndpoint + "?nocache=" + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))
                ),
                filter_
            ).then(response => {

                // Update bound objects
                _.registers = response.body.data
                _.pagination = response.body

                // Scans all records found
                if (_.registers.length) {

                    _.registers.forEach(function(object) {

                        setTimeout(function(){

                            _.detect(object)

                        }, 555)

                    });

                }

            }).catch( function(err){

                console.error(err)

            })

        },

        detect(object) {

            /*
             * This document
             */
            let _ = this

            /*
             * This div contains tha image and div bbox
             */
            let containerDefault = document.querySelector('#ukid-container-' + object.id)
            let containerZoom = document.querySelector('#ukid-container-zoom-' + object.id)

            /*
             * The first image is shown on list, second is shown when user click on zoom
             */
            let imgDefault = document.querySelector('#ukid-image-bodies-' + object.id)
            let imgZoom = document.querySelector('#ukid-image-bodies-zoom-' + object.id)

            /*
             * Canvas to image list and image zoom
             */
            let canvasDefault = document.createElement('canvas')
            let canvasZoom = document.createElement('canvas')

            canvasDefault.width  = imgDefault.width
            canvasDefault.height = imgDefault.height

            canvasZoom.width  = imgZoom.width
            canvasZoom.height = imgZoom.height

            /*
             * Context to image list and image zoom
             */
            let ctxDefault = canvasDefault.getContext('2d')
            let ctxZoom = canvasZoom.getContext('2d')

            ctxDefault.drawImage(imgDefault, 0, 0, imgDefault.width, imgDefault.height)
            ctxZoom.drawImage(imgZoom, 0, 0, imgZoom.width, imgZoom.height)

            /*
             * Detects the listing image
             */
            _.detectRequest.photo = canvasDefault.toDataURL('image/png').replace('data:image/png;base64,', '')

            _.$http.post(App.host.concat(App.detectBodiesEndpoint), _.detectRequest).then(response => {

                _.detectResponse = response.data

                _.bbox(containerDefault, imgDefault, _.detectResponse.objects.body)

            });

            /*
             * Detects the zoom image
             */
            _.detectRequest.photo = canvasZoom.toDataURL('image/png').replace('data:image/png;base64,', '')

            _.$http.post(App.host.concat(App.detectBodiesEndpoint), _.detectRequest).then(response => {

                _.detectResponse = response.data

                _.bbox(containerZoom, imgZoom, _.detectResponse.objects.body)

            });

        },

        bbox(container, img, bodies) {

            let _ = this

            container.querySelectorAll('div').forEach(e => e.parentNode.removeChild(e))

            bodies.forEach(function(object) {

                let rect = document.createElement('div')

                rect.classList.add('rect')

                rect.style.width  = (object.bbox.right - object.bbox.left) + 'px'
                rect.style.height = (object.bbox.bottom - object.bbox.top) + 'px'
                rect.style.left   = (img.offsetLeft + object.bbox.left) + 'px'
                rect.style.top    = (img.offsetTop  + object.bbox.top) + 'px'

                rect.style.borderColor = _.colorBox()

                container.appendChild(rect)

            });

        },

        colorBox() {

            let colors = ['#fdecce','#F5EA87','#5CF77E','#3acff8','#7C5EC6','#ff33ff','#b366ff','#bb4462','#b0b036','#f49e0b']

            return colors[Math.floor((Math.random() * 10))]

        },

        /**
         * List all devices relevant to or logged user
         */
        listDevices() {

            let _ = this

            _.$http.get(

                App.host.concat(App.devicesEndpoint)

            ).then(response => {

                _.devices = response.body

            }).catch( function(err){

                UIkit.modal.alert(err.body.description)

            })

        },

        /**
         * Saves user-selected filter data to browser database
         */
        saveSelectedDevices() {

            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_searchParam_bodies', this.searchParam)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_selectedDevices_bodies', this.selectedDevices)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_imagesPerPage_bodies', this.imagesPerPage)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_personsNotDetected_bodies', this.personsNotDetected)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_blcDetected_bodies', this.blcDetected)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_startDate_bodies', this.startDate)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_endDate_bodies', this.endDate)
            localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_currentDate_bodies', this.currentDate)

            this.updateFilters = true

            this.setFilters()

        },

        /**
         * Configure saved filter data for bound objects
         */
        setFilters() {

            let _searchParam_proximity = window.location.hostname + '_' + vm.user.sub + '_searchParam_bodies'
            let _selectedDevices_proximity = window.location.hostname + '_' + vm.user.sub + '_selectedDevices_bodies'
            let _imagesPerPage_proximity = window.location.hostname + '_' + vm.user.sub + '_imagesPerPage_bodies'
            let _blcDetected_proximity = window.location.hostname + '_' + vm.user.sub + '_blcDetected_bodies'
            let _startDate_proximity = window.location.hostname + '_' + vm.user.sub + '_startDate_bodies'
            let _endDate_proximity = window.location.hostname + '_' + vm.user.sub + '_endDate_bodies'
            let _currentDate_proximity = window.location.hostname + '_' + vm.user.sub + '_currentDate_bodies'

            if (localStorage.getItem(_selectedDevices_proximity) !== undefined && localStorage.getItem(_selectedDevices_proximity)) {

                this.selectedDevices = localStorage.getItem(_selectedDevices_proximity).split(',')

            }

            if (localStorage.getItem(_imagesPerPage_proximity) !== undefined && localStorage.getItem(_imagesPerPage_proximity)) {

                this.imagesPerPage = localStorage.getItem(_imagesPerPage_proximity)

            }

            if (localStorage.getItem(_blcDetected_proximity) !== undefined && localStorage.getItem(_blcDetected_proximity)) {

                this.blcDetected = localStorage.getItem(_blcDetected_proximity)

            }

            if (localStorage.getItem(_startDate_proximity) !== undefined && localStorage.getItem(_startDate_proximity)) {

                this.startDate = new Date(localStorage.getItem(_startDate_proximity)).toISOString()

            }

            if (localStorage.getItem(_endDate_proximity) !== undefined && localStorage.getItem(_endDate_proximity)) {

                this.endDate = new Date(localStorage.getItem(_endDate_proximity)).toISOString()

            }

            if (localStorage.getItem(_currentDate_proximity) !== undefined && localStorage.getItem(_currentDate_proximity)) {

                this.currentDate = localStorage.getItem(_currentDate_proximity)

            }

            this.currentDateFilter(this.currentDate)

        },

        currentDateFilter(isCurrentDate) {

            this.currentDate = isCurrentDate

            if (this.currentDate === "true") {

                this.startDate = new Date(new Date().setUTCHours(-3)).toISOString()
                this.endDate   = new Date(new Date().setUTCHours(-3)).toISOString()

            }

        },

        clearInterval() {

            let _ = this

            for (let i=1; i < 99999; i++) {

                window.clearInterval(i);

                _.enableAutoList = true

            }

        },

        enableInterval() {

            let _ = this

            _.page = 1

            setInterval(function(){

                _.enableAutoList = false

                _.list(undefined, 1, this.imagesPerPage)

            }, App.layout.bodies.interval)


        },

        openFullScreen() {

            this.imagesPerPage = App.pagination.bodies.itensPerPageFullscreen

            if (document.documentElement.requestFullscreen) {

                document.documentElement.requestFullscreen()

            } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */

                edocument.documentElementlem.mozRequestFullScreen()

            } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */

                document.documentElement.webkitRequestFullscreen()

            } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */

                document.documentElement.msRequestFullscreen()

            }

            document.querySelector('.ukid-open-fullscreen-buttom').classList.add('uk-hidden')
            document.querySelector('.ukid-close-fullscreen-buttom').classList.remove('uk-hidden')

            try {

                document.querySelector('#ukid-top').classList.add('uk-hidden')
                document.querySelector('#ukid-footer').classList.add('uk-hidden')

            } catch (ignored) {

            }

            document.querySelector('.ukid-logo-fullscreen').classList.remove('uk-hidden')

        },

        closeFullScreen() {

            this.imagesPerPage = App.pagination.bodies.itensPerPage

            if (document.exitFullscreen) {

                document.exitFullscreen()

            } else if (document.mozCancelFullScreen) { /* Firefox */

                document.mozCancelFullScreen()

            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */

                document.webkitExitFullscreen()

            } else if (document.msExitFullscreen) { /* IE/Edge */

                document.msExitFullscreen()

            }

            document.querySelector('.ukid-close-fullscreen-buttom').classList.add('uk-hidden')
            document.querySelector('.ukid-open-fullscreen-buttom').classList.remove('uk-hidden')
            document.querySelector('.ukid-logo-fullscreen').classList.add('uk-hidden')

            try {

                document.querySelector('#ukid-top').classList.remove('uk-hidden')
                document.querySelector('#ukid-footer').classList.remove('uk-hidden')

            } catch (ignored) {

            }

        }

    }

}

</script>

<template id="bodies-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>

        <!-- Modal filters -->
        <div id="modal-devices" uk-modal>

            <div class="uk-modal-dialog uk-modal-body">
                <div class="uk-margin">
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-visible@s">Snapshots por Página</span>
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-10">Snapshots por Página</span><br />
                    <div class="uk-inline ukid-width-22-percent uk-visible@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-50-percent uk-hidden@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                </div>
                <!--div class="uk-margin">
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-visible@s">Pesquisar Qualquer Parâmetro (Nome, CPF ou Outros)</span>
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-10">Pesquisar Qualquer Parâmetro<br />(Nome, CPF ou Outros)</span><br />
                    <div class="uk-inline ukid-width-30-percent uk-visible@s">
                        <input type="text" v-model="searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        <input type="text" v-model="searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                </div-->
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Corpos Detectados no Intervalo De:</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Corpos Detectados<br />no Intervalo De:</span><br /><br />
                    <div uk-grid>
                        <div class="uk-inline ukid-width-20-percent uk-visible@s">
                            DE:
                            <datetime
                                v-model="startDate"
                                type="date"
                                input-class="mx-input ukid-width-100-percent"
                                value-zone="America/Sao_Paulo"
                                zone="America/Sao_Paulo"
                                format="dd/MM/yyyy"
                                :phrases="{}"
                                :hour-step="1"
                                :minute-step="5"
                                :week-start="7"
                                :auto="true"
                                :max-datetime="endDate"
                                use24-hour></datetime>
                        </div>
                        <div></div>
                        <div class="uk-inline ukid-width-20-percent uk-visible@s">
                            ATÉ:
                            <datetime
                                v-model="endDate"
                                type="date"
                                input-class="mx-input ukid-width-100-percent"
                                value-zone="America/Sao_Paulo"
                                zone="America/Sao_Paulo"
                                format="dd/MM/yyyy"
                                :phrases="{}"
                                :hour-step="1"
                                :minute-step="5"
                                :week-start="7"
                                :auto="true"
                                :max-datetime="new Date().toISOString()"
                                :min-datetime="startDate"
                                use24-hour></datetime>
                        </div>
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        DE:
                        <datetime
                            v-model="startDate"
                            type="date"
                            input-class="mx-input ukid-width-100-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy"
                            :phrases="{}"
                            :hour-step="1"
                            :minute-step="5"
                            :week-start="7"
                            :auto="true"
                            :max-datetime="endDate"
                            use24-hour></datetime>
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        <br />
                        ATÉ:
                        <datetime
                            v-model="endDate"
                            type="date"
                            input-class="mx-input ukid-width-100-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy"
                            :phrases="{}"
                            :hour-step="1"
                            :minute-step="5"
                            :week-start="7"
                            :auto="true"
                            :max-datetime="new Date().toISOString()"
                            :min-datetime="startDate"
                            use24-hour></datetime>
                    </div>
                </div>
                <div class="uk-margin">
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-visible@s">
                        Somente registros da data atual
                    </span>
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Somente registros da data atual
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @click="currentDateFilter('true')" true-value="true" false-value="false" v-model="currentDate" class="uk-checkbox" type="checkbox">
                    </div>
                </div>
                <span class="uk-label uk-margin-small ukid-primary-label-color ukid-margin-bottom-10 uk-visible@s">
                    Escolha os dispositivos a serem assistidos:<br />
                    <span class="ukid-font-size-9 ukid-icon-pink-color">Exemplo: Camera em frente ao balcão principal</span><br/>
               </span>
                <span class="uk-label uk-margin-small ukid-primary-label-color ukid-margin-bottom-10 uk-hidden@s ukid-font-size-10">
                    Escolha os dispositivos<br />a serem assistidos:<br />
                    <span class="ukid-font-size-9 ukid-icon-pink-color">Exemplo: Camera em frente<br />ao balcão principal</span><br/>
               </span>
                <div class="uk-panel uk-panel-scrollable">
                    <ul class="uk-list">
                        <li v-for="device in devices" :key="device.uuid">
                            <label>
                                <input :id="device.uuid" :value="device.uuid" v-model="selectedDevices" class="uk-checkbox" type="checkbox"> {{device.type}} - {{device.meta}}<br />
                                <span class="ukid-font-size-10">{{device.uuid}}</span>
                                <span v-if="sub.indexOf('admin') > -1" class="ukid-font-size-10">
                                     - {{device.enabled ? 'Habilitado' : 'Desabilitado'}}
                                </span>
                            </label>
                        </li>
                    </ul>
                </div>
                <p>
                    <span class="ukid-font-size-11 ukid-icon-pink-color">
                        OBS: Os dados de dispositivos são salvos no armazenamento do navegador<br /> 
                        e não na sessão do usuário, possibilitando o monitoramento em computadores diferentes                                                
                    </span>
                </p>
                <p class="uk-text-right uk-visible@s">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Não Salvar</button>
                    <button @click="saveSelectedDevices()" class="uk-button uk-button-primary uk-modal-close" type="button">Salvar</button>
                </p>
                <p class="uk-text-right uk-hidden@s">
                    <button class="uk-button uk-button-default uk-button-small uk-modal-close" type="button">Não Salvar</button>
                    <button @click="saveSelectedDevices()" class="uk-button uk-button-primary uk-button-small uk-modal-close" type="button">Salvar</button>
                </p>
            </div>

        </div>

        <!-- Delete modal -->
        <div id="modal-overflow" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <button v-on:click="del()" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>
            </div>
        </div>

        <!-- Top of the layout proximity -->
        <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
            <div class="uk-card-header ukid-supress-border-bottom">
                <div class="ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50">
                    <a href="../" class="uk-navbar-item uk-logo ukid-white-color ukid-font-size-13">
                        <img src="content/images/unike-logo-un.png" width="120" class="uk-margin-small-right">
                    </a>
                </div>
                <div class="uk-grid-small uk-flex-middle ukid-min-height-60" uk-grid>
                    <div class="uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:search;ratio:5.0"></span>
                    </div>
                    <div class="uk-width-expand">
                        <span class="ukid-font-size-20 ukid-margin-top-40 uk-visible@s">Pessoas nas Proximidades</span>
                        <span class="ukid-font-size-16 ukid-margin-top-40 uk-hidden@s">Pessoas nas Proximidades</span>
                        <div class="uk-margin-remove-top">
                            <span class="uk-visible@s">                                
                                Módulo piloto para detecção de corpos nas proximidades das câmeras RTSP
                                <br />
                                <div class="uk-label ukid-label-blue-color">Pessoas neste Momento: {{pagination.databaseCount}}</div>
                            </span>
                            <span class="uk-hidden@s ukid-font-size-13">                                
                                Módulo piloto para detecção de corpos nas proximidades das câmeras RTSP
                                <br />
                                <div class="uk-label ukid-label-blue-color ukid-font-size-9">Snapshots neste Momento: {{pagination.databaseCount}}</div>
                            </span>
                            <div class="uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15" align="right">
                                <a uk-toggle="target: #modal-devices">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar
                                </a>
                            </div>
                            <div class="uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17" align="right">
                                <a uk-toggle="target: #modal-devices">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar por Dispositivo
                                </a>
                            </div>
                            <button uk-toggle="target: #modal-devices" class="uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                Filtrar por Dispositivo
                            </button>
                            <button @click="openFullScreen()" class="ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="tv"></span>
                                Tela Cheia
                            </button>
                            <button @click="closeFullScreen()" class="ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="close"></span>
                                Sair da Tela Cheia
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-card-footer ukid-supress-border-top">

                <div class="uk-flex uk-flex-center ukid-padding-top-20">
                    <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
                        <div v-show="!registers.length" class="uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color" uk-alert>
                            <p class="ukid-white-color">
                                <span class="uk-margin-small-right uk-icon" uk-icon="warning"></span>
                                {{App.proximity.nobody}}
                            </p>
                        </div>

                        <!--For on all detected bodies according to filters defined by final user -->
                        <div v-for="detectedBodies in registers" :key="detectedBodies.id">

                            <!-- Modal show face zoom -->
                            <div :id="'modal-media-image-' + detectedBodies.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                                    <button class="uk-modal-close-outside" type="button" uk-close></button>
                                    <div :id="'ukid-container-zoom-' + detectedBodies.id" class="uk-inline ukid-square-person-edit ukid-light-blue-color">
                                        <img crossorigin="Anonymous" :id="'ukid-image-bodies-zoom-' + detectedBodies.id" uk-tooltip :src="detectedBodies.snapshot + '?nocache=' + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))" alt="">
                                    </div>
                                    <div class="uk-overlay uk-light uk-position-bottom ukid-font-size-18">
                                        <p class="ukid-white-color ukid-background-transparent">
                                            <span v-if="detectedBodies.totalBodies" class="ukid-font-size-30 ukid-padding-15px">
                                                {{detectedBodies.totalBodies}} pessoas visíveis para contagem
                                            </span>
                                            <br />
                                            <span v-if="detectedBodies.totalBodies" class="ukid-font-size-30 ukid-padding-15px">
                                                Última atualização em {{new Date(detectedBodies.updateTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- Modal event camera device -->
                            <div :id="'modal-media-cameras-' + detectedBodies.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                                    <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>    
                                        <span class="uk-label uk-margin-small ukid-primary-label-color uk-visible@s">
                                            <span class="uk-margin-small-right uk-icon" uk-icon="icon:camera;ratio:0.8"></span>
                                            Informações de detecções da câmera
                                        </span>
                                        <span class="uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-12">                                            
                                            Informações de<br />detecções da câmera
                                        </span>
                                        <br />
                                        <div class="uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px">
                                            <ul class="uk-list ukid-margin-top-0 ukid-font-size-12">
                                                <li v-for="bboxInfo in detectedBodies.bboxInfoList" :key="bboxInfo.timestamp">
                                                    Pessoas visiveis para contagem -> {{bboxInfo.totalBodies}} -> {{new Date(bboxInfo.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="uk-modal-footer uk-text-right">
                                        <button class="uk-button uk-button-default uk-modal-close" type="button">Fechar</button>
                                    </div>
                                </div>
                            </div>

                            <!-- Show mode of demo and production -->
                            <div v-if="!$route.query.hasOwnProperty('debug')" class="uk-card uk-card-default uk-card-bod ukid-width-600-px">
                                <div align="center" class="ukid-padding-5px">
                                    <table class="ukid-font-size-17" width="100%">
                                        <tbody>
                                        <tr>
                                            <td align="center" class="ukid-font-size-20">
                                                {{detectedBodies.totalBodies}} pessoas visíveis para contagem
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                <!-- Images -->
                                                <div :id="'ukid-container-' + detectedBodies.id" class="uk-inline ukid-square-person-edit ukid-light-blue-color">
                                                    <img crossorigin="Anonymous" :id="'ukid-image-bodies-' + detectedBodies.id" :src="detectedBodies.snapshot + '?nocache=' + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))" />
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table class="ukid-font-size-15" width="100%">
                                        <tbody>
                                        <tr>
                                            <td align="center" class="ukid-icon-blue-color ukid-font-size-13">
                                                Camera: {{detectedBodies.camName}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div class="ukid-padding-7px ukid-icon-blue-color ukid-margin-negative-top-10">                                        
                                        <span class="ukid-icon-blue-color ukid-font-size-13">
                                            {{new Date(detectedBodies.updateTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                        </span>
                                        <br /><br />
                                        <a title="Ampliar a imagem" uk-tooltip :href="'#modal-media-image-' + detectedBodies.id" class="ukid-icon-blue-color ukid-magin-right-10 ukid-margin-top-3" uk-toggle>
                                            <span class="uk-margin-small-right uk-icon" uk-icon="icon:image;ratio:0.98"></span>
                                        </a>
                                        <a :href="'#modal-media-cameras-' + detectedBodies.id" uk-toggle>
                                            <span  title="Verificar eventos" class="ukid-icon-blue-color" uk-icon="icon:camera;ratio:1.0"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div align="center">
                <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10" uk-margin>
                    <paginate
                        v-model="page"
                        :page-count="pagination.last_page"
                        :click-handler="list"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-10'">
                    </paginate>
                </ul>
                <span
                    uk-margin
                    uk-tooltip
                    v-show="pagination.last_page > 1 && enableAutoList"
                    title="Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui"
                    @click="enableInterval()"
                    class="ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20"
                    uk-icon="icon:play-circle;ratio:2.7"></span>
            </div>

        </div>

    </div>

</template>