<!--suppress ALL-->

<style src="../../../content/css/vue-multiselect.min.css"></style>

<style scoped lang="scss">
    @import '../../../content/css/temperature.scss';
</style>

<script type="text/javascript">

    import App from '../../app.json'
    import UIkit from 'uikit'

    import { vm } from '../../js/main.js'
    import { Datetime } from 'vue-datetime'

    import { Strings } from '../../js/common.js'

    import Multiselect from 'vue-multiselect'

    export default {

        components: {
	    Multiselect,
            datetime: Datetime
        },

        /**
         * All objects required for this page to function properly
         */
        data() {

            return {

                App: App,
                pagination: {
                    last_page: 0
                },
                customers: '',
                registers: {},
                devices: {},
                units: {
                    Celcius: "°C",
                    Fahrenheit: "°F"
                },
                config: {
                    minTemp: 34,
                    maxTemp: 45,
                    unit: "Celcius"
                },
                filters: {
                    searchParam: '',
                    blcDetected: false,
                    personsRefusals: false,
                    personsNotDetected: true,
                    personsNotDetectedWithoutRefusals: true,
                    personsWithProblems: false,
                    currentDate: true,
		    blnJoin: false,
                    startDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    endDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    imageSize: '220',
                    fontSize: '16',
                    selectedDevices: ['7'],
                    imagesPerPage: App.pagination.proximity.itensPerPage,
                },
		customerSelected: '',
		selectedForUnion: [],
                enableAutoList: false,
                controlStartDate: 0,
                controlEndDate: 0,
                sub: '',
                loggedUser: null,
                page: 1,
                socket: null,
                stomp: null

            }

        },
        
        /**
         * This method executes when the vue object is created.
         */
        created() {

            let _ = this
            
            /**
             * Get logged user
             */
            _.getLoggedUser()

            /**
             * Config initial filters
             */
            _.setFilters()

            /** 
             * Active the search list
             */
            _.list(undefined)
            _.enableInterval()  
           

	    _.listCustomers()

            /**
             * Get all devices of the logged user
             */
            _.listDevices( '' )

            /**
             * Get user logged
             */
            _.sub = vm.user.sub
                    
        },
        
        methods: {

            /**
             * Responsible for re-registering the records 
             * and re-activating the polling interval.
             */
            search() {

                let _ = this

                vm.spinnerSearch()

                _.enableInterval()

            },

            /**
             * List all devices relevant to or logged user
             */
            getLoggedUser() {
            
                let _ = this

                _.$http.get(

                    App.host.concat(App.loggedUserEndpoint)

                ).then(response => {

                    _.loggedUser = response.body
                                        
                }).catch( function(err){

                    UIkit.modal.alert(err.body.description)

                }) 

            },

            /**
             * This method is responsible for listing the real time 
             * detections that are happening in CCTV cameras, this method 
             * is based on the end user predefined filters
             * 
             * This method fills in the attributes (registers and pagination) of the data object.
             * 
             * @param page Pagination based page number
             */
            list(page) {
            
                let _ = this

                if (page !== undefined) {
                                        
                    _.page = page

                    _.clearInterval()

                }
                
                let startDate = new Date(_.filters.startDate)
                let endDate   = new Date(_.filters.endDate)

                /**
                 * Filter of search
                 */
                let filter_ = {

                    pageNum: (page === undefined ? 1 : page), // Number of page
                    perPage: _.filters.imagesPerPage, // Total items per page
                    id19: _.filters.searchParam ? [_.filters.searchParam] : [], // Some string of research
		    id19Parent: '',
		    blcDetected: _.filters.blcDetected, // Enable blacklist people filter
                    personsDetected: _.filters.personsNotDetected || _.filters.personsNotDetectedWithoutRefusals,
		    personsRefusals: _.filters.personsNotDetected || _.filters.personsRefusals, // Get faces refusals
		    blnJoin: _.filters.blnJoin,
		    storeId: [],
                    devices: _.filters.selectedDevices, // Search per list of camera or proximity devices
                    startDate: startDate.getTime(), // Search per some start date
                    endDate: endDate.getTime() // Search per some end date

                }

                /**
                 * Post filter to unike server
                 */
                _.$http.post(
                    App.host.concat(
                        App.proximityEndpoint + "&nocache=" + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))
                    ), 
                    filter_
                ).then(response => {

                    /**
                     * Update binded objects
                     */
                    _.registers = response.body.data
                    _.pagination = response.body

                }).catch( function(err){

                    console.error(err)

                })

                if (page !== undefined) {
                                        
                    _.page = page

                    _.clearInterval()

                }

            },

	    handleCustomerSelection(selectedValue) {

      		console.log('Opção selecionada:', selectedValue);
		
		let _ = this

		if ( selectedValue == 'TODOS' ) {
			_.listDevices( '' )  
		} else {
			_.listDevices( selectedValue.toLowerCase() )
		}


    	    },

	    listCustomers() {

    	        let _ = this

                _.$http.get(

                    App.host.concat(App.customersEndpoint)

                ).then(response => {

                   _.customers = response.body

                })

            },

            /**
             * List all devices relevant to or logged user
             */
            listDevices( customer ) {
            
                let _ = this

                _.$http.get(

                    App.host.concat(App.devicesEndpoint + "/" + customer)

                ).then(response => {

                    _.devices = response.body
                                        
                }).catch(function(err){

                    UIkit.modal.alert(err.body.description)

                }) 

            },            

            /**
             * Remove detection by document number
             */
            del(documentNumber) {

                let _ = this

                _.$http.delete(App.host.concat(App.deleteProximityEndpoint).concat(documentNumber)).then(response => {

                    /**
                     * Close all visual notifications of uikit
                     */
                    UIkit.notification.closeAll()

                    /**
                     * Show delete notification to user
                     */
                    UIkit.notification({ 
                        message: App.notification.del, 
                        status: 'primary', 
                        pos: 'top-center', 
                        timeout: App.notification.timeout 
                    })

                    if (_.pagination.current_page > 0 && _.page == _.pagination.last_page && _.pagination.data.length == 1) {

                        _.list(_.page - 1)

                    } else {

                        _.list(_.page)

                    }

                    _.clear(false)

                }).catch(function(err) {
                        
                    UIkit.modal.alert(err.body.description)

                })    
            
            },

            generateExcel() {

                let _ = this
        
                let startDate = new Date(_.filters.startDate)
                let endDate   = new Date(_.filters.endDate)

                let modal_ = UIkit.modal.dialog(App.notification.download, { escClose: false, bgClose: false })

                /**
                 * Filter of search
                 */
                let filter_ = {

                    pageNum: 1, // Number of page
                    perPage: 1, // Total itens per page
                    param: _.filters.searchParam, // Some string of research
                    personsNotDetected: _.filters.personsNotDetected, // Enable unknown people filter
                    personsNotDetectedWithoutRefusals: _.filters.personsNotDetectedWithoutRefusals,
                    blcDetected: _.filters.blcDetected, // Enable blacklist people filter
                    personsRefusals: _.filters.personsRefusals, // Get faces refusals
                    devices: _.filters.selectedDevices, // Search per list of camera or proximity devices
		    blnJoin: _.filters.blnJoin, 
                    startDate: startDate.getTime(), // Search per some start date
                    endDate: endDate.getTime() // Search per some end date

                }

                /**
                 * Post filter to unike server
                 */
                _.$http.post(
                    App.host.concat(
                        App.generateReportEndpoint + "?nocache=" + Math.abs(Math.floor(Math.random() * 777 * 777 * 777 * 777))
                    ), 
                    filter_,
                    {
                        responseType: 'arraybuffer'
                    }
                ).then(response => {

                    let blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})

                    let filename = 'unike_faces_detection.xls'
                    
                    let result = document.createElement('a')
                    result.href = window.URL.createObjectURL(blob)
                    result.download = filename
                    
                    result.click()
                    
                    setTimeout(function(){ modal_.hide() }, 300)
                    
                }).catch(function(err){

                    setTimeout(function(){ 
                        modal_.hide()
                        UIkit.modal.alert(App.notification.reportDownloadError)
                    }, 300)

                }) 
                
            },

            /**
             * Saves user-selected filter data to browser database
             */
            saveSelectedDevices() {

                let filterName = window.location.hostname + '_' + vm.user.sub + '_filters_proximity'

                localStorage.setItem(filterName, JSON.stringify(this.filters))

                this.setFilters()
                
                this.list(this.page)

                this.clearInterval()
                this.enableInterval()

            },

            /**
             * Configure saved filter data for bound objects
             */
            setFilters() {

                let filterName = window.location.hostname + '_' + vm.user.sub + '_filters_proximity'

                let json = localStorage.getItem(filterName)

                if (!Strings.isNullOrEmpty(json)) {

                    this.filters = JSON.parse(json)

                    if (!this.filters.hasOwnProperty('imageSize'))
                        this.filters.imageSize = 220

                    if (!this.filters.hasOwnProperty('fontSize'))
                        this.filters.fontSize = 16                        

                    if (!Strings.isNullOrEmpty(this.filters.searchParam)) {

                        //this.filters.personsNotDetected = false
                        //this.filters.personsNotDetectedWithoutRefusals = false
                        //this.filters.blcDetected = false
                        //this.filters.personsRefusals = false
                        //this.filters.personsWithProblems = true
			//this.filters.blnJoin = false

                    } else {

                        // this.filters.personsNotDetected = true
                        // this.filters.personsWithProblems = false

                    }

                    localStorage.setItem(filterName, JSON.stringify(this.filters))

                }
                
                // this.currentDateFilter(this.filters.currentDate)

                this.$forceUpdate()

            },

            selectAllDevices() {

                let _ = this

                let uuids = []

                _.devices.forEach(function(item, index, object) { uuids[index] = item.uuid })

                _.filters.selectedDevices = uuids

            },

            desselectAllDevices() {

                let _ = this

                let uuids = ['7']

                _.filters.selectedDevices = uuids

            },

            currentDateFilter(isCurrentDate) {

                let _ = this
                
                if (isCurrentDate !== undefined) _.filters.currentDate = isCurrentDate

                if (_.filters.currentDate === true || _.filters.currentDate === "true") {

                    if (_.$moment(_.filters.endDate).isSameOrAfter(_.filters.startDate, 'day')) {
                     
                        _.filters.endDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()

                    }
                    
                    if (_.$moment(_.filters.startDate).isBefore(_.filters.endDate)) {

                        _.filters.startDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()

                    }

                }

            },

            includePersonsNotDetected() {

                this.filters.blcDetected = false
                this.filters.personsRefusals = false
                this.filters.personsNotDetectedWithoutRefusals = false
                this.filters.personsWithProblems = false

            },

            includePersonsNotDetectedWithoutRefusals() {

                this.filters.blcDetected = false
                this.filters.personsRefusals = false
                this.filters.personsNotDetected = false
                this.filters.personsWithProblems = false

            },

            onlyBLC() {

                this.filters.personsNotDetected = false
                this.filters.personsNotDetectedWithoutRefusals = false
                this.filters.personsWithProblems = false
                this.filters.personsRefusals = false

            },

            onlyRefusals() {

                this.filters.personsNotDetected = false
                this.filters.personsNotDetectedWithoutRefusals = false
                this.filters.personsWithProblems = false
                this.filters.blcDetected = false

            },

            includePersonsWithProblems() {

                this.filters.personsNotDetected = false
                this.filters.personsNotDetectedWithoutRefusals = false
                this.filters.blcDetected = false
                this.filters.personsRefusals = false

            },

            clearInterval() {

                let _ = this

                for (var i = 1; i < 99999; i++) {
                    
                    window.clearInterval(i);

                    _.enableAutoList = true
                    
                }

            },

            enableInterval() {
                
                let _ = this
    
                _.page = 1

                _.list(_.page)

                _.enableAutoList = false

                setInterval(function(){
                
                    _.list(undefined)

                }, App.layout.proximity.interval)
                                
            },            

            openFullScreen() {

                this.filters.imagesPerPage = App.pagination.proximity.itensPerPageFullscreen

                if (document.documentElement.requestFullscreen) {

                    document.documentElement.requestFullscreen()

                } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */

                    edocument.documentElementlem.mozRequestFullScreen()

                } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */

                    document.documentElement.webkitRequestFullscreen()

                } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */

                    document.documentElement.msRequestFullscreen()

                }

                document.querySelector('.ukid-open-fullscreen-buttom').classList.add('uk-hidden')
                document.querySelector('.ukid-close-fullscreen-buttom').classList.remove('uk-hidden')

                try { 

                    document.querySelector('#ukid-top').classList.add('uk-hidden')
                    document.querySelector('#ukid-footer').classList.add('uk-hidden')

                } catch (ignored) {

                }

                document.querySelector('.ukid-logo-fullscreen').classList.remove('uk-hidden')
                 
            },

            closeFullScreen() {

                this.filters.imagesPerPage = App.pagination.proximity.itensPerPage

                if (document.exitFullscreen) {

                    document.exitFullscreen()

                } else if (document.mozCancelFullScreen) { /* Firefox */

                    document.mozCancelFullScreen()

                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */

                    document.webkitExitFullscreen() 
                    
                } else if (document.msExitFullscreen) { /* IE/Edge */

                    document.msExitFullscreen()

                }

                document.querySelector('.ukid-close-fullscreen-buttom').classList.add('uk-hidden')
                document.querySelector('.ukid-open-fullscreen-buttom').classList.remove('uk-hidden')
                document.querySelector('.ukid-logo-fullscreen').classList.add('uk-hidden')

                try { 

                    document.querySelector('#ukid-top').classList.remove('uk-hidden') 
                    document.querySelector('#ukid-footer').classList.remove('uk-hidden')
                    
                } catch (ignored) {                    
                }
                
            },

            setTemperature(id, value) {
                
                let _ = this

                setTimeout(function(){

                    let el = document.querySelector('#temperature-' + id)

                    if (el !== null) {
                        
                        el.style.height = (value / _.config.maxTemp) * 100 + "%"
                        el.dataset.value = value + _.units[_.config.unit]
                        
                    }
                    
                }, 1000)

            },

            showCardNumber(camId, cardNumber, name) {

                let _ = this

                if (Strings.isNullOrEmpty(camId)) {

                    UIkit.modal.alert('<span class="ukid-font-size-15">O id da camera não foi encontrado</span>')
                    return
                    
                }

                if (Strings.isNullOrEmpty(cardNumber)) {

                    UIkit.modal.alert('<span class="ukid-font-size-15">Não há número de cartão para essa pessoa</span>')
                    return
                    
                }                
            
                // UIkit.modal.alert(`<span class="ukid-font-size-25">Cartão de Acesso: ${cardNumber}</span>`)

                let noCacheNumber = Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))

                _.$http.post(
                    `${App.host}${App.proximityIntegrationEndpoint}?nocache=${noCacheNumber}`,
                    {
                        host: 'https://sbx.beachpark.com.br/cxf/facial/rest/recongnition',
                        token: null,
                        json: `{
                            "cameraId": "${camId}",
                            "card": "${cardNumber}"
                        }`
                    },
                    { 
                        emulateJSON: true 
                    }
                ).then(response => {

                    var audio = new Audio('content/sounds/success.wav')
                    audio.play()

                    /**
                     * Close all visual notifications of uikit
                     */
                    UIkit.notification.closeAll()

                    /**
                     * Show delete notification to user
                     */
                    UIkit.notification({ 
                        message: `<br /><br /><br /><br /><span class="ukid-font-size-18">Reconhecimento Facial Inserido<br /><br />Cartão: ${cardNumber}<br />Nome: ${name}</span>`,
                        status: 'primary', 
                        pos: 'top-center', 
                        timeout: App.notification.timeout 
                    })                        

                }).catch(function(err) {

                    var audio = new Audio('content/sounds/error.wav')
                    audio.play()

                    UIkit.modal.alert('<span class="ukid-font-size-15">Ocorreu um erro na tentativa de integração com https://sbx.beachpark.com.br</span>')

                })

            },

            detect(object) {

                /*
                 * This document 
                 */
                let _ = this

                /*
                 * This div's contains tha image and div bbox  
                 * The first image is shown on list, second is shown when user click on zoom
                 */
                let containerDefault = document.querySelector('#ukid-container-zoom-' + object.id)
                let imgDefault = document.querySelector('#ukid-image-face-zoom-' + object.id)

                if (imgDefault === null || !imgDefault.complete) {

                    setTimeout(function(){ _.detect(object) }, 10)

                    return

                }

                setTimeout(function() {

                    /*
                    * Canvas to image list and image zoom
                    */
                    let canvasDefault = document.createElement('canvas')
                    canvasDefault.width  = containerDefault.offsetWidth
                    canvasDefault.height = containerDefault.offsetHeight
                                    
                    /*
                    * Context to image list and image zoom
                    */
                    let ctxDefault = canvasDefault.getContext('2d')
                    ctxDefault.drawImage(imgDefault, 0, 0, containerDefault.offsetWidth, containerDefault.offsetHeight)
                    
                    canvasDefault.toBlob(blob => {
                        
                        var formData = new FormData()
                        formData.append('threshold', '0.65')                       
                        formData.append('photo', blob)

                        /* 
			* _.$http.post(App.host.concat('/client/pay/detect/multipart'), formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(function(response) {                     
                        *    _.bbox(containerDefault, response.data.bbox)
			* })
			*/

                    })

                }, 350)

            },

            bbox(container, object) {
                
                let _ = this

                container.querySelectorAll('div').forEach(e => e.parentNode.removeChild(e))

                let rect = document.createElement('div')

                rect.classList.add('rect')
                    
                rect.style.width  = (object.right - object.left) + 'px'
                rect.style.height = (object.bottom - object.top) + 'px'
                rect.style.left   = (container.offsetTop + object.left) + 'px'
                rect.style.top    = (container.offsetTop + object.top) + 'px'

                rect.style.borderColor = _.colorBox()
                
                container.appendChild(rect)

            },

            colorBox() {

                let colors = ['#fdecce','#F5EA87','#5CF77E','#3acff8','#7C5EC6','#ff33ff','#b366ff','#bb4462','#b0b036','#f49e0b']
            
                return colors[Math.floor((Math.random() * 10))]

            }            

        }

    }

</script>

<template id="proximity-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>
        
        <!-- Start Modal filters -->
        <div id="modal-devices" uk-modal>
            
            <div class="uk-modal-dialog uk-modal-body">
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Faces por Página</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Faces por Página</span><br />
                    <div class="uk-inline ukid-width-22-percent uk-visible@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="filters.imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-50-percent uk-hidden@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="filters.imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                </div>
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Tamhanho da Face</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Tamhanho da Face</span><br />
                    <div class="uk-inline ukid-width-50-percent">
                        <input class="uk-range" type="range" v-model="filters.imageSize" min="220" max="600" step="1"> {{filters.imageSize}}
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Tamhanho da Fonte</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Tamhanho da Fonte</span><br />
                    <div class="uk-inline ukid-width-50-percent">
                        <input class="uk-range" type="range" v-model="filters.fontSize" min="16" max="50" step="1"> {{filters.fontSize}}
                    </div>
                </div>                
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color">
                        Pesquisar Por Parâmetro
                        <br />
                        (id19)
                        <br />
                        <span class="ukid-font-size-9 ukid-icon-pink-color">
                            OBS:<br />
                             - Habilita "registros com problemas"<br /> 
                             - Desabilita "todas as detecções"<br />
                             - Desabilita "somente blc"<br />
                             - Isso vai proporcionar um resultado melhor
                        </span>
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-30-percent uk-visible@s">
                        <input type="text" v-model="filters.searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        <input type="text" v-model="filters.searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                </div>
                <div class="uk-margin">   
                    <span class="uk-label ukid-primary-label-color uk-visible@s">Faces Detectadas no Intervalo De:</span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">Faces Detectadas<br />no Intervalo De:</span><br /><br />
                    <div uk-grid>
                        <div class="uk-inline ukid-width-20-percent uk-visible@s">
                            DE:
                            <datetime
                            v-model="filters.startDate"
                            type="date"
                            input-class="mx-input ukid-width-100-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy"
                            :phrases="{}"
                            :hour-step="1"
                            :minute-step="5"
                            :week-start="7"
                            :auto="true"
                            :max-datetime="filters.endDate"
                            use24-hour></datetime>
                        </div>
                        <div></div>
                        <div class="uk-inline ukid-width-20-percent uk-visible@s">
                            ATÉ:
                            <datetime
                            v-model="filters.endDate"
                            type="date"
                            input-class="mx-input ukid-width-100-percent"
                            value-zone="America/Sao_Paulo"
                            zone="America/Sao_Paulo"
                            format="dd/MM/yyyy"
                            :phrases="{}"
                            :hour-step="1"
                            :minute-step="5"
                            :week-start="7"
                            :auto="true"
                            :max-datetime="new Date().toISOString()"
                            :min-datetime="filters.startDate"
                            use24-hour></datetime>
                        </div>
                    </div>
                   <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        DE:
                        <datetime
                        v-model="filters.startDate"
                        type="date"
                        input-class="mx-input ukid-width-100-percent"
                        value-zone="America/Sao_Paulo"
                        zone="America/Sao_Paulo"
                        format="dd/MM/yyyy"
                        :phrases="{}"
                        :hour-step="1"
                        :minute-step="5"
                        :week-start="7"
                        :auto="true"
                        :max-datetime="filters.endDate"
                        use24-hour></datetime>
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        <br />
                        ATÉ:
                        <datetime
                        v-model="filters.endDate"
                        type="date"
                        input-class="mx-input ukid-width-100-percent"
                        value-zone="America/Sao_Paulo"
                        zone="America/Sao_Paulo"
                        format="dd/MM/yyyy"
                        :phrases="{}"
                        :hour-step="1"
                        :minute-step="5"
                        :week-start="7"
                        :auto="true"
                        :max-datetime="new Date().toISOString()"
                        :min-datetime="filters.startDate"
                        use24-hour></datetime>
                    </div>
                </div>
		<div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Registros agrupados
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Registros agrupados
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input true-value="true" false-value="false" v-model="filters.blnJoin" class="ukid-current-date uk-checkbox" type="checkbox">
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Somente registros da data atual
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Somente registros da data atual
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @change="currentDateFilter()" true-value="true" false-value="false" v-model="filters.currentDate" class="ukid-current-date uk-checkbox" type="checkbox">
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Todas as detecções
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Todas as detecções
                    </span>                    
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @click="includePersonsNotDetected()" true-value="true" false-value="false" v-model="filters.personsNotDetected" class="uk-checkbox" type="checkbox">
                    </div>                                                                                                                   
                </div>
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Todas as detecções menos Recusadas
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Todas as detecções<br />menos Recusadas
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @click="includePersonsNotDetectedWithoutRefusals()" true-value="true" false-value="false" v-model="filters.personsNotDetectedWithoutRefusals" class="uk-checkbox" type="checkbox">
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Somente BLC
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Somente BLC
                    </span>                    
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @click="onlyBLC()" true-value="true" false-value="false" v-model="filters.blcDetected" class="uk-checkbox" type="checkbox">
                    </div>
                </div>
                <div class="uk-margin">
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Faces Recusadas
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Faces Recusadas
                    </span>
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @click="onlyRefusals()" true-value="true" false-value="false" v-model="filters.personsRefusals" class="uk-checkbox" type="checkbox">
                    </div>
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Registros com Problemas
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Registros com Problemas
                    </span>                    
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @click="includePersonsWithProblems()" true-value="true" false-value="false" v-model="filters.personsWithProblems" class="uk-checkbox" type="checkbox">
                    </div>
                </div>


              <div class="uk-margin" >
                  <span class="uk-label ukid-primary-label-color">Customers</span><br />
                  <multiselect v-model="customerSelected"
                               style="color: #444444 !important;"
                               :options="[ 'TODOS', 
					   ...customers.length > 0 ? customers.map(function(obj) { return obj.user.toUpperCase() }) : [] ] "
                               :searchable="true"
                               :close-on-select="true"
                               :show-labels="false"
                               placeholder="Selecione o customer"
			       @input="handleCustomerSelection">

                      <template slot="singleLabel" slot-scope="props">
                          <span class="multiselect__tag">
                              {{ props.option }} -> selecionado
                          </span>
                      </template>
                      <template slot="noResult">
                              <span>
                                  Nenhum customer encontrado
                              </span>
                      </template>
                      <template slot="noOptions">
                          <span>
                              Comece a digitar
                          </span>
                      </template>
                  </multiselect>
              </div>


 
               <span class="uk-label ukid-primary-label-color ukid-margin-bottom-10 uk-visible@s">
                    Escolha os dispositivos a serem assistidos:<br />
                    <span class="ukid-font-size-9 ukid-icon-pink-color">Exemplo: Camera em frente ao balcão principal</span><br/>                    
               </span>
               <span class="uk-label ukid-primary-label-color ukid-margin-bottom-10 uk-hidden@s ukid-font-size-10">
                    Escolha os dispositivos<br />a serem assistidos:<br />
                    <span class="ukid-font-size-9 ukid-icon-pink-color">Exemplo: Camera em frente<br />ao balcão principal</span><br/>                    
               </span>
                <div class="uk-panel uk-panel-scrollable">
                    <button @click="selectAllDevices()" class="uk-button uk-button-link ukid-font-size-9" type="button">Selecionar Tudo</button>
		    <button @click="desselectAllDevices()" class="uk-button uk-button-link ukid-font-size-9" type="button">Desmarcar Tudo</button>
                    <ul class="uk-list">
                        <li v-for="device in devices" :key="device.uuid">
                            <label>
                                <input :id="device.uuid" :value="device.uuid" v-model="filters.selectedDevices" class="uk-checkbox" type="checkbox"> {{device.type}} - {{device.meta}}<br />
                                <span class="ukid-font-size-10">{{device.uuid}}</span>
                                <span v-if="sub.indexOf('admin') > -1" class="ukid-font-size-10">
                                     - {{device.enabled ? 'Habilitado' : 'Desabilitado'}}
                                </span>
                            </label>
                        </li>                                              
                    </ul>
                </div>
                <p>
                    <span class="ukid-font-size-11 ukid-icon-pink-color">
                        OBS: Os dados de dispositivos são salvos no armazenamento do navegador<br /> 
                        e não na sessão do usuário, possibilitando o monitoramento em máquinas diferentes                                                
                    </span>                                        
                </p>
                <p class="uk-text-right uk-visible@s">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Não Salvar</button>
                    <button @click="saveSelectedDevices()" class="uk-button uk-button-primary uk-modal-close ukid-magin-left-5" type="button">Salvar</button>
                    <br />
                    <button @click="generateExcel()" class="uk-button uk-button-primary ukid-width-250-px ukid-margin-top-10" type="button">Gerar Excel</button>
                </p>
                <p class="uk-text-right uk-hidden@s">                    
                    <button class="uk-button uk-button-default uk-button-small uk-modal-close ukid-magin-right-5" type="button">Não Salvar</button>
                    <button @click="saveSelectedDevices()" class="uk-button uk-button-primary uk-button-small uk-modal-close ukid-magin-left-10" type="button">Salvar</button>
                    <br />
                    <button @click="generateExcel()" class="uk-button uk-button-primary uk-button-small ukid-width-200-px ukid-margin-top-10" type="button">Gerar Excel</button>
                </p>                
            </div>

        </div>
        <!-- End Modal filters -->

        <!-- Delete modal -->
        <div id="modal-overflow" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <button v-on:click="del()" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>
            </div>
        </div>

        <!-- Top of the layout proximity -->
        <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
            <div class="uk-card-header ukid-supress-border-bottom">
                <div class="ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50">
                    <a href="../" class="uk-navbar-item uk-logo ukid-white-color ukid-font-size-13">
                        <img src="content/images/unike-logo-un.png" width="120" class="uk-margin-small-right">
                    </a>
                </div>
                <div class="uk-grid-small uk-flex-middle ukid-min-height-60" uk-grid>                                    
                    <div class="uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:search;ratio:5.0"></span> 
                    </div>
                    <div class="uk-width-expand">                        
                        <span class="ukid-font-size-20 ukid-margin-top-40 uk-visible@s">Pessoas nas Proximidades</span>
                        <span class="ukid-font-size-16 ukid-margin-top-40 uk-hidden@s">Pessoas nas Proximidades</span>
                        <div class="uk-margin-remove-top">
                            <span class="uk-visible@s">                                
                                Módulo piloto para detecção de pessoas nas proximidades das câmeras RTSP
                                <br />
                                <div class="uk-label ukid-label-blue-color">
                                    Total de Pessoas Até a Data de Hoje: {{pagination.databaseCount}}
                                    <br />
                                    Total de Pessoas Cadastradas: {{pagination.totalKnown}}
                                    <br />
                                    Total de Pessoas Desconhecidas: {{pagination.totalUnknown}}
                                    <br />
                                    Total de Pessoas Recusadas: {{pagination.totalRefusals}}
                                    <br />
                                    Total de Pessoas de Acordo com os Filtros: {{pagination.total}}
                                </div>
                            </span>
                            <span class="uk-hidden@s ukid-font-size-13">
                                Módulo piloto para detecção de pessoas nas proximidades das câmeras RTSP
                                <br />
                                <div class="uk-label ukid-label-blue-color ukid-font-size-9">
                                    Total de Pessoas Até a Data de Hoje: {{pagination.databaseCount}}
                                    <br />
                                    Total de Pessoas Cadastradas: {{pagination.totalKnown}}
                                    <br />
                                    Total de Pessoas Desconhecidas: {{pagination.totalUnknown}}
                                    <br />
                                    Total de Pessoas Recusadas: {{pagination.totalRefusals}}
                                    <br />
                                    Total de Pessoas de Acordo com os Filtros: {{pagination.total}}
                                </div>
                            </span>
                            <p>Valor selecionado: {{ selectedForUnion }}</p>
                            <div class="uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15" align="right">
                                <a @click="setFilters()" uk-toggle="target: #modal-devices">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar
                                </a>
                            </div>
                            <div class="uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17" align="right">
                                <a @click="setFilters()" uk-toggle="target: #modal-devices">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar por Dispositivo
                                </a>
                            </div>                            
                            <button @click="setFilters()" uk-toggle="target: #modal-devices" class="uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                Filtrar por Dispositivo
                            </button>
                            <button @click="openFullScreen()" class="ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="tv"></span>
                                Tela Cheia
                            </button>
                            <button @click="closeFullScreen()" class="ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="close"></span>
                                Sair da Tela Cheia
                            </button>
                            <br />
                        </div>
                    </div>
                </div>
                <div class="ukid-font-size-14 ukid-icon-pink-color ukid-margin-top-10 uk-align-right ukid-margin-bottom-0">
                    Atenção aos filtros que você configurou
                </div>
            </div>

            <div class="uk-card-footer ukid-supress-border-top">

                <div class="uk-flex uk-flex-center ukid-padding-top-20">
                    <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
                        <div v-show="!registers.length" class="uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color" uk-alert>                            
                            <p class="ukid-white-color">
                                <span class="uk-margin-small-right uk-icon" uk-icon="warning"></span>
                                {{App.proximity.nobody}}
                            </p>
                        </div>                        

                        <!--For on all detected faces according to filters defined by final user -->
                        <div v-for="detectedFaces in registers" :key="detectedFaces.id" class="ukid-offcanvas-over">
                            
                            <!-- Modal show face zoom -->
                            <div :id="'modal-media-image-' + detectedFaces.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog ukid-zoom-photo uk-margin-auto-vertical">
                                    <button class="uk-modal-close-outside" type="button" uk-close></button>
                                    <div :id="'ukid-container-zoom-' + detectedFaces.id">
                                        <img crossorigin="Anonymous" :id="'ukid-image-face-zoom-' + detectedFaces.id" uk-tooltip :src="detectedFaces.snapshot" class="ukid-photo-effect-zoom" width="640" height="480" alt="">
                                    </div>
                                    <div class="uk-overlay uk-light uk-position-bottom" :style="'font-size:'+filters.fontSize+'px !important'">
                                        <p>
                                            <span v-if="detectedFaces.documentNumber">Docto : {{detectedFaces.documentNumber}}</span><br />
                                            <span v-if="detectedFaces.name"> Nome : {{detectedFaces.name}}</span><br />
                                            <span v-if="detectedFaces.email"> Email&nbsp;&nbsp;: {{detectedFaces.email}}</span><br />
                                            <span v-if="detectedFaces.updateTimestamp"> Data&nbsp;&nbsp;&nbsp;: {{new Date(detectedFaces.updateTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><br />
                                            <span v-if="detectedFaces.maskDetection"> 
                                                Máscara&nbsp;: {{detectedFaces.maskDetection[0].confidence * 100}}% 
                                                <span v-if="detectedFaces.maskDetection[0].name == 'none'">não está usando</span>
                                                <span v-if="detectedFaces.maskDetection[0].name == 'correct'">usando corretamente</span>
                                                <span v-if="detectedFaces.maskDetection[0].name == 'incorrect'">usando incorretamente</span>
                                            </span>
                                            <br />

                                            <span v-if="detectedFaces.captures[detectedFaces.captures.length - 1].personAttributes.headpose != null">
                                                HeadPose&nbsp;: PITCH = {{detectedFaces.captures[detectedFaces.captures.length - 1].personAttributes.headpose.result.pitch}} ROLL = {{detectedFaces.captures[detectedFaces.captures.length - 1].personAttributes.headpose.result.roll}} YAW = {{detectedFaces.captures[detectedFaces.captures.length - 1].personAttributes.headpose.result.yaw}}
                                            </span>
                                        </p>
                                    </div>                                    
                                </div>
                            </div>

                            <!-- Modal event camera device -->
                            <div :id="'modal-media-emotions-' + detectedFaces.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                                    <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>    
                                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                                            <span class="uk-margin-small-right uk-icon" uk-icon="icon:heart;ratio:0.8"></span>
                                            Matriz de Emoções
                                        </span>
                                        <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                            Sentimentos
                                        </span>
                                        <br />
                                        <div class="uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px">
                                            <ul class="uk-list ukid-margin-top-0 ukid-font-size-12">
                                                {{detectedFaces.captures[detectedFaces.captures.length - 1].personAttributes.emotion.name}} -> {{parseFloat(detectedFaces.captures[detectedFaces.captures.length - 1].personAttributes.emotion.score * 100).toFixed(4) | numberFormat('00.00')}}%
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="uk-modal-footer uk-text-right">
                                        <button class="uk-button uk-button-default uk-modal-close" type="button">Fechar</button>
                                    </div>
                                </div>
                            </div>

                            <!-- Modal debug -->
                            <div :id="'modal-media-debug-' + detectedFaces.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                                    <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                                        <span class="uk-label ukid-primary-label-color uk-visible@s">
                                            <span class="uk-margin-small-right uk-icon" uk-icon="icon:hashtag;ratio:0.8"></span>
                                            Debug
                                        </span>
                                        <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-12">
                                            Debug
                                        </span>
                                        <br />
                                        <div class="uk-panel uk-panel-scrollable ukid-width-500-px ukid-height-300-px">
                                            <ul class="uk-list ukid-margin-top-0 ukid-font-size-12">
						<li>
						<span v-if="detectedFaces.reasonRefusals.length === 0 || detectedFaces.reasonRefusals == null">
                                                    <img :src="'data:image/png;base64,' + detectedFaces.person.principalCrop.base64Crop" class="ukid-height-150-px"/>&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
						    <img :src="'data:image/png;base64,' + detectedFaces.firstCropBase64" class="ukid-height-150-px"/>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <img :src="'data:image/png;base64,' + detectedFaces.cropBase64" class="ukid-height-150-px"/> <br /><br />
						
						    <b>---Atributos da Detecção---</b><br /><br />
						    <span v-if="detectedFaces.person && detectedFaces.person.firstDetect && detectedFaces.person.firstDetect.timestamp" > firstDetect: {{new Date(detectedFaces.person.firstDetect.timestamp * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><br />
                            <span v-if="detectedFaces.person && detectedFaces.person.lastDetect && detectedFaces.person.lastDetect.timestamp" > lastDetect: {{new Date(detectedFaces.person.lastDetect.timestamp * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><br />
						    <span v-if="detectedFaces.person.principalCrop"> principalTimestamp: {{new Date(detectedFaces.person.principalCrop.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><br />
                                                    firstSnapshot: <span v-if="detectedFaces.firstSnapshotDate && detectedFaces.firstSnapshot"><a :href="detectedFaces.firstSnapshot" target="_blank">clique aqui</a> - {{new Date(detectedFaces.firstSnapshotDate * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><span v-else>sem imagem</span><br />
                                                    lastSnapshot: <span v-if="detectedFaces.lastSnapshotDate && detectedFaces.snapshot"><a :href="detectedFaces.snapshot" target="_blank">clique aqui</a> - {{new Date(detectedFaces.lastSnapshotDate * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><span v-else>sem imagem</span><br />
						</li>
                                                <li v-for="rf in detectedFaces.reasonRefusals" :key="rf.id">
						    <span>snapshot: <span v-if="detectedFaces.snapshot"><a :href="detectedFaces.snapshot" target="_blank">clique aqui</a></span><span v-else>sem imagem</span></span><br /><br />
                                                    <b>---Motivo da Recusa---</b><br /><br />
                                                    <span>type: {{rf.type}}</span><br />
                                                    <span>description: {{rf.description}}</span><br />
                                                    <span>cropESRate: {{rf.cropESRate}}</span><br />
                                                    <span>quality: {{rf.quality}}</span><br />
                                                    <span>cropWidth: {{rf.cropWidth}}</span> - <span>cropHeight: {{rf.cropHeight}}</span><br />
                                                    <span v-if="detectedFaces.camId">câmera: {{detectedFaces.camId}}</span>
                                                </li>
                                                <li v-if="detectedFaces.person && detectedFaces.person.principalCrop">
                                                    <b>---Registro Principal ---</b><br /><br />
						    id19 Parent: <span v-if="detectedFaces.person.id19Parent" > {{detectedFaces.person.id19Parent}} <br /> </span>
                                                    <span>id19: {{detectedFaces.id19}}</span><br />
						    Age: <span v-if="detectedFaces.person.age" > {{detectedFaces.person.age.attributeValue}} ( {{ detectedFaces.person.age.perc !== null ? detectedFaces.person.age.perc.toFixed(4) : 'N/A' }} ) <br /> </span>
						    Gender: <span v-if="detectedFaces.person.gender" > {{detectedFaces.person.gender.attributeValue}} ( {{ detectedFaces.person.gender.perc !== null ? detectedFaces.person.gender.perc.toFixed(4) : 'N/A' }} ) <br /> </span>
					            Principal Store: <span v-if="detectedFaces.person.principalStore" >{{detectedFaces.person.principalStore.storeName}} </span><br />
                                Qtt Detect: <span v-if="detectedFaces.person.age.qttDetect" >{{detectedFaces.person.age.qttDetect}} </span> - Qtt Days: <span v-if="detectedFaces.person.principalStore.sumDetect" >{{detectedFaces.person.principalStore.sumDetect}} </span>    <br />
						    Work: <br />
						    <span v-for="work in detectedFaces.person.work" >
						    	<span v-if="work" >Start Date: {{new Date(work.startDate) | dateFormat('DD/MM/YYYY HH:mm:ss')}} - End Date: <span v-if="work.endDate"> {{new Date(work.endDate) | dateFormat('DD/MM/YYYY HH:mm:ss')}} </span>  <br /> </span>
						    </span>
						    <!--
                                                    <span>CropImage: <span v-if="detectedFaces.person.principalCrop.cropImage"><a :href="detectedFaces.person.principalCrop.cropImage" target="_blank">clique aqui</a></span><span v-else>sem imagem</span></span><br />
                                                    -->
                                                    <span>CropESRate: {{detectedFaces.person.principalCrop.cropESRate}}</span><br />
						                            <span>Snapshot: <span v-if="detectedFaces.person.principalCrop.snapshot"><a :href="detectedFaces.person.principalCrop.snapshot" target="_blank">clique aqui</a></span><span v-else>sem imagem</span></span><br />
                                                    <span>CropQuality: {{detectedFaces.person.principalCrop.cropQuality}}</span><br />
                                                    <span>CropHeadPose: pitch {{detectedFaces.person.principalCrop.cropHeadPose.result.pitch}} roll {{detectedFaces.person.principalCrop.cropHeadPose.result.roll}} yaw {{detectedFaces.person.principalCrop.cropHeadPose.result.yaw}}</span><br />
                                                    <span>CropWidth: {{detectedFaces.person.principalCrop.cropWidth}}</span> - <span>CropHeight: {{detectedFaces.person.principalCrop.cropHeight}}</span><br />                                                    
                                                    <span>câmera: {{detectedFaces.person.principalCrop.cameraCrop.id ? detectedFaces.person.principalCrop.cameraCrop.id : '-' }}</span><br />
                                                    <span>createCropTimestamp: {{new Date(detectedFaces.person.createTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span><br />
                                                    <span>updateCropTimestamp: {{new Date(detectedFaces.person.principalCrop.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}</span>
                                                </li>
						<li>
						   Qtt Captures: {{detectedFaces.captures.length}}
						</li>
                                                <li v-for="cp in detectedFaces.captures">
                                                    <span v-if="cp.personAttributes">
                                                        <b>---Array de Capturas---</b><br /><br />
                                                        timestamp: {{new Date(cp.timestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}<br />
                                                        cropDebug: <span v-if="cp.personAttributes.cropDebug"><a :href="cp.personAttributes.cropDebug" target="_blank">clique aqui</a></span><span v-else>sem imagem</span><br />
                                                        snapDebug: <span v-if="cp.personAttributes.snapDebug"><a :href="cp.personAttributes.snapDebug" target="_blank">clique aqui</a></span><span v-else>sem imagem</span><br />

                            <span v-if="cp.personAttributes.crop || cp.personAttributes.cropESRate"> cropESRate: {{cp.personAttributes.crop ? cp.personAttributes.crop.cropESRate : cp.personAttributes.cropESRate}}</span><br />
                            confidence: {{cp.personAttributes.confidence}}<br />
                            <span v-if="cp.personAttributes.crop || cp.personAttributes.cropQuality"> cropQuality: {{cp.personAttributes.crop ? cp.personAttributes.crop.cropQuality : cp.personAttributes.cropQuality}}</span><br />
                            <span v-if="cp.personAttributes.age"> age: {{cp.personAttributes.age}}</span><br />
							<span v-if="cp.personAttributes.emotion"> emotion: {{cp.personAttributes.emotion.name}} - {{cp.personAttributes.emotion.score}}</span><br />
							<span v-if="cp.personAttributes.gender"> gender: {{cp.personAttributes.gender.name}} - {{cp.personAttributes.gender.score}}</span><br />
							<span v-if="cp.personAttributes.beard"> beard: {{cp.personAttributes.beard.name}} - {{cp.personAttributes.beard.score}}</span><br />
							<span v-if="cp.personAttributes.glasses"> glasses: {{cp.personAttributes.glasses.name}} - {{cp.personAttributes.glasses.score}}</span><br />
							<span v-if="cp.personAttributes.medmask"> medmask: {{cp.personAttributes.medmask.name}} - {{cp.personAttributes.medmask.score}}</span><br />
							<span v-if="cp.personAttributes.crop || cp.personAttributes.cropWidth"> cropWidth: {{cp.personAttributes.crop ? cp.personAttributes.crop.cropWidth : cp.personAttributes.cropWidth}}</span> - 
                            <span v-if="cp.personAttributes.crop || cp.personAttributes.cropHeight"> cropHeight: {{cp.personAttributes.crop ? cp.personAttributes.crop.cropHeight : cp.personAttributes.cropHeight}}</span><br />
							<span v-if="cp.personAttributes.headpose != null">
    								headpose: pitch {{cp.personAttributes.headpose.result.pitch}} roll {{cp.personAttributes.headpose.result.roll}} yaw {{cp.personAttributes.headpose.result.yaw}}<br />
							</span>
							
                                                        
							userConfidence: {{cp.personAttributes.userConfidence}}<br />
                                                        
                                                        
                                                        cameraId: {{cp.captureAttributes.camId}}<br />
							cameraName: {{cp.captureAttributes.camName}}<br />

							startProcess: <span v-if="cp.personAttributes.startProcess"> {{new Date(cp.personAttributes.startProcess * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}.{{ cp.personAttributes.startProcess.substring(cp.personAttributes.startProcess.length - 3) }} </span> <br />
                                                        endDetect: <span v-if="cp.personAttributes.endDetect"> {{new Date(cp.personAttributes.endDetect * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}.{{ cp.personAttributes.endDetect.substring(cp.personAttributes.endDetect.length - 3) }} </span>   <br />
                                                        endProcess: <span v-if="cp.personAttributes.endProcess"> {{new Date(cp.personAttributes.endProcess * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}.{{ cp.personAttributes.endProcess.substring(cp.personAttributes.endProcess.length - 3) }} </span>  <br />
                                                        <!-- startProcess: {{new Date(cp.personAttributes.startProcess * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}.{{ cp.personAttributes.startProcess.substring(cp.personAttributes.startProcess.length - 3) }} <br />
                                                        endDetect: {{new Date(cp.personAttributes.endDetect * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}.{{ cp.personAttributes.endDetect.substring(cp.personAttributes.endDetect.length - 3) }} <br />
                                                        endProcess: {{new Date(cp.personAttributes.endProcess * 1) | dateFormat('DD/MM/YYYY HH:mm:ss')}}.{{ cp.personAttributes.endProcess.substring(cp.personAttributes.endProcess.length - 3) }} <br />
							-->
                                                        detectionTime: {{ (cp.personAttributes.endDetect - cp.personAttributes.startProcess) }} ms <br />
                                                        processingTime: {{ (cp.personAttributes.endProcess - cp.personAttributes.startProcess) }} ms                                                    
						     </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="uk-modal-footer uk-text-right">
                                        <button class="uk-button uk-button-default uk-modal-close" type="button">Fechar</button>
                                    </div>
                                </div>
                            </div>

                            <!-- Show mode of demo and production -->
                            <div v-if="!$route.query.hasOwnProperty('debug')" :class="'uk-card uk-card-default uk-card-bod ukid-width-'+filters.imageSize+'-px'">
                                <div align="center" class="ukid-padding-5px">
                                    <table class="ukid-font-size-17 ukid-cursor-pointer" :width="filters.imageSize - 40">
                                        <tbody>
                                            <tr>
                                                <!-- Condition per cctv camera -->
                                                <td align="left">
						    <input :value="detectedFaces.id19" v-model="selectedForUnion" class="uk-checkbox" type="checkbox">
                                                    <!-- Images -->
                                                    <div v-if="detectedFaces.id19 === 'unknown_refusal'"
                                                         class="ukid-circular-person-edit ukid-label-alert-color">

                                                        <img :src="'data:image/png;base64,' + detectedFaces.cropBase64" class="ukid-circular-portrait" />

                                                    </div>
                                                    <div v-else-if="
                                                                detectedFaces.id19[0] !== '4'
                                                                "
                                                        class="ukid-circular-person-edit ukid-label-green-color">

                                                        <img :src="loggedUser && loggedUser.showRegistrationImageOnDetection === true ? detectedFaces.snapshot : 'data:image/png;base64,' + detectedFaces.cropBase64" class="ukid-circular-portrait" />
                                                        
                                                    </div>
                                                    <div v-else-if="
                                                                detectedFaces.id19[0] === '4'
                                                                "
                                                        class="ukid-circular-person-edit ukid-label-gray-color">

                                                        <img :src="'data:image/png;base64,' + detectedFaces.cropBase64" class="ukid-circular-portrait" />

                                                    </div>
                                                </td>
                                            </tr>                                        
                                        </tbody>
                                    </table>                                    
                                    <table width="200" :style="'font-size:'+filters.fontSize+'px !important'">
                                        <tbody>
                                            <tr>
                                                <td v-if="detectedFaces.id19 === 'unknown_refusal'"
                                                    :style="{paddingLeft:'0px'}"
                                                    class="ukid-icon-yellow-color ukid-capitalize"
                                                    align="center">
                                                    {{new Date(detectedFaces.updateTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}<br />
                                                    {{detectedFaces.id19.toLowerCase()}}
                                                    {{detectedFaces.camName}}

                                                </td>
                                                <td v-else class="ukid-white-color ukid-capitalize" align="center">
						     <span :style="{paddingLeft:'0px'}">
						        {{new Date(detectedFaces.updateTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                                     </span>
 					  	     <span :style="{paddingLeft:'0px'}">
                                                        {{detectedFaces.person.id19Parent ? detectedFaces.person.id19Parent : '' }} <br />
                                                     </span>
						     <span :style="{paddingLeft:'0px'}">
                                                        {{detectedFaces.id19[0] === '4' || detectedFaces.id19[0] === '9' ? 'Desconhecido' : detectedFaces.person.name.toLowerCase()}} <br />
                                                     </span>
                                                    <span :style="{paddingLeft:'0px'}">
                                                         {{detectedFaces.camName ? detectedFaces.camName : detectedFaces.camId}}  <br />
                                                     </span>
                                                     <span :style="{paddingLeft:'0px'}">
                                                        Qtt Captures: {{detectedFaces.captures.length}}
                                                     </span>
                                                </td>
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                    <div class="ukid-padding-7px ukid-icon-blue-color ukid-margin-negative-top-10">
                               	       <div :style="{paddingLeft: '10px'}">
                                            <a @click="detect(detectedFaces)" title="Ampliar a imagem" uk-tooltip :href="'#modal-media-image-' + detectedFaces.id" class="ukid-icon-blue-color ukid-magin-right-10 ukid-margin-top-3" uk-toggle>
                                                <span class="uk-margin-small-right uk-icon" uk-icon="icon:image;ratio:0.98"></span>
                                            </a>
                                            <a :href="'#modal-media-debug-' + detectedFaces.id" v-if="loggedUser" uk-toggle>
                                                <span title="Verificar Debug" uk-tooltip class="ukid-icon-blue-color ukid-magin-left-15" uk-icon="icon:hashtag;ratio:1.2"></span>
                                            </a>
                                        </div>
				    </div>	
				 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div align="center">
                <div v-show="pagination.last_page > 1" class="uk-text-uppercase ukid-font-size-20">Pagina Atual: <b>{{page}}</b></div>
                <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10" uk-margin>
                    <paginate
                        v-model="page"
                        :page-count="pagination.last_page"
                        :click-handler="list"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-10'">
                    </paginate>
                </ul>                
                <span 
                    uk-margin
                    uk-tooltip 
                    v-show="pagination.last_page > 1 && enableAutoList" 
                    title="Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui"  
                    @click="enableInterval()" 
                    class="ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20" 
                    uk-icon="icon:play-circle;ratio:2.7"></span>
            </div>

        </div> 
    </div>

</template>
