var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "span",
      {
        staticClass:
          "ukid-icon-purple-color ukid-margin-top-40 ukid-font-size-25"
      },
      [_vm._v("\n        Ponto e Comunicação com RH\n    ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has("enabled-input_field"),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "workshift-type-input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.workShift,
                    expression: "user.workShift"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "workshift-type-input-yes_field",
                  name: "workshift-type-input_field"
                },
                domProps: { checked: _vm._q(_vm.user.workShift, "true") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.user, "workShift", "true")
                  }
                }
              }),
              _vm._v(" SIM\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.workShift,
                    expression: "user.workShift"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "workshift-type-input-no_field",
                  name: "workshift-type-input_field",
                  checked: ""
                },
                domProps: { checked: _vm._q(_vm.user.workShift, "false") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.user, "workShift", "false")
                  }
                }
              }),
              _vm._v(" NÃO\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "uk-inline ukid-width-100-percent" }, [
        _c(
          "div",
          {
            class: {
              "ukid-width-100-percent": _vm.errors.has("enabled-input_field"),
              "uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent": !_vm.errors.has(
                "afd-cron-type-input_field"
              )
            }
          },
          [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.generateAFD,
                    expression: "user.generateAFD"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "true",
                  type: "radio",
                  id: "afd-cron-type-input-yes_field",
                  name: "afd-cron-type-input_field"
                },
                domProps: { checked: _vm._q(_vm.user.generateAFD, "true") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.user, "generateAFD", "true")
                  }
                }
              }),
              _vm._v(" SIM\n                ")
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.generateAFD,
                    expression: "user.generateAFD"
                  }
                ],
                staticClass: "uk-radio",
                attrs: {
                  value: "false",
                  type: "radio",
                  id: "afd-cron-type-input-no_field",
                  name: "afd-cron-type-input_field",
                  checked: ""
                },
                domProps: { checked: _vm._q(_vm.user.generateAFD, "false") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.user, "generateAFD", "false")
                  }
                }
              }),
              _vm._v(" NÃO\n                ")
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.serverUrlUploadAfd,
            expression: "user.serverUrlUploadAfd"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "url|min:10|max:300",
            expression: "'url|min:10|max:300'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "server-url-afd-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "server-url-afd-select_field"
          )
        },
        attrs: {
          "c-data-vv-as": "field",
          id: "server-url-afd-select_field",
          name: "server-url-afd-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 300"
        },
        domProps: { value: _vm.user.serverUrlUploadAfd },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "serverUrlUploadAfd", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-margin",
        scopedSlots: _vm._u([
          {
            key: "tag",
            fn: function(ref) {
              var option = ref.option
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "multiselect__tag uk-text-center ukid-width-270-px"
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(option) +
                          "\n                    "
                      )
                    ])
                  ]
                )
              ]
            }
          }
        ])
      },
      [
        _c(
          "span",
          {
            staticClass:
              "uk-label ukid-primary-label-color ukid-font-size-10 uk-hidden@s"
          },
          [_vm._v("\n            Enviar Arquivos AFD Para:\n        ")]
        ),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _c("multiselect", {
          attrs: {
            options: _vm.emails,
            multiple: true,
            taggable: true,
            selectLabel: "Enter para marcar",
            deselectLabel: "Enter para desmarcar",
            selectedLabel: "Email marcado",
            "tag-placeholder": "Adicionar email",
            placeholder: "Adicionar email"
          },
          on: { tag: _vm.addEmail },
          model: {
            value: _vm.user.selectedAfdEmails,
            callback: function($$v) {
              _vm.$set(_vm.user, "selectedAfdEmails", $$v)
            },
            expression: "user.selectedAfdEmails"
          }
        }),
        _vm._v(" "),
        _c("template", { slot: "noResult" }, [
          _c("span", [
            _vm._v(
              "\n                    Nenhuma email encontrado\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c("template", { slot: "noOptions" }, [
          _c("span", [
            _vm._v("\n                    Comece a digitar\n                ")
          ])
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "uk-margin" },
      [
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _c("ckeditor", {
          attrs: { editor: _vm.editor, config: _vm.editorConfig },
          model: {
            value: _vm.user.emailMessageAfd,
            callback: function($$v) {
              _vm.$set(_vm.user, "emailMessageAfd", $$v)
            },
            expression: "user.emailMessageAfd"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("hr", { staticClass: "uk-divider-icon" }),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass:
          "ukid-icon-purple-color ukid-margin-top-40 ukid-font-size-25"
      },
      [_vm._v("\n        Comunicação com Funcionário\n    ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(9),
      _vm._v(" "),
      _vm._m(10),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.emailTitleMarking,
            expression: "user.emailTitleMarking"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:10|max:100",
            expression: "'required|min:10|max:100'"
          }
        ],
        class: {
          "ukid-form-error uk-input ukid-width-100-percent": _vm.errors.has(
            "email-title-marking-select_field"
          ),
          "uk-input ukid-width-100-percent": !_vm.errors.has(
            "email-title-marking-select_field"
          )
        },
        attrs: {
          "c-data-vv-as": "field",
          id: "email-title-marking-select_field",
          name: "email-title-marking-select_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 1000"
        },
        domProps: { value: _vm.user.emailTitleMarking },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "emailTitleMarking", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "uk-margin" },
      [
        _vm._m(12),
        _vm._v(" "),
        _vm._m(13),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._m(14),
        _vm._v(" "),
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _c("ckeditor", {
          attrs: { editor: _vm.editor, config: _vm.editorConfig },
          model: {
            value: _vm.user.emailMessageMarking,
            callback: function($$v) {
              _vm.$set(_vm.user, "emailMessageMarking", $$v)
            },
            expression: "user.emailMessageMarking"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin" }, [
      _vm._m(15),
      _vm._v(" "),
      _vm._m(16),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._m(17),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user.whatsappMessageMarking,
            expression: "user.whatsappMessageMarking"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:10|max:2000",
            expression: "'required|min:10|max:2000'"
          }
        ],
        class: {
          "ukid-form-error uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px": _vm.errors.has(
            "whatsapp-marking_input_field"
          ),
          "uk-textarea ukid-focus ukid-width-100-percent ukid-margin-top-10 ukid-height-200-px": !_vm.errors.has(
            "whatsapp-marking_input_field"
          )
        },
        attrs: {
          wrap: "physical",
          "c-data-vv-as": "field",
          id: "whatsapp-marking_input_field",
          name: "whatsapp-marking_input_field",
          type: "text",
          placeholder: "Mínimo 10, máximo 2000"
        },
        domProps: { value: _vm.user.whatsappMessageMarking },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.user, "whatsappMessageMarking", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.user.username === _vm.vm.user.sub
      ? _c("hr", { staticClass: "uk-divider-icon" })
      : _vm._e(),
    _vm._v(" "),
    _vm.user.username === _vm.vm.user.sub
      ? _c(
          "span",
          {
            staticClass:
              "ukid-icon-purple-color ukid-margin-top-40 ukid-font-size-25"
          },
          [_vm._v("\n        Pontos Gerados\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.markings !== null && _vm.markings.length > 0
      ? _c("div", { staticClass: "ukid-margin-top-20" }, [
          _c("table", { staticClass: "uk-table uk-table-small" }, [
            _vm._m(18),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  [
                    _vm._v(
                      "\n                        Data Inicio\n                        "
                    ),
                    _c("datetime", {
                      staticStyle: { width: "160px !important" },
                      attrs: {
                        name: "start-date-input_field",
                        type: "datetime",
                        "input-class": "mx-input ukid-width-100-percent",
                        "value-zone": "America/Sao_Paulo",
                        zone: "America/Sao_Paulo",
                        format: "dd/MM/yyyy HH:mm",
                        phrases: { ok: "Definir Hora", cancel: "Sair" },
                        "hour-step": 1,
                        "minute-step": 10,
                        "max-datetime": new Date().toISOString(),
                        auto: true
                      },
                      model: {
                        value: _vm.generateAfdFilter.startDate,
                        callback: function($$v) {
                          _vm.$set(_vm.generateAfdFilter, "startDate", $$v)
                        },
                        expression: "generateAfdFilter.startDate"
                      }
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n                        Data Fim\n                        "
                    ),
                    _c("datetime", {
                      staticStyle: { width: "160px !important" },
                      attrs: {
                        name: "end-date-input_field",
                        type: "datetime",
                        "input-class": "mx-input ukid-width-100-percent",
                        "value-zone": "America/Sao_Paulo",
                        zone: "America/Sao_Paulo",
                        format: "dd/MM/yyyy HH:mm",
                        phrases: { ok: "Definir Hora", cancel: "Sair" },
                        "hour-step": 1,
                        "minute-step": 1,
                        "max-datetime": new Date().toISOString(),
                        auto: true
                      },
                      model: {
                        value: _vm.generateAfdFilter.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.generateAfdFilter, "endDate", $$v)
                        },
                        expression: "generateAfdFilter.endDate"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { attrs: { align: "left", colspan: "2" } }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "uk-button uk-button-default uk-button-large ukid-magin-left-5",
                      attrs: { type: "buttom" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.downloadMarkFile(
                            null,
                            _vm.generateAfdFilter.startDate,
                            _vm.generateAfdFilter.endDate
                          )
                        }
                      }
                    },
                    [_vm._v("Baixar")]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "uk-table uk-table-small uk-table-striped" },
            [
              _vm._m(19),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.markings, function(marking) {
                  return _c("tr", { key: marking.id }, [
                    _c(
                      "td",
                      { staticClass: "uk-table-expand uk-text-center" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                new Date(marking.timestamp),
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            ) +
                            "\n                        "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        " +
                            _vm._s(marking.name) +
                            " \n                        "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "ukid-text-alert-color ukid-cursor-pointer ukid-magin-left-6",
                          attrs: {
                            title: "Baixar Arquivo",
                            "uk-tooltip": "",
                            "uk-icon": "icon:download;ratio:1.0"
                          },
                          on: {
                            click: function($event) {
                              return _vm.downloadMarkFile(marking, null, null)
                            }
                          }
                        })
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.markingsPagination.last_page > 1,
                  expression: "markingsPagination.last_page > 1"
                }
              ],
              staticClass:
                "uk-flex uk-flex-center uk-text-uppercase ukid-font-size-16"
            },
            [
              _vm._v("\n            Pagina Atual: "),
              _c("b", { staticClass: "ukid-magin-left-10" }, [
                _vm._v(_vm._s(_vm.markingPage))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.markingsPagination.last_page > 1,
                  expression: "markingsPagination.last_page > 1"
                }
              ],
              staticClass:
                "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-30",
              attrs: { "uk-margin": "" }
            },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.markingsPagination.last_page,
                  "click-handler": _vm.listMarkings,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class":
                    "ukid-pagination uk-flex-center page-link ukid-margin-bottom-30"
                },
                model: {
                  value: _vm.markingPage,
                  callback: function($$v) {
                    _vm.markingPage = $$v
                  },
                  expression: "markingPage"
                }
              })
            ],
            1
          )
        ])
      : _vm.user.username === _vm.vm.user.sub
      ? _c("div", [_vm._v("\n        Nunhum arquivo AFD gerado\n    ")])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _vm._v(
          "\n            Habilitar Preenchimento de Turno no Cadastro de Pessoa\n            "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
          _vm._v(
            "\n                Utilize essa opção para sistemas de marcação de ponto\n                "
          ),
          _c("br"),
          _vm._v(
            "\n                Geralmente utilizado para sistemas de ponto por CFTV\n            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n            Habilitar Preenchimento de\n            "),
        _c("br"),
        _vm._v("\n            Turno no Cadastro de Pessoa\n            "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
          _vm._v("\n                Utilize essa opção para\n                "),
          _c("br"),
          _vm._v(
            "\n                sistemas de marcação de ponto\n                "
          ),
          _c("br"),
          _vm._v(
            "\n                Geralmente utilizado para\n                "
          ),
          _c("br"),
          _vm._v("\n                sistemas de ponto por CFTV\n            ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _vm._v("\n            Habilitar Geração De Arquivo AFD\n            "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
          _vm._v(
            "\n                Utilize essa opção para sistemas de marcação de ponto\n            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _vm._v("\n            Habilitar Geração de\n            "),
        _c("br"),
        _vm._v("\n            Arquivo AFD\n            "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-9" }, [
          _vm._v("\n                Utilize essa opção para\n                "),
          _c("br"),
          _vm._v(
            "\n                sistemas de marcação de ponto\n            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:server;ratio:1.2" }
        }),
        _vm._v("\n            URL de envio do AFD:\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:server;ratio:1.2" }
        }),
        _vm._v("\n            URL de envio do AFD:\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color ukid-font-size-12 uk-visible@s"
      },
      [
        _vm._v("\n            Enviar Arquivos AFD Para:\n            "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "ukid-text-alert-color ukid-font-size-10" }, [
          _vm._v(
            "\n                Os arquivos AFD serão disparados para\n                "
          ),
          _c("br"),
          _vm._v(
            "\n                os emails configurados no campo abaixo\n            "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v(
          "\n            Mensagem de Recebimento de Arquivo AFD por Email\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Mensagem de Recebimento"),
        _c("br"),
        _vm._v("de Arquivo AFD por Email\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Título do Email da Marcação de Ponto\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Título do Email da"),
        _c("br"),
        _vm._v("Marcação de Ponto\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do funcionário"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome do local onde o ponto foi batido"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{timestamp}")]),
      _vm._v(" - Será substituído pela data do ponto"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v(
          "\n            Mensagem de Marcação de Ponto Por Email\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:mail;ratio:1.2" }
        }),
        _vm._v("\n            Mensagem de Marcação"),
        _c("br"),
        _vm._v("de Ponto Por Email\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do funcionário"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{camera}")]),
      _vm._v(" - Será substituído pela última câmera de detecção"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome do local onde o ponto foi batido"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{timestamp}")]),
      _vm._v(" - Será substituído pela data e hora do ponto"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:whatsapp;ratio:1.2" }
        }),
        _vm._v(
          "\n            Mensagem de Marcação de Ponto por Whatsapp\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "uk-label ukid-primary-label-color uk-hidden@s" },
      [
        _c("span", {
          staticClass: "ukid-icon-tuscan-color",
          attrs: { "uk-icon": "icon:whatsapp;ratio:1.2" }
        }),
        _vm._v("\n            Mensagem de Marcação"),
        _c("br"),
        _vm._v("de Ponto por Whatsapp\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ukid-font-size-10" }, [
      _c("b", [_vm._v("Chaves de Substituição:")]),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{name}")]),
      _vm._v(" - Será substituído pelo nome do funcionário"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{camera}")]),
      _vm._v(" - Será substituído pela última câmera de detecção"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{inviting}")]),
      _vm._v(" - Será substituído pelo nome do local onde o ponto foi batido"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("{timestamp}")]),
      _vm._v(" - Será substituído pela data e hora do ponto"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "uk-width-small uk-text-left ukid-text-alert-color" },
          [
            _vm._v(
              "\n                        Gerar uma arquivo AFD manualmente\n                        "
            ),
            _c("br"),
            _vm._v(
              "\n                        Não será gravado, somente download\n                    "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "uk-width-small uk-text-center" }, [
          _vm._v(
            "\n                        Data / Arquivo / Download\n                    "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5f2f107f", { render: render, staticRenderFns: staticRenderFns })
  }
}