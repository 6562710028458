<!--suppress JSUnresolvedVariable, JSUnresolvedFunction, SpellCheckingInspection, HtmlUnknownAttribute, HtmlDeprecatedAttribute, HtmlUnknownTag, RegExpRedundantEscape, RegExpSimplifiable, RegExpUnnecessaryNonCapturingGroup-->

<script>

export default {

    inject: ['$validator'],

    props: ['app', 'strings', 'commons', 'body', 'vm'],

    computed: {
        router_headers: {
            get() {
                return this.body.stream_settings.router_headers.join(';').replace(/[\;]/g, "\n")
            },
            set(value) {
                this.body.stream_settings.router_headers = value.replace(/(?:\r\n|\r|\n)/g, ";").split(';')
            }
        },
        router_body: {
            get() {
                return this.body.stream_settings.router_body.join(';').replace(/[\;]/g, "\n")
            },
            set(value) {
                this.body.stream_settings.router_body = value.replace(/(?:\r\n|\r|\n)/g, ";").split(';')
            }
        }
    }

}

</script>

<template id="camera-gpu-template">

    <div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    ffmpeg_format
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-ffmpeg-format-1'" :uk-toggle="'target: #ukid-plus-animation-ffmpeg-format-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-ffmpeg-format-1">
                    Não preencha se não<br />
                    tiver certeza do que está fazendo<br />
                    Passe o formato FFMPEG (mxg, flv, etc.)<br />
                    se não puder ser detectado automaticamente<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    ffmpeg_format
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-ffmpeg-format-2'" :uk-toggle="'target: #ukid-plus-animation-ffmpeg-format-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-ffmpeg-format-2">
                    Não preencha se não tiver certeza do que está fazendo<br />
                    Passe o formato FFMPEG (mxg, flv, etc.) se não puder<br />
                    ser detectado automaticamente<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('ffmpeg_format_gpu_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('ffmpeg_format_gpu_input_field')
                    }"
                    v-model="body.stream_settings.ffmpeg_format"
                    v-validate="'min:3|max:100'"
                    c-data-vv-as="field"
                    id="ffmpeg_format_gpu_input_field"
                    name="ffmpeg_format_gpu_input_field"
                    type="text"
                    placeholder="Mínimo 3, máximo 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    fullframe_crop_rot
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    fullframe_crop_rot - Valor padrão: Desabilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('fullframe_crop_rot_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('fullframe_crop_rot_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.fullframe_crop_rot" value="true" class="uk-radio" type="radio" id="fullframe_crop_rot_input_field_yes" name="fullframe_crop_rot_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.fullframe_crop_rot" value="false" class="uk-radio" type="radio" id="fullframe_crop_rot_input_field_no" name="fullframe_crop_rot_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    fullframe_use_png
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    fullframe_use_png - Valor padrão: Desabilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('fullframe_use_png_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('fullframe_use_png_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.fullframe_use_png" value="true" class="uk-radio" type="radio" id="fullframe_use_png_input_field_yes" name="fullframe_use_png_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.fullframe_use_png" value="false" class="uk-radio" type="radio" id="fullframe_use_png_input_field_no" name="fullframe_use_png_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    post_best_track_frame
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    post_best_track_frame - Valor padrão: Habilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('post_best_track_frame_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('post_best_track_frame_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_best_track_frame" value="true" class="uk-radio" type="radio" id="post_best_track_frame_input_field_yes" name="post_best_track_frame_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_best_track_frame" value="false" class="uk-radio" type="radio" id="post_best_track_frame_input_field_no" name="post_best_track_frame_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    post_best_track_normalize
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    post_best_track_normalize - Valor padrão: Habilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('post_best_track_normalize_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('post_best_track_normalize_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_best_track_normalize" value="true" class="uk-radio" type="radio" id="post_best_track_normalize_input_field_yes" name="post_best_track_normalize_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_best_track_normalize" value="false" class="uk-radio" type="radio" id="post_best_track_normalize_input_field_no" name="post_best_track_normalize_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    post_last_track_frame
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    post_last_track_frame - Valor padrão: Desabilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('post_last_track_frame_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('post_last_track_frame_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_last_track_frame" value="true" class="uk-radio" type="radio" id="post_last_track_frame_input_field_yes" name="post_last_track_frame_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_last_track_frame" value="false" class="uk-radio" type="radio" id="post_last_track_frame_input_field_no" name="post_last_track_frame_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    post_first_track_frame
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    post_first_track_frame - Valor padrão: Desabilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('post_first_track_frame_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('post_first_track_frame_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_first_track_frame" value="true" class="uk-radio" type="radio" id="post_first_track_frame_input_field_yes" name="post_first_track_frame_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.post_first_track_frame" value="false" class="uk-radio" type="radio" id="post_first_track_frame_input_field_no" name="post_first_track_frame_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_interpolate_bboxes
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    track_interpolate_bboxes - Valor padrão: Habilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('track_interpolate_bboxes_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('track_interpolate_bboxes_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.track_interpolate_bboxes" value="true" class="uk-radio" type="radio" id="track_interpolate_bboxes_input_field_yes" name="track_interpolate_bboxes_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.track_interpolate_bboxes" value="false" class="uk-radio" type="radio" id="track_interpolate_bboxes_input_field_no" name="track_interpolate_bboxes_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_send_history
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    track_send_history - Valor padrão: Desabilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('track_send_history_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('track_send_history_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.track_send_history" value="true" class="uk-radio" type="radio" id="track_send_history_input_field_yes" name="track_send_history_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.track_send_history" value="false" class="uk-radio" type="radio" id="track_send_history_input_field_no" name="track_send_history_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_deep_sort_filter_unconfirmed_tracks
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    track_deep_sort_filter_unconfirmed_tracks - Valor padrão: Habilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('track_deep_sort_filter_unconfirmed_tracks_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('track_deep_sort_filter_unconfirmed_tracks_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.track_deep_sort_filter_unconfirmed_tracks" value="true" class="uk-radio" type="radio" id="track_deep_sort_filter_unconfirmed_tracks_input_field_yes" name="track_deep_sort_filter_unconfirmed_tracks_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.track_deep_sort_filter_unconfirmed_tracks" value="false" class="uk-radio" type="radio" id="track_deep_sort_filter_unconfirmed_tracks_input_field_no" name="track_deep_sort_filter_unconfirmed_tracks_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_deep_sort_matching_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_deep_sort_matching_threshold-1'" :uk-toggle="'target: #ukid-plus-animation-track_deep_sort_matching_threshold-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_deep_sort_matching_threshold-1">

                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    track_deep_sort_matching_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_deep_sort_matching_threshold-2'" :uk-toggle="'target: #ukid-plus-animation-track_deep_sort_matching_threshold-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_deep_sort_matching_threshold-2">

                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('track_deep_sort_matching_threshold_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('track_deep_sort_matching_threshold_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.track_deep_sort_matching_threshold"
                    v-validate="'required|between:0,1'"
                    c-data-vv-as="field"
                    id="track_deep_sort_matching_threshold_input_field"
                    name="track_deep_sort_matching_threshold_input_field"
                    type="text"
                    placeholder="Entre 0 e 1" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_max_duration_frames
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_max_duration_frames-1'" :uk-toggle="'target: #ukid-plus-animation-track_max_duration_frames-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_max_duration_frames-1">

                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    track_max_duration_frames
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_max_duration_frames-2'" :uk-toggle="'target: #ukid-plus-animation-track_max_duration_frames-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_max_duration_frames-2">

                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('track_max_duration_frames_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('track_max_duration_frames_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.track_max_duration_frames"
                    v-validate="'required|between:0,100'"
                    c-data-vv-as="field"
                    id="track_max_duration_frames_input_field"
                    name="track_max_duration_frames_input_field"
                    type="text"
                    placeholder="Entre 0 e 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_miss_interval
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_miss_interval-1'" :uk-toggle="'target: #ukid-plus-animation-track_miss_interval-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_miss_interval-1">

                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    track_miss_interval
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_miss_interval-2'" :uk-toggle="'target: #ukid-plus-animation-track_miss_interval-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_miss_interval-2">

                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('track_miss_interval_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('track_miss_interval_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.track_miss_interval"
                    v-validate="'required|between:0,100'"
                    c-data-vv-as="field"
                    id="track_miss_interval_input_field"
                    name="track_miss_interval_input_field"
                    type="text"
                    placeholder="Entre 0 e 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    track_overlap_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_miss_interval-1'" :uk-toggle="'target: #ukid-plus-animation-track_overlap_threshold-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_overlap_threshold-1">

                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    track_overlap_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-track_miss_interval-2'" :uk-toggle="'target: #ukid-plus-animation-track_overlap_threshold-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-track_overlap_threshold-2">

                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('track_overlap_threshold_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('track_overlap_threshold_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.track_overlap_threshold"
                    v-validate="'required|between:0,1'"
                    c-data-vv-as="field"
                    id="track_miss_interval_input_field"
                    name="track_miss_interval_input_field"
                    type="text"
                    placeholder="Entre 0 e 1" />
            </div>
        </div>

        <div class="uk-margin uk-hidden">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('play_speed_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('play_speed_input_field')
                    }"
                    v-model="body.stream_settings.play_speed"
                    v-validate="'required|between:-1,10'"
                    c-data-vv-as="field"
                    id="play_speed_input_field"
                    name="play_speed_input_field"
                    type="text"
                    placeholder="Entre 30 e 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    filter_min_quality
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-min-quality-1'" :uk-toggle="'target: #ukid-plus-animation-filter-min-quality-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter_min_quality-1">
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Faces de baixíssima qualidade<br />
                    1 = Alta qualidade<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    filter_min_quality
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-min-quality-2'" :uk-toggle="'target: #ukid-plus-animation-filter-min-quality-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-min-quality-2">
                    Valor limite mínimo para uma qualidade de imagem facial<br />
                    Uma face é postada se tiver melhor qualidade<br />
                    O valor limite deve ser ajustado empiricamente<br />
                    Unidades empíricas: números racionais negativos para zero<br />
                    Marcos:<br />
                    0 = Faces de baixíssima qualidade<br />
                    1 = Alta qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('filter_min_quality_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('filter_min_quality_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.filter_min_quality"
                    v-validate="'required|between:0,1'"
                    c-data-vv-as="field"
                    id="filter_min_quality_input_field"
                    name="filter_min_quality_input_field"
                    type="text"
                    placeholder="Entre 0 e 1" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
               <span class="ukid-icon-pink-color">
                    filter_min_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-min-face-size-1'" :uk-toggle="'target: #ukid-plus-animation-filter-min-face-size-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-min-face-size-1">
                    Valor padrão: 0 (filtro desativado)<br />
                    Tamanho mínimo de uma face em pixels<br />
                    As faces subdimensionadas não são postadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
               <span class="ukid-icon-pink-color">
                    filter_min_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-min-face-size-2'" :uk-toggle="'target: #ukid-plus-animation-filter-min-face-size-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-min-face-size-2">
                    Valor padrão: 0 (filtro desativado)<br />
                    Tamanho mínimo de uma face em pixels<br />
                    As faces subdimensionadas não são postadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('filter_min_face_size_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('filter_min_face_size_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.filter_min_size"
                    v-validate="'required|min:0|max:1000'"
                    c-data-vv-as="field"
                    id="filter_min_face_size_input_field"
                    name="filter_min_face_size_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1000" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
               <span class="ukid-icon-pink-color">
                    filter_max_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-max-face-size-1'" :uk-toggle="'target: #ukid-plus-animation-filter-max-face-size-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-max-face-size-1">
                    Valor padrão: 0 (filtro desativado)<br />
                    Tamanho máximo de uma face em pixels<br />
                    As faces de grandes dimensões não são postadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    filter_max_size
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-max-face-size-2'" :uk-toggle="'target: #ukid-plus-animation-filter-max-face-size-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-max-face-size-2">
                    Valor padrão: 0 (filtro desativado)<br />
                    Tamanho máximo de uma face em pixels<br />
                    As faces de grandes dimensões não são postadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('filter_max_face_size_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('filter_max_face_size_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.filter_max_size"
                    v-validate="'required|min:0|max:1000'"
                    c-data-vv-as="field"
                    id="filter_max_face_size_input_field"
                    name="filter_max_face_size_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 1000" />
            </div>
        </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    jpeg_quality - Valor padrão: 95%
                </span>
                <br />
                Qualidade da compactação JPEG<br />
                do frame original, em porcentagem<br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    jpeg_quality - Valor padrão: 95%
                </span>
                <br />
                Qualidade da compactação JPEG do frame original, em porcentagem<br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('jpeg_quality_gpu_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('jpeg_quality_gpu_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.jpeg_quality"
                    v-validate="'required|between:30,100'"
                    c-data-vv-as="field"
                    id="jpeg_quality_gpu_input_field"
                    name="jpeg_quality_gpu_input_field"
                    type="text"
                    placeholder="Entre 30 e 100" />
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    overall_only - Valor padrão: Habilitado
                </span>
                <br />
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    overall_only - Valor padrão: Habilitado
                </span>
                <br />
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('overall_only_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('overall_only_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.overall_only" value="true" class="uk-radio" type="radio" id="overall_only_input_field_yes" name="overall_only_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.overall_only" value="false" class="uk-radio" type="radio" id="overall_only_input_field_no" name="overall_only_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    ffmpeg_params
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-ffmpeg-params-1'" :uk-toggle="'target: #ukid-plus-animation-filter-ffmpeg-params-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-ffmpeg-params-1">
                    Lista de opções de ffmpeg de um fluxo <br />
                    de vídeo com seus valorescomo uma<br />
                    matriz key=value: ["rtsp_transpotr = tcp", ..,<br />
                    "ss = 00: 20: 00"]<br />
                    Confira o site FFmpeg <a class="ukid-icon-pink-color" href="https://trac.ffmpeg.org" target="_blank">web site</a><br />
                    para a lista completa de opções.<br />
                    Valor padrão: opções não especificadas<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    ffmpeg_params
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-filter-ffmpeg-params-2'" :uk-toggle="'target: #ukid-plus-animation-filter-ffmpeg-params-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-filter-ffmpeg-params-2">
                    Lista de opções de ffmpeg de um fluxo de vídeo com seus valores como uma matriz<br />
                    key=value: ["rtsp_transpotr = tcp", .., "ss = 00: 20: 00"]<br />
                    Confira o site FFmpeg <a class="ukid-icon-pink-color" href="https://trac.ffmpeg.org" target="_blank">web site</a><br />
                    para a lista completa de opções. Valor padrão: opções não especificadas<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('ffmpeg_params_gpu_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('ffmpeg_params_gpu_input_field')
                    }"
                    v-model="body.stream_settings.ffmpeg_params"
                    v-validate="'min:5|max:100'"
                    c-data-vv-as="field"
                    id="ffmpeg_params_gpu_input_field"
                    name="ffmpeg_params_gpu_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 100">
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    router_timeout_ms
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-timeout-ms-1'" :uk-toggle="'target: #ukid-plus-animation-router-timeout-ms-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-timeout-ms-1">
                    router_timeout_ms - Valor padrão: 15000<br />
                    Tempo limite para uma resposta face router<br />
                    a uma solicitação da API video-worker, em milissegundos<br />
                    Se o tempo limite expirar, o sistema registrará um erro<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    router_timeout_ms
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-timeout-ms-2'" :uk-toggle="'target: #ukid-plus-animation-router-timeout-ms-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-timeout-ms-2">
                    router_timeout_ms - Valor padrão: 15000<br />
                    Tempo limite para uma resposta face router<br />
                    a uma solicitação da API video-worker, em milissegundos<br />
                    Se o tempo limite expirar, o sistema registrará um erro<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('router_timeout_ms_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('router_timeout_ms_input_field')
                    }"
                    v-model="body.stream_settings.router_timeout_ms"
                    v-validate="'required|between:1000,30000'"
                    c-data-vv-as="field"
                    id="router_timeout_ms_input_field"
                    name="router_timeout_ms_input_field"
                    type="text"
                    placeholder="Entre 1000 e 30000" />
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    router_verify_ssl
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-verify-ssl-1'" :uk-toggle="'target: #ukid-plus-animation-router-verify-ssl-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-verify-ssl-1">
                    Valor padrão: Não habilitado<br />
                    Não habilitado - Um cert. autoassinado também é aceito
                    Ativa a verificação do certificado https quando<br />
                    video-worker e facerouter interagem por https<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    router_verify_ssl
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-verify-ssl-2'" :uk-toggle="'target: #ukid-plus-animation-router-verify-ssl-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-verify-ssl-2">
                    Valor padrão: Não habilitado<br />
                    Não habilitado - Um cert. autoassinado também é aceito
                    Ativa a verificação do certificado https quando<br />
                    video-worker e facerouter interagem por https<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('router_verify_ssl_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('router_verify_ssl_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.router_verify_ssl" value="true" class="uk-radio" type="radio" id="router_verify_ssl_input_field_yes" name="router_verify_ssl_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.router_verify_ssl" value="false" class="uk-radio" type="radio" id="router_verify_ssl_input_field_no" name="router_verify_ssl_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    router_headers
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-headers-1'" :uk-toggle="'target: #ukid-plus-animation-router-headers-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-headers-1">
                    Valor padrão: cabeçalhos não especificados<br />
                    Campos de cabeçalho adicionais em uma<br />
                    solicitação POST ao postar uma face para WEB HUC<br />
                    Ex: Authorization=Token xxx<br />
                    Accept=application/json<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    router_headers
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-headers-2'" :uk-toggle="'target: #ukid-plus-animation-router-headers-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-headers-2">
                    Valor padrão: cabeçalhos não especificados<br />
                    Campos de cabeçalho adicionais em uma<br />
                    solicitação POST ao postar uma face para WEB HUC<br />
                    Ex: Authorization=Token xxx<br />
                    Accept=application/json<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <textarea
                    :class="{
                        'ukid-text-area-external uk-textarea ukid-form-error ukid-focus ukid-width-100-percent ukid-height-150-px': errors.has('router_headers_input_field'),
                        'ukid-text-area-external uk-textarea ukid-focus ukid-width-100-percent ukid-height-150-px': !errors.has('router_headers_input_field')
                    }"
                    rows="5"
                    v-model="router_headers"
                    v-validate="'min:0|max:2000'"
                    c-data-vv-as="field"
                    id="router_headers_input_field"
                    name="router_headers_input_field"
                    type="textarea"></textarea>
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    router_body
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-body-1'" :uk-toggle="'target: #ukid-plus-animation-router-body-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-body-1">
                    Campos adicionais no body da solicitação<br />
                    ao postar uma face. Ex: uuid=xxx,some_name=yyy<br />
                    <span class="uk-text-lowercase ukid-font-size-11">
                        image_type=BASE64<br />
                        external_url=https://xxx<br />
                        external_token=token_xxx<br />
                        mf_selector=<br />
                        detector=DEFAULT<br />
                        gallery=DEFAULT<br />
                        latitude=<br />
                        longitude=
                    </span>
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    router_body
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-router-body-2'" :uk-toggle="'target: #ukid-plus-animation-router-body-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-router-body-2">
                    Campos adicionais no body da solicitação<br />
                    ao postar uma face. Ex: uuid=xxx,some_name=yyy<br />
                    <span class="uk-text-lowercase ukid-font-size-11">
                        image_type=BASE64<br />
                        external_url=https://xxx<br />
                        external_token=token_xxx<br />
                        mf_selector=<br />
                        detector=DEFAULT<br />
                        gallery=DEFAULT<br />
                        latitude=<br />
                        longitude=
                    </span>
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <textarea
                    :class="{
                        'ukid-text-area-external ukid-form-error uk-input ukid-focus ukid-width-100-percent ukid-height-150-px': errors.has('router_headers_input_field'),
                        'ukid-text-area-external uk-input ukid-focus ukid-width-100-percent ukid-height-150-px': !errors.has('router_headers_input_field')
                    }"
                    v-model="router_body"
                    v-validate="'required|min:5|max:1000'"
                    c-data-vv-as="field"
                    id="router_headers_input_field"
                    name="router_headers_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 1000"></textarea>
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    use_stream_timestamp
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-use-stream-timestamp-1'" :uk-toggle="'target: #ukid-plus-animation-use-stream-timestamp-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-use-stream-timestamp-1">
                    Se habilitado, recupera e posta<br />
                    data e hora de um stream de vídeo<br />
                    Se não habilitado, envia a data e a hora reais<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    use_stream_timestamp
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-use-stream-timestamp-2'" :uk-toggle="'target: #ukid-plus-animation-use-stream-timestamp-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-use-stream-timestamp-2">
                    Se habilitado, recupera e posta<br />
                    data e hora de um stream de vídeo<br />
                    Se não habilitado, envia a data e a hora reais<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('use_stream_timestamp_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('use_stream_timestamp_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.use_stream_timestamp" value="true" class="uk-radio" type="radio" id="use_stream_timestamp_input_field_yes" name="use_stream_timestamp_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.use_stream_timestamp" value="false" class="uk-radio" type="radio" id="use_stream_timestamp_input_field_no" name="use_stream_timestamp_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
         </div>

         <div class="uk-margin uk-hidden">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('start_stream_timestamp_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('start_stream_timestamp_input_field')
                    }"
                    v-model="body.stream_settings.start_stream_timestamp"
                    v-validate="'required|between:0,10'"
                    c-data-vv-as="field"
                    id="start_stream_timestamp_input_field"
                    name="start_stream_timestamp_input_field"
                    type="text"
                    placeholder="Mínimo 0, máximo 10" />
            </div>
         </div>

         <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    imotion_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-imotion-threshold-1'" :uk-toggle="'target: #ukid-plus-animation-imotion-threshold-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-imotion-threshold-1">
                    Valor padrão: Região não especificada<br />
                    Intensidade mínima de movimento<br />
                    a ser detectada pelo detector de movimento<br />
                    O valor limite deve ser ajustado empiricamente.<br />
                    Unidades empíricas: zero e números racionais positivos.<br />
                    Marcos:<br />
                    0 = detector desativado<br />
                    0.002 = valor padrão<br />
                    0.05 = intensidade mínima muito alta<br />
                    para detectar movimento<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    imotion_threshold
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-imotion-threshold-2'" :uk-toggle="'target: #ukid-plus-animation-imotion-threshold-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-imotion-threshold-2">
                    Valor padrão: Região não especificada<br />
                    Intensidade mínima de movimento a ser detectada pelo detector de movimento<br />
                    O valor limite deve ser ajustado empiricamente.<br />
                    Unidades empíricas: zero e números racionais positivos.<br />
                    Marcos:<br />
                    0 = detector desativado<br />
                    0.002 = valor padrão<br />
                    0.05 = intensidade mínima muito alta para detectar movimento<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('imotion_threshold_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('imotion_threshold_input_field')
                    }"
                    v-model="body.stream_settings.imotion_threshold"
                    v-validate="'required|between:0,1|decimal:3'"
                    c-data-vv-as="field"
                    id="imotion_threshold_input_field"
                    name="imotion_threshold_input_field"
                    type="text"
                    placeholder="Entre 0 e 1">
            </div>
         </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    roi
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-gpu-roi-1'" :uk-toggle="'target: #ukid-plus-animation-gpu-roi-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-gpu-roi-1">
                    Valor padrão: Região não especificada<br />
                    Ativar post de faces detectadas apenas<br />
                    dentro de uma região de interesse WxH + X + Y<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    roi
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-gpu-roi-2'" :uk-toggle="'target: #ukid-plus-animation-gpu-roi-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-gpu-roi-2">
                    Valor padrão: Região não especificada<br />
                    Ativar post de faces detectadas apenas dentro de uma<br />
                    região de interesse WxH + X + Y<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('roi_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('roi_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.roi"
                    v-validate="'min:5|max:100'"
                    c-data-vv-as="field"
                    id="roi_input_field"
                    name="roi_input_field"
                    type="text"
                    placeholder="Mínimo 5, máximo 100" />
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    realtime_post_every_interval
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-pei-1'" :uk-toggle="'target: #ukid-plus-animation-realtime-pei-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-pei-1">
                    Valor padrão: Não habilitado<br />
                    Modo em tempo real<br />
                    Poste as melhores capturas obtidas em cada<br />
                    período de tempo realtime_post_interval<br />
                    Se não habilitado, procure o melhor<br />
                    momento dinamicamente<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    realtime_post_every_interval
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-pei-2'" :uk-toggle="'target: #ukid-plus-animation-realtime-pei-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-pei-2">
                    Valor padrão: Não habilitado<br />
                    Modo em tempo real<br />
                    Poste as melhores capturas obtidas em cada<br />
                    período de tempo realtime_post_interval<br />
                    Se não habilitado, procure o melhor momento dinamicamente<br />
                    e post em ordem crescente de qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('realtime_post_every_interval_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('realtime_post_every_interval_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.realtime_post_every_interval" value="true" class="uk-radio" type="radio" id="realtime_post_every_interval_input_field_yes" name="realtime_post_every_interval_input_field" checked> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.realtime_post_every_interval" value="false" class="uk-radio" type="radio" id="realtime_post_every_interval_input_field_no" name="realtime_post_every_interval_input_field"> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    realtime_post_first_immediately
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-pei-1'" :uk-toggle="'target: #ukid-plus-animation-realtime-pei-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-pei-1">

                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    realtime_post_first_immediately - VALOR PADRÃO: DESABILITADO
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-pei-2'" :uk-toggle="'target: #ukid-plus-animation-realtime-pei-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-pei-2">

                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <div :class="{
                        'ukid-width-100-percent': errors.has('realtime_post_first_immediately_input_field'),
                        'uk-margin uk-grid-small uk-child-width-auto uk-grid ukid-width-100-percent': !errors.has('realtime_post_first_immediately_input_field')}">
                    <label>
                        <input v-model="body.stream_settings.detectors.body.realtime_post_first_immediately" value="true" class="uk-radio" type="radio" id="realtime_post_first_immediately_input_field_yes" name="realtime_post_first_immediately_input_field"> Habilitar
                    </label>
                    <label>
                        <input v-model="body.stream_settings.detectors.body.realtime_post_first_immediately" value="false" class="uk-radio" type="radio" id="realtime_post_first_immediately_input_field_no" name="realtime_post_first_immediately_input_field" checked> Não Habilitar
                    </label>
                </div>
            </div>
        </div>

        <div class="uk-margin">
            <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-9">
                <span class="ukid-icon-pink-color">
                    realtime_post_interval
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-pi-1'" :uk-toggle="'target: #ukid-plus-animation-realtime-pi-1; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-pi-1">
                    Valor padrão: 1 - Em segundos<br />
                    Somente para o modo em tempo real habilitado<br />
                    Se realtime_post_every_interval = Habilitado,<br />
                    define o período em milissegundos dentro<br />
                    do qual o rastreador de face pega<br />
                    o melhor momento e o posta no face router<br />
                    Se realtime_post_every_interval = Não habilitado,<br />
                    define o período mínimo de tempo entre<br />
                    2 posts da mesma face com maior qualidade<br />
                </span>
            </span>
            <span class="uk-label ukid-primary-label-color uk-visible@s ukid-font-size-12">
                <span class="ukid-icon-pink-color">
                    realtime_post_interval
                </span>
                <br /><br />
                <span class="ukid-icon-yellow-color ukid-cursor-pointer" :href="'#ukid-plus-animation-realtime-pi-2'" :uk-toggle="'target: #ukid-plus-animation-realtime-pi-2; animation: uk-animation-fade; style: display:block;'" uk-icon="icon:comments;ratio:1.0"></span><br />
                <span class="ukid-font-size-9" hidden id="ukid-plus-animation-realtime-pi-2">
                    Valor padrão: 1 - Em segundos<br />
                    Somente para o modo em tempo real habilitado<br />
                    Se realtime_post_every_interval = Habilitado, define o período em milissegundos<br />
                    dentro do qual o rastreador de face pega<br />
                    o melhor momento e o posta no face router<br />
                    Se realtime_post_every_interval = Não habilitado, define o período mínimo de tempo<br />
                    entre 2 posts da mesma face com maior qualidade<br />
                </span>
            </span>
            <div class="uk-inline ukid-width-100-percent">
                <input
                    :class="{
                        'ukid-form-error uk-input ukid-focus ukid-width-100-percent': errors.has('realtime_post_interval_input_field'),
                        'uk-input ukid-focus ukid-width-100-percent': !errors.has('realtime_post_interval_input_field')
                    }"
                    v-model="body.stream_settings.detectors.body.realtime_post_interval"
                    v-validate="'required|decimal:2|between:0,500'"
                    c-data-vv-as="field"
                    id="realtime_post_interval_input_field"
                    name="realtime_post_interval_input_field"
                    type="text"
                    placeholder="Entre 0 e 500" />
            </div>
        </div>
    </div>

</template>