<script type="text/javascript">

    import App from '../../app.json'

    import { vm } from '../../js/main.js'
    import { Datetime } from 'vue-datetime'
    import { Strings } from '../../js/common.js'

    export default {

        components: {

            datetime: Datetime
            
        },

        /**
         * All objects required for this page to function properly
         */
        data() {

            return {

                App: App,
                searchParam: '',
                pagination: {
                    last_page: 0
                },                                
                registers: {
                },
                oldRegisters: [],
                detectRequest: {
                    gae: false,
                    photo: ''
                },
                detectObjectsRequest: {
                    threshold: '0.43',
                    photo: ''
                },
                enableAutoList: false,
                allPersons: false,
                controlStartDate: 0,
                controlEndDate: 0,                
                startDate: new Date(new Date().setUTCHours(-3)).toISOString(),
                endDate: new Date(new Date().setUTCHours(-3)).toISOString(),
                currentDate: true,
                imagesPerPage: App.pagination.bodies.itensPerPage,
                sub: '',
                page: 1

            }

        },

        /**
         * This method executes when the vue object is created.
         */
        created() {

            let _ = this

            /**
             * Config initial filters
             */
            _.setFilters()

            /** 
             * Active the search list
             */
            _.list(undefined)
            _.enableInterval()

            /**
             * Get user logged
             */
            _.sub = vm.user.sub
                    
        },
        
        methods: {

            /**
             * Responsible for re-registering the records 
             * and re-activating the polling interval.
             */
            search() {

                let _ = this

                vm.spinnerSearch()

                _.enableInterval()

            },

            /**
             * This method is responsible for listing the real time 
             * detections that are happening in CCTV cameras, this method 
             * is based on the end user predefined filters
             * 
             * This method fills in the attributes (registers and pagination) of the data object.
             * 
             * @param page Pagination based page number
             */
            list(page) {
            
                let _ = this

                if (page !== undefined) {
                                        
                    _.page = page

                    _.clearInterval()

                }

                /**
                 * Filter of search
                 */
                var filter_ = {

                    pageNum: (page === undefined ? 1 : page), // Number of page
                    perPage: _.imagesPerPage, // Total itens per page
                    param: _.searchParam // Some string of research

                }

                /**
                 * Post filter to unike server
                 */
                _.$http.get(
                    App.host.concat(
                        App.listLiveWebCamSessionsEndpoint 
                        + '?pageNum=' + filter_.pageNum
                        + '&perPage=' + filter_.perPage
                        + '&param=' + filter_.param
                        + "&nocache=" + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))
                    )                     
                ).then(response => {

                    /**
                     * Update binded objects
                     */
                    _.registers = response.body.data
                    _.pagination = response.body

                    /*
                     * Scans all records found 
                     */
                    if (_.registers.length) {
                        
                        _.registers.forEach(function(object) {
                            
                            _.detect(object)

                        })

                    }
                                        
                }).catch(function(err){

                    console.error(err)

                }) 

            },

            detect(object) {

                /*
                 * This document 
                 */
                let _ = this

                /*
                 * This div's contains tha image and div bbox  
                 */
                let containerDefault = document.querySelector('#ukid-container-' + object.id)
                let containerZoom = document.querySelector('#ukid-container-zoom-' + object.id)
                
                /*
                 * The first image is shown on list, second is shown when user click on zoom
                 */
                let imgDefault = document.querySelector('#ukid-image-bodies-' + object.id)
                let imgZoom = document.querySelector('#ukid-image-bodies-zoom-' + object.id)

                if (imgDefault === null || !imgDefault.complete) {

                    setTimeout(function(){ _.detect(object) }, 10)

                    return

                }
                
                /*
                 * Canvas to image list and image zoom
                 */
                let canvasDefault = document.createElement('canvas')
                let canvasZoom = document.createElement('canvas')

                canvasDefault.width  = imgDefault.width
                canvasDefault.height = imgDefault.height

                canvasZoom.width  = imgZoom.width
                canvasZoom.height = imgZoom.height                    
                
                /*
                 * Context to image list and image zoom
                 */
                let ctxDefault = canvasDefault.getContext('2d')
                let ctxZoom = canvasZoom.getContext('2d')

                ctxDefault.drawImage(imgDefault, 0, 0, imgDefault.width, imgDefault.height)
                ctxZoom.drawImage(imgZoom, 0, 0, imgZoom.width, imgZoom.height)

                /*
                 * Detects the listing image 
                 */
                _.detectRequest.gae = false
                _.detectRequest.photo = canvasDefault.toDataURL('image/jpeg').replace('data:image/jpeg;base64,', '')

                //_.$http.post(App.host.concat(App.detectPersonEndpoint), _.detectRequest).then(response => {

                    _.bbox(containerDefault, imgDefault, object.faces)

                //})

                /*
                 * Detects the listing image 
                 */
                _.detectObjectsRequest.threshold = '0.43'
                _.detectObjectsRequest.photo = canvasDefault.toDataURL('image/jpeg').replace('data:image/jpeg;base64,', '')

                //_.$http.post(App.host.concat(App.detectObjectsEndpoint), _.detectObjectsRequest).then(response => {
 
                    _.bbox_(containerDefault, imgDefault, object)

                //})

            },

            bbox(container, img, faces) {
                
                let _ = this

                container.querySelectorAll('div').forEach(e => e.parentNode.removeChild(e))

                faces.forEach(function(object) {

                    let rect = document.createElement('div')
                                        
                    rect.classList.add('rect')

                    rect.style.width  = (object.bbox.right - object.bbox.left) + 'px'
                    rect.style.height = (object.bbox.bottom - object.bbox.top) + 'px'
                    rect.style.left   = (img.offsetLeft + object.bbox.left) + 'px'
                    rect.style.top    = (img.offsetTop  + object.bbox.top) + 'px'

                    rect.style.borderColor = _.colorBox()
                    
                    container.appendChild(rect)

                })

            },

            bbox_(container, img, object) {
                
                let _ = this

                container.querySelectorAll('div.objects').forEach(e => e.parentNode.removeChild(e))

                object.objects.bbox.forEach(function(item, index) {

                    let rect = document.createElement('div')
                                        
                    rect.classList.add('rect')
                    rect.classList.add('objects')

                    rect.style.width  = (item.right - item.left) + 'px'
                    rect.style.height = (item.bottom - item.top) + 'px'
                    rect.style.left   = (img.offsetLeft + item.left) + 'px'
                    rect.style.top    = (img.offsetTop  + item.top) + 'px'

                    rect.style.borderColor = _.colorBox()
                    
                    if (object.objects.classes[index] === 'person')
                        rect.innerHTML = '<span style="color:#5CF77E">Pessoa</spam>'
                    else 
                        rect.innerHTML = '<span style="color:#5CF77E">Celular</spam>'

                    container.appendChild(rect)

                })

            },            

            colorBox() {

                let colors = ['#fdecce','#F5EA87','#5CF77E','#3acff8','#7C5EC6','#ff33ff','#b366ff','#bb4462','#b0b036','#f49e0b']
            
                return colors[Math.floor((Math.random() * 10))]

            },

            /**
             * Saves user-selected filter data to browser database
             */
            saveSelectedDevices() {

                if (this.allPersons === true || this.allPersons === 'true')
                    localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_searchParam_tp', 'tp_33g0opl90jko097j')
                else
                    localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_searchParam_tp', this.searchParam)
                
                localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_allPersons_tp', this.allPersons)
                localStorage.setItem(window.location.hostname + '_' + vm.user.sub + '_imagesPerPage_tp', this.imagesPerPage)
                
                this.setFilters()

                this.registers  = {}
                
                this.list(this.page)
                
                this.clearInterval()
                this.enableInterval()

            },

            /**
             * Configure saved filter data for bound objects
             */
            setFilters() {

                let _searchParam_proximity   = window.location.hostname + '_' + vm.user.sub + '_searchParam_tp'
                let _allPersons_proximity    = window.location.hostname + '_' + vm.user.sub + '_allPersons_tp'
                let _imagesPerPage_proximity = window.location.hostname + '_' + vm.user.sub + '_imagesPerPage_tp'

                if (!Strings.isNullOrEmpty(localStorage.getItem(_searchParam_proximity)))   this.searchParam   = localStorage.getItem(_searchParam_proximity)
                if (!Strings.isNullOrEmpty(localStorage.getItem(_allPersons_proximity)))    this.allPersons    = localStorage.getItem(_allPersons_proximity)
                if (!Strings.isNullOrEmpty(localStorage.getItem(_imagesPerPage_proximity))) this.imagesPerPage = localStorage.getItem(_imagesPerPage_proximity)

                this.$forceUpdate()

            },

            configSearch() {

                if (this.searchParam !== 'tp_33g0opl90jko097j') {

                    this.allPersons = false

                } else {

                    this.allPersons = true

                }

            },

            includeAllTpPersons() {
                
                if (this.allPersons === "true" || this.allPersons === true)
                    this.searchParam = 'tp_33g0opl90jko097j'
                else
                    this.searchParam = 'matrícula'

            },

            currentDateFilter(isCurrentDate) {

                this.currentDate = isCurrentDate

                if (this.currentDate === "true") {

                    this.startDate = new Date(new Date().setUTCHours(-3)).toISOString()
                    this.endDate   = new Date(new Date().setUTCHours(-3)).toISOString()

                }

            },

            clearInterval() {

                let _ = this

                for (var i=1; i < 99999; i++) {
                    
                    window.clearInterval(i);

                    _.enableAutoList = true
                    
                }

            },

            enableInterval() {
                
                let _ = this
    
                _.page = 1

                _.list(_.page)

                _.enableAutoList = false

                setInterval(function(){
                
                    _.list(undefined)

                }, 10000)
                
                
            },

            openFullScreen() {

                this.imagesPerPage = App.pagination.bodies.itensPerPageFullscreen

                if (document.documentElement.requestFullscreen) {

                    document.documentElement.requestFullscreen()

                } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */

                    edocument.documentElementlem.mozRequestFullScreen()

                } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */

                    document.documentElement.webkitRequestFullscreen()

                } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */

                    document.documentElement.msRequestFullscreen()

                }

                document.querySelector('.ukid-open-fullscreen-buttom').classList.add('uk-hidden')
                document.querySelector('.ukid-close-fullscreen-buttom').classList.remove('uk-hidden')

                try { 

                    document.querySelector('#ukid-top').classList.add('uk-hidden')
                    document.querySelector('#ukid-footer').classList.add('uk-hidden')

                } catch (ignored) {

                }

                document.querySelector('.ukid-logo-fullscreen').classList.remove('uk-hidden')
                 
            },

            closeFullScreen() {

                this.imagesPerPage = App.pagination.bodies.itensPerPage

                if (document.exitFullscreen) {

                    document.exitFullscreen()

                } else if (document.mozCancelFullScreen) { /* Firefox */

                    document.mozCancelFullScreen()

                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */

                    document.webkitExitFullscreen() 
                    
                } else if (document.msExitFullscreen) { /* IE/Edge */

                    document.msExitFullscreen()

                }

                document.querySelector('.ukid-close-fullscreen-buttom').classList.add('uk-hidden')
                document.querySelector('.ukid-open-fullscreen-buttom').classList.remove('uk-hidden')
                document.querySelector('.ukid-logo-fullscreen').classList.add('uk-hidden')

                try { 

                    document.querySelector('#ukid-top').classList.remove('uk-hidden') 
                    document.querySelector('#ukid-footer').classList.remove('uk-hidden')
                    
                } catch (ignored) {  

                }
                
            }

        }

    }

</script>

<template id="tp-template">

    <div class="uk-child-width-expand@s ukid-background-white-color ukid-min-height" uk-grid>
        
        <!-- Modal filters -->
        <div id="modal-devices-tp" uk-modal>
            
            <div class="uk-modal-dialog uk-modal-body">
                <div class="uk-margin">    
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-visible@s">Snapshots por Página</span>
                    <span class="uk-label uk-margin-small ukid-primary-label-color uk-hidden@s ukid-font-size-10">Snapshots por Página</span><br />
                    <div class="uk-inline ukid-width-22-percent uk-visible@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-50-percent uk-hidden@s">
                        <span class="uk-form-icon" uk-icon="icon:list"></span>
                        <the-mask v-model="imagesPerPage" :mask="['##', '##']" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>                    
                </div>
                <p>
                    <span class="ukid-font-size-11 ukid-icon-pink-color">
                        OBS: Os dados são salvos no armazenamento do navegador<br /> 
                        e não na sessão do usuário, possibilitando o monitoramento em computadores diferentes                                                
                    </span>
                </p>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Pesquisar Qualquer Parâmetro<br />(Nome, CPF ou Matrícula Teleperformance)<br />
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Pesquisar Qualquer Parâmetro<br />(Nome, CPF ou Matrícula Teleperformance)<br />
                    </span><br />
                    <div class="uk-inline ukid-width-30-percent uk-visible@s">
                        <input type="text" @blur="configSearch" v-model="searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>
                    <div class="uk-inline ukid-width-60-percent uk-hidden@s">
                        <input type="text" @blur="configSearch"  v-model="searchParam" class="uk-input ukid-focus ukid-width-100-percent" />
                    </div>                     
                </div>
                <div class="uk-margin">    
                    <span class="uk-label ukid-primary-label-color uk-visible@s">
                        Todas as detecções<br />
                        <span class="ukid-font-size-11 ukid-icon-pink-color">
                            OBS: Mostra identificados e não identificados
                        </span>
                    </span>
                    <span class="uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10">
                        Todas as detecções<br />
                        <span class="ukid-font-size-11 ukid-icon-pink-color">
                            OBS: Mostra identificados<br />e não identificados
                        </span>                        
                    </span>                    
                    <br />
                    <div class="uk-inline ukid-width-22-percent">
                        <input @change="includeAllTpPersons()" true-value="true" false-value="false" v-model="allPersons" class="uk-checkbox" type="checkbox">
                    </div>                                                                                                                   
                </div>                                                
                <p class="uk-text-right uk-visible@s">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Não Salvar</button>
                    <button @click="saveSelectedDevices" class="uk-button uk-button-primary uk-modal-close" type="button">Salvar</button>
                </p>
                <p class="uk-text-right uk-hidden@s">
                    <button class="uk-button uk-button-default uk-button-small uk-modal-close" type="button">Não Salvar</button>
                    <button @click="saveSelectedDevices" class="uk-button uk-button-primary uk-button-small uk-modal-close" type="button">Salvar</button>
                </p>                
            </div>

        </div>

        <!-- Delete modal -->
        <div id="modal-overflow" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body ukid-font-size-18" uk-overflow-auto>
                    <p>{{App.messages.delAsk}}</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">{{App.messages.popoUpButtonCancel}}</button>
                    <button v-on:click="del()" class="uk-button uk-button-primary uk-modal-close" type="button">{{App.messages.popoUpButtonDelete}}</button>
                </div>
            </div>
        </div>

        <!-- Top of the layout proximity -->
        <div class="uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent">
            <div class="uk-card-header ukid-supress-border-bottom">
                <div class="ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50">
                    <a href="../" class="uk-navbar-item uk-logo ukid-white-color ukid-font-size-13">
                        <img src="content/images/unike-logo-un.png" width="120" class="uk-margin-small-right">
                    </a>
                </div>
                <div class="uk-grid-small uk-flex-middle ukid-min-height-60" uk-grid>                                    
                    <div class="uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50">
                        <span class="uk-hidden ukid-spinner" uk-spinner="ratio:4"></span>
                        <span class="ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6" uk-icon="icon:search;ratio:5.0"></span> 
                    </div>
                    <div class="uk-width-expand">                        
                        <span class="ukid-font-size-20 ukid-margin-top-40 uk-visible@s">Frames Capturados</span>
                        <span class="ukid-font-size-16 ukid-margin-top-40 uk-hidden@s">Frames Capturados</span>
                        <div class="uk-margin-remove-top">
                            <span class="uk-visible@s">                                
                                Módulo piloto para detecção de pessoas e objetos
                                <br />
                                <div class="uk-label ukid-label-blue-color">
                                    Total de Snapshots até a Data de Hoje: {{pagination.databaseCount}}
                                    <br />
                                    Total de Pessoas de Acordo com os Filtros: {{pagination.total}}                                    
                                </div>
                            </span>
                            <span class="uk-hidden@s ukid-font-size-13">                                
                                Módulo piloto para detecção de pessoas e objetos
                                <br />
                                <div class="uk-label ukid-label-blue-color ukid-font-size-9">
                                    Snapshots neste Momento: {{pagination.databaseCount}}
                                    <br />
                                    Total com os Filtros: {{pagination.total}}                                    
                                </div>
                            </span>                            
                            <div class="uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15" align="right">
                                <a @click="setFilters()" uk-toggle="target: #modal-devices-tp">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar
                                </a>
                            </div>
                            <div class="uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17" align="right">
                                <a @click="setFilters()" uk-toggle="target: #modal-devices-tp">
                                    <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                    Filtrar
                                </a>
                            </div>                            
                            <button @click="setFilters()" uk-toggle="target: #modal-devices-tp" class="uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="cog"></span>
                                Filtrar
                            </button>                            
                            <button @click="openFullScreen" class="ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="tv"></span>
                                Tela Cheia
                            </button>
                            <button @click="closeFullScreen" class="ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0">
                                <span class="uk-margin-small-right uk-icon" uk-icon="close"></span>
                                Sair da Tela Cheia
                            </button>                            
                        </div>
                    </div>
                </div>        
            </div>

            <div class="uk-card-footer ukid-supress-border-top">

                <div class="uk-flex uk-flex-center ukid-padding-top-20">
                    <div class="uk-flex uk-flex-center uk-grid-medium uk-text-center" uk-grid>
                        <div v-show="!registers.length" class="uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color" uk-alert>                            
                            <p class="ukid-white-color">
                                <span class="uk-margin-small-right uk-icon" uk-icon="warning"></span>
                                {{App.proximity.nobody}}
                            </p>
                        </div>                        

                        <!--For on all detected bodies according to filters defined by final user -->
                        <div v-for="data in registers" :key="data.id">
                            
                            <!-- Modal show face zoom -->
                            <div :id="'modal-media-image-' + data.id" class="uk-flex-top ukid-photo-zindex" uk-modal>
                                <div class="ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                                    <button class="uk-modal-close-outside" type="button" uk-close></button>
                                    <div :id="'ukid-container-zoom-' + data.id" class="uk-inline ukid-square-person-edit ukid-light-blue-color">
                                        <img crossorigin="Anonymous" :id="'ukid-image-bodies-zoom-' + data.id" uk-tooltip :src="data.photo + '?nocache=' + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))" alt="">
                                    </div>
                                    <div class="uk-overlay uk-light uk-position-bottom ukid-font-size-18">
                                        <p class="ukid-white-color ukid-background-transparent">
                                            <span v-if="data.facesInFrame" class="ukid-font-size-30 ukid-padding-15px">
                                                {{data.facesInFrame}} pessoas visíveis para contagem
                                            </span>
                                            <br />
                                            <span v-if="data.facesInFrame" class="ukid-font-size-30 ukid-padding-15px">
                                                Última atualização em {{new Date(data.createTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}}
                                            </span>
                                        </p>                                      
                                    </div>                                    
                                </div>
                            </div>

                            <!-- Show mode of demo and production -->
                            <div v-if="!$route.query.hasOwnProperty('debug')" class="uk-card uk-card-default uk-card-bod">
                                <div align="center" class="ukid-padding-5px">                                
                                    <table class="ukid-font-size-17" width="100%">
                                        <tbody>
                                            <tr>
                                                <td align="center" class="ukid-font-size-12">
                                                    {{new Date(data.createTimestamp) | dateFormat('DD/MM/YYYY HH:mm:ss')}} - {{data.facesInFrame}} pessoa(s)<br />
                                                    N. Docto: {{data.documentNumber}} - {{data.name}}<br />
                                                    Verificado: {{data.verified ? 'Sim' : 'Não'}} <span v-if="data.confidence && data.confidence !== '0.0'">- Score: {{(data.confidence * 100) | numberFormat('00')}}%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center">                                                   
                                                    <!-- Images -->
                                                    <div :id="'ukid-container-' + data.id" class="uk-inline ukid-square-tp-edit ukid-light-blue-color">
                                                        <img crossorigin="Anonymous" :id="'ukid-image-bodies-' + data.id" :src="data.photo + '?nocache=' + Math.abs(Math.floor(Math.random() * 7773777377737773777377737773))" />
                                                    </div>
                                                </td>
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div align="center">
                <div v-show="pagination.last_page > 1" class="uk-text-uppercase ukid-font-size-20">Pagina Atual: <b>{{page}}</b></div>
                <ul v-show="pagination.last_page > 1" class="ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10" uk-margin>
                    <paginate
                        v-model="page"
                        :page-count="pagination.last_page"
                        :click-handler="list"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'ukid-pagination uk-flex-center page-link ukid-margin-bottom-10'">
                    </paginate>
                </ul>
                <span 
                    uk-margin
                    uk-tooltip 
                    v-show="pagination.last_page > 1 && enableAutoList" 
                    title="Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui"  
                    @click="enableInterval()" 
                    class="ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20" 
                    uk-icon="icon:play-circle;ratio:2.7"></span>
            </div>

        </div>
        
    </div>

</template>
