var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "uk-width-expand@m" }, [
      _c(
        "ul",
        { staticClass: "uk-switcher", attrs: { id: "component-switcher" } },
        [
          _c("credential", {
            ref: "credential",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          }),
          _vm._v(" "),
          _c("general-config", {
            ref: "generalConfig",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              editor: _vm.editor,
              "editor-config": _vm.editorConfig,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          }),
          _vm._v(" "),
          _c("liveness-config", {
            ref: "livenessConfig",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          }),
          _vm._v(" "),
          _c("marks", {
            ref: "marks",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              editor: _vm.editor,
              "editor-config": _vm.editorConfig,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          }),
          _vm._v(" "),
          _c("invite", {
            ref: "invite",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          }),
          _vm._v(" "),
          _c("inviteWhatsapp", {
            ref: "inviteWhatsapp",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          }),
          _vm._v(" "),
          _c("inviteEmail", {
            ref: "inviteEmail",
            attrs: {
              app: _vm.app,
              strings: _vm.strings,
              commons: _vm.commons,
              editor: _vm.editor,
              "editor-config": _vm.editorConfig,
              user: _vm.user,
              auth: _vm.auth,
              role: _vm.role,
              vm: _vm.vm
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "uk-width-auto ukid-margin-top-30 ukid-margin-bottom-30" },
      [
        _c(
          "ul",
          {
            staticClass: "uk-nav uk-nav-default",
            attrs: {
              "uk-switcher":
                "connect: #component-switcher; animation: uk-animation-fade"
            }
          },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ukid-register-menu ukid-text-alert-color",
                  attrs: { href: "#" }
                },
                [
                  _c("span", { attrs: { "uk-icon": "icon:user;ratio:1.0" } }),
                  _vm._v(" Cadastro\n                ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                { staticClass: "ukid-text-alert-color", attrs: { href: "#" } },
                [
                  _c("span", { attrs: { "uk-icon": "icon:cog;ratio:1.0" } }),
                  _vm._v(" Config. Geral\n                ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                { staticClass: "ukid-text-alert-color", attrs: { href: "#" } },
                [
                  _c("span", { attrs: { "uk-icon": "icon:cog;ratio:1.0" } }),
                  _vm._v(" Config. Liveness\n                ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                { staticClass: "ukid-text-alert-color", attrs: { href: "#" } },
                [
                  _c("span", { attrs: { "uk-icon": "icon:more;ratio:1.0" } }),
                  _vm._v(" unike.PONTO\n                ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                { staticClass: "ukid-text-alert-color", attrs: { href: "#" } },
                [
                  _c("span", { attrs: { "uk-icon": "icon:image;ratio:1.0" } }),
                  _vm._v(" Invite Validade / Logo\n                ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                { staticClass: "ukid-text-alert-color", attrs: { href: "#" } },
                [
                  _c("span", {
                    attrs: { "uk-icon": "icon:whatsapp;ratio:1.0" }
                  }),
                  _vm._v(" Invite Whatsapp\n                ")
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                { staticClass: "ukid-text-alert-color", attrs: { href: "#" } },
                [
                  _c("span", { attrs: { "uk-icon": "icon:mail;ratio:1.0" } }),
                  _vm._v(" Invite Email\n                ")
                ]
              )
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-06dc0b6d", { render: render, staticRenderFns: staticRenderFns })
  }
}