var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "uk-child-width-expand@s ukid-background-white-color ukid-min-height",
      attrs: { "uk-grid": "" }
    },
    [
      _c("div", { attrs: { id: "modal-devices", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog uk-modal-body" }, [
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Faces por Página")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Faces por Página")]
            ),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-22-percent uk-visible@s" },
              [
                _c("span", {
                  staticClass: "uk-form-icon",
                  attrs: { "uk-icon": "icon:list" }
                }),
                _vm._v(" "),
                _c("the-mask", {
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { mask: ["##", "##"] },
                  model: {
                    value: _vm.filters.imagesPerPage,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "imagesPerPage", $$v)
                    },
                    expression: "filters.imagesPerPage"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-50-percent uk-hidden@s" },
              [
                _c("span", {
                  staticClass: "uk-form-icon",
                  attrs: { "uk-icon": "icon:list" }
                }),
                _vm._v(" "),
                _c("the-mask", {
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { mask: ["##", "##"] },
                  model: {
                    value: _vm.filters.imagesPerPage,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "imagesPerPage", $$v)
                    },
                    expression: "filters.imagesPerPage"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Tamhanho da Face")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Tamhanho da Face")]
            ),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-50-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.imageSize,
                    expression: "filters.imageSize"
                  }
                ],
                staticClass: "uk-range",
                attrs: { type: "range", min: "220", max: "600", step: "1" },
                domProps: { value: _vm.filters.imageSize },
                on: {
                  __r: function($event) {
                    return _vm.$set(
                      _vm.filters,
                      "imageSize",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" " + _vm._s(_vm.filters.imageSize) + "\n                ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Tamhanho da Fonte")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [_vm._v("Tamhanho da Fonte")]
            ),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-50-percent" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.fontSize,
                    expression: "filters.fontSize"
                  }
                ],
                staticClass: "uk-range",
                attrs: { type: "range", min: "14", max: "50", step: "1" },
                domProps: { value: _vm.filters.fontSize },
                on: {
                  __r: function($event) {
                    return _vm.$set(
                      _vm.filters,
                      "fontSize",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" " + _vm._s(_vm.filters.fontSize) + "\n                ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c("span", { staticClass: "uk-label ukid-primary-label-color" }, [
              _vm._v("\n                    Nome ou CPF\n                ")
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-30-percent uk-visible@s" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.searchParam,
                      expression: "filters.searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.searchParam },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "searchParam", $event.target.value)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.searchParam,
                      expression: "filters.searchParam"
                    }
                  ],
                  staticClass: "uk-input ukid-focus ukid-width-100-percent",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.searchParam },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "searchParam", $event.target.value)
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [
                _vm._v(
                  "\n                    Somente registros da data atual\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
              },
              [
                _vm._v(
                  "\n                    Somente registros da data atual\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "uk-inline ukid-width-50-percent" }, [
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.currentDateFilter(true)
                    }
                  }
                },
                [_vm._v("Somente data atual")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-margin" }, [
            _c(
              "span",
              { staticClass: "uk-label ukid-primary-label-color uk-visible@s" },
              [_vm._v("Faces Verificadas no Intervalo De:")]
            ),
            _vm._v(" "),
            _vm._m(0),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ukid-dates-interval", attrs: { "uk-grid": "" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "uk-inline ukid-width-20-percent uk-visible@s"
                  },
                  [
                    _vm._v(
                      "\n                        DE:\n                        "
                    ),
                    _c("datetime", {
                      attrs: {
                        type: "date",
                        "input-class": "mx-input ukid-width-100-percent",
                        "value-zone": "America/Sao_Paulo",
                        zone: "America/Sao_Paulo",
                        format: "dd/MM/yyyy",
                        phrases: {},
                        "hour-step": 1,
                        "minute-step": 5,
                        "week-start": 7,
                        auto: true,
                        "max-datetime": _vm.filters.endDate,
                        "use24-hour": ""
                      },
                      model: {
                        value: _vm.filters.startDate,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "startDate", $$v)
                        },
                        expression: "filters.startDate"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "uk-inline ukid-width-20-percent uk-visible@s"
                  },
                  [
                    _vm._v(
                      "\n                        ATÉ:\n                        "
                    ),
                    _c("datetime", {
                      attrs: {
                        type: "date",
                        "input-class": "mx-input ukid-width-100-percent",
                        "value-zone": "America/Sao_Paulo",
                        zone: "America/Sao_Paulo",
                        format: "dd/MM/yyyy",
                        phrases: {},
                        "hour-step": 1,
                        "minute-step": 5,
                        "week-start": 7,
                        auto: true,
                        "max-datetime": new Date().toISOString(),
                        "min-datetime": _vm.filters.startDate,
                        "use24-hour": ""
                      },
                      model: {
                        value: _vm.filters.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "endDate", $$v)
                        },
                        expression: "filters.endDate"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _vm._v("\n                    DE:\n                    "),
                _c("datetime", {
                  attrs: {
                    type: "date",
                    "input-class": "mx-input ukid-width-100-percent",
                    "value-zone": "America/Sao_Paulo",
                    zone: "America/Sao_Paulo",
                    format: "dd/MM/yyyy",
                    phrases: {},
                    "hour-step": 1,
                    "minute-step": 5,
                    "week-start": 7,
                    auto: true,
                    "max-datetime": _vm.filters.endDate,
                    "use24-hour": ""
                  },
                  model: {
                    value: _vm.filters.startDate,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "startDate", $$v)
                    },
                    expression: "filters.startDate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-inline ukid-width-60-percent uk-hidden@s" },
              [
                _c("br"),
                _vm._v("\n                    ATÉ:\n                    "),
                _c("datetime", {
                  attrs: {
                    type: "date",
                    "input-class": "mx-input ukid-width-100-percent",
                    "value-zone": "America/Sao_Paulo",
                    zone: "America/Sao_Paulo",
                    format: "dd/MM/yyyy",
                    phrases: {},
                    "hour-step": 1,
                    "minute-step": 5,
                    "week-start": 7,
                    auto: true,
                    "max-datetime": new Date().toISOString(),
                    "min-datetime": _vm.filters.startDate,
                    "use24-hour": ""
                  },
                  model: {
                    value: _vm.filters.endDate,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "endDate", $$v)
                    },
                    expression: "filters.endDate"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "uk-text-right uk-visible@s" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v("Não Salvar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary uk-modal-close ukid-magin-left-5",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveFilters()
                  }
                }
              },
              [_vm._v("Salvar")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "uk-text-right uk-hidden@s" }, [
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-default uk-button-small uk-modal-close ukid-magin-right-5",
                attrs: { type: "button" }
              },
              [_vm._v("Não Salvar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "uk-button uk-button-primary uk-button-small uk-modal-close ukid-magin-left-10",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveFilters()
                  }
                }
              },
              [_vm._v("Salvar")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "modal-overflow", "uk-modal": "" } }, [
        _c("div", { staticClass: "uk-modal-dialog" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-modal-body ukid-font-size-18",
              attrs: { "uk-overflow-auto": "" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.App.messages.delAsk))])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-default uk-modal-close",
                attrs: { type: "button" }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonCancel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary uk-modal-close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.del()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.App.messages.popoUpButtonDelete))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "uk-card uk-card-default uk-container uk-align-center uk-width-1-1 ukid-width-100-percent"
        },
        [
          _c(
            "div",
            { staticClass: "uk-card-header ukid-supress-border-bottom" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-small uk-flex-middle ukid-min-height-60",
                  attrs: { "uk-grid": "" }
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-20 ukid-margin-top-40 uk-visible@s"
                      },
                      [_vm._v("Pessoas nas Verificadas")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "ukid-font-size-16 ukid-margin-top-40 uk-hidden@s"
                      },
                      [_vm._v("Pessoas nas Verificadas")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-remove-top" }, [
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(
                          "\n                            Exibição de pessoas verificadas\n                            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-label ukid-label-blue-color" },
                          [
                            _vm._v(
                              "\n                                Total de Pessoas Até a Data de Hoje: " +
                                _vm._s(_vm.pagination.databaseCount) +
                                "\n                                "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                Total de Pessoas de Acordo com os Filtros: " +
                                _vm._s(_vm.pagination.total) +
                                "\n                            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "uk-hidden@s ukid-font-size-13" },
                        [
                          _vm._v(
                            "\n                            Exibição de pessoas verificadas\n                            "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-label ukid-label-blue-color ukid-font-size-9"
                            },
                            [
                              _vm._v(
                                "\n                                Total de Pessoas Até a Data de Hoje: " +
                                  _vm._s(_vm.pagination.databaseCount) +
                                  "\n                                "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                Total de Pessoas de Acordo com os Filtros: " +
                                  _vm._s(_vm.pagination.total) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-hidden@l uk-hidden@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-15",
                          attrs: { align: "right" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { "uk-toggle": "target: #modal-devices" },
                              on: {
                                click: function($event) {
                                  return _vm.setFilters()
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "uk-margin-small-right uk-icon",
                                attrs: { "uk-icon": "cog" }
                              }),
                              _vm._v(
                                "\n                                Filtrar\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "uk-hidden@l uk-visible@s uk-align-right ukid-margin-bottom-0 ukid-margin-top-20 ukid-font-size-17",
                          attrs: { align: "right" }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { "uk-toggle": "target: #modal-devices" },
                              on: {
                                click: function($event) {
                                  return _vm.setFilters()
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "uk-margin-small-right uk-icon",
                                attrs: { "uk-icon": "cog" }
                              }),
                              _vm._v(
                                "\n                                Filtrar\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0",
                          attrs: { "uk-toggle": "target: #modal-devices" },
                          on: {
                            click: function($event) {
                              return _vm.setFilters()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "cog" }
                          }),
                          _vm._v(
                            "\n                            Filtrar\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "ukid-open-fullscreen-buttom uk-button uk-button-default uk-visible@l uk-button-large uk-align-right ukid-margin-top-20 ukid-margin-bottom-0",
                          on: {
                            click: function($event) {
                              return _vm.openFullScreen()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "tv" }
                          }),
                          _vm._v(
                            "\n                            Tela Cheia\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "ukid-close-fullscreen-buttom uk-hidden uk-button uk-button-default uk-visible@l uk-button-large ukid-margin-top-20 uk-align-right ukid-margin-bottom-0",
                          on: {
                            click: function($event) {
                              return _vm.closeFullScreen()
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "close" }
                          }),
                          _vm._v(
                            "\n                            Sair da Tela Cheia\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("br")
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ukid-font-size-14 ukid-icon-pink-color ukid-margin-top-10 uk-align-right ukid-margin-bottom-0"
                },
                [
                  _vm._v(
                    "\n                Atenção aos filtros que você configurou\n            "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-card-footer ukid-supress-border-top" }, [
            _c(
              "div",
              { staticClass: "uk-flex uk-flex-center ukid-padding-top-20" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "uk-flex uk-flex-center uk-grid-medium uk-text-center",
                    attrs: { "uk-grid": "" }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.registers.length,
                            expression: "!registers.length"
                          }
                        ],
                        staticClass:
                          "uk-alert-primary ukid-magin-left-35 ukid-margin-top-30 ukid-label-blue-color",
                        attrs: { "uk-alert": "" }
                      },
                      [
                        _c("p", { staticClass: "ukid-white-color" }, [
                          _c("span", {
                            staticClass: "uk-margin-small-right uk-icon",
                            attrs: { "uk-icon": "warning" }
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.App.proximity.nobody) +
                              "\n                        "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.registers, function(verifyFaces) {
                      return _c(
                        "div",
                        {
                          key: verifyFaces.id,
                          staticClass: "ukid-offcanvas-over"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "uk-flex-top ukid-photo-zindex",
                              attrs: {
                                id: "modal-media-image-" + verifyFaces.id,
                                "uk-modal": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ukid-light-blue-color ukid-photo-effect-zoom uk-modal-dialog ukid-zoom-photo uk-margin-auto-vertical"
                                },
                                [
                                  _c("button", {
                                    staticClass: "uk-modal-close-outside",
                                    attrs: { type: "button", "uk-close": "" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        id:
                                          "ukid-container-zoom-" +
                                          verifyFaces.id
                                      }
                                    },
                                    [
                                      _c("table", [
                                        _c("tr", [
                                          _c("td", [
                                            _c("img", {
                                              staticClass:
                                                "ukid-photo-effect-zoom",
                                              attrs: {
                                                crossorigin: "Anonymous",
                                                id:
                                                  "ukid-request-image-face-zoom-" +
                                                  verifyFaces.id,
                                                src: verifyFaces.requestPhoto,
                                                width: "340",
                                                alt: ""
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("img", {
                                              staticClass:
                                                "ukid-photo-effect-zoom",
                                              attrs: {
                                                crossorigin: "Anonymous",
                                                id:
                                                  "ukid-image-face-zoom-" +
                                                  verifyFaces.id,
                                                src: verifyFaces.photo,
                                                width: "340",
                                                alt: ""
                                              }
                                            })
                                          ])
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-overlay uk-light uk-position-bottom",
                                      style:
                                        "font-size:" +
                                        _vm.filters.fontSize +
                                        "px !important"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "uk-text-uppercase" },
                                        [_vm._v("Registro Consultado")]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c("span", [
                                          _vm._v(
                                            " Docto : " +
                                              _vm._s(verifyFaces.documentNumber)
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " Nome : " +
                                              _vm._s(verifyFaces.name)
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " Resultado : " +
                                              _vm._s(
                                                verifyFaces.verified === true
                                                  ? "Passou"
                                                  : "Não Passou"
                                              )
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " Data: " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  new Date(
                                                    verifyFaces.timestamp
                                                  ),
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )
                                              )
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        verifyFaces.error
                                          ? _c("span", [
                                              _vm._v(
                                                " Erro: " +
                                                  _vm._s(verifyFaces.error)
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                "uk-card uk-card-default uk-card-bod ukid-width-" +
                                _vm.filters.imageSize +
                                "-px"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ukid-padding-5px",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "ukid-font-size-17 ukid-cursor-pointer",
                                      attrs: {
                                        width: _vm.filters.imageSize - 40
                                      }
                                    },
                                    [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { align: "left" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class: {
                                                    "ukid-circular-person-edit ukid-label-green-color":
                                                      verifyFaces.verified ===
                                                      true,
                                                    "ukid-circular-person-edit ukid-label-alert-color":
                                                      verifyFaces.verified ===
                                                      false
                                                  },
                                                  attrs: {
                                                    "uk-tooltip": "",
                                                    title:
                                                      "Foto enviada para comparação"
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "ukid-circular-portrait",
                                                    attrs: {
                                                      src:
                                                        verifyFaces.requestPhoto
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  "ukid-circular-person-edit ukid-label-green-color":
                                                    verifyFaces.verified ===
                                                    true,
                                                  "ukid-circular-person-edit ukid-label-alert-color":
                                                    verifyFaces.verified ===
                                                    false
                                                },
                                                attrs: {
                                                  "uk-tooltip": "",
                                                  title: "Foto do cadastro"
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "ukid-circular-portrait",
                                                  attrs: {
                                                    src: verifyFaces.photo
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      style:
                                        "font-size:" +
                                        _vm.filters.fontSize +
                                        "px !important",
                                      attrs: { width: "310" }
                                    },
                                    [
                                      _c(
                                        "tbody",
                                        {
                                          class: {
                                            "ukid-icon-green-color ukid-capitalize":
                                              verifyFaces.verified === true,
                                            "ukid-icon-alert-color ukid-capitalize":
                                              verifyFaces.verified === false
                                          }
                                        },
                                        [
                                          _vm._m(3, true),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { align: "center" } },
                                              [
                                                _vm._v(
                                                  "\n                                                Docto: " +
                                                    _vm._s(
                                                      verifyFaces.documentNumber.toLowerCase()
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { align: "center" } },
                                              [
                                                _vm._v(
                                                  "\n                                                Nome: " +
                                                    _vm._s(
                                                      verifyFaces.name.toLowerCase()
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { align: "center" } },
                                              [
                                                _vm._v(
                                                  "\n                                                Resultado: " +
                                                    _vm._s(
                                                      verifyFaces.verified ===
                                                        true
                                                        ? "Passou"
                                                        : "Não Passou"
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { align: "center" } },
                                              [
                                                _vm._v(
                                                  "\n                                                Data: " +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        new Date(
                                                          verifyFaces.timestamp
                                                        ),
                                                        "DD/MM/YYYY HH:mm:ss"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          verifyFaces.error
                                            ? _c("tr", [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "ukid-text-alert-color uk-text-lowercase",
                                                    attrs: { align: "center" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Erro: " +
                                                        _vm._s(
                                                          verifyFaces.error.toLowerCase()
                                                        ) +
                                                        "\n                                            "
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { attrs: { align: "center" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pagination.last_page > 1,
                    expression: "pagination.last_page > 1"
                  }
                ],
                staticClass: "uk-text-uppercase ukid-font-size-20"
              },
              [_vm._v("Pagina Atual: "), _c("b", [_vm._v(_vm._s(_vm.page))])]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pagination.last_page > 1,
                    expression: "pagination.last_page > 1"
                  }
                ],
                staticClass:
                  "ukid-pagination uk-flex-center ukid-magin-left-25 ukid-margin-top-10",
                attrs: { "uk-margin": "" }
              },
              [
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.pagination.last_page,
                    "click-handler": _vm.list,
                    "prev-text": "<",
                    "next-text": ">",
                    "container-class":
                      "ukid-pagination uk-flex-center page-link ukid-margin-bottom-10"
                  },
                  model: {
                    value: _vm.page,
                    callback: function($$v) {
                      _vm.page = $$v
                    },
                    expression: "page"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagination.last_page > 1 && _vm.enableAutoList,
                  expression: "pagination.last_page > 1 && enableAutoList"
                }
              ],
              staticClass:
                "ukid-cursor-pointer uk-animation-scale-down ukid-icon-blue-color uk-flex-center ukid-margin-bottom-20",
              attrs: {
                "uk-margin": "",
                "uk-tooltip": "",
                title:
                  "Ao paginar os registros você pausou o acompanhamento em tempo real.<br />Para ativá-lo novamente basta clicar aqui",
                "uk-icon": "icon:play-circle;ratio:2.7"
              },
              on: {
                click: function($event) {
                  return _vm.enableInterval()
                }
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "uk-label ukid-primary-label-color uk-hidden@s ukid-font-size-10"
      },
      [_vm._v("Faces Verificadas"), _c("br"), _vm._v("no Intervalo De:")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "ukid-logo-fullscreen uk-hidden uk-width-auto ukid-min-width-50"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "uk-navbar-item uk-logo ukid-white-color ukid-font-size-13",
            attrs: { href: "../" }
          },
          [
            _c("img", {
              staticClass: "uk-margin-small-right",
              attrs: { src: "content/images/unike-logo-un.png", width: "120" }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "uk-hidden@l uk-visible@s uk-width-auto ukid-min-width-50"
      },
      [
        _c("span", {
          staticClass: "uk-hidden ukid-spinner",
          attrs: { "uk-spinner": "ratio:4" }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "ukid-icon ukid-icon-blue-color ukid-magin-left-5 ukid-margin-top-6",
          attrs: { "uk-icon": "icon:search;ratio:5.0" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "uk-text-uppercase",
          attrs: { align: "center", colspan: "2" }
        },
        [
          _vm._v(
            "\n                                                Registro Consultado\n                                            "
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-35dc6d60", { render: render, staticRenderFns: staticRenderFns })
  }
}