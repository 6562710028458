"use strict"

const crypto = require('crypto')

export var pbe = {

    KDF: function(password, salt, iterations) {

      var pwd = new Buffer(password,'utf-8')
      var key = Buffer.concat([pwd, salt])

      var i

      for (i = 0; i < iterations; i+=1) {

        key = crypto.createHash("md5").update(key).digest()

      }

      return key

    },
  
    getKeyIV: function(password, salt, iterations) {

      var key = this.KDF(password, salt, iterations)

      var keybuf = new Buffer(key, 'binary').slice(0, 8)
      var ivbuf  = new Buffer(key, 'binary').slice(8, 16)

      return [ keybuf, ivbuf ]

    },
  
    encrypt: function(payload, password, salt, iterations, cb) {

      var kiv = this.getKeyIV(password, salt, iterations)
      var cipher = crypto.createCipheriv('des', kiv[0],kiv[1])
      
      var encrypted = []
      encrypted.push(cipher.update(payload, 'utf-8', 'hex'))
      encrypted.push(cipher.final('hex'))

      return cb(new Buffer(encrypted.join(''), 'hex').toString('base64'))

    },
  
    decrypt: function(payload, password, salt, iterations, cb) {
      
      var encryptedBuffer = new Buffer(payload, 'base64')

      var kiv = this.getKeyIV(password, salt, iterations)

      var decipher = crypto.createDecipheriv('des', kiv[0], kiv[1])

      var decrypted = []
      
      decrypted.push(decipher.update(encryptedBuffer, 'binary', 'utf8'))
      decrypted.push(decipher.final('utf8'))

      return cb(decrypted.join(''))

    }
    
    /*,

    decrypt: function(payload, password, cb) {
    
      try{

        const key = Buffer.from([-20, 61, -40, 127, 35, -16, -84, 110, -83, 42, -50, 107, -3, 103, 54, 68]).slice(0, 16) 
        const encoded = Buffer.from(payload)
        const iv = Buffer.from([111, 123, 56, 123, 99, 108, 45, 65, 111, 123, 56, 123, 99, 108, 45, 65])

        var decoded = encoded.slice(16, encoded.length)
        
        const decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);

        var decrypted = []
        decrypted.push(decipher.update(decoded, 'base64', 'utf8'))
        decrypted.push(decipher.final('utf8'))

        return cb(decrypted.join(''))
        
      } catch (err) {

        console.info(err)

      }

  }*/   

}